import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { FibEssayProps } from '@cms/comps/interact/fill-in-blank/FibEssayComp';

export const FibEssayExampleData: FibEssayProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FIB_ESSAY,

  setting: {
    row: 6,
    autoScore: false,
  },
};
