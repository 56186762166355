import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import CustomModal from '@components/modal/CustomModal';
import ProductEditForm from '@modules/product/components/product/ProductEditForm';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { ProductActionEnum } from '@modules/product/container/product/TeacherProductGroup';
import React, { useState } from 'react';
import { ManageProductTable } from '../../components/product/ManageProductTable';
import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';

export default function ComposeProductGroup() {
  const { t } = useTranslation();

  const [action, changeAction] = useState<ProductActionEnum>(
    ProductActionEnum.DEFAULT
  );

  const handleOnCancel = () => {
    changeAction(ProductActionEnum.DEFAULT);
  };

  const handleOnSuccess = () => {
    changeAction(ProductActionEnum.REFRESH);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('header.products')}

          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.CREATE_PRODUCT, RoleEnum.SCHOOL]}
          >
            <Button
              type={'primary'}
              shape="circle"
              icon={IconUtils.add_new}
              onClick={() => changeAction(ProductActionEnum.ADD_PRODUCT)}
            />
          </Authorization>
        </H1>
      </HeaderGroup>

      <ManageProductTable action={action} />

      {action === ProductActionEnum.ADD_PRODUCT && (
        <CustomModal
          header={t('product.actions.add')}
          className={'large-modal'}
          content={
            <ProductEditForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
