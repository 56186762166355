import React from 'react';

import { useRouter } from '@hooks/useRouter';
import ViewProductPage from '@modules/product/pages/ViewProductPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ViewMyProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: productId } = router.query as unknown as { id: number };

  return (
    <ManagementLayout className={'view-my-product-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.LIBRARY_PRODUCTS}
        title={t('product.actions.view')}
        description={t('product.actions.view')}
      />

      <ViewProductPage productId={productId} />
    </ManagementLayout>
  );
};

export default ViewMyProduct;
