import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { EntityStatusEnum } from '@services/model/common';
import CustomModal from '@components/modal/CustomModal';
import ProductEditForm from '@modules/product/components/product/ProductEditForm';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import { H1 } from '@components/typography';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ProductSchool } from '@components/product/ProductSchool';
import { IconUtils } from '@utils/IconUtils';
import { ProductTeacherItem } from '@components/product/ProductTeacherItem';
import Card, { CardClassEnum } from '@components/card';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { ProductRes } from '@modules/product/services/product_model';

export enum ProductActionEnum {
  DEFAULT = '',
  REFRESH = 'refresh',

  ADD_PRODUCT = 'add-product',
  EDIT_PRODUCT = 'edit-product',
  REMOVE_PRODUCT = 'remove-product',

  SHARE_PRODUCT = 'share-product',
  INVITE_STUDENT = 'invite-student',
  EDIT_METADATA = 'edit-metadata',
  SHARE_ACCESS_CODE = 'share-access-code',
  UPDATE_STATUS = 'update-status',
  UPDATE_ACCESSIBLE = 'update-accessible',
  ASSIGN_LICENSE = 'assign-license',
  VALIDATION = 'validation',
}

export default function TeacherProductGroup(props: {
  onLoaded?: (total: number) => void;
}) {
  const router = useRouter();
  const { t } = useTranslation();

  const [getMyProducts, { data, isSuccess }] =
    ProductAPI.endpoints.getMyProducts.useLazyQuery({});

  const [action, changeAction] = useState<ProductActionEnum>(
    ProductActionEnum.DEFAULT
  );

  const [privateProduct, setPrivateProduct] = useState<ProductRes[]>([]);
  const [publishProduct, setPublishProduct] = useState<ProductRes[]>([]);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      if (data.totalElements > 0 && data.content) {
        setListProduct(data.content);
      }

      if (props.onLoaded) {
        props.onLoaded(data.content.length);
      }
    }
  }, [data]);

  const setListProduct = (products: ProductRes[]) => {
    const publishPrd: ProductRes[] = [];
    const privatePrd: ProductRes[] = [];

    products.forEach((prod) => {
      if (prod.status != null && prod.status === EntityStatusEnum.PUBLISHED) {
        publishPrd.push(prod);
      } else {
        privatePrd.push(prod);
      }
    });

    setPublishProduct(publishPrd);
    setPrivateProduct(privatePrd);
  };

  const handleOnCancel = () => {
    changeAction(ProductActionEnum.DEFAULT);
    refresh();
  };

  const handleOnSuccess = (isAdded: boolean, productData: ProductRes) => {
    if (isAdded) {
      router.push(SiteMap.content.product.toc_gen(productData.productId));
    } else {
      changeAction(ProductActionEnum.DEFAULT);
      refresh();
    }
  };

  const refresh = () => {
    getMyProducts({});
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('header.products')}
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => changeAction(ProductActionEnum.ADD_PRODUCT)}
          />
        </H1>
      </HeaderGroup>

      {privateProduct && privateProduct.length > 0 && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          {privateProduct.map((item) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={8}
                key={item.productId + '_' + item.modifiedDate}
              >
                <Card
                  hoverable
                  className={`${CardClassEnum.rectangle}`}
                  style={{ marginBottom: 0 }}
                >
                  <Authorization
                    type={'ifAnyGranted'}
                    roles={[RoleEnum.TEACHER]}
                  >
                    <ProductTeacherItem item={item} onChange={refresh} />
                  </Authorization>

                  <Authorization
                    type={'ifAnyGranted'}
                    roles={[RoleEnum.SCHOOL]}
                  >
                    <ProductSchool item={item} />
                  </Authorization>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      {publishProduct && publishProduct.length > 0 && (
        <>
          <HeaderGroup className="header-group">
            <H1>{t('product.published_product')}</H1>
          </HeaderGroup>

          <Row gutter={[Default_Gutter, Default_Gutter]}>
            {publishProduct.map((item) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={8}
                  key={item.productId + '_' + item.modifiedDate}
                >
                  <Authorization
                    type={'ifAnyGranted'}
                    roles={[RoleEnum.TEACHER]}
                  >
                    <Card
                      className={CardClassEnum.rectangle}
                      style={{ marginBottom: 0 }}
                    >
                      <ProductTeacherItem item={item} onChange={refresh} />
                    </Card>
                  </Authorization>

                  <Authorization
                    type={'ifAnyGranted'}
                    roles={[RoleEnum.SCHOOL]}
                  >
                    <ProductSchool item={item} />
                  </Authorization>
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {action === ProductActionEnum.ADD_PRODUCT && (
        <CustomModal
          header={t('product.actions.add')}
          className={'large-modal'}
          content={
            <ProductEditForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
