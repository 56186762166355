import {
  CompCorrectIcon,
  CompIncorrectIcon,
  CompManualIcon,
  CompSkipIcon,
} from '@cms/icons';
import styled from 'styled-components';
import {
  CompFeedbackModalStyle,
  useCompFeedbackContext,
} from '@cms/feedback/CompFeedbackContext';
import React, { useEffect, useState } from 'react';
import Popover from 'antd/lib/popover';
import { ComponentItem } from '../context/ComponentItem';
import { CompProps } from '@cms/ComponentInteface';

export enum CompFeedbackTypeEnum {
  default = '',
  correct = 'correct',
  incorrect = 'incorrect',
  skip = 'skip',
  manual = 'manual',
}

export interface CompFeedbackProps {
  manualScore?: boolean;
  correct: string[];
  incorrect: string[];
  correctAnswer?: any;
}

export const initCompFeedback: CompFeedbackProps = {
  manualScore: false,
  correct: [],
  incorrect: [],
  correctAnswer: '',
};

export const CompFeedbackResultIcon = (props: {
  answer: any;
  compareFunc?: (ans: any[], res: any) => boolean;
}) => {
  const { feedBack } = useCompFeedbackContext();

  return (
    <CompFeedbackResultIconDisplay
      feedBack={feedBack}
      answer={props.answer}
      compareFunc={props.compareFunc}
    />
  );
};

export const CompFeedbackResultIconDisplay = (props: {
  feedBack: null | CompFeedbackProps;
  answer: any;
  compareFunc?: (ans: any[], res: any) => boolean;
}) => {
  const [type, setType] = useState(CompFeedbackTypeEnum.default);

  useEffect(() => {
    let type = CompFeedbackTypeEnum.default;

    if (props.feedBack) {
      if (props.feedBack.manualScore) {
        type = CompFeedbackTypeEnum.manual;
      } else if (props.compareFunc != null) {
        if (
          props.feedBack.correct != null &&
          props.feedBack.correct.length > 0 &&
          props.compareFunc(props.feedBack.correct, props.answer)
        ) {
          type = CompFeedbackTypeEnum.correct;
        } else if (
          props.feedBack.incorrect != null &&
          props.feedBack.incorrect.length > 0 &&
          props.compareFunc(props.feedBack.incorrect, props.answer)
        ) {
          type = CompFeedbackTypeEnum.incorrect;
        }
      } else if (
        props.feedBack.correct != null &&
        props.feedBack.correct.length > 0 &&
        props.feedBack.correct.includes(props.answer)
      ) {
        type = CompFeedbackTypeEnum.correct;
      } else if (
        props.feedBack.incorrect != null &&
        props.feedBack.incorrect.length > 0 &&
        props.feedBack.incorrect.includes(props.answer)
      ) {
        type = CompFeedbackTypeEnum.incorrect;
      }
    }
    setType(type);
  }, [props.feedBack, props.answer]);

  return <CompFeedbackIcon type={type} />;
};

export const CompFeedbackAnswerIcon = (props: {
  item: CompProps;
  correctAnswer: any;
  answer: any;
  compareFunc?: (ans: any[], res: any) => boolean;
}) => {
  const { feedBack } = useCompFeedbackContext();
  const [showCorrectAns, setShowCorrectAns] = useState(false);
  const [type, setType] = useState(CompFeedbackTypeEnum.default);

  useEffect(() => {
    let type = CompFeedbackTypeEnum.default;

    if (feedBack) {
      if (feedBack.manualScore) {
        type = CompFeedbackTypeEnum.manual;
      } else if (props.compareFunc != null) {
        if (
          feedBack.correct != null &&
          feedBack.correct.length > 0 &&
          props.compareFunc(feedBack.correct, props.answer)
        ) {
          type = CompFeedbackTypeEnum.correct;
        } else if (
          feedBack.incorrect != null &&
          feedBack.incorrect.length > 0 &&
          props.compareFunc(feedBack.incorrect, props.answer)
        ) {
          type = CompFeedbackTypeEnum.incorrect;
        }
      } else if (
        feedBack.correct != null &&
        feedBack.correct.length > 0 &&
        feedBack.correct.includes(props.answer)
      ) {
        setType(CompFeedbackTypeEnum.correct);
      } else if (
        feedBack.incorrect != null &&
        feedBack.incorrect.length > 0 &&
        feedBack.incorrect.includes(props.answer)
      ) {
        type = CompFeedbackTypeEnum.incorrect;
      }
    }
    setType(type);
  }, [feedBack, props.answer]);

  return (
    <>
      {type !== CompFeedbackTypeEnum.default && (
        <Popover
          content={
            <CompFeedbackModalStyle className={'show-correct-answer'}>
              <ComponentItem
                disabled={true}
                item={props.item}
                answer={{
                  id: props.item.id,
                  type: props.item.type,
                  answer: props.correctAnswer,
                }}
                feedback={null}
                onChange={() => {}}
              />
            </CompFeedbackModalStyle>
          }
          title="Correct Answer"
          trigger="click"
          placement={'leftTop'}
          open={showCorrectAns}
          onOpenChange={setShowCorrectAns}
        >
          <CompFeedbackIcon
            type={type}
            onClick={() => setShowCorrectAns(true)}
          />
        </Popover>
      )}
    </>
  );
};

export const CompFeedbackIcon = (props: {
  type: CompFeedbackTypeEnum;
  onClick?: () => void;
}) => {
  switch (props.type) {
    case CompFeedbackTypeEnum.correct:
      return (
        <CompFeedbackIconStyle
          className={'feedback-icon correct-icon'}
          onClick={props.onClick}
        >
          <CompCorrectIcon />
        </CompFeedbackIconStyle>
      );

    case CompFeedbackTypeEnum.incorrect:
      return (
        <CompFeedbackIconStyle
          className={'feedback-icon incorrect-icon'}
          onClick={props.onClick}
        >
          <CompIncorrectIcon />
        </CompFeedbackIconStyle>
      );

    case CompFeedbackTypeEnum.manual:
      return (
        <CompFeedbackIconStyle
          className={'feedback-icon manual-icon'}
          onClick={props.onClick}
        >
          <CompManualIcon />
        </CompFeedbackIconStyle>
      );
    case CompFeedbackTypeEnum.skip:
      return (
        <CompFeedbackIconStyle
          className={'feedback-icon skip-icon'}
          onClick={props.onClick}
        >
          <CompSkipIcon />
        </CompFeedbackIconStyle>
      );
    default:
      return null;
  }
};

const CompFeedbackIconStyle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5ch;
  cursor: pointer;

  svg {
    width: 1em;
    height: 1em;
  }
`;
