import { FilterUserReq } from '@modules/users/services/StudentAPI';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { UserRes } from '@modules/users/services/model';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import { UserSchoolAdminAPI } from '@modules/users/services/UserSchoolAdminAPI';
import { Button, Dropdown, notification } from 'antd';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { ManageUserActionEnum } from '@modules/users/pages/ManageTeacherPage';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { CreateSchoolAdminUser } from '@modules/users/component/users/CreateSchoolAdminUserForm';
import useConfirmModal from '@components/modal/ConfirmModal';
import { ResponsiveScreen } from '@hooks/useMedia';
import { IconUtils } from '@utils/IconUtils';
import Card, { CardClassEnum } from '@components/card';
import { SchoolAdminInfo } from '@modules/users/container/users/SchoolAdminInfor';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { InputGroup } from '@components/input/InputGroup';
import { RoleEnum } from '@app/redux/slices/roles';
import { useSecurity } from '@utils/authorization';
import { ResetUserPasswordForm } from '@modules/users/container/users/ResetUserPasswordForm';
import { UserPermissionForm } from '@modules/users/component/users/UserPermissionForm';

const SchoolAdminUserTable = (props: {
  schoolId?: number;
  reload: { reload: boolean; timestamp: number };
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const { ifAnyGranted } = useSecurity();

  const [findAll, { data, isFetching }] =
    UserSchoolAdminAPI.endpoints.findAll.useLazyQuery();

  const [disableUser] = UserSchoolAdminAPI.endpoints.disableUser.useMutation(
    {}
  );

  const [params, setParams] = useState<FilterUserReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    keyword: '',
    schoolId:
      props.schoolId != null && props.schoolId > 0 ? props.schoolId : -1,
  });

  const [action, setAction] = useState({
    action: ManageUserActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    reloadData();
  }, [params, props.reload]);

  const reloadData = () => {
    findAll(params);
  };

  const columns = [
    {
      title: t('label.avatar'),
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
      width: 72,
    },
    {
      title: t('label.username'),
      dataIndex: 'user.username',
      key: 'user.username',
      sorter: true,
    },
    {
      title: t('label.firstName'),
      dataIndex: 'user.firstName',
      key: 'user.firstName',
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'user.lastName',
      key: 'user.lastName',
      sorter: true,
    },
    {
      title: t('label.email'),
      dataIndex: 'user.email',
      key: 'user.email',
      width: 200,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.phone'),
      dataIndex: 'user.phone',
      key: 'user.phone',
      width: 200,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const renderMobile = (record: UserRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <SchoolAdminInfo
          item={record}
          onEdit={handleOnEdit}
          onDelete={handleOnRemove}
        />
      </Card>
    );
  };

  const handleOnUpdatePermission = (teacherId: number) => {
    setAction({
      action: ManageUserActionEnum.update_permission,
      params: teacherId,
    });
  };

  const processDataRow = (record: UserRes) => {
    return {
      key: record.userId,
      avatar: <UserAvatar src={record.avatar} size={64} />,
      'user.username': (
        <>
          <div>{record.username}</div>
          {record.lastLogin && (
            <InputGroup
              addedClass={'small-group'}
              label={t('label.last_login')}
            >
              <DateAndTimeLabel type="inline" label={record.lastLogin} />
            </InputGroup>
          )}
        </>
      ),
      'user.firstName': record.firstName,
      'user.lastName': record.lastName,
      'user.email': record.email,
      'user.phone': record.phone,
      actions: (
        <Dropdown
          menu={{
            items: [
              {
                key: 'edit',
                icon: IconUtils.actions.edit,
                label: t('school_admin.actions.update'),
                onClick: () => handleOnEdit(record.userId),
              },

              ifAnyGranted([RoleEnum.ADMIN])
                ? {
                    key: 'reset-password',
                    icon: IconUtils.password,
                    label: t('users.actions.reset_password'),
                    onClick: () => handleOnResetPassword(record.userId),
                  }
                : null,

              ifAnyGranted([RoleEnum.SCHOOL])
                ? {
                    key: 'update-permission',
                    icon: IconUtils.password,
                    label: t('users.actions.update_permission'),
                    onClick: () => handleOnUpdatePermission(record.userId),
                  }
                : null,

              {
                key: 'remove',
                icon: IconUtils.actions.delete,
                label: t('school_admin.actions.remove'),
                onClick: () => handleOnRemove(record.userId),
              },
            ],
          }}
          trigger={['click']}
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const handleOnEdit = (studentId: number) => {
    setAction({ action: ManageUserActionEnum.update, params: studentId });
  };

  const handleOnResetPassword = (teacherId: number) => {
    setAction({
      action: ManageUserActionEnum.reset_password,
      params: teacherId,
    });
  };

  const handleOnRemove = (userId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      'Do you want to disabled the account?',
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          const request = {
            userId: userId,
            disabled: true,
          };

          disableUser(request)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('school_admin.warning.remove_success'),
                description: t('school_admin.warning.remove_success_message'),
                placement: 'bottomRight',
              });
              reloadData();
            })
            .catch(() => {
              notification.error({
                message: t('school_admin.warning.remove_error'),
                description: t('school_admin.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const refreshData = (params: any) => {
    setParams({ ...params });
  };

  const onEditSuccess = () => {
    reloadData();
    onCancelEdit();
  };

  const onCancelEdit = () => {
    setAction({ action: ManageUserActionEnum.default, params: -1 });
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === ManageUserActionEnum.update && (
        <CustomModal
          header={t('school_admin.actions.update')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateSchoolAdminUser
              schoolId={props.schoolId}
              userId={action.params}
              onSuccess={onEditSuccess}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}

      {action.action === ManageUserActionEnum.reset_password && (
        <CustomModal
          header={t('users.actions.reset_password')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ResetUserPasswordForm
              schoolId={props.schoolId}
              userId={action.params}
              onSuccess={onEditSuccess}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}

      {action.action === ManageUserActionEnum.update_permission && (
        <CustomModal
          header={t('users.actions.update_permission')}
          className={CustomModalClassEnum.large_modal}
          content={
            <UserPermissionForm
              roles={RoleEnum.SCHOOL}
              schoolId={props.schoolId}
              userId={action.params}
              onSuccess={onEditSuccess}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}
    </>
  );
};

export default SchoolAdminUserTable;
