import { ViewLessonContentProvider } from '@cms/lesson-template/context/ViewLessonContext';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { SurveyServiceProvider } from '@cms/service/SurveyServiceProvider';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import React from 'react';
import { LessonRes } from '@modules/product/services/lesson_model';
import { SurveyRes } from '@modules/assignments/service/survey_model';

export const SurveyLessonContent = (props: {
  survey: SurveyRes;
  content: LessonRes;
}) => {
  const onExitExercise = () => {};

  return (
    <ViewLessonContentProvider
      type={CompMode.DO_EXERCISE}
      lesson={props.content}
      lessonType={props.content.type ?? LessonTypeEnum.page}
      answers={[]}
      feedBacks={[]}
      gradings={[]}
      exitExercise={onExitExercise}
    >
      <SurveyServiceProvider surveyId={props.survey.surveyId}>
        <LessonTemplateLayoutProvider lesson={props.content}>
          <ViewLessonContent />
        </LessonTemplateLayoutProvider>
      </SurveyServiceProvider>
    </ViewLessonContentProvider>
  );
};
