import { useTranslation } from 'react-i18next';
import { useRouter } from '@hooks/useRouter';
import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';

import PublicLayout from '@components/template/public-layout/PublicLayout';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { PageHeader } from '@app/header/PageHeader';
import { SiteMap } from '@router/SiteMap';
import { IconUtils } from '@utils/IconUtils';

const TestAccessProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      accessToken: '',
      productCode: '',
      uid: '',
    },

    validationSchema: yup.object().shape({
      productCode: yup.string().required(t('validation.required.field')),
      accessToken: yup.string().required(t('validation.required.field')),
      uid: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      router.push(
        SiteMap.lti.product_view_gen(
          values.accessToken,
          values.uid,
          values.productCode
        )
      );
    },
  });

  return (
    <PublicLayout className={'access-product-lti-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.actions.review')}
        description={t('lesson.actions.review')}
      />

      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <FormikProvider value={formik}>
              <Input
                required={true}
                name="productCode"
                type="text"
                label={'Product Code'}
                placeholder={'Product Code'}
                onChange={formik.handleChange}
                value={formik.values.productCode}
              />

              <Input
                required={true}
                name="uid"
                type="text"
                label={'Email'}
                placeholder={'Email'}
                onChange={formik.handleChange}
                value={formik.values.uid}
              />

              <Input
                required={true}
                name="accessToken"
                type="textarea"
                label={'Access Token'}
                placeholder={'Access Token'}
                onChange={formik.handleChange}
                value={formik.values.accessToken}
                maxLength={512}
                row={4}
              />

              <div className="submit-container">
                <ButtonGroup>
                  <Button
                    type={'primary'}
                    shape={'round'}
                    onClick={formik.submitForm}
                    icon={IconUtils.login}
                  >
                    {t('button.login')}
                  </Button>
                </ButtonGroup>
              </div>
            </FormikProvider>
          </div>
        </div>
      </AuthorizeStyle>
    </PublicLayout>
  );
};

export default TestAccessProduct;
