import React from 'react';

import { useRouter } from '@hooks/useRouter';
import ViewLessonPage from '@modules/product/pages/ViewLessonPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ViewMyLesson = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: lessonId } = router.query as unknown as { id: number };

  return (
    <ManagementLayout className={'view-lesson-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('exercise.actions.view')}
        description={t('exercise.actions.view')}
      />

      <ViewLessonPage lessonId={lessonId} />
    </ManagementLayout>
  );
};

export default ViewMyLesson;
