import React from 'react';
import EmptyLayout from '@components/Layout/EmptyLayout';
import {
  LogoutComp,
  LoginComp,
  LoginOauthReq,
} from '@modules/authorization/components/LogoutComp';
import { useRouter } from '@hooks/useRouter';

const RedirectOauthPage = () => {
  const router = useRouter();

  const data = router.query as unknown as LoginOauthReq;

  if (data.error) {
    return (
      <EmptyLayout>
        <LogoutComp />
      </EmptyLayout>
    );
  } else {
    return (
      <EmptyLayout>
        <LoginComp data={data} />
      </EmptyLayout>
    );
  }
};

export default RedirectOauthPage;
