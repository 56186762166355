import { CourseRes } from '@services/private/CourseAPI';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import { PublicSchoolRes } from '@services/private/SchoolAPI';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { DateLabel } from '@components/text/DateLabel';
import React, { useState } from 'react';
import Card, { CardClassEnum } from '@components/card';
import ContainerScale from '@components/Layout/ContainerScale';
import NavLink from '@components/button/NavLink';
import { H5 } from '@components/typography';
import styled from 'styled-components';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import RequireLoginButton from '@components/button/RequireLoginButton';
import RegisterCourseForm from '@modules/authorization/container/RegisterCourseForm';
import { SiteMap } from '@router/SiteMap';

export const PublicCourseDetail = (props: {
  course: CourseRes;
  author: UserProfileRes;
  school: PublicSchoolRes;
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState('');

  const registerCourse = () => {
    setAction('register');
  };

  const handleOnClose = () => {
    setAction('');
  };

  return (
    <PublicCourseDetailStyle className={'course-detail-info'}>
      <Row gutter={40}>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={17}>
          {props.course.video ? (
            <ContainerScale scale={9 / 16} minHeight={300}>
              <iframe
                title={props.course.name}
                className="video-instruction content-scale"
                width="100%"
                height="100%"
                src={props.course.video}
              />
            </ContainerScale>
          ) : (
            <>
              {props.course.thumbnails && (
                <ThumbnailsImg
                  type={'icon'}
                  src={props.course.thumbnails}
                  ratio={ImageScale.course.ratio}
                />
              )}
            </>
          )}

          <Col className="gutter-row" span={16}>
            <div
              className={'course-introduction'}
              dangerouslySetInnerHTML={{ __html: props.course.introduce }}
            />
          </Col>
        </Col>

        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={7}>
          <Card
            className={CardClassEnum.rectangle_box}
            style={{ marginBottom: '2.5em' }}
          >
            <ThumbnailsImg
              type={'icon'}
              src={props.course.thumbnails}
              ratio={ImageScale.course.ratio}
            />

            <div
              className={'description'}
              dangerouslySetInnerHTML={{ __html: props.course.shortIntroduce }}
            />

            <div className={'course-info'}>
              <h3>Price: {props.course.price} G-points</h3>

              {props.course.startDate != null && (
                <div className={'created-date'}>
                  <label>{t('label.openDate')}</label>
                  <DateLabel label={props.course.startDate} />
                </div>
              )}
            </div>

            <H5 className={'product-author'}>
              Author:&nbsp;
              <NavLink
                href={SiteMap.public.teacher_view_gen(props.author.name)}
              >
                {props.author.displayName}
              </NavLink>
            </H5>

            <div className={'course-actions'}>
              <RequireLoginButton
                size={'large'}
                shape={'round'}
                type={'primary'}
                onClick={registerCourse}
              >
                {t('button.registration')}
              </RequireLoginButton>
            </div>
          </Card>
        </Col>
      </Row>

      {action === 'register' && (
        <CustomModal
          header={t('header.course_registration')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <RegisterCourseForm
              courseId={props.course.courseId}
              schoolId={props.school.schoolId}
              onSuccess={handleOnClose}
            />
          }
          onCloseFunc={handleOnClose}
        />
      )}
    </PublicCourseDetailStyle>
  );
};

const PublicCourseDetailStyle = styled.div``;
