import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import styled from 'styled-components';
import {
  ClassRoomHost,
  ClassRoomParticipant,
} from '@classroom/context/ClassRoomMode';
import { DynamicContainer } from '@components/Layout/DynamicContainer';
import React from 'react';
import { ClassRoomSessionTask } from '@classroom/container/task/ClassRoomSessionTask';
import { ClassRoomInprogressTask } from '@classroom/container/task/ClassRoomInprogressTask';
import { ClassRoomParticipantTask } from '@classroom/container/task/ClassRoomParticipantTask';

export const ClassRoomExercise = () => {
  const { session, sessionTaskId } = useClassRoomContext();

  return (
    <>
      {sessionTaskId > 0 ? (
        <>
          <ClassRoomHost>
            <ClassRoomExerciseStyle className={'class-room-exercise'}>
              <ClassRoomSessionTask
                sessionId={session.sessionId}
                sessionTaskId={sessionTaskId}
              />
            </ClassRoomExerciseStyle>
          </ClassRoomHost>

          <ClassRoomParticipant>
            <DynamicContainer
              className={'class-room-content-board'}
              setting={{ width: 1600, height: 900, fontSize: 24 }}
            >
              <ClassRoomSelfExerciseStyle
                className={'class-room-self-exercise'}
              >
                <ClassRoomParticipantTask
                  sessionId={session.sessionId}
                  sessionTaskId={sessionTaskId}
                />
              </ClassRoomSelfExerciseStyle>
            </DynamicContainer>
          </ClassRoomParticipant>
        </>
      ) : (
        <ClassRoomHost>
          <ClassRoomExerciseStyle className={'class-room-exercise'}>
            <ClassRoomInprogressTask sessionId={session.sessionId} />
          </ClassRoomExerciseStyle>
        </ClassRoomHost>
      )}
    </>
  );
};

const ClassRoomExerciseStyle = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const ClassRoomSelfExerciseStyle = styled.div`
  position: relative;
  min-height: 100%;
  border: 2px solid #333;
`;
