import React, { ReactNode } from 'react';
import { Button, Card, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RegistrationType } from '@modules/public/RegistrationPage';
import styled from 'styled-components';
import NavLink from '@components/button/NavLink';
import { useTheme } from '@app/styled/StyledProvider';
import { SiteMap } from '@router/SiteMap';

const Registration = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div
      className={'home-block-container'}
      style={{ background: theme.app.background }}
    >
      <div className={'gstudy-container'}>
        <h2>Đăng kí</h2>

        <Row gutter={[16, 16]} justify="center" align="top">
          <Col span={8}>
            <Card title="Đăng kí học sinh" bordered={false}>
              <p>Tìm kiếm Tài liệu</p>
              <p>Xây dựng thư viện sách cá nhân</p>
              <p>Đăng kí tham gia các khóa học</p>
              <p>Đọc sách và làm bài tập</p>

              <Divider />

              <NavLink href={SiteMap.register.student}>
                <Button type={'primary'}>{t('button.register')}</Button>
              </NavLink>
            </Card>
          </Col>

          <Col span={8}>
            <Card title="Đăng kí giáo viên" bordered={false}>
              <p>Tạo Tài liệu dễ dàng</p>
              <p>Tạo các khóa học đơn giản</p>
              <p>Quản lý học sinh</p>
              <p>Theo dõi quá trình học thuận tiện</p>

              <Divider />

              <NavLink href={SiteMap.register.teacher}>
                <Button type={'primary'}>{t('button.register')}</Button>
              </NavLink>
            </Card>
          </Col>

          <Col span={8}>
            <Card title="Đăng kí phụ huynh" bordered={false}>
              <p>Đồng hành cùng con</p>
              <p>Theo dõi kết quả</p>
              <p>Bám sát quá trình học của con</p>
              <p>Liên lạc với giáo viên</p>

              <Divider />

              <NavLink href={SiteMap.register.parent}>
                <Button disabled={true} type={'primary'}>
                  {t('button.register')}
                </Button>
              </NavLink>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const RegistrationBlockStyle = styled.div`
  .ant-card {
    border: 1px solid #ccc;

    &.active {
      border: 1px solid #6366f1;
    }
  }
`;

const RegistrationUserBlock = (props: {
  title: string | ReactNode;
  type: RegistrationType;
  currentType: RegistrationType;
  onClick: (type: RegistrationType) => void;
  children: any;
}) => {
  const { t } = useTranslation();
  const isSelect = props.type === props.currentType;

  return (
    <Card
      title={props.title}
      bordered={isSelect}
      className={isSelect ? ' active ' : ''}
    >
      {props.children}

      <Divider />

      <Button type={'primary'} onClick={() => props.onClick(props.type)}>
        {t('button.register')}
      </Button>
    </Card>
  );
};

export const RegistrationBlock = (props: {
  onClick: (type: RegistrationType) => void;
  selectType: RegistrationType;
}) => {
  const { t } = useTranslation();

  return (
    <RegistrationBlockStyle className={'home-block-container'}>
      <h2>{t('registration.header')}</h2>

      <Row gutter={[16, 16]} justify="center" align="top">
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <RegistrationUserBlock
            title={t('registration.actions.student')}
            type={RegistrationType.STUDENT}
            currentType={props.selectType}
            onClick={props.onClick}
          >
            <p>Tìm kiếm Tài liệu/ các khóa học</p>
            <p>Xây dựng thư viện sách cá nhân</p>
            <p>Đọc sách và làm bài tập</p>
            <p>Công cụ học tập</p>
            <p>Theo dõi quá trình học thuận tiện</p>
          </RegistrationUserBlock>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <RegistrationUserBlock
            title={t('registration.actions.teacher')}
            type={RegistrationType.TEACHER}
            currentType={props.selectType}
            onClick={props.onClick}
          >
            <p>Quản lý học sinh</p>
            <p>Tạo bài học và giáo trình</p>
            <p>Tuyển sinh</p>
            <p>Xuất bản giáo trình, Khai giảng khóa học</p>
            <p>Công cụ dạy và học</p>
          </RegistrationUserBlock>
        </Col>

        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <RegistrationUserBlock
            title={t('registration.actions.parent')}
            type={RegistrationType.PARENT}
            currentType={props.selectType}
            onClick={props.onClick}
          >
            <p>Đồng hành cùng con</p>
            <p>Theo dõi kết quả</p>
            <p>Bám sát quá trình học của con</p>
            <p>Liên lạc với giáo viên</p>
            <p>Quà tặng</p>
          </RegistrationUserBlock>
        </Col>
      </Row>
    </RegistrationBlockStyle>
  );
};

export default Registration;
