import React, { useEffect } from 'react';
import { message } from 'antd';

import { resetComposeLesson } from '@app/redux/slices/composeLesson';
import { useTranslation } from 'react-i18next';

import BuildLessonTemplate from '@cms/lesson-template/compose/build/BuildLessonTemplate';
import { ComposeMultipleQuestionTemplate } from '@cms/lesson-template/compose/lesson/ComposeMultipleQuestionTemplate';
import BuildSectionTemplate from '@cms/lesson-template/compose/sections/BuildSectionTemplate';
import { LessonEditableProvider } from '@cms/context/LessonEditableProvider';
import { EditModeEnum } from '../ComposeLessonProps';
import { ComposeSingleQuestionTemplate } from '@cms/lesson-template/compose/lesson/ComposeSingleQuestionTemplate';
import { LessonServiceProvider } from '@cms/context/service/LessonServiceProvider';
import { ComposeLessonActionHandler } from '@cms/context/ComposeLessonActionHandler';
import { useDispatch, useSelector } from '@app/redux/hook';

const ComposeLessonPage = (props: {
  from: string;
  lessonId: number;
  resourceId?: number;
  productId?: number;
  productTocId?: number;
  editMode: EditModeEnum;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { warningMsg } = useSelector((state) => state.composeLesson);

  useEffect(() => {
    return () => {
      dispatch(resetComposeLesson());
    };
  }, [props.lessonId, props.productId, props.productTocId, props.editMode]);

  useEffect(() => {
    if (warningMsg && warningMsg.timestamp && warningMsg.success != null) {
      warningMessage(warningMsg.success);
    }
  }, [warningMsg]);

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      message.info({
        content: t('resource.warning.update_success'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    } else {
      message.error({
        content: t('resource.warning.update_error'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    }
  };

  return (
    <LessonServiceProvider
      type={'db'}
      lessonId={props.lessonId ?? -1}
      lessonExampleId={-1}
      resourceId={props.resourceId}
    >
      <LessonEditableProvider
        from={props.from}
        editMode={props.editMode}
        lessonId={props.lessonId}
        resourceId={props.resourceId}
        productId={props.productId}
        productTocId={props.productTocId}
      >
        <ComposeLessonActionHandler />

        {props.editMode === EditModeEnum.MULTIPLE_QUESTION && (
          <ComposeMultipleQuestionTemplate />
        )}

        {props.editMode === EditModeEnum.SINGLE_QUESTION && (
          <ComposeSingleQuestionTemplate />
        )}

        {props.editMode === EditModeEnum.BUILD_QUESTION && (
          <BuildLessonTemplate />
        )}

        {props.editMode === EditModeEnum.BUILD_SECTION && (
          <BuildSectionTemplate lessonId={props.lessonId} />
        )}
      </LessonEditableProvider>
    </LessonServiceProvider>
  );
};

export default ComposeLessonPage;
