import { ComposeResourceItem } from '@cms/lesson-template/compose/lesson/ComposeResource';
import React, { useEffect, useMemo, useRef } from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import Button from '@components/button';
import Space from 'antd/lib/space';
import styled from 'styled-components';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { ResourceValidationInfo } from '@cms/lesson-template/compose/validation';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { Tooltip } from '@components/tooltip/Tooltip';
import { IconUtils } from '@utils/IconUtils';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';

export const SectionResourceBank = (props: {
  index: number;
  resource: ResourceProps;
}) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const service = useLessonServiceContext();
  const { dispatchAction } = useLessonEditableContext();

  useEffect(() => {
    if (
      contentRef.current != null &&
      props.resource.resourceId === service.resourceId
    ) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.resource, service.resourceId, contentRef]);

  const className = useMemo(() => {
    const className: string[] = ['section-resource-item'];
    if (props.resource.resourceId === service.resourceId) {
      className.push(' active ');
    }

    if (
      props.resource.validation != null &&
      (props.resource.validation.hasError ||
        !props.resource.validation.completed)
    ) {
      className.push(' error ');
    }

    return className;
  }, [props.resource]);

  const handleOnRemove = (resourceId: number) => {
    dispatchAction(LessonEditableActionEnum.delete_resource, resourceId);
  };

  const handleOnClone = (resourceId: number) => {
    dispatchAction(LessonEditableActionEnum.clone_resource, resourceId);
  };

  const handleOnShowSetting = () => {
    dispatchAction(
      LessonEditableActionEnum.show_setting,
      props.resource.resourceId
    );
  };

  const handleOnSelect = () => {
    if (props.resource.resourceId !== service.resourceId) {
      dispatchAction(
        LessonEditableActionEnum.view_resource,
        props.resource.resourceId
      );
    }
  };

  return (
    <DragItem
      key={JSON.stringify(props.resource) + '_' + props.index}
      acceptType={'resource-item'}
      index={props.index}
      draggableId={props.resource.resourceId + ''}
    >
      <SectionResourceBankStyle
        className={className.join(' ')}
        ref={contentRef}
      >
        <div
          className={'question-content-display show-correct-answer'}
          onClick={handleOnSelect}
        >
          <div className={'question-content-background'} />
          {props.resource.validation != null &&
          (props.resource.validation.hasError ||
            !props.resource.validation.completed) ? (
            <Popover
              placement="rightTop"
              title={'Resource validation'}
              content={
                <ResourceValidationInfo
                  resourceId={props.resource.resourceId}
                />
              }
              trigger={['hover', 'click']}
            >
              <ComposeResourceItem item={props.resource} index={props.index} />
            </Popover>
          ) : (
            <ComposeResourceItem item={props.resource} index={props.index} />
          )}
        </div>

        <Space className={'resource-actions bottom-left-menu'}>
          <Button shape={'circle'} size={'small'} type={'primary'}>
            <b>{props.index + 1}</b>
          </Button>
        </Space>

        <Space className={'resource-actions top-right-menu'}>
          <Tooltip title={t('resource.actions.show_setting')}>
            <Button
              shape={'circle'}
              size={'small'}
              onClick={handleOnShowSetting}
              icon={IconUtils.view_info}
            />
          </Tooltip>

          <Tooltip title={t('resource.actions.clone_question')}>
            <Button
              size={'small'}
              shape={'circle'}
              onClick={() => handleOnClone(props.resource.resourceId)}
              icon={IconUtils.compose.clone}
            />
          </Tooltip>
        </Space>

        <Space className={'resource-actions bottom-right-menu'}>
          <Tooltip title={t('resource.actions.delete_question')}>
            <Button
              type={'primary'}
              size={'small'}
              shape={'circle'}
              danger
              onClick={() => handleOnRemove(props.resource.resourceId)}
              icon={IconUtils.compose.delete}
            />
          </Tooltip>
        </Space>
      </SectionResourceBankStyle>
    </DragItem>
  );
};

export const SectionResourceBankStyle = styled.div`
  border: 1px solid #ccc;
  background: #fff;
  margin-bottom: 10px;

  position: relative;
  overflow: hidden;

  .question-content-display {
    cursor: pointer;
    position: relative;
    padding: 5px 10px 10px;

    &:hover {
      .question-content-background {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .question-content-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.01);
      z-index: 2;
    }
  }

  .resource-comps {
    width: 100%;
    aspect-ratio: 16 / 9;
    font-size: 10px;
    overflow: hidden;
    z-index: 1;
  }

  &.active {
    border: 1px solid ${(props) => props.theme.app.primary};
    outline: 1px solid ${(props) => props.theme.app.primary};
    background: ${(props) => props.theme.app.primary_bgr};
  }

  &.error {
    border: 1px solid ${(props) => props.theme.warning.error};
    outline: 1px solid ${(props) => props.theme.warning.error};
    background: ${(props) => props.theme.warning.error_bgr};
  }

  > .resource-actions {
    position: absolute;
    z-index: 2;

    &.bottom-center-menu {
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &.bottom-left-menu {
      left: 5px;
      bottom: 5px;
    }

    &.top-right-menu {
      top: 5px;
      right: 5px;
    }

    &.bottom-right-menu {
      right: 5px;
      bottom: 5px;
    }
  }

  &.blank-resource {
    min-height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    h1 {
      font-size: 200%;
      color: ${(props) => props.theme.component.disabled};
    }

    p {
      color: ${(props) => props.theme.component.disabled};
    }
  }
`;
