import { Tag, Tooltip } from 'antd';
import ColorUtils from '@utils/ColorUtils';
import React from 'react';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';
import { LanguageContentTag } from '@components/text/LanguageTag';

export const StandardSetDisplay = (props: { item: StandardSetRes }) => {
  return (
    <Tooltip
      placement="topLeft"
      trigger={['click']}
      title={
        <>
          <b>{props.item.name}</b>

          <LanguageContentTag content={props.item.description} />
        </>
      }
    >
      <StandardSetTag item={props.item} />
    </Tooltip>
  );
};

export const StandardSetTag = (props: { item: StandardSetRes }) => {
  return (
    <Tag color={ColorUtils.getDefaultColor(props.item.standardSetId).color}>
      {props.item.code}
    </Tag>
  );
};

export const StandardTag = (props: { item: StandardRes }) => {
  return (
    <Tag color={ColorUtils.getDefaultColor(props.item.standardId).color}>
      {props.item.code}
    </Tag>
  );
};
