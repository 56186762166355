import { ChatBox } from '@components/chatbox/ChatBox';
import React from 'react';
import styled from 'styled-components';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';

export const ClassDiscussion = (props: { classId: number }) => {
  return (
    <ClassDiscussionStyle className={'class-discussion'}>
      <HeaderGroup className={'mt-0'}>
        <H1>Discussion</H1>
      </HeaderGroup>

      <ChatBox
        className={'class-discussion'}
        collection={`/classes/${props.classId}`}
        channel={`/messages`}
      />
    </ClassDiscussionStyle>
  );
};

const ClassDiscussionStyle = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  .chat-box-container {
    width: 100%;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: ${(props) => props.theme.app.background};
  }
`;
