import React from 'react';

import ViewProductContextProvider from '../components/view-toc/ViewTableOfContentContext';
import ViewLessonInTableOfContent from '../components/view-toc/ViewLessonInTableOfContent';
import { ViewTableOfContentToolbar } from '../components/view-toc/ViewTableOfContentToolbar';
import { ViewProductMode } from '@modules/product/components/view-toc/model';
import { ViewMode } from '@services/model/common';

const ReviewProductPage = (props: {
  productId: number;
  type: ViewProductMode;
}) => {
  return (
    <ViewProductContextProvider
      type={props.type}
      productId={props.productId}
      productTocId={-1}
      lessonId={-1}
    >
      <ViewLessonInTableOfContent />
      <ViewTableOfContentToolbar
        type={
          props.type === ViewProductMode.compose_review
            ? ViewMode.edit
            : ViewMode.view
        }
      />
    </ViewProductContextProvider>
  );
};

export default ReviewProductPage;
