import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useState } from 'react';
import { Button, Dropdown, notification } from 'antd';
import { PageSiteAPI, PageSiteRes } from '@services/page-site/PageSiteAPI';
import { PageSiteActionsHandle } from '@modules/page-site/containers/PageSiteActionsHandle';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';
import NavLink from '@components/button/NavLink';

export enum PageSiteActionEnum {
  DEFAULT = '',
  COMPOSE = 'compose',
  ADD = 'add',
  EDIT = 'edit',
  EDIT_METADATA = 'edit-metadata',
  REMOVE = 'remove',
  PUBLISH = 'publish',
}

export const PageSiteActions = ({
  item,
  onChange,
}: {
  item: PageSiteRes;
  onChange: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [deletePageSite] = PageSiteAPI.endpoints.deletePageSite.useMutation();

  const [action, setAction] = useState<{
    action: PageSiteActionEnum;
    params: number;
  }>({ action: PageSiteActionEnum.DEFAULT, params: -1 });

  const handleOnCancel = () => {
    setAction({ action: PageSiteActionEnum.DEFAULT, params: -1 });
    onChange();
  };

  const onEdit = (pageSiteId: number) => {
    setAction({ action: PageSiteActionEnum.EDIT, params: pageSiteId });
  };

  const editMetadata = (lessonId: number) => {
    setAction({ action: PageSiteActionEnum.EDIT_METADATA, params: lessonId });
  };

  const removePageSite = (lessonId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('page_site.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deletePageSite(lessonId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('page_site.warning.remove_success'),
                description: t('page_site.warning.remove_success_message'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('page_site.warning.remove_error'),
                description: t('page_site.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  return (
    <>
      <Dropdown
        overlayClassName={'item-action-menu'}
        menu={{
          items: [
            {
              key: PageSiteActionEnum.EDIT,
              icon: IconUtils.lesson.edit,
              label: t('page_site.actions.edit'),
              onClick: () => onEdit(item.pageSiteId),
            },

            {
              key: PageSiteActionEnum.EDIT_METADATA,
              icon: IconUtils.lesson.edit_metadata,
              label: t('page_site.actions.edit_metadata'),
              onClick: () => editMetadata(item.lesson.lessonId),
            },

            {
              key: PageSiteActionEnum.REMOVE,
              icon: IconUtils.lesson.remove,
              label: t('page_site.actions.remove'),
              onClick: () => removePageSite(item.pageSiteId),
            },
            {
              key: 'divider-1',
              type: 'divider',
            },
            {
              key: PageSiteActionEnum.PUBLISH,
              icon: IconUtils.actions.publish,
              label: (
                <NavLink
                  href={SiteMap.page_content.publish_static_page_gen(
                    item.pageSiteId
                  )}
                >
                  {t('page_site.actions.publish')}
                </NavLink>
              ),
            },
          ],
        }}
        trigger={['click']}
        placement={'bottom'}
      >
        <Button type="default" shape="circle" icon={IconUtils.more_icon} />
      </Dropdown>

      <PageSiteActionsHandle action={action} onCancel={handleOnCancel} />
    </>
  );
};
