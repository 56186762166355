import {
  ContentAction,
  ContentActionEnum,
  ContentOperationEnum,
} from '@cms/comps/content/ContentViewerComp';
import React from 'react';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { CompMode } from '@cms/ComponentInteface';
import { useResourceContext } from '@cms/context/ResourceContextProvider';

const OptionEditable = (props: {
  type: AssetsTypeEnum;
  disabled?: {
    addOption: boolean;
    removeOption: boolean;
  };
  contentTypes?: ContentActionEnum[];
  onChangeType: (newType: AssetsTypeEnum) => void;
  onOperation: (operation: ContentOperationEnum) => void;
  children: any;
}) => {
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    return (
      <ContentAction
        type={props.type}
        disabled={props.disabled}
        actions={
          props.contentTypes
            ? props.contentTypes
            : [
                ContentActionEnum.CHANGE_TO_TEXT,
                ContentActionEnum.CHANGE_TO_IMAGE,
                ContentActionEnum.CHANGE_TO_AUDIO,
                ContentActionEnum.CHANGE_TO_VIDEO,
              ]
        }
        operations={[ContentOperationEnum.ADD, ContentOperationEnum.REMOVE]}
        onChangeType={props.onChangeType}
        onClick={props.onOperation}
      >
        {props.children}
      </ContentAction>
    );
  } else {
    return props.children;
  }
};

export default OptionEditable;

export const ChangeOptionEditable = (props: {
  type: AssetsTypeEnum;
  contentTypes?: ContentActionEnum[] | null;
  onChangeType: (newType: AssetsTypeEnum) => void;
  children: any;
}) => {
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    return (
      <ContentAction
        type={props.type}
        actions={
          props.contentTypes
            ? props.contentTypes
            : [
                ContentActionEnum.CHANGE_TO_TEXT,
                ContentActionEnum.CHANGE_TO_IMAGE,
                ContentActionEnum.CHANGE_TO_AUDIO,
                ContentActionEnum.CHANGE_TO_VIDEO,
              ]
        }
        onChangeType={props.onChangeType}
      >
        {props.children}
      </ContentAction>
    );
  } else {
    return props.children;
  }
};

export const AddGroupOptionEditable = (props: {
  disabled?: { addOption: boolean; removeOption: boolean };
  onOperation: (operation: ContentOperationEnum) => void;
  children: any;
}) => {
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    return (
      <ContentAction
        disabled={props.disabled}
        operations={[ContentOperationEnum.ADD, ContentOperationEnum.REMOVE]}
        onClick={props.onOperation}
      >
        {props.children}
      </ContentAction>
    );
  } else {
    return props.children;
  }
};
