import React, { ReactNode, useState } from 'react';
import { ContainerRatio } from '@components/Layout/ContainerScale';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';

export const ViewExerciseWrapper = (props: {
  className?: string;
  ratio: { width: number; height: number }; //height/ width
  minFontSize: number; // font size if width is 1000px
  children: ReactNode;
}) => {
  const { setting } = useLessonTemplateContext();

  const defaultFontSize = setting.fontSizeSetting
    ? setting.fontSizeSetting.default
    : 18;

  const [fontSize, setFontSize] = useState(defaultFontSize);

  const handleOnContainerChange = (width: number) => {
    const newFontSize = (width * defaultFontSize) / 1000;
    setFontSize(
      newFontSize > props.minFontSize ? newFontSize : props.minFontSize
    );
  };

  return (
    <ContainerRatio
      className={props.className}
      ratio={props.ratio.width + '/' + props.ratio.height}
      onChange={handleOnContainerChange}
      style={{ fontSize: fontSize }}
    >
      {props.children}
    </ContainerRatio>
  );
};
