import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import PublicHeader from '@components/template/public-layout/header/PublicHeader';
import PublicFooter from './footer/PublicFooter';
import PublicContent from '@components/template/public-layout/content/PublicContent';
import styled from 'styled-components';
import useMediaQuery from '@hooks/useMedia';

import useLoadSchoolSetting from '@hooks/useLoadSchoolSetting';
import useLogin from '@hooks/useLogin';
import { CookieConsent } from '@components/template/common/CookieConsent';

const PublicLayout = (props: { className?: string; children: ReactNode }) => {
  const screen = useMediaQuery();

  useLogin(false);
  useLoadSchoolSetting(false);

  return (
    <PublicLayoutStyle
      className={`gstudy-public-layout 
      ${props.className ?? ''} screen-${screen}`}
    >
      <PublicHeader />
      <PublicContent>{props.children}</PublicContent>
      <PublicFooter />
      <CookieConsent />
    </PublicLayoutStyle>
  );
};

export default PublicLayout;

const PublicLayoutStyle = styled(Layout)`
  background: #fff;
  min-height: 100vh;

  .site-layout {
    flex-grow: 1;
  }

  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer {
    padding: 0px 10px;
  }

  &.screen-xs {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 10px;
    }
  }

  &.screen-sm {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 12px;
    }
  }

  &.screen-md {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 16px;
    }
  }

  &.screen-lg {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 24px;
    }
  }

  &.screen-xl {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 32px;
    }
  }

  &.screen-xxl {
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
      padding: 0px 48px;
    }
  }

  .ant-layout-content {
  }
`;
