import React from 'react';
import styled from 'styled-components';
import SearchProduct from '@modules/public/components/filter-product/SearchProduct';

const FindCoursePage = () => {
  return (
    <FindProductPageStyle className={'container-fruid'}>
      <SearchProduct type={'courses'} />
    </FindProductPageStyle>
  );
};

export default FindCoursePage;

const FindProductPageStyle = styled.div`
  font-size: 16px;
  padding: 50px 0px;
`;
