export interface ThemeAppColor {
  primary: string;
  primaryBgr: string;
  primaryText: string;

  secondary: string;
  secondaryBgr: string;

  background: string;
}

export interface ThemeMenuColor {
  primary: string;
  primaryBgr: string;
  primaryText: string;

  secondary: string;
  secondaryBgr: string;
  secondaryText: string;
}

export interface ThemeEventColor {
  pass: string;
  passBgr: string;
  today: string;
  todayBgr: string;
  future: string;
  futureBgr: string;
}

export interface ThemeStatusColor {
  activated: string;
  activatedBgr: string;

  inactivated: string;
  inactivatedBgr: string;

  removed: string;
  removedBgr: string;

  pending: string;
  pendingBgr: string;

  published: string;
  publishedBgr: string;
}

export interface ThemeWarningColor {
  info: string;
  success: string;
  warning: string;
  error: string;
  errorBgr: string;
}

export interface ThemeExerciseColor {
  pending: string;
  active: string;
  expired: string;
  completed: string;

  review: string;
  save: string;
  submit: string;
  exit: string;
  exitBgr: string;

  needGrading: string;
}

export interface ThemeComponentColor {
  primary: string;
  primaryBgr: string;

  secondary: string;
  secondaryBgr: string;

  disabled: string;
  correct: string;
  correctBgr: string;

  incorrect: string;
  incorrectBgr: string;

  notStart: string;
  notStartBgr: string;

  skip: string;
  skipBgr: string;

  needGrading: string;
  needGradingBgr: string;

  correctAnswer: string;
  correctAnswerBgr: string;
}

export const appColors: ThemeAppColor = {
  primary: '#884ffb',
  primaryBgr: '#e2d8f9',
  primaryText: '#FFF',

  secondary: '#212529',
  secondaryBgr: '#000',

  background: '#ebecf0',
};

export const menuColors: ThemeMenuColor = {
  primary: '#884ffb',
  primaryBgr: '#e2d8f9',
  primaryText: '#884ffb',

  secondary: '#0474BB',
  secondaryBgr: '#e2d8f9',
  secondaryText: '#333',
};

export const warningColors: ThemeWarningColor = {
  info: '#0474BB',
  success: '#00A14B',
  warning: '#f58220',

  error: '#ED1F24',
  errorBgr: '#f9d0d0',
};

export const eventColors: ThemeEventColor = {
  pass: '#e2d8f9',
  passBgr: '#e2d8f9',
  today: '#884ffb',
  todayBgr: '#e2d8f9',
  future: '#0474BB',
  futureBgr: '#0474BB',
};

export const statusColors: ThemeStatusColor = {
  activated: '#0474BB',
  activatedBgr: '#0474BB',

  inactivated: '#CCC',
  inactivatedBgr: '#CCC',

  removed: '#ED1F24',
  removedBgr: '#ED1F24',

  pending: '#f58220',
  pendingBgr: '#f58220',

  published: '#884ffb',
  publishedBgr: '#884ffb',
};

export const exerciseColors: ThemeExerciseColor = {
  pending: '#0474BB',
  active: '#0474BB',
  expired: '#ED1F24',
  completed: '#00A14B',

  review: '#f58220',
  save: '#0474BB',
  submit: '#00A14B',
  exit: '#333',
  exitBgr: '#FFF',

  needGrading: '#e2d8f9',
};

// check_answer: '#00A14B',
//     view_report: '#f58220',
//
//     save: '#0474BB',
//     submit: '#00A14B',
//     review: '#f58220',
//
//     exit_grading: '#333',
//     exit_grading_text: '#FFF',
//
//     need_grading_bgr: '#e2d8f9',

export const componentColors: ThemeComponentColor = {
  primary: '#0474BB',
  primaryBgr: '#cde3f6',

  secondary: '#ccc',
  secondaryBgr: '#f5f5f5',

  disabled: '#ccc',

  correct: '#00A14B',
  correctBgr: '#00A14B',

  incorrect: '#ED1F24',
  incorrectBgr: '#f9d0d0',

  notStart: '#ccc',
  notStartBgr: '#ccc',

  skip: '#f58220',
  skipBgr: '#f58220',

  needGrading: '#0474BB',
  needGradingBgr: '#0474BB',

  correctAnswer: '#d424ff',
  correctAnswerBgr: 'rgba(212, 36, 255, 0.3)',
};
