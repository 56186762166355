import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useTranslation } from 'react-i18next';

import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import { ButtonProps } from 'antd';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';

export const NextQuestionButton = ({ onClick, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  const { config } = useLessonTemplateContext();

  const { triggerBeforeNavigate } = useViewLessonContext();

  const { resource, updateNavigation } = useLessonNavigationContext();

  const handleNextQuestion = (e: any) => {
    triggerBeforeNavigate();

    updateNavigation(LessonNavigationAction.NEXT_QUESTION, {
      resourceId: resource.nextResourceId,
      type: LessonNavigationType.refresh,
    });

    if (onClick) {
      onClick(e);
    }
  };

  if (config.layout === LessonTemplateLayoutEnum.presentation) {
    return (
      <LessonButton
        className={'next-question-button'}
        shape={'circle'}
        size={'large'}
        onClick={handleNextQuestion}
        disabled={resource.nextResourceId < 0}
        icon={<RightOutlined />}
        {...props}
      />
    );
  } else {
    return (
      <LessonButton
        className={'next-question-button'}
        shape={'round'}
        type={'primary'}
        onClick={handleNextQuestion}
        disabled={resource.nextResourceId < 0}
        {...props}
      >
        {t('exercise.button.next')} <RightOutlined />
      </LessonButton>
    );
  }
};
