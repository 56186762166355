import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { LessonMetadataComp } from './modal/LessonMetadataComp';
import { LessonStandardComp } from './modal/LessonStandardComp';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { LessonTypeEnum } from '@modules/admin/service/model';

export enum UpdateLessonDataEnum {
  REVIEW = 'review',
  METADATA = 'metadata',
  STANDARDS = 'standards',
  SHARE = 'share',
  EXTRACT = 'extract-json',
  DEFAULT = '',
}

export default function UpdateLessonDataModal(props: {
  type: 'edit' | 'review';
  lessonType?: LessonTypeEnum;
  action: UpdateLessonDataEnum;
  lessonId: number;
  onCancel: () => void;
  onChange: (action: UpdateLessonDataEnum) => void;
}) {
  const { t } = useTranslation();
  const proUser = useProUser();

  return (
    <UpdateLessonDataModalStyle>
      {proUser ? (
        <SwitchCondition
          conditions={[
            {
              condition: props.lessonType === LessonTypeEnum.page,
              children: (
                <LessonMetadataComp
                  lessonId={props.lessonId}
                  onCancel={props.onCancel}
                />
              ),
            },
            {
              condition: props.lessonType === LessonTypeEnum.template,
              children: (
                <LessonMetadataComp
                  lessonId={props.lessonId}
                  onCancel={props.onCancel}
                />
              ),
            },
            {
              condition:
                props.lessonType == null ||
                props.lessonType === LessonTypeEnum.lesson ||
                props.lessonType === LessonTypeEnum.collection,
              children: (
                <ResponsiveTabs
                  onChange={(activeTab) =>
                    props.onChange(activeTab as UpdateLessonDataEnum)
                  }
                  items={[
                    {
                      key: UpdateLessonDataEnum.METADATA,
                      label: (
                        <>
                          {IconUtils.lesson.edit_metadata}
                          {t('lesson.actions.update_metadata')}
                        </>
                      ),
                      children: (
                        <LessonMetadataComp
                          lessonId={props.lessonId}
                          onCancel={props.onCancel}
                        />
                      ),
                    },
                    {
                      key: UpdateLessonDataEnum.STANDARDS,
                      label: (
                        <>
                          {IconUtils.lesson.standards}
                          {t('lesson.actions.view_standards')}
                        </>
                      ),
                      children: (
                        <LessonStandardComp
                          lessonId={props.lessonId}
                          onCancel={props.onCancel}
                        />
                      ),
                    },
                  ]}
                  activeKey={props.action}
                />
              ),
            },
          ]}
        />
      ) : (
        <LessonMetadataComp
          lessonId={props.lessonId}
          onCancel={props.onCancel}
        />
      )}
    </UpdateLessonDataModalStyle>
  );
}

const UpdateLessonDataModalStyle = styled.div``;
