import { IconUtils } from '@utils/IconUtils';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import React, { ReactNode, useEffect, useState } from 'react';
import { useViewExercise } from '@cms/lesson-template/context/ViewExerciseContext';
import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import styled from 'styled-components';
import { ClockCircleOutlined } from '@ant-design/icons';
import Button from '@components/button';

interface TimeProps {
  hour: number;
  minute: number;
  second: number;
}

enum WarningTimeoutType {
  empty = '',
  lt_one_min = 'less-than-1-min',
  lt_five_min = 'remain-5-mins',
  lt_ten_min = 'remain-10-mins',
  lt_fifteen_min = 'remain-15-mins',
}
export const RemainTimeBtn = () => {
  const { exercise } = useViewExercise();
  const { dispatchExerciseAction } = useViewLessonContext();

  const [expiredInMs, setExpiredInMs] = useState<number | null>(null);
  const [timeRemain, setTimeRemain] = useState<TimeProps | null>(null);
  const [warning, setWarning] = useState(WarningTimeoutType.empty);
  const [excludeWarning, setExcludeWarning] = useState<WarningTimeoutType[]>(
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      handleTimeOut();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (exercise.expiredInMs != null && exercise.expiredInMs > 0) {
      setExpiredInMs(exercise.expiredInMs);
    } else {
      setExpiredInMs(null);
    }
  }, [exercise.expiredInMs, exercise.unlockInMs]);

  useEffect(() => {
    if (expiredInMs != null) {
      const total_second = Math.round(expiredInMs / 1000);
      const second = total_second % 60;
      const minutes = (total_second - second) / 60;
      const minute = minutes % 60;
      const hour = (minutes - minute) / 60;

      setTimeRemain({ hour: hour, minute: minute, second });

      if (total_second <= 0) {
        // time out
        dispatchExerciseAction(ExerciseActionEnum.submit, true);
      } else if (total_second < 60) {
        setWarning(WarningTimeoutType.lt_one_min);
      } else if (total_second < 5 * 60) {
        setWarning(WarningTimeoutType.lt_five_min);
      } else if (total_second < 10 * 60) {
        setWarning(WarningTimeoutType.lt_ten_min);
      } else if (total_second < 15 * 60) {
        setWarning(WarningTimeoutType.lt_fifteen_min);
      }
    } else {
      setTimeRemain(null);
    }
  }, [expiredInMs]);

  const handleTimeOut = () => {
    setExpiredInMs((prev) => {
      return prev != null ? prev - 1000 : prev;
    });
  };

  const handleOnClose = (type: WarningTimeoutType) => {
    setWarning(WarningTimeoutType.empty);
    setExcludeWarning((prev) => {
      return [...prev, type];
    });
  };

  return (
    <>
      {timeRemain != null && (
        <>
          <LessonButton
            className={warning + '-btn'}
            type={'default'}
            shape={'round'}
            icon={<ClockCircleOutlined />}
            style={{ marginLeft: '2em' }}
          >
            <RemainTimeLabel>
              {timeRemain.hour > 0 && (
                <DisplayTimeValue value={timeRemain.hour} type={'hour'} />
              )}

              <DisplayTimeValue value={timeRemain.minute} type={'minute'} />
              <DisplayTimeValue value={timeRemain.second} type={'second'} />
            </RemainTimeLabel>
          </LessonButton>

          {warning === WarningTimeoutType.lt_fifteen_min &&
            !excludeWarning.includes(warning) && (
              <WarningTimeLeft type={warning} onClose={handleOnClose}>
                Remain {timeRemain.minute} minutes
              </WarningTimeLeft>
            )}

          {warning === WarningTimeoutType.lt_ten_min &&
            !excludeWarning.includes(warning) && (
              <WarningTimeLeft type={warning} onClose={handleOnClose}>
                Remain {timeRemain.minute} minutes. The assignment will auto
                submit when time left.
              </WarningTimeLeft>
            )}

          {warning === WarningTimeoutType.lt_five_min &&
            !excludeWarning.includes(warning) && (
              <WarningTimeLeft type={warning} onClose={handleOnClose}>
                Remain {timeRemain.minute} minutes. The assignment will auto
                submit when time left.
              </WarningTimeLeft>
            )}

          {warning === WarningTimeoutType.lt_one_min &&
            !excludeWarning.includes(warning) && (
              <WarningTimeLeft type={warning} onClose={handleOnClose}>
                Assignment will be close soon. Please recheck and make sure that
                you answered all question.
              </WarningTimeLeft>
            )}
        </>
      )}
    </>
  );
};

const WarningTimeLeft = (props: {
  type: WarningTimeoutType;
  children: ReactNode;
  onClose: (type: WarningTimeoutType) => void;
}) => {
  return (
    <WarningTimeLeftStyle className={`waring-time-left ${props.type}`}>
      <Button
        type={'text'}
        icon={IconUtils.close}
        onClick={() => props.onClose(props.type)}
      />
      {props.children}
    </WarningTimeLeftStyle>
  );
};

const DisplayTimeValue = (props: {
  value: number;
  type: 'hour' | 'minute' | 'second';
}) => {
  return (
    <>
      <span className={'time-label'}>
        {props.value < 10 ? '0' : ''}
        {props.value}
      </span>
      <span className={'time-separator'}>
        {props.type === 'hour' || props.type === 'minute' ? ':' : ''}
      </span>
    </>
  );
};

const WarningTimeLeftStyle = styled.div`
  position: fixed;
  z-index: 9;
  padding: 1em 3em 1em 1em;
  right: 1em;
  top: 70px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 16px;
  line-height: 1.6;
  max-width: 400px;

  &.less-than-1-min {
    color: ${(props) => props.theme.warning.error};
  }

  &.remain-5-mins {
    color: ${(props) => props.theme.warning.warning};
  }

  &.remain-10-mins {
    color: ${(props) => props.theme.warning.info};
  }

  &.remain-15-mins {
    color: ${(props) => props.theme.warning.success};
  }

  .ant-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const RemainTimeLabel = styled.span`
  display: inline-flex;
  align-items: center;
  min-width: 60px;

  > span {
    display: inline-flex;
    align-items: center;

    &.time-label {
      font-weight: 600;
    }
  }
`;
