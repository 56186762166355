import { collectExerciseAnswer } from '@cms/lesson-template/components/toolbar/handler/collectExerciseAnswer';
import { notification } from 'antd';
import { useSelector } from '@app/redux/hook';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import { ExerciseServiceContext } from './ExerciseServiceContext';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { SavePageAnswerReq } from '@services/model/survey';
import { SiteMap } from '@router/SiteMap';
import { useRouter } from '@hooks/useRouter';

export const PageServiceProvider = (props: {
  lessonId: number;
  children: ReactNode;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [savePageResponse, { isLoading }] =
    PublicPageAPI.endpoints.savePageResponse.useMutation();

  const { currentAnswer } = useSelector((state) => state.viewResource);

  const onSave = (callBack: (res?: ExerciseRes) => void) => {
    callBack();
  };

  const handleSubmit = () => {
    router.push(SiteMap.index);
  };

  const onSubmit = () => {
    const request: SavePageAnswerReq = {
      lessonId: props.lessonId,
      answers: collectExerciseAnswer(currentAnswer),
    };

    savePageResponse(request)
      .unwrap()
      .then((res) => {
        if (res.success) {
          notification.success({
            message: t('page_site.warning.submit_success'),
            description: t('page_site.warning.submit_success_message'),
            placement: 'bottomRight',
          });
          handleSubmit();
        } else {
          notification.error({
            message: t('page_site.warning.submit_error'),
            description: t('page_site.warning.submit_error_message'),
            placement: 'bottomRight',
          });
        }
      })
      .catch(() => {
        notification.error({
          message: t('page_site.warning.submit_error'),
          description: t('page_site.warning.submit_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const onGrading = (callBack: (res: ExerciseRes) => void) => {
    console.log(callBack);
  };

  return (
    <ExerciseServiceContext.Provider
      value={{
        isLoading: isLoading,
        params: props.lessonId,
        lessonId: props.lessonId,
        exerciseId: -1,

        viewStatus: () => {},

        onSave: onSave,
        onSubmit: onSubmit,
        onGrading: onGrading,

        viewScores: () => {},
      }}
    >
      {props.children}
    </ExerciseServiceContext.Provider>
  );
};
