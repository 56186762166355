import { H4 } from '@components/typography';
import { LessonQuestions } from '@cms/lesson-template/compose/sections/components/LessonQuestion';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { max_bank_size } from '@cms/lesson-template/compose/layout';
import styled from 'styled-components';

export const EmptySectionQuestion = (props: {
  requireRefresh: number;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <EmptySectionQuestionStyle
      className={'compose-lesson-bank'}
      style={{ width: max_bank_size }}
    >
      <div className={'section-question-header'}>
        <H4 style={{ marginBottom: 0 }}>
          {t('my_lesson.actions.select_a_content')}
        </H4>
      </div>

      <div className={'section-question-body'}>
        <LessonQuestions requireRefresh={props.requireRefresh} />
      </div>

      <div className={'section-question-footer'}>
        <ButtonGroup type={'center'}>
          <Button shape={'round'} danger onClick={props.onClose}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </div>
    </EmptySectionQuestionStyle>
  );
};

const EmptySectionQuestionStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  z-index: 999;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  //min-height: 100vh;
  //max-height: 100vh;

  .section-question-header {
    padding-bottom: 10px;
  }

  .section-question-body {
    flex-grow: 1;
    overflow-y: auto;

    .draggable-item {
      border: 1px solid #ccc;
      background: #fff;
      margin-bottom: 10px;
      padding: 10px;
    }
  }

  .section-question-footer {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
  }
`;
