import styled from 'styled-components';

export const HintText = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.color.black};
  margin-bottom: 6px;

  &:before {
    content: '* ';
  }
`;
