import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import en from './locales/en.json';
import vi from './locales/vi.json';
import { ReactNode } from 'react';
import { getDefaultLanguage } from '@app/redux/slices/appLanguageSlice';

i18next.init({
  interpolation: { escapeValue: false },
  lng: getDefaultLanguage(),
  resources: {
    en: {
      translation: en,
    },
    vi: {
      translation: vi,
    },
  },
});

export const I18nProvider = (props: { children: ReactNode }) => {
  return <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>;
};
