import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import styled from 'styled-components';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { connect } from 'formik';
import { get as getPath } from 'lodash';
import { ErrMessageStyle } from '@components/input/style';
import { LessonTypeEnum } from '@modules/admin/service/model';

const SelectLessonExample = (props: {
  category: LessonTypeEnum;
  formik?: any;
  name: string;
  value: number;
  disabled?: boolean;
  onChange: (lessonId: number) => void;
}) => {
  const { t } = useTranslation();

  const [viewTemplates, { data, isSuccess }] =
    LessonExampleAPI.endpoints.viewTemplates.useLazyQuery({});

  useEffect(() => {
    viewTemplates(props.category ? props.category : LessonTypeEnum.lesson);
  }, [props.category]);

  useEffect(() => {
    if (data && isSuccess && data.length > 0) {
      if (props.value == null || props.value < 0) {
        props.onChange(data[0].lessonExampleId);
      }
    }
  }, [props.value, data]);

  const handleSelectData = (lessonExampleId: number) => {
    if (props.formik != null && (props.disabled == null || !props.disabled)) {
      props.formik.setFieldValue(props.name, lessonExampleId);
    } else if (props.onChange != null) {
      props.onChange(lessonExampleId);
    }
  };

  return (
    <InputGroup label={t('my_lesson.label.example')}>
      <SelectLessonExampleStyle>
        {data &&
          isSuccess &&
          data.map((res) => {
            return (
              <div
                key={res.lessonExampleId}
                className={`view-lesson-icon 
              ${props.value === res.lessonExampleId ? 'selected' : ''}
              `}
                onClick={() => handleSelectData(res.lessonExampleId)}
              >
                <ThumbnailsImg
                  type={'icon'}
                  src={res.lesson.thumbnails}
                  ratio={ImageScale.lesson.ratio}
                />

                <span className={'lesson-name'}>
                  <LanguageContentTag content={res.description} />
                </span>
              </div>
            );
          })}
      </SelectLessonExampleStyle>

      {getPath(props.formik?.errors, props.name) &&
        getPath(props.formik?.touched, props.name) && (
          <ErrMessageStyle className="error-message">
            {getPath(props.formik?.errors, props.name)}
          </ErrMessageStyle>
        )}
    </InputGroup>
  );
};

export default connect(SelectLessonExample);

const SelectLessonExampleStyle = styled.div`
  gap: 1em;
  display: flex;
  flex-wrap: wrap;

  .view-lesson-icon {
    width: 200px;
    border: 1px solid #ccc;
    display: flex;
    position: relative;
    cursor: pointer;

    &:hover {
      border: 1px solid #6366f1;
      outline: 1px solid #6366f1;
      background: var(--primary-background-color, #e2d8f9);
    }

    &.selected {
      border: 1px solid #6366f1;
      outline: 1px solid #6366f1;
      background: var(--primary-background-color, #e2d8f9);
    }

    .lesson-name {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 0.5em;
      font-size: 16px;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.125);
    }
  }
`;
