import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';

export interface EmailSettingRes {
  emailSettingId: number;

  apikey: string;
  host: string;
  port: string;

  username: string;
  password: string;

  fromName: string;
}

export interface EmailSettingReq {
  apikey: string;
  host: string;
  port: string;

  username: string;
  password: string;

  fromName: string;
}

export const EmailSettingAPI = createApi({
  reducerPath: 'EmailSettingAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getEmailSetting: builder.query<EmailSettingRes, unknown>({
      query: () => ({
        url: `/email-setting`,
      }),
    }),

    update: builder.mutation<BasicMessageRes, EmailSettingReq>({
      query: (body: EmailSettingReq) => ({
        url: '/email-setting',
        method: 'PUT',
        body,
      }),
    }),
  }),
});
