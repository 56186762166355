import React, { useEffect, useState } from 'react';

import { Button, Dropdown, notification, Select, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';

import { LessonTypeAPI } from '@modules/admin/service/LessonTypeAPI';
import { InputGroup } from '@components/input/InputGroup';
import { EntityStatusEnum } from '@services/model/common';
import { LanguageContentInput } from '@components/editor/LanguageContentInput';
import { EntityStatus } from '@components/status/EntityStatus';
import { Default_Gutter } from '@components/grid';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IconUtils } from '@utils/IconUtils';
import {
  CreateLessonTypeReq,
  LessonTypeEnum,
  LessonTypeRes,
} from '@modules/admin/service/model';

const initialValue: CreateLessonTypeReq = {
  lessonTypeId: -1,
  name: LessonTypeEnum.lesson,
  description: '',
  displayOrder: 1,
  status: EntityStatusEnum.ACTIVE,
};

export function LessonTypeEditForm(props: {
  lessonTypeId?: number;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isLoading }] =
    LessonTypeAPI.endpoints.findById.useLazyQuery();

  const [createLessonType, { isLoading: isCreateLoading }] =
    LessonTypeAPI.endpoints.createLessonType.useMutation({});

  const [updateLessonType, { isLoading: isUpdateLoading }] =
    LessonTypeAPI.endpoints.updateLessonType.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.lessonTypeId != null && props.lessonTypeId > 0) {
      findById(props.lessonTypeId);
    } else {
      setIsReady(true);
    }
  }, [props.lessonTypeId]);

  useEffect(() => {
    if (data && isSuccess) {
      setFormData(data);
      setIsReady(true);
    }
  }, [data]);

  const setFormData = (data: LessonTypeRes) => {
    formik.setValues({
      lessonTypeId: data.lessonTypeId,
      name: data.name,
      description: data.description,
      displayOrder: data.displayOrder,
      status: data.status,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      description: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.lessonTypeId > 0) {
        updateLessonType(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        createLessonType(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });
            props.successHandle();
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const changeLessonType = (type: LessonTypeEnum) => {
    formik.setFieldValue('name', type);
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <InputGroup label={t('label.name')}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'space-between',
                  }}
                >
                  <Input
                    name="name"
                    type="text"
                    placeholder={t('label.name')}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <LessonTypeHelperDropdown onChange={changeLessonType} />
                </div>
              </InputGroup>

              <InputGroup label={t('label.status')}>
                <Select
                  size={'large'}
                  placeholder={t('label.status')}
                  onChange={(value) => formik.setFieldValue('status', value)}
                  value={formik.values.status}
                  style={{ width: '100%' }}
                >
                  <Select.Option
                    key={EntityStatusEnum.ACTIVE}
                    value={EntityStatusEnum.ACTIVE}
                  >
                    <EntityStatus status={EntityStatusEnum.ACTIVE} />
                  </Select.Option>

                  <Select.Option
                    key={EntityStatusEnum.INACTIVE}
                    value={EntityStatusEnum.INACTIVE}
                  >
                    <EntityStatus status={EntityStatusEnum.INACTIVE} />
                  </Select.Option>

                  <Select.Option
                    key={EntityStatusEnum.REMOVED}
                    value={EntityStatusEnum.REMOVED}
                  >
                    <EntityStatus status={EntityStatusEnum.REMOVED} />
                  </Select.Option>

                  <Select.Option
                    key={EntityStatusEnum.PUBLISHED}
                    value={EntityStatusEnum.PUBLISHED}
                  >
                    <EntityStatus status={EntityStatusEnum.PUBLISHED} />
                  </Select.Option>
                </Select>
              </InputGroup>

              <LanguageContentInput
                row={2}
                maxLength={512}
                initValue={data ? data.description : ''}
                onChange={(val) => formik.setFieldValue('description', val)}
              />

              <Input
                name="displayOrder"
                type="text"
                label={t('label.displayOrder')}
                placeholder={t('label.displayOrder')}
                onChange={formik.handleChange}
                value={formik.values.displayOrder}
              />

              <ButtonGroup className="submit-container" type={'right'}>
                <Button
                  type="default"
                  size={'large'}
                  shape={'round'}
                  onClick={props.onCloseFunc}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  size={'large'}
                  shape={'round'}
                  icon={IconUtils.actions.save}
                  loading={isLoading || isUpdateLoading || isCreateLoading}
                  onClick={formik.submitForm}
                >
                  {props.lessonTypeId != null && props.lessonTypeId > 0
                    ? t('button.update')
                    : t('button.create')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}

const LessonTypeHelperDropdown = (props: {
  onChange: (type: LessonTypeEnum) => void;
}) => {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: LessonTypeEnum.lesson,
            label: (
              <div onClick={() => props.onChange(LessonTypeEnum.lesson)}>
                Lesson Type. Resource is a lesson. It's an exercise or
                practice....
              </div>
            ),
          },

          {
            key: LessonTypeEnum.game,
            label: (
              <div onClick={() => props.onChange(LessonTypeEnum.game)}>
                Glossary game type - Resource is a glossary game.
              </div>
            ),
          },

          {
            key: LessonTypeEnum.presentation,
            label: (
              <div onClick={() => props.onChange(LessonTypeEnum.presentation)}>
                Presentation - Resource is a presentation (Create in ClassRoom)
              </div>
            ),
          },
          {
            key: LessonTypeEnum.page,
            label: (
              <div onClick={() => props.onChange(LessonTypeEnum.page)}>
                Page type - This is static page designed by school admin /
                Support Admin
              </div>
            ),
          },

          {
            key: LessonTypeEnum.survey,
            label: (
              <div onClick={() => props.onChange(LessonTypeEnum.survey)}>
                Survey Type. Sent to any user and get their feedback.
              </div>
            ),
          },
        ],
      }}
      placement="topLeft"
      arrow
    >
      <Button icon={<QuestionCircleOutlined />} />
    </Dropdown>
  );
};
