import React from 'react';

import ParentRegistration from '@modules/authorization/pages/RegisterParent';
import { useSelector } from 'react-redux';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { RootState } from '@app/redux/store';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const RegisterParent = () => {
  const { t } = useTranslation();

  const { allowParentRegister } = useSelector(
    (state: RootState) => state.appSetting
  );

  return (
    <PublicLayout className={'register-parent-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('registration.actions.parent')}
        description={t('registration.actions.parent')}
      />

      <ParentRegistration disabled={!allowParentRegister} />
    </PublicLayout>
  );
};

export default RegisterParent;
