import React, { useState } from 'react';
import styled from 'styled-components';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import { Button, Dropdown, Space, Typography } from 'antd';
import { UpdateLessonDataEnum } from '@modules/product/components/lesson/lesson-data/LessonDataModal';
import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { IconUtils } from '@utils/IconUtils';
import { ComposingWithStatus } from '@components/common/lesson/ComposingStatus';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { LessonRes } from '@modules/product/services/lesson_model';

const { Text } = Typography;

export const LessonInfoDraggable = (props: {
  selected: boolean;
  item: LessonRes;
}) => {
  const { t } = useTranslation();

  const [mode, changeToMode] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  return (
    <LessonInfoDraggableStyle>
      <DropItem
        acceptType={DragItemTypeEnum.LESSON_ITEM}
        droppableId={props.item.lessonId + ''}
        className={'product-toc-lessons'}
      >
        <DragItem
          className={`lesson-info-draggable ${
            props.selected ? 'selected' : ''
          }`}
          acceptType={DragItemTypeEnum.LESSON_ITEM}
          draggableId={props.item.lessonId + ''}
          index={0}
          key={props.item.lessonId}
        >
          <div className={'lesson-title'}>
            <Space>
              <ThumbnailsImg
                type={'icon'}
                src={props.item.thumbnails}
                ratio={ImageScale.product.ratio}
                width={ImageScale.product.icon}
              />

              <ComposingWithStatus status={props.item.status}>
                {props.item.name}
              </ComposingWithStatus>
            </Space>
          </div>

          <div className={'lesson-action'}>
            <Button
              size={'small'}
              type={'default'}
              shape={'round'}
              onClick={() => changeToMode(UpdateLessonDataEnum.REVIEW)}
              title={t('lesson.actions.view')}
              icon={IconUtils.lesson.review}
            >
              {t('label.view')}
            </Button>
          </div>
        </DragItem>
      </DropItem>

      {mode === UpdateLessonDataEnum.REVIEW && (
        <CustomModal
          header={t('lesson.actions.review')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={props.item.lessonId} />}
          onCloseFunc={() => {
            changeToMode(UpdateLessonDataEnum.DEFAULT);
          }}
        />
      )}
    </LessonInfoDraggableStyle>
  );
};

const LessonClickableStyle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LessonClickable = (props: {
  selected: boolean;
  item: LessonRes;
  onSelect?: (lessonData: LessonRes) => void;
  onRemove?: (lessonId: number) => void;
}) => {
  const { t } = useTranslation();

  const [mode, changeToMode] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  const handleOnSelect = () => {
    if (props.onSelect) {
      props.onSelect(props.item);
    }
  };

  const handleOnRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.item.lessonId);
    }
  };

  return (
    <>
      <LessonClickableStyle>
        <div className={'lesson-title'}>
          <Space>
            <ThumbnailsImg
              type={'icon'}
              src={props.item.thumbnails}
              ratio={ImageScale.product.ratio}
              width={ImageScale.product.icon}
            />

            <ComposingWithStatus status={props.item.status}>
              {props.item.name}
            </ComposingWithStatus>
          </Space>
        </div>

        <Space className={'lesson-action'}>
          <Button
            size={'small'}
            type={'default'}
            shape={'round'}
            onClick={() => changeToMode(UpdateLessonDataEnum.REVIEW)}
            title={t('lesson.actions.view')}
            icon={IconUtils.lesson.edit_metadata}
          >
            {t('label.view')}
          </Button>

          {props.selected && props.onRemove && (
            <Button
              size={'small'}
              type={'primary'}
              shape={'round'}
              danger={true}
              onClick={handleOnRemove}
              title={t('lesson.actions.remove_lesson')}
            >
              {t('label.remove')}
            </Button>
          )}

          {!props.selected && props.onSelect && (
            <Button
              size={'small'}
              type={'primary'}
              shape={'round'}
              onClick={handleOnSelect}
              title={t('lesson.actions.select_lesson')}
            >
              {t('label.select')}
            </Button>
          )}
        </Space>
      </LessonClickableStyle>

      {mode === UpdateLessonDataEnum.REVIEW && (
        <CustomModal
          header={t('lesson.actions.review')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={props.item.lessonId} />}
          onCloseFunc={() => {
            changeToMode(UpdateLessonDataEnum.DEFAULT);
          }}
        />
      )}
    </>
  );
};

export const LessonInfoStatic = (props: {
  item: LessonRes;
  onView: (lessonId: number) => void;
  onEdit: (lessonId: number) => void;
  onRemove: (lessonId: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <LessonInfoDraggableStyle className={'lesson-info-static'}>
        <div className={'lesson-title'}>
          <Space>
            <ThumbnailsImg
              type={'icon'}
              src={props.item.thumbnails}
              ratio={ImageScale.product.ratio}
              width={ImageScale.product.icon}
            />
            <span>{props.item.name}</span>
          </Space>
        </div>

        <div className={'lesson-action'}>
          <Space>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'view-lesson',
                    onClick: () => props.onView(props.item.lessonId),
                    label: (
                      <>
                        {IconUtils.lesson.review}{' '}
                        {t('table_of_content.actions.view_lesson')}
                      </>
                    ),
                  },
                  {
                    key: 'compose-lesson',
                    onClick: () => props.onEdit(props.item.lessonId),
                    label: (
                      <>
                        {IconUtils.compose_lesson}{' '}
                        {t('table_of_content.actions.compose_lesson')}
                      </>
                    ),
                  },
                  {
                    key: 'divider',
                    type: 'divider',
                  },
                  {
                    key: 'remove-lesson',
                    onClick: () => props.onRemove(props.item.lessonId),
                    label: (
                      <Text type="danger">
                        {IconUtils.actions.delete}{' '}
                        {t('table_of_content.actions.remove_lesson')}
                      </Text>
                    ),
                  },
                ],
              }}
              arrow
              placement={'bottomLeft'}
              trigger={['click']}
            >
              <Button
                shape="circle"
                type={'default'}
                icon={IconUtils.more_icon}
              />
            </Dropdown>
          </Space>
        </div>
      </LessonInfoDraggableStyle>
    </>
  );
};

const LessonInfoDraggableStyle = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid;
  padding: 0.25em 0em 0.25em 1em;
  margin-bottom: 1em;

  .droppable-container {
    width: 100%;

    .draggable-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .ant-list & {
    border: none;
    padding: 0;
    margin-bottom: 0;
  }

  .lesson-title {
    h4 {
      margin-bottom: 0px;
    }
  }
`;
