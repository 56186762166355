import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { SessionAPI } from '@services/private/SessionAPI';
import CreateSessionForm from '@modules/calendar/components/CreateSessionForm';
import SessionLessonForm from '@modules/calendar/components/SessionLessonForm';
import ShareSessionToStudentForm from '@components/form/ShareSessionToStudentForm';
import PublicSessionForm from '@modules/calendar/components/PublicSessionForm';
import RemoveSessionForm from '@modules/calendar/components/RemoveSessionForm';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { IconUtils } from '@utils/IconUtils';
import { SessionRes } from '@services/model/session';

export enum UpdateSessionAction {
  DEFAULT = '',
  METADATA = 'metadata',
  LESSON = 'lesson',
  STUDENT = 'student',
  SHARE = 'share',
  REMOVE = 'delete',
}

export default function UpdateSessionDataModal(props: {
  action: UpdateSessionAction;
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean, needReload?: boolean) => void;
  onClose: (session: SessionRes | null) => void;
  onTabChange: (title: string) => void;
}) {
  const { t } = useTranslation();

  const [action, setAction] = useState(props.action);

  const [findById, { data: sessionData }] =
    SessionAPI.endpoints.findById.useLazyQuery({});

  useEffect(() => {
    refresh();
  }, [props.sessionData.sessionId]);

  const handleClick = (data: UpdateSessionAction) => {
    refresh();
    setAction(data);
  };

  const refresh = () => {
    if (props.sessionData.sessionId > 0) {
      findById(props.sessionData.sessionId);
    }
  };

  return (
    <>
      {sessionData && (
        <ResponsiveTabs
          onChange={(type) => handleClick(type as UpdateSessionAction)}
          items={[
            {
              key: UpdateSessionAction.METADATA,
              label: (
                <>
                  {IconUtils.view_info}
                  {t('session.update')}
                </>
              ),
              children: (
                <CreateSessionForm
                  sessionData={sessionData}
                  selectedDate={''}
                  onClose={props.onClose}
                  warningResult={props.warningResult}
                  onDidMount={() => props.onTabChange(t('session.update'))}
                />
              ),
            },
            {
              key: UpdateSessionAction.STUDENT,
              label: (
                <>
                  {IconUtils.users}
                  {t('session.actions.invite_students')}
                </>
              ),
              children: (
                <ShareSessionToStudentForm
                  sessionId={sessionData.sessionId}
                  warningResult={props.warningResult}
                  onActive={() =>
                    props.onTabChange(t('session.actions.invite_students'))
                  }
                />
              ),
            },
            {
              key: UpdateSessionAction.LESSON,
              label: (
                <>
                  {IconUtils.lesson.assign}
                  {t('session.actions.share_lessons')}
                </>
              ),
              children: (
                <SessionLessonForm
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() =>
                    props.onTabChange(t('session.actions.share_lessons'))
                  }
                />
              ),
            },
            {
              key: UpdateSessionAction.SHARE,
              label: (
                <>
                  {IconUtils.lesson.publish}
                  {t('session.actions.share')}
                </>
              ),
              children: (
                <PublicSessionForm
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() => props.onTabChange(t('session.actions.share'))}
                />
              ),
            },
            {
              key: UpdateSessionAction.REMOVE,
              label: (
                <>
                  {IconUtils.actions.delete}
                  {t('session.actions.remove')}
                </>
              ),
              children: (
                <RemoveSessionForm
                  sessionData={sessionData}
                  warningResult={props.warningResult}
                  onActive={() =>
                    props.onTabChange(t('session.actions.remove'))
                  }
                />
              ),
            },
          ]}
          activeKey={action}
        />
      )}
    </>
  );
}
