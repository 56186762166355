import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { Button, Col, Dropdown, notification, Popover, Row } from 'antd';

import { DateLabel } from '@components/text/DateLabel';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1, H5 } from '@components/typography';
import TablePagination from '@components/table/TablePagination';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { OauthAppAPI, OauthAppRes } from '@services/private/OauthAppAPI';
import CreateOauthAppForm from '../containers/oauth-app/CreateOauthAppForm';
import ViewOAuthApp from '../containers/oauth-app/ViewOauthApp';
import { MenuProps } from 'antd/es/menu';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { Default_Gutter } from '@components/grid';
import { InputGroup } from '@components/input/InputGroup';
import styled from 'styled-components';
import { TooltipPlacement } from 'antd/es/tooltip';

enum OauthAppManagementAction {
  DEFAULT = '',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

const OauthAppManagementPage = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [findAll, { data, isFetching }] =
    OauthAppAPI.endpoints.findAll.useLazyQuery();

  const [deleteOauthApp] = OauthAppAPI.endpoints.delete.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'code',
    direction: 'ascend',
  });

  const [action, setAction] = useState({
    action: OauthAppManagementAction.DEFAULT,
    params: -1,
  });

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 250,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: false,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: false,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.actions'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 200,
    },
  ];

  useEffect(() => {
    findAll(params);
  }, [params]);

  useEffect(() => {
    return () => {
      setParams({
        page: 1,
        size: MAX_PAGE_SIZE,
        sort: 'name',
        direction: 'ascend',
      });
    };
  }, []);

  const handleClickMenu = (
    item: OauthAppRes,
    action: OauthAppManagementAction
  ) => {
    setAction({ action: action, params: item.oauthAppId });
  };

  const renderMobile = (item: OauthAppRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <OauthAppItemStyle className={'oauth-app-item'}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col flex={'150px'}>
              <div className={'oauth-app-info'}>
                <H5>{item.name}</H5>
                {getRecordStatus(item, 'bottom')}
              </div>
            </Col>

            <Col flex={'auto'}>
              <div className={'oauth-app-description'}>
                <InputGroup label={t('label.description')}>
                  {item.description}
                </InputGroup>

                <InputGroup label={t('label.createdDate')}>
                  <DateLabel label={item.createdDate} />
                </InputGroup>
              </div>
            </Col>
          </Row>

          <div className={'oauth-app-action'}>{getMenuAction(item)}</div>
        </OauthAppItemStyle>
      </Card>
    );
  };

  const processDataRow = (item: OauthAppRes) => {
    return {
      key: item.oauthAppId,
      name: item.name,
      description: item.description,
      status: getRecordStatus(item, 'bottom'),
      createdDate: <DateLabel label={item.createdDate} />,
      action: getMenuAction(item),
    };
  };

  const getRecordStatus = (item: OauthAppRes, placement: TooltipPlacement) => {
    return (
      <Popover
        title={`${item.name} Oauth Data`}
        content={<ViewOAuthApp data={item} />}
        placement={placement}
        trigger={['click']}
      >
        {item.enable ? (
          <Button shape={'round'} size={'small'} type={'primary'}>
            {t('label.activated')}
          </Button>
        ) : (
          <Button shape={'round'} size={'small'} type={'default'}>
            {t('label.view')}
          </Button>
        )}
      </Popover>
    );
  };

  const getMenuAction = (item: OauthAppRes) => {
    const menu: MenuProps['items'] = [
      {
        key: OauthAppManagementAction.UPDATE,
        icon: IconUtils.actions.edit,
        onClick: () => handleClickMenu(item, OauthAppManagementAction.UPDATE),
        label: t('oauth_app.actions.update'),
      },
      {
        key: OauthAppManagementAction.DELETE,
        icon: IconUtils.actions.delete,
        onClick: () => handleClickRemove(item),
        label: t('oauth_app.actions.delete'),
      },
    ];

    return (
      <Dropdown menu={{ items: menu }} placement="topRight" arrow>
        <Button type="default" shape="circle" icon={IconUtils.more_icon} />
      </Dropdown>
    );
  };

  const refreshData = (params: any) => {
    setParams(params);
  };

  const refresh = () => {
    findAll(params);
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setAction({ action: OauthAppManagementAction.DEFAULT, params: -1 });
  };

  const handleClickRemove = (item: OauthAppRes) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('oauth_app.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteOauthApp(item.oauthAppId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('oauth_app.warning.remove_success'),
                description: t('oauth_app.warning.remove_success_message'),
                placement: 'bottomRight',
              });

              refresh();
            })
            .catch(() => {
              notification.error({
                message: t('oauth_app.warning.remove_error'),
                description: t('oauth_app.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('oauth_app.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() =>
              setAction({ action: OauthAppManagementAction.ADD, params: -1 })
            }
          />
        </H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === OauthAppManagementAction.ADD && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('oauth_app.actions.add')}
          content={
            <CreateOauthAppForm
              oauthAppId={action.params}
              onSuccess={refresh}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === OauthAppManagementAction.UPDATE && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('oauth_app.actions.update')}
          content={
            <CreateOauthAppForm
              oauthAppId={action.params}
              onSuccess={refresh}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default OauthAppManagementPage;

const OauthAppItemStyle = styled.div`
  position: relative;

  .oauth-app-info {
    text-align: center;
  }

  .oauth-app-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
