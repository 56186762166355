import React, { ReactNode, useEffect, useState } from 'react';
import {
  SectionResourceBank,
  SectionResourceBankStyle,
} from './SessionResourceBank';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import styled from 'styled-components';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { LoadingOutlined } from '@ant-design/icons';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import DragNDropContext, {
  DragNDropAction,
  DragNDropActionType,
} from '@components/react-dnd-bt/DragNDropContext';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { Spin } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import Space from 'antd/lib/space';
import Button from '@components/button';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonResourceBanks = (props: {
  lesson: LessonRes;
  placeHolder?: ReactNode;
}) => {
  const { t } = useTranslation();

  const service = useLessonServiceContext();

  const [reorderResources, { isLoading }] =
    LessonAPI.endpoints.reorderResources.useMutation({});

  const { dispatchAction } = useLessonEditableContext();

  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (props.lesson.sections != null && props.lesson.sections.length > 0) {
      setResources(ResourceUtils.getResources(props.lesson.sections));
    } else {
      setResources([]);
    }
  }, [props.lesson]);

  const handleOnChange = (action: DragNDropAction) => {
    if (action.action === DragNDropActionType.order) {
      const sourceData = resources.filter((s) => {
        return s.resourceId + '' === action.params?.new?.draggableId;
      });

      const newOrder = [...resources];
      newOrder.splice(action.params!.old!.index, 1);
      newOrder.splice(action.params!.new!.index, 0, sourceData[0]);

      const newResourceOrder: number[] = newOrder.map((dt) => {
        return dt.resourceId;
      });

      const request = {
        lessonId: props.lesson.lessonId,
        resourceIds: newResourceOrder,
      };

      reorderResources(request)
        .unwrap()
        .then(() => {
          dispatchAction(LessonEditableActionEnum.refresh, -1);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleOnSelect = () => {
    dispatchAction(LessonEditableActionEnum.view_resource, 0);
  };

  return (
    <LessonSectionBanksStyle className={'lesson-sections-bank'}>
      {isLoading && (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      )}

      <DragNDropContext
        id={props.lesson.lessonId + ''}
        onChange={handleOnChange}
      >
        <DropItem
          droppableId={'lesson'}
          acceptType={'resource-item'}
          className={'draggable-container'}
        >
          {resources.map((res, index) => {
            return (
              <SectionResourceBank
                key={res.resourceId + '_' + index + '_' + service.resourceId}
                resource={res}
                index={index}
              />
            );
          })}
        </DropItem>
      </DragNDropContext>

      <SectionResourceBankStyle
        className={`section-resource-item blank-resource ${
          service.resourceId === 0 ? 'active' : ''
        }`}
        onClick={handleOnSelect}
      >
        <H1>{IconUtils.add_new}</H1>
        <p> {props.placeHolder ?? t('resource.actions.click_to_add')}</p>

        <Space className={'resource-actions bottom-left-menu'}>
          <Button shape={'circle'} size={'small'} type={'primary'}>
            <b>{resources.length + 1}</b>
          </Button>
        </Space>
      </SectionResourceBankStyle>
    </LessonSectionBanksStyle>
  );
};

const LessonSectionBanksStyle = styled.div`
  position: relative;

  .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: rgba(0, 0, 0, 0.25);
  }

  .question-content-display {
    aspect-ratio: 16 / 9;
    overflow: hidden;

    .resource-comps {
      width: 1000px;
      font-size: 22px;
      transform: scale(0.35);
      transform-origin: top left;
    }
  }
`;
