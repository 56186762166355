import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useRouter } from '@hooks/useRouter';
import ActiveUserPage from '@modules/public/pages/ActiveUserPage';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { useTranslation } from 'react-i18next';

const RegisterActiveUser = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const queryData = router.query as unknown as { code: string; token: string };

  return (
    <PublicLayout className={'register-active-user-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('registration.activation')}
        description={t('registration.activation')}
      />

      <ActiveUserPage code={queryData.code} token={queryData.token} />
    </PublicLayout>
  );
};

export default RegisterActiveUser;
