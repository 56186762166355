import { ExerciseReportRes } from '@services/reports/LessonReport';
import ColorUtils from '@utils/ColorUtils';
import BarGraphChart, {
  BarGraphReportProp,
} from '@modules/reports/charts/bargraph/BarGraphChart';
import { ReportUtils } from '@modules/reports/ReportUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import React from 'react';
import { LessonReportChartStyle } from './style';
import { H3 } from '@components/typography';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';

const LessonScoreHistory = (props: { reports: ExerciseReportRes[] }) => {
  const renderFunc = (data: ExerciseReportRes): BarGraphReportProp => {
    const percent = ReportUtils.percent(
      data.exercise.score!,
      data.exercise.maxScore!
    );

    const recordData: BarGraphReportProp = {
      group: DateAndTimeUtils.format(
        data.exercise.createdDate,
        DateAndTimeFormat.SHORT
      ),
      score: ReportUtils.percent(data.exercise.score!, data.exercise.maxScore!),
      scoreColor: ColorUtils.getColorByScore(percent),
      data: data,
    };

    return recordData;
  };

  const labelFormat = (e: any) => {
    return e.id;
  };

  const customColorBargraph = (params: {
    id: string;
    data: BarGraphReportProp;
  }) => {
    return params.data[params.id + 'Color'];
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Score History{' '}
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Score Report</h3>}
          content={
            <>
              <p>
                Biểu đồ này thể hiện kế quả của các bài kiểm tra theo lịch sử
                tiến hành.
              </p>

              <p>
                Bạn có thể sử dụng nó để đánh giá sự hoàn thiện các kĩ năng của
                mình.
              </p>
            </>
          }
        />
      </H3>

      <BarGraphChart
        keys={['score', 'incorrect']}
        className={'lesson-standard'}
        content={props.reports}
        maxValue={100}
        size={500}
        render={renderFunc}
        labelFormat={labelFormat}
        xAxisTitle={'Date and time'}
        yAxisTitle={'Score (%)'}
        yAxisValues={[0, 25, 50, 75, 100]}
        getColor={customColorBargraph}
      />
    </LessonReportChartStyle>
  );
};

export default LessonScoreHistory;
