import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ProductTocAPI } from '@modules/product/services/ProductTocAPI';
import { ProductTocInfo } from '@modules/product/components/toc/ProductTocInfo';
import { ReviewProductContext } from '@modules/product/context/BuildProductContext';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

export default function TableOfContent() {
  const {
    productId,
    productTocs,
    setProductTocs,
    selectedProductTocId,
    selectLessonId,
    setSelectLesson,
  } = useContext(ReviewProductContext);

  const [getProductTocs, { data, isSuccess }] =
    ProductTocAPI.endpoints.getProductTocs.useLazyQuery({});

  useEffect(() => {
    getProductTocs(productId);
  }, []);

  // then load first lesson
  useEffect(() => {
    if (selectLessonId < 0) {
      let lesson: LessonRes | null = null;
      let productToc: ProductTocRes | null = null;

      productTocs.forEach((item) => {
        if (lesson == null && item.lessons != null && item.lessons.length > 0) {
          lesson = item.lessons[0];
          productToc = item;
        }
      });

      if (lesson != null && productToc != null) {
        setSelectLesson(lesson, productToc);
      }
    }
  }, [productTocs]);

  useEffect(() => {
    if (isSuccess && data) {
      setProductTocs(data);
    }
  }, [data]);

  return (
    <TableOfContentStyle className={'table-of-content'}>
      <div className={'table-of-content-tree'}>
        {productTocs &&
          productTocs.map((item) => {
            return (
              <ProductTocInfo
                key={item.productTocId}
                selectTocId={selectedProductTocId}
                selectLessonId={selectLessonId}
                onSelectLesson={setSelectLesson}
                item={item}
              />
            );
          })}
      </div>
    </TableOfContentStyle>
  );
}

const TableOfContentStyle = styled.div`
  .product-toc-info {
    margin-bottom: 1.25em;
    background: #fff;
  }
`;
