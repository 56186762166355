import { LockOutlined, KeyOutlined } from '@ant-design/icons';
import React from 'react';
import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/tooltip/Tooltip';
import LessonButton from '@cms/lesson-template/components/buttons/style';

export const ToggleCorrectAnswer = () => {
  const { t } = useTranslation();

  const { isShowCorrectAnswer, dispatchExerciseAction, lesson } =
    useViewLessonContext();

  const handleShowCorrectAns = () => {
    dispatchExerciseAction(ExerciseActionEnum.toggle_correct_answer, null);
  };

  if (lesson.validation && lesson.validation.interact) {
    if (isShowCorrectAnswer) {
      return (
        <Tooltip title={t('exercise.button.hide_correct_answer')}>
          <LessonButton
            className={'hide-correct-answer-button'}
            type={'default'}
            shape={'circle'}
            onClick={handleShowCorrectAns}
            icon={<LockOutlined />}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t('exercise.button.show_correct_answer')}>
          <LessonButton
            className={'show-correct-answer-button'}
            type={'default'}
            shape={'circle'}
            onClick={handleShowCorrectAns}
            icon={<KeyOutlined />}
          />
        </Tooltip>
      );
    }
  } else {
    return null;
  }
};
