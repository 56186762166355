import React from 'react';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { SingleQuestionNavigationHandler } from '@cms/lesson-template/components/toolbar/SingleQuestionToolbar';
import { StepByStepQuestionNavigationHandle } from '@cms/lesson-template/components/toolbar/StepByStepQuestionToolbar';

export const SingleQuestionToolbarHandle = (props: {
  sectionId: number;
  resources: ResourceProps[];
}) => {
  const { config } = useLessonTemplateContext();

  if (config.stepByStep) {
    return <StepByStepQuestionNavigationHandle {...props} />;
  } else {
    return <SingleQuestionNavigationHandler {...props} />;
  }
};
