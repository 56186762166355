import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import { MyCertificatePage } from '@modules/setting/pages/MyCertificatePage';

const MyCertificate = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'review-public-profile-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_ACCOUNT}
        subMenu={NavigationMenuEnum.MY_ACCOUNT_CERTIFICATE}
        title={t('profile.public_profile')}
        description={t('profile.public_profile')}
      />

      <MyCertificatePage />
    </ManagementLayout>
  );
};

export default MyCertificate;
