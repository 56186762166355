import React from 'react';

import { RoleEnum } from '@app/redux/slices/roles';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { LanguageManagementPage } from '@modules/setting/pages/language/LanguageManagementPage';
import { PageHeader } from '@app/header/PageHeader';

const LanguageSetting = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'language-setting-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_LANGUAGE}
        title={t('language.header')}
        description={t('language.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <LanguageManagementPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default LanguageSetting;
