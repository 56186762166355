import { SessionAPI } from '@services/private/SessionAPI';
import React, { useEffect, useRef, useState } from 'react';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { SectionItem } from '@cms/section-bank/components/SectionItem';
import { CompMode } from '@cms/ComponentInteface';
import SectionUtils from '@cms/section-bank/SectionUtils';
import styled from 'styled-components';
import ClassRoomLibrary from '@classroom/container/section/ClassRoomLibrary';
import { useTranslation } from 'react-i18next';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { IconUtils } from '@utils/IconUtils';
import {
  ClassRoomAction,
  ClassRoomInteractAction,
} from '@classroom/context/actions';
import Button from '@components/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { StringUtils } from '@utils/StringUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { CreateSessionSlideReq } from '@services/model/session_request';
import {
  LessonRes,
  LessonSectionRes,
} from '@modules/product/services/lesson_model';

export const ClassRoomSection = () => {
  const { session } = useClassRoomContext();

  const [viewLessonSessions, { data, isSuccess }] =
    SessionAPI.endpoints.viewLessonSessions.useLazyQuery();

  const [createSessionSlide] =
    SessionAPI.endpoints.createSessionSlide.useMutation({});

  const [lesson, setLesson] = useState<LessonRes | undefined>(undefined);
  const [viewType, setViewType] = useState('');

  useEffect(() => {
    viewLessonSessions(session.sessionId);
  }, [session.sessionId]);

  useEffect(() => {
    if (data && isSuccess) {
      setLesson(data);
    }
  }, [data]);

  const onSelectResources = (res: ResourceProps[]) => {
    const request: CreateSessionSlideReq = {
      sessionId: session.sessionId,
      displayOrder:
        lesson && lesson.sections && lesson.sections.length > 0
          ? lesson.sections.length + 1
          : 1,
      resourceIds: res.map((rs) => {
        return rs.resourceId;
      }),
    };

    createSessionSlide(request)
      .unwrap()
      .then(() => {
        handleOnClose();
        viewLessonSessions(session.sessionId);
      })
      .catch((err) => {
        console.log('Have error when save data', err);
      });
  };

  const handleOnClose = () => {
    setViewType('');
  };

  return (
    <>
      <ClassRoomSectionDisplay
        lesson={lesson}
        onAddSlide={() => setViewType('add-section')}
        onClose={handleOnClose}
      />

      {viewType === 'add-section' && (
        <CustomModal
          header={'Add New Section'}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ClassRoomLibrary
              item={session}
              onSelectResources={onSelectResources}
              onClose={handleOnClose}
            />
          }
          onCloseFunc={handleOnClose}
        />
      )}
    </>
  );
};

const ClassRoomSectionDisplay = (props: {
  lesson?: LessonRes;
  onAddSlide: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { dispatchAction } = useClassRoomContext();
  const { onActionChange } = useClassRoomInteractContext();

  const [selectSectionId, setSelectSessionId] = useState(-1);

  const onPresent = (_sectionId: number) => {
    dispatchAction(ClassRoomAction.PRESENT_SECTION, _sectionId);
  };

  const onAssign = (_sectionId: number) => {
    onActionChange(ClassRoomInteractAction.ASSIGN, _sectionId + '');
  };

  return (
    <ClassRoomSectionStyle className={'class-room-section'}>
      <div className={'class-room-section-items hide-scrollbar'}>
        {props.lesson &&
          props.lesson.sections &&
          props.lesson.sections.map((sec) => {
            return (
              <ClassRoomSectionItem
                key={sec.lessonSectionId}
                section={sec}
                selectedSectionId={selectSectionId}
                onSelectSection={(secId) => setSelectSessionId(secId)}
              />
            );
          })}

        <div
          className={'class-room-section-item blank-section'}
          onClick={props.onAddSlide}
        >
          <div className={'lesson-section'}>
            <span className={'icon'}>{IconUtils.add_new}</span>
            <span className={'label'}>{t('class_room.actions.add_slide')}</span>
          </div>
        </div>
      </div>

      <div className={'class-room-section-review hide-scrollbar'}>
        {props.lesson &&
          props.lesson.sections &&
          props.lesson.sections.map((sec) => {
            if (sec.lessonSectionId === selectSectionId) {
              return (
                <ClassRoomSectionItem
                  key={sec.lessonSectionId + '_' + selectSectionId}
                  section={sec}
                  selectedSectionId={selectSectionId}
                />
              );
            } else {
              return null;
            }
          })}

        {selectSectionId > 0 && (
          <ButtonGroup type={'center'} className={'present-section-group'}>
            <Button
              size={'large'}
              shape={'round'}
              type={'primary'}
              icon={IconUtils.class_room.join_classroom}
              onClick={() => onPresent(selectSectionId)}
            >
              {t('class_room.actions.present')}
            </Button>

            <Button
              size={'large'}
              shape={'round'}
              type={'primary'}
              icon={IconUtils.actions.assign}
              onClick={() => onAssign(selectSectionId)}
            >
              {t('class_room.actions.assign')}
            </Button>
          </ButtonGroup>
        )}
      </div>
    </ClassRoomSectionStyle>
  );
};

export const ClassRoomSectionItem = (props: {
  section: LessonSectionRes;
  selectedSectionId: number;
  onSelectSection?: (lessonSectionId: number) => void;
  onSelectResource?: (resourceId: number) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      contentRef.current != null &&
      props.section.lessonSectionId === props.selectedSectionId
    ) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.section, props.selectedSectionId]);

  const handleOnSelectSection = (sectionId: number) => {
    if (props.onSelectSection) {
      props.onSelectSection(sectionId);
    }
  };

  return (
    <div
      ref={contentRef}
      className={StringUtils.classes(
        'class-room-section-item',
        props.section.lessonSectionId === props.selectedSectionId
          ? 'selected'
          : ''
      )}
      onClick={() => handleOnSelectSection(props.section.lessonSectionId)}
    >
      <SectionItem
        item={SectionUtils.convert(props.section)}
        mode={CompMode.VIEW}
      />
    </div>
  );
};

const ClassRoomSectionStyle = styled.div`
  position: relative;
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  height: 100%;

  .class-room-section-items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1em 1em;
    width: 40%;
    max-height: 100%;
    overflow-y: auto;

    .class-room-section-item {
      display: inline-flex;
      width: calc(50% - 1em);
      //aspect-ratio: 16 / 9;
      padding-top: 25%;
      position: relative;
      border: 2px solid #ccc;
      background: #fff;
      cursor: pointer;

      .lesson-section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        font-size: 10px;
        padding: 1em;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
      }

      &:hover {
        &:before {
          background: ${(props) => props.theme.app.primary_bgr};
          opacity: 0.67;
        }
      }

      &.selected {
        border: 2px solid ${(props) => props.theme.app.primary};

        &:before {
          background: ${(props) => props.theme.app.primary_bgr};
          opacity: 0.33;
        }
      }

      &.blank-section {
        .lesson-section {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: ${(props) => props.theme.color.grey};

          .icon {
            font-size: 500%;
          }
        }
      }
    }
  }

  .class-room-section-review {
    width: calc(60% - 1em);
    max-height: 100%;
    overflow-y: auto;

    .class-room-section-item {
      width: auto;
      padding: 2em 1.5em 3em 1.5em;
      border: 2px solid #ccc;
      background: #fff;
      max-width: 1000px;
      margin: 0 auto;
    }

    .present-section-group {
      margin-top: 1em;
      position: sticky;
      bottom: 5px;
      left: 5px;
      right: 5px;
    }
  }
`;
