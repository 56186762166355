import React from 'react';
import { ViewLessonContentProvider } from './context/ViewLessonContext';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';
import { AnonymousServiceProvider } from '@cms/service/AnnonymousServiceProvider';

export const AnonymousViewLesson = (props: {
  data: LessonRes;
  goBack: () => void;
}) => {
  return (
    <ViewLessonContentProvider
      type={CompMode.DO_EXERCISE}
      lesson={props.data}
      lessonType={props.data.type ?? LessonTypeEnum.lesson}
      answers={[]}
      feedBacks={[]}
      gradings={[]}
      exitExercise={props.goBack}
    >
      <AnonymousServiceProvider
        exerciseId={-1}
        lessonId={props.data.lessonId}
        event={{ onExit: props.goBack }}
      >
        <LessonTemplateLayoutProvider lesson={props.data}>
          <ViewLessonContent />
        </LessonTemplateLayoutProvider>
      </AnonymousServiceProvider>
    </ViewLessonContentProvider>
  );
};
