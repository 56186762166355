import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Divider, notification, Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import ContentEditor from '@components/editor/WysyEditor';
import KeywordInput from '@components/input/KeywordInput';
import { useDispatch } from 'react-redux';
import { updateMetadata } from '@app/redux/slices/composeLesson';
import SingleUpload from '@components/input/SingleUpload';
import { ImageScale } from '@utils/ImageScale';
import IconImage from '@components/images/IconImage';
import { useLoadLessonData } from '@cms/lesson-template/compose/LessonToolbar';
import SelectLessonTemplate from '@cms/resource-bank/SelectLessonTemplate';
import { RequireProUser } from '@components/button/RequireProUserButton';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { LessonTypeEnum } from '@modules/admin/service/model';
import SelectLessonType from '@cms/resource-bank/SelectLessonType';
import {
  LessonRes,
  UpdateLessonReq,
} from '@modules/product/services/lesson_model';

const initLessonInfo = {
  lessonId: -1,
  type: LessonTypeEnum.lesson,
  name: '',
  description: '',
  lessonTemplateId: -1,
};

export default function LessonMetadata(props: {
  lessonId: number;
  onCancel: () => void;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data, isSuccess } = useLoadLessonData(props.lessonId, true);

  const [updateLessonMetadata, { isLoading }] =
    LessonAPI.endpoints.updateLessonMetadata.useMutation({});

  const [ready, setReady] = useState(false);

  const [thumbnails, setThumbnails] = useState('');
  const [keywords, setListKeywords] = useState<string[]>([]);
  const [instruction, setInstruction] = useState('');
  const [shortInstruction, setShortInstruction] = useState('');

  useEffect(() => {
    if (isSuccess && data) {
      updateLessonData(data);
    }
  }, [data]);

  const handleOnFileUploaded = (uploadedFile: string) => {
    setThumbnails(uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err when upload file....');
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initLessonInfo,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateLessonReq = {
        lessonId: values.lessonId,
        type: values.type,
        name: values.name,
        description: values.description,
        keyword: keywords.join(','),

        instruction: instruction,
        shortInstruction: shortInstruction,
        thumbnails: thumbnails,
        lessonTemplateId: values.lessonTemplateId,
      };

      updateLessonMetadata(request)
        .unwrap()
        .then((newData) => {
          dispatch(updateMetadata(newData));

          notification.success({
            message: t('lesson.warning.update_success'),
            description: t('lesson.warning.update_success_message'),
            placement: 'bottomRight',
          });

          if (props.onSuccess) {
            props.onSuccess();
          }
        })
        .catch(() => {
          notification.error({
            message: t('lesson.warning.update_error'),
            description: t('lesson.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  const updateLessonData = (lsData: LessonRes) => {
    formik.setValues({
      lessonId: lsData.lessonId,
      type: lsData.type,
      lessonTemplateId: lsData.lessonTemplate
        ? lsData.lessonTemplate.lessonTemplateId
        : -1,
      name: lsData.name,
      description: lsData.description,
    });

    setInstruction(lsData.instruction);
    setShortInstruction(lsData.shortInstruction);
    setThumbnails(lsData.thumbnails);

    setListKeywords(
      lsData.keyword != null && lsData.keyword.trim() !== ''
        ? lsData.keyword.split(',')
        : []
    );
    setReady(true);
  };

  return (
    <>
      <div className={'update-lesson-metadata'}>
        {!ready && (
          <>
            <Skeleton paragraph={{ rows: 4 }} />
            <Skeleton paragraph={{ rows: 4 }} />
          </>
        )}

        {ready && (
          <FormikProvider value={formik}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col flex={ImageScale.lesson.width + 24 * 2 + 'px'}>
                <InputGroup label={t('label.thumbnails')}>
                  <SingleUpload
                    scale={ImageScale.lesson.scale}
                    accept={'image/jpg, image/jpeg, image/png'}
                    multiple={false}
                    onSuccessFunc={handleOnFileUploaded}
                    onErrorFunc={handleOnUploadHasErr}
                  >
                    <IconImage
                      src={thumbnails}
                      width={ImageScale.lesson.width}
                      height={ImageScale.lesson.height}
                    />
                  </SingleUpload>
                </InputGroup>
              </Col>

              <Col flex={'auto'}>
                <Input
                  required={true}
                  name="name"
                  type="text"
                  label={t('label.name')}
                  placeholder={t('label.name')}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                <RequireProUser>
                  <SelectLessonTemplate
                    formik={formik}
                    name={'lessonTemplateId'}
                    value={formik.values.lessonTemplateId}
                    onChange={(value) =>
                      formik.setFieldValue('lessonTemplateId', value)
                    }
                  />

                  <SelectLessonType
                    formik={formik}
                    name={'type'}
                    value={formik.values.type}
                    onChange={(value) => formik.setFieldValue('type', value)}
                  />
                </RequireProUser>
              </Col>
            </Row>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col span={24}>
                <InputGroup label={t('label.keyword')}>
                  <KeywordInput
                    keywords={data ? data.keyword : ''}
                    placeholder={t('label.enter_keyword')}
                    enterButton={t('label.add')}
                    size={'large'}
                    onKeywordChange={(values) => setListKeywords(values)}
                  />
                </InputGroup>

                <InputGroup label={t('label.description')}>
                  <Input
                    type={'textarea'}
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    style={{ minHeight: 120 }}
                    maxLength={512}
                  />
                </InputGroup>

                <Divider />

                <InputGroup label={t('label.instruction')}>
                  <ContentEditor
                    initValue={data?.instruction}
                    onChange={(value: string) => setInstruction(value)}
                  />
                </InputGroup>

                <InputGroup label={t('label.short_instruction')}>
                  <ContentEditor
                    initValue={data?.shortInstruction}
                    onChange={(value: string) => setShortInstruction(value)}
                  />
                </InputGroup>

                <ButtonGroup type={'right'} className="submit-container">
                  <Button
                    type="default"
                    shape={'round'}
                    size={'large'}
                    onClick={props.onCancel}
                  >
                    {t('button.cancel')}
                  </Button>

                  <Button
                    type={'primary'}
                    shape={'round'}
                    size={'large'}
                    loading={isLoading}
                    onClick={formik.submitForm}
                    icon={IconUtils.actions.save}
                  >
                    {t('button.update')}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </FormikProvider>
        )}
      </div>
    </>
  );
}
