import React from 'react';
import { InputProps as AntInputProps } from 'antd/lib/input/Input';
import { StyledInput } from '@components/input/style';

export interface InputLabelProps extends AntInputProps {
  label?: string;
  value: any;
  className?: string;
}

const InputLabel = ({ label, value, className }: InputLabelProps) => {
  return (
    <StyledInput className={'form-group' + (className ? ' ' + className : '')}>
      {label && <div className={'ant-form-label'}>{label}</div>}

      <div className={'ant-form-controls'}>
        <span className={'input-label'}>{value}</span>
      </div>
    </StyledInput>
  );
};

export default InputLabel;
