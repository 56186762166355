import SectionItem from '@cms/section-bank/components/SectionItem';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { CompMode } from '@cms/ComponentInteface';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';

export const PageSections = () => {
  const { section } = useLessonNavigationContext();
  const { sections } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  return (
    <>
      {sections.map((item, index) => {
        const visible = Boolean(
          !config.singleMode || section.sectionId === item.lessonSectionId
        );

        return (
          <React.Fragment
            key={JSON.stringify(item) + '_' + index + '_' + visible}
          >
            <SectionItem
              visible={visible}
              key={JSON.stringify(item) + '_' + index}
              item={item}
              mode={CompMode.VIEW}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
