import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSelector } from '@app/redux/hook';
import { FireBaseService } from '@services/FireStoreService';
import { ClassRoomInteractAction } from '@classroom/context/actions';
import { SessionRes } from '@services/model/session';

const defaultAction = {
  action: ClassRoomInteractAction.DEFAULT,
  params: '',
};

export const ClassRoomInteractProvider = (props: {
  session: SessionRes;
  children: ReactNode;
}) => {
  const { uuid } = useSelector((state) => state.userInformation);

  const attendService = useRef(
    new FireBaseService(uuid, `/sessions/${props.session.code}`, '/attendance')
  );

  const raiseHandService = useRef(
    new FireBaseService(uuid, `/sessions/${props.session.code}`, '/raise-hand')
  );

  const presentIdsService = useRef(
    new FireBaseService(uuid, `/sessions/${props.session.code}`, '/presenting')
  );

  const [recording, setRecording] = useState(false);
  const [allowInteract, setAllowInteract] = useState(!!props.session.owner);
  const [openChatBox, setOpenChatBox] = useState(false);

  const [raiseHandIds, setRaiseHandIds] = useState<string[]>([]);
  const [presentingIds, setPresentingIds] = useState<string[]>([]);

  const [action, setAction] = useState(defaultAction);

  useEffect(() => {
    raiseHandService.current.onSelectAll(_onRaiseHandChange);
    presentIdsService.current.onSelectAll(_onPresentChange);
    onJoinSession(true);

    return () => {
      onQuitSession();
    };
  }, [props.session.sessionId]);

  useEffect(() => {
    if (
      presentingIds != null &&
      presentingIds.length > 0 &&
      presentingIds.includes(uuid)
    ) {
      setAllowInteract(true);
      setRecording(true);
    } else {
      setAllowInteract(false);
      setRecording(false);
    }
  }, [props.session.isHost, presentingIds]);

  const onQuitSession = () => {
    onJoinSession(false);
    onRaiseHand(false, uuid);
    onAllowPresent(false, uuid);
  };

  const onActionChange = (action: ClassRoomInteractAction, params: string) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const onAllowPresent = (allowPresent: boolean, uuid: string) => {
    // clear present ids
    if (allowPresent) {
      stopPresent();
    }

    presentIdsService.current.create(
      {
        allowPresent: allowPresent,
      },
      uuid
    );
  };

  const stopPresent = () => {
    presentIdsService.current.deleteAll();
  };

  const onRaiseHand = (isRaise: boolean, uuid: string) => {
    raiseHandService.current.create(
      {
        raiseHand: isRaise,
      },
      uuid
    );
  };

  const _onPresentChange = (data: { uid: string; allowPresent: boolean }[]) => {
    const filter = data
      .filter((dt) => {
        return dt.allowPresent;
      })
      .map((dt) => {
        return dt.uid;
      });

    setPresentingIds(filter);
  };

  const _onRaiseHandChange = (data: { uid: string; raiseHand: boolean }[]) => {
    const filter = data
      .filter((dt) => {
        return dt.raiseHand;
      })
      .map((dt) => {
        return dt.uid;
      });

    setRaiseHandIds(filter);
  };

  const onOpenChatBox = (isOpen: boolean) => {
    setOpenChatBox(isOpen);
  };

  const onJoinSession = (isOnline: boolean) => {
    attendService.current.create(
      {
        uuid: uuid,
        isOnline: isOnline,
      },
      uuid
    );
  };

  return (
    <ClassRoomInteractContext.Provider
      value={{
        action,
        recording,

        openChatBox,
        allowInteract,
        raiseHandIds,
        presentingIds,

        onActionChange,
        onJoinSession,
        onOpenChatBox,
        onRaiseHand,
        onAllowPresent,
      }}
    >
      {props.children}
    </ClassRoomInteractContext.Provider>
  );
};

const ClassRoomInteractContext = createContext({
  action: defaultAction,
  recording: false as boolean,
  allowInteract: false as boolean,
  openChatBox: false as boolean,

  raiseHandIds: [] as string[],
  presentingIds: [] as string[],

  onJoinSession: (isOnline: boolean) => {
    console.log(isOnline);
  },

  onActionChange: (action: ClassRoomInteractAction, params: string) => {
    console.log('action', action, params);
  },

  onOpenChatBox: (isOpen: boolean) => {
    console.log('isOpen', isOpen);
  },

  onRaiseHand: (isRaise: boolean, uuid: string) => {
    console.log(isRaise, uuid);
  },

  onAllowPresent: (isAllow: boolean, uuid: string) => {
    console.log('.................', isAllow, uuid);
  },
});

export const useClassRoomInteractContext = () => {
  const context = useContext(ClassRoomInteractContext);
  if (!context) {
    throw new Error('You must wrap container by ClassRoomInteractProvider');
  }
  return context;
};
