import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  CreateSchoolReq,
  SchoolAPI,
  SchoolRes,
} from '@services/private/SchoolAPI';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import { IconUtils } from '@utils/IconUtils';
import { useLoadSchool } from '@hooks/useLoadSchool';

const initialValue: CreateSchoolReq = {
  schoolId: -1,

  clientId: '',
  clientUrl: '',

  name: '',
  address: '',
  city: '',
  district: '',
  zipCode: '',
  country: '',
};

const SchoolEditForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const { data, isFetching, isSuccess } = useLoadSchool(props.schoolId);

  const [createSchool, { isLoading }] =
    SchoolAPI.endpoints.createSchool.useMutation({});

  const [updateSchool, { isLoading: isUpdateLoading }] =
    SchoolAPI.endpoints.updateSchool.useMutation({});

  useEffect(() => {
    if (data && isSuccess) {
      setSchoolData(data);
    }
  }, [data]);

  const setSchoolData = (data: SchoolRes) => {
    formik.setValues(data);
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      clientUrl: yup.string().required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      updateSchoolData(values);
    },
  });

  const updateSchoolData = (request: CreateSchoolReq) => {
    if (formik.values.schoolId != null && formik.values.schoolId > 0) {
      updateSchool(request)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('school.warning.update_success'),
            description: t('school.warning.update_success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess();
        })
        .catch((err) => {
          if (err.data.message === 'school.domain.used') {
            formik.setFieldError(
              'domain',
              t('school.warning.client_url_used') as string
            );
          }

          notification.error({
            message: t('school.warning.update_error'),
            description: t('school.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    } else {
      createSchool(request)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('school.warning.update_success'),
            description: t('school.warning.update_success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess();
        })
        .catch((err) => {
          if (err.data.message === 'school.domain.used') {
            formik.setFieldError(
              'domain',
              t('school.warning.client_url_used') as string
            );
          }

          notification.error({
            message: t('school.warning.update_error'),
            description: t('school.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    }
  };

  return (
    <FormikProvider value={formik}>
      {formik.values.clientId && (
        <InputGroup label={'Client Id'}>
          <InputValue type={'textarea'}>{formik.values.clientId}</InputValue>
        </InputGroup>
      )}

      {data && data.clientUrl ? (
        <InputGroup label={t('label.clientUrl')}>
          <InputValue type={'textarea'}>{formik.values.clientUrl}</InputValue>
        </InputGroup>
      ) : (
        <Input
          required={true}
          name="clientUrl"
          type="text"
          label={t('label.clientUrl')}
          placeholder={t('label.clientUrl')}
          onChange={formik.handleChange}
          value={formik.values.clientUrl}
          maxLength={15}
        />
      )}

      <Input
        required={true}
        name="name"
        type="text"
        label={t('label.name')}
        placeholder={t('label.name')}
        onChange={formik.handleChange}
        value={formik.values.name}
      />

      <Input
        name="address"
        type="textarea"
        label={t('label.address')}
        placeholder={t('label.address')}
        onChange={formik.handleChange}
        value={formik.values.address}
        maxLength={512}
        row={4}
      />

      <Input
        name="city"
        type="text"
        label={t('label.city')}
        placeholder={t('label.city')}
        onChange={formik.handleChange}
        value={formik.values.city}
      />

      <Input
        name="district"
        type="text"
        label={t('label.district')}
        placeholder={t('label.district')}
        onChange={formik.handleChange}
        value={formik.values.district}
      />

      <Input
        name="zipCode"
        type="text"
        label={t('label.zipCode')}
        placeholder={t('label.zipCode')}
        onChange={formik.handleChange}
        value={formik.values.zipCode}
      />

      <Input
        name="country"
        type="text"
        label={t('label.country')}
        placeholder={t('label.country')}
        onChange={formik.handleChange}
        value={formik.values.country}
      />

      {props.schoolId != null && props.schoolId > 0 ? (
        <ButtonGroup type={'space-between'} className={'submit-container'}>
          <Button
            type="primary"
            shape={'round'}
            size={'large'}
            onClick={formik.submitForm}
            icon={IconUtils.save}
          >
            <>{t('button.update')}</>
          </Button>

          <Button type="default" shape={'round'} onClick={props.onCancel}>
            {t('button.cancel')}
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup type={'space-between'} className={'submit-container'}>
          <Button
            type="default"
            shape={'round'}
            size={'large'}
            onClick={props.onCancel}
          >
            {t('button.cancel')}
          </Button>

          <Button
            loading={isFetching || isLoading || isUpdateLoading}
            type={'primary'}
            shape={'round'}
            size={'large'}
            onClick={formik.submitForm}
            icon={IconUtils.save}
          >
            {t('button.update')}
          </Button>
        </ButtonGroup>
      )}
    </FormikProvider>
  );
};

export default SchoolEditForm;
