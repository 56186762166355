import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { logout, updateAccessToken } from '@app/redux/slices/profileSlice';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import env from '@utils/config';

export const baseQuery = fetchBaseQuery({
  baseUrl: env.SERVER_API,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshArgs = {
      url: '/auth/token/refresh',
      body: {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      },
      method: 'POST',
    };

    const { data }: { [key: string]: any } = await baseQuery(
      refreshArgs,
      api,
      extraOptions
    );

    if (data) {
      api.dispatch(updateAccessToken(data));
      api.dispatch(refreshExpiredData(data.expiredTime));
    } else {
      api.dispatch(logout());
    }

    result = await baseQuery(args, api, extraOptions);
  }

  return result;
};
