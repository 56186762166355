import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, List, Row } from 'antd';
import { FormHorizontal } from '@components/input/InputGroup';
import Input from '@components/input/input';
import { useTranslation } from 'react-i18next';
import SelectClass from '@modules/common/components/SelectClass';
import StudentAssignmentStatus from '@modules/assignments/components/StudentAssignmentStatus';
import { Default_Gutter } from '@components/grid';
import { SelectTeacher } from '@components/select/SelectTeacher';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { SimpleClassRosterRes } from '@modules/users/services/clazz';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';

const FilterStudentContainerStyle = styled.div`
  .ant-list-item.selected {
    font-weight: bold;
    background: ${(props) => props.theme.app.primary_bgr};
  }
`;

const FilterStudentContainer = (props: {
  teacherId?: number;
  classId: number;
  disabled?: {
    class?: boolean;
    teacher?: boolean;
  };
  selectedStudentIds: number[];
  studentAssignmentStatus?: Record<number, ExerciseStatus>;
  onSelect: (student: SimpleClassRosterRes) => void;
  onRemove: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const [classId, setClassId] = useState(props.classId);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setClassId(props.classId);
  }, [props.classId]);

  const handleSelectClass = (classId: number) => {
    setClassId(classId);
  };

  return (
    <FilterStudentContainerStyle className={'filter-lesson-container'}>
      <FormHorizontal>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          {props.teacherId != null && props.teacherId > 0 && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <SelectTeacher
                label={'Teacher'}
                value={props.teacherId}
                onChange={(val) => {}}
                disabled
              />
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <SelectClass
              teacherId={props.teacherId}
              name={'classId'}
              value={classId}
              onChange={handleSelectClass}
              disabled={props.disabled?.class}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Input
              addedClass={'single'}
              name="keyword"
              placeholder={t('label.keyword')}
              onChange={(event) => setKeyword(event.target.value)}
              defaultValue={keyword}
            />
          </Col>
        </Row>
      </FormHorizontal>
      <FormHorizontal>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <FilterStudent
              classId={classId}
              keyword={keyword}
              studentAssignmentStatus={props.studentAssignmentStatus}
              selectedStudentIds={props.selectedStudentIds}
              onSelect={props.onSelect}
              onRemove={props.onRemove}
            />
          </Col>
        </Row>
      </FormHorizontal>
    </FilterStudentContainerStyle>
  );
};

export default FilterStudentContainer;

const FilterStudent = (props: {
  classId: number;
  keyword: string;
  selectedStudentIds: number[];
  studentAssignmentStatus?: Record<number, ExerciseStatus>;
  onSelect: (student: SimpleClassRosterRes) => void;
  onRemove: (studentId: number) => void;
}) => {
  const [rosters, setRosters] = useState<SimpleClassRosterRes[]>([]);
  const [getClassRoster, { data, isSuccess, isFetching }] =
    ClassAPI.endpoints.getClassRoster.useLazyQuery({});

  useEffect(() => {
    if (props.classId > 0) {
      getClassRoster({
        classId: props.classId,
        selectAll: true,
      });
    }
  }, [props.classId]);

  useEffect(() => {
    if (data && isSuccess) {
      const classRoster = data.content.filter((rt) => {
        return (
          props.keyword.toLowerCase().trim() === '' ||
          [
            rt.user.firstName ?? '',
            rt.user.lastName ?? '',
            rt.user.username ?? '',
            rt.user.email ?? '',
            rt.user.phone ?? '',
          ]
            .join(' ')
            .toLowerCase()
            .includes(props.keyword.toLowerCase().trim())
        );
      });
      setRosters(classRoster);
    }
  }, [data, props.keyword]);

  return (
    <List
      bordered
      dataSource={rosters}
      loading={isFetching}
      size={'small'}
      renderItem={(item) => {
        const isSelected = props.selectedStudentIds.includes(item.user.userId);
        return (
          <List.Item className={isSelected ? 'selected' : ''}>
            <StudentAssignmentStatus
              status={
                props.studentAssignmentStatus
                  ? props.studentAssignmentStatus[item.user.userId]
                  : null
              }
              item={item.user}
              selected={props.selectedStudentIds.includes(item.user.userId)}
              onSelect={() => props.onSelect(item)}
              onRemove={props.onRemove}
            />
          </List.Item>
        );
      }}
    />
  );
};
