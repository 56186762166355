import { StartOverButton } from '@cms/lesson-template/components/buttons/StartOverButton';
import React from 'react';
import { SelfCheckSectionBtn } from '../buttons/presentation/SelfCheckSectionBtn';

export const SingleSelfCheckBtn = () => {
  return (
    <>
      <StartOverButton />
      <SelfCheckSectionBtn />
    </>
  );
};
