import { OperationUtils } from '@cms/comps/math/operation/OperationUtils';
import React, { useEffect, useRef } from 'react';
import { useLongOperationTableContext } from '@cms/comps/math/operation/table/LongOperationTableContext';

export const LongOperationInput = (props: {
  rowIndex: number;
  columnIndex: number;
  value: string;
  onChange: (val: string) => void;
  disabled: boolean;
}) => {
  const { rowIndex, columnIndex } = useLongOperationTableContext();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (
      rowIndex === props.rowIndex &&
      columnIndex === props.columnIndex &&
      inputRef.current != null
    ) {
      inputRef.current.focus();
    }
  }, [rowIndex, columnIndex, props.columnIndex, inputRef]);

  const onKeydown = (e: any) => {
    if (
      (48 <= e.keyCode && e.keyCode <= 57) ||
      (96 <= e.keyCode && e.keyCode <= 105)
    ) {
      if (e.target.value.indexOf(OperationUtils.DECIMAL_CHARACTER) > -1) {
        // $(this).val('');
      } else {
        // $(this).val('');
      }

      // is decimal point
    } else if (e.keyCode === 110 || e.keyCode === 190) {
      if (isTheNumberHasDecimalPoint(e.target)) {
        e.preventDefault();
      }
    } else if (e.keyCode === 46 || e.keyCode === 8) {
      // is delete key
    } else {
      e.preventDefault();
    }
  };

  const onKeyup = (e: any) => {
    if (e.keyCode === 46 || e.keyCode === 8) {
      props.onChange(OperationUtils.VARIABLE_CHARACTER);
    } else {
      const value = String.fromCharCode(e.keyCode);
      if ('0123456789'.includes(value)) {
        props.onChange(value);
      }
    }

    // check condition for keyup....

    // var $rowData = $(this).closest('.' + OPERATION_ROW_CLASS);

    // if (!$rowData.hasClass(OPERATION_TERM_CLASS)) {
    //   if (
    //     (48 <= e.keyCode && e.keyCode <= 57) ||
    //     (96 <= e.keyCode && e.keyCode <= 105) ||
    //     e.keyCode == 110 ||
    //     e.keyCode == 190
    //   ) {
    //     //                || ($(this).hasClass(OPERATION_ALLOW_DECIMAL) && (e.keyCode == 110 || e.keyCode == 190))) {
    //     if (currentValue.indexOf(OPERATION_DECIMAL_POINT_CHARACTER) > -1) {
    //       if (currentValue.indexOf(OPERATION_DECIMAL_POINT_CHARACTER) == 0) {
    //         // addDecimalPointForInputContainer(this, "current", true);
    //
    //         var row = $(this).closest('tr.operation-row');
    //         var index = $(this).closest('td.operation-input').index();
    //         //if (index > 1) {
    //
    //         $(this).val(
    //           currentValue.replace(OPERATION_DECIMAL_POINT_CHARACTER, '')
    //         );
    //         var previousInputIndex = index;
    //         var previousInput = row
    //           .find('td:nth-child(' + previousInputIndex + ')')
    //           .find('input');
    //         if (previousInput.length > 0) {
    //           addDecimalPointForInputContainer(previousInput, 'current');
    //         } else {
    //           addDecimalPointForInputContainer(this, 'current', true);
    //         }
    //
    //         //}
    //
    //         //$(this).closest('tr.operation-row').
    //         //$(this).val(currentValue.replace(OPERATION_DECIMAL_POINT_CHARACTER, ""));
    //       } else {
    //         addDecimalPointForInputContainer(this, 'current');
    //       }
    //     }
    //   }
    // }
  };

  return (
    <input
      ref={inputRef}
      disabled={props.disabled}
      value={
        props.value !== OperationUtils.VARIABLE_CHARACTER ? props.value : ''
      }
      onKeyDown={onKeydown}
      onKeyUp={onKeyup}
    />
  );
};

const isTheNumberHasDecimalPoint = (input: HTMLInputElement) => {
  console.log(input);
  return false;
};
