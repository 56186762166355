import {
  MultipleChoiceAnsProps,
  MultipleChoiceCompProps,
} from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
import { ComponentValidationProps } from '@cms/utils/ResourceUtils';

export const MultipleChoiceValidation = {
  validateComponentAndAnswer: (
    component: MultipleChoiceCompProps,
    answer: MultipleChoiceAnsProps
  ): ComponentValidationProps => {
    let needUpdate = false;
    let currentAns = { ...answer };

    if (!component.setting.multiple && answer.answer.length > 1) {
      currentAns = { ...answer, answer: [answer.answer[0]] };
      needUpdate = true;
    }

    const optionLabel: string[] = component.configuration.sourceItems.map(
      (sr) => {
        return sr.label;
      }
    );

    const newAnswer: string[] = [];
    currentAns.answer.forEach((ans) => {
      // remove non-existed options in correct ans.
      if (optionLabel.indexOf(ans) < 0) {
        needUpdate = true;
      } else {
        newAnswer.push(ans);
      }
    });
    currentAns.answer = newAnswer;

    return {
      updateComponent: false,
      updateAnswer: needUpdate,
      newComponent: component,
      newAnswer: currentAns,
    };
  },
};
