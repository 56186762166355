import React from 'react';
import { connect } from 'formik';
import { get as getPath } from 'lodash';

import { ErrMessageStyle } from '@components/input/style';

export interface InputValidationProps {
  formik?: any;
  name: string;
}

const InputValidation = ({ formik, name }: InputValidationProps) => {
  return (
    <>
      {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
        <ErrMessageStyle className="error-message">
          {getPath(formik?.errors, name)}
        </ErrMessageStyle>
      )}
    </>
  );
};

export default connect(InputValidation);
