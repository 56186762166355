import React from 'react';
import { PreviousQuestionButton } from '../buttons/PreviousQuestionButton';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { PreviousSectionBtn } from '@cms/lesson-template/components/button-wrapper/PreviousSectionBtn';

export const PreviousQuestionBtn = () => {
  const { section, resource } = useLessonNavigationContext();
  const firstSection = section.previousSectionId < 0;
  const firstQuestion = resource.previousResourceId < 0;

  if (firstQuestion && firstSection) {
    return <PreviousQuestionButton />;
  } else if (firstQuestion) {
    return <PreviousSectionBtn />;
  } else {
    return <PreviousQuestionButton />;
  }
};
