import ComponentUtils, { ComponentColorEnum } from '@cms/script/ComponentUtils';
import React from 'react';
import {
  generateMatchingItemUId,
  MatchingItemCompProps,
} from '@cms/comps/interact/matching/MatchingComp';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { AssetsRes } from '@modules/product/services/assets_model';
import { CompGroupEnum } from '@cms/utils/CompProps';
import styled from 'styled-components';

export interface MatchingOptionProps {
  compId: string;
  type: CompGroupEnum;
  color: ComponentColorEnum;
  target: MatchingItemCompProps;
  selectTarget: string;
  onClick: (label: string, type: CompGroupEnum) => void;
  handleOnContentChange: (data: AssetsRes) => void;
}

export interface MatchingDragOptionProps {
  compId: string;
  type: CompGroupEnum;
  color: ComponentColorEnum;
  target: MatchingItemCompProps;
  handleOnContentChange: (data: AssetsRes) => void;
}

const MatchingOption = ({
  compId,
  target,
  type,
  selectTarget,
  onClick,
  handleOnContentChange,
  color,
}: MatchingOptionProps) => {
  return (
    <MatchingOptionStyle
      id={generateMatchingItemUId(compId, type, target.label)}
      className={`matching-option-item ${
        target.label === selectTarget ? ' active' : ''
      }`}
      style={{ borderColor: ComponentUtils.getColor(color) }}
      onClick={() => {
        onClick(target.label, type);
      }}
    >
      <ContentViewerEditable
        contentData={target.content}
        onChange={handleOnContentChange}
      />
    </MatchingOptionStyle>
  );
};

export const MatchingDragOption = ({
  compId,
  target,
  type,
  handleOnContentChange,
  color,
}: MatchingDragOptionProps) => {
  return (
    <MatchingOptionStyle
      id={generateMatchingItemUId(compId, type, target.label)}
      className={`matching-option-item`}
      style={{ borderColor: ComponentUtils.getColor(color) }}
    >
      <ContentViewerEditable
        contentData={target.content}
        onChange={handleOnContentChange}
      />
    </MatchingOptionStyle>
  );
};

export default MatchingOption;

const MatchingOptionStyle = styled.div`
  border: 2px solid #ccc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  padding: 0.125em 0.75em;
  width: 100%;

  &.active {
    color: ${(props) => props.theme.component.primary};
    background: rgba(0, 0, 0, 0.2);

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }
  }
`;
