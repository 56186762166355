import { useTranslation } from 'react-i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import useConfirmModal from '@components/modal/ConfirmModal';
import {
  CompAnswerProps,
  CompProps,
  CompScoringProps,
} from '@cms/ComponentInteface';
import { LessonServiceContext } from './LessonServiceProvider';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { LessonRes } from '@modules/product/services/lesson_model';
import { CreateResourceReq } from '@modules/product/services/resource_model';

export const LessonDBService = (props: {
  lessonId: number;
  resourceId?: number;
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [viewLesson, { data, isSuccess, isLoading }] =
    LessonAPI.endpoints.viewLesson.useLazyQuery();

  const [
    findResourceById,
    {
      data: rsData,
      isSuccess: rsSuccess,
      error: rsError,
      isError: rsIsErr,
      isLoading: rsLoading,
    },
  ] = ResourceAPI.endpoints.findById.useLazyQuery({});
  const [createResource, { isLoading: createLoading }] =
    ResourceAPI.endpoints.createResource.useMutation({});
  const [updateResourceContent, { isLoading: updateLoading }] =
    ResourceAPI.endpoints.updateResourceContent.useMutation({});
  const [removeFromLesson, { isLoading: removeLoading }] =
    ResourceAPI.endpoints.removeFromLesson.useMutation({});
  const [cloneResource, { isLoading: cloneLoading }] =
    ResourceAPI.endpoints.cloneResource.useMutation({});

  const [lesson, setLesson] = useState<LessonRes | null>(null);
  const [resourceId, setResourceId] = useState(props.resourceId ?? 0);
  const [resource, setResource] = useState<ResourceProps | null>(null);
  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    loadLessonData();
  }, [props.lessonId]);

  useEffect(() => {
    if (data && isSuccess) {
      const resources = ResourceUtils.getResources(data.sections);

      setLesson(data);
      setResources(resources);

      if (resourceId <= 0 && resources.length > 0) {
        setResourceId(resources[0].resourceId);
        setResource(resources[0]);
      }
    }
  }, [data, modifiedTime]);

  useEffect(() => {
    if (rsData && rsSuccess) {
      setResourceId(rsData.resourceId);
      setResource(ResourceUtils.convert(rsData, 'content', -1));
    } else if (rsError && rsIsErr) {
      setResource(null);
      setResourceId(resourceId);
    }
  }, [rsData, rsError]);

  const loadLessonData = () => {
    viewLesson(props.lessonId);
    setModifiedTime(DateAndTimeUtils.getCurrentTime);
  };

  const viewResource = (resourceId: number) => {
    setResourceId(resourceId);
  };

  const findById = (resourceId: number) => {
    if (resourceId != null && resourceId > 0) {
      findResourceById(resourceId);
    } else {
      setResource(null);
      setResourceId(resourceId);
    }
  };

  const onCreateOrUpdate = (
    resourceId: number,
    components: CompProps[],
    answers: CompAnswerProps[],
    scoring: CompScoringProps[],
    callBackFun: (rsId: number) => void
  ) => {
    if (resourceId != null && resourceId > 0) {
      onUpdate(resourceId, components, answers, scoring, callBackFun);
    } else {
      onCreate(resourceId, components, answers, scoring, callBackFun);
    }
  };

  const getNextResource = (removedId: number): number => {
    const index = resources.findIndex((rs) => {
      return rs.resourceId === removedId;
    });

    if (index > 0) {
      return resources[index - 1].resourceId;
    } else if (index < resources.length - 1) {
      return resources[index + 1].resourceId;
    } else {
      return -1;
    }
  };

  const onDelete = (removeId: number, calBackFunc: (rmvId: number) => void) => {
    const request = {
      lessonId: props.lessonId,
      resourceId: removeId,
    };

    confirm(
      'danger',
      t('header.confirm'),
      t('component.actions.delete_component'),
      t('button.confirm'),
      t('button.cancel'),
      (result) => {
        if (result) {
          removeFromLesson(request)
            .unwrap()
            .then(() => {
              loadLessonData();

              if (removeId === resourceId) {
                const nextRsIndex = getNextResource(removeId);
                findById(nextRsIndex);
              }
              calBackFunc(resourceId);
            })
            .catch(() => {});
        }
      }
    );
  };

  const onInsert = (
    resourceId: number,
    calBackFunc: (clId: number) => void
  ) => {
    const request = {
      lessonId: props.lessonId,
      resourceId: resourceId,
      empty: true,
    };

    cloneResource(request)
      .unwrap()
      .then((clData) => {
        loadLessonData();
        setResourceId(clData.resourceId);
        calBackFunc(clData.resourceId);
      })
      .catch(() => {});
  };

  const onClone = (resourceId: number, calBackFunc: (clId: number) => void) => {
    const request = {
      lessonId: props.lessonId,
      resourceId: resourceId,
    };

    cloneResource(request)
      .unwrap()
      .then((clData) => {
        loadLessonData();

        setResourceId(clData.resourceId);
        calBackFunc(clData.resourceId);
      })
      .catch(() => {});
  };

  const onCreate = (
    resourceId: number,
    components: CompProps[],
    answers: CompAnswerProps[],
    scoring: CompScoringProps[],
    callBackFun: (res: number) => void
  ) => {
    const request: CreateResourceReq = {
      lessonId: props.lessonId,
      displayOrder: 1,
      content: JSON.stringify(components, null, 2),
      correctAnswer: JSON.stringify(answers, null, 2),
      scoring: JSON.stringify(scoring, null, 2),
    };

    createResource(request)
      .unwrap()
      .then((inData) => {
        loadLessonData();
        callBackFun(inData.resourceId);
      })
      .catch(() => {});
  };

  const onUpdate = (
    resourceId: number,
    components: CompProps[],
    answers: CompAnswerProps[],
    scoring: CompScoringProps[],
    callBackFun: (res: number) => void
  ) => {
    const request = {
      resourceId: resourceId,
      content: JSON.stringify(components, null, 2),
      correctAnswer: JSON.stringify(answers, null, 2),
      scoring: JSON.stringify(scoring, null, 2),
    };

    updateResourceContent(request)
      .unwrap()
      .then((upData) => {
        loadLessonData();
        callBackFun(upData.resourceId);
      })
      .catch(() => {});
  };

  const onCreateOrUpdateLesson = () => {
    console.log('trigger update lesson');
  };

  return (
    <LessonServiceContext.Provider
      value={{
        loading:
          isLoading ||
          rsLoading ||
          createLoading ||
          updateLoading ||
          removeLoading ||
          cloneLoading,
        getLessonData: loadLessonData,
        lesson,
        resourceId,
        resource,
        resources,
        viewResource,
        findById,
        onCreateOrUpdate,
        onDelete,
        onInsert,
        onClone,
        onCreateOrUpdateLesson,
      }}
    >
      {props.children}
    </LessonServiceContext.Provider>
  );
};
