import {
  AppstoreAddOutlined,
  AuditOutlined,
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ClearOutlined,
  LikeOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CommentOutlined,
  ControlOutlined,
  CopyOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  AlertOutlined,
  FundProjectionScreenOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ProfileOutlined,
  EllipsisOutlined,
  EyeOutlined,
  BarChartOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  FormOutlined,
  FundViewOutlined,
  GlobalOutlined,
  HddOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  InteractionOutlined,
  KeyOutlined,
  LeftOutlined,
  LoginOutlined,
  LogoutOutlined,
  MergeCellsOutlined,
  MessageOutlined,
  MobileOutlined,
  PicLeftOutlined,
  PictureOutlined,
  PieChartOutlined,
  PlaySquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RedoOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RightOutlined,
  SaveOutlined,
  SearchOutlined,
  SendOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SnippetsOutlined,
  SwapOutlined,
  TeamOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  UpOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
  LockOutlined,
  UnlockOutlined,
  FileProtectOutlined,
  PrinterOutlined,
  SolutionOutlined,
  VideoCameraAddOutlined,
  ScheduleOutlined,
  DoubleRightOutlined,
  CloudServerOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import React from 'react';

export const IconUtilsSVG = {
  session: `<svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="calendar"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
    </svg>`,
  assignment: `<svg viewBox="64 64 896 896" focusable="false" data-icon="snippets" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M832 112H724V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H500V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H320c-17.7 0-32 14.3-32 32v120h-96c-17.7 0-32 14.3-32 32v632c0 17.7 14.3 32 32 32h512c17.7 0 32-14.3 32-32v-96h96c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM664 888H232V336h218v174c0 22.1 17.9 40 40 40h174v338zm0-402H514V336h.2L664 485.8v.2zm128 274h-56V456L544 264H360v-80h68v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h152v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h68v576z"></path></svg>`,
};

export const IconUtils = {
  login: <LoginOutlined />,
  users: <UsergroupAddOutlined />,
  password: <KeyOutlined />,

  add_new: <PlusOutlined />,
  close: <CloseOutlined />,
  edit: <EditOutlined />,
  save: <SaveOutlined />,
  view_info: <InfoCircleOutlined />,
  review: <EyeOutlined />,

  table_of_content: <UnorderedListOutlined />,
  compose_lesson: <FileTextOutlined />,
  assign: <ReconciliationOutlined />,
  more_icon: <EllipsisOutlined />,
  session: <ClockCircleOutlined />,

  menu: {
    login: <DashboardOutlined />,
    dashboard: <DashboardOutlined />,
    calendar: <CalendarOutlined />,
    assignment: <SnippetsOutlined />,
    survey: <ProfileOutlined />,
    collection: <IdcardOutlined />,

    user: <UserOutlined />,
    student: <UserOutlined />,
    class: <TeamOutlined />,
    course: <ReconciliationOutlined />,
    invitation: <MergeCellsOutlined />,

    session: <ReadOutlined />,
    library: <ReadOutlined />,
    products: <BookOutlined />,
    lesson: <FileDoneOutlined />,
    setting: <SettingOutlined />,

    pages: <FileTextOutlined />,
    pages_template: <FileTextOutlined />,

    license: <FileProtectOutlined />,

    program: <AuditOutlined />,
    certificate: <SolutionOutlined />,

    resource: <AppstoreAddOutlined />,
    assets: <CloudServerOutlined />,
    standard: <ClusterOutlined />,
    ranking: <BarChartOutlined />,
  },

  actions: {
    search: <SearchOutlined />,
    access: <DoubleRightOutlined />,
    alert: <AlertOutlined />,
    users: <TeamOutlined />,
    select: <SendOutlined />,
    filter: <SearchOutlined />,
    save: <SaveOutlined />,
    edit: <EditOutlined />,
    revert: <UndoOutlined />,
    edit_metadata: <ReconciliationOutlined />,
    setting: <SettingOutlined />,

    review: <FundViewOutlined />,
    view_info: <InfoCircleOutlined />,
    move_up: <UpOutlined />,
    move_down: <DownOutlined />,

    add_more: <PlusSquareOutlined />,
    remove: <CloseSquareOutlined />,
    delete: <DeleteOutlined />,

    update: <CheckOutlined />,
    cancel: <ReloadOutlined />,

    clone: <CopyOutlined />,

    send_message: <SendOutlined />,
    share: <ShareAltOutlined />,
    invite_student: <UsergroupAddOutlined />,
    publish: <GlobalOutlined />,

    start_exercise: <SendOutlined />,

    previous: <LeftOutlined />,
    next: <RightOutlined />,
    expand: <UpOutlined />,
    collapse: <DownOutlined />,

    accept: <CheckCircleOutlined />,
    reject: <CloseCircleOutlined />,

    send: <SendOutlined />,

    swap: <SwapOutlined />,

    assign: <UserSwitchOutlined />,
    print: <PrinterOutlined />,
    disabled: <LockOutlined />,
    enabled: <UnlockOutlined />,
    report: <PieChartOutlined />,
    assign_license: <FileProtectOutlined />,
  },

  component: {
    upload: <CloudUploadOutlined />,
    change_to_text: <FontSizeOutlined />,
    change_to_image: <PictureOutlined />,
    change_to_audio: <PlaySquareOutlined />,
    change_to_video: <VideoCameraOutlined />,

    add_more: <PlusSquareOutlined />,
    remove: <CloseSquareOutlined />,
    switch_to_other: <RetweetOutlined />,
  },

  lesson: {
    save: <SaveOutlined />,
    edit: <FormOutlined />,
    review: <FundViewOutlined />,
    edit_standard: <FundViewOutlined />,
    edit_metadata: <ReconciliationOutlined />,
    validate: <FileSyncOutlined />,
    remove: <DeleteOutlined />,
    link_to_example: <FileDoneOutlined />,

    share_to_students: <ShareAltOutlined />,

    publish: <GlobalOutlined />,
    assign: <SnippetsOutlined />,
    setting: <SettingOutlined />,
    update: <SendOutlined />,
    standards: <ClusterOutlined />,

    extract: <ClearOutlined />,
    status: <InteractionOutlined />,
    exit_compose: <LogoutOutlined />,
    accessible: <ShareAltOutlined />,
  },

  compose: {
    multiple_question: <DatabaseOutlined />,
    single_question: <MobileOutlined />,
    build_lesson: <AppstoreAddOutlined />,
    build_section: <PicLeftOutlined />,

    review: <FundViewOutlined />,
    extract: <ControlOutlined />,
    exit_compose: <LogoutOutlined />,

    clone: <CopyOutlined />,
    delete: <DeleteOutlined />,

    move_up: <UpOutlined />,
    move_down: <DownOutlined />,
    setting: <PicLeftOutlined />,
    component_banks: <HddOutlined />,
  },

  exercise: {
    review: <UnorderedListOutlined />,
    save: <SaveOutlined />,
    submit: <SendOutlined />,
    add_comments: <CommentOutlined />,
    report: <PieChartOutlined />,
    check_answer: <CheckCircleOutlined />,
    try_again: <RedoOutlined />,
    start_over: <RedoOutlined />,

    grade: <AuditOutlined />,
  },

  assignment: {
    start: <SendOutlined />,
    edit: <FormOutlined />,
    grade: <AuditOutlined />,
    report: <PieChartOutlined />,
    remind: <MessageOutlined />,
  },

  class_room: {
    join_classroom: <FundProjectionScreenOutlined />,
    participants: <TeamOutlined />,
    chatting: <CommentOutlined />,

    record: <VideoCameraAddOutlined />,

    raise_hand: <LikeOutlined />,
    end_class: <LogoutOutlined />,

    session_info: <ScheduleOutlined />,
  },
};
