import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useMemo } from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { MultipleChoiceCompContextProvider } from './MultipleChoiceCompContext';
import MultipleChoiceOptions from '@cms/comps/interact/multiple-choice/MultipleChoiceOptions';

export interface MultipleChoiceItemProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface MultipleChoiceSettingProps extends CompInteractSettingProps {
  layout: 'horizontal' | 'vertical' | '2x2';
  showLabel: boolean;
  showOption: boolean;
  multiple: boolean;
}

export interface MultipleChoiceCompProps extends CompProps {
  type: CompTypeEnum.MULTIPLE_CHOICE;
  configuration: {
    sourceItems: MultipleChoiceItemProps[];
  };
  setting: MultipleChoiceSettingProps;
}

export interface MultipleChoiceAnsProps extends CompAnswerProps {
  answer: string[];
}

export const MultipleChoiceComp = (props: {
  disabled: boolean;
  item: MultipleChoiceCompProps;
  answer: MultipleChoiceAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const componentClassName = useMemo(() => {
    let className = '';

    if (props.item.setting) {
      if (props.item.setting.showOption) {
        className += ' show-option ';
      } else {
        className += ' hide-option ';
      }

      if (props.item.setting.showLabel) {
        className += ' show-label ';
      } else {
        className += ' hide-label ';
      }

      if (props.item.setting.multiple) {
        className += ' multiple-select ';
      } else {
        className += ' single-select ';
      }

      if (props.item.setting.layout) {
        className += ' layout-' + props.item.setting.layout;
      }
    }

    return className;
  }, [props.item.setting]);

  return (
    <MCCompStyle
      className={
        `comps-mc ${props.item.setting?.className} ` + componentClassName
      }
    >
      <MultipleChoiceCompContextProvider
        disabled={props.disabled}
        item={props.item}
        answer={props.answer}
        feedback={props.feedback}
        onChange={props.onChange}
      >
        <MultipleChoiceOptions />

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </MultipleChoiceCompContextProvider>
    </MCCompStyle>
  );
};

const MCCompStyle = styled.div`
  position: relative;

  &.hide-option {
    .mc-option {
      gap: 0.5em;

      .mc-content {
        margin: 0;
      }
    }

    &.layout-2x2 {
      .mc-panel .mc-option {
        max-width: calc(50% - 0.5em);
      }
    }
  }

  &:hover {
    .mc-panel {
      z-index: 3;
    }

    .component-grading-background {
      opacity: 0.6;
    }
  }

  &.layout-horizontal {
    .mc-panel {
      display: flex;
      flex-direction: row;
      gap: 1em;
    }
  }

  &.layout-vertical {
    .mc-panel {
      display: flex;
      flex-direction: column;

      .mc-option {
        &:not(:first-child) {
          margin-top: var(--cms-padding-option, 0.5em);
        }
      }
    }
  }

  &.layout-2x2 {
    .mc-panel {
      display: flex;
      flex-wrap: wrap;

      .mc-option {
        width: 50%;
        max-width: 50%;

        &:not(:first-child) {
          &:not(:nth-child(2)) {
            margin-top: var(--cms-padding-option, 0.5em);
          }
        }
      }
    }
  }

  .mc-option {
    &.correct-part .mc-item {
      color: ${(props) => props.theme.component.correct} !important;
    }

    &.incorrect-part .mc-item {
      color: ${(props) => props.theme.component.incorrect} !important;
    }
  }
`;
