import { ResourceProps } from '@modules/product/components/resource/Resource';
import {
  CompAnswerProps,
  CompProps,
  CompScoringProps,
  CompTypeEnum,
  INTERACT_COMPONENT_TYPES,
  MANUAL_SCORING_COMPONENT_TYPES,
} from '@cms/ComponentInteface';
import { ResourceAnswerProps } from '@app/redux/slices/viewResource';
import { WordPuzzleValidationUtils } from '@cms/comps/game/word-puzzle/WordPuzzleValidation';
import { WordPuzzleCompProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import { WordSearchValidationUtils } from '@cms/comps/game/word-search/WordSearchValidation';
import { WordSearchCompProps } from '@cms/comps/game/word-search/WordSearchComp';
import { WordBoxValidationUtils } from '@cms/comps/game/word-box/WordBoxValidation';
import { WordBoxCompProps } from '@cms/comps/game/word-box/WordBoxComp';
import { WordScrambleValidationUtils } from '@cms/comps/game/word-scramble/WordScrambleValidation';
import { WordScrambleCompProps } from '@cms/comps/game/word-scramble/WordScrambleComp';
import { MultipleChoiceValidation } from '@cms/comps/interact/multiple-choice/MultipleChoiceValidation';
import {
  MultipleChoiceAnsProps,
  MultipleChoiceCompProps,
} from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
// import { SelectTextValidation } from '@cms/comps/interact/dropdown/SelectTextValidation';
// import {
//   DropDownAnsProps,
//   DropDownProps,
// } from '@cms/comps/interact/dropdown/SelectTextComp';
import { MatchingValidation } from '@cms/comps/interact/matching/MatchingValidation';
import {
  MatchingCompAnsProps,
  MatchingCompProps,
} from '@cms/comps/interact/matching/MatchingComp';
import { DragNDropValidation } from '@cms/comps/interact/drag-n-drop/DragNDropValidation';
import {
  DragNDropAnsProps,
  DragNDropCompProps,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import SectionUtils from '../section-bank/SectionUtils';
import {
  ComponentResponseProps,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import { ResourceRes } from '@modules/product/services/resource_model';
import { LessonSectionRes } from '@modules/product/services/lesson_model';

export interface ComponentValidationProps {
  updateComponent: boolean;
  newComponent: CompProps;
  updateAnswer: boolean;
  newAnswer: CompAnswerProps;
}

export interface ResourceContentDisplayProps {
  resourceId: number;
  questionNumber: number | null;
  resource: ResourceProps;
  answers: CompAnswerProps[];
  correctAnswers: CompAnswerProps[];
  feedBacks: ComponentResponseProps[];
  scoring: ExerciseResponseRes | null;
}

export class ResourceSupportUtils {
  convert = (
    item: ResourceRes,
    group: string,
    sectionId: number
  ): ResourceProps => {
    const components = item.content ? item.content.components : [];
    const correctAnswer = item.content ? item.content.correctAnswer : [];
    const scoring = item.content ? item.content.scoring : [];

    return {
      ...item,
      components: components,
      correctAnswer: correctAnswer,
      scoring: scoring,
      group: group,
      sectionId: sectionId,
    };
  };

  getSections = (sections: LessonSectionRes[]): LessonSectionProps[] => {
    let questionNumber = 1;

    if (sections != null && sections.length > 0) {
      return sections.map((sec) => {
        const result = SectionUtils.convertSection(sec, questionNumber);
        questionNumber += result.totalQuestion;
        return result.section;
      });
    } else {
      return [];
    }
  };

  getResource = (sec: LessonSectionRes): ResourceProps[] => {
    const resources: ResourceProps[] = [];

    let questionNumber = 1;
    if (sec.header != null && sec.header.length > 0) {
      sec.header.forEach((rs) => {
        const resource = {
          ...this.convert(rs, 'header', sec.lessonSectionId),
        };
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...resource, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(resource);
        }
      });
    }

    if (sec.content != null && sec.content.length > 0) {
      sec.content.forEach((rs) => {
        const resource = {
          ...this.convert(rs, 'content', sec.lessonSectionId),
        };
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...resource, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(resource);
        }
      });
    }

    if (sec.footer != null && sec.footer.length > 0) {
      sec.footer.forEach((rs) => {
        const resource = {
          ...this.convert(rs, 'footer', sec.lessonSectionId),
        };
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...resource, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(resource);
        }
      });
    }

    return resources;
  };

  getResourceFromSection = (sec: LessonSectionProps): ResourceProps[] => {
    const resources: ResourceProps[] = [];

    let questionNumber = 1;
    if (
      sec.group != null &&
      sec.group.header != null &&
      sec.group.header.length > 0
    ) {
      sec.group.header.forEach((rs) => {
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...rs, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(rs);
        }
      });
    }

    if (
      sec.group != null &&
      sec.group.content != null &&
      sec.group.content.length > 0
    ) {
      sec.group.content.forEach((rs) => {
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...rs, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(rs);
        }
      });
    }

    if (
      sec.group != null &&
      sec.group.footer != null &&
      sec.group.footer.length > 0
    ) {
      sec.group.footer.forEach((rs) => {
        if (rs.validation != null && rs.validation.interact) {
          resources.push({ ...rs, questionNumber: questionNumber });
          questionNumber += 1;
        } else {
          resources.push(rs);
        }
      });
    }

    return resources;
  };

  getResourcesFromSections = (
    sections: LessonSectionProps[]
  ): ResourceProps[] => {
    const resources: ResourceProps[] = [];
    if (sections != null && sections.length > 0) {
      let questionNumber = 1;

      sections.forEach((sec) => {
        if (sec.group.header != null && sec.group.header.length > 0) {
          sec.group.header.forEach((rs) => {
            if (rs.validation != null && rs.validation.interact) {
              resources.push({
                ...rs,
                questionNumber: questionNumber,
                sectionId: sec.lessonSectionId,
                group: 'header',
              });
              questionNumber += 1;
            } else {
              resources.push({
                ...rs,
                sectionId: sec.lessonSectionId,
                group: 'header',
              });
            }
          });
        }

        if (sec.group.content != null && sec.group.content.length > 0) {
          sec.group.content.forEach((rs) => {
            if (rs.validation != null && rs.validation.interact) {
              resources.push({
                ...rs,
                questionNumber: questionNumber,
                sectionId: sec.lessonSectionId,
                group: 'content',
              });
              questionNumber += 1;
            } else {
              resources.push({
                ...rs,
                sectionId: sec.lessonSectionId,
                group: 'content',
              });
            }
          });
        }

        if (sec.group.footer != null && sec.group.footer.length > 0) {
          sec.group.footer.forEach((rs) => {
            if (rs.validation != null && rs.validation.interact) {
              resources.push({
                ...rs,
                questionNumber: questionNumber,
                sectionId: sec.lessonSectionId,
                group: 'footer',
              });
              questionNumber += 1;
            } else {
              resources.push({
                ...rs,
                sectionId: sec.lessonSectionId,
                group: 'footer',
              });
            }
          });
        }
      });
    }

    return resources;
  };

  getResources = (sections: LessonSectionRes[]): ResourceProps[] => {
    const resources: ResourceProps[] = [];

    if (sections != null && sections.length > 0) {
      let questionNumber = 1;
      sections.forEach((sec) => {
        if (sec.header != null && sec.header.length > 0) {
          sec.header.forEach((rs) => {
            const resource = {
              ...this.convert(rs, 'header', sec.lessonSectionId),
            };
            if (rs.validation != null && rs.validation.interact) {
              resources.push({ ...resource, questionNumber: questionNumber });
              questionNumber += 1;
            } else {
              resources.push(resource);
            }
          });
        }

        if (sec.content != null && sec.content.length > 0) {
          sec.content.forEach((rs) => {
            const resource = {
              ...this.convert(rs, 'content', sec.lessonSectionId),
            };
            if (rs.validation != null && rs.validation.interact) {
              resources.push({ ...resource, questionNumber: questionNumber });
              questionNumber += 1;
            } else {
              resources.push(resource);
            }
          });
        }

        if (sec.footer != null && sec.footer.length > 0) {
          sec.footer.forEach((rs) => {
            const resource = {
              ...this.convert(rs, 'footer', sec.lessonSectionId),
            };
            if (rs.validation != null && rs.validation.interact) {
              resources.push({ ...resource, questionNumber: questionNumber });
              questionNumber += 1;
            } else {
              resources.push(resource);
            }
          });
        }
      });
    }

    return resources;
  };

  convertResult = (item: ExerciseResponseRes) => {
    const answers = item.answer;
    const results = item.result;
    return { ...item, answers: answers, results: results };
  };

  isSkip = (answers: CompAnswerProps[]) => {
    if (answers == null || answers.length === 0) {
      return true;
    } else {
      let answered = false;
      try {
        answers.forEach((item) => {
          const isSkip =
            item.answer == null ||
            (typeof item.answer == 'string' && item.answer.trim() === '') ||
            (Array.isArray(item.answer) && item.answer.length === 0);
          if (!isSkip) {
            answered = true;
          }
        });
      } catch (e) {
        console.log(e, answers);
      }
      return !answered;
    }
  };

  getDefaultComponentScore = (type: CompTypeEnum) => {
    if ([CompTypeEnum.FIB_PARAGRAPH].includes(type)) {
      return 2;
    } else if ([CompTypeEnum.AUDIO_RECORDING].includes(type)) {
      return 1;
    } else if ([CompTypeEnum.FIB_ESSAY].includes(type)) {
      return 4;
    } else if ([CompTypeEnum.COORDINATE].includes(type)) {
      return 2;
    } else if (
      [
        CompTypeEnum.LONG_ADDITION,
        CompTypeEnum.LONG_SUBTRACTION,
        CompTypeEnum.LONG_MULTIPLICATION,
        CompTypeEnum.LONG_DIVISION,
        CompTypeEnum.AREA_MULTIPLICATION,
        CompTypeEnum.AREA_DIVISION,
      ].includes(type)
    ) {
      return 2;
    } else if ([CompTypeEnum.FIB_EQUATION].includes(type)) {
      return 2;
    } else {
      return 1;
    }
  };

  isInteractComp = (type: CompTypeEnum) => {
    return INTERACT_COMPONENT_TYPES.includes(type);
  };

  onlySupportManualScore = (type: CompTypeEnum) => {
    return MANUAL_SCORING_COMPONENT_TYPES.includes(type);
  };

  getResourceContents = (
    resources: ResourceProps[],
    answers: ResourceAnswerProps[],
    correctAnswers: ResourceAnswerProps[],
    feedBacks: ExerciseResponseRes[]
  ): ResourceContentDisplayProps[] => {
    let questionNumber = 0;

    const resourceItems: ResourceContentDisplayProps[] = resources.map(
      (item) => {
        const _answers = answers.filter((ans) => {
          return item.resourceId === ans.resourceId;
        });

        const _correctAnswers = correctAnswers.filter((ans) => {
          return item.resourceId === ans.resourceId;
        });

        const _feedbacks = feedBacks.filter((fb) => {
          return item.resourceId === fb.resourceId;
        });

        if (item.validation?.interact) {
          questionNumber += 1;
        }

        return {
          resourceId: item.resourceId,
          questionNumber: item.validation?.interact ? questionNumber : null,
          resource: item,
          answers: _answers.length > 0 ? _answers[0].answers : [],
          correctAnswers:
            _correctAnswers.length > 0 ? _correctAnswers[0].answers : [],
          scoring: _feedbacks.length > 0 ? _feedbacks[0] : null,
          feedBacks: _feedbacks.length > 0 ? _feedbacks[0].result : [],
        };
      }
    );

    return resourceItems;
  };

  validateComponents = (components: CompProps[]): CompProps[] => {
    const componentResults: CompProps[] = [];
    components.forEach((comp) => {
      if (comp.type === CompTypeEnum.WORD_PUZZLE) {
        componentResults.push(
          WordPuzzleValidationUtils.validation(comp as WordPuzzleCompProps)
        );
      } else if (comp.type === CompTypeEnum.WORD_SEARCH) {
        componentResults.push(
          WordSearchValidationUtils.validation(comp as WordSearchCompProps)
        );
      } else {
        componentResults.push({ ...comp });
      }
    });

    return componentResults;
  };

  validateResources = (resources: ResourceProps[]): Record<number, boolean> => {
    const mapResourceError: Record<number, boolean> = {};

    resources.forEach((rs) => {
      const errors = this.validateResource(
        rs.components,
        rs.correctAnswer,
        rs.scoring
      );

      if (errors != null) {
        mapResourceError[rs.resourceId] = true;
      }
    });

    return mapResourceError;
  };

  validateResource = (
    components: CompProps[],
    answers: CompAnswerProps[],
    scoring: CompScoringProps[]
  ): Record<string, string[]> | null => {
    const mapResourceError: Record<string, string[]> = {};

    const answerMapping: Record<string, boolean> = {};
    answers.forEach((ans) => {
      answerMapping[ans.id] = !(
        ans.answer === null ||
        ans.answer === '' ||
        ans.answer.length === 0
      );
    });

    const scoringMapping: Record<string, boolean> = {};
    scoring.forEach((ans) => {
      scoringMapping[ans.id] = true;
    });

    let hasError = false;
    components.forEach((comp) => {
      if (INTERACT_COMPONENT_TYPES.includes(comp.type)) {
        const error = [];
        const hasAnswer = answerMapping[comp.id];
        const hasScoring = scoringMapping[comp.id];

        if (!hasAnswer) {
          error.push('component.label.missing_correct_answer');
        }

        if (!hasScoring) {
          error.push('component.label.missing_scoring');
        }

        if (error.length > 0) {
          hasError = true;
          mapResourceError[comp.id] = error;
        }
      }
    });

    return hasError ? mapResourceError : null;
  };

  validateComponentAndAnswer = (
    components: CompProps[],
    answers: CompAnswerProps[]
  ): {
    updateComponents: boolean;
    updateAnswers: boolean;
    newComponents: CompProps[];
    newAnswers: CompAnswerProps[];
  } => {
    const answerMapping: Record<string, CompAnswerProps> = {};
    answers.forEach((ans) => {
      answerMapping[ans.id] = ans;
    });

    let needUpdateComponent = false;
    let needUpdateAnswer = false;

    const newComponents: CompProps[] = [];
    const newAnswers: CompAnswerProps[] = [];

    [...components].forEach((comp) => {
      const answer = answerMapping[comp.id];

      if (answer != null) {
        let compResult = null;

        if (comp.type === CompTypeEnum.MULTIPLE_CHOICE) {
          compResult = MultipleChoiceValidation.validateComponentAndAnswer(
            comp as MultipleChoiceCompProps,
            answer as MultipleChoiceAnsProps
          );
          // } else if (comp.type == CompTypeEnum.DROPDOWN) {
          //   compResult = SelectTextValidation.validateComponentAndAnswer(
          //     comp as DropDownProps,
          //     answer as DropDownAnsProps
          //   );
        } else if (comp.type === CompTypeEnum.MATCHING) {
          compResult = MatchingValidation.validateComponentAndAnswer(
            comp as MatchingCompProps,
            answer as MatchingCompAnsProps
          );
        } else if (comp.type === CompTypeEnum.DRAG_N_DROP) {
          compResult = DragNDropValidation.validateComponentAndAnswer(
            comp as DragNDropCompProps,
            answer as DragNDropAnsProps
          );
        }
        // else if (comp.type == CompTypeEnum.DRAG_N_DROP_INLINE) {
        //   compResult = DragNDropValidation.validateComponentAndAnswer(
        //     comp as DragNDropCompProps,
        //     answer as DragNDropAnsProps
        //   );
        // }

        if (
          compResult != null &&
          (compResult.updateAnswer || compResult.updateComponent)
        ) {
          if (compResult.updateComponent) {
            needUpdateComponent = true;
            newComponents.push(compResult.newComponent);
          }

          if (compResult.updateAnswer) {
            needUpdateAnswer = true;
            newAnswers.push(compResult.newAnswer);
          }
        } else {
          newComponents.push({ ...comp });
          newAnswers.push(answer);
        }
      } else {
        newComponents.push({ ...comp });
        if (answer != null) {
          newAnswers.push(answer);
        }
      }
    });

    return {
      updateComponents: needUpdateComponent,
      updateAnswers: needUpdateAnswer,
      newComponents: newComponents,
      newAnswers: newAnswers,
    };
  };

  generateInteractScoring = (comp: CompProps): CompScoringProps => {
    if (comp.type === CompTypeEnum.WORD_BOX) {
      return WordBoxValidationUtils.getScoring(comp as WordBoxCompProps);
      //
    } else if (comp.type === CompTypeEnum.WORD_PUZZLE) {
      return WordPuzzleValidationUtils.getScoring(comp as WordPuzzleCompProps);
      //
    } else if (comp.type === CompTypeEnum.WORD_SCRAMBLE) {
      return WordScrambleValidationUtils.getScoring(
        comp as WordScrambleCompProps
      );
      //
    } else if (comp.type === CompTypeEnum.WORD_SEARCH) {
      return WordSearchValidationUtils.getScoring(comp as WordSearchCompProps);
      //
    } else {
      const isManualScore = MANUAL_SCORING_COMPONENT_TYPES.includes(comp.type);
      const maxScore = this.getDefaultComponentScore(comp.type);

      return {
        id: comp.id,
        type: comp.type,
        manualScore: isManualScore,
        maxScore: maxScore,
      };
    }
  };

  generationScoring = (components: CompProps[]): CompScoringProps[] => {
    const scorings: CompScoringProps[] = [];

    components.forEach((comp) => {
      if (this.isInteractComp(comp.type)) {
        const compScoring = this.generateInteractScoring(comp);
        scorings.push(compScoring);
      }
    });

    return scorings;
  };

  validateScoring = (
    scorings: CompScoringProps[],
    components: CompProps[]
  ): CompScoringProps[] => {
    const mapCompProps: Record<string, CompProps> = {};

    components.forEach((comp) => {
      mapCompProps[comp.id] = comp;
    });

    const componentScoring: CompScoringProps[] = [];

    scorings.forEach((scoring) => {
      if (scoring.type === CompTypeEnum.WORD_BOX) {
        componentScoring.push(
          WordBoxValidationUtils.getScoring(
            mapCompProps[scoring.id] as WordBoxCompProps
          )
        );
      } else if (scoring.type === CompTypeEnum.WORD_PUZZLE) {
        console.log('CompTypeEnum.WORD_PUZZLE');
        componentScoring.push(
          WordPuzzleValidationUtils.getScoring(
            mapCompProps[scoring.id] as WordPuzzleCompProps
          )
        );
      } else if (scoring.type === CompTypeEnum.WORD_SCRAMBLE) {
        componentScoring.push(
          WordScrambleValidationUtils.getScoring(
            mapCompProps[scoring.id] as WordScrambleCompProps
          )
        );
      } else if (scoring.type === CompTypeEnum.WORD_SEARCH) {
        componentScoring.push(
          WordSearchValidationUtils.getScoring(
            mapCompProps[scoring.id] as WordSearchCompProps
          )
        );
      } else {
        componentScoring.push({ ...scoring });
      }
    });

    return componentScoring;
  };
}

export const ResourceUtils = new ResourceSupportUtils();
