import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import ExerciseLessonReportPage from '@modules/reports/exercises/ExerciseReportPage';

const ViewExerciseReport = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { lessonId } = router.query as unknown as { lessonId: number };

  return (
    <ManagementLayout className={'view-exerice-report-page'}>
      <PageHeader
        menu={NavigationMenuEnum.REPORTS}
        subMenu={NavigationMenuEnum.REPORTS_LESSONS}
        title={t('report.view_lesson_record')}
        description={t('report.view_lesson_record')}
      />

      <ExerciseLessonReportPage lessonId={lessonId} />
    </ManagementLayout>
  );
};

export default ViewExerciseReport;
