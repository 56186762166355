import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';

const TimestampViewerEditable = (props: {
  from: number;
  to: number;
  onChange: (from: number, to: number) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      from: 0,
      to: -1,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setValues({
      from: props.from,
      to: props.to,
    });
  }, [props.from, props.to]);

  const triggerChange = () => {
    props.onChange(formik.values.from, formik.values.to);
  };

  return (
    <FormikProvider value={formik}>
      <Input
        addedClass={'from-group'}
        name="from"
        type="number"
        label={t('component.label.from')}
        min={0}
        value={formik.values.from}
        onChange={formik.handleChange}
        onBlur={triggerChange}
      />

      <InputGroup label={<>&nbsp;</>}>:</InputGroup>

      <Input
        addedClass={'to-group'}
        name="to"
        type="number"
        label={t('component.label.to')}
        value={formik.values.to}
        onChange={formik.handleChange}
        onBlur={triggerChange}
      />
    </FormikProvider>
  );
};

export default TimestampViewerEditable;
