import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import React from 'react';
import styled from 'styled-components';
import { LessonSkeleton } from '../lesson-placeholder/LessonSkeleton';
import { getTemplateStyle } from '@cms/design-template/layout/LessonTemplateLayoutEditable';
import { LessonTemplateSettingProps } from '@cms/design-template/layout/props';

const ViewLessonLayoutTemplate = (props: {
  singleMode: boolean;
  singleQuestionMode: boolean;
  stepByStep: boolean;
  type: LessonTemplateLayoutEnum;
  setting?: LessonTemplateSettingProps;
}) => {
  return (
    <ViewLessonLayoutTemplateStyle className={'view-lesson-layout-template'}>
      {getTemplateStyle('view-lesson-layout-template', props.setting)}

      {props.setting?.stylesheet && (
        <header
          dangerouslySetInnerHTML={{ __html: props.setting?.stylesheet }}
        />
      )}

      {props.type === LessonTemplateLayoutEnum.letter && (
        <div className={'lesson-letter-layout'}>
          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[1, 4]}
          />
        </div>
      )}

      {props.type === LessonTemplateLayoutEnum.letter_horizontal && (
        <div className={'lesson-letter-horizontal-layout'}>
          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[1, 4]}
          />
        </div>
      )}

      {props.type === LessonTemplateLayoutEnum.responsive && (
        <div className={'lesson-letter-responsive-layout'}>
          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[1, 4]}
          />
        </div>
      )}

      {props.type === LessonTemplateLayoutEnum.two_page && (
        <div className={'lesson-two-page-layout'}>
          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[1]}
          />

          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[4]}
          />
        </div>
      )}

      {props.type === LessonTemplateLayoutEnum.presentation && (
        <div className={'lesson-presentation-layout'}>
          <LessonSkeleton
            header={props.setting?.header}
            footer={props.setting?.footer}
            singleMode={props.singleMode}
            singleQuestionMode={props.singleQuestionMode}
            stepByStep={props.stepByStep}
            sections={[1, 4]}
          />
        </div>
      )}

      {props.setting?.script && (
        <footer dangerouslySetInnerHTML={{ __html: props.setting?.script }} />
      )}
    </ViewLessonLayoutTemplateStyle>
  );
};

export default ViewLessonLayoutTemplate;

const ViewLessonLayoutTemplateStyle = styled.div`
  * {
    box-sizing: border-box;
  }

  .lesson-skeleton-item {
    position: relative;
  }

  .lesson-letter-layout .lesson-skeleton-item {
    width: 50%;
    aspect-ratio: 594 / 814;
  }

  .lesson-letter-horizontal-layout .lesson-skeleton-item {
    width: 70%;
    aspect-ratio: 814 / 594;
  }

  .lesson-letter-responsive-layout .lesson-skeleton-item {
    width: 80%;
    aspect-ratio: 16 / 9;
  }

  .lesson-two-page-layout {
    display: flex;
    gap: 1em;

    .lesson-skeleton-item {
      width: calc(50% - 1em);
      aspect-ratio: 594 / 814;
    }
  }

  .lesson-presentation-layout {
    .lesson-skeleton-item {
      aspect-ratio: 16 / 9;
      overflow: hidden;
    }
  }
`;
