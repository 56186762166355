import { SessionAPI } from '@services/private/SessionAPI';
import React, { useEffect, useState } from 'react';
import CalendarEventItem from '@modules/calendar/components/calendar-event/CalendarEvent';
import Card, { CardClassEnum } from '@components/card';
import { H3 } from '@components/typography';
import { InComingEventStyle } from './IncomingEvents';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { SessionRes } from '@services/model/session';

const InComingStudentEvent = () => {
  const { t } = useTranslation();

  const [getIncomingEvent, { data, isLoading }] =
    SessionAPI.endpoints.getIncomingEvent.useLazyQuery({});

  const [selectedEvent, setSelectedEvent] = useState<SessionRes | null>(null);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    getIncomingEvent({});
  };

  const handleOnView = (event: SessionRes) => {
    setSelectedEvent(event);
  };

  return (
    <>
      <InComingEventStyle className={'incoming-events'}>
        <H3>{t('dashboard.label.incoming_event')}</H3>

        <List
          itemLayout="horizontal"
          loading={isLoading}
          dataSource={data?.content}
          renderItem={(event) => (
            <List.Item>
              <Card
                hoverable
                className={CardClassEnum.rectangle}
                key={event.sessionId}
              >
                <CalendarEventItem
                  type={'view'}
                  session={event}
                  onChange={refreshData}
                  onView={handleOnView}
                />
              </Card>
            </List.Item>
          )}
        />

        <ButtonGroup type={'right'}>
          <Button size={'small'} type={'primary'}>
            {t('button.view_all')}
          </Button>
        </ButtonGroup>

        {selectedEvent && (
          <CustomModal
            header={t('session.header')}
            className={CustomModalClassEnum.full_size_modal}
            content={<PublicSessionInfo item={selectedEvent} />}
            onCloseFunc={() => setSelectedEvent(null)}
          />
        )}
      </InComingEventStyle>
    </>
  );
};

export default InComingStudentEvent;
