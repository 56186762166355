import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import { FractionTypeEnum } from './FractionInlineComp';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { FractionCompContextProvider } from '@cms/comps/math/frac/FractionCompContext';
import FractionCompEditable from './FractionEditableData';
import FractionGroupItems from './FractionGroupItem';

export interface FractionOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface FractionSettingProps extends CompInteractSettingProps {
  digit: string;
  type: FractionTypeEnum;
}

export interface FractionCompProps extends CompProps {
  type: CompTypeEnum.FIB_FRACTION;
  configuration: {
    targetItems: FractionOptionProps[];
  };
  setting: FractionSettingProps;
}

export interface FractionCompAnsProps extends CompAnswerProps {
  answer: string[];
}

export const FractionComp = (props: {
  disabled: boolean;
  item: FractionCompProps;
  answer: FractionCompAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);

  return (
    <FractionCompContextProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <FractionCompStyle
        className={`comp comp-fraction ${props.item.setting?.className}`}
      >
        {showComponent && (
          <>
            <FractionGroupItems />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </>
        )}

        <ComposeModeContent>
          <div className={'fib-inline-group'}>
            {!showComponent && (
              <FractionCompEditable
                item={props.item}
                onChange={props.onChange}
                onClose={() => setShowComponent(true)}
              />
            )}
          </div>
        </ComposeModeContent>

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </FractionCompStyle>
    </FractionCompContextProvider>
  );
};

const FractionCompStyle = styled.div`
  position: relative;

  .comps-fraction-input-group {
    display: flex;
    align-items: center;
  }
`;
