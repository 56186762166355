import React from 'react';

import { RoleEnum } from '@app/redux/slices/roles';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import CurrencyManagementPage from '@modules/payment/currency/pages/CurrencyManagementPage';
import { PageHeader } from '@app/header/PageHeader';

const CurrencySetting = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'currency-setting-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_PAYMENT_CURRENCY}
        title={t('currency.header')}
        description={t('currency.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <CurrencyManagementPage />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        Setup currency
      </Authorization>
    </ManagementLayout>
  );
};

export default CurrencySetting;
