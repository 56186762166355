import React, { createContext, useContext, useEffect, useState } from 'react';
import { CompAnswerProps, CompProps } from '@cms/ComponentInteface';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompGroupEnum } from '@cms/utils/CompProps';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import produce from 'immer';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import {
  generatorMatchingAns,
  getMatchingAns,
  MatchingCompAnsProps,
  MatchingCompProps,
  MatchingItemCompProps,
  MatchingSettingProps,
} from '@cms/comps/interact/matching/MatchingComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import { ContentOperationEnum } from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';

export interface SelectDragNDropItem {
  item: MatchingItemCompProps | null;
  type: 'source' | 'target';
}

const MatchingCompContext = createContext({
  modifiedTime: -1 as number,
  disabled: false as boolean,

  originalData: {} as MatchingCompProps,
  setting: {} as MatchingSettingProps,
  sourceItems: [] as MatchingItemCompProps[],
  targetItems: [] as MatchingItemCompProps[],

  disableSource: [] as string[],
  disableTarget: [] as string[],
  selectItem: { item: null, type: 'source' } as SelectDragNDropItem,
  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  onRemoveAnswer: (removeAns: string) => {
    console.log(removeAns);
  },

  onAddAnswer: (ansPart: string) => {
    console.log(ansPart);
  },

  onDropData: (answer: string) => {
    console.log(answer);
  },

  onSelectItem: (item: MatchingItemCompProps, type: 'source' | 'target') => {
    console.log(item, type);
  },

  // for editable
  updateOptions: (options: MatchingItemCompProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  updateComponent: () => {},

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(type, index, option, from);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(operation, index, option, from);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    console.log(data, index, option, from);
  },
});

export const MatchingContextProvider = (props: {
  disabled: boolean;
  item: MatchingCompProps;
  answer: MatchingCompAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [answer, setCompAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });
  const [feedback, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  const [selectItem, setSelectItems] = useState<SelectDragNDropItem>({
    item: null,
    type: 'source',
  });

  const [disableSource, setDisableSource] = useState<string[]>([]);
  const [disableTarget, setDisableTarget] = useState<string[]>([]);

  useEffect(() => {
    if (props.answer && props.answer.answer) {
      setCompAnswer({
        value: props.answer && props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    updateDisabledGroup(answer.value);
  }, [answer, props.item.setting]);

  const updateDisabledGroup = (answers: string[]) => {
    const disabledSrc: string[] = [];
    const disabledTgt: string[] = [];

    // each object is used one time
    if (props.item.setting.layout === 'one-to-one') {
      answers.forEach((item) => {
        const ans = getMatchingAns(item);
        disabledSrc.push(ans.source);
        disabledTgt.push(ans.target);
      });
    } else if (props.item.setting.layout === 'one-to-many') {
      answers.forEach((item) => {
        const ans = getMatchingAns(item);
        disabledTgt.push(ans.target);
      });
    } else if (props.item.setting.layout === 'many-to-many') {
      // drag everytime...
    }

    setDisableSource(disabledSrc);
    setDisableTarget(disabledTgt);
  };

  const onRemoveAnswer = (removeAns: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const newAns = prevAns.value.filter((ans) => {
          return ans !== removeAns;
        });

        return {
          value: newAns,
          triggerChange: true,
        };
      });
    }
  };

  const onAddAnswer = (ansPart: string) => {
    if (!props.disabled) {
      setCompAnswer((prevAns) => {
        const newAns = [...prevAns.value, ansPart];
        return {
          value: newAns,
          triggerChange: true,
        };
      });
    }
  };

  // ------------------- custom data ------------------------------------

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );
  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems
  );

  const updateOptions = (
    options: MatchingItemCompProps[],
    type: CompGroupEnum
  ) => {
    if (type === CompGroupEnum.source) {
      setSourceItems(options);
    } else {
      setTargetItems(options);
    }

    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
        targetItems: targetItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  const onDropData = (ansPart: string) => {
    onAddAnswer(ansPart);
  };

  const onSelectItem = (
    item: MatchingItemCompProps,
    type: 'source' | 'target'
  ) => {
    if (selectItem.item == null) {
      setSelectItems({
        item: item,
        type: type,
      });
    } else if (selectItem.type === type) {
      if (type === 'source') {
        setSelectItems({
          item: item,
          type: type,
        });
      } else {
        clearDropItemAnswers(item);
        setSelectItems({
          item: null,
          type: 'source',
        });
      }
    } else {
      if (
        (selectItem.type === 'source' && type === 'target') ||
        (selectItem.type === 'target' && type === 'source')
      ) {
        if (selectItem.type === 'source' && type === 'target') {
          const ansPart = generatorMatchingAns(
            selectItem.item.label,
            item.label
          );
          onDropData(ansPart);
        } else {
          const ansPart = generatorMatchingAns(
            item.label,
            selectItem.item.label
          );
          onDropData(ansPart);
        }

        setSelectItems({
          item: null,
          type: 'source',
        });
      }
    }
  };

  const clearDropItemAnswers = (dropItem: MatchingItemCompProps) => {
    setCompAnswer((prevAns) => {
      const reduceAnswer = [...prevAns.value].filter((str) => {
        const answerPart = getMatchingAns(str);
        return answerPart.target !== dropItem.label;
      });

      return {
        value: reduceAnswer,
        triggerChange: true,
      };
    });
  };

  // for editable
  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    if (from === CompGroupEnum.source) {
      const newSourceItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        sourceItems
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        targetItems
      );
      updateOptions(newTargetItems, from);
    }
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    let newOptions: MatchingItemCompProps[] = [];
    let newAnswers: string[] = [];

    if (from === CompGroupEnum.source) {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleInsertOption(
          newOptions[index + 1].label,
          answer.value
        );

        // then, update correct answer.
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          sourceItems,
          OptionTypeEnum.ABC
        );

        newAnswers = handleRemoveOption(option.label, answer.value);
      }
    } else {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleInsertGroup(index, answer.value);
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleRemoveGroup(index + 1, answer.value);
      }
    }

    // then update data...
    updateOptions(newOptions, from);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: MatchingItemCompProps,
    from: CompGroupEnum
  ) => {
    const newOption = { label: option.label, content: { ...data } };

    if (from === CompGroupEnum.source) {
      const newSourceItems = COMPONENT_UTILS.updateAtIndex(
        sourceItems,
        index,
        newOption
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = COMPONENT_UTILS.updateAtIndex(
        targetItems,
        index,
        newOption
      );
      updateOptions(newTargetItems, from);
    }
  };

  return (
    <MatchingCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        originalData: props.item,

        selectItem,
        sourceItems,
        targetItems,
        updateOptions,

        disabled: props.disabled,
        setting: props.item.setting,

        disableSource,
        disableTarget,
        answers: answer.value,
        feedback: feedback,

        updateComponent,
        updateAnswers,

        onDropData,
        onAddAnswer,
        onRemoveAnswer,
        onSelectItem,

        updateGroupOptions,
        updateOptionData,
        updateOptionType,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedback}>
        {props.children}
      </CompFeedbackContextProvider>
    </MatchingCompContext.Provider>
  );
};

export const useMatchingCompContext = () => {
  const context = useContext(MatchingCompContext);
  if (!context) {
    throw new Error('You must wrap container by DragNDropCompContextProvider');
  }
  return context;
};

const handleInsertOption = (optionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(optionLabel);

  return answers.map((ans) => {
    const ansData = parseDragNDropAnswer(ans);
    // if answer is a -> a => b
    const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
    if (answerIndex >= newOptionIndex) {
      return generateDragNDropAns(
        ansData.index,
        ansData.groupLabel,
        COMPONENT_UTILS.getABC(answerIndex + 1)
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveOption = (removeOptionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(removeOptionLabel);

  return answers
    .filter((ans) => {
      const ansData = parseDragNDropAnswer(ans);
      return ansData.optionLabel !== removeOptionLabel;
    })
    .map((ans) => {
      const ansData = parseDragNDropAnswer(ans);

      const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
      if (answerIndex > newOptionIndex) {
        return generateDragNDropAns(
          ansData.index,
          ansData.groupLabel,
          COMPONENT_UTILS.getABC(answerIndex - 1)
        );
      } else {
        return ans;
      }
    });
};

const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseDragNDropAnswer(ans);

    if (ansData.index > index + 1) {
      return generateDragNDropAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseDragNDropAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseDragNDropAnswer(ans);

      if (ansData.index > index) {
        return generateDragNDropAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

const parseDragNDropAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    optionLabel: answerPart,
  };
};

export const generateDragNDropAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};
