import React from 'react';

import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { DragNDropItemCompProps } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { DragNDropInlineContextProvider } from './DragNDropInlineCompContext';
import DragNDropInlineClickEvent from './DragNDropInlineClickEvent';
import DragNDropInlineDragEvent from './DragNDropInlineDragEvent';
import styled from 'styled-components';
import { DndInlineEditable } from '@cms/comps/interact/drag-n-drop/inline/DnDInlineEditable';

export interface DragNDropInlineCompProps extends CompProps {
  type: CompTypeEnum.DRAG_N_DROP_INLINE;

  configuration: {
    sourceItems: DragNDropItemCompProps[];
    targetItems: DragNDropItemCompProps[];
  };
  setting: DragNDropInlineSettingProps;
}

export interface DragNDropInlineSettingProps extends CompInteractSettingProps {
  multipleDrag: boolean;
  multipleDrop: boolean;
  eventType: 'drag' | 'click';
}

export interface DragNDropInlineAnsProps extends CompAnswerProps {
  answer: string[];
}

export const DragNDropInlineComp = (props: {
  disabled: boolean;
  item: DragNDropInlineCompProps;
  answer: DragNDropInlineAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  return (
    <DragNDropInlineContextProvider
      item={props.item}
      disabled={props.disabled}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <DndInlineEditable>
        <DragNDropInlineCompStyle
          className={`comp comp-dnd-inline ${props.item.setting?.className}`}
        >
          {props.item.setting.eventType === 'drag' ? (
            <DragNDropInlineDragEvent />
          ) : (
            <DragNDropInlineClickEvent />
          )}

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </DragNDropInlineCompStyle>
      </DndInlineEditable>
    </DragNDropInlineContextProvider>
  );
};

const DragNDropInlineCompStyle = styled.div`
  position: relative;

  .dnd-draggable-items {
    text-align: center;

    .draggable-container {
      display: inline-flex;
      text-align: center;

      .draggable-item {
        border: 2px solid #ccc;
        display: inline-flex;
        padding: 0.25em 0.5em;
        margin: 0.125em 0.25em;
        border-radius: 0.25em;
        text-align: center;

        &.click-event {
          cursor: pointer;

          &.highlight-option {
            background-color: ${(props) => props.theme.component.primary_bgr};
          }
        }

        &.disabled-draggable {
          background: rgba(0, 0, 0, 0.06);
        }

        &.disabled-option {
          background: rgba(0, 0, 0, 0.06);
        }

        > .anticon {
          font-size: 80%;
          margin-right: 0.25em;
        }
      }
    }
  }

  .dnd-droppable-items {
    margin-top: var(--cms-padding-option, 0.5em);

    .dnd-inline-group {
      &:not(:first-child) {
        margin-top: var(--cms-padding-option, 0.5em);
      }
    }
  }

  .droppable-items {
    .dropable-items.dragging {
      .comps-dnd-drop-content {
        display: none !important;
      }
    }
  }
`;
