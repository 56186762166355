import React from 'react';
import Input from '@components/input/input';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';

export const FontSizeSetting = (props: { formik: any }) => {
  const { t } = useTranslation();

  return (
    <div className={'template-setting-group font-size-group'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="fontSizeSetting.default"
            type="number"
            label={t('lesson_template.config.default_font_size')}
            placeholder={t('lesson_template.config.default_font_size')}
            value={props.formik.values.fontSizeSetting.default}
            onChange={props.formik.handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};
