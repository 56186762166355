import { EntityStatusEnum, RequestStatusEnum } from '@services/model/common';
import { EntityStatusStyle } from '@components/status/style';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';

export const RequestStatus = (props: { status: RequestStatusEnum }) => {
  const { t } = useTranslation();

  switch (props.status) {
    case RequestStatusEnum.UNREAD:
      return (
        <EntityStatusStyle className={'entity-status inactive-status'}>
          {t('label.request_status.unread')}
        </EntityStatusStyle>
      );

    case RequestStatusEnum.READ:
      return (
        <EntityStatusStyle className={'entity-status active-status'}>
          {t('label.request_status.unread')}
        </EntityStatusStyle>
      );

    case RequestStatusEnum.REPLY:
      return (
        <EntityStatusStyle className={'entity-status published-status'}>
          {t('label.request_status.processed')}
        </EntityStatusStyle>
      );

    case RequestStatusEnum.REMOVED:
      return (
        <EntityStatusStyle className={'entity-status removed-status'}>
          {t('label.request_status.removed')}
        </EntityStatusStyle>
      );

    default:
      return (
        <EntityStatusStyle className={'entity-status empty-status'}>
          &nbsp;
        </EntityStatusStyle>
      );
  }
};

export const EntityStatus = (props: { status: EntityStatusEnum }) => {
  const { t } = useTranslation();

  switch (props.status) {
    case EntityStatusEnum.ACTIVE:
      return (
        <EntityStatusStyle className={'entity-status active-status'}>
          {t('label.compose_status.activated')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.INACTIVE:
      return (
        <EntityStatusStyle className={'entity-status inactive-status'}>
          {t('label.compose_status.inactivated')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.PENDING:
      return (
        <EntityStatusStyle className={'entity-status pending-status'}>
          {t('label.compose_status.pending')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.PUBLISHED:
      return (
        <EntityStatusStyle className={'entity-status published-status'}>
          {t('label.compose_status.published')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.REMOVED:
      return (
        <EntityStatusStyle className={'entity-status removed-status'}>
          {t('label.compose_status.removed')}
        </EntityStatusStyle>
      );
    default:
      return (
        <EntityStatusStyle className={'entity-status empty-status'}>
          &nbsp;
        </EntityStatusStyle>
      );
  }
};

export const EntityWithStatus = (props: {
  status: EntityStatusEnum;
  hideToolTip?: boolean;
  onClick?: () => void;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  switch (props.status) {
    case EntityStatusEnum.ACTIVE:
      return props.hideToolTip ? (
        <EntityStatusStyle
          className={'entity-status active-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      ) : (
        <Tooltip title={t('label.compose_status.activated')}>
          <EntityStatusStyle
            className={'entity-status active-status'}
            onClick={props.onClick}
          >
            {props.children}
          </EntityStatusStyle>
        </Tooltip>
      );
    case EntityStatusEnum.INACTIVE:
      return props.hideToolTip ? (
        <EntityStatusStyle
          className={'entity-status inactive-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      ) : (
        <Tooltip title={t('label.compose_status.inactivated')}>
          <EntityStatusStyle
            className={'entity-status inactive-status'}
            onClick={props.onClick}
          >
            {props.children}
          </EntityStatusStyle>
        </Tooltip>
      );
    case EntityStatusEnum.PENDING:
      return props.hideToolTip ? (
        <EntityStatusStyle
          className={'entity-status pending-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      ) : (
        <Tooltip title={t('label.compose_status.pending')}>
          <EntityStatusStyle
            className={'entity-status pending-status'}
            onClick={props.onClick}
          >
            {props.children}
          </EntityStatusStyle>
        </Tooltip>
      );
    case EntityStatusEnum.PUBLISHED:
      return props.hideToolTip ? (
        <EntityStatusStyle
          className={'entity-status published-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      ) : (
        <Tooltip title={t('label.compose_status.published')}>
          <EntityStatusStyle
            className={'entity-status published-status'}
            onClick={props.onClick}
          >
            {props.children}
          </EntityStatusStyle>
        </Tooltip>
      );
    case EntityStatusEnum.REMOVED:
      return props.hideToolTip ? (
        <EntityStatusStyle
          className={'entity-status removed-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      ) : (
        <Tooltip title={t('label.compose_status.removed')}>
          <EntityStatusStyle
            className={'entity-status removed-status'}
            onClick={props.onClick}
          >
            {props.children}
          </EntityStatusStyle>
        </Tooltip>
      );
    default:
      return (
        <EntityStatusStyle
          className={'entity-status empty-status'}
          onClick={props.onClick}
        >
          {props.children}
        </EntityStatusStyle>
      );
  }
};

export const ComposeStatus = (props: { status: EntityStatusEnum }) => {
  const { t } = useTranslation();

  switch (props.status) {
    case EntityStatusEnum.ACTIVE:
      return (
        <EntityStatusStyle className={'entity-status active-status'}>
          {t('label.compose_status.activated')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.INACTIVE:
      return (
        <EntityStatusStyle className={'entity-status inactive-status'}>
          {t('label.compose_status.inactivated')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.PENDING:
      return (
        <EntityStatusStyle className={'entity-status pending-status'}>
          {t('label.compose_status.pending')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.PUBLISHED:
      return (
        <EntityStatusStyle className={'entity-status published-status'}>
          {t('label.compose_status.published')}
        </EntityStatusStyle>
      );
    case EntityStatusEnum.REMOVED:
      return (
        <EntityStatusStyle className={'entity-status removed-status'}>
          {t('label.compose_status.removed')}
        </EntityStatusStyle>
      );
    default:
      return (
        <EntityStatusStyle className={'entity-status empty-status'}>
          &nbsp;
        </EntityStatusStyle>
      );
  }
};
