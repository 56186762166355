import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { LessonInfoDraggable } from '@components/product/LessonInfoDraggable';
import { Divider, List } from 'antd';
import { useTranslation } from 'react-i18next';
import LessonListItem, {
  LessonSelectionEnum,
} from '@modules/product/components/lesson/SelectLessonItem';

import { H3, H5 } from '@components/typography';
import FilterLessonTree from '@modules/product/components/toc/FilterLessonTree';
import {
  FilterProductLessonReq,
  TableOfContentRes,
} from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum ProductFilter {
  NOT_ARRANGE = -1,
  ALL_LESSONS = 0,
}

const LessonTree = (props: {
  teacherId?: number;
  selectedProductId: number;
  selectedLessonIds: number[];

  productId?: number;
  productTocId?: number;
  lessonIds?: number[];

  setLessons?: (lessons: LessonRes[]) => void;
  handleSelectData?: (lessonData: LessonRes) => void;
  handleRemoveLesson?: (lessonId: number) => void;
}) => {
  const { t } = useTranslation();

  const [filterLessons, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.filterLessons.useLazyQuery({});

  const [firstLoading, setFirstLoading] = useState(true);

  const [tableOfContent, setTableOfContent] =
    useState<TableOfContentRes | null>(null);

  useEffect(() => {
    if (
      tableOfContent &&
      firstLoading &&
      props.lessonIds != null &&
      props.lessonIds.length > 0 &&
      props.setLessons != null
    ) {
      const lessons: LessonRes[] = [];

      tableOfContent.tableOfContent.forEach((res) => {
        res.lessons.forEach((ls) => {
          if (props.lessonIds?.includes(ls.lessonId)) {
            lessons.push(ls);
          }
        });
      });

      props.setLessons(lessons);
      setFirstLoading(false);
    }
  }, [props.lessonIds, tableOfContent]);

  useEffect(() => {
    if (isSuccess && data) {
      setTableOfContent(data);
    }
  }, [data]);

  const handleOnSubmit = (data: FilterProductLessonReq) => {
    filterLessons({
      productId: data.productId,
      keyword: data.keyword,
      status: data.status,
    });
  };

  return (
    <LessonTreeStyle className={'lesson-tree'}>
      <div className={'product-filter'}>
        <FilterLessonTree
          teacherId={props.teacherId}
          productId={props.productId ?? ProductFilter.NOT_ARRANGE}
          onSubmit={handleOnSubmit}
        />
      </div>

      <Divider plain>
        <H3>{t('lesson.list')}</H3>
      </Divider>

      <TableOfContentStyle
        className={'table-of-content scrollable-container scroll-flex'}
        style={{
          minHeight: 200,
          maxHeight: 'calc(100vh - 400px)',
          overflowY: 'auto',
        }}
      >
        {tableOfContent &&
          tableOfContent.tableOfContent.map((item) => {
            if (item.lessons != null && item.lessons.length > 0) {
              return (
                <div
                  key={JSON.stringify(item)}
                  className={'table-of-content-group'}
                >
                  {item.name !== 'not-arranged' && (
                    <H5 className={'toc-title'}>{item.name}</H5>
                  )}

                  <div className={'lesson-group'}>
                    <List
                      bordered
                      loading={isLoading}
                      dataSource={item.lessons}
                      style={{ width: '100%' }}
                      renderItem={(item) => {
                        const selected = props.selectedLessonIds.includes(
                          item.lessonId
                        );
                        return (
                          <>
                            {props.handleSelectData != null ? (
                              <List.Item className={selected ? 'selected' : ''}>
                                <LessonListItem
                                  item={item}
                                  selected={selected}
                                  type={LessonSelectionEnum.add}
                                  onSelect={props.handleSelectData}
                                />
                              </List.Item>
                            ) : (
                              <List.Item className={selected ? 'selected' : ''}>
                                <LessonInfoDraggable
                                  item={item}
                                  selected={selected}
                                />
                              </List.Item>
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </TableOfContentStyle>
    </LessonTreeStyle>
  );
};

export default LessonTree;

const LessonTreeStyle = styled.div`
  .ant-list-item .draggable-item {
    width: 100%;
    background: transparent !important;

    &.dragging {
      background: #fff !important;
    }
  }
`;

const TableOfContentStyle = styled.div`
  .table-of-content-group {
    &:not(:last-child) {
      margin-bottom: 2em;
    }

    .toc-title {
      margin-bottom: 0.25em;
    }
  }

  .ant-list-items .ant-list-item.selected {
    font-weight: bold;
    background: ${(props) => props.theme.app.primary_bgr};
  }
`;
