import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '@services/baseQuery';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import { PublicSchoolRes, SchoolRes } from '@services/private/SchoolAPI';
import env from '@utils/config';
import { CourseRes } from '@services/private/CourseAPI';
import { SchoolSettingRes } from '@services/private/SchoolSettingAPI';
import {
  ProductPriceRes,
  ProductRes,
  ProductTocRes,
} from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface ProductTableOfContentRes {
  product: ProductRes;
  author: UserProfileRes;
  school: PublicSchoolRes;
  tableOfContent: ProductTocRes[];
}

export interface CoursePublicRes {
  course: CourseRes;
  author: UserProfileRes;
  school: PublicSchoolRes;
}

export interface PublicLessonRes extends ProductTableOfContentRes {
  exerciseId: number;
  productTocId: number;
  lesson: LessonRes;
  free: boolean;
}

export const PublishSchoolAPI = createApi({
  reducerPath: 'PublishSchoolAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    viewSchool: builder.query<SchoolRes, string>({
      query: (clientId: string) => ({
        url: `/public/school/${clientId}`,
      }),
    }),

    getSchoolSetting: builder.query<SchoolSettingRes, string>({
      query: (clientId: string) => ({
        url: `/public/school/${clientId}/setting`,
      }),
    }),

    getProductByAlias: builder.query<ProductTableOfContentRes, unknown>({
      // => PublishProductInfoRes
      query: (query: { alias: string; productId: number }) => ({
        url: `/public/school/${query.alias}/products/${query.productId}`,
      }),
    }),

    getProductPrices: builder.query<ProductPriceRes, number>({
      // => PublishProductInfoRes
      query: (productId) => ({
        url: `/public/school/${env.CLIENT_ID}/products/${productId}/price`,
      }),
    }),
  }),
});
