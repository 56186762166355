import React, { useEffect, useMemo, useState } from 'react';
import { Col, List, Progress, Row } from 'antd';
import { useProductTableOfContentContext } from '@modules/product/context/ProductTableOfContent';
import styled from 'styled-components';
import { ViewProductLessonTrackingRes } from '@services/reports/ProgressReport';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { H1, H3, H4, H5 } from '@components/typography';
import { RowGutter } from '@utils/Styled';
import { ReportUtils } from '@modules/reports/ReportUtils';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from '@components/button';
import { LineChartOutlined } from '@ant-design/icons';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import ProductReportPage from '@modules/reports/pages/ProductReportPage';
import { LessonInfoReviewStyle } from '@modules/public/components/lessons/style';
import { ExerciseResponseStatusEnum } from '@modules/assignments/service/exercise_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const TableOfContentProgress = (props: {
  lessonId?: number;
  productTocId?: number;
}) => {
  const [action, setAction] = useState({
    action: '',
    timestamp: -1,
  });

  const { product, tableOfContent, selectLesson } =
    useProductTableOfContentContext();

  const handleSelectLesson = (lesson: LessonRes, productTocId: number) => {
    selectLesson(productTocId, lesson.lessonId);
  };

  const showReportProduct = () => {
    setAction({
      action: 'report',
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{product.name}</H1>

        <Button onClick={showReportProduct}>
          <LineChartOutlined /> Reports
        </Button>
      </HeaderGroup>

      <ProductTocProgressStyle className={'table-of-contents'}>
        <div className={'table-of-content-items'}>
          <Row gutter={RowGutter}>
            {tableOfContent.map((toc) => {
              if (toc.lessons.length > 0) {
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    xxl={12}
                    key={toc.productTocId}
                  >
                    <div className={`table-of-content-item success`}>
                      <H3 className={'product-toc-title'} ellipsis>
                        <span className={'title-display'}>{toc.name}</span>
                      </H3>

                      <div className={'product-lesson-group'}>
                        <List
                          dataSource={toc.lessons}
                          renderItem={(item) => (
                            <List.Item
                              style={{ width: '100%' }}
                              className={
                                props.productTocId &&
                                toc.productTocId === props.productTocId &&
                                props.lessonId &&
                                item.lessonId === props.lessonId
                                  ? 'highlight-lesson'
                                  : ''
                              }
                            >
                              <ViewLessonProgressProductInfo
                                key={item.lessonId}
                                item={item}
                                productTocId={toc.productTocId}
                                onClick={() =>
                                  handleSelectLesson(item, toc.productTocId)
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                );
              } else {
                return null;
              }
            })}
          </Row>
        </div>
      </ProductTocProgressStyle>

      {action.action === 'report' && (
        <CustomModal
          header={'Product Reports'}
          className={CustomModalClassEnum.full_size_modal}
          content={<ProductReportPage productId={product.productId} />}
          onCloseFunc={() => {
            setAction({ action: '', timestamp: -1 });
          }}
        />
      )}
    </>
  );
};

export const ViewLessonProgressProductInfo = (props: {
  item: LessonRes;
  productTocId: number;
  onClick: () => void;
}) => {
  const [currentProgress, setCurrentProgress] =
    useState<ViewProductLessonTrackingRes | null>(null);

  const { progress } = useProductTableOfContentContext();

  useEffect(() => {
    if (progress.length > 0) {
      const index = progress.findIndex((res) => {
        return (
          res.lesson.lessonId === props.item.lessonId &&
          res.productToc.productTocId === props.productTocId
        );
      });

      if (index > -1) {
        setCurrentProgress(progress[index]);
      } else {
        setCurrentProgress(null);
      }
    } else {
      setCurrentProgress(null);
    }
  }, [progress]);

  return (
    <LessonInfoReviewStyle className={`lesson-info`} onClick={props.onClick}>
      <LessonProgress lesson={props.item} progress={currentProgress}>
        <H5 ellipsis>{props.item.name}</H5>
      </LessonProgress>
    </LessonInfoReviewStyle>
  );
};

const LessonProgressStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.not-started {
    color: ${(props) => props.theme.status.not_started};
    filter: grayscale(0.9);
  }

  &.in-progress {
    color: ${(props) => props.theme.status.in_progress};
  }

  &.completed {
    color: ${(props) => props.theme.status.completed};
    font-weight: bold;
  }

  .ant-space {
    width: 100%;

    .ant-space-item:nth-child(2) {
      flex-grow: 1;
    }
  }

  .lesson-thumbnails {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }

  .lesson-title {
    flex-grow: 1;
    position: relative;

    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: calc(100% - 160px);
    margin-left: 10px;
    margin-right: 10px;

    .ant-typography {
      margin-bottom: 0.25em;
    }

    .lesson-result {
      width: 100%;

      .ant-progress-text {
        margin-left: 1ch;
        width: auto;
      }
    }
  }
`;

export const LessonProgress = (props: {
  lesson: LessonRes;
  progress: ViewProductLessonTrackingRes | null;
  children: any;
}) => {
  const progressStatus: {
    className: string;
    status: string;
  } = useMemo(() => {
    if (props.progress) {
      if (props.progress.status === ExerciseResponseStatusEnum.COMPLETED) {
        return {
          status: 'success',
          className: 'completed',
        };
      } else {
        return {
          status: 'active',
          className: 'in-progress',
        };
      }
    } else {
      return {
        status: 'normal',
        className: 'not-started',
      };
    }
  }, [props.progress]);

  return (
    <LessonProgressStyle
      className={`lesson-progress-info ${progressStatus.className}`}
    >
      <div className={'lesson-thumbnails'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.lesson.thumbnails}
          ratio={ImageScale.product.ratio}
          width={60}
        />
      </div>

      <div className={'lesson-title'}>
        <H5>{props.lesson.name}</H5>

        {props.progress &&
        props.progress.status === ExerciseResponseStatusEnum.COMPLETED &&
        props.progress.score &&
        props.progress.maxScore ? (
          <div className={'lesson-result'}>
            <LessonResult
              score={props.progress!.score}
              maxScore={props.progress!.maxScore}
            />
          </div>
        ) : (
          <div className={'lesson-result'} />
        )}
      </div>

      <div className={'lesson-progress'}>
        <Progress
          type={'circle'}
          size={60}
          percent={props.progress?.progress || 0}
          status={progressStatus.status as any}
        />
      </div>
    </LessonProgressStyle>
  );
};

const LessonResultStyle = styled(Progress)`
  .ant-progress-steps-outer .ant-progress-text {
    width: auto;
  }
`;

const LessonScoreStyle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 3.2em;
  height: 3.2em;
  border-radius: 3.2em;

  .ant-typography {
    color: inherit;
  }

  span {
    font-size: 80%;
  }

  &.red {
    color: red;
    border: 2px solid red;
  }

  &.orange {
    color: orange;
    border: 2px solid orange;
  }

  &.blue {
    color: blue;
    border: 2px solid blue;
  }

  &.green {
    color: green;
    border: 2px solid green;
  }
`;

const getScoreColor = (percent: number) => {
  if (percent < 25) {
    return 'red';
  } else if (percent < 50) {
    return 'orange';
  } else if (percent < 75) {
    return 'blue';
  } else {
    return 'green';
  }
};

export const LessonScore = (props: { score: number; maxScore: number }) => {
  const percent = ReportUtils.percent(props.score, props.maxScore);
  const colorClass = getScoreColor(percent);

  return (
    <LessonScoreStyle className={`score-display ${colorClass}`}>
      <H4>{percent}</H4>
      <span>%</span>
    </LessonScoreStyle>
  );
};
export const LessonResult = (props: { score: number; maxScore: number }) => {
  const percent = (props.score * 100) / props.maxScore;

  if (percent < 25) {
    return (
      <LessonResultStyle
        width={300}
        percent={percent}
        steps={10}
        strokeColor={'red'}
        trailColor={'#CCC'}
        format={() => `${props.score} of ${props.maxScore}`}
        status={'normal'}
      />
    );
  } else if (percent < 50) {
    return (
      <LessonResultStyle
        width={300}
        percent={percent}
        steps={10}
        strokeColor={'orange'}
        trailColor={'#CCC'}
        format={() => `${props.score} of ${props.maxScore}`}
        status={'normal'}
      />
    );
  } else if (percent < 75) {
    return (
      <LessonResultStyle
        width={300}
        percent={percent}
        steps={10}
        strokeColor={'blue'}
        trailColor={'#CCC'}
        format={() => `${props.score} of ${props.maxScore}`}
        status={'normal'}
      />
    );
  } else {
    return (
      <LessonResultStyle
        width={300}
        percent={percent}
        steps={10}
        strokeColor={'green'}
        trailColor={'#CCC'}
        format={() => `${props.score} of ${props.maxScore}`}
        status={'normal'}
      />
    );
  }
};

export const TableOfContentProgressSmall = styled.div``;

const ProductTocProgressStyle = styled.div`
  .table-of-content-item {
    margin-bottom: 2em;

    .lesson-info {
      width: 100%;
    }

    .product-lesson-group {
      border-radius: 6px;
      padding: 0px 12px;
      background: #fff;

      .custom-modal & {
        background: #f9f8fb;
      }

      .ant-list-split .ant-list-item {
        .custom-modal & {
          border-color: #fff;
        }
      }
    }
  }

  .highlight-lesson {
    font-weight: bold;
    position: relative;

    &:before {
      content: '';
      top: 0px;
      bottom: 0px;

      left: -12px;
      right: -12px;

      display: inline-block;
      z-index: 1;
      background: var(--primary-background-color, #e2d8f9);
      border-radius: 12px;
      position: absolute;
    }

    .lesson-info {
      z-index: 2;
    }
  }
`;
