import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useEffect } from 'react';
import { updateMenu } from '@app/redux/slices/appSlice';
import { useDispatch } from '@app/redux/hook';

export const PageHeader = (props: {
  menu: NavigationMenuEnum;
  subMenu: NavigationMenuEnum;
  title: string;
  keywords?: string;
  description: string;
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateMenu({
        menu: props.menu,
        submenu: props.subMenu,
      })
    );

    setTitle(props.title);
    setDescription(props.description);

    return () => {
      setTitle('');
      setDescription('');
    };
  }, [props.menu, props.subMenu, props.title, props.description]);

  const setTitle = (title: string) => {
    const el = document.querySelector('title');
    if (el) {
      el.innerText = title;
    }
  };

  const setDescription = (description: string) => {
    const el = document.querySelector("meta[name='description']");
    if (el) {
      el.setAttribute('content', description);
    }
  };

  return <></>;
};
