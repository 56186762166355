import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import LessonTemplatePage from '@modules/product/pages/LessonTemplatePage';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const ManageLessonTemplate = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-lesson-template-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE}
        title={t('lesson_template.header')}
        description={t('lesson_template.header')}
      />

      <LessonTemplatePage />
    </ManagementLayout>
  );
};

export default ManageLessonTemplate;
