import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { createContext, ReactNode, useContext } from 'react';

const ViewExerciseContext = createContext({
  exercise: {} as ExerciseRes,
  exitExercise: () => {},
});

export const ViewExerciseProvider = (props: {
  exercise: ExerciseRes;
  exitExercise: () => void;
  children: ReactNode;
}) => {
  return (
    <ViewExerciseContext.Provider
      value={{ exercise: props.exercise, exitExercise: props.exitExercise }}
    >
      {props.children}
    </ViewExerciseContext.Provider>
  );
};

export const useViewExercise = () => {
  const context = useContext(ViewExerciseContext);
  if (!context) {
    throw new Error('You must wrap container by ViewExerciseProvider');
  }
  return context;
};
