import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconImage from '@components/images/IconImage';
import { SmallColorCircle } from '@components/input/ColorCircle';

export interface UserPublicInfoProps {
  userId: number;
  displayName: string;
  email: string;
  avatar: string;
  live: boolean;
}

export const ChatBoxMessage = (props: {
  myMessage: boolean;
  author: UserPublicInfoProps | null;
  message: string;
  createdDate?: string;
}) => {
  const { t } = useTranslation();

  return (
    <ChatBoxMessageStyle
      className={props.myMessage ? ' my-message' : 'another-message'}
    >
      <div className={'message-content-display'}>
        <div className={'user-avatar'}>
          {props.author && props.author.avatar ? (
            <IconImage
              src={props.author.avatar}
              width={'100%'}
              height={'100%'}
            />
          ) : (
            <SmallColorCircle color={'#6366F1'}>{'gs'}</SmallColorCircle>
          )}

          <h5 className={'author'}>
            {props.author
              ? props.author.displayName
              : t('chat_box.label.anonymous')}
          </h5>
        </div>
        <div className={'message-content'}>{props.message}</div>
        <div className={'create-time'}>{props.createdDate}</div>
      </div>
    </ChatBoxMessageStyle>
  );
};

const ChatBoxMessageStyle = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 15px;
  position: relative;
  display: flex;

  .message-content-display {
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    max-width: 600px;

    .user-avatar {
      display: inline-flex;
      position: absolute;
      bottom: 0px;

      width: 36px;
      height: 36px;
      border-radius: 100%;
      overflow: hidden;

      .author {
        display: none;
      }
    }
  }

  &.my-message {
    justify-content: flex-end;
    .message-content-display {
      border-radius: 10px 10px 0px 10px;

      .user-avatar {
        right: 0px;
      }

      .message-content {
        text-align: right;
      }
    }
  }

  &.another-message {
    justify-content: flex-start;

    .message-content-display {
      border-radius: 10px 10px 10px 0px;

      .user-avatar {
        left: 0px;
      }

      .message-content {
        text-align: left;
      }
    }
  }
`;
