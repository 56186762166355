import Input from '@components/input/input';
import { H5 } from '@components/typography';
import Space from 'antd/lib/space';
import React, { useEffect, useState } from 'react';
import { AxisSettingProps } from '@cms/comps/interact/coordinate/CoordinateUtils';
import { useTranslation } from 'react-i18next';

export const AxisRangeInput = (props: {
  name: string;
  label?: string;
  formik?: any;
  value: AxisSettingProps;
  onChange: (val: AxisSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState({
    from: props.value.from,
    to: props.value.to,
    step: props.value.step,
    stepLabel: props.value.stepLabel,
    triggerChange: false,
  });

  useEffect(() => {
    if (inputValue && inputValue.triggerChange) {
      props.onChange({
        from: inputValue.from,
        to: inputValue.to,
        step: inputValue.step,
        stepLabel: inputValue.stepLabel,
      });
    }
  }, [inputValue]);

  const onInputChange = (
    value: any,
    type: 'from' | 'to' | 'step' | 'stepLabel'
  ) => {
    if (type === 'from') {
      setInputValue((prev) => {
        return { ...prev, from: value, triggerChange: true };
      });
    } else if (type === 'to') {
      setInputValue((prev) => {
        return { ...prev, to: value, triggerChange: true };
      });
    } else if (type === 'step' && value > 0) {
      setInputValue((prev) => {
        return { ...prev, step: value, triggerChange: true };
      });
    } else if (type === 'stepLabel' && value > 0) {
      setInputValue((prev) => {
        return { ...prev, stepLabel: value, triggerChange: true };
      });
    }
  };

  return (
    <div className={'axis-range-input-group'}>
      <div className="mb-3">
        <H5>{props.label}</H5>

        <Space>
          <Input
            name={'from'}
            label={t('component.label.from')}
            type="number"
            formik={props.formik}
            value={inputValue.from}
            onChange={(val) => onInputChange(val.target.value, 'from')}
          />

          <Input
            name={'to'}
            label={t('component.label.to')}
            type="number"
            formik={props.formik}
            value={inputValue.to}
            onChange={(val) => onInputChange(val.target.value, 'to')}
          />
        </Space>

        <Space>
          <Input
            name={'step'}
            label={t('component.label.step')}
            type="number"
            formik={props.formik}
            value={inputValue.step}
            onChange={(val) => onInputChange(val.target.value, 'step')}
          />

          <Input
            name={'stepLabel'}
            label={t('component.label.step_label')}
            type="number"
            formik={props.formik}
            value={inputValue.stepLabel}
            onChange={(val) => onInputChange(val.target.value, 'stepLabel')}
          />
        </Space>
      </div>
    </div>
  );
};
