import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import {
  LongOperationLayoutEnum,
  OperationExpProps,
} from '@cms/comps/math/operation/OperationUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import React, { useEffect, useState } from 'react';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import styled from 'styled-components';
import { LongMultiplicationEditable } from './LongMultiplicationEditable';
import { LongOperationTable } from '../table/LongOperationTable';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { LongOperationTableProvider } from '@cms/comps/math/operation/table/LongOperationTableContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';

export interface LongMultiplicationSettingProps
  extends CompInteractSettingProps {
  layout: LongOperationLayoutEnum;
  removeZero: boolean;
}

export interface LongMultiplicationCompProps extends CompProps {
  type: CompTypeEnum.LONG_MULTIPLICATION;
  configuration: {
    multiplier: string;
    multiplicand: string;
    expression: OperationExpProps[];
  };
  setting: LongMultiplicationSettingProps;
}
export interface LongMultiplicationAnsProps extends CompAnswerProps {
  answer: string[];
}

export const LongMultiplicationComp = (props: {
  disabled: boolean;
  item: LongMultiplicationCompProps;
  answer: LongMultiplicationAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();
  const [showComponent, setShowComponent] = useState(true);

  const [answer, setAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (
      props.answer &&
      props.answer.answer &&
      JSON.stringify(props.answer.answer) !== JSON.stringify(answer.value)
    ) {
      setAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleOnAnswerChange = (answerExpression: OperationExpProps[]) => {
    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('');
    });

    setAnswer({
      value: answer,
      triggerChange: true,
    });
  };

  const handleOnEditableChange = (
    multiplier: string,
    multiplicand: string,
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => {
    const newCompData = produce(props.item, (draft) => {
      draft.configuration.multiplier = multiplier;
      draft.configuration.multiplicand = multiplicand;
      draft.configuration.expression = expression;
    });

    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('');
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newCompData,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );

    setAnswer({
      value: answer,
      triggerChange: false,
    });
    setShowComponent(true);
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      {showComponent ? (
        <LongOperationTableProvider
          expression={props.item.configuration.expression}
          type={
            props.item.setting.layout === LongOperationLayoutEnum.vertical
              ? 'right-to-left'
              : 'left-to-right'
          }
        >
          <LongMultiplicationCompStyle
            className={`comp comp-multiplication ${props.item.setting?.className}`}
          >
            <LongOperationTable
              layout={props.item.setting.layout}
              disabled={props.disabled || mode === CompMode.COMPOSE}
              expression={props.item.configuration.expression}
              answer={answer.value}
              onChange={handleOnAnswerChange}
            />
            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </LongMultiplicationCompStyle>
        </LongOperationTableProvider>
      ) : (
        <ComposeModeContent>
          <LongMultiplicationEditable
            layout={props.item.setting.layout}
            multiplier={props.item.configuration.multiplier}
            multiplicand={props.item.configuration.multiplicand}
            removeZero={props.item.setting.removeZero}
            expression={props.item.configuration.expression}
            onChange={handleOnEditableChange}
          />
        </ComposeModeContent>
      )}
    </CompFeedbackContextProvider>
  );
};

const LongMultiplicationCompStyle = styled.div`
  position: relative;
`;
