import EmptyLayout from '@components/Layout/EmptyLayout';
import React from 'react';
import { useRouter } from '@hooks/useRouter';
import {
  LoginComp,
  LogoutComp,
  RedirectLoginReq,
} from '@modules/authorization/components/LogoutComp';
import { RoleEnum } from '@app/redux/slices/roles';

const Launch = () => {
  const router = useRouter();

  const data = router.query as unknown as RedirectLoginReq;

  if (data.error) {
    return <LogoutComp />;
  } else if (data.access_token) {
    const request = {
      ...data,
      token: data.access_token,
      roles:
        typeof data.roles == 'string'
          ? ([data.roles] as RoleEnum[])
          : (data.roles as RoleEnum[]),
    };

    return (
      <EmptyLayout>
        <LoginComp data={request} />
      </EmptyLayout>
    );
  } else {
    return null;
  }
};

export default Launch;
