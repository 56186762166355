import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { updateMenu } from '@app/redux/slices/appSlice';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { RootState } from '@app/redux/store';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import StudentRegistration from '@modules/authorization/pages/RegisterStudent';

const RegisterStudent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allowStudentRegister } = useSelector(
    (state: RootState) => state.appSetting
  );

  useEffect(() => {
    dispatch(
      updateMenu({
        menu: NavigationMenuEnum.PUBLIC_REGISTER,
        submenu: NavigationMenuEnum.EMPTY,
      })
    );
  }, []);

  return (
    <PublicLayout className={'register-student-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('registration.actions.student')}
        description={t('registration.actions.student')}
      />
      <StudentRegistration disabled={!allowStudentRegister} />
    </PublicLayout>
  );
};

export default RegisterStudent;
