import React from 'react';
import CookieConsentComp from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@app/styled/StyledProvider';

export const CookieConsent = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <CookieConsentComp
      buttonText={t('app.accept_cookie')}
      cookieName="acceptCookieConsent"
      style={{ background: theme.app.secondary }}
      buttonStyle={{
        color: theme.app.primary,
        background: '#FFF',
        fontSize: '15px',
        borderRadius: '1em',
        padding: '0.25em 1em',
      }}
    >
      {t('app.cookie_consent')}
    </CookieConsentComp>
  );
};
