import React from 'react';
import { InvitationType } from '@services/private/InvitationAPI';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const InvitationTypeCompStyle = styled.div``;

const InvitationTypeComp = (props: { type: InvitationType; params: any }) => {
  const { t } = useTranslation();

  const message = () => {
    switch (props.type) {
      case InvitationType.CLASS:
        return t('invitation.actions.join_class');

      case InvitationType.COURSE:
        return t('invitation.actions.join_course');

      case InvitationType.SESSION:
        return t('invitation.actions.join_session');

      case InvitationType.SHARE_LESSON:
        return t('invitation.actions.share_lesson');

      case InvitationType.SHARE_PRODUCT:
        return t('invitation.actions.share_product');
      default:
        return t('invitation.actions.become_student');
    }
  };

  return (
    <InvitationTypeCompStyle className={'invitation-type'}>
      {message()}
    </InvitationTypeCompStyle>
  );
};

export default InvitationTypeComp;
