import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SessionInfoProps } from '@modules/calendar/container/ManageCalendar';
import {
  CalendarDateAndTimeFormat,
  DateAndTimeUtils,
} from '@utils/DateAndTimeUtils';

export const GCalendarContext = createContext({
  defaultDate: '' as string,
  setDefaultDate: (date: string) => {
    console.log(date);
  },
  events: [] as SessionInfoProps[],
  setEvents: (data: any) => {
    console.log(data);
  },
});

export const CalendarContextProvider = (props: { children: ReactNode }) => {
  const [defaultDate, setDefaultDate] = useState(
    DateAndTimeUtils.getCurrentDate(CalendarDateAndTimeFormat.DATE_N_TIME)
  );
  const [events, setEvents] = useState<SessionInfoProps[]>([]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [defaultDate]);

  const changeStartDate = (date: string) => {
    setDefaultDate(date.split('T')[0]);
  };

  return (
    <GCalendarContext.Provider
      value={{
        defaultDate,
        setDefaultDate: changeStartDate,
        events,
        setEvents,
      }}
    >
      {props.children}
    </GCalendarContext.Provider>
  );
};
