import React from 'react';

import { useTranslation } from 'react-i18next';
import RegisterUserForm from '@modules/authorization/container/RegisteUserForm';
import { RoleEnum } from '@app/redux/slices/roles';
import Card, { CardClassEnum } from '@components/card';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LoginLayout } from '@modules/authorization/layout/LoginLayout';

export default function TeacherRegistration(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();

  const handleRegisterSuccess = () => {
    router.push(SiteMap.register.success);
  };

  return (
    <LoginLayout>
      <h1 className={'text-center'}>{t('header.teacher_registration')}</h1>

      <Card className={CardClassEnum.rectangle_box}>
        <RegisterUserForm
          disabled={props.disabled}
          role={RoleEnum.TEACHER}
          successHandle={handleRegisterSuccess}
        />
      </Card>
    </LoginLayout>
  );
}
