import React from 'react';

import MyStudentAssignmentPage from '@modules/assignments/pages/MyStudentAssignmentPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const MyAssignments = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-assignment-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('assignment.title')}
        description={t('assignment.title')}
      />
      <MyStudentAssignmentPage />
    </ManagementLayout>
  );
};

export default MyAssignments;
