import { SessionAPI } from '@services/private/SessionAPI';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { SessionRes, SessionTaskRes } from '@services/model/session';

export const ClassRoomAssignForm = (props: {
  session: SessionRes;
  sectionId: number;
  onSuccess: (task: SessionTaskRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [assignTask, { isLoading }] =
    SessionAPI.endpoints.assignTask.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      direction: '',
      sessionId: props.session.sessionId,
      sectionId: props.sectionId,
    },
    validationSchema: yup.object().shape({}),

    onSubmit: (values) => {
      assignTask(values)
        .unwrap()
        .then((res) => {
          props.onSuccess(res);

          notification.success({
            message: t('lesson.warning.success'),
            description: t('lesson.warning.add_success'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('lesson.warning.error'),
            description: t('lesson.warning.add_error'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Input
        label={t('label.direction')}
        type={'textarea'}
        name="direction"
        onChange={formik.handleChange}
        value={formik.values.direction}
        style={{ minHeight: 120 }}
      />

      <ButtonGroup className="submit-container">
        <Button
          type={'primary'}
          shape={'round'}
          size={'large'}
          loading={isLoading}
          onClick={formik.submitForm}
          icon={IconUtils.actions.assign}
        >
          {t('button.create')}
        </Button>

        <Button
          type="default"
          shape={'round'}
          size={'large'}
          onClick={props.onCancel}
        >
          {t('button.cancel')}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
};
