import { RegistrationBlock } from '@modules/public/landing-page/Registration';
import React, { useEffect, useRef, useState } from 'react';
import { WelComeStyle } from '@modules/public/HomePage';
import ParentRegistration from '@modules/authorization/pages/RegisterParent';
import StudentRegistration from '@modules/authorization/pages/RegisterStudent';
import TeacherRegistration from '@modules/authorization/pages/RegisterTeacher';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { useSelector } from '@app/redux/hook';

export enum RegistrationType {
  EMPTY = '',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  PARENT = 'PARENT',
}

const RegistrationPage = () => {
  const container = useRef<HTMLDivElement>(null);

  const { allowStudentRegister, allowParentRegister, allowTeacherRegister } =
    useSelector((state) => state.appSetting);

  const [registrationType, setRegistrationType] = useState<RegistrationType>(
    RegistrationType.EMPTY
  );

  useEffect(() => {
    if (
      registrationType !== RegistrationType.EMPTY &&
      container.current != null
    ) {
      container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [registrationType]);

  return (
    <WelComeStyle>
      <RegistrationBlock
        selectType={registrationType}
        onClick={setRegistrationType}
      />

      {registrationType && (
        <div className={'registration-item'} ref={container}>
          <SwitchCondition
            conditions={[
              {
                condition: registrationType === RegistrationType.STUDENT,
                children: (
                  <StudentRegistration disabled={!allowStudentRegister} />
                ),
              },
              {
                condition: registrationType === RegistrationType.TEACHER,
                children: (
                  <TeacherRegistration disabled={!allowTeacherRegister} />
                ),
              },
              {
                condition: registrationType === RegistrationType.PARENT,
                children: (
                  <ParentRegistration disabled={!allowParentRegister} />
                ),
              },
            ]}
          />
        </div>
      )}
    </WelComeStyle>
  );
};

export default RegistrationPage;
