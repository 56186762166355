import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Space } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';
import {
  TableOfContentAction,
  useComposeTocContext,
} from '@modules/product/components/toc/ComposeTocProvider';
import { Tooltip } from '@components/tooltip/Tooltip';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { LessonActionEnum } from '@modules/product/container/actions';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import { IconUtils } from '@utils/IconUtils';
import { ComposingWithStatus } from '@components/common/lesson/ComposingStatus';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';
import { SimpleLessonEditForm } from '@modules/product/container/SimpleLessonEditForm';

export const ComposeTocLesson = (props: {
  productToc: ProductTocRes;
  item: LessonRes;
  first: boolean;
  last: boolean;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const proUser = useProUser();

  const { productId, dispatchAction } = useComposeTocContext();

  const [action, setAction] = useState({
    action: LessonActionEnum.DEFAULT,
    params: -1,
  });

  const onViewLesson = () => {
    dispatchAction(TableOfContentAction.view_lesson, props.item.lessonId);
  };

  const onViewLessonStandard = () => {
    dispatchAction(
      TableOfContentAction.view_lesson_standard,
      props.item.lessonId
    );
  };

  const onEditLesson = () => {
    router.push(
      SiteMap.content.lesson.compose_gen(
        props.item.lessonId,
        proUser,
        productId,
        props.productToc.productTocId
      )
    );
  };

  const onEditMetadataLesson = () => {
    setAction({
      action: LessonActionEnum.EDIT_METADATA,
      params: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      params: -1,
    });
  };

  const onRemoveLesson = () => {
    dispatchAction(TableOfContentAction.remove_lesson_from_unit, {
      lessonId: props.item.lessonId,
      productTocId: props.productToc.productTocId,
      name: props.productToc.name,
    });
  };

  const onMoveLessonUp = () => {
    dispatchAction(TableOfContentAction.move_lesson_up, {
      lessonId: props.item.lessonId,
      productTocId: props.productToc.productTocId,
    });
  };

  const onMoveLessonDown = () => {
    dispatchAction(TableOfContentAction.move_lesson_down, {
      lessonId: props.item.lessonId,
      productTocId: props.productToc.productTocId,
    });
  };

  const handleOnUpdate = () => {
    dispatchAction(TableOfContentAction.refresh, {
      lessonId: props.item.lessonId,
      productTocId: props.productToc.productTocId,
    });
    handleOnCancel();
  };

  return (
    <>
      <LessonInfoDraggableStyle className={'lesson-info-static'}>
        <div className={'lesson-title'}>
          <Space size={12}>
            <ThumbnailsImg
              type={'icon'}
              src={props.item.thumbnails}
              ratio={ImageScale.product.ratio}
              width={ImageScale.product.icon}
            />

            <ComposingWithStatus status={props.item.status}>
              {props.item.name}
            </ComposingWithStatus>

            <Tooltip title={t('table_of_content.actions.compose_lesson')}>
              <Button
                type={'text'}
                onClick={onEditLesson}
                icon={IconUtils.actions.edit}
              />
            </Tooltip>
          </Space>
        </div>

        <div className={'lesson-action'}>
          <Space size={12}>
            <Tooltip title={t('table_of_content.actions.move_lesson_up')}>
              <Button
                shape={'circle'}
                disabled={props.first}
                onClick={onMoveLessonUp}
                size={'small'}
                icon={IconUtils.actions.move_up}
              />
            </Tooltip>

            <Tooltip title={t('table_of_content.actions.move_lesson_down')}>
              <Button
                shape={'circle'}
                disabled={props.last}
                onClick={onMoveLessonDown}
                size={'small'}
                icon={IconUtils.actions.move_down}
              />
            </Tooltip>

            <Dropdown
              menu={{
                items: [
                  {
                    key: 'view-lesson',
                    onClick: onViewLesson,
                    icon: IconUtils.lesson.review,
                    label: t('table_of_content.actions.view_lesson'),
                  },
                  {
                    key: 'compose-lesson',
                    onClick: onEditLesson,
                    icon: IconUtils.compose_lesson,
                    label: t('table_of_content.actions.compose_lesson'),
                  },
                  {
                    key: 'divider',
                    type: 'divider',
                  },
                  {
                    key: 'edit-metadata',
                    icon: IconUtils.lesson.edit_metadata,
                    label: t('lesson.actions.update_metadata'),
                    onClick: onEditMetadataLesson,
                  },

                  {
                    key: 'view-standard',
                    onClick: onViewLessonStandard,
                    icon: IconUtils.lesson.standards,
                    label: t('lesson.actions.view_standards'),
                  },
                  {
                    key: 'divider-2',
                    type: 'divider',
                  },
                  {
                    key: 'remove-lesson',
                    onClick: onRemoveLesson,
                    icon: IconUtils.lesson.remove,
                    label: t('table_of_content.actions.remove_lesson'),
                  },
                ],
              }}
              arrow
              placement={'bottomLeft'}
              trigger={['click']}
            >
              <Button
                shape="circle"
                type={'default'}
                size={'small'}
                icon={IconUtils.more_icon}
              />
            </Dropdown>
          </Space>
        </div>
      </LessonInfoDraggableStyle>

      {action.action === LessonActionEnum.EDIT_METADATA && (
        <CustomModal
          header={t('lesson.actions.update_metadata')}
          className={
            proUser
              ? CustomModalClassEnum.large_modal
              : CustomModalClassEnum.medium_modal
          }
          content={
            <>
              {proUser ? (
                <LessonMetadata
                  lessonId={props.item.lessonId}
                  onSuccess={handleOnUpdate}
                  onCancel={handleOnCancel}
                />
              ) : (
                <SimpleLessonEditForm
                  lessonId={props.item.lessonId}
                  onSuccess={handleOnUpdate}
                  onCancel={handleOnCancel}
                />
              )}
            </>
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

const LessonInfoDraggableStyle = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid;
  padding: 0.25em 0em 0.25em 1em;
  margin-bottom: 1em;

  .ant-list & {
    border: none;
    padding: 0;
    margin-bottom: 0;
  }

  .ant-list-item .draggable-item {
    background: transparent !important;

    &.dragging {
      background: #fff !important;
    }
  }

  .lesson-title {
    display: flex;

    h4 {
      margin-bottom: 0px;
    }
  }
`;
