import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';

export interface PublicLessonReq {
  lessonId: number;
  publish: boolean;
}

export const PublishLessonAPI = createApi({
  reducerPath: 'PublishLessonAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    publishItem: builder.mutation<BasicMessageRes, PublicLessonReq>({
      query: (body) => ({
        url: `/publish/lesson`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
