import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import MyCollection from '@router/pages/private/my-lesson/my_lesson';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import CreateCollection from '@router/pages/private/my-lesson/create_lesson';
import ComposeCollection from '@router/pages/private/my-lesson/compose_lesson';
import ViewCollection from '@router/pages/private/my-lesson/view_collection';
import MyLibrary from '@router/pages/private/my-library/my_library';
import MyLibraryLessons from '@router/pages/private/my-library/lessons/lessons';
import ViewMyLesson from '@router/pages/private/my-library/lessons/view_lesson';
import MyLibraryProduct from '@router/pages/private/my-library/products/products';
import ViewMyProduct from '@router/pages/private/my-library/products/view_product';
import { RoleEnum } from '@app/redux/slices/roles';
import ReviewViewProduct from '@router/pages/private/my-library/products/review_product';

export const MyLessonRouter: PageRouterProps[] = [
  {
    path: SiteMap.private.my_lesson.list,
    element: <PrivateRouter element={<MyCollection />} />,
  },

  {
    path: SiteMap.private.my_lesson.create,
    element: <PrivateRouter element={<CreateCollection />} />,
  },
  {
    path: SiteMap.private.my_lesson.compose,
    element: <PrivateRouter element={<ComposeCollection />} />,
  },
  {
    path: SiteMap.private.my_lesson.practice,
    element: <PrivateRouter element={<ViewCollection />} />,
  },
];

export const MyLibraryRouters: PageRouterProps[] = [
  {
    path: SiteMap.private.my_library.list,
    element: <PrivateRouter element={<MyLibrary />} />,
  },

  {
    path: SiteMap.private.my_library.lessons,
    element: <PrivateRouter element={<MyLibraryLessons />} />,
  },

  {
    path: SiteMap.private.my_library.view_lesson,
    element: <PrivateRouter element={<ViewMyLesson />} />,
  },

  {
    path: SiteMap.private.my_library.products,
    element: <PrivateRouter element={<MyLibraryProduct />} />,
  },
  {
    path: SiteMap.private.my_library.view_product,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
        element={<ViewMyProduct />}
      />
    ),
  },

  {
    path: SiteMap.private.my_library.review_product,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
        element={<ReviewViewProduct />}
      />
    ),
  },
];
