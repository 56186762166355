import QRCode from 'qrcode.react';
import React from 'react';
import NavLink, { NavLinkNewTab } from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

export const QRCodeComp = (props: { url: string; size?: number }) => {
  return (
    <NavLinkNewTab href={SiteMap.public.view_qa_code(props.url)}>
      <QRCode size={props.size ? props.size : 124} value={props.url} />
    </NavLinkNewTab>
  );
};

export const QRCodeCompStatic = (props: { url: string; size?: number }) => {
  return (
    <NavLink href={props.url}>
      <QRCode size={props.size ? props.size : 124} value={props.url} />
    </NavLink>
  );
};

export default QRCodeComp;
