import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { StandardRes } from '@modules/product/services/standard_model';
import { H5 } from '@components/typography';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import ResourceStandardForm, {
  ResourceStandardItem,
} from '@modules/product/components/resource/metadata/ResourceStandardForm';
import styled from 'styled-components';
import { IconUtils } from '@utils/IconUtils';
import { ResourceRes } from '@modules/product/services/resource_model';

const ResourceStandardDisplay = (props: {
  item: ResourceRes;
  questionNumber: number;
  selectedResource: number;
  setSelectedResource: (resourceId: number) => void;
  reloadLessonStandard: () => void;
}) => {
  const { t } = useTranslation();
  const [standards, setStandards] = useState<StandardRes[]>(() => {
    return props.item.standards ? props.item.standards : [];
  });

  useEffect(() => {
    setStandards(props.item.standards ? props.item.standards : []);
  }, [props.item.standards]);

  const handleOnSuccess = () => {
    props.setSelectedResource(-1);
    props.reloadLessonStandard();
  };

  const changeToEditMode = () => {
    props.setSelectedResource(props.item.resourceId);
  };

  const dataSource: any[] = useMemo(() => {
    if (
      props.selectedResource === props.item.resourceId ||
      standards.length === 0
    ) {
      return [
        {
          standard: DateAndTimeUtils.getCurrentTime(),
          timestamp: DateAndTimeUtils.getCurrentTime(),
        },
      ];
    } else {
      return standards;
    }
  }, [props.selectedResource, standards]);

  return (
    <ResourceStandardDisplayStyle className={'resource-question'}>
      <H5 onClick={changeToEditMode}>
        {t('standard_set.standards')} (
        {t('resource.question_n', { questionNumber: props.questionNumber })}
        )&nbsp;
        {IconUtils.actions.edit}
      </H5>

      <div className={'resource-standard-items'}>
        {props.selectedResource === props.item.resourceId ? (
          <ResourceStandardForm
            resourceId={props.item.resourceId}
            onCancel={() => {
              props.setSelectedResource(-1);
            }}
            onSuccess={handleOnSuccess}
          />
        ) : (
          <>
            {standards.length === 0 ? (
              <p>Question has no standards.</p>
            ) : (
              <>
                {dataSource.map((st) => {
                  return (
                    <ResourceStandardItem key={st.standardId} standard={st} />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </ResourceStandardDisplayStyle>
  );
};

export default ResourceStandardDisplay;

const ResourceStandardDisplayStyle = styled.div`
  margin-bottom: 1.5em;
  width: 100%;

  .resource-standard-items {
    width: 100%;
  }

  .button-action {
    text-align: right;
  }
`;
