import styled from 'styled-components';
import { LessonInfo, LessonInfoPrice } from '@components/product/LessonInfo';
import { List } from 'antd';
import { H4 } from '@components/typography';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

export const ProductTocInfo = (props: {
  selectLessonId: number;
  selectTocId: number;
  onSelectLesson: (lesson: LessonRes, productToc: ProductTocRes) => void;
  item: ProductTocRes;
}) => {
  return (
    <ProductTocInfoStyle
      className={`product-toc-info ${
        props.item.productTocId === props.selectTocId ? 'selected' : ''
      }`}
    >
      <H4 className={'product-toc-title'}>
        <div className={'title-display'}>{props.item.name}</div>
        <div className={'lesson-number'}>({props.item.lessons.length})</div>
      </H4>

      <List
        bordered
        size={'small'}
        dataSource={props.item.lessons}
        renderItem={(lsItem) => (
          <List.Item
            className={
              props.item.productTocId === props.selectTocId &&
              lsItem.lessonId === props.selectLessonId
                ? 'selected'
                : ''
            }
          >
            <LessonInfo
              item={lsItem}
              onClickHandle={() => props.onSelectLesson(lsItem, props.item)}
            />
          </List.Item>
        )}
      />
    </ProductTocInfoStyle>
  );
};

export const ProductTocInfoPrice = (props: { item: ProductTocRes }) => {
  return (
    <ProductTocInfoStyle className={'product-toc-info'}>
      <List
        bordered
        dataSource={props.item.lessons}
        header={
          <h3 className={'product-toc-title'}>
            <div className={'title-display'}>{props.item.name}</div>
            <div className={'lesson-number'}>({props.item.lessons.length})</div>
          </h3>
        }
        renderItem={(lsItem) => (
          <List.Item>
            <LessonInfoPrice key={lsItem.lessonId} item={lsItem} />
          </List.Item>
        )}
      />
    </ProductTocInfoStyle>
  );
};

const ProductTocInfoStyle = styled.div`
  position: relative;

  .product-toc-title {
    white-space: nowrap;
    position: relative;
    line-height: 1.6;
    cursor: pointer;
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25em;

    .title-display {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .lesson-number {
      font-size: 80%;
      font-weight: 100;
    }
  }

  .product-lesson-group {
    padding: 0.25em 1em;
    color: #333;

    .lesson-info {
      width: 100%;
      padding: 0.125em 0.125em 0.125em 1em;
    }
  }

  &.selected {
    .ant-list-header {
      color: ${(props) => props.theme.app.primary} !important;

      .product-toc-title {
        color: ${(props) => props.theme.app.primary} !important;
      }
    }

    .ant-list-item {
      background: ${(props) => props.theme.app.primary_bgr};

      &.selected {
        color: ${(props) => props.theme.app.primary_text};
        background-color: ${(props) => props.theme.app.primary} !important;
      }
    }
  }

  .ant-list-item {
    line-height: 1;

    &.selected {
      font-weight: bold;
    }
  }

  .ant-list-item:nth-child(2n + 1) {
    background: #f6f7fb;
  }
`;
