import React, { useEffect } from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { WelComeStyle } from '@modules/public/HomePage';
import { ViewTeacherProfilePage } from '@modules/public/pages/ViewTeacherProfilePage';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const ViewTeacher = () => {
  const router = useRouter();

  const { shortName } = router.query as unknown as { shortName: string };

  const [viewTeacher, { data, error, isError }] =
    PublicPageAPI.endpoints.viewTeacher.useLazyQuery();

  useEffect(() => {
    viewTeacher(shortName);
  }, [shortName]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  return (
    <PublicLayout className={'teacher-info-page'}>
      {data && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_TEACHERS}
            subMenu={NavigationMenuEnum.EMPTY}
            title={data.displayName}
            keywords={data.keyword}
            description={data.description}
          />

          <WelComeStyle>
            <div className={'home-block-container'}>
              <ViewTeacherProfilePage shortName={shortName} item={data} />
            </div>
          </WelComeStyle>
        </>
      )}
    </PublicLayout>
  );
};

export default ViewTeacher;
