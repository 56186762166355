import {
  ProgressReportAPI,
  ViewProductTrackingRes,
} from '@services/reports/ProgressReport';
import { useEffect, useState } from 'react';

export const useGetProductProgress = () => {
  const [getProductsTracking, { data, isSuccess, isLoading }] =
    ProgressReportAPI.endpoints.getProductsTracking.useLazyQuery();

  const [progress, setProgress] = useState<
    Record<number, ViewProductTrackingRes>
  >({});

  useEffect(() => {
    getProductsTracking({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      const progress: Record<number, ViewProductTrackingRes> = {};

      data.forEach((rc) => {
        progress[rc.productId] = rc;
      });
      setProgress(progress);
    }
  }, [data]);

  return {
    progress,
    isLoading,
  };
};
