import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AreaDivisionCompProps } from '@cms/comps/math/operation/area-division/AreaDivisionComp';

export const AreaDivisionExampleData: AreaDivisionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.AREA_DIVISION,

  configuration: {
    dividend: '575',
    divisor: '5',
    expression: [
      {
        type: 'header',
        exp: ['_', '100', '10', '5'],
      },
      {
        type: 'template',
        exp: ['_', '575', '75', '25'],
      },
      {
        type: 'template',
        exp: ['5', '500', '50', '25'],
      },
      {
        type: 'template',
        exp: ['_', '75', '25', '0'],
      },
    ],
  },
  setting: {
    autoScore: true,
  },
};
