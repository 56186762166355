import { createSlice } from '@reduxjs/toolkit';

export interface INotifyData {
  index: number;
  message: string;
  title: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

const initialState = {
  alerts: [],
};

let currentIndex = 1;

const alertSlice = createSlice({
  name: 'alertNotify',
  initialState,
  reducers: {
    alert: (
      state: { alerts: INotifyData[] },
      action: { payload: INotifyData }
    ) => {
      state.alerts.push({
        index: currentIndex,
        message: action.payload.message,
        title: action.payload.title + '<sup>(' + currentIndex + ')</sup>',
        type: action.payload.type,
      });

      if (state.alerts.length > 2) {
        state.alerts = [
          state.alerts[state.alerts.length - 2],
          state.alerts[state.alerts.length - 1],
        ];
      }

      currentIndex++;
    },
    remove: (
      state: { alerts: INotifyData[] },
      action: { payload: { index: number } }
    ) => {
      const reduces = state.alerts.filter((item) => {
        return item.index !== action.payload.index;
      });
      state.alerts = reduces;
    },
    clear: (state: { alerts: INotifyData[] }) => {
      state.alerts = [];
    },
  },
});

const { actions, reducer } = alertSlice;
export const { alert, clear, remove } = actions;
export default reducer;
