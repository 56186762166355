import { Button } from 'antd';
import React, { useState } from 'react';
import {
  GlossaryGameCompProps,
  GlossaryGameContent,
} from '@cms/comps/game/GameUtils';
import {
  ContentActionEnum,
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import {
  AddGroupOptionEditable,
  ChangeOptionEditable,
} from '@cms/editable/OptionEditable';
import { AssetsRes } from '@modules/product/services/assets_model';
import { AssetsContentProps } from '@cms/content/ContentType';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import styled from 'styled-components';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { H4 } from '@components/typography';

export enum GlossaryType {
  word = 'word',
  description = 'description',
}

const GlossaryWordBankStyle = styled.div`
  width: 100%;

  .glossary-word-container {
    min-height: 10ch;

    &:not(:first-child) {
      margin-top: 2em;
    }

    > .cms-content-actions {
      padding: 0.25em 0.5em;
      outline: 1px solid #ccc;

      margin-top: 0.5em;

      .cms-content-display {
        display: flex;
        align-items: center;
        flex-grow: 1;

        .group-number {
          display: inline-block;
          margin-right: 0.5em;
        }

        .glossary-word-content {
          flex-grow: 1;
        }
      }
    }
  }

  .component-setting-footer {
    margin-top: 0.5em;
  }
`;

export enum GlossaryGroupTypeEnum {
  sourceItems = 'sourceItems',
  targetItems = 'targetItems',
}

export const GlossaryWordBank = (props: {
  item: GlossaryGameCompProps;
  onChange: (newData: any) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems!
  );

  const [contentChange, setContentChange] = useState(false);

  const updateDataForOption = (
    data: AssetsContentProps,
    index: string,
    glossary: GlossaryGameContent,
    type: GlossaryType,
    groupType: GlossaryGroupTypeEnum
  ) => {
    if (type === GlossaryType.word) {
      const newGlossary = { ...glossary, word: { ...data } };

      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            prevState,
            parseInt(index),
            newGlossary
          );
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            prevState,
            parseInt(index),
            newGlossary
          );
        });
      }
    } else {
      const newGlossary = { ...glossary, description: { ...data } };
      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            prevState,
            parseInt(index),
            newGlossary
          );
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            prevState,
            parseInt(index),
            newGlossary
          );
        });
      }
    }
    setContentChange(true);
  };

  const handleOnClickOperation = (
    operation: ContentOperationEnum,
    index: string,
    glossary: GlossaryGameContent,
    groupType: GlossaryGroupTypeEnum
  ) => {
    if (operation === ContentOperationEnum.ADD) {
      const cloneGlossary = {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          ...glossary.word,
          id: COMPONENT_UTILS.generateUID(),
        },
        description: {
          ...glossary.description,
          id: COMPONENT_UTILS.generateUID(),
        },
      };

      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.insertAtIndex(
            prevState,
            parseInt(index),
            cloneGlossary
          );
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.insertAtIndex(
            prevState,
            parseInt(index),
            cloneGlossary
          );
        });
      }
    } else {
      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.removeAtIndex([...prevState], parseInt(index));
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.removeAtIndex([...prevState], parseInt(index));
        });
      }
    }

    setContentChange(true);
  };

  const handleOnChangeType = (
    contentType: AssetsTypeEnum,
    index: string,
    glossary: GlossaryGameContent,
    type: GlossaryType,
    groupType: GlossaryGroupTypeEnum
  ) => {
    if (type === GlossaryType.word) {
      const newWord = { ...glossary.word, type: contentType };
      const newGlossary = { ...glossary, word: newWord };

      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            [...prevState],
            parseInt(index),
            newGlossary
          );
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            [...prevState],
            parseInt(index),
            newGlossary
          );
        });
      }
    } else {
      const newDescription = { ...glossary.description, type: contentType };
      const newGlossary = { ...glossary, description: newDescription };

      if (groupType === GlossaryGroupTypeEnum.sourceItems) {
        setSourceItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            [...prevState],
            parseInt(index),
            newGlossary
          );
        });
      } else {
        setTargetItems((prevState) => {
          return COMPONENT_UTILS.updateAtIndex(
            [...prevState],
            parseInt(index),
            newGlossary
          );
        });
      }
    }

    setContentChange(true);
  };

  const updateComponentData = () => {
    const newData = {
      ...props.item,
      configuration: {
        ...props.item.configuration,
        sourceItems: sourceItems,
        targetItems: targetItems,
      },
    };

    props.onChange(newData);
  };

  return (
    <GlossaryWordBankStyle className={'glossary-word-bank'}>
      <div className={'glossary-word-container'}>
        <H4>
          {props.item.type === CompTypeEnum.WORD_PUZZLE
            ? 'Across'
            : 'Source Items:'}
        </H4>

        {sourceItems &&
          sourceItems.map((glossary, index) => {
            return (
              <AddGroupOptionEditable
                key={JSON.stringify(glossary, null, 2) + '_' + index}
                disabled={{
                  addOption: false,
                  removeOption: sourceItems.length === 1,
                }}
                onOperation={(operation) =>
                  handleOnClickOperation(
                    operation,
                    index + '',
                    glossary,
                    GlossaryGroupTypeEnum.sourceItems
                  )
                }
              >
                <span className={'group-number'}>{index + 1}.</span>
                <GlossaryWordContentEditable
                  componentType={props.item.type}
                  item={glossary}
                  handleOnContentChange={(data, type) =>
                    updateDataForOption(
                      data,
                      index + '',
                      glossary,
                      type,
                      GlossaryGroupTypeEnum.sourceItems
                    )
                  }
                  handleOnChangeType={(newType, type) =>
                    handleOnChangeType(
                      newType,
                      index + '',
                      glossary,
                      type,
                      GlossaryGroupTypeEnum.sourceItems
                    )
                  }
                />
              </AddGroupOptionEditable>
            );
          })}
      </div>

      {targetItems != null && targetItems.length > 0 && (
        <div className={'glossary-word-container'}>
          <H4>
            {props.item.type === CompTypeEnum.WORD_PUZZLE
              ? 'Down'
              : 'Target Items:'}
          </H4>

          {targetItems.map((glossary, index) => {
            return (
              <AddGroupOptionEditable
                key={JSON.stringify(glossary, null, 2) + '_' + index}
                onOperation={(operation) =>
                  handleOnClickOperation(
                    operation,
                    index + '',
                    glossary,
                    GlossaryGroupTypeEnum.targetItems
                  )
                }
              >
                <span className={'group-number'}>{index + 1}.</span>
                <GlossaryWordContentEditable
                  componentType={props.item.type}
                  item={glossary}
                  handleOnContentChange={(data, type) =>
                    updateDataForOption(
                      data,
                      index + '',
                      glossary,
                      type,
                      GlossaryGroupTypeEnum.targetItems
                    )
                  }
                  handleOnChangeType={(newType, type) =>
                    handleOnChangeType(
                      newType,
                      index + '',
                      glossary,
                      type,
                      GlossaryGroupTypeEnum.targetItems
                    )
                  }
                />
              </AddGroupOptionEditable>
            );
          })}
        </div>
      )}

      <div className={'component-setting-footer'}>
        <ButtonGroup type={'right'}>
          {contentChange && (
            <Button
              shape={'round'}
              type={'primary'}
              onClick={updateComponentData}
            >
              {t('button.update')}
            </Button>
          )}
          <Button shape={'round'} onClick={props.onCancel}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </div>
    </GlossaryWordBankStyle>
  );
};

const GlossaryWordContentEditableStyle = styled.div`
  display: flex;
  font-size: 16px;

  .glossary-word-term {
    width: 40%;
    padding: 0 2em 0 0;
  }

  .glossary-word-definition {
    width: 60%;
    padding: 0 2em 0 0;
  }
`;

const GlossaryWordContentEditable = (props: {
  componentType: CompTypeEnum;
  item: GlossaryGameContent;
  handleOnContentChange: (data: AssetsRes, type: GlossaryType) => void;
  handleOnChangeType: (newType: AssetsTypeEnum, type: GlossaryType) => void;
}) => {
  return (
    <GlossaryWordContentEditableStyle className={'glossary-word-content'}>
      {/*GLOSSARY WORD*/}
      <div className={'glossary-word-term'}>
        <ChangeOptionEditable
          type={props.item.word.type}
          contentTypes={
            props.componentType === CompTypeEnum.WORD_BOX
              ? [ContentActionEnum.CHANGE_TO_TEXT]
              : null
          }
          onChangeType={(newType) =>
            props.handleOnChangeType(newType, GlossaryType.word)
          }
        >
          <ContentViewerEditable
            contentData={props.item.word}
            onChange={(data) =>
              props.handleOnContentChange(data, GlossaryType.word)
            }
          />
        </ChangeOptionEditable>
      </div>

      {/*GLOSSARY DEFINITION*/}
      <div className={'glossary-word-definition'}>
        <ChangeOptionEditable
          type={props.item.word.type}
          onChangeType={(newType) =>
            props.handleOnChangeType(newType, GlossaryType.description)
          }
        >
          <ContentViewerEditable
            contentData={props.item.description}
            onChange={(data) =>
              props.handleOnContentChange(data, GlossaryType.description)
            }
          />
        </ChangeOptionEditable>
      </div>
    </GlossaryWordContentEditableStyle>
  );
};
