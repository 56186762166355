import React from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { useTranslation } from 'react-i18next';
import { ManageSurveyTable } from '../container/survey/ManageSurveyTable';

export const ManageSurveyPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('survey.header')}</H1>
      </HeaderGroup>

      <ManageSurveyTable />
    </>
  );
};
