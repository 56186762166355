import { Droppable, DroppableProps } from 'react-beautiful-dnd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DragNDropActionType,
  DragNDropSplitLabel,
  DropItemProps,
  useDragNDropContext,
} from '@components/react-dnd-bt/DragNDropContext';
import styled from 'styled-components';

export const generateDropId = (
  droppableId: string,
  acceptType: string,
  containerId: string
) => {
  return [droppableId, acceptType, containerId].join(DragNDropSplitLabel);
};

export const parseDropData = (droppableId: string) => {
  const [dropId, acceptType, containerId] =
    droppableId.split(DragNDropSplitLabel);
  return {
    droppableId: dropId,
    acceptType: acceptType,
    id: containerId,
  };
};

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export function DropItem(props: DropItemProps) {
  const { id, staticDrag, action, disableDropIds } = useDragNDropContext();

  useEffect(() => {
    if (action.params != null) {
      if (
        action.action === DragNDropActionType.add &&
        action.params.new != null &&
        action.params.new?.droppableId === props.droppableId
      ) {
        if (props.handleOnDrop) {
          props.handleOnDrop(
            action.params.new.draggableId,
            action.params.new.index
          );
        }
      } else if (
        action.action === DragNDropActionType.remove &&
        action.params.old != null &&
        action.params.old.droppableId === props.droppableId
      ) {
        if (props.handleOnRemove) {
          props.handleOnRemove(
            action.params.old.draggableId,
            action.params.old.index
          );
        }
      } else if (
        action.action === DragNDropActionType.move &&
        action.params.old != null &&
        action.params.new != null
      ) {
        if (props.droppableId === action.params.old.droppableId) {
          if (props.handleOnRemove) {
            props.handleOnRemove(
              action.params.old.draggableId,
              action.params.old.index
            );
          }
        } else if (props.droppableId === action.params.new.droppableId) {
          if (props.handleOnDrop) {
            props.handleOnDrop(
              action.params.new.draggableId,
              action.params.new.index
            );
          }
        }
      } else if (
        action.action === DragNDropActionType.order &&
        action.params.old != null &&
        action.params.new != null
      ) {
        if (props.handleOnDrop) {
          props.handleOnDrop(
            action.params.new.draggableId,
            action.params.new.index
          );
        }
      }
    }
  }, [action]);

  const isDropDisabled =
    props.droppableId === '' ||
    disableDropIds.includes(props.droppableId) ||
    (props.disabledIds != null &&
      props.disabledIds.length > 0 &&
      props.disabledIds.includes(props.droppableId));

  return (
    <DropContextProvider.Provider
      value={{
        droppableId: props.droppableId,
        disabledIds: props.disabledIds ?? [],
      }}
    >
      <StrictModeDroppable
        key={generateDropId(props.droppableId, props.acceptType, id)}
        droppableId={generateDropId(props.droppableId, props.acceptType, id)}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => {
          const className = `
            ${props.className ? props.className : ''} 
            ${
              props.droppableId === ''
                ? ' draggable-container '
                : ' droppable-container '
            }
            ${isDropDisabled ? ' disabled-droppable ' : ''} 
            ${staticDrag ? ' static-drop ' : ''}
            ${snapshot.isDraggingOver ? ' dragging ' : ''}
          `;

          return (
            <DropItemStyle
              className={className}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {props.children}
              {provided.placeholder}
            </DropItemStyle>
          );
        }}
      </StrictModeDroppable>
    </DropContextProvider.Provider>
  );
}

export const DropContextProvider = createContext({
  droppableId: '' as string,
  disabledIds: [] as string[],
});

export const useDropContext = () => {
  const context = useContext(DropContextProvider);
  if (!context) {
    throw new Error('You must wrap container by DropContextProvider');
  }
  return context;
};

const DropItemStyle = styled.div`
  &.dragging {
    background: rgba(0, 0, 0, 0.03) !important;
  }

  &.droppable-container {
    &.disabled-droppable {
      background: rgba(0, 0, 0, 0.06) !important;
    }
  }

  &.static-drop {
  }
`;
