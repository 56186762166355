import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import {
  AssignSessionTaskReq,
  LessonSectionSlideResponse,
  SessionRes,
  SessionSectionHistoryRes,
  SessionStudentRes,
  SessionTaskInstanceRes,
  SessionTaskRes,
} from '@services/model/session';
import {
  AddLessonToSession,
  AddStudentToSessionReq,
  CreateSessionActionReq,
  CreateSessionReq,
  CreateSessionSlideReq,
  MoveSessionReq,
  RemoveStudentFromSessionReq,
} from '@services/model/session_request';
import { LessonRes } from '@modules/product/services/lesson_model';

export const SessionAPI = createApi({
  reducerPath: 'SessionAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getListSession: builder.query<SessionRes[], unknown>({
      query: () => ({
        url: `/sessions`,
      }),
    }),

    getIncomingEvent: builder.query<PaginationRes<SessionRes>, PaginationReq>({
      query: (params: PaginationReq) => ({
        url: `/sessions/incoming`,
        method: 'GET',
        params,
      }),
    }),

    getPublishSessions: builder.query<SessionRes[], unknown>({
      query: () => ({
        url: `/sessions/publish`,
      }),
    }),

    findById: builder.query<SessionRes, unknown>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}`,
        method: 'GET',
      }),
    }),

    generatePublicCode: builder.query<SessionRes, unknown>({
      query: (sessionId: number) => ({
        url: `/sessions/${sessionId}/generate-public-code`,
        method: 'GET',
      }),
    }),

    createSession: builder.mutation<SessionRes, CreateSessionReq>({
      query: (body: CreateSessionReq) => ({
        url: '/sessions',
        method: 'POST',
        body,
      }),
    }),

    updateSession: builder.mutation<SessionRes, CreateSessionReq>({
      query: (body: CreateSessionReq) => ({
        url: '/sessions',
        method: 'PUT',
        body,
      }),
    }),

    moveSession: builder.mutation<SessionRes, MoveSessionReq>({
      query: (body) => ({
        url: '/sessions/move-session',
        method: 'PUT',
        body,
      }),
    }),

    removeSession: builder.mutation<BasicMessageRes, unknown>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}`,
        method: 'DELETE',
      }),
    }),

    addLesson: builder.mutation<BasicMessageRes, AddLessonToSession>({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/lessons`,
        method: 'POST',
        body: body,
      }),
    }),

    removeLesson: builder.mutation<BasicMessageRes, AddLessonToSession>({
      query: (body: AddLessonToSession) => ({
        url: `/sessions/${body.sessionId}/lessons`,
        method: 'DELETE',
        body: body,
      }),
    }),

    getStudents: builder.query<PaginationRes<SessionStudentRes>, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/students`,
      }),
    }),

    addStudent: builder.mutation<SessionRes, AddStudentToSessionReq>({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/students`,
        method: 'POST',
        body,
      }),
    }),

    removeStudent: builder.mutation<SessionRes, RemoveStudentFromSessionReq>({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/students`,
        method: 'DELETE',
        body,
      }),
    }),

    saveSessionActions: builder.mutation<
      SessionSectionHistoryRes,
      CreateSessionActionReq
    >({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/history/${body.sectionId}`,
        method: 'POST',
        body,
      }),
    }),

    getLatestState: builder.query<SessionSectionHistoryRes, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/history/latest`,
      }),
    }),

    getClassRoomState: builder.query<
      SessionSectionHistoryRes,
      { sessionId: number; historyUid: string }
    >({
      query: (params) => ({
        url: `/sessions/${params.sessionId}/history/${params.historyUid}`,
      }),
    }),

    getLatestSectionState: builder.query<
      SessionSectionHistoryRes,
      { sessionId: number; sectionId: number }
    >({
      query: (params) => ({
        url: `/sessions/${params.sessionId}/history/${params.sectionId}/latest`,
      }),
    }),

    getPublicSessionData: builder.query<SessionRes, unknown>({
      query: (params: { token: string }) => ({
        url: `/public/session/join/`,
        params,
      }),
    }),

    viewSessionData: builder.query<SessionRes, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/view`,
      }),
    }),

    viewLessonSessions: builder.query<LessonRes, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/lesson/view`,
      }),
    }),

    createSessionSlide: builder.mutation<
      BasicMessageRes,
      CreateSessionSlideReq
    >({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/lesson/sections`,
        method: 'POST',
        body,
      }),
    }),

    viewSectionSlide: builder.query<
      LessonSectionSlideResponse,
      { sessionId: number; sectionId: number }
    >({
      query: (params) => ({
        url: `/sessions/${params.sessionId}/lesson/sections/${params.sectionId}`,
      }),
    }),

    viewSessionAttendances: builder.query<SessionStudentRes[], number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/attendance`,
      }),
    }),

    attendanceSession: builder.mutation<BasicMessageRes, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/attendance`,
        method: 'PUT',
      }),
    }),

    assignTask: builder.mutation<SessionTaskRes, AssignSessionTaskReq>({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/tasks`,
        method: 'POST',
        body,
      }),
    }),

    getTasks: builder.query<SessionTaskRes[], number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/tasks`,
      }),
    }),

    getInProgressTask: builder.query<SessionTaskRes, number>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/tasks/in-progress`,
      }),
    }),

    getTaskExercise: builder.query<
      SessionTaskInstanceRes,
      { sessionId: number; sessionTaskId: number }
    >({
      query: (params) => ({
        url: `/sessions/${params.sessionId}/tasks/${params.sessionTaskId}`,
      }),
    }),

    getTaskInstances: builder.query<
      SessionTaskInstanceRes[],
      { sessionId: number; sessionTaskId: number }
    >({
      query: (params) => ({
        url: `/sessions/${params.sessionId}/tasks/${params.sessionTaskId}/instances`,
      }),
    }),

    completeTask: builder.mutation<
      SessionTaskInstanceRes,
      { sessionId: number; taskId: number; taskInstanceId: number }
    >({
      query: (body) => ({
        url: `/sessions/${body.sessionId}/tasks/${body.taskId}/instances/${body.taskInstanceId}/complete`,
        method: 'PUT',
      }),
    }),
  }),
});
