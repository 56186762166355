import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export enum CalendarViewType {
  day = 'timeGridDay',
  week = 'timeGridWeek',
  month = 'dayGridMonth',
}

const CalendarViewDropdown = (props: {
  type: CalendarViewType;
  onChange: (type: CalendarViewType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CalendarViewDropdownStyle className={'calendar-view'}>
      <Segmented
        options={[
          {
            label: t('calendar.label.month'),
            value: CalendarViewType.month,
            icon: <CalendarOutlined />,
          },
          {
            label: t('calendar.label.week'),
            value: CalendarViewType.week,
            icon: <CalendarOutlined />,
          },
          {
            label: t('calendar.label.day'),
            value: CalendarViewType.day,
            icon: <CalendarOutlined />,
          },
        ]}
        value={props.type}
        onChange={(val) => props.onChange(val as CalendarViewType)}
      />
    </CalendarViewDropdownStyle>
  );
};

export default CalendarViewDropdown;

const CalendarViewDropdownStyle = styled.div``;
