import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@app/redux/hook';
import {
  ExerciseRes,
  ExerciseScoreOverallRes,
  ExerciseStatus,
  GradeExerciseReq,
  UpdateExerciseAndAnswerReq,
} from '@modules/assignments/service/exercise_model';
import { ExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import { useRouter } from '@hooks/useRouter';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { collectExerciseAnswer } from '@cms/lesson-template/components/toolbar/handler/collectExerciseAnswer';
import { notification } from 'antd';
import { SiteMap } from '@router/SiteMap';
import ExerciseUtils from '@modules/assignments/utils/ExerciseUtils';
import { AnonymousDoExerciseAPI } from '@modules/assignments/service/AnonymousDoExerciseAPI';

export const AnonymousServiceProvider = (props: {
  exerciseId: number;
  lessonId: number;
  children: ReactNode;
  event?: {
    onSubmit?: () => void;
    onGradingComplete?: () => void;
    onExit: () => void;
  };
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [viewExerciseStatus] =
    AnonymousDoExerciseAPI.endpoints.viewExerciseStatus.useLazyQuery();

  const [saveExercise, { isLoading: isSaveLoading }] =
    AnonymousDoExerciseAPI.endpoints.saveExercise.useMutation();

  const [submitExercise, { isLoading: isSubmitLoading }] =
    AnonymousDoExerciseAPI.endpoints.submitExercise.useMutation();

  const [gradeExercise] =
    AnonymousDoExerciseAPI.endpoints.gradeExercise.useMutation();

  const [viewExerciseScore] =
    AnonymousDoExerciseAPI.endpoints.viewExerciseScore.useLazyQuery();

  const { currentAnswer } = useSelector((state) => state.viewResource);
  const { section, resource } = useLessonNavigationContext();
  const { type, lesson, gradings } = useViewLessonContext();

  const viewStatus = (callBack: (data: ExerciseRes) => void) => {
    viewExerciseStatus(props.exerciseId)
      .unwrap()
      .then((data) => {
        callBack(data);
      })
      .catch(() => {
        console.log('Have error when view exercise status');
      });
  };

  const viewScores = (callBack: (data: ExerciseScoreOverallRes) => void) => {
    viewExerciseScore(props.exerciseId)
      .unwrap()
      .then((data) => {
        callBack(data);
      })
      .catch(() => {
        console.log('Have error when view exercise scores');
      });
  };

  const onSave = (callBack: (res: ExerciseRes) => void) => {
    const request = {
      exerciseId: props.exerciseId,
      sectionId: section.sectionId,
      resourceId: resource.resourceId,
      answers: collectExerciseAnswer(currentAnswer),
    };

    saveExercise(request)
      .unwrap()
      .then((data) => {
        callBack(data);
      })
      .catch(() => {});
  };

  const onSubmit = () => {
    const request: UpdateExerciseAndAnswerReq = {
      exerciseId: props.exerciseId,
      sectionId: section.sectionId,
      resourceId: resource.resourceId,
      answers: collectExerciseAnswer(currentAnswer),
    };

    submitExercise(request)
      .unwrap()
      .then((result) => {
        notification.success({
          message: t('exercise.warning.submit_success'),
          description: t('exercise.warning.submit_success_message'),
          placement: 'bottomRight',
        });
        onSubmitComplete(result);
      })
      .catch(() => {
        notification.error({
          message: t('exercise.warning.submit_error'),
          description: t('exercise.warning.submit_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const onGrading = (callBack: (res: ExerciseRes) => void) => {
    const request: GradeExerciseReq = {
      exerciseId: props.exerciseId,
      grading: ExerciseUtils.collectGrading(gradings),
    };

    gradeExercise(request)
      .unwrap()
      .then((result) => {
        if (result.status === ExerciseStatus.COMPLETED) {
          notification.success({
            message: t('exercise.warning.submit_success'),
            description: t('exercise.warning.submit_success_message'),
            placement: 'bottomRight',
          });

          router.push(
            SiteMap.public.exercise.review_exercise_gen(result.exerciseId)
          );
        } else {
          callBack(result);
        }
      })
      .catch(() => {
        notification.error({
          message: t('exercise.warning.submit_error'),
          description: t('exercise.warning.submit_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const onSubmitComplete = (data: ExerciseRes) => {
    return ExerciseUtils.onSubmitAnonymous(type, lesson, data, router, {
      onSubmit: props.event?.onSubmit,
    });
  };

  return (
    <ExerciseServiceContext.Provider
      value={{
        isLoading: isSaveLoading || isSubmitLoading,
        params: props.exerciseId,

        lessonId: props.lessonId,
        exerciseId: props.exerciseId,

        viewStatus,

        onSave,
        onSubmit,
        onGrading,

        viewScores,
      }}
    >
      {props.children}
    </ExerciseServiceContext.Provider>
  );
};
