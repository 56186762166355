import React from 'react';

import MyNotificationPage from '@modules/setting/pages/MyNotificationPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const MyNotification = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-notification-page'}>
      <PageHeader
        menu={NavigationMenuEnum.NOTIFICATION}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('notification.my_notification')}
        description={t('notification.my_notification')}
      />
      <MyNotificationPage />
    </ManagementLayout>
  );
};

export default MyNotification;
