import React from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import { useLessonEditableContext } from '@cms/context/LessonEditableProvider';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '../../components/buttons/style';
import { useSelector } from '@app/redux/hook';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ExitComposeBtn = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { confirm } = useConfirmModal();

  const { changed } = useSelector((state) => state.composeResource);

  const { productId, lesson, from } = useLessonEditableContext();

  const goBack = () => {
    if (changed.changed) {
      confirmChange(handleGoBack);
    } else {
      confirm(
        'warning',
        t('header.confirm'),
        t('resource.warning.exit_compose'),
        t('label.quit'),
        t('label.cancel'),
        (r) => {
          if (r) {
            handleGoBack();
          }
        }
      );
    }
  };

  const confirmChange = (callBackFunc: () => void) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('resource.warning.exit_without_saving'),
      t('label.quit'),
      t('label.cancel'),
      (r) => {
        if (r) {
          callBackFunc();
        }
      }
    );
  };

  const handleGoBack = () => {
    if (lesson != null && lesson.lessonId > 0) {
      if (from === 'simple' || lesson.type === LessonTypeEnum.collection) {
        router.push(SiteMap.private.my_lesson.list);
      } else if (lesson.type === LessonTypeEnum.template) {
        router.push(SiteMap.content.example.list);
      } else if (lesson.type === LessonTypeEnum.page) {
        router.push(SiteMap.page_content.static_page);
      } else if (productId != null && Number(productId) > 0) {
        router.push(SiteMap.content.product.toc_gen(productId));
      } else {
        router.push(SiteMap.content.lesson.list);
      }
    } else {
      if (from === 'simple') {
        router.push(SiteMap.private.my_lesson.list);
      } else {
        router.push(SiteMap.content.resource.list);
      }
    }
  };

  return (
    <Tooltip title={t('lesson_compose.actions.go_back')} placement={'topLeft'}>
      <LessonButton
        danger
        type={'primary'}
        shape={'round'}
        className={'action-button'}
        onClick={goBack}
        icon={IconUtils.compose.exit_compose}
      >
        {t('label.cancel')}
      </LessonButton>
    </Tooltip>
  );
};
