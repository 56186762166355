import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';

export const GradingExerciseAPI = createApi({
  reducerPath: 'GradingExerciseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findExercises: builder.query<ExerciseRes[], number>({
      query: (assignmentInstanceId) => ({
        url: `/exercise-grading/${assignmentInstanceId}`,
      }),
    }),
  }),
});
