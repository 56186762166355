import { StandardSetWeightRes } from '@services/reports/LessonReport';

import ColorUtils from '@utils/ColorUtils';
import PieChartReport, {
  PieRecordData,
} from '@modules/reports/charts/pie/PieChart';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import React from 'react';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { H3 } from '@components/typography';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonStandardSetWeight = (props: {
  lesson: LessonRes;
  standardSet: StandardSetWeightRes[];
}) => {
  const renderFunc = (data: StandardSetWeightRes): PieRecordData => {
    return {
      id: data.standardSet.code,
      label: data.standardSet.code,
      value: data.weight,
      color: ColorUtils.getDefaultColor(data.standardSet.standardSetId).color,
    };
  };

  const getCustomColor = (params: { id: string; data: PieRecordData }) => {
    return params.data.color;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Set
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3> Lesson Standard Set</h3>}
          content={
            <>
              <p>Report này thể hiện phân bố các kĩ năng trong bài kiểm tra</p>
            </>
          }
        />
      </H3>

      <PieChartReport
        className={'lesson-standard'}
        content={props.standardSet}
        size={300}
        render={renderFunc}
        getColor={getCustomColor}
      />

      <div className={'standard-set-description'}>
        {props.standardSet.map((item) => {
          return (
            <LessonStandardSetInfo
              key={item.standardSet.standardSetId}
              item={item}
            />
          );
        })}
      </div>
    </LessonReportChartStyle>
  );
};

const LessonStandardSetInfoStyle = styled.div`
  .standard-set-icon {
    width: 1em;
    height: 1em;
    border: 1px solid #000;
    display: inline-flex;
    margin-right: 1em;
  }
`;

export const LessonStandardSetInfo = (props: {
  item: StandardSetWeightRes;
}) => {
  return (
    <LessonStandardSetInfoStyle className={'standard-set-item'}>
      <span
        className={'standard-set-icon'}
        style={{
          background: ColorUtils.getDefaultColor(
            props.item.standardSet.standardSetId
          ).color,
        }}
      />
      <span className={'standard-set-info'}>
        {props.item.standardSet.name} -{' '}
        <LanguageContentTag content={props.item.standardSet.description} />
      </span>
    </LessonStandardSetInfoStyle>
  );
};
