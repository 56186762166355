import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { LanguageRes } from '@services/setting/LanguageAPI';
import { LanguageEnum } from '@components/language/LanguageIcon';
import { BasicMessageRes } from '@services/model/Response';

export interface SchoolRegisterSettingRes {
  allowStudentRegister: boolean;
  allowTeacherRegister: boolean;
  allowParentRegister: boolean;
}

export interface UpdateSchoolRegisterSettingRes {
  schoolId?: number;
  allowStudentRegister: boolean;
  allowTeacherRegister: boolean;
  allowParentRegister: boolean;
}

export interface UpdateSchoolLanguageSettingReq {
  schoolId?: number;
  languages: LanguageEnum[];
  defaultLanguage: LanguageEnum;
}

export interface UpdateSchoolContentSettingRes {
  schoolId?: number;

  enableClassRoom: boolean;
  enableStandard: boolean;
  allowUsedLessonBank: boolean;
  allowUpdateScoring: boolean;
}

export interface UpdateSchoolUserSettingReq {
  schoolId?: number;
  accessStudentInSchool: boolean;
}

export interface ContentSettingRes {
  enableClassRoom: boolean;
  enableStandard: boolean;
  allowUsedLessonBank: boolean;
  allowUpdateScoring: boolean;
}

export interface SchoolSettingRes
  extends SchoolRegisterSettingRes,
    ContentSettingRes {
  languages: LanguageRes[];
  defaultLanguage: LanguageRes;
  accessStudentInSchool: boolean;
}

export interface UpdateSchoolSettingReq {
  allowStudentRegister: boolean;
  allowTeacherRegister: boolean;
  allowParentRegister: boolean;

  languages: LanguageEnum[];
  defaultLanguage: LanguageEnum;
}

export interface UpdateSchoolSettingAdminRes extends UpdateSchoolSettingReq {
  schoolId: number;

  enableClassRoom: boolean;
  enableStandard: boolean;
  allowUsedLessonBank: boolean;
  allowUpdateScoring: boolean;
}

export const SchoolSettingAPI = createApi({
  reducerPath: 'SchoolSettingAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getSchoolSetting: builder.query<SchoolSettingRes, unknown>({
      query: () => ({
        url: `/school/setting`,
      }),
    }),

    updateSetting: builder.mutation<SchoolSettingRes, UpdateSchoolSettingReq>({
      query: (body) => ({
        url: `/school/setting`,
        method: 'PUT',
        body,
      }),
    }),

    getSchoolSettingAdmin: builder.query<SchoolSettingRes, number>({
      query: (schoolId) => ({
        url: `/school/${schoolId}/setting`,
      }),
    }),

    updateSettingAdmin: builder.mutation<
      SchoolSettingRes,
      UpdateSchoolSettingAdminRes
    >({
      query: (body) => ({
        url: `/school/${body.schoolId}/setting`,
        method: 'PUT',
        body,
      }),
    }),

    // update register
    updateRegisterSetting: builder.mutation<
      SchoolSettingRes,
      UpdateSchoolRegisterSettingRes
    >({
      query: (body) => ({
        url: `/school/setting/register`,
        method: 'PUT',
        body,
      }),
    }),

    updateRegisterSettingAdmin: builder.mutation<
      SchoolSettingRes,
      Required<UpdateSchoolRegisterSettingRes>
    >({
      query: (body) => ({
        url: `/school/${body.schoolId}/setting/register`,
        method: 'PUT',
        body,
      }),
    }),

    // update language
    updateLanguageSetting: builder.mutation<
      SchoolSettingRes,
      UpdateSchoolLanguageSettingReq
    >({
      query: (body) => ({
        url: `/school/setting/languages`,
        method: 'PUT',
        body,
      }),
    }),

    updateLanguageSettingAdmin: builder.mutation<
      SchoolSettingRes,
      Required<UpdateSchoolLanguageSettingReq>
    >({
      query: (body) => ({
        url: `/school/${body.schoolId}/setting/languages`,
        method: 'PUT',
        body,
      }),
    }),

    // update content
    updateContentSetting: builder.mutation<
      SchoolSettingRes,
      UpdateSchoolContentSettingRes
    >({
      query: (body) => ({
        url: `/school/setting/content`,
        method: 'PUT',
        body,
      }),
    }),

    updateContentSettingAdmin: builder.mutation<
      SchoolSettingRes,
      Required<UpdateSchoolContentSettingRes>
    >({
      query: (body) => ({
        url: `/school/${body.schoolId}/setting/content`,
        method: 'PUT',
        body,
      }),
    }),

    // update users
    updateUserSetting: builder.mutation<
      SchoolSettingRes,
      UpdateSchoolUserSettingReq
    >({
      query: (body) => ({
        url: `/school/setting/users`,
        method: 'PUT',
        body,
      }),
    }),

    updateUserSettingAdmin: builder.mutation<
      SchoolSettingRes,
      Required<UpdateSchoolUserSettingReq>
    >({
      query: (body) => ({
        url: `/school/${body.schoolId}/setting/users`,
        method: 'PUT',
        body,
      }),
    }),

    // get default roles setting
    getDefaultRoles: builder.query<string[], unknown>({
      query: () => ({
        url: `/school/setting/roles`,
      }),
    }),

    updateDefaultRoles: builder.mutation<BasicMessageRes, string[]>({
      query: (roles) => ({
        url: `/school/setting/roles`,
        method: 'PUT',
        body: roles,
      }),
    }),
  }),
});
