import React, { useState } from 'react';
import { AudioPlayer, AudioPlayerActionEnum } from '@cms/content/AudioViewer';
import styled from 'styled-components';

const AudioInlinePlayer = (props: { source: string; children: any }) => {
  const [action, setAction] = useState<{
    action: AudioPlayerActionEnum;
    params: any;
  }>({
    action: AudioPlayerActionEnum.default,
    params: null,
  });

  const handleOnClickData = () => {
    setAction({
      action: AudioPlayerActionEnum.play,
      params: 0,
    });
  };

  return (
    <AudioInlinePlayerStyle
      className={'audio-inline-comp-content'}
      onClick={() => handleOnClickData()}
    >
      <div className={'audio-inline-content'}>{props.children}</div>

      <div className={'audio-inline-comp-viewer'}>
        <AudioPlayer
          action={action}
          audioSrc={props.source}
          title={props.source}
        />
      </div>
    </AudioInlinePlayerStyle>
  );
};

export default AudioInlinePlayer;

const AudioInlinePlayerStyle = styled.div`
  display: inline-flex;
  position: relative;
  cursor: pointer;

  .audio-inline-content {
    display: inline-flex;
  }

  .audio-inline-comp-viewer {
    position: absolute;
    font-size: 0;
    visibility: hidden;
  }
`;

