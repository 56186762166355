import React, { useState } from 'react';
import DragNDropContext from '@components/react-dnd-bt/DragNDropContext';
import { SectionBanks } from './components/SectionBanks';
import { LessonSectionAPI } from '@modules/product/services/LessonSectionAPI';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { LessonSectionBank } from '@cms/lesson-template/compose/lesson/LessonSectionBanks';
import {
  ComposeContentLayout,
  ComposeFooterLayout,
  ComposeLayout,
  LessonBankLayout,
  max_bank_size,
} from '@cms/lesson-template/compose/layout';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { H4 } from '@components/typography';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import SectionCompose from '@cms/lesson-template/compose/sections/components/SectionCompose';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import ComposeSectionToolbar from '@cms/lesson-template/compose/resource/ComposeSectionToolbar';
import { EmptySectionQuestion } from '@cms/lesson-template/compose/sections/components/EmptySectionQuestion';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import SectionUtils from '@cms/section-bank/SectionUtils';
import { AssetsTypeEnum } from '@modules/product/services/assets_model';

const BuildSectionTemplate = (props: { lessonId: number }) => {
  const { t } = useTranslation();

  const [createSection] = LessonSectionAPI.endpoints.createSection.useMutation(
    {}
  );

  const { lesson, mode, changeToMode, dispatchAction } =
    useLessonEditableContext();

  const [requireRefresh, setRequireRefresh] = useState(-1);
  const [section, setSection] = useState<LessonSectionProps | null>(null);

  const handleOnClose = () => {
    changeToMode(LessonMode.view);
  };

  const handleCreateSection = (section: LessonSectionProps) => {
    const request = {
      lessonId: props.lessonId,
      type: section.type,
      setting: JSON.stringify(section.setting, null, 2),
      content: JSON.stringify({
        header: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: '',
        },
        footer: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: '',
        },
      }),
    };

    createSection(request)
      .unwrap()
      .then((res) => {
        setSection(SectionUtils.convert(res));
        changeToMode(LessonMode.view_question_banks);
        handleOnChange();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSelectSection = (sec: LessonSectionProps) => {
    setSection(sec);
  };

  const handleOnChange = () => {
    setRequireRefresh(DateAndTimeUtils.getCurrentTime());
    dispatchAction(LessonEditableActionEnum.refresh, -1);
  };

  return (
    <DragNDropContext id={'build-section'}>
      <LessonBankLayout className={'lesson-sections'}>
        {lesson && (
          <div className={'component-bank'}>
            <LessonSectionBank
              section={section}
              lesson={lesson}
              onClick={handleSelectSection}
            />
          </div>
        )}
      </LessonBankLayout>

      <ComposeLayout className={'show-correct-answer compose-section-page'}>
        <ComposeContentLayout className={'compose-content-layout'}>
          <LessonTemplateLayoutProvider lesson={lesson}>
            {lesson && section && (
              <SectionCompose
                key={JSON.stringify(section)}
                lessonId={props.lessonId}
                item={section}
                onChange={handleOnChange}
              />
            )}
          </LessonTemplateLayoutProvider>
        </ComposeContentLayout>

        <ComposeFooterLayout className={'compose-footer-layout'}>
          <ComposeSectionToolbar type={lesson?.type} />
        </ComposeFooterLayout>
      </ComposeLayout>

      <Drawer
        className={'compose-lesson-bank'}
        width={max_bank_size}
        title={
          <H4 style={{ marginBottom: 0 }}>
            {t('my_lesson.actions.select_a_content')}
          </H4>
        }
        placement="left"
        open={mode === LessonMode.edit}
        closable={true}
        onClose={handleOnClose}
      >
        <SectionBanks
          lessonId={props.lessonId}
          onSelect={handleCreateSection}
        />

        <ButtonGroup type={'center'}>
          <Button shape={'round'} danger onClick={handleOnClose}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </Drawer>

      {mode === LessonMode.view_question_banks && (
        <EmptySectionQuestion
          requireRefresh={requireRefresh}
          onClose={handleOnClose}
        />
      )}
    </DragNDropContext>
  );
};

export default BuildSectionTemplate;
