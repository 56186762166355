import React from 'react';

import ReviewProductPage from '@modules/product/pages/ReviewProductPage';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';
import { ViewProductMode } from '@modules/product/components/view-toc/model';

const ReviewProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id } = router.query;

  return (
    <EmptyLayout className={'review-product-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={t('product.actions.review')}
        description={t('product.actions.review')}
      />

      <ReviewProductPage
        productId={Number(id)}
        type={ViewProductMode.compose_review}
      />
    </EmptyLayout>
  );
};

export default ReviewProduct;
