import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';
import { useTranslation } from 'react-i18next';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, notification } from 'antd';
import Input from '@components/input/input';
import SelectLessonExample from '@cms/resource-bank/SelectLessonExample';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import React, { useEffect } from 'react';
import { useLoadLessonData } from '@cms/lesson-template/compose/LessonToolbar';

export const SimpleLessonEditForm = (props: {
  lessonId?: number;
  onSuccess: (isAdded: boolean, data: LessonRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const { data, isSuccess } = useLoadLessonData(props.lessonId ?? -1, true);

  const [createLesson, { isLoading }] =
    LessonAPI.endpoints.createLesson.useMutation({});

  const [updateLesson, { isLoading: isUpdateLoading }] =
    LessonAPI.endpoints.updateLesson.useMutation({});

  useEffect(() => {
    if (data && isSuccess) {
      restoreLessonData(data);
    }
  }, [data]);

  const restoreLessonData = (data: LessonRes) => {
    formik.setValues({
      name: data.name,
      lessonExampleId: data.lessonExample
        ? data.lessonExample.lessonExampleId
        : -1,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      lessonExampleId: -1,
    },
    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.lessonId != null && props.lessonId > 0) {
        handleUpdate(props.lessonId, values.name, values.lessonExampleId);
      } else {
        handleCreate(values.name, values.lessonExampleId);
      }
    },
  });

  const handleUpdate = (
    lessonId: number,
    name: string,
    lessonExampleId: number
  ) => {
    const payload = {
      lessonId: lessonId,
      name: name,
      type: LessonTypeEnum.collection,
      lessonExampleId: lessonExampleId,
    };

    updateLesson(payload)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('lesson.warning.success'),
          description: t('lesson.warning.add_success'),
          placement: 'bottomRight',
        });
        props.onSuccess(true, data);
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.error'),
          description: t('lesson.warning.add_error'),
          placement: 'bottomRight',
        });
      });
  };

  const handleCreate = (name: string, lessonExampleId: number) => {
    const payload = {
      name: name,
      type: LessonTypeEnum.collection,
      lessonExampleId: lessonExampleId,
    };

    createLesson(payload)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('lesson.warning.success'),
          description: t('lesson.warning.add_success'),
          placement: 'bottomRight',
        });
        props.onSuccess(true, data);
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.error'),
          description: t('lesson.warning.add_error'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <FormikProvider value={formik}>
      <Input
        required
        name="name"
        type="text"
        label={t('label.name')}
        placeholder={t('label.name')}
        onChange={formik.handleChange}
        value={formik.values.name}
      />

      <SelectLessonExample
        category={LessonTypeEnum.collection}
        name={'lessonExampleId'}
        value={formik.values.lessonExampleId}
        onChange={(value) => formik.setFieldValue('lessonExampleId', value)}
      />

      <ButtonGroup className="submit-container" type={'right'}>
        <Button
          type={'primary'}
          shape={'round'}
          size={'large'}
          loading={isLoading || isUpdateLoading}
          onClick={formik.submitForm}
          icon={IconUtils.actions.save}
        >
          {props.lessonId != null && props.lessonId > 0
            ? t('button.update')
            : t('button.create')}
        </Button>

        <Button
          type="default"
          shape={'round'}
          size={'large'}
          onClick={props.onCancel}
        >
          {t('button.cancel')}
        </Button>
      </ButtonGroup>
    </FormikProvider>
  );
};
