import useScript from './useScript';
import { useEffect, useState } from 'react';
import { JsLib } from '@cms/script/LoadLibraryUtils';

const useRaphaelJs = (onSuccess: () => void) => {
  const [state, setState] = useState({
    [JsLib.jquery.name]: false,
    // [JsLibraryData.jqueryTouch.name]: false,
    [JsLib.raphael.name]: false,
  });

  useEffect(() => {
    let allCompleted = true;

    Object.keys(state).forEach((key) => {
      if (!state[key]) {
        allCompleted = false;
      }
    });

    if (allCompleted) {
      setTimeout(() => {
        onSuccess();
      }, 250);
    }
  }, [state]);

  useScript(JsLib.jquery.url, JsLib.jquery.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.jquery.name]: true } };
    });
  });

  // useScript(JsLibraryData.jqueryTouch.url, JsLibraryData.jqueryTouch.id, () => {
  //   setState((prevState) => {
  //     return { ...prevState, ...{ [JsLibraryData.jqueryTouch.name]: true } };
  //   });
  // });

  useScript(JsLib.raphael.url, JsLib.raphael.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.raphael.name]: true } };
    });
  });
};

export default useRaphaelJs;
