import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';

import { ManageProductLicenseTable } from '@modules/product/components/product/ManageProductLicenseTable';

export default function PublishProductGroup() {
  return (
    <>
      <HeaderGroup className="header-group">
        <H1>Products</H1>
      </HeaderGroup>

      <ManageProductLicenseTable />
    </>
  );
}
