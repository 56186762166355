import React, { useState } from 'react';
import Input from '@components/input/input';
import Select from 'antd/lib/select';
import Button from '@components/button';
import TextArea from 'antd/lib/input/TextArea';
import Popover from 'antd/lib/popover';
import styled from 'styled-components';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

export const ComponentGradingItem = (props: {
  className?: string;
  feedback: ComponentResponseProps;
}) => {
  const { t } = useTranslation();
  const { onGradingChange } = useResourceContext();

  const [score, setScore] = useState<number | null>(
    props.feedback.score != null && props.feedback.score >= 0
      ? props.feedback.score
      : null
  );
  const [comment, setComment] = useState(props.feedback.comment || '');

  const [disabled] = useState(() => {
    return props.feedback.autoScore || props.feedback.isSkip;
  });

  const onScoreChange = (_score: number) => {
    setScore(_score);
    onGradingChange({ id: props.feedback.id, score: _score, comment: comment });
  };

  const onCommentChange = (_comment: string) => {
    setComment(_comment);
    onGradingChange({ id: props.feedback.id, score: score, comment: _comment });
  };

  return (
    <ComponentGradingStyle
      className={`component-feedback 
        ${props.className ? props.className : ''} 
        ${score != null && score > -1 ? '' : 'need-grading'}`}
    >
      <div className={'component-score-field'}>
        {props.feedback.maxScore < 10 ? (
          <Select
            onChange={(_score) => onScoreChange(_score)}
            defaultValue={score}
            disabled={disabled}
            style={{ minWidth: '14ch' }}
          >
            <Select.Option key={'empty'} value={null}>
              Select Score
            </Select.Option>

            {Array(props.feedback.maxScore + 1)
              .fill(1)
              .map((el, i) => {
                return (
                  <Select.Option key={i} value={i}>
                    {i === 1
                      ? t('component.label.one_point')
                      : t('component.label.n_point', { n: i })}
                  </Select.Option>
                );
              })}
          </Select>
        ) : (
          <Input
            name="score"
            type="number"
            disabled={disabled}
            onBlur={(_score) => onScoreChange(Number(_score.target.value))}
            defaultValue={score || undefined}
            min={0}
            max={props.feedback.maxScore}
          />
        )}
      </div>
      <div className={'component-comment-field'}>
        <Popover
          content={
            <TextArea
              value={comment}
              rows={4}
              maxLength={255}
              onChange={(val) => onCommentChange(val.target.value)}
            />
          }
          title={t('component.label.add_comment')}
          trigger={'click'}
        >
          <Button shape={'circle'} icon={IconUtils.exercise.add_comments} />
        </Popover>
      </div>
    </ComponentGradingStyle>
  );
};

export const ComponentGradingStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25em 0.25em;
  border-radius: 0.25em;

  &.need-grading {
    background: ${(props) => props.theme.exercise.need_grading_bgr};
  }

  .component-score-field {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .component-comment-field {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.25em;
  }

  &.absolute-position {
    position: absolute;
    right: -50px;
    top: 0px;
  }
`;
