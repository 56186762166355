import { PageRouterProps } from '@router/modules/props';

import { SiteMap } from '@router/SiteMap';
import Login from '@router/pages/login/login';
import LoginAdmin from '@router/pages/login/login_admin';
import LoginToAnotherSchool from '@router/pages/login/school/[clientId]';
import Logout from '@router/pages/login/logout';
import Launch from '@router/pages/login/launch';
import SchoolLaunch from '@router/pages/login/school/launch';
import ForgotPassword from '@router/pages/login/forgot_password';
import ResetPassword from '@router/pages/login/reset_password';

import RegisterActiveAccount from '@router/pages/register/active-account';
import Register from '@router/pages/register';
import RegisterStudent from '@router/pages/register/student';
import RegisterTeacher from '@router/pages/register/teacher';
import RegisterParent from '@router/pages/register/parent';
import RegisterSuccess from '@router/pages/register/success';
import RegisterActiveUser from '@router/pages/register/active-user';

export const LoginRouters: PageRouterProps[] = [
  { path: SiteMap.auth.login, element: <Login /> },
  { path: SiteMap.auth.login_admin, element: <LoginAdmin /> },
  {
    path: SiteMap.auth.login_to_another_school,
    element: <LoginToAnotherSchool />,
  },
  { path: SiteMap.auth.logout, element: <Logout /> },
  { path: SiteMap.auth.launch, element: <Launch /> },
  { path: SiteMap.auth.school_launch, element: <SchoolLaunch /> },
  { path: SiteMap.auth.forgot_password, element: <ForgotPassword /> },
  { path: SiteMap.auth.reset_password, element: <ResetPassword /> },
];

export const RegisterRouter: PageRouterProps[] = [
  { path: SiteMap.register.index, element: <Register /> },
  { path: SiteMap.register.student, element: <RegisterStudent /> },
  { path: SiteMap.register.teacher, element: <RegisterTeacher /> },
  { path: SiteMap.register.parent, element: <RegisterParent /> },
  { path: SiteMap.register.success, element: <RegisterSuccess /> },
  { path: SiteMap.register.active_user, element: <RegisterActiveUser /> },
  { path: SiteMap.register.active_account, element: <RegisterActiveAccount /> },
];
