import React, { useEffect, useState } from 'react';
import { StandardRes } from '@modules/product/services/standard_model';
import {
  CoordinatePointProps,
  LineGraphChardRecordProps,
} from '@modules/reports/charts/line/LineGraphChart';
import {
  ProductStandardDailyScoreRes,
  ProgressReportAPI,
} from '@services/reports/ProgressReport';
import ColorUtils from '@utils/ColorUtils';
import { H1 } from '@components/typography';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import PumpChart from '@modules/reports/charts/line/BumpChart';
import { StandardDefaultScore } from '@modules/reports/product/StandardDefaultScore';
import styled from 'styled-components';

export const ProductScoreReport = (props: {
  userId?: number;
  productId: number;
}) => {
  const [getProductReport, { data, isSuccess }] =
    ProgressReportAPI.endpoints.getProductReport.useLazyQuery();

  const [
    getProductReportForUser,
    { data: userData, isSuccess: isUserSuccess },
  ] = ProgressReportAPI.endpoints.getProductReportForUser.useLazyQuery();

  const [highlightChar, setHighlightChart] = useState<number[]>([]);
  const [standards, setStandards] = useState<StandardRes[]>([]);
  const [standardReports, setStandardReportData] = useState<
    LineGraphChardRecordProps[]
  >([]);

  useEffect(() => {
    if (props.userId != null && props.userId > 0) {
      getProductReportForUser({
        productId: props.productId,
        userId: props.userId,
      });
    } else {
      getProductReport(props.productId);
    }
  }, [props.userId, props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      setReportData(data, highlightChar);
    }
  }, [data, highlightChar]);

  useEffect(() => {
    if (userData && isUserSuccess) {
      setReportData(userData, highlightChar);
    }
  }, [userData, highlightChar]);

  const renderFunc = (
    data: LineGraphChardRecordProps
  ): LineGraphChardRecordProps => {
    return data;
  };

  const setReportData = (
    data: ProductStandardDailyScoreRes,
    highlightChar: number[]
  ) => {
    const standardRecordMapping: Record<
      number,
      { standard: StandardRes; points: CoordinatePointProps[] }
    > = {};

    data.scores.forEach((rc) => {
      if (
        highlightChar.length === 0 ||
        highlightChar.indexOf(rc.standard.standardId) > -1
      ) {
        const point = {
          x: rc.createdDate.split('T')[0],
          y: rc.percent,
        };

        if (standardRecordMapping[rc.standard.standardId] == null) {
          standardRecordMapping[rc.standard.standardId] = {
            standard: rc.standard,
            points: [point],
          };
        } else {
          standardRecordMapping[rc.standard.standardId].points.push(point);
        }
      }
    });

    const _standards: StandardRes[] = [];
    const standardReports: LineGraphChardRecordProps[] = [];
    Object.entries(standardRecordMapping).forEach(([, value]) => {
      const report = {
        id: value.standard.code,
        color: ColorUtils.getDefaultColor(value.standard.standardId).color,
        data: value.points.sort((a, b) => {
          return (a.x + '').localeCompare(b.x + '');
        }),
      };
      standardReports.push(report);
      _standards.push(value.standard);
    });

    setStandardReportData(standardReports);

    if (standards.length === 0) {
      setStandards(_standards);
    }
  };

  const toggleHighlightChart = (standardId: number) => {
    setHighlightChart((prev) => {
      if (prev.indexOf(standardId) > -1) {
        return [...prev].filter((id) => {
          return id !== standardId;
        });
      } else {
        return [...prev, standardId];
      }
    });
  };

  return (
    <ViewProductReportPageStyle>
      <H1>
        Progress Reports<>{data && <>{data.product.name}</>}</>
      </H1>

      {standardReports && standards && (
        <LessonReportChartStyle className={'lesson-standard-group'}>
          <PumpChart
            className={'product-growth-chart'}
            size={500}
            content={standardReports}
            render={renderFunc}
          />

          <div className={'standard-set-description'}>
            {standards.map((item) => {
              return (
                <StandardDefaultScore
                  key={item.standardId}
                  isSelected={highlightChar.indexOf(item.standardId) > -1}
                  item={item}
                  onClick={toggleHighlightChart}
                />
              );
            })}
          </div>
        </LessonReportChartStyle>
      )}
    </ViewProductReportPageStyle>
  );
};

const ViewProductReportPageStyle = styled.div`
  .standard-set-description {
    margin-top: 2em;

    .standard-item-data {
      margin-top: 0.75em;
      padding: 0.25em;
      border-radius: 0.25em;
      cursor: pointer;

      &.selected {
        background: ${(props) => props.theme.color.grey};
      }

      .standard-item {
        display: flex;
        align-items: baseline;

        .ant-tag {
          min-width: 10ch;
          text-align: center;
        }
      }
    }
  }
`;
