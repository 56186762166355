import React, { ReactNode } from 'react';
import { StyledInput } from '@components/input/style';
import styled from 'styled-components';
import { Typography } from 'antd';
const { Text } = Typography;

export const InputGroup = (props: {
  addedClass?: string;
  label?: string | ReactNode;
  hint?: ReactNode;
  required?: boolean;
  onClick?: () => void;
  children: any;
}) => {
  return (
    <StyledInput
      className={`form-group ${props.addedClass ? props.addedClass : ''}`}
      onClick={props.onClick}
      {...props}
    >
      {(props.label != null || props.hint != null) && (
        <div className={'ant-form-label'}>
          {props.label}
          {props.required && <span className={'required-character'}>*</span>}
          {props.hint}
        </div>
      )}

      <div className={'ant-form-controls'}>{props.children}</div>
    </StyledInput>
  );
};

export const InputValue = (props: {
  type?: string;
  copyable?: boolean;
  children: ReactNode;
}) => {
  return (
    <div className={`input-value-display ${props.type}`}>
      <Text copyable={props.copyable}>{props.children}</Text>
    </div>
  );
};

export const FormHorizontal = (props: any) => {
  return (
    <FormHorizontalStyle className={'form-horizontal'} {...props}>
      {props.children}
    </FormHorizontalStyle>
  );
};

export const FormGroup = ({ className, children, ...props }: any) => {
  return (
    <FormGroupStyle
      className={`form-group-container ${className ?? ''}`}
      {...props}
    >
      {children}
    </FormGroupStyle>
  );
};

const FormGroupStyle = styled.div`
  .form-group {
    margin-bottom: 0px;
  }
`;

const FormHorizontalStyle = styled.div`
  .ant-row {
    margin-bottom: 24px;

    .screen-xs & {
      margin-bottom: 8px;
    }

    .screen-sm & {
      margin-bottom: 12px;
    }

    .screen-md & {
      margin-bottom: 16px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
`;
