import { UserProfileRes } from '@services/private/UserProfileAPI';
import { FaultTolerantImg } from '@components/public/FaultTolerantImg';
import { Card } from 'antd';
import React from 'react';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

export const TeacherProfile = (props: { item: UserProfileRes }) => {
  return (
    <NavLink href={SiteMap.public.teacher_view_gen(props.item.name)}>
      <Card
        hoverable
        style={{ width: '100%' }}
        cover={
          <FaultTolerantImg
            width={'100%'}
            height={'100%'}
            src={props.item.avatarUrl}
            alt={props.item.displayName}
          />
        }
      >
        <h3>{props.item.displayName}</h3>

        <div
          className={'school-introduction'}
          dangerouslySetInnerHTML={{ __html: props.item.shortIntroduce }}
        />
      </Card>
    </NavLink>
  );
};
