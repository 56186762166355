// import '/math-equation/guppy-default-osk.min.css';
// import 'public/math-equation/katex.min.css';

import React, { useEffect, useRef, useState } from 'react';
import useMathEquation from '@cms/script/useMathEquation';
import styled from 'styled-components';

export enum MathEquationLayoutEnum {
  inline = 'inline',
  block = 'block',
}

export enum MathEquationTabEnum {
  // arithmetic = 'arithmetic',
  // qwerty = 'qwerty',
  // QWERTY = 'QWERTY',
  functions = 'functions',
  editor = 'editor',
  calculus = 'calculus',
  array = 'array',
  operations = 'operations',
  trigonometry = 'trigonometry',
  greek = 'greek',
}

export const MathEquationInput = (props: {
  id: string;
  disabled: boolean;
  initValue: string;
  activeTab?: MathEquationTabEnum;
  activeTabs: MathEquationTabEnum[];
  onChange: (val: string) => void;
}) => {
  const ref = useRef(null);
  const editorRef = useRef(null);

  const [ready, setReady] = useState(false);
  const [expression, setExpression] = useState({
    value: ' ',
    triggerChange: false,
  });

  useMathEquation(() => {
    setReady(true);
  });

  useEffect(() => {
    if (expression && expression.triggerChange) {
      props.onChange(expression.value);
    }
  }, [expression]);

  useEffect(() => {
    setExpression({
      value: props.initValue,
      triggerChange: false,
    });
  }, [props.initValue]);

  const handleValueChange = (ev: any) => {
    setExpression({
      value: ev.target.latex(),
      triggerChange: false,
    });
  };

  const handleFocus = (ev: any) => {
    if (!ev.focused) {
      setExpression((prev) => {
        return { ...prev, triggerChange: true };
      });
    }
  };

  const triggerWhenEnter = (ev: any) => {
    console.log('The event is trigger when user press Enter key.', ev);
  };

  useEffect(() => {
    if (ready) {
      // @ts-ignore
      Guppy.use_osk(
        // @ts-ignore
        new GuppyOSK({ attach: 'focus' })
      );

      // @ts-ignore
      const guppy = new Guppy(`math-equation-${props.id}`);
      // guppy.configure('empty_content', '\\color{red}{\\text{[?]}}');

      if (expression.value != null && expression.value.trim() !== '') {
        // @ts-ignore
        guppy.import_xml(
          // @ts-ignore
          MathExpression.fromLaTeX(expression.value).toXML()
        );
      }
      guppy.render(true);
      guppy.event('focus', handleFocus);
      guppy.event('change', handleValueChange);
      guppy.event('done', triggerWhenEnter);
    }
  }, [ready]);

  return (
    <MathEquationDisplay
      className={`math-equation-display ${props.activeTabs.join(' ')}`}
    >
      <div
        className={'math-equation-editor'}
        id={'math-equation-' + props.id}
        ref={ref}
      />
      <div
        className={'math-equation-editor-tools'}
        id={'math-equation-editor-' + props.id}
        ref={editorRef}
      />
    </MathEquationDisplay>
  );
};

const MathEquationDisplay = styled.div`
  z-index: 1;

  .math-equation-editor {
    min-width: 5em;
    min-height: 1.6em;

    padding: 0.5em 0.5em;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 0.25em;
    outline: none !important;

    .guppy_buttons {
      display: none !important;
    }

    &.guppy_active {
      border: 1px solid #ccc !important;
    }
    z-index: 2;
  }

  .math-equation-editor-tools {
    position: relative;
    z-index: 3;
    font-size: 80%;
    margin-top: 10px;

    &:not(:empty) {
      &:before,
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        display: inline-block;
        position: absolute;
        left: 20px;
        transform: translate(-50%, -100%);
      }

      &:before {
        top: 0;
        border-color: transparent transparent #ccc transparent;
      }

      &:after {
        top: 1px;
        border-color: transparent transparent #fff transparent;
      }
    }

    .guppy_osk {
      position: absolute;
      left: 0px;
      top: 0px;
      right: auto;
      bottom: auto;
      border: 1px solid #ccc;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      padding: 0.5em 0.5em;
      background: #fff;
      width: auto;
      max-width: 620px;

      .tabbar {
        padding: 0 0 0.5em 0;
        margin-bottom: 0.5em;
        border-bottom: 1px solid #ccc;

        float: none;

        a.active_tab {
          background: ${(props) => props.theme.component.primary} !important;
          color: ${(props) => props.theme.color.white} !important;
        }

        .scroller-left,
        .scroller-right {
          width: auto !important;

          &.disabled {
            display: none;
          }
        }

        ul {
          text-align: left;
          margin: 0px;
          width: 100% !important;
          overflow-x: auto;
          float: none;
          display: flex;
          flex-direction: row;

          li.guppy_osk_tab {
            a {
              padding: 0.25em 0.25em;
              min-width: 4em !important;
              min-height: auto !important;
              line-height: 1.6em !important;
              color: inherit;
              border: 1px solid #ccc;
              border-radius: 6px;
              text-align: center;
              margin-left: 0px;
              margin-right: 0.25em;
            }
          }
        }
      }

      > .controls {
        display: none !important;
      }
    }

    .guppy_osk_group {
      text-align: left;
      font-size: 80%;

      .guppy_osk_group_box {
        .guppy_osk_key {
          width: auto;
          min-width: 3em !important;
          height: auto !important;
          min-height: 3em;
          max-height: 6em !important;
          line-height: 3em !important;
        }

        .spacer {
          width: 1em !important;
        }
      }

      &[id='qwerty'],
      &[id='QWERTY'] {
        text-align: center;
      }
    }
  }
`;
