import React from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';

import PublicLayout from '@components/template/public-layout/PublicLayout';
import AppVersionPage from '@modules/setting/pages/AppVersionPage';
import { PageHeader } from '@app/header/PageHeader';

const AppVersion = () => {
  return (
    <PublicLayout className={'version-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_LANDING_PAGE}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'App version'}
        description={'App version'}
      />
      <AppVersionPage version={'v1.0.3'} />
    </PublicLayout>
  );
};

export default AppVersion;
