import React, { useEffect, useState } from 'react';
import { MetadataAPI } from '@services/metadata/MetadataAPI';
import { useTranslation } from 'react-i18next';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { InputGroup } from '@components/input/InputGroup';
import { LanguageContentTag } from '@components/text/LanguageTag';
import Select from '@components/select/Select';
import { DefaultOptionType } from 'antd/lib/select';

const SelectLessonType = (props: {
  formik: any;
  name: string;
  value: LessonTypeEnum;
  onChange: (value: LessonTypeEnum) => void;
}) => {
  const { t } = useTranslation();

  const [findAllLessonType, { data }] =
    MetadataAPI.endpoints.findAllLessonType.useLazyQuery();

  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>([
    {
      key: LessonTypeEnum.empty,
      value: LessonTypeEnum.empty,
      label: t('lesson.actions.select_type'),
    },
  ]);

  useEffect(() => {
    findAllLessonType({});
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const restrict = getRestrictLessonTypes();

      const dataFilters = data.filter((res) => {
        return restrict.includes(res.name);
      });

      const options = dataFilters.map((lt) => {
        return {
          key: lt.name,
          value: lt.name,
          label: <LanguageContentTag content={lt.description} />,
        };
      });

      setSelectOptions(options);
    }
  }, [data, props.value]);

  return (
    <InputGroup label={<>{t('lesson.form.lesson_type')}</>}>
      <Select
        size={'large'}
        placeholder={t('lesson_type.title')}
        onChange={(value) => props.onChange(value)}
        name={props.name}
        value={props.value}
        options={selectOptions}
      />
    </InputGroup>
  );
};

export default SelectLessonType;

const getRestrictLessonTypes = (): LessonTypeEnum[] => {
  return [
    LessonTypeEnum.lesson,
    LessonTypeEnum.collection,
    LessonTypeEnum.game,
    LessonTypeEnum.page,
    LessonTypeEnum.survey,
  ];
};
