import { createSlice } from '@reduxjs/toolkit';
import cookie from 'js-cookie';
import { genUUID } from '@utils/uuid';

import { BroadcastChannel } from 'broadcast-channel';
import { SiteMap } from '@router/SiteMap';
import { RoleEnum } from '@app/redux/slices/roles';
import { RefreshTokenRes } from '@modules/authorization/services/model';
const logoutChannel = new BroadcastChannel('logout');

export enum BROADCAST_MESSAGE_TYPE {
  LOGOUT = 'Logout',
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = (msg: string) => {
    if (BROADCAST_MESSAGE_TYPE.LOGOUT === msg) {
      handleLogout(true);
    }
    logoutChannel.close();
  };
};

export interface LoginUserProp {
  uuid: string;
  roles: RoleEnum[];
}

export interface LoginSuccessProp extends LoginUserProp {
  access_token: string;
  refresh_token: string;
}

export const getPersonateSecretCode = () => {
  return localStorage.getItem('personateSecretCode') ?? '';
};

export const getRefreshTokenData = () => {
  return localStorage.getItem('refreshToken') ?? '';
};

export const getAccessTokenData = () => {
  return localStorage.getItem('accessToken') ?? '';
};

export const setAccessTokenData = (acess_token: string) => {
  localStorage.setItem('accessToken', acess_token);
};

export const updateCurrentUserData = (
  access_token: string,
  refresh_token: string,
  uuid: string,
  authorities: RoleEnum[]
) => {
  cookie.set('accessToken', access_token);

  localStorage.setItem('accessToken', access_token);
  localStorage.setItem('refreshToken', refresh_token);
  // store user data
  localStorage.setItem('uuid', uuid);
  localStorage.setItem('authorities', authorities.join(','));
};

export const clearCurrentUserData = () => {
  cookie.remove('accessToken');
  cookie.remove('sessionExpiredTime');
  cookie.remove('personateSecretCode');

  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  // clear user data
  localStorage.removeItem('uuid');
  localStorage.removeItem('authorities');
  localStorage.removeItem('personateSecretCode');
};

export const handleLogout = (redirect: boolean) => {
  clearCurrentUserData();

  if (redirect) {
    logoutChannel.postMessage(BROADCAST_MESSAGE_TYPE.LOGOUT);
    window.location.href = window.location.origin + SiteMap.auth.login;
  }
};

const initialState = {
  isAuth: false,
  isReady: false,
  uuid: genUUID(),
  personateSecretCode: '',
  authorities: [RoleEnum.ANONYMOUS] as RoleEnum[],
};

export const getCurrentData = (): LoginUserProp => {
  const uuid = localStorage.getItem('uuid')
    ? localStorage.getItem('uuid') + ''
    : '';

  const roles = localStorage.getItem('authorities')
    ? ((localStorage.getItem('authorities') + '').split(',') as RoleEnum[])
    : [RoleEnum.ANONYMOUS];

  return {
    uuid: uuid,
    roles: roles,
  };
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,

  reducers: {
    login(state, action: { payload: LoginSuccessProp }) {
      state.isAuth = true;
      state.isReady = true;
      state.uuid = action.payload.uuid;
      state.authorities = action.payload.roles;
      state.personateSecretCode = getPersonateSecretCode();

      updateCurrentUserData(
        action.payload.access_token,
        action.payload.refresh_token,
        action.payload.uuid,
        action.payload.roles
      );
    },

    relogin(state, action: { payload: LoginUserProp }) {
      state.isAuth = true;
      state.isReady = true;
      state.uuid = action.payload.uuid;
      state.authorities = action.payload.roles;
      state.personateSecretCode = getPersonateSecretCode();
    },

    oauthLogin(
      state,
      action: { payload: { uid: string; access_token: string } }
    ) {
      state.isAuth = true;
      state.isReady = true;
      state.uuid = action.payload.uid;
      state.authorities = [RoleEnum.STUDENT];

      updateCurrentUserData(
        action.payload.access_token,
        '',
        action.payload.uid,
        state.authorities
      );
    },

    changeToPersonateMode(state, action: { payload: string | null }) {
      if (action.payload && action.payload.trim() !== '') {
        state.personateSecretCode = action.payload;

        cookie.set('personateSecretCode', action.payload);
        localStorage.setItem('personateSecretCode', action.payload);
      } else {
        cookie.remove('personateSecretCode');
        localStorage.removeItem('personateSecretCode');
      }
    },

    updateAccessToken: (state, action: { payload: RefreshTokenRes }) => {
      cookie.set('accessToken', action.payload.access_token);
      localStorage.setItem('accessToken', action.payload.access_token);
      localStorage.setItem('refreshToken', action.payload.refresh_token);
    },

    changeToLogout(state) {
      Object.assign(state, initialState);
      handleLogout(false);
    },

    logout(state) {
      Object.assign(state, initialState);
      handleLogout(true);
    },
  },
});

const { actions, reducer } = profileSlice;

export const {
  login,
  relogin,
  oauthLogin,
  logout,
  changeToLogout,
  updateAccessToken,
  changeToPersonateMode,
} = actions;

export default reducer;
