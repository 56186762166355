import React, { useEffect } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import { ImageScale } from '@utils/ImageScale';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import IconImage from '@components/images/IconImage';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { UserParentAPI } from '@modules/users/services/UserParentAPI';

export const ViewParentInfo = (props: {
  schoolId?: number;
  parentId: number;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getUserData, { data, isSuccess }] =
    UserParentAPI.endpoints.getUserData.useLazyQuery();

  useEffect(() => {
    if (props.parentId != null && props.parentId > 0) {
      getUserData(props.parentId);
    }
  }, [props.parentId]);

  return (
    <>
      {data && isSuccess && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={ImageScale.avatar.width + 24 + 'px'}>
            <InputGroup label={t('label.avatar')}>
              <InputValue type={'textarea'}>
                <IconImage
                  src={data?.avatar}
                  width={ImageScale.avatar.width}
                  height={ImageScale.avatar.height}
                />
              </InputValue>
            </InputGroup>
          </Col>

          <Col flex="auto">
            <InputGroup label={t('label.username')}>
              <InputValue type={'textarea'} copyable={true}>
                {data.username}
              </InputValue>
            </InputGroup>

            <Divider />

            <InputGroup label={t('label.firstName')}>
              <InputValue type={'textarea'}>{data.firstName}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.lastName')}>
              <InputValue type={'textarea'}>{data.lastName}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.email')}>
              <InputValue type={'textarea'}>{data.email}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.phone')}>
              <InputValue type={'textarea'}>{data.phone}</InputValue>
            </InputGroup>

            <div className="submit-container">
              <ButtonGroup type={'right'}>
                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.close')}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
