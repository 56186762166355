import { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';

export const TextEditable = (props: {
  initValue: string;
  placeHolder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  style?: any;
}) => {
  const contentRef = useRef<any>(null);
  const text = useRef(props.initValue);

  const [value, setValue] = useState({
    value: props.initValue,
    triggerChange: false,
  });

  useEffect(() => {
    setValue({
      value: text.current,
      triggerChange: false,
    });
  }, [props.initValue, contentRef.current]);

  useEffect(() => {
    if (value.triggerChange) {
      props.onChange(value.value);
    }
  }, [value]);

  const handleChange = (evt: any) => {
    text.current = evt.target.value;
  };

  const handleBlur = (evt: any) => {
    if (evt.target.innerText !== props.initValue) {
      setValue({
        value: evt.target.innerHTML,
        triggerChange: true,
      });
    }
  };

  const pasteAsPlainText = (event: any) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  return (
    <TextEditableStyle
      className={'cms-content-editable'}
      style={props.style ? props.style : {}}
    >
      {props.placeHolder && !text.current && (
        <div className={'text-place-holder'}>{props.placeHolder}</div>
      )}

      {props.disabled && (
        <div dangerouslySetInnerHTML={{ __html: props.initValue }} />
      )}

      {!props.disabled && (
        <ContentEditable
          key={value.value}
          ref={contentRef}
          html={value.value}
          onBlur={handleBlur}
          onChange={handleChange}
          onPaste={pasteAsPlainText}
        />
      )}
    </TextEditableStyle>
  );
};

const TextEditableStyle = styled.div`
  position: relative;

  div[contenteditable] {
    border: none;

    &:focus {
      outline: none;
    }
  }

  .text-place-holder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.component.disabled};
  }
`;
