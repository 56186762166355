import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';

import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import ComposeSimpleLessonPage from '@modules/product/components/lesson/pages/ComposeSimpleLessonPage';
import { PageHeader } from '@app/header/PageHeader';

const CreateLesson = () => {
  const { t } = useTranslation();

  return (
    <ComposeLessonLayout className={'compose-lesson-layout create-lesson'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.actions.create')}
        description={t('lesson.actions.create')}
      />

      <ComposeSimpleLessonPage lessonId={-1} from={'lesson'} />
    </ComposeLessonLayout>
  );
};

export default CreateLesson;
