import { ReactNode, useEffect, useRef, useState } from 'react';
import useHighlightTextLib from '@cms/script/useHighlightTextLib';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

export interface HighlightTextProps {
  init: () => void;
  restoreAnswer: (content: string) => void;
  clearText: () => void;
  updateText: (id: string, type: string) => void;
  removeText: (id: string) => void;
}

export interface TextSelectableSettingProps {
  excludeClassName?: string[];
  className: string;
}

export interface SelectTextProps {
  id: string;
  type?: string;
  content: string;
}

export interface TextSelectableProps {
  disabled?: boolean;
  className?: string;
  content: string;
  answer: string;

  setting?: TextSelectableSettingProps;

  action: { id: string; type: string };

  actions: {
    onSelect: (data: SelectTextProps, bBox: any) => void;
    onChange: (data: string) => void;
  };
  children: ReactNode;
}

export const TextSelectable = (props: TextSelectableProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const highlightUtils = useRef<HighlightTextProps | null>(null);
  const [ready, setReady] = useState(false);

  const [contentDisplay, setContentDisplay] = useState(props.content);

  useEffect(() => {
    if (props.answer == null || props.answer.trim() === '') {
      setContentDisplay(props.content);
    } else {
      setContentDisplay(props.answer);
      if (highlightUtils.current != null) {
        highlightUtils.current.restoreAnswer(props.answer);
      }
    }
  }, [props.answer]);

  useHighlightTextLib(() => {
    setReady(true);
  });

  useEffect(() => {
    if (ref.current && ready) {
      // @ts-ignore
      highlightUtils.current = new HighlightText(
        ref.current,
        props.setting,
        props.actions,
        COMPONENT_UTILS.generateUID
      );

      highlightUtils.current!.init();
    }
  }, [ref.current, ready]);

  useEffect(() => {
    if (ready && highlightUtils.current) {
      if (props.action.type === 'cancel') {
        highlightUtils.current.clearText();
      } else if (props.action.type === 'remove' && props.action.id) {
        highlightUtils.current.removeText(props.action.id);
      } else {
        highlightUtils.current.updateText(props.action.id, props.action.type);
      }
    }
  }, [props.action, ready]);

  return (
    <TextSelectableStyle
      className={`text-selectable-container ${
        props.disabled ? 'disabled' : ''
      }`}
    >
      <div
        ref={ref}
        className={`text-selectable ${props.className ?? ''}`}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentDisplay) }}
      />
      {props.children}
    </TextSelectableStyle>
  );
};

const TextSelectableStyle = styled.div`
  position: relative;

  &.disabled {
    .text-selectable {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }

  .text-selectable {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
  }
`;
