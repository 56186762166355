import { Button, Dropdown } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { RoleEnum } from '@app/redux/slices/roles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSecurity } from '@utils/authorization';
import { ProductRes } from '@modules/product/services/product_model';

export const PublishProductAction = (props: {
  item: ProductRes;
  onChange: () => void;
}) => {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: 'product-license',
              icon: IconUtils.actions.assign,
              label: (
                <NavLink
                  href={SiteMap.license.book_license_gen(props.item.productId)}
                >
                  {t('license.actions.assign_license')}
                </NavLink>
              ),
            },
            {
              key: 'divider-1',
              type: 'divider',
            },

            ifAnyGranted([RoleEnum.PUBLISH_LICENSE, RoleEnum.SCHOOL])
              ? {
                  key: 'publish',
                  icon: IconUtils.actions.publish,
                  label: (
                    <NavLink
                      href={SiteMap.content.product.publish_gen(
                        props.item.productId
                      )}
                    >
                      {t('product.publish')}
                    </NavLink>
                  ),
                }
              : null,
          ],
        }}
        trigger={['click']}
      >
        <Button type="default" shape="circle" icon={IconUtils.more_icon} />
      </Dropdown>
    </>
  );
};
