import React from 'react';

import { ShareProductAPI } from '@services/share/ShareProductAPI';
import ShareProductToStudentForm from '@components/form/ShareProductToStudentForm';
import ProductMetadata from '@modules/product/components/product/ProductMetadata';
import { ProductActionEnum } from '@modules/product/container/product/TeacherProductGroup';
import ProductEditForm from '@modules/product/components/product/ProductEditForm';
import { useTranslation } from 'react-i18next';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { IconUtils } from '@utils/IconUtils';
// import InviteStudentProductForm from '@components/form/InviteStudentProductForm';

export default function ProductManageForm(props: {
  productId: number;
  type: ProductActionEnum;
  onSuccess: () => void;
  onClose: () => void;
  handleOnChangeTab: (action: ProductActionEnum) => void;
}) {
  const { t } = useTranslation();

  const [addStudent] = ShareProductAPI.endpoints.addStudent.useMutation();
  const [removeStudent] = ShareProductAPI.endpoints.removeStudent.useMutation();

  const onAddStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.productId != null && props.productId > 0) {
      const request = {
        productId: props.productId,
        studentId: studentId,
      };

      addStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  const onRemoveStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.productId != null && props.productId > 0) {
      const request = {
        productId: props.productId,
        studentId: studentId,
      };

      removeStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  return (
    <ResponsiveTabs
      onChange={(type) => props.handleOnChangeTab(type as ProductActionEnum)}
      items={[
        {
          key: ProductActionEnum.EDIT_PRODUCT,
          label: t('product.actions.edit'),
          icon: IconUtils.actions.edit,
          children: (
            <ProductEditForm
              productId={props.productId}
              successHandle={props.onSuccess}
              onCloseFunc={props.onClose}
            />
          ),
        },
        {
          key: ProductActionEnum.EDIT_METADATA,
          label: t('product.actions.update'),
          icon: IconUtils.actions.edit_metadata,
          children: (
            <ProductMetadata
              type={'edit'}
              productId={props.productId}
              onCancel={props.onClose}
              onSuccess={props.onSuccess}
            />
          ),
        },
        {
          key: ProductActionEnum.SHARE_PRODUCT,
          icon: IconUtils.actions.share,
          label: t('product.actions.share'),
          children: (
            <ShareProductToStudentForm
              productId={props.productId}
              selectedStudents={[]}
              onAddStudent={onAddStudent}
              onRemoveStudent={onRemoveStudent}
              successHandle={() => {}}
            />
          ),
        },
        // {
        //   key: ProductActionEnum.INVITE_STUDENT,
        //   icon: IconUtils.actions.invite_student,
        //   label: t('product.actions.invite'),
        //   children: (
        //     <InviteStudentProductForm
        //       productId={props.productId}
        //       successHandle={() => {}}
        //     />
        //   ),
        // },
        //
        // {
        //   key: ProductActionEnum.SHARE_ACCESS_CODE,
        //   label: t('product.actions.share_public_link'),
        //   children: (
        //     <ShareProductToStudentForm
        //       productId={props.productId}
        //       selectedStudents={[]}
        //       onAddStudent={onAddStudent}
        //       onRemoveStudent={onRemoveStudent}
        //       successHandle={() => {}}
        //     />
        //   ),
        // },
      ]}
      activeKey={props.type}
    />
  );
}
