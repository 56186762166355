import React, { useEffect, useState } from 'react';

import { H3 } from '@components/typography';
import ResourceStandardForm, {
  EditStandardModeEnum,
  ResourceStandardItem,
} from '@modules/product/components/resource/metadata/ResourceStandardForm';
import { StandardRes } from '@modules/product/services/standard_model';
import { useTranslation } from 'react-i18next';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useSelector } from '@app/redux/hook';

const ResourceStandardEditable = () => {
  const { t } = useTranslation();

  const [getResourceStandards, { data, isSuccess }] =
    ResourceAPI.endpoints.getResourceStandards.useLazyQuery({});

  const { originalResource } = useSelector((state) => state.composeResource);

  const [mode, setMode] = useState('view');
  const [standards, setStandards] = useState<StandardRes[]>([]);

  const [header, setHeader] = useState(t('standard_set.standards'));

  useEffect(() => {
    if (originalResource.resourceId > 0) {
      getResourceStandards(originalResource.resourceId);
    }
  }, [originalResource.resourceId]);

  useEffect(() => {
    if (data && isSuccess) {
      setStandards(data);
    }
  }, [data]);

  const changeToEditMode = () => {
    setMode('edit');
  };

  const changeToDefault = () => {
    setMode('view');
    getResourceStandards(originalResource.resourceId);
  };

  const handleOnChange = (mode: EditStandardModeEnum) => {
    if (mode === EditStandardModeEnum.add_standard_set) {
      setHeader(
        t('lesson_compose.actions.resource_standards.add_standard_set')
      );
    } else if (mode === EditStandardModeEnum.add_standard) {
      setHeader(t('lesson_compose.actions.resource_standards.add_standard'));
    }
  };

  return (
    <>
      {originalResource.resourceId > 0 && (
        <>
          <HeaderGroup className="header-group">
            <H3>
              {t('resource.standards')}
              <Button
                type={'primary'}
                size={'small'}
                shape={'circle'}
                onClick={changeToEditMode}
                icon={IconUtils.edit}
              />
            </H3>
          </HeaderGroup>

          <div className={'resource-standard-items'}>
            {standards.map((st) => {
              return <ResourceStandardItem key={st.standardId} standard={st} />;
            })}
          </div>

          {mode === 'edit' && originalResource.resourceId > 0 && (
            <CustomModal
              header={header}
              className={CustomModalClassEnum.large_modal}
              content={
                <ResourceStandardForm
                  resourceId={originalResource.resourceId}
                  onChange={handleOnChange}
                  onSuccess={changeToDefault}
                  onCancel={changeToDefault}
                />
              }
              onCloseFunc={changeToDefault}
            />
          )}
        </>
      )}
    </>
  );
};

export default ResourceStandardEditable;
