import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { HTMLUtils } from '@cms/utils/HtmlUtils';
import DOMPurify from 'dompurify';

export const InputWordComp = (props: {
  className?: string;
  maxLength: number;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
}) => {
  const contentRef = useRef<any>(null);

  const [answer, setAnswer] = useState({
    value: props.value,
    triggerChange: false,
  });

  useEffect(() => {
    setAnswer({
      value: props.value ? props.value : '',
      triggerChange: false,
    });
  }, [props.value, contentRef.current]);

  useEffect(() => {
    if (answer.triggerChange) {
      props.onChange(answer.value);
    }
  }, [answer]);

  const handleChange = (evt: any) => {
    setAnswer({
      value: evt.target.value,
      triggerChange: false,
    });
  };

  const handleBlur = (evt: any) => {
    setAnswer({
      value: evt.target.innerHTML,
      triggerChange: true,
    });
  };

  const pasteAsPlainText = (event: any) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  return (
    <FibWordStyle className={`comp comp-word ${props.className ?? ''}`}>
      {props.disabled ? (
        <div
          className={'word-field'}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(answer.value + ' '),
          }}
        />
      ) : (
        <ContentEditable
          className={'word-field'}
          ref={contentRef}
          html={answer.value ? answer.value : ''}
          onKeyUp={(e) => {
            return HTMLUtils.validateMaxLength(e, props.maxLength);
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          onPaste={pasteAsPlainText}
        />
      )}
    </FibWordStyle>
  );
};

const FibWordStyle = styled.div`
  display: inline-flex;

  .word-field {
    display: inline-block;
    padding: 0 0.25em;
    outline: none !important;
    font-weight: 600;
    text-align: center;
    min-height: 1.4em;
    min-width: 5em;
    color: ${(props) => props.theme.component.primary};
    border-bottom: 2px solid #cccccc;

    &:focus {
      outline: none !important;
    }

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
      border-bottom: 2px solid
        ${(props) => props.theme.component.correct_answer_bgr};
    }
  }
`;
