import { Button, Col, notification, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { InputGroup } from '@components/input/InputGroup';
import DatePicker from '@components/date-n-time/DatePicker';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ProductDisplay } from '@modules/public/components/products/ProductDipslay';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import { ProductLicenseRes } from '@modules/license/services/model';
import { UserInfoRes, UserRes } from '@modules/users/services/model';
import { ProductRes } from '@modules/product/services/product_model';

export const AssignProductLicenseForUser = (props: {
  product: ProductRes;
  user: UserRes | UserInfoRes;
  onSuccess: (data: ProductLicenseRes) => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();

  const [assignLicenseForUsers] =
    UserProductLicenseAPI.endpoints.assignLicenseForUsers.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      availableDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      expiredDate: DateAndTimeUtils.add(
        DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
        DateAndTimeFormat.YYYY_MM_DD,
        1,
        'year'
      ),
    },
    onSubmit: (values) => {
      handleOnSubmit(values.availableDate, values.expiredDate);
    },
  });

  const handleOnSubmit = (availableDate: string, expiredDate: string) => {
    const request = {
      productId: props.product.productId,
      userIds: [props.user.userId],
      availableDate: availableDate,
      expiredDate: expiredDate,
    };

    assignLicenseForUsers(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('license.warning.add_success'),
          description: t('license.warning.add_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('license.warning.add_error'),
          description: t('license.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={'Product'}>
            <ProductDisplay size={32} item={props.product} />
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={'Assign to'}>
            <UserThumbnailDisplay size={32} item={props.user} />
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('label.available_date')}>
            <DatePicker
              formik={formik}
              name="availableDate"
              value={formik.values.availableDate}
              handleChange={formik.handleChange}
            />
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={t('label.expired_date')}>
            <DatePicker
              formik={formik}
              name="expiredDate"
              value={formik.values.expiredDate}
              handleChange={formik.handleChange}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup type={'right'} className={'submit-container'}>
            <Button size={'large'} shape={'round'} onClick={props.onCancel}>
              {t('label.cancel')}
            </Button>

            <Button
              type={'primary'}
              size={'large'}
              shape={'round'}
              icon={IconUtils.menu.license}
              onClick={formik.submitForm}
            >
              {t('license.actions.assign_license')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};
