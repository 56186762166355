import Input from '@components/input/input';
import React, { useEffect, useState } from 'react';

import {
  getLanguageTextDisplay,
  LanguageIcon,
} from '@components/language/LanguageIcon';
import { useTranslation } from 'react-i18next';
import ContentEditor from '@components/editor/WysyEditor';
import { InputGroup } from '../input/InputGroup';
import { useSelector } from '@app/redux/hook';

export const LanguageContentInput = (props: {
  type?: 'wysi' | 'textarea';
  initValue?: string;
  row: number;
  maxLength: number;
  className?: string;
  required?: boolean;
  onChange: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const { ready, languages, language } = useSelector(
    (state) => state.appLanguage
  );

  const [contentValue, setContentValue] = useState<Record<string, string>>({
    default: '',
  });

  useEffect(() => {
    if (props.initValue) {
      try {
        setContentValue(JSON.parse(props.initValue));
      } catch (e) {
        setContentValue({
          default: '',
        });
      }
    } else {
      setContentValue({
        default: '',
      });
    }
  }, [props.initValue]);

  useEffect(() => {
    props.onChange(JSON.stringify(contentValue));
  }, [contentValue]);

  const handleOnContentChange = (value: string, lang: string) => {
    const newData: Record<string, string> = {};
    newData[lang] = value;
    setContentValue((prev) => {
      return {
        ...prev,
        ...newData,
      };
    });
  };

  return (
    <>
      {props.type === 'wysi' ? (
        <ContentEditor
          initValue={contentValue['default']}
          onChange={(value: string) => handleOnContentChange(value, 'default')}
        />
      ) : (
        <Input
          key={''}
          required={props.required}
          className={props.className ?? ''}
          name={'default'}
          label={
            <>
              {t('school_language.actions.default_language')}
              {language && (
                <span className={'default-text'}>
                  &nbsp;(
                  <LanguageIcon language={language} height={10} />)
                </span>
              )}
            </>
          }
          placeholder={t('school_language.actions.default_language')}
          onChange={(e) => handleOnContentChange(e.target.value, 'default')}
          value={contentValue['default']}
          maxLength={props.maxLength}
          type="textarea"
          row={props.row}
        />
      )}

      {ready && languages && languages.length > 0 && (
        <>
          {languages.map((lang) => {
            if (props.type === 'wysi') {
              return (
                <InputGroup
                  key={lang}
                  label={<LanguageIcon language={lang} showLabel height={16} />}
                  required={props.required}
                >
                  <ContentEditor
                    initValue={contentValue[lang]}
                    onChange={(value: string) =>
                      handleOnContentChange(value, 'default')
                    }
                  />
                </InputGroup>
              );
            } else {
              return (
                <Input
                  key={lang}
                  required={props.required}
                  className={props.className ?? ''}
                  name={lang}
                  label={<LanguageIcon language={lang} showLabel height={16} />}
                  placeholder={getLanguageTextDisplay(t, lang)}
                  onChange={(e) => handleOnContentChange(e.target.value, lang)}
                  value={contentValue[lang]}
                  maxLength={props.maxLength}
                  type="textarea"
                  row={props.row}
                />
              );
            }
          })}
        </>
      )}
    </>
  );
};
