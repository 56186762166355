import { FormikProvider, useFormik } from 'formik';
import { Button, notification } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from '@app/redux/slices/roles';
import styled from 'styled-components';
import { HintText } from '@components/text/HintText';
import { UserTeacherAPI } from '@modules/users/services/UserTeacherAPI';
import { IconUtils } from '@utils/IconUtils';
import { PermissionCheckBox } from '../permission/PermissionCheckBox';

export const PermissionTable = (props: {
  roles: RoleEnum;
  schoolId?: number;
  userId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getUserPermission, { data, isSuccess, isLoading }] =
    UserTeacherAPI.endpoints.getUserPermission.useLazyQuery({});

  const [updateUserPermission, { isLoading: isUpdateLoading }] =
    UserTeacherAPI.endpoints.updateUserPermission.useMutation({});

  useEffect(() => {
    getUserPermission({ userId: props.userId, schoolId: props.schoolId });
  }, [props.schoolId, props.userId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setFieldValue('permissions', data);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      permissions: [] as RoleEnum[],
    },

    // validationSchema: yup.object().shape({
    //   permissions: yup.array(),
    // }),

    onSubmit: (values) => {
      onUpdatePermission(values.permissions);
    },
  });

  const onUpdatePermission = (roles: RoleEnum[]) => {
    updateUserPermission({
      userId: props.userId,
      schoolId: props.schoolId,
      permissions: roles,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: t('teacher.warning.update_success'),
          description: t('teacher.warning.update_success_message'),
          placement: 'bottomRight',
        });
        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: t('teacher.warning.update_error'),
          description: t('teacher.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleChange = (value: RoleEnum, activated: boolean) => {
    if (activated) {
      formik.setFieldValue('permissions', [
        ...formik.values.permissions,
        value,
      ]);
    } else {
      formik.setFieldValue(
        'permissions',
        formik.values.permissions.filter((rl) => {
          return rl !== value;
        })
      );
    }
  };

  return (
    <PermissionTableStyle className={'permission-table-form'}>
      <FormikProvider value={formik}>
        <table
          className={'permission-table'}
          style={{ width: '100%', textAlign: 'center' }}
          border={1}
        >
          <thead>
            <tr>
              <td style={{ borderTop: 'none', borderLeft: 'none' }} />
              <th style={{ width: 180 }}>Support</th>
              <th style={{ width: 180 }}>Admin</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th colSpan={3} className={'group'}>
                MANAGER USER AND CLASS
              </th>
            </tr>

            <tr>
              <td>
                Manage Course
                <HintText>Allow user create and publish courses</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_COURSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Manage Class And Students
                <HintText>Allow user to manage classes and students</HintText>
              </td>
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_CLASS}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
              <td />
            </tr>

            <tr>
              <td>
                Create Calendar Event
                <HintText>Allow user to create Calendar events.</HintText>
              </td>
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_CALENDAR}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
              <td />
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER CMS (BOOK AND LESSON)
              </th>
            </tr>

            <tr>
              <td>
                Compose Lesson
                <HintText>
                  Allow user to compose lesson. User can access all lesson
                  created by him.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.COMPOSE_LESSON}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Create product
                <HintText>
                  Allow user to create product. User can access all products
                  created by him.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_PRODUCT}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Create Lesson Template
                <HintText>Allow user to create lesson template.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_LESSON_TEMPLATE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER LICENSE
              </th>
            </tr>

            <tr>
              <td>
                Assign Book License
                <HintText>
                  Allow user to assign completed product to teachers. User can
                  access all products and all lessons.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.BOOK_LICENSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Publish Books
                <HintText>
                  Allow user to publish products. User can access all products
                  and all lessons.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.PUBLISH_LICENSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER SCHOOL
              </th>
            </tr>

            <tr>
              <td>
                Design Page Content
                <HintText>Design landing page and other pages.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.DESIGN_PAGE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Publish Page Content
                <HintText>Publish Page content after completed..</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.PUBLISH_PAGE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Update School Setting
                <HintText>
                  Allow user to update school setting includes
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.UPDATE_SCHOOL_SETTING}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Manage Payment
                <HintText>Update Payment information.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.UPDATE_SCHOOL_SETTING}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER LTI
              </th>
            </tr>

            <tr>
              <td>
                Manage Oauth App
                <HintText>Manage Oauth App.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_OAUTH_APP}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="submit-container">
          <ButtonGroup>
            <Button
              size={'large'}
              loading={isLoading || isUpdateLoading}
              type={'primary'}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              {t('button.update')}
            </Button>

            <Button type="default" size={'large'} onClick={props.onCancel}>
              {t('button.back')}
            </Button>
          </ButtonGroup>
        </div>
      </FormikProvider>
    </PermissionTableStyle>
  );
};

export const PermissionTableStyle = styled.div`
  .direction-group {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;

    .direction-item {
      border: 1px solid #ccc;
      padding: 0.25em 1em 0.25em 0.5em;
      margin-left: 1em;
    }
  }

  table.permission-table {
    thead tr th,
    tbody tr th.group,
    tbody tr th.group-empty {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 13px;
    }

    thead tr th {
      text-align: center;
      background: ${(props) => props.theme.app.primary};
      color: #fff;
    }

    tr td,
    tr th {
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 8px 16px;

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: 1px solid #ccc;
      }
    }

    tr td:first-child {
      text-align: left;
    }

    tr th {
      text-align: left;
      background: ${(props) => props.theme.app.primary_bgr};
      color: #000;
    }
  }

  .ant-checkbox-group {
    display: block;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }
`;
