import React from 'react';

import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@hooks/useRouter';
import { PageHeader } from '@app/header/PageHeader';
import Breadcrumb from '@components/breadcrumb';
import { SiteMap } from '@router/SiteMap';
import ManageSchoolSupportPage from '@modules/users/pages/ManageSchoolSupportPage';

const SchoolSupportAdminManagement = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { schoolId } = router.query as unknown as { schoolId: string };

  return (
    <ManagementLayout className={'school-support-admin-page'}>
      <PageHeader
        menu={NavigationMenuEnum.USERS}
        subMenu={NavigationMenuEnum.USERS_SCHOOL_SUPPORT}
        title={t('school_admin.header')}
        description={t('school_admin.header')}
      />

      <Breadcrumb
        items={[
          { link: SiteMap.management.school.list, title: t('menu.school') },
          { title: t('school_admin.header') },
        ]}
      />
      <ManageSchoolSupportPage schoolId={Number(schoolId)} />
    </ManagementLayout>
  );
};

export default SchoolSupportAdminManagement;
