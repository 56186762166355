import React from 'react';

import MyAccountPage from '@modules/setting/pages/MyAccountPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const MyAccount = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-account-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_ACCOUNT}
        subMenu={NavigationMenuEnum.MY_ACCOUNT_INFO}
        title={t('profile.header')}
        description={t('profile.header')}
      />
      <MyAccountPage />
    </ManagementLayout>
  );
};

export default MyAccount;
