import { useTranslation } from 'react-i18next';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Row, Select } from 'antd';
import Input from '@components/input/input';
import { SearchOutlined } from '@ant-design/icons';
import SelectProduct from '@modules/common/components/SelectProduct';
import { InputGroup } from '@components/input/InputGroup';
import { EntityStatusEnum } from '@services/model/common';
import { ComposingStatus } from './ComposingStatus';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';

export const LessonFilterForm = (props: {
  span?: number;
  loading?: boolean;
  productId: number;
  keyword: string;
  status: EntityStatusEnum;
  onSubmit: (
    productId: number,
    keyword: string,
    status: EntityStatusEnum
  ) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      productId: props.productId,
      keyword: props.keyword,
      status: props.status,
    },

    onSubmit: (values) => {
      props.onSubmit(values.productId, values.keyword, values.status);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <SelectProduct
            formik={formik}
            name={'productId'}
            value={formik.values.productId}
            onChange={(val) => formik.setFieldValue('productId', val)}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <Input
            name="keyword"
            type="text"
            label={t('label.keyword')}
            placeholder={t('label.enter_keyword')}
            onChange={formik.handleChange}
            value={formik.values.keyword}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <InputGroup label={t('lesson.status.status')}>
            <Select
              size={'large'}
              placeholder={t('lesson.status.select_status')}
              onChange={(value) => formik.setFieldValue('status', value)}
              value={formik.values.status}
              style={{ width: '100%' }}
            >
              <Select.Option
                key={EntityStatusEnum.EMPTY}
                value={EntityStatusEnum.EMPTY}
              >
                {t('lesson.status.all')}
              </Select.Option>

              <Select.Option
                key={EntityStatusEnum.ACTIVE}
                value={EntityStatusEnum.ACTIVE}
              >
                <ComposingStatus status={EntityStatusEnum.ACTIVE} />
              </Select.Option>

              <Select.Option
                key={EntityStatusEnum.PUBLISHED}
                value={EntityStatusEnum.PUBLISHED}
              >
                <ComposingStatus status={EntityStatusEnum.PUBLISHED} />
              </Select.Option>

              <Select.Option
                key={EntityStatusEnum.REMOVED}
                value={EntityStatusEnum.REMOVED}
              >
                <ComposingStatus status={EntityStatusEnum.REMOVED} />
              </Select.Option>
            </Select>
          </InputGroup>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <InputGroup
            label={
              props.span != null && props.span === 12 ? <>&nbsp;</> : undefined
            }
          >
            <Button
              type={'primary'}
              loading={props.loading}
              onClick={formik.submitForm}
              icon={IconUtils.actions.search}
            >
              <SearchOutlined /> {t('button.filter')}
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};

export default LessonFilterForm;
