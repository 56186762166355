import React from 'react';
import { LessonTypeEnum } from '@modules/admin/service/model';
import styled from 'styled-components';

export const LessonTypeInfo = (props: {
  type?: LessonTypeEnum;
  showLabel: boolean;
}) => {
  if (props.type) {
    return (
      <LessonTypeInfoStyle className={'lesson-type-name'}>
        {props.type}
      </LessonTypeInfoStyle>
    );
  } else {
    return null;
  }
};

const LessonTypeInfoStyle = styled.span`
  text-transform: uppercase;
  min-width: 120px;
  text-align: center;
`;
