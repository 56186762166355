import React, { ReactNode, useEffect } from 'react';
import { Select, SelectProps } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import useFilterOptions from '@hooks/useFilterOptions';
import { compareKeywords } from '@utils/common';
import { OptionWrapper } from '@modules/common/components/style';
import { ProductRes } from '@modules/product/services/product_model';

interface SelectProductProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  name: string;
  value?: number;
  onChange?: (value: number) => void;
  onProductChange?: (value: number, product: ProductRes) => void;
}

const SelectProduct = (props: SelectProductProps) => {
  const { t } = useTranslation();

  const [getMyProducts, { data, isFetching }] =
    ProductAPI.endpoints.getMyProducts.useLazyQuery({});

  const filter = useFilterOptions<ProductRes>(
    data ? data.content : [],
    (opt, keyword) => {
      const keywords =
        keyword != null && keyword.trim() !== ''
          ? keyword.toLowerCase().trim().split(' ')
          : null;

      return (
        keywords == null ||
        compareKeywords(opt.name, keywords) ||
        compareKeywords(opt.keyword, keywords) ||
        compareKeywords(opt.description, keywords)
      );
    }
  );

  useEffect(() => {
    getMyProducts({
      page: 1,
      size: MAX_PAGE_SIZE,
      sort: 'name',
      direction: 'descend',
      selectAll: true,
    });
  }, []);

  const handleOnInputChange = (value: number) => {
    if (props.onChange) {
      props.onChange(value);
    } else if (props.onProductChange) {
      const products = filter.options.filter((prd) => {
        return prd.productId === value;
      });
      props.onProductChange(value, products[0]);
    } else if (props.formik) {
      props.formik.setFieldValue(props.name, value);
    }
  };

  return (
    <InputGroup label={props.label ?? t('product.actions.select')}>
      <Select
        showSearch
        style={{ width: '100%' }}
        size={'large'}
        placeholder={props.placeholder}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value ?? props.formik.values[props.name]}
        loading={isFetching}
        searchValue={filter.keyword}
        onSearch={filter.onSearch}
        filterOption={false}
      >
        <Select.Option key={-1} value={-1}>
          <OptionWrapper>
            <ThumbnailsImg
              type={'icon'}
              src={''}
              ratio={ImageScale.lesson.ratio}
              width={ImageScale.product.icon}
            />
            <span>{t('product.actions.select')}</span>
          </OptionWrapper>
        </Select.Option>

        {filter.options.map((pd) => {
          return (
            <Select.Option key={pd.productId} value={pd.productId}>
              <OptionWrapper>
                <ThumbnailsImg
                  type={'icon'}
                  src={pd.icon}
                  ratio={ImageScale.lesson.ratio}
                  width={ImageScale.product.icon}
                />
                <span>{pd.name}</span>
              </OptionWrapper>
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};

export default SelectProduct;
