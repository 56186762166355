export const xAxisSetting = (xAxisTitle: string): any => {
  return {
    tickSize: 12,
    tickPadding: 5,
    tickRotation: 0,
    legend: xAxisTitle,
    legendPosition: 'middle',
    legendOffset: 32,
  };
};

export const yAxisSetting = (
  yAxisTitle: string,
  yAxisValues: number[]
): any => {
  return {
    tickSize: 12,
    tickPadding: 5,
    tickRotation: 0,
    legend: yAxisTitle,
    legendPosition: 'middle',
    legendOffset: -45,
    tickValues: yAxisValues,
  };
};
