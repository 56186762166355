import React from 'react';

import { Col, Row } from 'antd';
import { MyInvitations } from '@modules/dashboard/students/MyInvitations';
import InComingStudentEvent from '@modules/dashboard/components/IncomingStudentEvents';
import CalendarThumbnails from '@modules/calendar/container/CalendarThumbnails';
import { CalendarContextProvider } from '@modules/calendar/context/CalendarContext';
import { MyStudentAssignment } from '../components/MyStudentAssignment';
import { Default_Gutter } from '@components/grid';

const MyStudentDashboardPage = () => {
  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <MyInvitations />

          <MyStudentAssignment />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <CalendarContextProvider>
            <CalendarThumbnails />

            <InComingStudentEvent />
          </CalendarContextProvider>
        </Col>
      </Row>
    </>
  );
};

export default MyStudentDashboardPage;
