import React from 'react';
import Input from '@components/input/input';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';

export const SpaceSetting = (props: { formik: any }) => {
  const { t } = useTranslation();

  return (
    <div className={'template-setting-group font-size-group'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.option"
            type="text"
            label={t('lesson_template.config.option_margin')}
            placeholder={t('lesson_template.config.option_margin')}
            value={props.formik.values.spacingSetting.option}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.component"
            type="text"
            label={t('lesson_template.config.component_margin')}
            placeholder={t('lesson_template.config.component_margin')}
            value={props.formik.values.spacingSetting.component}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.question"
            type="text"
            label={t('lesson_template.config.question_margin')}
            placeholder={t('lesson_template.config.question_margin')}
            value={props.formik.values.spacingSetting.question}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.section"
            type="text"
            label={t('lesson_template.config.section_margin')}
            placeholder={t('lesson_template.config.section_margin')}
            value={props.formik.values.spacingSetting.section}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.content"
            type="text"
            label={t('lesson_template.config.content_padding')}
            placeholder={t('lesson_template.config.content_padding')}
            value={props.formik.values.spacingSetting.content}
            onChange={props.formik.handleChange}
          />
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Input
            name="spacingSetting.page"
            type="text"
            label={t('lesson_template.config.page_padding')}
            placeholder={t('lesson_template.config.page_padding')}
            value={props.formik.values.spacingSetting.page}
            onChange={props.formik.handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};
