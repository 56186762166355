import React, { useState } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from '@components/button';
import useModal from '@hooks/useModal';
import CustomModal from '@components/modal/CustomModal';
import AssetsLibraryFilter from '../container/assets-files/AssetsFileFilter';
import AddStaticResource, {
  AssetsTypeEnum,
} from '@modules/product/components/lesson/AddStaticResource';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';

const MyAssetsFilePage = () => {
  const { t } = useTranslation();

  const [type, setType] = useState<AssetsTypeEnum>(AssetsTypeEnum.IMAGE);

  const showUploadModal = useModal();
  const [timestamp, setTimestamp] = useState(DateAndTimeUtils.getCurrentTime());

  const showUploadAssetsdModal = () => {
    showUploadModal.show();
  };

  const hideUploadAssetsModal = () => {
    showUploadModal.hide();
  };

  const refreshPage = () => {
    showUploadModal.hide();
    setTimestamp(DateAndTimeUtils.getCurrentTime());
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('assets.header')}
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={showUploadAssetsdModal}
          />
        </H1>
      </HeaderGroup>

      <AssetsLibraryFilter
        key={timestamp}
        mode={'edit'}
        onCancel={hideUploadAssetsModal}
        onTypeChange={(_tp) => setType(_tp)}
      />

      {showUploadModal.visible && (
        <CustomModal
          header={t('assets.actions.upload_file', { fileType: type })}
          className={'large-modal'}
          content={
            <AddStaticResource fileType={type} onUploadFile={refreshPage}>
              <p className="ant-upload-text">
                {t('assets.warning.select_file')}
              </p>
            </AddStaticResource>
          }
          onCloseFunc={hideUploadAssetsModal}
        />
      )}
    </>
  );
};

export default MyAssetsFilePage;
