import { DoubleRightOutlined } from '@ant-design/icons';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useTranslation } from 'react-i18next';

import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { ButtonProps } from 'antd';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';

export const NextSectionButton = ({ onClick, ...props }: ButtonProps) => {
  const { t } = useTranslation();
  const { triggerBeforeNavigate } = useViewLessonContext();
  const { section, updateNavigation } = useLessonNavigationContext();

  const { config } = useLessonTemplateContext();

  const handleNextSection = (e: any) => {
    triggerBeforeNavigate();

    updateNavigation(LessonNavigationAction.NEXT_SECTION, {
      sectionId: section.nextSectionId,
      type: LessonNavigationType.first,
    });

    if (onClick) {
      onClick(e);
    }
  };

  if (config.layout === LessonTemplateLayoutEnum.presentation) {
    return (
      <LessonButton
        shape={'circle'}
        size={'large'}
        className={'next-section-button'}
        onClick={handleNextSection}
        disabled={section.nextSectionId < 0}
        icon={<DoubleRightOutlined />}
        {...props}
      />
    );
  } else {
    return (
      <LessonButton
        className={'next-section-button'}
        shape={'round'}
        type={'primary'}
        onClick={handleNextSection}
        disabled={section.nextSectionId < 0}
        {...props}
      >
        {t('exercise.button.next')} <DoubleRightOutlined />
      </LessonButton>
    );
  }
};
