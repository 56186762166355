import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateUserReq,
  DisabledUserReq,
} from '@modules/users/services/StudentAPI';
import { RoleEnum } from '@app/redux/slices/roles';
import {
  GetUserPermissionReq,
  ResetPasswordUserReq,
  UpdatePermissionReq,
  UserRes,
} from '@modules/users/services/model';

export const UserTeacherAPI = createApi({
  reducerPath: 'UserTeacherAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    resetPassword: builder.mutation<BasicMessageRes, ResetPasswordUserReq>({
      query: (body) => ({
        url: '/users/reset-password',
        method: 'PUT',
        body,
      }),
    }),

    findAll: builder.query<PaginationRes<UserRes>, PaginationReq>({
      query: (params) => ({
        url: `/users/teachers`,
        method: 'GET',
        params,
      }),
    }),

    getUserData: builder.query<UserRes, number>({
      query: (userId: number) => ({
        url: `/users/teachers/${userId}`,
        method: 'GET',
      }),
    }),

    createUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/teachers',
        method: 'POST',
        body,
      }),
    }),

    updateUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: `/users/teachers/${body.userId}`,
        method: 'PUT',
        body,
      }),
    }),

    disableUser: builder.mutation<BasicMessageRes, DisabledUserReq>({
      query: (body) => ({
        url: `/users/teachers/${body.userId}/activated`,
        method: 'PUT',
        body,
      }),
    }),

    // update permission (for admin, school admin)
    getUserPermission: builder.query<RoleEnum[], GetUserPermissionReq>({
      query: (params) => ({
        url: `/users/teachers/${params.userId}/permissions`,
        method: 'GET',
        params,
      }),
    }),

    updateUserPermission: builder.mutation<
      BasicMessageRes,
      UpdatePermissionReq
    >({
      query: (body) => ({
        url: `/users/teachers/${body.userId}/permissions`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
