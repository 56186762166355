import OptionEditable from '@cms/editable/OptionEditable';
import {
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { CompProps } from '@cms/ComponentInteface';
import produce from 'immer';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import {
  AudioOptionProps,
  AudioRecordingProps,
} from '@cms/comps/recording/AudioRecordingComp';
import styled from 'styled-components';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { updateComponent } from '@app/redux/slices/composeResource';

export const AudioRecordingCompEditable = (props: {
  item: AudioRecordingProps;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState<AudioOptionProps[]>(
    props.item.configuration.sourceItems
  );

  useEffect(() => {
    setSourceItems(props.item.configuration.sourceItems);
  }, [props.item]);

  const updateDataForOption = (
    data: AssetsContentProps,
    index: number,
    option: AudioOptionProps
  ) => {
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(sourceItems, index, {
      ...option,
      content: { ...data },
    });
    updateOptions(newSourceItems);
  };

  const handleOnChangeType = (
    type: AssetsTypeEnum,
    index: number,
    option: AudioOptionProps
  ) => {
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(sourceItems, index, {
      ...option,
      content: { ...option.content, type: type },
    });
    updateOptions(newSourceItems);
  };

  const handleOnClickOperation = (
    operation: ContentOperationEnum,
    index: number,
    option: AudioOptionProps
  ) => {
    let newSourceItems: AudioOptionProps[] = [];

    if (operation === ContentOperationEnum.ADD) {
      const cloneOption = {
        ...option,
        content: { ...option.content, id: COMPONENT_UTILS.generateUID() },
      };
      newSourceItems = COMPONENT_UTILS.insertAtIndex(
        sourceItems,
        index,
        cloneOption
      );
    } else {
      newSourceItems = COMPONENT_UTILS.removeAtIndex(sourceItems, index);
    }

    updateOptions(newSourceItems);
  };

  const updateOptions = (sourceItems: AudioOptionProps[]) => {
    const newSourceItems = sourceItems.map((opt, index) => {
      return { ...opt, label: COMPONENT_UTILS.getABC(index) };
    });
    setSourceItems(newSourceItems);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const handleUpdateComponentData = () => {
    onUpdateComponent();
    props.onClose();
  };

  const onUpdateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
      };
    });
    dispatch(updateComponent(newComps));
  };

  return (
    <>
      <AudioRecordingCompEditableStyle className={`audio-recording-component`}>
        {sourceItems &&
          sourceItems.map((option, index) => {
            return (
              <OptionEditable
                key={JSON.stringify(option) + '_' + index + '_' + modifiedTime}
                type={option.content.type}
                onChangeType={(newType) =>
                  handleOnChangeType(newType, index, option)
                }
                onOperation={(operation) =>
                  handleOnClickOperation(operation, index, option)
                }
              >
                <ContentViewerEditable
                  contentData={option.content}
                  onChange={(data) => updateDataForOption(data, index, option)}
                />
              </OptionEditable>
            );
          })}
      </AudioRecordingCompEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={handleUpdateComponentData}
      />
      <AutoUpdateToolbar
        lastChange={modifiedTime}
        delay={1.5}
        onTimeout={onUpdateComponent}
      />
    </>
  );
};

const AudioRecordingCompEditableStyle = styled.div`
  .cms-content-actions {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
`;
