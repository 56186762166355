import styled from 'styled-components';

export const CalendarStyle = styled.div`
  --fc-now-indicator-color: ${(props) => props.theme.event.primary};
  --fc-border-color: ${(props) => props.theme.color.grey};
  --fc-page-bg-color: transparent;
  --fc-event-text-color: ${(props) => props.theme.color.black};
  --fc-event-bg-color: ${(props) => props.theme.event.default};
  --fc-event-border-color: ${(props) => props.theme.event.primary};

  position: relative;

  .fc-daygrid-event {
    border: 1px solid ${(props) => props.theme.event.primary};
    background: ${(props) => props.theme.event.default};
  }

  .calendar-view {
    position: absolute;
    right: 0px;
    top: 18px;
    transform: translate(0, -50%);
  }

  // for day view
  .fc-view.fc-timeGridDay-view {
    .fc-col-header-cell-cushion {
      width: 100%;
      display: flex;

      .custom-day-header {
        flex-grow: 1;
        display: flex;
        justify-content: center;
      }
    }
  }

  // for month view
  .fc-view.fc-dayGridMonth-view {
    .fc-daygrid-day-frame {
      aspect-ratio: 1 / 1;
    }
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    height: 100%;
    position: relative;

    .fc-timegrid-slot-label-cushion {
      position: absolute;
      top: 0px;
      right: 0px;
      transform: translate(0%, -50%);
    }
  }

  .fc-timegrid-body .fc-timegrid-slots > table > tbody > tr:first-child {
    .custom-slot-label:first-child {
      z-index: 999;
    }
  }

  //CUSTOM STYLE FOR HEADER
  .custom-slot-label {
    font-weight: bold;
    color: #333;
  }

  .fc-event-past {
    filter: grayscale(1);
  }

  .custom-day-header {
    .day-header-group {
      display: flex;
      flex-direction: column;

      .day-header-number,
      .day-header-label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .day-header-number {
      font-weight: 600;
      width: 2.25em;
      height: 2.25em;
      border-radius: 100%;
    }

    .day-header-label {
      font-size: 85%;
      color: #ccc;
      font-weight: 500;
      margin-top: 0.25em;

      &:first-child {
        color: #333;
        font-weight: 600;
        font-size: 100%;
        margin-top: 0;
      }
    }

    .is-past .day-header-number {
      background-color: #ccc;
      color: #333;
    }

    .is-today .day-header-number {
      background-color: ${(props) => props.theme.event.primary};
      color: ${(props) => props.theme.color.white};
    }

    .is-future .day-header-number {
      background-color: ${(props) => props.theme.event.future};
      color: ${(props) => props.theme.color.white};
    }
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
    display: flex;

    .fc-toolbar-chunk {
      margin-right: 1em;
      &:last-child {
        flex-grow: 1;
      }
    }

    .fc-button.fc-today-button {
      background-color: ${(props) => props.theme.app.primary};
      border-color: ${(props) => props.theme.app.primary};
    }

    .fc-button-group .fc-button {
      border-radius: 100%;
      width: 2.5em;
      margin-left: 0.5em;
      background-color: ${(props) => props.theme.app.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 2.5em;
    }
  }

  .fc-timegrid-slot.fc-timegrid-slot-lane {
    background: #fff;
  }

  .fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor {
    border-top: none !important;
  }
  //
  //.fc-scrollgrid-section.fc-scrollgrid-section-body .fc-scroller-harness {
  //  background: #fff;
  //}

  .fc-dayGridMonth-view {
    .fc-daygrid-body {
      border: 1px solid #ccc !important;
    }
    .fc-col-header-cell-cushion {
      .day-header-label {
        color: #ccc;
      }
    }
  }

  .fc-view-harness {
    box-shadow: none;

    /* ---- custom header style ----- */
    .fc-scrollgrid {
      border: none;

      .fc-scrollgrid-section {
        th {
          //border-top: none;
          //border-bottom: none;
          //border-left: none;
          border: none;
        }

        .fc-timegrid-axis {
          //border-top: none;
          //border-bottom: none;
          //border-left: none;
          border: none;
        }

        .fc-timegrid-slot-label {
          border: none;
        }
      }
    }

    .fc-scrollgrid-sync-inner {
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  .fc-scrollgrid .fc-scrollgrid-section > td[role='presentation'] {
    border: none;
  }

  .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
    border-width: 0px;
    margin-top: 0px;
    left: auto;
    right: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.event.primary};
    transform: translate(0%, -50%);
  }

  .fc-timegrid-now-indicator-line {
    border-width: 2px 0 0;
    border-style: dotted;
  }

  /* ---- custom events style ----- */

  // for week and day view

  .fc-timegrid.fc-timeGridWeek-view,
  .fc-timegrid.fc-timeGridDay-view {
    .fc-timegrid-slot-lane {
      height: 40px;
      border-style: dashed;
      border-left: none;
    }
  }

  .fc-timegrid.fc-timeGridDay-view {
    .fc-timegrid-event-harness > .fc-timegrid-event {
      left: 30px;
      color: #000;

      .fc-event-main-frame {
        .fc-event-title-container {
          font-size: 18px;
          display: flex;
          align-items: center;
          font-weight: bold;
          padding-left: 10px;
        }
      }
    }
  }

  .fc-day.fc-daygrid-day {
    .fc-daygrid-day-number {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 75%;
      font-weight: 800;
      width: 1.8em;
      height: 1.8em;
      border-radius: 100%;
      margin: 4px;
    }

    &.fc-day-other.fc-day-past {
      opacity: 0.3;
    }

    &.fc-day-other.fc-day-future .fc-daygrid-day-top {
      opacity: 0.6;
    }

    &.fc-day-past {
      .fc-daygrid-day-number {
        background-color: #ccc;
        color: #333;
      }
    }

    &.fc-day-today {
      .fc-daygrid-day-number {
        background-color: ${(props) => props.theme.event.primary};
        color: #fff;
      }
    }

    &.fc-day-future {
      .fc-daygrid-day-number {
        background-color: ${(props) => props.theme.event.future};
        color: #fff;
      }
    }
  }

  .fc-timegrid-event .fc-event-time {
    display: none;
  }

  .fc-day.fc-timegrid-col {
    .fc-non-business {
      background-color: transparent;
    }
  }

  .custom-event {
    display: flex;
    align-items: center;
    height: 100%;

    svg {
      margin-right: 0.25em;
      min-width: 10px;
      min-height: 10px;
    }
  }

  .fc-dayGridMonth-view .custom-event {
    padding-left: 10px;
  }

  .fc-timeGridWeek-view .custom-event {
    padding-left: 16px;
    font-size: 16px;
  }

  .fc-timeGridDay-view {
    .custom-event {
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  /* ---------------- custom style grid -------------------- */
`;
