import {
  CompProps,
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';
import ComponentUtils from '@cms/script/ComponentUtils';
import { CompGroupEnum } from '@cms/utils/CompProps';
import MatchingDragNDrops from '@cms/comps/interact/matching/MatchingCompDragNDrop';
import MatchingClickComps from '@cms/comps/interact/matching/MatchingCompClick';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { MatchingContextProvider } from './MatchingCompContext';
import { MatchingComponentBackground } from '@cms/comps/interact/matching/MatchingCompBackground';

export interface MatchingItemCompProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface MatchingSettingProps extends CompInteractSettingProps {
  layout: 'one-to-one' | 'one-to-many' | 'many-to-many';
  eventType: 'drag' | 'click';
}

export interface MatchingCompProps extends CompProps {
  type: CompTypeEnum.MATCHING;
  configuration: {
    sourceItems: MatchingItemCompProps[];
    targetItems: MatchingItemCompProps[];
  };
  setting: MatchingSettingProps;
}

export interface MatchingCompAnsProps extends CompAnswerProps {
  answer: string[];
}

export const generateMatchingItemUId = (
  compId: string,
  type: CompGroupEnum,
  label: string
) => {
  return `${compId}_${type}_${label}`;
};

export const matchingColors = ComponentUtils.getColors();

export const generatorMatchingAns = (source: string, target: string) => {
  return `${source}:${target}`;
};

export const getMatchingAns = (answerPart: string) => {
  return {
    source: answerPart.split(':')[0],
    target: answerPart.split(':')[1],
  };
};

export function MatchingComp(props: {
  disabled: boolean;
  item: MatchingCompProps;
  answer: MatchingCompAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: MatchingCompAnsProps) => void;
}) {
  return (
    <MatchingContextProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <MatchingCompStyle
        className={`comps-matching ${props.item.setting?.className}`}
      >
        <MatchingComponentBackground />

        {props.item.setting.eventType === 'click' ? (
          <MatchingClickComps />
        ) : (
          <MatchingDragNDrops />
        )}

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </MatchingCompStyle>
    </MatchingContextProvider>
  );
}

const MatchingCompStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }

  .comps-matching-bgr {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .matching-panel {
    width: 40%;
    max-width: 40%;
    position: relative;

    .matching-option {
      //display: flex;
      //align-items: baseline;
      width: 100%;
      position: relative;

      &:not(:first-child) {
        margin-top: var(--cms-padding-option, 0.5em);
      }

      > .anticon {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translate(-100%, -50%);
        opacity: 0.5;
      }

      &:hover {
        > .anticon {
          opacity: 1;
          color: #11009e;
        }
      }

      &.drop-item {
        .matching-option-item {
          width: 100%;
        }

        &.dragging {
          .matching-option-item {
            background: ${(props) => props.theme.component.primary_bgr};
            border: 2px solid ${(props) => props.theme.component.primary};
          }
        }
      }

      .cms-content-actions {
        flex-grow: 1;
      }
    }

    .matching-option.disabled-option {
      .matching-option-item {
        background: ${(props) => props.theme.component.secondary_bgr};
      }
    }

    .drag-item.disabled-item,
    .drop-item.disabled-item {
      .matching-option-item {
        background: ${(props) => props.theme.component.secondary_bgr};
      }
    }

    .droppable-container {
      .droppable-placeholder {
        display: none;
      }

      .draggable-item {
        width: 100%;

        .matching-option-item {
          width: 100%;
        }
      }
    }
  }
`;
