import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import {AudioRecordingProps} from "@cms/comps/recording/AudioRecordingComp";

export const AudioRecordingExampleData: AudioRecordingProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.AUDIO_RECORDING,

  configuration: {
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Click the icon to record __________.',
        },
      }
    ],
  },
  setting: {
    autoScore: false,
  },
};
