import React from 'react';

import { RoleEnum } from '@app/redux/slices/roles';
import LessonTypeManagementPage from '@modules/admin/pages/LessonTypeManagementPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ManageLessonType = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-lesson-type-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON_TYPE}
        title={t('lesson_type.header')}
        description={t('lesson_type.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <LessonTypeManagementPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default ManageLessonType;
