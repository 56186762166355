import React from 'react';
import { LessonResourceBanks } from './LessonResourceBanks';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import ComposeResourceTemplate from '@cms/lesson-template/compose/resource/ComposeResourceTemplate';
import { ComponentLibrary } from '@cms/comps-bank/ComponentLibrary';
import { CompProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { addComponents } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import ComposeResourceToolbar from '@cms/lesson-template/compose/resource/ComposeResourceToolbar';

import {
  LessonTemplateLayoutProvider,
  LessonTemplateWrapper,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import {
  ComposeContentLayout,
  ComposeFooterLayout,
  ComposeLayout,
  LessonBankLayout,
  max_bank_size,
} from '../layout';
import { H4 } from '@components/typography';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { ResourceRelativeData } from '@cms/lesson-template/compose/resource/ResourceRelativeData';
import { ViewExerciseWrapper } from '@cms/lesson-template/exercise/ViewExerciseWrapper';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ComposeMultipleQuestionTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const service = useLessonServiceContext();

  const { lesson, editMode, mode, changeToMode, dispatchAction } =
    useLessonEditableContext();

  const handleOnSelectComponents = (components: CompProps[]) => {
    const newComps = components.map((compData) => {
      return COMPONENT_UTILS.generateComponent(compData);
    });
    dispatch(addComponents(newComps));
    dispatchAction(LessonEditableActionEnum.add_component, -1);
  };

  const handleOnClose = () => {
    changeToMode(LessonMode.view);
  };

  return (
    <>
      <LessonBankLayout className={'lesson-sections'}>
        {lesson && (
          <div className={'component-bank'}>
            <LessonResourceBanks lesson={lesson} />
          </div>
        )}
      </LessonBankLayout>

      <ComposeLayout className={'show-correct-answer compose-multiple-page'}>
        <ComposeContentLayout className={'compose-content-layout'}>
          <LessonTemplateLayoutProvider lesson={lesson}>
            <ViewExerciseWrapper
              className={'exercise-content-display'}
              ratio={{ width: 16, height: 9 }}
              minFontSize={12}
            >
              <LessonTemplateWrapper>
                <ComposeResourceTemplate
                  key={service.resourceId}
                  resourceId={service.resourceId}
                />
              </LessonTemplateWrapper>
            </ViewExerciseWrapper>
          </LessonTemplateLayoutProvider>

          {editMode !== EditModeEnum.WYSI && <ResourceRelativeData />}
        </ComposeContentLayout>

        <ComposeFooterLayout className={'compose-footer-layout'}>
          <ComposeResourceToolbar type={lesson?.type} />
        </ComposeFooterLayout>
      </ComposeLayout>

      <Drawer
        className={'compose-lesson-bank multiple-question-bank'}
        width={max_bank_size}
        title={
          <H4 style={{ marginBottom: 0 }}>
            {t('my_lesson.actions.select_a_content')}
          </H4>
        }
        placement="left"
        open={mode === 'edit'}
        closable={false}
        destroyOnClose={false}
      >
        <ComponentLibrary
          type={lesson ? lesson.type : LessonTypeEnum.lesson}
          onSelect={handleOnSelectComponents}
        />

        <ButtonGroup type={'center'}>
          <Button shape={'round'} danger onClick={handleOnClose}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </Drawer>
    </>
  );
};
