import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { WordSearchCompProps } from '@cms/comps/game/word-search/WordSearchComp';

export const WordSearchSampleData: WordSearchCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.WORD_SEARCH,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),

        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana Thumbnail',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry Thumbnails',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'middle',

    maxRows: 10,
    maxColumns: 10,

    horizontal: true,
    vertical: true,
    diagonal: true,
  },
};
