import { Col, List, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserRes } from '@modules/users/services/model';
import { useTranslation } from 'react-i18next';
import { H4 } from '@components/typography';
import StudentListItem, {
  SelectStudentActionEnum,
} from '@modules/users/component/StudentListItem';
import { UserParentAPI } from '@modules/users/services/UserParentAPI';
import useConfirmModal from '@components/modal/ConfirmModal';
import LinkToStudent from '../../../authorization/components/LinkToStudentField';
import { Default_Gutter } from '@components/grid';
import { LinkToStudentReq } from '@modules/authorization/services/model';

const LinkToStudentParentForm = (props: {
  parentId?: number;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [getParentStudents, { data, isSuccess }] =
    UserParentAPI.endpoints.getParentStudents.useLazyQuery({});

  const [linkToStudentForParent] =
    UserParentAPI.endpoints.linkToStudent.useMutation({});

  const [linkToStudentForTeacher] =
    UserParentAPI.endpoints.linkToStudentForTeacher.useMutation({});

  const [students, setStudents] = useState<UserRes[]>([]);

  useEffect(() => {
    if (props.parentId && props.parentId > 0) {
      getParentStudents(props.parentId);
    }
  }, [props.parentId]);

  useEffect(() => {
    if (data && isSuccess) {
      setStudents(data);
    }
  }, [data, isSuccess]);

  const onLinkToStudent = (userName: string, password: string) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('parent.warning.confirm_link_to_students'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          if (props.parentId != null && props.parentId > 0) {
            const request: LinkToStudentReq = {
              username: userName,
              password: password,
              parentId: props.parentId,
            };

            linkToStudentForTeacher(request)
              .unwrap()
              .then((res) => {
                setStudents((prev) => {
                  const reduceSt = [...prev].filter((st) => {
                    return st.userId !== res.userId;
                  });
                  return [...reduceSt, res];
                });
              })
              .catch(() => {
                notification.error({
                  message: t('parent.warning.link_error'),
                  description: t('parent.warning.link_error_message'),
                  placement: 'bottomRight',
                });
              });
          } else {
            const request: LinkToStudentReq = {
              username: userName,
              password: password,
            };

            linkToStudentForParent(request)
              .unwrap()
              .then((res) => {
                setStudents((prev) => {
                  const reduceSt = [...prev].filter((st) => {
                    return st.userId !== res.userId;
                  });
                  return [...reduceSt, res];
                });
              })
              .catch(() => {
                notification.error({
                  message: t('parent.warning.link_error'),
                  description: t('parent.warning.link_error_message'),
                  placement: 'bottomRight',
                });
              });
          }
        }
      }
    );
  };

  const onRemoveStudent = (userId: number) => {
    setStudents((prev) => {
      return [...prev].filter((stu) => {
        return stu.userId !== userId;
      });
    });
  };

  return (
    <div className={'link-to-student'} style={{ marginTop: '2em' }}>
      <H4>{t('label.students')}</H4>

      {students.length > 0 && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <List
              style={{ width: '100%', marginBottom: '1em' }}
              bordered
              dataSource={students}
              renderItem={(item) => (
                <StudentListItem
                  item={item}
                  type={SelectStudentActionEnum.remove}
                  onRemove={onRemoveStudent}
                />
              )}
            />
          </Col>
        </Row>
      )}

      <H4>{t('parent.actions.link_to_student')}</H4>
      <LinkToStudent type={'page'} onSubmit={onLinkToStudent} />
    </div>
  );
};

export default LinkToStudentParentForm;
