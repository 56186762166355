import { LessonAPI } from '@modules/product/services/LessonAPI';
import React, { useCallback, useEffect, useState } from 'react';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import styled from 'styled-components';
import Button from '@components/button';
import { notification, Space } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/tooltip/Tooltip';
import { PublishLessonAPI } from '@services/publish/PublishLessonAPI';
import useConfirmModal from '@components/modal/ConfirmModal';
import { ManageCollectionActionEnum } from './actions';
import { EntityStatusEnum, getEntityStatusClass } from '@services/model/common';
import { H5 } from '@components/typography';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonTypeEnum } from '@modules/admin/service/model';
import {
  FilterLessonReq,
  LessonRes,
} from '@modules/product/services/lesson_model';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';

const max_page_size = 10;

export const CollectionManagement = (props: {
  action: {
    action: ManageCollectionActionEnum;
    params: number;
  };
  onActionChange: (action: ManageCollectionActionEnum, params: number) => void;
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [getMyLessons, { data }] =
    LessonAPI.endpoints.getMyLessons.useLazyQuery({});

  const [createExercise] = DoExerciseAPI.endpoints.createExercise.useMutation(
    {}
  );
  const [publishItem] = PublishLessonAPI.endpoints.publishItem.useMutation({});
  const [deleteLesson] = LessonAPI.endpoints.deleteLesson.useMutation();

  const [params, setParams] = useState<FilterLessonReq>({
    page: 1,
    size: max_page_size,
    sort: 'createdDate',
    direction: 'descend',
    productId: -1,
    keyword: '',
    types: [LessonTypeEnum.lesson, LessonTypeEnum.collection],
  });

  useEffect(() => {
    refreshPage();
  }, [params]);

  useEffect(() => {
    if (props.action.action === ManageCollectionActionEnum.refresh) {
      refreshPage();
    }
  }, [props.action]);

  const refreshPage = useCallback(() => {
    getMyLessons(params);
  }, [params]);

  const onStartExercise = (lessonId: number) => {
    const request = {
      lessonId: lessonId,
      productId: -1,
      productTocId: -1,
      getLatest: true,
    };

    createExercise(request)
      .unwrap()
      .then((exercise) => {
        router.push(
          SiteMap.private.my_lesson.practice_gen(exercise.exerciseId)
        );
      })
      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotUpdate'),
          placement: 'bottomRight',
        });
      });
  };

  const onCompose = (lessonId: number) => {
    router.push(SiteMap.private.my_lesson.compose_gen(lessonId, 'simple'));
  };

  const onEdit = (lessonId: number) => {
    props.onActionChange(
      ManageCollectionActionEnum.update_collection,
      lessonId
    );
  };

  const onRemove = (lessonId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('lesson.warning.confirm_remove'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteLesson(lessonId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('lesson.warning.success'),
                description: t('lesson.warning.remove_success'),
                placement: 'bottomRight',
              });
              props.onActionChange(ManageCollectionActionEnum.refresh, -1);
            })
            .catch(() => {
              notification.error({
                message: t('lesson.warning.error'),
                description: t('lesson.warning.remove_error'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onPublish = (lessonId: number) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('my_lesson.warning.publish_confirm'),
      <>
        {IconUtils.actions.publish} {t('label.publish')}
      </>,
      t('label.cancel'),
      (r) => {
        if (r) {
          publishItem({
            lessonId: lessonId,
            publish: true,
          })
            .unwrap()
            .then(() => {
              notification.success({
                message: t('my_lesson.warning.publish_success'),
                description: t('my_lesson.warning.publish_success_message'),
                placement: 'bottomRight',
              });
              props.onActionChange(ManageCollectionActionEnum.refresh, -1);
            })
            .catch(() => {
              notification.error({
                message: t('my_lesson.warning.publish_error'),
                description: t('my_lesson.warning.publish_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onLoadMore = () => {
    setParams((prev) => {
      return {
        ...prev,
        size: prev.size ? prev.size + max_page_size : max_page_size * 2,
      };
    });
  };

  const handleToPublishLesson = (ls: LessonRes) => {
    if (ls.status === EntityStatusEnum.ACTIVE) {
      onPublish(ls.lessonId);
    } else {
      props.onActionChange(
        ManageCollectionActionEnum.publish_collection,
        ls.lessonId
      );
    }
  };

  return (
    <CollectionTableStyle className={'collection-management-table'}>
      {data &&
        data.content.map((ls) => {
          if (ls.status !== EntityStatusEnum.REMOVED) {
            return (
              <CollectionItem
                className={`collection-item ${getEntityStatusClass(ls.status)}`}
                key={ls.lessonId + '_' + ls.modifiedDate}
              >
                <div className={'collection-info'}>
                  <ThumbnailsImg
                    type={'icon'}
                    src={ls.thumbnails}
                    ratio={ImageScale.product.ratio}
                    width={300}
                    height={300}
                  />

                  <H5
                    className={'collection-title'}
                    onClick={() => onEdit(ls.lessonId)}
                  >
                    {ls.name}
                    &nbsp;
                    {IconUtils.edit}
                  </H5>
                </div>

                <div className={'collection-actions right-group'}>
                  <Button
                    shape={'circle'}
                    type={'primary'}
                    danger
                    icon={IconUtils.lesson.remove}
                    onClick={() => onRemove(ls.lessonId)}
                  />
                </div>

                <div className={'collection-actions'}>
                  <div className={'button-group'}>
                    <Button
                      shape={'round'}
                      type={'primary'}
                      icon={IconUtils.actions.start_exercise}
                      onClick={() => onStartExercise(ls.lessonId)}
                    >
                      {t('lesson.actions.practice')}
                    </Button>
                  </div>

                  <div className={'button-group'}>
                    <Space>
                      <Tooltip title={t('lesson_example.actions.compose')}>
                        <Button
                          shape={'circle'}
                          icon={IconUtils.compose_lesson}
                          onClick={() => onCompose(ls.lessonId)}
                        />
                      </Tooltip>

                      <Tooltip title={t('lesson_example.actions.publish')}>
                        <Button
                          shape={'circle'}
                          icon={IconUtils.actions.publish}
                          onClick={() => handleToPublishLesson(ls)}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                </div>
              </CollectionItem>
            );
          } else {
            return null;
          }
        })}

      {data && data.totalPages > 1 && (
        <Button onClick={onLoadMore}>{t('label.load_more')}</Button>
      )}
    </CollectionTableStyle>
  );
};

const CollectionTableStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;

const CollectionItem = styled.div`
  width: 300px;
  position: relative;

  &.published {
    .collection-info {
      border: 1px solid ${(props) => props.theme.status.published};

      .collection-title {
        background: ${(props) => props.theme.status.published};
        color: ${(props) => props.theme.color.white};

        &:hover {
          background: ${(props) => props.theme.status.published};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
  }

  &.activated {
    .collection-info {
      border: 1px solid ${(props) => props.theme.status.activated};

      .collection-title {
        color: ${(props) => props.theme.status.activated};

        &:hover {
          background: ${(props) => props.theme.status.activated};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
  }

  &.removed {
    .collection-info {
      border: 1px solid ${(props) => props.theme.status.removed};

      .collection-title {
        color: ${(props) => props.theme.status.removed};

        &:hover {
          background: ${(props) => props.theme.status.removed};
          color: ${(props) => props.theme.color.white};
        }
      }
    }
  }

  .collection-info {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    width: 300px;
    height: 300px;
    margin-bottom: 0.5em;
    display: flex;
    overflow: hidden;

    .image-thumbnail-wrapper {
      width: 300px;
      height: 300px;
    }

    .collection-title {
      padding: 0.25em 0.5em;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: bold;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 0px;
    }
  }

  .collection-actions {
    padding: 0.125em 0;

    display: flex;
    justify-content: space-between;

    &.right-group {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
`;
