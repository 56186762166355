import useMediaQuery, { ResponsiveScreen } from '@hooks/useMedia';
import NoSsr from '@components/Layout/NoSsr';
import React, { ReactNode } from 'react';

export const Responsive = (props: {
  screens?: ResponsiveScreen[];
  excludeScreens?: ResponsiveScreen[];
  children: any;
}) => {
  return (
    <NoSsr>
      <ResponsiveContent
        screens={props.screens}
        excludeScreens={props.excludeScreens}
      >
        {props.children}
      </ResponsiveContent>
    </NoSsr>
  );
};

const ResponsiveContent = (props: {
  screens?: ResponsiveScreen[];
  excludeScreens?: ResponsiveScreen[];
  children: any;
}) => {
  const screen = useMediaQuery();

  if (
    (props.screens && props.screens.includes(screen)) ||
    (props.excludeScreens && !props.excludeScreens.includes(screen))
  ) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const ResponsiveSwitchContent = (props: {
  screens: ResponsiveScreen[];
  excludeScreenContent: ReactNode;
  children: ReactNode;
}) => {
  return (
    <>
      <Responsive screens={props.screens}>{props.children}</Responsive>

      <Responsive excludeScreens={props.screens}>
        {props.excludeScreenContent}
      </Responsive>
    </>
  );
};
