import React, { useEffect, useRef, useState } from 'react';
// import { ReactEpubViewer } from 'react-epub-viewer';
import NoSsr from '@components/Layout/NoSsr';

export default function EpubViewer(props: { title?: string; fileUrl: string }) {
  const viewerRef = useRef(null);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <div className={'epub-viewer-component'}>
      {ready && (
        <NoSsr>
          <p ref={viewerRef}>${props.fileUrl}</p>
        </NoSsr>
      )}
    </div>
  );
}
