import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import Checkbox from '@components/input/Checkbox';
import {
  AxisSettingProps,
  CoordinateGridType,
  CoordinateSettingProps,
} from '@cms/comps/interact/coordinate/CoordinateUtils';
import Input from '@components/input/input';
import { AxisRangeInput } from '../../common/AxisRangeInput';
import { useTranslation } from 'react-i18next';

export const CoordinateSetting = (props: {
  setting: CoordinateSettingProps;
  onSettingChange: (newSetting: CoordinateSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  const handleOnAxisChange = (type: string, axis: AxisSettingProps) => {
    formik.setFieldValue(type, axis);
  };

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={'Select Background type'}>
          <Select
            size={'large'}
            placeholder="Select Background type"
            onChange={(value) => formik.setFieldValue('grid.type', value)}
            value={formik.values.grid.type}
          >
            <Select.Option
              key={CoordinateGridType.grid}
              value={CoordinateGridType.grid}
            >
              {t('component.coordinate.grid.grid')}
            </Select.Option>

            <Select.Option
              key={CoordinateGridType.coordinate}
              value={CoordinateGridType.coordinate}
            >
              {t('component.coordinate.grid.coordinate')}
            </Select.Option>

            <Select.Option
              key={CoordinateGridType.dot}
              value={CoordinateGridType.dot}
            >
              {t('component.coordinate.grid.dot')}
            </Select.Option>

            <Select.Option
              key={CoordinateGridType.blank}
              value={CoordinateGridType.blank}
            >
              {t('component.coordinate.grid.blank')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Checkbox
          name={'grid.snapToGrid'}
          checked={formik.values.grid.snapToGrid}
          value={formik.values.grid.snapToGrid}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.coordinate.grid.snap_to_grid')}
        </Checkbox>

        <Input
          name="grid.unit"
          type="number"
          label={'Unit Size'}
          onBlur={formik.handleChange}
          defaultValue={formik.values.grid.unit ?? 10}
        />

        <AxisRangeInput
          name={'grid.xAxis'}
          label={t('component.coordinate.label.x_axis')}
          value={formik.values.grid.xAxis}
          onChange={(val) => handleOnAxisChange('grid.xAxis', val)}
        />

        <AxisRangeInput
          name={'grid.yAxis'}
          label={t('component.coordinate.label.y_axis')}
          value={formik.values.grid.xAxis}
          onChange={(val) => handleOnAxisChange('grid.yAxis', val)}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
