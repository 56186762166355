import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateSchoolPaymentMethodReq,
  SchoolPaymentMethodRes,
} from '@modules/payment/services/model';

export const SchoolPaymentMethodAPI = createApi({
  reducerPath: 'SchoolPaymentMethodAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<SchoolPaymentMethodRes[], unknown>({
      query: () => ({
        url: `/school/payment-method`,
      }),
    }),

    findById: builder.query<SchoolPaymentMethodRes, number>({
      query: (paymentMethodId) => ({
        url: `/school/payment-method/${paymentMethodId}`,
        method: 'GET',
      }),
    }),

    createOrUpdate: builder.mutation<
      SchoolPaymentMethodRes,
      CreateSchoolPaymentMethodReq
    >({
      query: (body) => ({
        url: '/school/payment-method',
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (paymentMethodId) => ({
        url: `/school/payment-method/${paymentMethodId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
