import React from 'react';
import {
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import { DoExerciseToolbar } from '@cms/lesson-template/components/toolbar/common/DoExerciseToolbar';
import { GradingExerciseToolbar } from '@cms/lesson-template/components/toolbar/common/GradingExerciseToolbar';
import { ReviewExerciseToolbar } from '@cms/lesson-template/components/toolbar/common/ReviewExerciseToolbar';
import { ExerciseScoreResult } from '@cms/lesson-template/exercise/ExerciseScoreResult';
import ExerciseAnswerStatus from '@cms/lesson-template/components/exercise-status/ExerciseAnswerStatus';
import { ExerciseSections } from '@cms/lesson-template/exercise/ExerciseSections';
import styled from 'styled-components';
import { ViewExerciseWrapper } from './ViewExerciseWrapper';
import { ExerciseToolbar } from './ExerciseToolbar';
import { PracticeContextProvider } from '@cms/lesson-template/context/PracticeContext';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';

export const ExerciseTemplate = () => {
  const { exerciseId } = useExerciseServiceContext();

  return (
    <PracticeContextProvider>
      <ExerciseTemplateStyleWrapper className={'exercise-template-container'}>
        <DoExerciseMode>
          <DoExerciseToolbar />
        </DoExerciseMode>

        <GradingExerciseMode>
          <GradingExerciseToolbar />
        </GradingExerciseMode>

        <ReviewExerciseMode>
          <ReviewExerciseToolbar />
          <ExerciseScoreResult exerciseId={exerciseId} />
        </ReviewExerciseMode>

        <ExerciseAnswerStatus />

        <ViewExerciseWrapper
          className={'exercise-content-display'}
          ratio={{ width: 16, height: 9 }}
          minFontSize={12}
        >
          <ExerciseSections />
        </ViewExerciseWrapper>

        <ExerciseToolbar className={'lesson-toolbar'} />
      </ExerciseTemplateStyleWrapper>
    </PracticeContextProvider>
  );
};

export const ExerciseTemplateStyleWrapper = styled.div`
  width: 100%;
  padding-bottom: 60px;

  .screen-sm & {
    .exercise-content-display,
    .exercise-score-result,
    .exercise-toolbar {
      max-width: 100%;
    }
  }

  .exercise-content-display {
    background: #fff;
    max-width: 80%;
    margin: 1em auto 1em;
    border: 1px solid #ccc;
  }

  .exercise-score-result {
    max-width: 80%;
    margin: 1em auto;
  }

  .exercise-toolbar {
    max-width: 80%;
    margin: 1em auto;
  }
`;
