import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { SubmitExerciseButton } from '@cms/lesson-template/components/buttons/exercise/SubmitExerciseButton';
import { CompleteExerciseButton } from '@cms/lesson-template/components/buttons/exercise/CompleteExerciseButton';
import React from 'react';

export const SubmitExerciseBtn = (props: { interact?: boolean }) => {
  const { lesson } = useViewLessonContext();

  if (props.interact != null && props.interact) {
    return <SubmitExerciseButton />;
  } else if (lesson && lesson.validation && lesson.validation.interact) {
    return <SubmitExerciseButton />;
  } else {
    return <CompleteExerciseButton />;
  }
};
