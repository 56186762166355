import React, { useEffect, useState } from 'react';
import { Button, Modal, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import NavLink from '@components/button/NavLink';
import { LeftCircleOutlined } from '@ant-design/icons';
import CourseInformationStep from '@modules/course/container/publish/CourseInformationStep';
import CourseIntroduceStep from '@modules/course/container/publish/CourseIntroduceStep';
import CourseLicenseStep from '@modules/course/container/publish/CourseLicenseStep';
import CourseReviewStep from '@modules/course/container/publish/CourseReviewStep';
import { CourseAPI, CourseRes } from '@services/private/CourseAPI';
import { SchoolAPI } from '@services/private/SchoolAPI';
import { H1 } from '@components/typography';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
const { Step } = Steps;

export enum PublicCourseStepEnum {
  INFORMATION = 1,
  INTRODUCTION = 2,
  PRICE = 4,
  REVIEW = 5,
}

export default function PublishCoursePage({ courseId }: { courseId: number }) {
  const { t } = useTranslation();
  const router = useRouter();

  const [getSchool, { data: schoolData, isSuccess: isSchoolSuccess }] =
    SchoolAPI.endpoints.getSchool.useLazyQuery();

  const [getCourse, { data }] = CourseAPI.endpoints.getCourse.useLazyQuery({});

  const [warningCreateSchool, setWarningCreateSchool] = useState(false);
  const [step, setStep] = useState<PublicCourseStepEnum>(
    PublicCourseStepEnum.INFORMATION
  );

  useEffect(() => {
    getSchool({});
    getCourse(courseId);
  }, [courseId]);

  useEffect(() => {
    if (isSchoolSuccess) {
      if (schoolData != null && schoolData.schoolId > 0) {
        // do nothing....
      } else {
        setWarningCreateSchool(true);
      }
    }
  }, [schoolData]);

  const getStatusType = (status: PublicCourseStepEnum) => {
    return status === step ? 'process' : status < step ? 'finish' : 'wait';
  };

  const handlePublishedAction = (courseData: CourseRes) => {
    if (schoolData && courseData) {
      router.push(
        SiteMap.public.course_detail_gen(courseData.courseId, courseData.name)
      );
    }
  };

  const viewPublishSite = () => {
    router.push(SiteMap.management.school.info_review);
  };

  return (
    <>
      {data && (
        <>
          <H1>
            <NavLink href={SiteMap.content.product.list}>
              <LeftCircleOutlined />
            </NavLink>
            {t('publish.course.header')} :{' '}
            <span className={'text-default'}>
              <b>{data?.code}</b> - {data?.name}
            </span>
          </H1>

          {warningCreateSchool && (
            <Modal
              open={warningCreateSchool}
              title={t('alert.warning')}
              footer={[
                <Button key="submit" type="primary" onClick={viewPublishSite}>
                  {t('button.create_public_site')}
                </Button>,
              ]}
            >
              <p>{t('publish.warning.need_update_profile')}</p>
            </Modal>
          )}

          <Steps>
            <Step
              status={getStatusType(PublicCourseStepEnum.INFORMATION)}
              title={t('publish.basic_info.header')}
            />
            <Step
              status={getStatusType(PublicCourseStepEnum.INTRODUCTION)}
              title={t('publish.basic_info.introduction')}
            />

            <Step
              status={getStatusType(PublicCourseStepEnum.PRICE)}
              title={t('publish.basic_info.price')}
            />
            <Step
              status={getStatusType(PublicCourseStepEnum.REVIEW)}
              title={t('publish.basic_info.review')}
            />
          </Steps>

          {step === PublicCourseStepEnum.INFORMATION && (
            <CourseInformationStep
              courseData={data}
              goNext={() => setStep(PublicCourseStepEnum.INTRODUCTION)}
            />
          )}

          {step === PublicCourseStepEnum.INTRODUCTION && (
            <CourseIntroduceStep
              courseData={data}
              goPrevious={() => setStep(PublicCourseStepEnum.INFORMATION)}
              goNext={() => setStep(PublicCourseStepEnum.PRICE)}
            />
          )}

          {step === PublicCourseStepEnum.PRICE && (
            <CourseLicenseStep
              courseData={data}
              goPrevious={() => setStep(PublicCourseStepEnum.INTRODUCTION)}
              goNext={() => setStep(PublicCourseStepEnum.REVIEW)}
            />
          )}

          {schoolData && step === PublicCourseStepEnum.REVIEW && (
            <CourseReviewStep
              schoolData={schoolData}
              courseData={data}
              goPrevious={() => setStep(PublicCourseStepEnum.PRICE)}
              goNext={handlePublishedAction}
            />
          )}
        </>
      )}
    </>
  );
}
