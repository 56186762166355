import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, notification } from 'antd';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { DateLabel } from '@components/text/DateLabel';
import { CurrencyAPI } from '@modules/payment/services/CurrencyAPI';
import useConfirmModal from '@components/modal/ConfirmModal';
import { CreateCurrencyForm } from '@modules/payment/currency/container/CreateCurrencyForm';
import { MenuProps } from 'antd/es/menu';
import { IconUtils } from '@utils/IconUtils';
import { CurrencyRes } from '@modules/payment/services/model';

enum CurrencyActionEnum {
  DEFAULT = '',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

const CurrencyManagementPage = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [findAll, { data, isFetching }] =
    CurrencyAPI.endpoints.findAll.useLazyQuery();

  const [deleteCurrency] = CurrencyAPI.endpoints.delete.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'code',
    direction: 'ascend',
  });

  const [action, setAction] = useState({
    action: CurrencyActionEnum.DEFAULT,
  });

  const [selectedData, setSelectedData] = useState<CurrencyRes | null>(null);

  const columns = [
    {
      title: t('label.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: false,
    },
    {
      title: t('label.actions'),
      dataIndex: 'action',
      key: 'action',
      width: '',
    },
  ];

  useEffect(() => {
    findAll(params);
  }, [params]);

  useEffect(() => {
    return () => {
      setParams({
        page: 1,
        size: MAX_PAGE_SIZE,
        sort: 'createdDate',
        direction: 'descend',
      });
    };
  }, []);

  const handleClickMenu = (item: CurrencyRes, action: CurrencyActionEnum) => {
    setSelectedData(item);
    setAction({ action: action });
  };

  const processDataRow = (item: CurrencyRes) => {
    const items: MenuProps['items'] = [
      {
        key: CurrencyActionEnum.UPDATE,
        icon: IconUtils.actions.edit,
        onClick: () => handleClickMenu(item, CurrencyActionEnum.UPDATE),
        label: t('currency.actions.update'),
      },
      {
        key: CurrencyActionEnum.DELETE,
        icon: IconUtils.actions.delete,
        onClick: () => handleClickRemove(item),
        label: t('currency.actions.delete'),
      },
    ];

    return {
      key: item.currencyId,
      code: item.code,
      name: item.name,
      createdDate: <DateLabel label={item.createdDate} />,
      action: (
        <Dropdown menu={{ items: items }} placement="topRight" arrow>
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const refreshData = (params: any) => {
    setParams(params);
  };

  const refresh = () => {
    findAll(params);
    handleOnCancel();
  };

  const handleOnCancel = () => {
    setSelectedData(null);
    setAction({ action: CurrencyActionEnum.DEFAULT });
  };

  const handleClickRemove = (item: CurrencyRes) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('currency.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteCurrency(item.currencyId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('currency.warning.remove_success'),
                description: t('currency.warning.remove_success_message'),
                placement: 'bottomRight',
              });

              refresh();
            })
            .catch(() => {
              notification.error({
                message: t('currency.warning.remove_error'),
                description: t('currency.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('currency.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction({ action: CurrencyActionEnum.ADD })}
          />
        </H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
      />

      {action.action === CurrencyActionEnum.ADD && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('currency.actions.add')}
          content={
            <CreateCurrencyForm
              currencyId={null}
              successHandle={refresh}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === CurrencyActionEnum.UPDATE && selectedData != null && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('currency.actions.update')}
          content={
            <CreateCurrencyForm
              currencyId={selectedData.currencyId}
              successHandle={refresh}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default CurrencyManagementPage;
