export enum ComponentColorEnum {
  red = 'red',
  redBackground = 'redBackground',

  blue = 'blue',
  blueBackground = 'blueBackground',

  yellow = 'yellow',

  green = 'green',
  greenBackground = 'greenBackground',
  lightGreen = 'lightGreen',
  darkGreen = 'darkGreen',

  aqua = 'aqua',

  orange = 'orange',
  orangeBackground = 'orangeBackground',

  purple = 'purple',
  purpleBackground = 'purpleBackground',

  dark = 'dark',

  pink = 'pink',
  lightPink = 'lightPink',

  grey = 'grey',
  black = 'black',

  teal = 'teal',
  tealBackground = 'tealBackground',

  white = 'white',
}

class Component_Utils {
  getColors() {
    return [
      ComponentColorEnum.red,
      ComponentColorEnum.blue,
      ComponentColorEnum.yellow,
      ComponentColorEnum.green,
      ComponentColorEnum.aqua,
      ComponentColorEnum.orange,
      ComponentColorEnum.purple,
      ComponentColorEnum.dark,
    ];
  }

  getColorMapping() {
    return {
      [ComponentColorEnum.red]: '#F44336',
      [ComponentColorEnum.redBackground]: '#f9d0d0',
      [ComponentColorEnum.orange]: '#FF7043',
      [ComponentColorEnum.orangeBackground]: '#fce0c5',

      [ComponentColorEnum.yellow]: '#FF9800',
      [ComponentColorEnum.green]: '#4CAF50',
      [ComponentColorEnum.greenBackground]: '#e1edd4',

      [ComponentColorEnum.lightGreen]: '#99FF00',
      [ComponentColorEnum.darkGreen]: '#00BCD4',
      [ComponentColorEnum.aqua]: '#00B4EB',

      [ComponentColorEnum.blue]: '#3F51B5',
      [ComponentColorEnum.blueBackground]: '#cde3f6',
      [ComponentColorEnum.purple]: '#673AB7',
      [ComponentColorEnum.purpleBackground]: '#e4d5ea',

      [ComponentColorEnum.pink]: '#FF00CC',
      [ComponentColorEnum.lightPink]: '#F072AB',
      [ComponentColorEnum.grey]: '#999999',
      [ComponentColorEnum.dark]: '#555',
      [ComponentColorEnum.black]: '#000000',

      [ComponentColorEnum.teal]: '#009688',
      [ComponentColorEnum.tealBackground]: '#cce8ec',
      [ComponentColorEnum.white]: '#FFF',
    };
  }

  getColor(color: ComponentColorEnum) {
    return this.getColorMapping()[color];
  }
}

const ComponentUtils = new Component_Utils();
export default ComponentUtils;