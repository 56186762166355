import React, { useEffect, useState } from 'react';
import { Button, List } from 'antd';
import { StudentInfoSimple } from '@components/info/StudentInfo';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H4 } from '../typography';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { IconUtils } from '@utils/IconUtils';
import { InviteStudentForm } from '@modules/users/container/users/InviteStudentForm';
import SelectStudent from '@modules/common/components/SelectStudent';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import { CreateStudentUserForm } from '@modules/users/component/users/CreateStudentUserForm';

enum StudentClassActionEnum {
  default = '',
  invite_student = 'invite-student',
  add_student = 'add-student',
}

export default function InviteStudentToClass(props: {
  classId: number;
  successHandle: () => void;
}) {
  const { t } = useTranslation();

  const [getStudentInClass, { data, isSuccess }] =
    ClassAPI.endpoints.getStudentInClass.useLazyQuery({});

  const [addStd] = ClassAPI.endpoints.addStudent.useMutation();
  const [removeStd] = ClassAPI.endpoints.removeStudent.useMutation();

  const [action, setAction] = useState(StudentClassActionEnum.default);
  const [excludedIds, setExcludedIds] = useState<number[]>([]);

  useEffect(() => {
    onRefresh();
  }, [props.classId]);

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      const currentIds = data.map((st) => {
        return st.userId;
      });
      setExcludedIds(currentIds);
    }
  }, [data]);

  const addStudentToClass = (studentId: number, callBack?: () => void) => {
    const request = {
      classId: props.classId,
      studentId: studentId,
    };

    addStd(request)
      .unwrap()
      .then(() => {
        onRefresh();
        if (callBack) {
          callBack();
        }
      })
      .catch(() => {});
  };

  const onRemoveStudent = (studentId: number) => {
    const request = {
      classId: props.classId,
      studentId: studentId,
    };

    removeStd(request)
      .unwrap()
      .then(() => {
        onRefresh();
      })
      .catch(() => {});
  };

  const onRefresh = () => {
    getStudentInClass({
      classId: props.classId,
    });
  };

  const onInviteStudent = () => {
    setAction(StudentClassActionEnum.invite_student);
  };

  const onAddStudent = () => {
    setAction(StudentClassActionEnum.add_student);
  };

  const onCancel = () => {
    setAction(StudentClassActionEnum.default);
  };

  const handleOnSuccess = (studentId: number) => {
    addStudentToClass(studentId);
  };

  const handleOnCreateSuccess = (studentId: number) => {
    addStudentToClass(studentId, () => {
      setAction(StudentClassActionEnum.default);
    });
  };

  return (
    <InvitedStudentForm className={'invite-student-to-class-form'}>
      <SwitchCondition
        conditions={[
          {
            condition: action === StudentClassActionEnum.invite_student,
            children: (
              <InviteStudentForm
                onSuccess={handleOnSuccess}
                onCancel={onCancel}
              />
            ),
          },
          {
            condition: action === StudentClassActionEnum.add_student,
            children: (
              <CreateStudentUserForm
                onSuccess={(user) => handleOnCreateSuccess(user.userId)}
                onCancel={onCancel}
              />
            ),
          },
          {
            condition: action === StudentClassActionEnum.default,
            children: (
              <>
                <div className={'invite-student-group'}>
                  <HeaderGroup
                    className="header-group"
                    style={{ marginTop: 0 }}
                  >
                    <H4>{t('invitation.select_students')}:</H4>

                    <Button
                      type={'primary'}
                      shape="round"
                      icon={IconUtils.actions.invite_student}
                      onClick={onInviteStudent}
                    >
                      {t('class.actions.invite_students')}
                    </Button>
                  </HeaderGroup>

                  <SelectStudent
                    name={'studentId'}
                    value={-1}
                    excludedIds={excludedIds}
                    onChange={addStudentToClass}
                  />
                </div>

                <div className={'invite-student-group'}>
                  <HeaderGroup
                    className="header-group"
                    style={{ marginTop: 0 }}
                  >
                    <H4>{t('class.students')}:</H4>

                    <Button
                      type={'primary'}
                      shape="round"
                      icon={IconUtils.actions.add_more}
                      onClick={onAddStudent}
                    >
                      {t('classes.actions.create_student')}
                    </Button>
                  </HeaderGroup>

                  <List
                    bordered
                    dataSource={data}
                    renderItem={(std) => (
                      <List.Item>
                        <div className={'selected-student'} key={std.userId}>
                          <StudentInfoSimple
                            className={'small-padding'}
                            item={std}
                          />

                          <Button
                            size={'small'}
                            type={'primary'}
                            shape="round"
                            danger
                            onClick={() => onRemoveStudent(std.userId)}
                          >
                            {t('button.remove')}
                          </Button>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              </>
            ),
          },
        ]}
      />
    </InvitedStudentForm>
  );
}

export const InvitedStudentForm = styled.div`
  .invite-student-group {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  .selected-student {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
