import useScript from './useScript';
import { useEffect, useState } from 'react';
import { JsLib } from '@cms/script/LoadLibraryUtils';

const useAnnotationLib = (onSuccess: () => void) => {
  const [state, setState] = useState<Record<string, boolean>>({
    [JsLib.jquery.name]: false,
    // [JsLibraryData.jqueryTouch.name]: false,
    [JsLib.raphael.name]: false,
    [JsLib.raphaelInlineEdit.name]: false,
    [JsLib.annotation.name]: false,
  });

  useEffect(() => {
    let allCompleted = true;

    Object.keys(state).forEach((key) => {
      // @ts-ignore
      if (state[key] === false) {
        allCompleted = false;
      }
    });

    if (allCompleted) {
      setTimeout(() => {
        onSuccess();
      }, 250);
    }
  }, [state]);

  useScript(JsLib.jquery.url, JsLib.jquery.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.jquery.name]: true } };
    });
  });

  // useScript(JsLibraryData.jqueryTouch.url, JsLibraryData.jqueryTouch.id, () => {
  //   setState((prevState) => {
  //     return { ...prevState, ...{ [JsLibraryData.jqueryTouch.name]: true } };
  //   });
  // });

  useScript(JsLib.raphael.url, JsLib.raphael.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.raphael.name]: true } };
    });
  });

  useScript(JsLib.raphaelInlineEdit.url, JsLib.raphaelInlineEdit.id, () => {
    setState((prevState) => {
      return {
        ...prevState,
        ...{ [JsLib.raphaelInlineEdit.name]: true },
      };
    });
  });

  useScript(JsLib.annotation.url, JsLib.annotation.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.annotation.name]: true } };
    });
  });
};

export default useAnnotationLib;
