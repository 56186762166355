import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import React from 'react';
import FeaturesApp from '@router/pages/app_feature';
import TemplateFileForTesting from '@router/pages/template/template';
import PdfViewerTesting from '@router/pages/template/video';
import SocialVideoExample from '@router/pages/template/video';
import DnDTestingExample from '@router/pages/template/drag-n-drop';
import CoordinateTesting from '@router/pages/template/coordinate';
import AudioTestingExample from '@router/pages/template/audio-recording';

export const TemplateRouters: PageRouterProps[] = [
  {
    path: SiteMap.template.features,
    element: <FeaturesApp />,
  },
  {
    path: SiteMap.template.template,
    element: <TemplateFileForTesting />,
  },
  {
    path: SiteMap.template.template_audio_recording,
    element: <AudioTestingExample />,
  },
  {
    path: SiteMap.template.template_coordinate,
    element: <CoordinateTesting />,
  },
  {
    path: SiteMap.template.template_dnd,
    element: <DnDTestingExample />,
  },
  {
    path: SiteMap.template.template_pdf,
    element: <PdfViewerTesting />,
  },
  {
    path: SiteMap.template.template_video,
    element: <SocialVideoExample />,
  },
];
