import React, { useCallback, useContext, useEffect, useState } from 'react';
import CreateSessionForm from '@modules/calendar/components/CreateSessionForm';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { SessionAPI } from '@services/private/SessionAPI';
import dayjs from 'dayjs';
import { notification } from 'antd';
import { GCalendarContext } from '@modules/calendar/context/CalendarContext';
import styled from 'styled-components';
import UpdateSessionDataModal, {
  UpdateSessionAction,
} from '@modules/calendar/components/UpdateSessionDataModal';
import { useTranslation } from 'react-i18next';
import {
  CalendarDateAndTimeFormat,
  ClientDateAndTimeFormat,
} from '@utils/DateAndTimeUtils';

import {
  CalendarEventRes,
  EventAPI,
  EventTypeEnum,
} from '@services/event/EventAPI';
import MonthCalendar from '@components/calendar/MyCalendar';
import { IconUtilsSVG } from '@utils/IconUtils';
import { restructureEvents } from '@modules/calendar/container/UserCalendar';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import ViewSessionDataModal from '../components/ViewSessionDataModal';
import { useSelectUser } from '@hooks/useSelectUser';
import { MoveSessionReq } from '@services/model/session_request';
import { SessionRes } from '@services/model/session';

export interface CalendarEventProps {
  title: string;
  type: EventTypeEnum;
  start: string;
  end: string;
  clone: boolean;
  item?: CalendarEventRes;
  items?: CalendarEventRes[];
}

export interface SessionInfoProps {
  title: string;
  date: string;
  item: SessionRes;
}

export interface PublicSessionInfoProps {
  title: string;
  date: string;
  item: SessionRes;
}

export function convertSessionToEvent(item: SessionRes) {
  return { title: item.name, date: item.startDate, item: item };
}

export function convertSessionToPublicEvent(
  item: SessionRes
): PublicSessionInfoProps {
  return { title: item.name, date: item.startDate, item: item };
}

export const convertToDefaultDate = (dateAndTimeStr: string) => {
  return dayjs(
    dateAndTimeStr.replace('Z', ''),
    CalendarDateAndTimeFormat.DATE_N_TIME
  ).format(ClientDateAndTimeFormat.DATE_N_TIME);
};

export enum CalendarEventAction {
  default = '',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

const ManageCalendar = (props: { classId?: number }) => {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  const [findAll, { data, isSuccess }] =
    EventAPI.endpoints.findAll.useLazyQuery({});

  const [moveSession] = SessionAPI.endpoints.moveSession.useMutation({});

  const selectUserId = useSelectUser();
  const { defaultDate, setDefaultDate } = useContext(GCalendarContext);
  const [events, setEvents] = useState<CalendarEventProps[]>([]);

  const [action, setAction] = useState({
    action: CalendarEventAction.default,
    event: null as SessionRes | null,
    date: '',
  });

  const [title, updateTitle] = useState('');

  const editable = ifAnyGranted([
    RoleEnum.CREATE_CALENDAR,
    RoleEnum.SUPPORT,
    RoleEnum.SCHOOL,
  ]);

  useEffect(() => {
    refresh();
  }, [selectUserId]);

  useEffect(() => {
    if (isSuccess && data) {
      setEvents(restructureEvents(data));
    }
  }, [data]);

  const refresh = useCallback(() => {
    findAll({ forUserId: selectUserId, classId: props.classId });
  }, [selectUserId]);

  const handleDateClick = (arg: any) => {
    if (editable) {
      setAction({
        action: CalendarEventAction.add,
        event: null,
        date: arg.dateStr,
      });
    }
  };

  const handleEventClick = (info: any) => {
    const event: CalendarEventProps = info.event._def.extendedProps;

    if (event.type === EventTypeEnum.SESSION && event.item) {
      if (editable) {
        setAction({
          action: CalendarEventAction.edit,
          event: event.item.session,
          date: '',
        });
      } else {
        setAction({
          action: CalendarEventAction.view,
          event: event.item.session,
          date: '',
        });
      }
    }
  };

  const handleCreateSession = (session: SessionRes | null) => {
    if (session) {
      refresh();
    }
    revertActionToDefault();
  };

  const handleOnUpdate = () => {
    refresh();
    revertActionToDefault();
  };

  const revertActionToDefault = () => {
    setAction({
      action: CalendarEventAction.default,
      event: null,
      date: '',
    });
  };

  const handleEventDrop = (info: any) => {
    if (editable) {
      const event: CalendarEventProps = info.event._def.extendedProps;

      if (event.type === EventTypeEnum.SESSION && event.item) {
        const request: MoveSessionReq = {
          sessionId: event.item.session.sessionId,
          startDate: info.event.start.toISOString(),
        };

        moveSession(request)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('session.warning.update_success'),
              description: t('session.warning.update_success_message'),
              placement: 'bottomRight',
            });
            return info;
          })
          .catch(() => {
            notification.error({
              message: t('session.warning.update_error'),
              description: t('session.warning.update_error_message'),
              placement: 'bottomRight',
            });

            info.revert();
          });
      }
    }
  };

  const warningResult = (isSuccess: boolean, needReload?: boolean) => {
    if (isSuccess) {
      notification.success({
        message: t('session.warning.update_success'),
        description: t('session.warning.update_success_message'),
        placement: 'bottomRight',
      });

      if (needReload) {
        refresh();
      }
    } else {
      notification.error({
        message: t('session.warning.update_error'),
        description: t('session.warning.update_error_message'),
        placement: 'bottomRight',
      });
    }
  };

  const getEventClassNames = (data: any) => {
    return '';
  };

  const eventRendering = (info: any) => {
    const event = info.event._def.extendedProps as CalendarEventProps;
    if (event.type === EventTypeEnum.SESSION) {
      return {
        html: `<div class="custom-event">${IconUtilsSVG.session}${info.event.title}</div>`,
      };
    } else {
      return {
        html: `<div class="custom-event">${IconUtilsSVG.assignment}${info.event.title}</div>`,
      };
    }
  };

  return (
    <TeacherCalendarStyle>
      <MonthCalendar
        editable={editable}
        selectable
        defaultDate={defaultDate}
        events={events}
        getEventClass={getEventClassNames}
        dateClick={editable ? handleDateClick : undefined}
        eventDrop={editable ? handleEventDrop : undefined}
        eventClick={handleEventClick}
        goToDate={setDefaultDate}
        eventRendering={eventRendering}
      />

      {action.action === CalendarEventAction.add && (
        <CustomModal
          header={t('session.actions.add')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateSessionForm
              selectedDate={action.date}
              userId={selectUserId}
              onClose={handleCreateSession}
              warningResult={warningResult}
            />
          }
          onCloseFunc={revertActionToDefault}
        />
      )}

      {action.action === CalendarEventAction.edit && action.event && (
        <CustomModal
          header={title ? title : t('session.actions.update_time')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <UpdateSessionDataModal
              action={UpdateSessionAction.METADATA}
              warningResult={warningResult}
              sessionData={action.event}
              onClose={handleOnUpdate}
              onTabChange={updateTitle}
            />
          }
          onCloseFunc={handleOnUpdate}
        />
      )}

      {action.action === CalendarEventAction.view && action.event && (
        <CustomModal
          header={title ? title : t('session.actions.update_time')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ViewSessionDataModal
              action={UpdateSessionAction.METADATA}
              warningResult={warningResult}
              sessionData={action.event}
              onClose={revertActionToDefault}
              onChange={updateTitle}
            />
          }
          onCloseFunc={revertActionToDefault}
        />
      )}
    </TeacherCalendarStyle>
  );
};

export default ManageCalendar;

const TeacherCalendarStyle = styled.div`
  .fc-view-harness {
    box-shadow: none !important;
  }

  > .calendar-container
    > .fc-theme-standard
    > .fc-view-harness
    > .fc-timegrid
    > table.fc-scrollgrid {
    > tbody[role='rowgroup']
      > tr.fc-scrollgrid-section
      > td[role='presentation'] {
      border: none !important;
    }
  }

  .fc .fc-timegrid-slot-label-cushion {
    text-transform: uppercase;
  }
`;
