import React, { useEffect, useState } from 'react';
import { InputNumberComp } from '@cms/comps/math/fib/InputNumberComp';
import styled from 'styled-components';

export enum FractionTypeEnum {
  fraction = 'fraction',
  mixed_number = 'mixed-number',
}

const parseFractionValue = (value: string) => {
  if (value != null && value.trim() !== '') {
    //is mixed number
    if (value.includes(' ')) {
      const mixedNumber = value.split(' ')[0];
      const fractionNumber = value.split(' ')[1];

      const numerator = fractionNumber.split('/')[0];
      const denominator = fractionNumber.split('/')[1];

      return {
        mixedNumber,
        numerator,
        denominator,
      };
    } else if (value.includes('/')) {
      const numerator = value.split('/')[0];
      const denominator = value.split('/')[1];

      return {
        mixedNumber: '',
        numerator,
        denominator,
      };
    } else {
      return {
        mixedNumber: value,
        numerator: '',
        denominator: '',
      };
    }
  } else {
    return {
      mixedNumber: '',
      numerator: '',
      denominator: '',
    };
  }
};

function getFractionValue(
  type: FractionTypeEnum,
  mixedNumber: string,
  numerator: string,
  denominator: string
) {
  if (type === FractionTypeEnum.mixed_number) {
    return `${mixedNumber} ${numerator}/${denominator}`;
  } else {
    return `${numerator}/${denominator}`;
  }
}

export const FractionInlineComp = (props: {
  type: FractionTypeEnum;
  disabled: boolean;
  digit: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const [fraction, setFraction] = useState(() => {
    return { ...parseFractionValue(props.value), triggerChange: false };
  });

  useEffect(() => {
    setFraction({ ...parseFractionValue(props.value), triggerChange: false });
  }, [props.value]);

  useEffect(() => {
    if (fraction && fraction.triggerChange) {
      props.onChange(
        getFractionValue(
          props.type,
          fraction.mixedNumber,
          fraction.numerator,
          fraction.denominator
        )
      );
    }
  }, [fraction]);

  const handleOnChange = (
    value: string,
    type: 'numerator' | 'denominator' | 'mixed-number'
  ) => {
    if (type === 'mixed-number') {
      setFraction((prev) => {
        return { ...prev, mixedNumber: value, triggerChange: true };
      });
    } else if (type === 'numerator') {
      setFraction((prev) => {
        return { ...prev, numerator: value, triggerChange: true };
      });
    } else if (type === 'denominator') {
      setFraction((prev) => {
        return { ...prev, denominator: value, triggerChange: true };
      });
    }
  };

  return (
    <FractionInlineCompStyle className={'fraction-inline-comp'}>
      {props.type === FractionTypeEnum.mixed_number && (
        <div className={'mixed-number-group'}>
          <InputNumberComp
            className={'mixed-number-field'}
            disabled={props.disabled}
            value={fraction.mixedNumber}
            onChange={(e) => handleOnChange(e, 'mixed-number')}
            digit={props.digit}
          />
        </div>
      )}

      <div className={'fraction-number-group'}>
        <InputNumberComp
          disabled={props.disabled}
          value={fraction.numerator}
          onChange={(e) => handleOnChange(e, 'numerator')}
          digit={props.digit}
          className={'numerator-number-field'}
        />
        <div className={'fraction-divider'} />
        <InputNumberComp
          disabled={props.disabled}
          value={fraction.denominator}
          onChange={(e) => handleOnChange(e, 'denominator')}
          digit={props.digit}
          className={'denominator-number-field'}
        />
      </div>
    </FractionInlineCompStyle>
  );
};

const FractionInlineCompStyle = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .mixed-number-group {
    margin-right: 0.25em;
  }

  .fraction-number-group {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fraction-divider {
      margin-top: 0.125em;
      margin-bottom: 0.125em;
      border-top: 2px solid #ccc;
      width: 100%;
    }
  }
`;
