import { sectionBankTemplates } from '@cms/section-bank/SectionExampleData';
import React from 'react';
import styled from 'styled-components';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { SectionItemClickable } from '@cms/section-bank/components/SectionItemClickable';

export const SectionBanks = (props: {
  lessonId: number;
  onSelect: (data: LessonSectionProps) => void;
}) => {
  return (
    <SectionBanksStyle className={'build-section-container'}>
      {sectionBankTemplates.map(({ item, name }) => {
        return (
          <div className={'section-bank-item'} key={item.code}>
            <SectionItemClickable onClick={props.onSelect} item={item} />
            <div className={'section-name'}>{name}</div>
          </div>
        );
      })}
    </SectionBanksStyle>
  );
};

const SectionBanksStyle = styled.div`
  .section-bank-item {
    position: relative;

    .section-name {
      position: absolute;
      font-weight: bold;
      z-index: 1;
      top: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      color: ${(props) => props.theme.app.primary};
    }

    .section-item-clickable {
      border: 1px solid #ccc;
      padding: 1.3em 1em 1em 1em;
    }
  }
`;
