import { Collapse } from 'antd';
import React from 'react';
import { ParentFeature } from '@modules/public/components/features/ParentFeature';
import { OrganizationFeature } from '@modules/public/components/features/OrganizationFeature';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { RegistrationFeature } from '@modules/public/features/Registration';
import styled from 'styled-components';
import { UserFeatures } from '@modules/public/features/UserFeatures';
import { CreateLessonFeature } from './features/CreateLessonFeature';
import { LtiFeature } from '@modules/public/features/LtiFeatures';
import { CalendarFeature } from './features/CalendarFeature';
import { UserProfileFeatures } from '@modules/public/features/UserProfileFeature';
import { LicenseFeatures } from '@modules/public/components/features/LicenseFeature';
import { ModuleTable } from '@modules/ModuleTable';

const { Panel } = Collapse;

export const FeaturesPages = () => {
  const { t } = useTranslation();

  return (
    <FeaturesPagesStyle>
      <H1>{t('header.features')}</H1>

      <ModuleTable />

      <Collapse accordion={true} expandIconPosition={'end'}>
        <Panel header="Registration" key="registration">
          <RegistrationFeature />
        </Panel>

        <Panel header="User Profile" key="user-profile">
          <UserProfileFeatures />
        </Panel>

        <Panel header="Manage Users" key="manage-users">
          <UserFeatures />
        </Panel>

        <Panel header="Create Lesson And Product" key="lesson-n-product">
          <CreateLessonFeature />
        </Panel>

        <Panel header="Calendar and Events" key="calendar-n-event">
          <CalendarFeature />
        </Panel>

        <Panel header="Assignment and report" key="assignment-n-report">
          <OrganizationFeature />
        </Panel>

        <Panel header="Page and Site Layout" key="page-n-side-layout">
          <ParentFeature />
        </Panel>

        <Panel header="Licenses" key="license">
          <LicenseFeatures />
        </Panel>

        <Panel header="LTI" key="lti-app">
          <LtiFeature />
        </Panel>
      </Collapse>
    </FeaturesPagesStyle>
  );
};

const FeaturesPagesStyle = styled.div`
  padding: 50px 0;

  .ant-collapse-header-text {
    font-size: 20px;
  }
`;
