import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@components/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewerComp = (props: { title?: string; pdfSrc: string }) => {
  const { t } = useTranslation();
  const [ready, setIsReady] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: any) {
    setIsReady(true);
    setNumPages(nextNumPages);
  }

  const handlePreviousPage = () => {
    setPageIndex((prevState) => prevState - 1);
  };

  const handleNextPage = () => {
    setPageIndex((prevState) => prevState + 1);
  };

  return (
    <PDFViewerCompStyle>
      <Document file={props.pdfSrc} onLoadSuccess={onDocumentLoadSuccess}>
        {ready && (
          <Page
            key={`page_${pageIndex}`}
            pageNumber={pageIndex + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        )}

        <div className={'pdf-toolbar-navigation'}>
          <div className={'navigation'}>
            <Button
              type={'default'}
              shape={'circle'}
              disabled={pageIndex === 0}
              onClick={handlePreviousPage}
            >
              &lt;
            </Button>
            <Button
              type={'default'}
              shape={'circle'}
              disabled={pageIndex === numPages - 1}
              onClick={handleNextPage}
            >
              &gt;
            </Button>
          </div>

          <div className={'navigation'}>
            <span>&nbsp;</span>
            <span>
              {t('component.pdf.total_page', {
                index: pageIndex + 1,
                total: numPages,
              })}
            </span>
            <span>&nbsp;</span>
          </div>
        </div>
      </Document>
    </PDFViewerCompStyle>
  );
};

export default PDFViewerComp;

const PDFViewerCompStyle = styled.div`
  canvas.react-pdf__Page__canvas {
    max-width: 100%;
    height: auto !important;
  }

  .pdf-toolbar-navigation {
    .navigation {
      display: flex;
      justify-content: space-between;
    }
  }
`;
