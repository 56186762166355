import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { SyncEventPage } from '@modules/sync/pages/SyncEventPage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';

const SyncEvents = () => {
  return (
    <ManagementLayout className={'sync-event-page'}>
      <PageHeader
        menu={NavigationMenuEnum.EMPTY}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Sync Events'}
        description={'Sync Events'}
      />

      <SyncEventPage />
    </ManagementLayout>
  );
};

export default SyncEvents;
