import {faCoffee, faHeadphones, faMicrophone, faPlay, faStop, faMicrophoneSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const FaIcons = {
  play: () => {
    return <FontAwesomeIcon icon={faPlay}/>;
  },

  stop: () => {
    return <FontAwesomeIcon icon={faStop}/>;
  },

  coffee: () => {
    return <FontAwesomeIcon icon={faCoffee}/>;
  },
  headphones: () => {
    return <FontAwesomeIcon icon={faHeadphones}/>;
  },

  microphone: () => {
    return <FontAwesomeIcon icon={faMicrophone} />;
  },

  microphoneSlash: () => {
    return <FontAwesomeIcon icon={faMicrophoneSlash} />;
  }
};

export default FaIcons;