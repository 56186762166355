import React, { useEffect, useState } from 'react';
import { SessionAPI } from '@services/private/SessionAPI';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import { Card } from 'antd';
import { SessionRes } from '@services/model/session';

export default function SessionPublicCodeInfo(props: { token: string }) {
  const [getPublicSessionData, { data }] =
    SessionAPI.endpoints.getPublicSessionData.useLazyQuery({});

  const [session, setSession] = useState<SessionRes | null>(null);

  useEffect(() => {
    getPublicSessionData({ token: props.token });
  }, [props.token]);

  useEffect(() => {
    if (data) {
      setSession(data);
    }
  }, [data]);

  return (
    <div className={'session-information'}>
      {session && (
        <div
          className={'session-info-container'}
          style={{
            maxWidth: 900,
            margin: '50px auto',
          }}
        >
          <Card hoverable>
            <PublicSessionInfo item={session} />
          </Card>
        </div>
      )}
    </div>
  );
}
