import React, { useEffect, useState } from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { Row, Col } from 'antd';
import SingleUpload from '@components/input/SingleUpload';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import { H4 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import {
  CreateProductReq,
  ProductRes,
} from '@modules/product/services/product_model';

const initialValue: CreateProductReq = {
  name: '',
  description: '',
  icon: '',
};

export default function ProductEditForm(props: {
  productId?: number;
  successHandle: (isAdded: boolean, data: ProductRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const { data, isSuccess } = ProductAPI.endpoints.getProduct.useQuery(
    props.productId!,
    { skip: !props.productId, refetchOnMountOrArgChange: true }
  );

  const [createProduct, { isLoading: isCreateLoading }] =
    ProductAPI.endpoints.createProduct.useMutation({});
  const [updateProduct, { isLoading: isUpdateLoading }] =
    ProductAPI.endpoints.updateProduct.useMutation({});

  const [productIcon, setProductIcon] = useState('');

  useEffect(() => {
    if (data) {
      setProductData(data);
    }
  }, [isSuccess]);

  const setProductData = (data: ProductRes) => {
    formik.setValues({
      name: data.name,
      description: data.description,
      icon: data.icon,
    });

    setProductIcon(data.icon);
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
      description: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.productId > 0) {
        const payload = {
          productId: data.productId,
          name: values.name.trim(),
          description: values.description,
          icon: productIcon,
        };

        updateProduct(payload)
          .unwrap()
          .then((data) => {
            props.successHandle(false, data);

            notification.success({
              message: t('product.warning.update_product_metadata_success'),
              description: t(
                'product.warning.update_product_metadata_success_message'
              ),
              placement: 'bottomRight',
            });
          })
          .catch(() => {
            notification.error({
              message: t('product.warning.update_product_metadata_error'),
              description: t(
                'product.warning.update_product_metadata_error_message'
              ),
              placement: 'bottomRight',
            });
          });
      } else {
        const payload = {
          name: values.name,
          description: values.description,
          icon: productIcon,
        };

        createProduct(payload)
          .unwrap()
          .then((data) => {
            props.successHandle(true, data);

            notification.success({
              message: t('product.warning.create_success'),
              description: t('product.warning.create_success_message'),
              placement: 'bottomRight',
            });
          })
          .catch(() => {
            notification.error({
              message: t('product.warning.create_error'),
              description: t('product.warning.create_error_message'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const handleOnFileUploaded = (uploadedFile: string) => {
    setProductIcon(uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err when upload file....');
  };

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col flex={ImageScale.product.width + 15 + 'px'}>
          <InputGroup label={'Icon'}>
            <SingleUpload
              scale={ImageScale.product.scale}
              accept={'image/jpg, image/jpeg, image/png'}
              multiple={false}
              onSuccessFunc={handleOnFileUploaded}
              onErrorFunc={handleOnUploadHasErr}
            >
              <IconImage
                src={productIcon}
                width={ImageScale.product.width}
                height={ImageScale.product.height}
              />
            </SingleUpload>
          </InputGroup>
        </Col>

        <Col flex={'auto'}>
          {data && isSuccess && (
            <InputGroup label={t('label.code')}>
              <InputValue type={'textarea'}>
                <H4 className={'no-margin'}>{data.code}</H4>
              </InputValue>
            </InputGroup>
          )}

          <Input
            required={true}
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <InputGroup label={t('label.description')}>
            <Input
              type={'textarea'}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              maxLength={512}
              style={{ minHeight: 120 }}
            />
          </InputGroup>

          <ButtonGroup className="submit-container" type={'right'}>
            <Button
              type="default"
              shape={'round'}
              size={'large'}
              onClick={props.onCloseFunc}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              loading={isUpdateLoading || isCreateLoading}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              {props.productId && props.productId > 0
                ? t('button.update')
                : t('button.create')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
