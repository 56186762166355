import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { SessionTaskInstanceRes } from '@services/model/session';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { SessionAPI } from '@services/private/SessionAPI';
import { ClassRoomAction } from '@classroom/context/actions';
import { List } from 'antd';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import Button from '@components/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import { ClassRoomExerciseStyle } from './style';
import { ReviewStudentExercise } from './ReviewStudentTask';

export const ClassRoomSessionTask = (props: {
  sessionId: number;
  sessionTaskId: number;
}) => {
  const { t } = useTranslation();

  const [getTaskInstances, { data, isSuccess }] =
    SessionAPI.endpoints.getTaskInstances.useLazyQuery();

  const { taskStatus, dispatchAction } = useClassRoomContext();

  const [taskInstance, setTaskInstance] =
    useState<SessionTaskInstanceRes | null>(null);

  useEffect(() => {
    if (props.sessionId > 0 && props.sessionTaskId > 0) {
      getTaskInstances({
        sessionId: props.sessionId,
        sessionTaskId: props.sessionTaskId,
      });
    }
  }, [props.sessionId, props.sessionTaskId]);

  const onViewExercise = (_taskInstance: SessionTaskInstanceRes) => {
    setTaskInstance(_taskInstance);
  };

  const onPresent = (exerciseId: number) => {
    dispatchAction(ClassRoomAction.PRESENT_EXERCISE, exerciseId);
  };

  return (
    <ClassRoomExerciseStyle className={'class-room-task'}>
      <div className={'class-room-instance-items hide-scrollbar'}>
        {data && isSuccess && (
          <List
            bordered
            dataSource={data}
            renderItem={(ins) => {
              const completed =
                taskStatus[ins.sessionTaskInstanceId] != null &&
                taskStatus[ins.sessionTaskInstanceId].submitted;

              return (
                <List.Item
                  className={`exercise-status-display ${
                    ins.sessionTaskInstanceId ===
                    taskInstance?.sessionTaskInstanceId
                      ? 'selected'
                      : ''
                  } ${completed ? 'completed' : 'in-progress'}`}
                >
                  <div className={'exercise-status-display'}>
                    <UserThumbnailDisplay size={30} item={ins.user} />

                    <Button
                      shape={'round'}
                      size={'small'}
                      type={completed ? 'primary' : 'default'}
                      className={'exercise-status'}
                      onClick={() => onViewExercise(ins)}
                    >
                      {completed ? 'Completed' : 'In-progress'}
                    </Button>
                  </div>
                </List.Item>
              );
            }}
          />
        )}
      </div>

      <div className={'class-room-instance-review hide-scrollbar'}>
        {taskInstance && (
          <div className={'class-room-exercise-item'}>
            <ReviewStudentExercise
              exerciseId={taskInstance.exercise.exerciseId}
            />

            <ButtonGroup type={'center'} className={'submit-container'}>
              <Button
                size={'large'}
                shape={'round'}
                type={'primary'}
                icon={IconUtils.class_room.join_classroom}
                onClick={() => onPresent(taskInstance?.exercise.exerciseId)}
              >
                {t('class_room.actions.present')}
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
    </ClassRoomExerciseStyle>
  );
};
