import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import {
  CompAnswerProps,
  CompProps,
  CompScoringProps,
} from '@cms/ComponentInteface';
import { LessonServiceContext } from './LessonServiceProvider';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export const LessonSessionService = (props: {
  lessonId: number;
  lessonExampleId: number;
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { confirm } = useConfirmModal();

  const [createLessonAndResources, { isLoading }] =
    LessonAPI.endpoints.createLessonAndResources.useMutation({});

  const [resourceId, setResourceId] = useState(0);
  const [resource, setResource] = useState<ResourceProps | null>(null);
  const [resources, setResources] = useState<ResourceProps[]>([]);

  const viewResource = (resourceId: number) => {
    findById(resourceId);
  };

  const findById = (resourceIndex: number) => {
    setResourceId(resourceIndex);

    if (resourceIndex != null && resourceIndex >= 0 && resources.length > 0) {
      setResource(resources[resourceIndex]);
    } else {
      setResource(null);
    }
  };

  const onDelete = (removeId: number, callBack: (rmId: number) => void) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('component.actions.delete_component'),
      t('button.confirm'),
      t('button.cancel'),
      (result) => {
        if (result) {
          setResources((prev) => {
            return prev.filter((res) => {
              return res.resourceId !== removeId;
            });
          });

          if (removeId === resourceId) {
            const nextRsIndex = getNextResource(removeId);
            findById(nextRsIndex);
          }
          callBack(removeId);
        }
      }
    );
  };

  const onInsert = (
    cloneId: number,
    callBack: (resourceIndex: number) => void
  ) => {
    const rsIndex = resources.findIndex((rs) => {
      return rs.resourceId === cloneId;
    });

    if (rsIndex > -1) {
      const targetRes = resources[rsIndex];
      // create empty resource....
      const cloneRs = {
        ...targetRes,
        resourceId: targetRes.resourceId + 1,
        components: [],
        correctAnswer: [],
        scoring: [],
      };

      setResources((prev) => {
        let resourceId = 0;
        const results: ResourceProps[] = [];

        prev.forEach((res) => {
          if (res.resourceId === cloneId) {
            results.push({ ...res, resourceId: resourceId });
            results.push(cloneRs);
            resourceId += 2;
          } else {
            results.push({ ...res, resourceId: resourceId });
            resourceId += 1;
          }
        });
        return results;
      });

      setResourceId(cloneRs.resourceId);
      setResource(cloneRs);
      callBack(cloneRs.resourceId);
    }
  };

  const onCreateOrUpdate = (
    resourceId: number,
    components: CompProps[],
    correctAnswer: CompAnswerProps[],
    scoring: CompScoringProps[],
    callBackFun: (res: number) => void
  ) => {
    const indexOf = resources.findIndex((res) => {
      return res.resourceId === resourceId;
    });

    // if is exits
    if (indexOf > -1) {
      setResources((prev) => {
        return [...prev].map((res) => {
          if (res.resourceId === resourceId) {
            return {
              ...res,
              components: components,
              correctAnswer: correctAnswer,
              scoring: scoring,
            };
          } else {
            return res;
          }
        });
      });
      callBackFun(resourceId);
      // add new
    } else {
      const resource: ResourceProps = {
        resourceId: resourceId,
        description: '',
        keyword: '',
        components: components,
        correctAnswer: correctAnswer,
        scoring: scoring,
        displayOrder: resourceId,
        questionNumber: resourceId,
        showQuestionNumber: true,
      };

      setResources((prev) => {
        const results: ResourceProps[] = [];
        [...prev].forEach((res, index) => {
          if (index < resourceId) {
            results.push(res);
          } else {
            results.push({ ...res, resourceId: index + 1 });
          }
        });

        results.push(resource);

        return results.sort((a, b) => {
          return a.resourceId - b.resourceId;
        });
      });
      callBackFun(resourceId);
    }
  };

  const getNextResource = (removedId: number): number => {
    const index = resources.findIndex((rs) => {
      return rs.resourceId === removedId;
    });

    if (index > 0) {
      return resources[index - 1].resourceId;
    } else if (index < resources.length - 1) {
      return resources[index + 1].resourceId;
    } else {
      return -1;
    }
  };

  const onCreateOrUpdateLesson = (name: string) => {
    const request = {
      name: name,
      lessonExampleId: props.lessonExampleId,
      resources: resources.map((res) => {
        return {
          lessonId: -1,
          displayOrder: res.resourceId,
          content: JSON.stringify(res.components, null, 2),
          correctAnswer: JSON.stringify(res.correctAnswer, null, 2),
          scoring: JSON.stringify(res.scoring, null, 2),
        };
      }),
    };

    createLessonAndResources(request)
      .unwrap()
      .then(() => {
        router.push(SiteMap.private.my_lesson.list);
      })
      .catch(() => {});
  };

  const onClone = (resourceId: number, calBackFunc: (clId: number) => void) => {
    console.log('..................', resourceId, calBackFunc);
  };

  const getLessonData = () => {
    // do nothing....
  };

  return (
    <LessonServiceContext.Provider
      value={{
        loading: isLoading,
        lesson: null,
        resourceId,
        resource,
        resources,
        getLessonData,
        viewResource,
        findById,
        onCreateOrUpdate,
        onDelete,
        onInsert,
        onClone,
        onCreateOrUpdateLesson,
      }}
    >
      {props.children}
    </LessonServiceContext.Provider>
  );
};
