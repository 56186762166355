import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import {
  GlossaryGameCompProps,
  GlossaryGameContent,
} from '@cms/comps/game/GameUtils';

import styled from 'styled-components';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import FlashCardItem from './FlashCardItem';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ContainerRatio } from '@components/Layout/ContainerScale';
import { AssetsRes } from '@modules/product/services/assets_model';
import produce from 'immer';
import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import {
  GlossaryType,
  GlossaryWordBank,
} from '@cms/comps/game/GlossaryWordBank';

export interface FlashCardSettingProps extends CompInteractSettingProps {
  scramble: boolean;
  showTerm?: GlossaryType;
}

export interface FlashCardCompProps extends GlossaryGameCompProps {
  setting: FlashCardSettingProps;
  configuration: {
    sourceItems: GlossaryGameContent[];
  };
}

export function FlashCardComp(props: {
  item: FlashCardCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);
  const [cardIndex, setCardIndex] = useState(0);
  const [flashCards, setFlashCards] = useState<GlossaryGameContent[]>([]);

  // step 1. Generate list card items.....
  useEffect(() => {
    if (props.item.setting.scramble) {
      const flashCards: GlossaryGameContent[] = [
        ...props.item.configuration.sourceItems,
      ].sort(() => {
        return Math.random() - 0.5;
      });
      setFlashCards(flashCards);
    } else {
      setFlashCards([...props.item.configuration.sourceItems]);
    }
  }, [props.item]);

  const goToPrevious = () => {
    setCardIndex((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const goNext = () => {
    setCardIndex((prev) => {
      if (prev < flashCards.length - 1) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  const handleOnContentChange = (
    id: string,
    type: GlossaryType,
    data: AssetsRes
  ) => {
    const newSourceItems: GlossaryGameContent[] = [
      ...props.item.configuration.sourceItems,
    ].map((card) => {
      if (card.id === id) {
        if (type === GlossaryType.word) {
          return { ...card, word: data };
        } else {
          return { ...card, description: data };
        }
      } else {
        return { ...card };
      }
    });

    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: newSourceItems,
      };
    });

    dispatch(updateComponent(newComps));
  };

  const handleComponentChange = (newData: FlashCardCompProps) => {
    dispatch(updateComponent(newData));
    setShowComponent(true);
  };

  return (
    <FlashCardCompStyle className={'comp comp-flashcard'}>
      {showComponent && (
        <>
          <div className={'flash-card-header'}>
            {cardIndex + 1} of {flashCards.length}
          </div>

          <div className={'flash-card-navigation left-panel'}>
            <button disabled={cardIndex <= 0} onClick={goToPrevious}>
              <LeftOutlined />
            </button>
          </div>

          <div className={'flash-card-workspace'}>
            <ContainerRatio
              className={'flash-card-workspace-display'}
              ratio={' 16 / 9'}
            >
              {flashCards.map((word, index) => {
                if (index === cardIndex) {
                  return (
                    <FlashCardItem
                      key={JSON.stringify(word) + '_' + index}
                      item={word}
                      setting={props.item.setting}
                      onContentChange={handleOnContentChange}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </ContainerRatio>
          </div>

          <div className={'flash-card-navigation right-panel'}>
            <button
              onClick={goNext}
              disabled={cardIndex >= flashCards.length - 1}
            >
              <RightOutlined />
            </button>
          </div>

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </FlashCardCompStyle>
  );
}

const FlashCardCompStyle = styled.div`
  position: relative;
  border: 2px solid #ccc;
  border-radius: 1em;

  .flash-card-navigation {
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.left-panel {
      left: 0px;
    }

    &.right-panel {
      right: 0px;
    }

    button {
      width: 2em;
      height: 2em;
      border: none;
      font-size: 150%;
      background: none;
      border-radius: 100%;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .flash-card-workspace-display {
    display: flex;
  }

  .flash-card-header {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: 150%;
    font-weight: bold;
    padding: 1em;
  }

  .flash-card-workspace {
    width: 100%;
    z-index: 1;

    .container-scale-wrapper {
      width: 100%;
      position: relative;
    }
  }
`;
