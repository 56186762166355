import { OperationUtils } from '@cms/comps/math/operation/OperationUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { PlaceValueUnitEnum } from '@cms/comps/math/operation/table/PlaceValueHeader';
import Button from '@components/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { Space } from 'antd';

const onesIcon = 'public/cms/components/place-value/ones.png';
const tenIcon = 'public/cms/components/place-value/tens.png';
const hundredIcon = 'public/cms/components/place-value/hundreds.png';
const thousandIcon = 'public/cms/components/place-value/thoudsands.png';

const getPlaceValueIcon = (type: PlaceValueUnitEnum) => {
  if (type === PlaceValueUnitEnum.ones) {
    return (
      <img
        className={'component-score-image'}
        src={onesIcon}
        alt="ones"
        style={{ width: 7, height: 7 }}
      />
    );
  } else if (type === PlaceValueUnitEnum.tens) {
    return (
      <img
        className={'component-score-image'}
        src={tenIcon}
        alt="tens"
        style={{ width: 7, height: 45 }}
      />
    );
  } else if (type === PlaceValueUnitEnum.hundreds) {
    return (
      <img
        className={'component-score-image'}
        src={hundredIcon}
        alt="hundreds"
        style={{ width: 46, height: 45 }}
      />
    );
  } else if (type === PlaceValueUnitEnum.thousands) {
    return (
      <img
        className={'component-score-image'}
        src={thousandIcon}
        alt="hundreds"
        style={{ width: 57, height: 56 }}
      />
    );
  } else {
    return '';
  }
};

export const PlaceValueInput = (props: {
  type: PlaceValueUnitEnum;
  value: string;
  onChange: (val: string) => void;
  disabled: boolean;
}) => {
  const [numberUnit, setNumberUnit] = useState({
    numbers:
      OperationUtils.VARIABLE_CHARACTER === props.value
        ? 0
        : Number(props.value),
    triggerChange: false,
  });

  useEffect(() => {
    if (numberUnit && numberUnit.triggerChange) {
      if (numberUnit.numbers === 0) {
        props.onChange(OperationUtils.VARIABLE_CHARACTER);
      } else {
        props.onChange(numberUnit.numbers.toString());
      }
    }
  }, [numberUnit]);

  useEffect(() => {
    setNumberUnit({
      numbers:
        OperationUtils.VARIABLE_CHARACTER === props.value
          ? 0
          : Number(props.value),
      triggerChange: false,
    });
  }, [props.value]);

  const handleOnClick = (value: number | null) => {
    if (!props.disabled) {
      if (value == null) {
        setNumberUnit({ numbers: 0, triggerChange: true });
      } else {
        setNumberUnit((prev) => {
          return { numbers: prev.numbers + value, triggerChange: true };
        });
      }
    }
  };

  const numberObjects = useMemo(() => {
    const iconType = getPlaceValueIcon(props.type);
    const icons = [];

    for (let i = 0; i < numberUnit.numbers; i++) {
      icons.push(<span className={'place-value-block-icon'}>{iconType}</span>);
    }
    return icons;
  }, [numberUnit.numbers, props.type]);

  return (
    <PlaceValueInputStyle
      className={`place-value-block-input place-value-block-type-${props.type}`}
    >
      <div className={'place-value-block-input-display'}>{numberObjects}</div>

      <ButtonGroup>
        <Space>
          <Button
            size={'small'}
            onClick={() => handleOnClick(-1)}
            disabled={props.disabled || numberUnit.numbers <= 0}
          >
            <MinusOutlined />
          </Button>

          <span className={'place-value-block-input-header'}>
            {numberUnit.numbers}
          </span>

          <Button
            size={'small'}
            onClick={() => handleOnClick(+1)}
            disabled={props.disabled || numberUnit.numbers > 20}
          >
            <PlusOutlined />
          </Button>

          <Button
            size={'small'}
            disabled={props.disabled}
            onClick={() => handleOnClick(+1)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      </ButtonGroup>
    </PlaceValueInputStyle>
  );
};

const PlaceValueInputStyle = styled.div`
  display: flex;
  flex-direction: column;

  //.place-value-block-input-header {
  //  font-size: 80%;
  //  text-align: center;
  //}

  .place-value-block-input-display {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    min-height: 7em;
    gap: 3px;

    .place-value-block-icon {
      display: inline-flex;
    }
  }

  &.place-value-block-type-thoudsands {
    .place-value-block-input-display {
      max-width: 13em;
      min-width: 13em;
      img {
        max-width: 6em !important;
        height: auto !important;
      }
    }
  }

  &.place-value-block-type-hundreds {
    .place-value-block-input-display {
      min-width: 11em;
      max-width: 11em;

      img {
        max-width: 5em !important;
        height: auto !important;
      }
    }
  }

  &.place-value-block-type-tens {
    .place-value-block-input-display {
      min-width: 3em;
      max-width: 7em;

      img {
        max-width: 1em !important;
        height: auto !important;
      }
    }
  }

  &.place-value-block-type-ones {
    .place-value-block-input-display {
      align-items: flex-start;
      min-width: 1em;
      max-width: 1em;

      img {
        max-width: 1em !important;
        height: auto !important;
      }
    }
  }
`;
