import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';

import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import ComposeLessonPage from '@modules/product/components/lesson/pages/ComposeLessonPage';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { PageHeader } from '@app/header/PageHeader';

const ComposeLessonResource = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { lessonId, resourceId, productId, productTocId } =
    router.query as unknown as {
      lessonId: number;
      resourceId: number;
      productId?: number;
      productTocId?: number;
    };

  return (
    <ComposeLessonLayout
      className={'compose-lesson-layout compose-resource-in-lesson'}
    >
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.actions.compose')}
        description={t('lesson.actions.compose')}
      />

      <ComposeLessonPage
        from={'lesson'}
        lessonId={Number(lessonId)}
        resourceId={Number(resourceId)}
        productId={productId}
        productTocId={productTocId}
        editMode={EditModeEnum.MULTIPLE_QUESTION}
      />
    </ComposeLessonLayout>
  );
};

export default ComposeLessonResource;
