import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { SessionRes } from '@services/model/session';

export const StudentSessionAPI = createApi({
  reducerPath: 'StudentSessionAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getMySession: builder.query<SessionRes[], unknown>({
      query: () => ({
        url: `/student/sessions`,
      }),
    }),
  }),
});
