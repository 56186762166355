import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { Button, Select, Slider } from 'antd';
import Input from '@components/input/input';
import {
  FixedGroupSectionProps,
  FixedGroupSectionSettingProps,
} from '@cms/section-bank/fixed-group/FixedGroupSection';
import { InputGroup } from '@components/input/InputGroup';
import Checkbox from '@components/input/Checkbox';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';

export const FixedGroupSectionSetting = (props: {
  item: FixedGroupSectionProps;
  onChange: (setting: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.item.setting as FixedGroupSectionSettingProps,
    onSubmit: (values) => {
      props.onChange(JSON.stringify(values));
    },
  });

  const handleSlideChange = (value: number, index: number) => {
    if (index === 0) {
      formik.setFieldValue('width', [value, formik.values.width[1]]);
    } else {
      formik.setFieldValue('width', [formik.values.width[0], value]);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Input
        name="gap"
        type="text"
        label={t('section.label.space_between')}
        onBlur={formik.handleChange}
        defaultValue={formik.values.gap}
      />

      <InputGroup label={t('section.label.column_size')}>
        <Slider
          min={1}
          step={1}
          max={100}
          onChange={(val) => handleSlideChange(val, 0)}
          value={formik.values.width[0]}
        />
      </InputGroup>

      <InputGroup label={t('section.label.column_size')}>
        <Slider
          min={1}
          step={1}
          max={100}
          onChange={(val) => handleSlideChange(val, 1)}
          value={formik.values.width[1]}
        />
      </InputGroup>

      <InputGroup label={t('section.label.layout')}>
        <Select
          size={'large'}
          placeholder={t('section.label.layout')}
          onChange={(value) => formik.setFieldValue('layout', value)}
          value={formik.values.layout}
        >
          <Select.Option key={'left-right'} value={'left-right'}>
            {t('section.label.left_right')}
          </Select.Option>

          <Select.Option key={'right-left'} value={'right-left'}>
            {t('section.label.right_left')}
          </Select.Option>

          <Select.Option key={'top-bottom'} value={'top-bottom'}>
            {t('section.label.top_bottom')}
          </Select.Option>

          <Select.Option key={'bottom-top'} value={'bottom-top'}>
            {t('section.label.bottom_top')}
          </Select.Option>
        </Select>
      </InputGroup>

      <div style={{ display: 'none' }}>
        <Checkbox
          name={'showLabel'}
          checked={formik.values.showJump}
          value={formik.values.showJump}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('section.label.show_jump')}
        </Checkbox>
      </div>

      <Button
        type={'primary'}
        onClick={formik.submitForm}
        icon={IconUtils.actions.save}
      >
        {t('button.update')}
      </Button>
    </FormikProvider>
  );
};
