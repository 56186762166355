import styled from 'styled-components';
import { CompProps, CompTypeEnum } from '@cms/ComponentInteface';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { MultipleChoiceSetting } from '@cms/comps/interact/multiple-choice/MultipleChoiceSetting';
import { MatchingSetting } from '@cms/comps/interact/matching/MatchingSetting';
import { DragNDropSetting } from '@cms/comps/interact/drag-n-drop/DragNDropSetting';
import { DropdownSetting } from '@cms/comps/interact/dropdown/DropdownSetting';
import { FibNumberSetting } from '@cms/comps/math/fib/FibNumberSetting';
import { FibWordSetting } from '@cms/comps/interact/inline/FibWordSetting';
import { FibEssaySetting } from '@cms/comps/interact/fill-in-blank/FibEssaySetting';
import { FibParagraphSetting } from '@cms/comps/interact/fill-in-blank/FibParagraphSetting';
import { FlashCardMatchingSetting } from '@cms/comps/game/matching/FlashCardMatchingSetting';
import { WordBoxSetting } from '@cms/comps/game/word-box/WordBoxSetting';
import { WordSearchSetting } from '@cms/comps/game/word-search/WordSearchSetting';
import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { WordScrambleSetting } from '@cms/comps/game/word-scramble/WordScrambleSetting';
import { WordPuzzleSetting } from '@cms/comps/game/word-puzzle/WordPuzzleSetting';
import { OrderingSetting } from '@cms/comps/interact/ordering/OrderingSetting';
import { LongAdditionSetting } from './comps/math/operation/addition/LongAdditionSetting';
import { LongSubtractionSetting } from '@cms/comps/math/operation/subtraction/LongSubtractionSetting';
import { LongMultiplicationSetting } from '@cms/comps/math/operation/multiplication/LongMultiplicationSetting';
import { LongDivisionSetting } from '@cms/comps/math/operation/division/LongDivisionSetting';
import { FractionSetting } from '@cms/comps/math/frac/FractionSetting';
import { MathEquationSetting } from '@cms/comps/math/equation/MathEquationSetting';
import { CoordinateSetting } from './comps/interact/coordinate/CoordinateSetting';
import { useTranslation } from 'react-i18next';
import { DragNDropInlineSetting } from './comps/interact/drag-n-drop/inline/DragNDropInlineSetting';
import { AreaMultiplicationSetting } from './comps/math/operation/area-multiplication/AreaMultiplicationSetting';
import { AreaDivisionSetting } from '@cms/comps/math/operation/area-division/AreaDivisionSetting';
import { PlaceValueSetting } from '@cms/comps/math/operation/place-value/PlaceValueSetting';
import { CheckBoxSetting } from '@cms/comps/interact/checkbox/CheckBoxSetting';
import { AudioCompSetting } from '@cms/comps/content/audio/AudioCompSetting';
import { FlashCardSetting } from '@cms/comps/game/flashcard/FlashCardSetting';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  ComposeCompActionEnum,
  dispatchComposeAction,
} from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { GlossarySetting } from './comps/game/glossary/GlossarySetting';
import { CircleUnderlineSetting } from './comps/interact/circle-underline/CircleUnderlineSetting';
import { ComponentCommonSetting } from '@cms/comps/common/ComponentCommonSetting';

export const ComponentSettingStyle = styled.div`
  z-index: 2;
  .component-setting-footer {
    margin-top: 2em;
  }
`;

export function ComponentSettingItems(props: {
  item: CompProps;
  onChange: (newSetting: any) => void;
}) {
  switch (props.item.type) {
    case CompTypeEnum.AUDIO:
      return (
        <AudioCompSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.MATCHING:
      return (
        <MatchingSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.DRAG_N_DROP:
      return (
        <DragNDropSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.DRAG_N_DROP_INLINE:
      return (
        <DragNDropInlineSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.ORDERING:
      return (
        <OrderingSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.DROPDOWN:
      return (
        <DropdownSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.CHECK_BOX:
      return (
        <CheckBoxSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.FIB_WORD:
      return (
        <FibWordSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );

    case CompTypeEnum.CIRCLE_UNDERLINE:
      return (
        <CircleUnderlineSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );

    case CompTypeEnum.FIB_PARAGRAPH:
      return (
        <FibParagraphSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.FIB_ESSAY:
      return (
        <FibEssaySetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.GLOSSARY:
      return (
        <GlossarySetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );

    case CompTypeEnum.FLASH_CARD:
      return (
        <FlashCardSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );

    case CompTypeEnum.GAME_MATCHING:
      return (
        <FlashCardMatchingSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.WORD_BOX:
      return (
        <WordBoxSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.WORD_SEARCH:
      return (
        <WordSearchSetting
          sourceItems={
            props.item.configuration.sourceItems as GlossaryGameContent[]
          }
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.WORD_SCRAMBLE:
      return (
        <WordScrambleSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.WORD_PUZZLE:
      return (
        <WordPuzzleSetting
          sourceItems={
            props.item.configuration.sourceItems as GlossaryGameContent[]
          }
          targetItems={
            props.item.configuration.targetItems as GlossaryGameContent[]
          }
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.FIB_NUMBER:
      return (
        <FibNumberSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.FIB_FRACTION:
      return (
        <FractionSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.FIB_EQUATION:
      return (
        <MathEquationSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.LONG_ADDITION:
      return (
        <LongAdditionSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.LONG_SUBTRACTION:
      return (
        <LongSubtractionSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.LONG_MULTIPLICATION:
      return (
        <LongMultiplicationSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.LONG_DIVISION:
      return (
        <LongDivisionSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.AREA_MULTIPLICATION:
      return (
        <AreaMultiplicationSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.AREA_DIVISION:
      return (
        <AreaDivisionSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.PLACE_VALUE:
      return (
        <PlaceValueSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
    case CompTypeEnum.COORDINATE:
      return (
        <CoordinateSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );

    default:
      return (
        <ComponentCommonSetting
          setting={props.item.setting}
          onSettingChange={props.onChange}
        />
      );
  }
}

export const ComponentSetting = (props: {
  item: CompProps;
  onChange: (newSetting: any) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [setting, setSetting] = useState<null | any>(null);

  const handleOnChange = (newSetting: any) => {
    setSetting(newSetting);
  };

  const triggerSettingChange = () => {
    dispatch(
      dispatchComposeAction({
        action: ComposeCompActionEnum.default,
        params: props.item.id,
      })
    );
    props.onChange(setting);
  };

  return (
    <ComponentSettingStyle
      className={'view-component-setting'}
      onClick={(e) => e.stopPropagation()}
    >
      <div style={{ minHeight: 200 }}>
        <ComponentSettingItems item={props.item} onChange={handleOnChange} />
      </div>

      <div className={'component-setting-footer'}>
        <ButtonGroup type={'right'}>
          <Space>
            <Button onClick={props.onCancel}>Close</Button>

            <Button type={'primary'} onClick={triggerSettingChange}>
              {t('button.update')}
            </Button>
          </Space>
        </ButtonGroup>
      </div>
    </ComponentSettingStyle>
  );
};
