import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { RequestStatusEnum } from '@services/model/common';

export interface UserSubscriptionRes {
  userSubscriptionId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  company: string;
  subject: string;
  status: RequestStatusEnum;
  createdDate: string;
  modifiedDate: string;
}

export interface CreateSubscriptionReq {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
  company: string;
  subject: string;
}

export const UserSubscriptionAPI = createApi({
  reducerPath: 'UserSubscriptionAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<UserSubscriptionRes>, PaginationReq>({
      query: (params: PaginationReq) => ({
        url: '/subscriptions',
        method: 'GET',
        params,
      }),
    }),

    findSubscription: builder.query<UserSubscriptionRes, number>({
      query: (userSubscriptionId: number) => ({
        url: `/subscriptions/${userSubscriptionId}`,
        method: 'GET',
      }),
    }),

    readSubscription: builder.mutation<UserSubscriptionRes, number>({
      query: (userSubscriptionId: number) => ({
        url: `/subscriptions/${userSubscriptionId}`,
        method: 'PUT',
      }),
    }),

    deleteSubscription: builder.mutation<UserSubscriptionRes, number>({
      query: (userSubscriptionId: number) => ({
        url: `/subscriptions/${userSubscriptionId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
