import React from 'react';

import CalendarManagement from '@modules/calendar/pages/CalendarManagement';
import { RoleEnum } from '@app/redux/slices/roles';
import MyCalendarPage from '@modules/calendar/pages/MyCalendarPage';
import Authorization from '@utils/authorization';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const MyCalendar = () => {
  const { t } = useTranslation();

  return (
    <>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.CREATE_CALENDAR, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <ManagementLayout
          className={'manage-calendar-page'}
          selectUser={'teacher'}
        >
          <PageHeader
            menu={NavigationMenuEnum.CALENDAR}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('calendar.header')}
            description={t('calendar.header')}
          />
          <CalendarManagement />
        </ManagementLayout>
      </Authorization>

      <Authorization
        type={'ifNotGranted'}
        roles={[RoleEnum.CREATE_CALENDAR, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <ManagementLayout className={'my-calendar-page'}>
          <PageHeader
            menu={NavigationMenuEnum.CALENDAR}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('calendar.header')}
            description={t('calendar.header')}
          />
          <MyCalendarPage />
        </ManagementLayout>
      </Authorization>
    </>
  );
};

export default MyCalendar;
