import { useTranslation } from 'react-i18next';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import {
  DisabledUserReq,
  FilterUserReq,
} from '@modules/users/services/StudentAPI';
import React, { useEffect, useState } from 'react';
import { UserRes } from '@modules/users/services/model';
import TablePagination from '@components/table/TablePagination';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { notification } from 'antd';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import useConfirmModal from '@components/modal/ConfirmModal';
import { CreateStudentUserForm } from '@modules/users/component/users/CreateStudentUserForm';
import { ManageUserActionEnum } from '@modules/users/pages/ManageTeacherPage';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { StudentActionDropdown, StudentInforMobile } from './StudentInfor';
import { UserStudentAPI } from '@modules/users/services/UserStudentAPI';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { InputGroup } from '@components/input/InputGroup';
import { ViewStudentInfo } from '@modules/users/component/users/ViewStudentInfo';
import { ResetUserPasswordForm } from '@modules/users/container/users/ResetUserPasswordForm';
import { EntityWithStatus } from '@components/status/EntityStatus';
import { UserProductLicense } from '@modules/license/containers/UserProductLicense';

export const ManageStudentTable = (props: {
  schoolId?: number;
  reload: { reload: boolean; timestamp: number };
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [params, setParams] = useState<FilterUserReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    keyword: '',
    schoolId:
      props.schoolId != null && props.schoolId > 0 ? props.schoolId : -1,
  });

  const [action, setAction] = useState({
    action: ManageUserActionEnum.default,
    params: -1 as number | UserRes,
  });

  const [findAll, { data, isLoading }] =
    UserStudentAPI.endpoints.findAll.useLazyQuery();

  const [activateUser] = UserStudentAPI.endpoints.activateUser.useMutation({});

  useEffect(() => {
    reloadData();
  }, [params]);

  useEffect(() => {
    if (props.reload && props.reload.reload) {
      reloadData();
    }
  }, [props.reload]);

  const reloadData = () => {
    findAll(params);
  };

  const columns = [
    {
      title: t('label.avatar'),
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
    },
    {
      title: t('label.username'),
      dataIndex: 'user.username',
      key: 'user.username',
      width: '15%',
      sorter: true,
    },
    {
      title: t('label.firstName'),
      dataIndex: 'user.firstName',
      key: 'user.firstName',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'user.lastName',
      key: 'user.lastName',
      sorter: true,
      width: '20%',
    },
    {
      title: t('label.email'),
      dataIndex: 'user.email',
      key: 'user.email',
      width: '20%',
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.phone'),
      dataIndex: 'user.phone',
      key: 'user.phone',
      width: '',
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '',
      align: 'center',
    },
  ];

  const onView = (userId: number) => {
    setAction({ action: ManageUserActionEnum.view_info, params: userId });
  };

  const onEdit = (userId: number) => {
    setAction({ action: ManageUserActionEnum.update, params: userId });
  };

  const onActiveUser = (userId: number, activated: boolean) => {
    confirm(
      activated ? 'warning' : 'danger',
      t('header.confirm'),
      activated
        ? t('student.warning.activated')
        : t('student.warning.disabled'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          const request: DisabledUserReq = {
            userId: userId,
            disabled: activated,
          };

          activateUser(request)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('student.warning.remove_subscribe_success'),
                description: t('student.warning.remove_subscribe_success'),
                placement: 'bottomRight',
              });
              reloadData();
            })
            .catch(() => {
              notification.error({
                message: t('student.warning.remove_subscribe_error'),
                description: t(
                  'student.warning.remove_subscribe_error_message'
                ),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const onResetPassword = (userId: number) => {
    setAction({
      action: ManageUserActionEnum.reset_password,
      params: userId,
    });
  };

  const onViewProductLicense = (user: UserRes) => {
    setAction({
      action: ManageUserActionEnum.view_product_license,
      params: user,
    });
  };

  const processDataRow = (record: UserRes) => {
    return {
      key: record.userId,
      avatar: <UserAvatar src={record.avatar} size={64} />,
      'user.username': (
        <>
          <EntityWithStatus
            status={record.status}
            onClick={() => onView(record.userId)}
          >
            {record.username}
          </EntityWithStatus>

          {record.lastLogin && (
            <InputGroup
              addedClass={'small-group'}
              label={t('label.last_login')}
            >
              <DateAndTimeLabel type="inline" label={record.lastLogin} />
            </InputGroup>
          )}
        </>
      ),
      'user.firstName': record.firstName,
      'user.lastName': record.lastName,
      'user.email': record.email,
      'user.phone': record.phone,
      actions: (
        <StudentActionDropdown
          item={record}
          onView={onView}
          onEdit={onEdit}
          onActiveUser={onActiveUser}
          onResetPassword={onResetPassword}
          onViewProductLicense={onViewProductLicense}
        />
      ),
    };
  };

  const renderMobile = (record: UserRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <StudentInforMobile
          item={record}
          onView={onView}
          onEdit={onEdit}
          onActiveUser={onActiveUser}
          onResetPassword={onResetPassword}
          onViewProductLicense={onViewProductLicense}
        />
      </Card>
    );
  };

  const refreshData = (params: any) => {
    setParams({ ...params });
  };

  const onEditSuccess = () => {
    reloadData();
    onCancelEdit();
  };

  const onCancelEdit = () => {
    setAction({ action: ManageUserActionEnum.default, params: -1 });
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isLoading}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === ManageUserActionEnum.view_info && (
        <CustomModal
          header={t('student.actions.view_info')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ViewStudentInfo
              studentId={action.params as number}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}

      {action.action === ManageUserActionEnum.update && (
        <CustomModal
          header={t('student.actions.update')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateStudentUserForm
              studentId={action.params as number}
              onSuccess={onEditSuccess}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}

      {action.action === ManageUserActionEnum.reset_password && (
        <CustomModal
          header={t('users.actions.reset_password')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ResetUserPasswordForm
              schoolId={props.schoolId}
              userId={action.params as number}
              onSuccess={onEditSuccess}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}

      {action.action === ManageUserActionEnum.view_product_license && (
        <CustomModal
          header={t('users.actions.manage_license')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <UserProductLicense
              schoolId={props.schoolId}
              user={action.params as UserRes}
              onCancel={onCancelEdit}
            />
          }
          onCloseFunc={onCancelEdit}
        />
      )}
    </>
  );
};
