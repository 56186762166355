import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { CompMode } from '@cms/ComponentInteface';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';
import React from 'react';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ReviewStudentExercise = (props: { exerciseId: number }) => {
  return (
    <ViewExercisePage
      type={CompMode.REVIEW_EXERCISE}
      exerciseId={props.exerciseId}
      setting={{
        lessonType: LessonTypeEnum.lesson,
        config: {
          singleMode: false,
          singleQuestionMode: false,
          stepByStep: false,
          layout: LessonTemplateLayoutEnum.responsive,
        },
      }}
    />
  );
};
