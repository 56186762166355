import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { SessionAPI } from '@services/private/SessionAPI';
import { useEffect, useState } from 'react';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { UserThumbnailDisplay } from '@components/avatar/UserDisplayName';
import styled from 'styled-components';
import {
  ClassRoomHost,
  ClassRoomParticipant,
} from '@classroom/context/ClassRoomMode';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';
import { UserInfoRes } from '@modules/users/services/model';

export const CurrentPresentUser = (props: {
  onStopPresent: (uuid: string) => void;
}) => {
  const [viewSessionAttendances, { data, isSuccess }] =
    SessionAPI.endpoints.viewSessionAttendances.useLazyQuery();

  const { presentingIds } = useClassRoomInteractContext();
  const { session } = useClassRoomContext();

  useEffect(() => {
    viewSessionAttendances(session.sessionId);
  }, [session.sessionId]);

  const [presentUser, setPresentUser] = useState<UserInfoRes[]>([]);

  useEffect(() => {
    if (data && isSuccess) {
      if (presentingIds.length > 0) {
        const presentUser = data
          .filter((rc) => {
            return presentingIds.includes(rc.user.uuid);
          })
          .map((rc) => {
            return rc.user;
          });

        setPresentUser(presentUser);
      } else {
        setPresentUser([]);
      }
    }
  }, [data, isSuccess, presentingIds]);

  if (presentUser.length > 0) {
    return (
      <PresentUserStyle className={'present-users'}>
        {presentUser.map((ur) => {
          return (
            <>
              <UserThumbnailDisplay
                size={24}
                key={ur.uuid}
                layout={'row'}
                item={ur}
              />

              <ClassRoomHost>
                <Button
                  type={'primary'}
                  danger
                  shape={'circle'}
                  size={'small'}
                  icon={IconUtils.close}
                  onClick={() => props.onStopPresent(ur.uuid)}
                />
              </ClassRoomHost>
              <ClassRoomParticipant>&nbsp;</ClassRoomParticipant>
            </>
          );
        })}
      </PresentUserStyle>
    );
  } else {
    return null;
  }
};

const PresentUserStyle = styled.div`
  padding: 0.125em;
  border-radius: 1.5em;
  border: 1px solid ${(props) => props.theme.app.primary};
  background: ${(props) => props.theme.app.primary_bgr};

  .ant-btn {
    margin-left: 0.5em;
  }
`;
