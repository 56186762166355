import React, { useEffect, useRef } from 'react';
import { MatchingUtils } from '@cms/comps/interact/matching/MatchingUtils';
import useRaphaelJs from '@cms/script/useRaphaelLib';
import useResizeObserver from '@react-hook/resize-observer';
import { useMatchingCompContext } from '@cms/comps/interact/matching/MatchingCompContext';

export const MatchingComponentBackground = () => {
  const { originalData, onRemoveAnswer, feedback, answers } =
    useMatchingCompContext();
  const connectLineBgr = useRef(null);
  const matchingUtils = useRef(new MatchingUtils());

  useRaphaelJs(() => {
    if (connectLineBgr.current) {
      matchingUtils.current.initBackground(
        originalData.id,
        connectLineBgr.current,
        onRemoveAnswer
      );
    }
  });

  useEffect(() => {
    if (answers) {
      matchingUtils.current.onAnswerChange(answers);
    }
  }, [answers]);

  useResizeObserver(connectLineBgr, (entry) => {
    if (connectLineBgr.current) {
      matchingUtils.current.onSizeChange(
        entry.contentRect.width,
        entry.contentRect.height
      );
    }
  });

  useEffect(() => {
    if (
      feedback &&
      ((feedback.correct && feedback.correct.length > 0) ||
        (feedback.incorrect && feedback.incorrect.length > 0))
    ) {
      matchingUtils.current.onFeedBackChange(
        answers,
        feedback.correct,
        feedback.incorrect
      );
    }
  }, [feedback]);

  return <div className={'comps-matching-bgr'} ref={connectLineBgr} />;
};
