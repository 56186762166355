export enum CoordinateActionEnum {
  DEFAULT = '',

  POINT = 'POINT',
  POINT_LABEL = 'POINT_LABEL',
  CONNECT_LINE = 'CONNECT_LINE',

  LINE = 'LINE',
  VECTOR = 'VECTOR',
  LINEAR = 'LINEAR',
  DASH_LINE = 'DASH_LINE',
  DOT_LINE = 'DOT_LINE',

  CIRCLE = 'CIRCLE',
  ELLIPSE = 'ELLIPSE',
  RECTANGLE = 'RECTANGLE',
  POLYGON = 'POLYGON',

  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  PENCIL = 'PENCIL',
  HIGHLIGHT = 'HIGHLIGHT',

  ZOOM_IN = 'ZOOM-IN',
  ZOOM_OUT = 'ZOOM-OUT',
  MOVING_BGR = 'MOVING_BGR',

  EXPORT = 'EXPORT',

  SELECT = 'SELECT',
  CLONE = 'CLONE',
  REMOVE = 'REMOVE',
  CHANGE_COLOR = 'CHANGE_COLOR',
  RESET = 'RESET',
}
