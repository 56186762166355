import { LessonAPI } from '@modules/product/services/LessonAPI';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { Space } from 'antd';
import React, { useEffect } from 'react';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonDisplayComp = (props: {
  lessonId: number;
  size?: number;
}) => {
  const [getLesson, { data, isSuccess }] =
    LessonAPI.endpoints.getLesson.useLazyQuery({});

  useEffect(() => {
    if (props.lessonId) {
      getLesson(props.lessonId);
    }
  }, [props.lessonId]);

  if (data && isSuccess) {
    return <LessonDisplay size={props.size} item={data} />;
  } else {
    return null;
  }
};

export const LessonDisplay = (props: { size?: number; item: LessonRes }) => {
  return (
    <div className={'lesson-display'}>
      <Space>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.thumbnails}
          ratio={ImageScale.lesson.ratio}
          width={props.size ?? ImageScale.lesson.icon}
        />

        <span>{props.item.name}</span>
      </Space>
    </div>
  );
};
