import { StandardWeightRes } from '@services/reports/LessonReport';
import ColorUtils from '@utils/ColorUtils';
import PieChartReport, {
  PieRecordData,
} from '@modules/reports/charts/pie/PieChart';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import React, { useState } from 'react';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import styled from 'styled-components';
import { H3 } from '@components/typography';
import { StandardSetRes } from '@modules/product/services/standard_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface LessonStandardGroupProps {
  standardSet: StandardSetRes;
  standards: StandardWeightRes[];
}
export const LessonStandardGroupWeight = (props: {
  lesson: LessonRes;
  standards: StandardWeightRes[];
}) => {
  const [lessonStandardGroup] = useState<LessonStandardGroupProps[]>(() => {
    const initArray: LessonStandardGroupProps[] = [];

    const process = (
      initArr: LessonStandardGroupProps[],
      current: StandardWeightRes
    ) => {
      if (
        initArr.length === 0 ||
        initArr[initArr.length - 1].standardSet.standardSetId !==
          current.standard.standardSet.standardSetId
      ) {
        initArr.push({
          standardSet: current.standard.standardSet,
          standards: [current],
        });
      } else {
        initArr[initArr.length - 1].standards.push(current);
      }
      return initArr;
    };

    const sortArray = [...props.standards].sort((stSetA, stSetB) => {
      return (
        stSetA.standard.standardSet.standardSetId * 1000 +
        stSetA.standard.standardSet.displayOrder -
        stSetB.standard.standardSet.standardSetId * 1000 +
        stSetB.standard.standardSet.displayOrder
      );
    });

    sortArray.reduce(process, initArray);

    return initArray;
  });

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Set Chart
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Chart</h3>}
          content={
            <>
              <p>
                Report này thể hiện sự phân bố điểm số của từng Standard trong
                Standard Set trong bài kiểm tra.
              </p>
            </>
          }
        />
      </H3>

      <div className={'lesson-standard-set-group'}>
        {lessonStandardGroup.map((item) => {
          return (
            <LessonStandardGroup
              item={item}
              key={item.standardSet.standardSetId}
            />
          );
        })}
      </div>
    </LessonReportChartStyle>
  );
};

const LessonStandardGroupStyle = styled.div`
  display: inline-flex;
  min-width: 150px;
  max-width: 150px;
  flex-direction: column;
  text-align: center;
  margin: 0.5em;
`;

export const LessonStandardGroup = (props: {
  item: LessonStandardGroupProps;
}) => {
  const renderFunc = (data: StandardWeightRes): PieRecordData => {
    return {
      id: data.standard.code,
      label: data.standard.code,
      value: data.weight,
      color: ColorUtils.getDefaultColor(data.standard.standardId).background,
    };
  };

  const customGetColor = (params: { id: string; data: PieRecordData }) => {
    return params.data.color;
  };

  return (
    <LessonStandardGroupStyle className={'lesson-standard-set-group'}>
      <PieChartReport
        className={'lesson-standard'}
        content={props.item.standards}
        size={150}
        render={renderFunc}
        getColor={customGetColor}
      />
      <h5>{props.item.standardSet.name}</h5>
    </LessonStandardGroupStyle>
  );
};
