import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfileAPI } from '@services/private/UserProfileAPI';
import Card from '@components/card';
import ButtonGroup from '@components/button/ButtonGroup';
import { Button, notification } from 'antd';

export default function UserProfileReview(props: {
  onSuccess: (name: string) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const [getUserProfile, { data, isSuccess }] =
    UserProfileAPI.endpoints.getUserProfile.useLazyQuery({});

  const [publishProfile, { isLoading }] =
    UserProfileAPI.endpoints.publishProfile.useMutation({});

  useEffect(() => {
    getUserProfile({});
  }, []);

  const handlePublishProfile = () => {
    publishProfile({})
      .unwrap()
      .then(() => {
        notification.success({
          message: t('alert.update_success'),
          description: t('alert.update_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(data!.name);
      })
      .catch(() => {
        notification.error({
          message: t('alert.update_error'),
          description: t('alert.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <Card>
      <div>{data && isSuccess && JSON.stringify(data)}</div>
      <div
        className="d-flex submit-container"
        style={{ justifyContent: 'space-between' }}
      >
        <ButtonGroup>
          <Button size={'large'} type="default" onClick={props.onCancel}>
            {t('button.cancel')}
          </Button>
        </ButtonGroup>

        <ButtonGroup type={'right'}>
          <Button
            type={'primary'}
            size={'large'}
            loading={isLoading}
            onClick={handlePublishProfile}
          >
            {t('label.publish')}
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  );
}
