import React from 'react';
import styled from 'styled-components';
import { useDragNDropInlineCompContext } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import { DnDDropInlineGroup } from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineDropGroup';
import { DnDInlineClickGroup } from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineClickGroup';

const DnDInlineDropItems = () => {
  const { targetItems } = useDragNDropInlineCompContext();

  return (
    <DnDDropItemsStyle className={'dnd-droppable-items'}>
      {targetItems &&
        targetItems.map((drop, index) => {
          return <DnDDropInlineGroup drop={drop} index={index} />;
        })}
    </DnDDropItemsStyle>
  );
};

export default DnDInlineDropItems;

export const DnDInlineSelectTargetItems = () => {
  const { targetItems } = useDragNDropInlineCompContext();

  return (
    <DnDDropItemsStyle className={'dnd-droppable-items'}>
      {targetItems &&
        targetItems.map((drop, index) => {
          return <DnDInlineClickGroup drop={drop} index={index} />;
        })}
    </DnDDropItemsStyle>
  );
};

const DnDDropItemsStyle = styled.div`
  .comps-dnd-drag-option {
    &.correct-part {
      color: ${(props) => props.theme.component.correct};
    }

    &.incorrect-part {
      color: ${(props) => props.theme.component.incorrect};
    }
  }

  .cms-content-actions {
    margin-top: var(--cms-padding-option, 0.5em);

    .cms-content-display {
      width: 100%;
      position: relative;
      padding-right: 30px;

      .edit-content-group {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }

  .dnd-inline-group {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    line-height: 1.6;

    .dnd-inline-text {
      padding: 0.25em 0em;
    }

    .droppable-items {
      padding: 0.25em 0.25em;
      border-radius: 0.25em;
      min-width: 5em;
      border: 2px dashed #ccc;
      display: inline-block;

      .drag-item {
        border: none;
      }
    }
  }
`;
