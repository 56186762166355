import { MyProfileAPI } from '@services/private/MyProfileAPI';
import { useEffect } from 'react';

import { updateUserInfo } from '@app/redux/slices/userInfomationSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { updateDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { changeTheme } from '@app/redux/slices/appLayoutSlice';
import {
  changeToLogout,
  getAccessTokenData,
  login,
} from '@app/redux/slices/profileSlice';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';

const useRefreshUserData = () => {
  const dispatch = useDispatch();
  const { defaultLanguage } = useSelector((state) => state.appLanguage);

  const [
    getLoginData,
    {
      data: loginData,
      isSuccess: loginSuccess,
      error: loginError,
      isError: loginIsError,
    },
  ] = MyProfileAPI.endpoints.getLoginData.useLazyQuery();

  const [getMyProfile, { data: profileData, isSuccess: profileSuccess }] =
    MyProfileAPI.endpoints.getMyProfile.useLazyQuery();

  const { isAuth } = useSelector((state) => state.profile);

  useEffect(() => {
    // if is auth -> get user profile...
    if (isAuth) {
      getMyProfile({});

      // if not : check access token.
    } else {
      const accessToken = getAccessTokenData();

      // if access token is provided, get login data again...
      if (accessToken) {
        getLoginData({});
      }
    }
  }, [isAuth, getAccessTokenData()]);

  useEffect(() => {
    if (loginError && loginIsError) {
      dispatch(changeToLogout());
    } else if (loginData && loginSuccess) {
      const payload = {
        access_token: loginData.access_token,
        refresh_token: loginData.refresh_token,
        uuid: loginData.uuid,
        roles: loginData.roles,
        schoolId: loginData.schoolId,
      };

      dispatch(login(payload));
      dispatch(refreshExpiredData(loginData.expires_in));
    }
  }, [loginData, loginError]);

  useEffect(() => {
    if (profileData && profileSuccess) {
      if (
        profileData.language != null &&
        profileData.language.code !== defaultLanguage
      ) {
        dispatch(updateDefaultLanguage(profileData.language.code));
      }
      dispatch(changeTheme(profileData.theme));
      dispatch(updateUserInfo(profileData));
    }
  }, [profileData, profileSuccess]);
};

export default useRefreshUserData;
