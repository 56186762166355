import ButtonGroup from '@components/button/ButtonGroup';
import { Button } from 'antd';
import React from 'react';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const SectionToolbarSkeleton = (props: { stepByStep: boolean }) => {
  const { t } = useTranslation();

  return (
    <SectionToolbarSkeletonStyle className={'section-skeleton-toolbar'}>
      {props.stepByStep ? (
        <ButtonGroup type={'center'}>
          <Button
            size={'small'}
            shape={'round'}
            disabled
            icon={IconUtils.exercise.check_answer}
          >
            {t('exercise.button.check_answer')}
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup type={'space-between'}>
          <Button size={'small'} shape={'round'} disabled>
            {IconUtils.actions.previous} {t('button.pagination.previous')}
          </Button>

          <Button size={'small'} shape={'round'} disabled>
            {t('button.step.next')} {IconUtils.actions.next}
          </Button>
        </ButtonGroup>
      )}
    </SectionToolbarSkeletonStyle>
  );
};

const SectionToolbarSkeletonStyle = styled.div`
  margin-top: var(--cms-padding-section, 1.5em);
`;
