import { ClassRoomToolbar } from '@classroom/container/ClassRoomToolbar';
import React from 'react';
import styled from 'styled-components';
import { ClassRoomHeader } from '@classroom/container/ClassRoomHeader';
import { ClassRoomContent } from '@classroom/container/ClassRoomContent';
import { ClassRoomChatBoxModal } from '@classroom/container/chat-message/ClassRoomChatBox';

export const ClassRoomWorkspace = () => {
  return (
    <ClassRoomWorkspaceStyle className={'class-room-container'}>
      <ClassRoomHeader />
      <ClassRoomContent />
      <ClassRoomToolbar />

      <ClassRoomChatBoxModal />
    </ClassRoomWorkspaceStyle>
  );
};

const ClassRoomWorkspaceStyle = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  .class-room-content {
    flex-grow: 1;
    padding: 2em;
    overflow-y: auto;
  }
`;
