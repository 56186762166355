import { StandardRes } from '@modules/product/services/standard_model';
import { InputGroup } from '@components/input/InputGroup';
import { StandardTag } from '@modules/product/components/standards/StandardSetDisplay';
import { H4 } from '@components/typography';
import { LanguageContentTag } from '@components/text/LanguageTag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'antd';
import { StandardSetAction } from '@modules/product/container/standards/useManageStandardSet';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';

export const StandardItem = (props: {
  item: StandardRes;
  onClick: (item: StandardRes, action: StandardSetAction) => void;
}) => {
  const { t } = useTranslation();
  return (
    <StandardItemStyle className={'standard-item'}>
      <div className={'standard-item-display'}>
        <InputGroup label={<StandardTag item={props.item} />}>
          <H4>{props.item.name}</H4>
        </InputGroup>

        <InputGroup label={t('label.description')}>
          <LanguageContentTag multiple content={props.item.description} />
        </InputGroup>
      </div>

      <div className={'standard-actions'}>
        <Dropdown
          menu={{
            items: [
              {
                key: StandardSetAction.update_standard,
                icon: IconUtils.actions.edit,
                label: t('standard.actions.edit'),
                onClick: () =>
                  props.onClick(props.item, StandardSetAction.update_standard),
              },
              {
                key: StandardSetAction.delete_standard,
                icon: IconUtils.actions.delete,
                label: t('standard.actions.remove'),
                onClick: () =>
                  props.onClick(props.item, StandardSetAction.delete_standard),
              },
            ],
          }}
          arrow
          trigger={['click']}
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      </div>
    </StandardItemStyle>
  );
};

const StandardItemStyle = styled.div`
  position: relative;
  width: 100%;

  .standard-actions {
    position: absolute;
    top: 0;
    right: 0px;
  }
`;
