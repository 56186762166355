import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { StoreProvider } from '@app/redux/StoreProvider';
import { I18nProvider } from '@app/i18n/I18nProvider';
import { StyledProvider } from '@app/styled/StyledProvider';
import App from './App';

import 'antd/dist/reset.css';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider>
        <I18nProvider>
          <StyledProvider>
            <App />
          </StyledProvider>
        </I18nProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
