import {
  LongOperationLayoutEnum,
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import Button from '@components/button';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { SyncOutlined } from '@ant-design/icons';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { LongOperationTableEditable } from '../table/LongOperationTableEditable';
import { LongEditableStyle } from '../addition/LongAdditionEditable';
import Input from 'antd/lib/input/Input';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { useTranslation } from 'react-i18next';

export const PlaceValueEditable = (props: {
  numbers: string;
  expression: OperationExpProps[];
  onChange: (
    numbers: string,
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [numbers, setNumbers] = useState(props.numbers);
  const [expression, setExpression] = useState<OperationExpProps[]>([]);
  const [answerExpression, setAnswerExpression] = useState<OperationExpProps[]>(
    []
  );

  useEffect(() => {
    const answer = OperationUtils.parsePlaceValue(props.numbers);
    setNumbers(props.numbers);
    setAnswerExpression(answer.results);
  }, [props.numbers]);

  useEffect(() => {
    setExpression([...props.expression]);
  }, [props.expression]);

  const handleOnExpressionChange = (
    index: number,
    expression: OperationExpProps
  ) => {
    setExpression((prev) => {
      return COMPONENT_UTILS.updateAtIndex(prev, index, expression);
    });
  };

  const handleOnChange = (val: string) => {
    setNumbers(val);
  };

  const onUpdateEditableData = () => {
    props.onChange(numbers, expression, answerExpression);
  };

  const handleOnClick = () => {
    const addendArrays = OperationUtils.parsePlaceValue(numbers);
    setExpression(addendArrays.results);
    setAnswerExpression(addendArrays.results);
  };

  return (
    <>
      <LongEditableStyle className={'place-value-editable'}>
        <Row>
          <Col flex={'200px'}>
            <InputGroup label={t('component.label.number')} key={'number '}>
              <Input
                name="numbers"
                type="number"
                onChange={(evt) => handleOnChange(evt.target.value)}
                value={numbers}
              />
            </InputGroup>
          </Col>

          <Col flex={'100px'}>
            <div className={'button-center-group'}>
              <Button shape={'circle'} onClick={handleOnClick}>
                <SyncOutlined />
              </Button>
            </div>
          </Col>

          <Col flex={'auto'}>
            <LongOperationTableEditable
              layout={LongOperationLayoutEnum.place_value}
              operation={MathOperationEnum.place_value}
              expression={expression}
              answerExpression={answerExpression}
              onChange={handleOnExpressionChange}
            />
          </Col>
        </Row>
      </LongEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={onUpdateEditableData}
      />
    </>
  );
};
