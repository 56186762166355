import React, { ReactNode } from 'react';
import { PermissionTableStyle } from '@modules/users/component/permission/PermissionTableForm';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

enum RolePermission {
  A = 'access', // ACCESS only
  R = 'role', // UPDATE WHEN HAS ROLE
  F = 'full', // ACCESS AND UPDATE
  E = 'empty',
}

const Role = (props: { permission?: RolePermission }) => {
  if (props.permission) {
    return (
      <RoleDisplayStyle className={`permission-role ${props.permission}`}>
        {props.permission.substring(0, 1)}
      </RoleDisplayStyle>
    );
  } else {
    return (
      <RoleDisplayStyle className={'permission-role no-access'}>
        &nbsp;
      </RoleDisplayStyle>
    );
  }
};

const RoleDisplayStyle = styled.span`
  border-radius: 1em;
  width: 1.6em;
  height: 1.6em;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;

  &.access {
    background: #f58220;
  }

  &.role {
    background: #0474bb;
  }

  &.full {
    background: #00a14b;
  }

  &.empty {
    background: #ccc;
  }
`;

export const RoleRow = (props: {
  label: ReactNode;
  permission: RolePermission[];
}) => {
  return (
    <tr>
      <td>{props.label}</td>
      <td>
        <Role permission={props.permission[0]} />
      </td>
      <td>
        <Role permission={props.permission[1]} />
      </td>
      <td>
        <Role permission={props.permission[2]} />
      </td>
      <td>
        <Role permission={props.permission[3]} />
      </td>
      <td>
        <Role permission={props.permission[4]} />
      </td>
    </tr>
  );
};
export const RoleDescriptionTable = () => {
  const { t } = useTranslation();

  return (
    <PermissionTableStyle className={'role-description-table'}>
      <div className={'direction-group'}>
        <div className={'direction-item'}>
          <Role permission={RolePermission.E} /> No access
        </div>

        <div className={'direction-item'}>
          <Role permission={RolePermission.A} /> Access only
        </div>

        <div className={'direction-item'}>
          <Role permission={RolePermission.R} /> Can update if has Roles
        </div>

        <div className={'direction-item'}>
          <Role permission={RolePermission.F} /> Can Access and Update
        </div>
      </div>

      <table
        className={'permission-table'}
        style={{ width: '100%', textAlign: 'center' }}
        border={1}
      >
        <thead>
          <tr>
            <td style={{ borderTop: 'none', borderLeft: 'none' }} />
            <th style={{ width: 100 }}>Student</th>
            <th style={{ width: 100 }}>Parent</th>
            <th style={{ width: 100 }}>Teacher</th>
            <th style={{ width: 100 }}>Support</th>
            <th style={{ width: 100 }}>Admin</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th className={'group-empty'} colSpan={6}>
              Users
            </th>
          </tr>

          <RoleRow
            label={'Student'}
            permission={[
              RolePermission.E,
              RolePermission.F,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Parent'}
            permission={[
              RolePermission.E,
              RolePermission.R,
              RolePermission.E,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Teacher'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.E,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Support'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.E,
              RolePermission.E,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Admin'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.E,
              RolePermission.E,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Class'}
            permission={[
              RolePermission.A,
              RolePermission.A,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Course'}
            permission={[
              RolePermission.A,
              RolePermission.A,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <tr>
            <th className={'group-empty'} colSpan={6}>
              Book And Licenses
            </th>
          </tr>

          <RoleRow
            label={'Lesson'}
            permission={[
              RolePermission.A,
              RolePermission.E,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Book'}
            permission={[
              RolePermission.A,
              RolePermission.E,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Publish Book'}
            permission={[
              RolePermission.A,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={t('license.actions.assign_license')}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <tr>
            <th className={'group-empty'} colSpan={6}>
              Assignment
            </th>
          </tr>

          <RoleRow
            label={'Assignment'}
            permission={[
              RolePermission.F,
              RolePermission.A,
              RolePermission.F,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Assignment Report'}
            permission={[
              RolePermission.A,
              RolePermission.A,
              RolePermission.F,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <tr>
            <th className={'group-empty'} colSpan={6}>
              Session And ClassRoom
            </th>
          </tr>

          <RoleRow
            label={'Session'}
            permission={[
              RolePermission.A,
              RolePermission.A,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Class Room'}
            permission={[
              RolePermission.A,
              RolePermission.A,
              RolePermission.R,
              RolePermission.F,
              RolePermission.F,
            ]}
          />

          <tr>
            <th className={'group-empty'} colSpan={6}>
              Page Management
            </th>
          </tr>

          <RoleRow
            label={'Design Page'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Publish Page'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Design Page layout'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <tr>
            <th className={'group-empty'} colSpan={6}>
              School Setting And Payment
            </th>
          </tr>

          <RoleRow
            label={'School Setting (language, register ...)'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Payment'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />

          <RoleRow
            label={'Email Template'}
            permission={[
              RolePermission.E,
              RolePermission.E,
              RolePermission.R,
              RolePermission.R,
              RolePermission.F,
            ]}
          />
        </tbody>
      </table>
    </PermissionTableStyle>
  );
};
