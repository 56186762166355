import { PublicSchoolRes } from '@services/private/SchoolAPI';
import React from 'react';
import { Col, Row } from 'antd';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import styled from 'styled-components';
import { PublishTableOfContents } from '../components/table-of-content/TableOfContent';
import { ProductInfo } from '@modules/public/components/products/ProductInfo';
import { H3 } from '@components/typography';
import ContainerScale from '@components/Layout/ContainerScale';
import { useTranslation } from 'react-i18next';
import {
  ProductRes,
  ProductTocRes,
} from '@modules/product/services/product_model';

export const ProductDetailPage = (props: {
  product: ProductRes;
  author: UserProfileRes;
  school: PublicSchoolRes;
  tableOfContent: ProductTocRes[];
}) => {
  const { t } = useTranslation();

  return (
    <PublicProductDetailStyle className={'product-info-detail'}>
      <Row gutter={40}>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={17}>
          <ProductInfo product={props.product} author={props.author} />
        </Col>
      </Row>

      <Row gutter={40}>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={17}>
          <div className={'product-info-group'}>
            <div className={'product-introduction'}>
              {props.product.video ? (
                <ContainerScale scale={9 / 16} minHeight={300}>
                  <iframe
                    title={props.product.name}
                    className="video-instruction content-scale"
                    width="100%"
                    height="100%"
                    src={props.product.video}
                  />
                </ContainerScale>
              ) : (
                <span>{props.product.thumbnails}</span>
              )}
            </div>
          </div>

          <div className={'product-info-group'}>
            <H3>{t('publish.product.about')}</H3>

            <div
              className={'product-introduction'}
              dangerouslySetInnerHTML={{ __html: props.product.instruction }}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={7}>
          <PublishTableOfContents
            items={props.tableOfContent}
            product={props.product}
          />
        </Col>
      </Row>
    </PublicProductDetailStyle>
  );
};

const PublicProductDetailStyle = styled.div`
  font-size: 16px;

  .product-introduction {
    font-size: 16px;
  }

  .product-info-group {
    margin-bottom: 1.5em;
  }
`;
