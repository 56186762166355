import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import StudentLessonGroup from '@modules/product/container/student/StudentLessonGroup';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const MyLibraryLessons = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-lessons-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.LIBRARY_LESSONS}
        title={t('lesson.my_lesson')}
        description={t('lesson.my_lesson')}
      />

      <StudentLessonGroup />
    </ManagementLayout>
  );
};

export default MyLibraryLessons;
