import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { CreateSubjectReq, SubjectRes } from '@modules/admin/service/model';

export const SubjectAPI = createApi({
  reducerPath: 'SubjectAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<SubjectRes>, unknown>({
      query: () => ({
        url: `/subjects/find-all`,
      }),
    }),

    findById: builder.query<SubjectRes, number>({
      query: (subjectId) => ({
        url: `/subjects/${subjectId}`,
        method: 'GET',
      }),
    }),

    createSubject: builder.mutation<SubjectRes, CreateSubjectReq>({
      query: (body: CreateSubjectReq) => ({
        url: '/subjects',
        method: 'POST',
        body,
      }),
    }),

    updateSubject: builder.mutation<SubjectRes, CreateSubjectReq>({
      query: (body: CreateSubjectReq) => ({
        url: `/subjects/${body.subjectId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteSubject: builder.mutation<BasicMessageRes, number>({
      query: (subjectId) => ({
        url: `/subjects/${subjectId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
