import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import { PageRouterProps } from '@router/modules/props';

const ManageCategory = React.lazy(
  () => import('@router/pages/program/categories')
);
const ManageGrade = React.lazy(() => import('@router/pages/program/grade'));
const ManageSubject = React.lazy(() => import('@router/pages/program/subject'));
const ManageProgram = React.lazy(() => import('@router/pages/program/program'));
const ManageCertificate = React.lazy(
  () => import('@router/pages/program/certificate')
);

export const ProgramRouters: PageRouterProps[] = [
  {
    path: SiteMap.program.program,
    element: (
      <PrivateRouter
        roles={SiteMap.program.roles}
        element={<ManageProgram />}
      />
    ),
  },

  {
    path: SiteMap.program.certificate,
    element: (
      <PrivateRouter
        roles={SiteMap.program.roles}
        element={<ManageCertificate />}
      />
    ),
  },

  {
    path: SiteMap.program.categories,
    element: (
      <PrivateRouter
        roles={SiteMap.program.roles}
        element={<ManageCategory />}
      />
    ),
  },
  {
    path: SiteMap.program.grades,
    element: (
      <PrivateRouter roles={SiteMap.program.roles} element={<ManageGrade />} />
    ),
  },

  {
    path: SiteMap.program.subjects,
    element: (
      <PrivateRouter
        roles={SiteMap.program.roles}
        element={<ManageSubject />}
      />
    ),
  },
];
