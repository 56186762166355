import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  getDefaultPageStyle,
  getDefaultTemplateStyle,
  getPresentationTemplate,
  LessonTemplateLayout,
} from '@cms/design-template/layout/LessonTemplateLayoutEditable';
import { getDefaultTemplateConfig } from '@cms/design-template/layout/DesignTemplateContext';
import {
  LessonTemplateConfigProps,
  LessonTemplateSettingProps,
} from '@cms/design-template/layout/props';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const getLessonConfig = (lesson: LessonRes) => {
  if (lesson.lessonTemplate != null) {
    return {
      singleMode: lesson.lessonTemplate.singleMode,
      singleQuestionMode: lesson.lessonTemplate.singleQuestionMode,
      stepByStep: lesson.lessonTemplate.stepByStep,
      layout: lesson.lessonTemplate.layout,
    };
  } else {
    const config = getDefaultTemplateConfig();

    if (lesson.type) {
      if (lesson.type === LessonTypeEnum.presentation) {
        return {
          singleMode: true,
          singleQuestionMode: false,
          stepByStep: false,
          layout: config.layout,
        };
      } else {
        return {
          singleMode: true,
          singleQuestionMode: true,
          stepByStep: false,
          layout: config.layout,
        };
      }
    } else {
      return config;
    }
  }
};

export const getLessonSetting = (
  lesson: LessonRes,
  type: 'page' | 'lesson'
) => {
  if (
    lesson.lessonTemplate != null &&
    lesson.lessonTemplate.content != null &&
    lesson.lessonTemplate.content !== ''
  ) {
    return JSON.parse(lesson.lessonTemplate.content);
  } else if (lesson.type === LessonTypeEnum.presentation) {
    return getPresentationTemplate();
  } else {
    if (type === 'page') {
      return getDefaultPageStyle();
    } else {
      return getDefaultTemplateStyle();
    }
  }
};

const LessonTemplateContext = createContext({
  title: '' as string,
  config: {} as LessonTemplateConfigProps,
  setting: {} as LessonTemplateSettingProps,
});

export const LessonTemplateLayoutProvider = (props: {
  lesson?: LessonRes | null;
  config?: LessonTemplateConfigProps;
  children: any;
}) => {
  const title = useMemo(() => {
    return props.lesson ? props.lesson.name : '';
  }, [props.lesson]);

  const config = useMemo(() => {
    if (props.config) {
      return props.config;
    } else {
      return props.lesson
        ? getLessonConfig(props.lesson)
        : getDefaultTemplateConfig();
    }
  }, [props.lesson, props.config]);

  const setting = useMemo(() => {
    return props.lesson
      ? getLessonSetting(props.lesson, 'lesson')
      : getDefaultTemplateStyle();
  }, [props.lesson]);

  return (
    <LessonTemplateContext.Provider
      value={{
        title: title,
        config: config,
        setting: setting,
      }}
    >
      {props.children}
    </LessonTemplateContext.Provider>
  );
};

export const LessonTemplateWrapper = (props: { children: ReactNode }) => {
  const { title, config, setting } = useLessonTemplateContext();
  return (
    <LessonTemplateLayout config={config} title={title} setting={setting}>
      {props.children}
    </LessonTemplateLayout>
  );
};

export const useLessonTemplateContext = () => {
  const context = useContext(LessonTemplateContext);
  if (!context) {
    throw new Error('You must wrap container by LessonTemplateLayoutProvider');
  }
  return context;
};
