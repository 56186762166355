import { useTranslation } from 'react-i18next';
import { TeacherAssignmentAPI } from '@modules/assignments/service/TeacherAssignmentAPI';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import AssignmentActionTeacher from '@components/common/assignment/teacher/AssignmentTeacherActions';
import {
  AssigmentInstanceStatus,
  AssignmentProgressStatus,
} from './AssigmentInstanceStatus';
import { H5 } from '@components/typography';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import styled from 'styled-components';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { AssignmentMobileItem } from '@components/common/assignment/teacher/AssignmentMobileItem';
import { useSelectUser } from '@hooks/useSelectUser';
import {
  AssignmentInstanceStatusRes,
  FilterAssignmentReq,
} from '@modules/assignments/service/assignment_model';

export const TeacherAssignmentTable = (props: {
  action?: 'refresh' | string;
  classId?: number;
}) => {
  const { t } = useTranslation();

  const [filterAssignments, { data, isFetching }] =
    TeacherAssignmentAPI.endpoints.filterAssignments.useLazyQuery();

  const selectUserId = useSelectUser();

  const [params, setParams] = useState<FilterAssignmentReq>({
    size: MAX_PAGE_SIZE,
    forUserId: selectUserId,
    classId: props.classId ?? undefined,
  });

  useEffect(() => {
    reloadData();
  }, [params]);

  useEffect(() => {
    if (props.action && props.action === 'refresh') {
      reloadData();
    }
  }, [props.action]);

  useEffect(() => {
    setParams((prev) => {
      return { ...prev, forUserId: selectUserId };
    });
  }, [selectUserId]);

  const onPageChange = (params: PaginationReq) => {
    setParams((prevState) => {
      return {
        ...prevState,
        params,
      };
    });
  };

  const reloadData = useCallback(() => {
    filterAssignments(params);
  }, [params]);

  const columns = [
    {
      title: t('assignment_group.header'),
      dataIndex: 'assignmentInstance.assignment.assignmentGroup.name',
      key: 'assignmentInstance.assignment.assignmentGroup.name',
      width: '20%',
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'assignmentInstance.name',
      key: 'assignmentInstance.name',
      sorter: true,
    },
    {
      title: t('assignment.unlockAt'),
      dataIndex: 'assignmentInstance.unlockAt',
      key: 'assignmentInstance.unlockAt',
      width: 160,
      align: 'center',
      sorter: true,
    },
    {
      title: t('assignment.lockAt'),
      dataIndex: 'assignmentInstance.lockAt',
      key: 'assignmentInstance.lockAt',
      sorter: true,
      width: 160,
      align: 'center',
    },

    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      align: 'center',
    },
  ];

  const processDataRow = (item: AssignmentInstanceStatusRes) => {
    return {
      key: item.assignmentInstanceStatusId,
      'assignmentInstance.assignment.assignmentGroup.name': (
        <>{item.assignment?.assignmentGroup?.name}</>
      ),
      'assignmentInstance.name': <AssignmentName item={item} />,
      'assignmentInstance.unlockAt': (
        <DateAndTimeLabel label={item.assignmentInstance.unlockAt} />
      ),
      'assignmentInstance.lockAt': (
        <DateAndTimeLabel label={item.assignmentInstance.lockAt} />
      ),
      actions: <AssignmentActionTeacher item={item} />,
    };
  };

  const renderMobile = (item: AssignmentInstanceStatusRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssignmentMobileItem item={item} />
      </Card>
    );
  };

  return (
    <TablePagination
      params={params}
      isLoading={isFetching}
      columns={columns}
      data={data}
      refresh={onPageChange}
      processDataRow={processDataRow}
      responsive={{
        screen: [ResponsiveScreen.xs, ResponsiveScreen.sm, ResponsiveScreen.md],
        render: renderMobile,
      }}
    />
  );
};

enum AssignmentNameAction {
  default = '',
  show_modal = 'show-modal',
}

const AssignmentNameStyle = styled.div`
  cursor: pointer;

  h5 {
    margin-bottom: 0em;
  }

  .ant-progress-inner {
    background: ${(props) => props.theme.app.primary_bgr};
  }
`;

export const AssignmentName = (props: {
  item: AssignmentInstanceStatusRes;
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState({
    action: AssignmentNameAction.default,
    timestamp: 0,
  });

  const dispatchAction = (action: AssignmentNameAction) => {
    setAction({
      action: action,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  return (
    <>
      <AssignmentNameStyle
        className={'assignment-description'}
        onClick={() => dispatchAction(AssignmentNameAction.show_modal)}
      >
        <H5>{props.item.assignmentInstance.name}</H5>

        <AssigmentInstanceStatus item={props.item} />
      </AssignmentNameStyle>

      {action.action === AssignmentNameAction.show_modal && (
        <CustomModal
          header={t('assignment_report.header')}
          className={CustomModalClassEnum.full_size_modal}
          content={<AssignmentProgressStatus item={props.item} />}
          onCloseFunc={() => dispatchAction(AssignmentNameAction.default)}
        />
      )}
    </>
  );
};
