import React, { useEffect, useState } from 'react';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { IconUtils } from '@utils/IconUtils';
import { ClassOverview } from '@modules/users/component/classes/ClassOverview';
import { ClassAssignment } from '@modules/users/component/classes/ClassAssignment';
import { ClassSession } from '@modules/users/component/classes/ClassSession';
import { ClassDiscussion } from '@modules/users/component/classes/ClassDiscussion';
import { ClassRoster } from '@modules/users/component/classes/ClassRoster';

import Card, { CardClassEnum } from '@components/card';
import { ClassReports } from '@modules/users/component/classes/ClassReports';
import { ClassAPI } from '@modules/users/services/ClassAPI';
// import { ClassProgram } from '@modules/users/component/classes/ClassProgram';
// import { ClassCertificate } from '@modules/users/component/classes/ClassCertificate';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';

enum ViewClassModeType {
  overview = 'overview',
  members = 'members',
  assignments = 'assignments',
  discussion = 'discussion',
  session = 'session',
  reports = 'reports',

  program = 'program',
  certificate = 'certificate',
}
export const ViewClassInformation = (props: { classId: number }) => {
  const { ifAnyGranted } = useSecurity();
  const [getClassInfo, { data }] =
    ClassAPI.endpoints.getClassInfo.useLazyQuery();

  const [viewMode, setViewMode] = useState(ViewClassModeType.overview);

  useEffect(() => {
    if (props.classId != null && props.classId > 0) {
      getClassInfo(props.classId);
    }
  }, [props.classId]);

  const handleOnChange = (tab: string) => {
    setViewMode(tab as ViewClassModeType);
  };

  return (
    <>
      {data && (
        <ResponsiveTabs
          activeKey={viewMode}
          onChange={handleOnChange}
          items={[
            {
              key: ViewClassModeType.overview,
              icon: IconUtils.view_info,
              label: 'Class Info',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassOverview item={data} />
                </Card>
              ),
            },
            {
              key: ViewClassModeType.session,
              icon: IconUtils.menu.calendar,
              label: 'Session',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassSession classId={props.classId} />
                </Card>
              ),
            },
            {
              key: ViewClassModeType.assignments,
              icon: IconUtils.actions.assign,
              label: 'Assignments',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassAssignment classId={props.classId} />
                </Card>
              ),
            },
            {
              key: ViewClassModeType.discussion,
              icon: IconUtils.class_room.chatting,
              label: 'Discussion',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassDiscussion classId={props.classId} />
                </Card>
              ),
            },
            {
              key: ViewClassModeType.reports,
              icon: IconUtils.actions.report,
              label: 'Reports',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassReports classId={props.classId} />
                </Card>
              ),
              visible: ifAnyGranted([
                RoleEnum.TEACHER,
                RoleEnum.SUPPORT,
                RoleEnum.SCHOOL,
              ]),
            },
            {
              key: ViewClassModeType.members,
              icon: IconUtils.users,
              label: 'Members',
              children: (
                <Card className={CardClassEnum.round_box}>
                  <ClassRoster classId={props.classId} />
                </Card>
              ),
              visible: ifAnyGranted([
                RoleEnum.TEACHER,
                RoleEnum.SUPPORT,
                RoleEnum.SCHOOL,
              ]),
            },
            // {
            //   key: ViewClassModeType.program,
            //   icon: IconUtils.menu.program,
            //   label: 'Programs',
            //   children: (
            //     <Card className={CardClassEnum.round_box}>
            //       <ClassProgram item={data} />
            //     </Card>
            //   ),
            // },
            // {
            //   key: ViewClassModeType.certificate,
            //   icon: IconUtils.menu.certificate,
            //   label: 'Certificate',
            //   children: (
            //     <Card className={CardClassEnum.round_box}>
            //       <ClassCertificate item={data} />
            //     </Card>
            //   ),
            // },
          ]}
        />
      )}
    </>
  );
};
