import React, { useEffect, useState } from 'react';
import {
  SocialVideoAnsProps,
  SocialVideoPlayerProps,
  SocialVideoProps,
} from '@cms/comps/presentation/social-video/SocialVideoComp';
import { SocialMediaPlayerWorkSpace } from '@cms/comps/presentation/social-video/SocialMediaPlayeWorkspacer';
import styled from 'styled-components';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { CompMode, CompProps } from '@cms/ComponentInteface';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { PlusOutlined } from '@ant-design/icons';
import { SocialMediaEditForm } from '@cms/comps/presentation/social-video/SocialMediaEditForm';
import { updateComponent } from '@app/redux/slices/composeResource';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';

enum SocialMediaAction {
  default = '',
  add_media = 'add-media',
}
export const SocialMediaPlayer = (props: {
  disabled?: boolean;
  item: SocialVideoProps;
  answer: SocialVideoAnsProps | null;
  onChange: (newAns: SocialVideoAnsProps) => void;
}) => {
  const dispatch = useDispatch();

  const { mode } = useResourceContext();
  const [action, setAction] = useState({
    action: SocialMediaAction.default,
    params: -1,
  });

  const [multipleLayout, setMultipleLayout] = useState(
    props.item.configuration.sourceItems.length > 1
  );

  const [currentPlayer, setCurrentPlayer] =
    useState<SocialVideoPlayerProps | null>(() => {
      if (props.item.configuration.sourceItems.length > 0) {
        return props.item.configuration.sourceItems[0];
      } else {
        return null;
      }
    });

  useEffect(() => {
    if (currentPlayer) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: currentPlayer.id,
      });
    }
  }, [currentPlayer?.id]);

  useEffect(() => {
    setMultipleLayout(props.item.configuration.sourceItems.length > 1);
  }, [props.item]);

  const onAddVideo = () => {
    setAction({
      action: SocialMediaAction.add_media,
      params: 1,
    });
    setCurrentPlayer(null);
  };

  const onEditVideo = (video: SocialVideoPlayerProps) => {
    setAction({
      action: SocialMediaAction.add_media,
      params: 1,
    });
    setCurrentPlayer(video);
  };

  const onRemoveVideo = (video: SocialVideoPlayerProps) => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: [...props.item.configuration.sourceItems].filter((sr) => {
          return sr.id !== video.id;
        }),
      };
    });
    dispatch(updateComponent(newComps));

    if (currentPlayer != null && video.id === currentPlayer.id) {
      setCurrentPlayer(null);
    }
  };

  const handleOnCancel = () => {
    setAction({
      action: SocialMediaAction.default,
      params: 1,
    });
  };

  const handleOnCreateOrUpdateVideo = (
    data: SocialVideoPlayerProps,
    type: 'create' | 'update'
  ) => {
    if (type === 'create') {
      const newComps: CompProps = produce(props.item, (draft) => {
        draft.configuration = {
          sourceItems: [...props.item.configuration.sourceItems, data],
        };
      });
      dispatch(updateComponent(newComps));
    } else {
      const newComps: CompProps = produce(props.item, (draft) => {
        draft.configuration = {
          sourceItems: props.item.configuration.sourceItems.map((src) => {
            if (src.id === data.id) {
              return { ...data };
            } else {
              return { ...src };
            }
          }),
        };
      });
      dispatch(updateComponent(newComps));
    }

    setAction({
      action: SocialMediaAction.default,
      params: 1,
    });
    setCurrentPlayer(data);
  };

  return (
    <SocialMediaPlayerStyle
      className={`social-media-player-container 
      ${multipleLayout ? 'multiple-layout' : 'single-layout'}`}
    >
      <div className={'social-media-player'}>
        {action.action === SocialMediaAction.add_media ? (
          <SocialMediaEditForm
            item={currentPlayer}
            onCancel={handleOnCancel}
            onSubmit={handleOnCreateOrUpdateVideo}
          />
        ) : (
          <>
            <div className={'social-media-player-container'}>
              <SocialMediaPlayerWorkSpace
                item={currentPlayer}
                onClick={onAddVideo}
              />
            </div>

            {(multipleLayout || mode === CompMode.COMPOSE) && (
              <div className={'social-media-playlist'}>
                {props.item.configuration.sourceItems.map((video) => {
                  return (
                    <div
                      key={video.id}
                      className={`social-media-playlist-item ${
                        currentPlayer && currentPlayer.id === video.id
                          ? ' selected'
                          : ''
                      }`}
                    >
                      <div
                        className={'social-media-icon-display'}
                        onClick={() => setCurrentPlayer(video)}
                      >
                        {video.name}
                      </div>

                      <ComposeModeContent>
                        <ButtonGroup type={'right'}>
                          <Button
                            onClick={() => onEditVideo(video)}
                            shape={'circle'}
                            size={'small'}
                            type={'primary'}
                            icon={IconUtils.actions.edit}
                          />
                          <Button
                            shape={'circle'}
                            size={'small'}
                            type={'primary'}
                            danger
                            icon={IconUtils.actions.delete}
                            onClick={() => onRemoveVideo(video)}
                          />
                        </ButtonGroup>
                      </ComposeModeContent>
                    </div>
                  );
                })}

                <ComposeModeContent>
                  <div
                    className={`social-media-playlist-item blank-item`}
                    onClick={onAddVideo}
                  >
                    <PlusOutlined /> Add Video
                  </div>
                </ComposeModeContent>
              </div>
            )}
          </>
        )}
      </div>
    </SocialMediaPlayerStyle>
  );
};

const SocialMediaPlayerStyle = styled.div`
  width: 100%;
  font-size: 16px;
  position: relative;

  &.multiple-layout {
    padding-right: 250px;
    border: 1px solid #333;

    .social-media-playlist {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  .social-media-player {
    display: flex;
    flex-wrap: nowrap;

    .social-media-player-container {
      flex-grow: 1;
    }

    .social-media-playlist {
      width: 250px;
      min-width: 250px;
      max-width: 250px;
      padding: 0.5em;
      border-left: 1px solid #ccc;
      overflow-y: auto;

      .social-media-playlist-item {
        border: 1px solid #333;
        height: 100px;
        cursor: pointer;
        position: relative;
        padding: 0.5em;

        .social-media-icon-display {
          width: 100%;
          height: 100%;
          font-weight: bold;
        }

        .button-group {
          position: absolute;
          bottom: 5px;
          right: 5px;

          .ant-btn {
            margin-left: 0.25em;
            margin-right: 0px;
          }
        }

        &.blank-item {
          font-size: 125%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${(props) => props.theme.component.disabled};
        }

        &:hover {
          border: 1px solid ${(props) => props.theme.component.primary};
          background: ${(props) => props.theme.component.primary_bgr};
        }

        &.selected {
          border: 1px solid ${(props) => props.theme.component.primary};
          background: ${(props) => props.theme.component.primary_bgr};
        }

        &:not(:last-child) {
          margin-bottom: 0.5em;
        }
      }
    }
  }
`;
