import { ExerciseResponseRes } from '@modules/assignments/service/exercise_model';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CompAnswerProps } from '@cms/ComponentInteface';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { ResourceProps } from '@modules/product/components/resource/Resource';

export enum LessonNavigationAction {
  DEFAULT = '',
  RESET = 'reset', // move to top...

  NEXT_SECTION = 'next-section',
  PREVIOUS_SECTION = 'previous-section',

  NEXT_QUESTION = 'next-question',
  PREVIOUS_QUESTION = 'previous-question',

  UPDATE_RESOURCE_NAV = 'update-resource-nav',
  UPDATE_NAVIGATION = 'update-navigation',

  GO_TO_QUESTION = 'go-to-question',
}

export enum LessonNavigationType {
  default = '', // init and do-nothing
  first = 'first', // move to the first question of section
  last = 'last', // move to last question of section
  refresh = 'refresh', // set the current question and let section define how to go next and go previous... All data will be re-calculate.
}

export interface SectionNavReq {
  sectionId: number;
  resourceId?: number;
  type: LessonNavigationType;
}

export interface ResourceNavReq {
  resourceId: number;
  type: LessonNavigationType;
}

export interface UpdateResourceNavReq {
  resourceId: number;
  previousResourceId: number;
  nextResourceId: number;
  type: LessonNavigationType;
}

const LessonNavigationContext = createContext({
  interact: false as boolean,
  action: {
    action: LessonNavigationAction.DEFAULT,
    params: null as
      | SectionNavReq
      | ResourceNavReq
      | UpdateResourceNavReq
      | null,
  },

  lastQuestion: false as boolean,
  lastSection: false as boolean,

  section: {
    sectionId: -1,
    previousSectionId: -1,
    nextSectionId: -1,
    resourceIds: [] as number[],
    interact: false,
  },

  resource: {
    resourceId: -1,
    previousResourceId: -1,
    nextResourceId: -1,
    type: LessonNavigationType.default as LessonNavigationType,
    interact: false,
  },

  updateNavigation: (
    action: LessonNavigationAction,
    params: SectionNavReq | ResourceNavReq | UpdateResourceNavReq | null
  ) => {
    console.log(action, params);
  },
});

const getDefaultSection = (
  sections: LessonSectionProps[],
  sectionId: number
): LessonSectionProps => {
  const sectionIndex =
    sectionId != null && sectionId > 0
      ? sections.findIndex((sec) => {
          return sec.lessonSectionId === sectionId;
        })
      : -1;

  return sectionIndex > -1 ? sections[sectionIndex] : sections[0];
};

export const LessonNavigationContextProvider = (props: {
  resourceId: number;
  sectionId: number;
  children: ReactNode;
}) => {
  const { sections } = useViewLessonContext();

  const [action, setAction] = useState<{
    action: LessonNavigationAction;
    params: SectionNavReq | ResourceNavReq | UpdateResourceNavReq | null;
  }>({
    action: LessonNavigationAction.DEFAULT,
    params: null,
  });

  const [lastQuestion, setLastQuestion] = useState(true);
  const [lastSection, setLastSection] = useState(false);

  const [resources, setResources] = useState<ResourceProps[]>(
    ResourceUtils.getResourcesFromSections(sections)
  );

  const interact: boolean = useMemo(() => {
    return resources.some((res) => {
      return res.validation != null ? res.validation.interact : false;
    });
  }, [sections]);

  const [sectionNav, setSectionNav] = useState(() => {
    const defaultSection = getDefaultSection(sections, props.sectionId);

    if (defaultSection != null) {
      const resources = ResourceUtils.getResourceFromSection(defaultSection);
      const hasInteract = resources.some((res) => {
        return res.validation ? res.validation.interact : false;
      });

      return {
        sectionId: defaultSection.lessonSectionId,
        previousSectionId: -1,
        nextSectionId: sections.length > 1 ? sections[1].lessonSectionId : -1,
        resourceIds: resources.map((res) => {
          return res.resourceId;
        }),
        interact: hasInteract,
      };
    } else {
      return {
        sectionId: -1,
        previousSectionId: -1,
        nextSectionId: -1,
        resourceIds: [],
        interact: false,
      };
    }
  });

  const [resourceNav, setResourceNav] = useState({
    resourceId: props.resourceId > 0 ? props.resourceId : -1,
    previousResourceId: -1,
    nextResourceId: -1,
    type:
      props.resourceId > 0
        ? LessonNavigationType.refresh
        : LessonNavigationType.default,
    interact: false,
  });

  useEffect(() => {
    setResources(ResourceUtils.getResourcesFromSections(sections));
  }, [sections]);

  useEffect(() => {
    if (
      action.action === LessonNavigationAction.NEXT_SECTION ||
      action.action === LessonNavigationAction.PREVIOUS_SECTION
    ) {
      updateSectionNavigation(action.params as SectionNavReq);
    } else if (action.action === LessonNavigationAction.GO_TO_QUESTION) {
      updateSectionNavigation(action.params as SectionNavReq);
    } else if (
      action.action === LessonNavigationAction.PREVIOUS_QUESTION ||
      action.action === LessonNavigationAction.NEXT_QUESTION
    ) {
      updateResourceNavigation(action.params as ResourceNavReq);
    } else if (action.action === LessonNavigationAction.UPDATE_RESOURCE_NAV) {
      updateReferenceResourceNavigation(action.params as UpdateResourceNavReq);
    }
  }, [action, sections]);

  useEffect(() => {
    setLastSection(sectionNav.nextSectionId < 0);
  }, [sectionNav]);

  useEffect(() => {
    setLastQuestion(resourceNav.nextResourceId < 0);
  }, [resourceNav]);

  const updateNavigation = (
    action: LessonNavigationAction,
    params: SectionNavReq | ResourceNavReq | UpdateResourceNavReq | null
  ) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const updateResourceNavigation = (params: ResourceNavReq) => {
    setResourceNav((prev) => {
      return {
        ...prev,
        resourceId: params.resourceId,
        type: LessonNavigationType.refresh,
      };
    });
  };

  const updateReferenceResourceNavigation = (params: UpdateResourceNavReq) => {
    const res = resources.findIndex((resource) => {
      return resource.resourceId === params.resourceId;
    });

    setResourceNav({
      resourceId: params.resourceId,
      previousResourceId: params.previousResourceId,
      nextResourceId: params.nextResourceId,
      type: params.type,
      interact:
        res != null &&
        res > 0 &&
        resources[res].validation &&
        resources[res].validation?.interact
          ? resources[res].validation?.interact === true
          : false,
    });

    setAction({
      action: LessonNavigationAction.UPDATE_NAVIGATION,
      params: null,
    });
  };

  const updateSectionNavigation = (params: SectionNavReq) => {
    const _section = getDefaultSection(sections, params.sectionId);
    const _sectionId = _section.lessonSectionId;

    let _currentResourceId = -1;
    let _previousSectionId = -1;
    let _nextSectionId = -1;
    let _resourceIds: number[] = [];
    let isMatch = false;
    let sectionInteract = false;
    let resourceInteract = false;

    sections.forEach((sec) => {
      if (sec.lessonSectionId === _sectionId) {
        isMatch = true;
        const resources = ResourceUtils.getResourceFromSection(sec);

        sectionInteract = resources.some((res) => {
          return res.validation ? res.validation.interact : false;
        });

        resources.forEach((res) => {
          if (res.resourceId === params.resourceId) {
            resourceInteract = res.validation ? res.validation.interact : false;
          }
        });

        _resourceIds = resources.map((res) => {
          return res.resourceId;
        });

        if (
          params.resourceId != null &&
          params.resourceId > 0 &&
          _resourceIds.includes(params.resourceId)
        ) {
          _currentResourceId = params.resourceId;
        }
      } else {
        if (isMatch && _nextSectionId < 0) {
          _nextSectionId = sec.lessonSectionId;
        } else if (!isMatch) {
          _previousSectionId = sec.lessonSectionId;
        }
      }
    });

    setSectionNav({
      sectionId: _sectionId,
      previousSectionId: _previousSectionId,
      nextSectionId: _nextSectionId,
      resourceIds: _resourceIds,
      interact: sectionInteract,
    });

    setResourceNav({
      resourceId: _currentResourceId,
      previousResourceId: -1,
      nextResourceId: -1,
      type: params.type,
      interact: resourceInteract,
    });
  };

  return (
    <LessonNavigationContext.Provider
      value={{
        action,
        interact: interact,
        lastQuestion: lastQuestion,
        lastSection: lastSection,
        section: sectionNav,
        resource: resourceNav,
        updateNavigation,
      }}
    >
      {props.children}
    </LessonNavigationContext.Provider>
  );
};

export const useLessonNavigationContext = () => {
  const context = useContext(LessonNavigationContext);
  if (!context) {
    throw new Error(
      'You must wrap container by LessonNavigationContextProvider'
    );
  }
  return context;
};

export interface ShowQuestionFeedbackProps {
  resourceId: number;
  feedBack: ExerciseResponseRes;
  answers: CompAnswerProps[];
}
