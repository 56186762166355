import React from 'react';
import { useDropDownCompContext } from '@cms/comps/interact/dropdown/DropdownCompContext';
import DropdownGroupItem from '@cms/comps/interact/dropdown/DropdownGroupItem';
import styled from 'styled-components';

const DropdownGroupItems = () => {
  const { modifiedTime, targetItems } = useDropDownCompContext();

  return (
    <DropdownGroupItemsStyle className={'dropdown-group-items'}>
      {targetItems &&
        targetItems.map((drop, index) => {
          return (
            <DropdownGroupItem
              key={drop.label + '_' + drop.content.id + '_' + modifiedTime}
              drop={drop}
              index={index}
            />
          );
        })}
    </DropdownGroupItemsStyle>
  );
};

export default DropdownGroupItems;

const DropdownGroupItemsStyle = styled.div`
  .dropdown-group-item {
    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }

    .cms-content-display {
      padding-right: 30px;
      position: relative;

      .edit-content-group {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
`;
