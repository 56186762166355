import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { FeaturesPages } from '@modules/public/FeaturesPage';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const FeaturesApp = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout className={'app-feature-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_LANDING_PAGE}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('features.header')}
        description={t('features.header')}
      />
      <FeaturesPages />
    </PublicLayout>
  );
};

export default FeaturesApp;
