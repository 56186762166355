import { TeacherAssignmentTable } from '@components/common/assignment/teacher/AssignmentTableTeacher';
import React, { useState } from 'react';
import { H1 } from '@components/typography';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import CreateAssignment from '@modules/assignments/container/assignment/CreateAssignment';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import styled from 'styled-components';
import { StudentAssignmentTable } from '@modules/assignments/container/StudentAssignmentTable';

export const ClassAssignment = (props: { classId: number }) => {
  const { t } = useTranslation();

  const [action, setAction] = useState('');

  const createAssignment = () => {
    setAction('create-assignment');
  };

  const onCreateSuccess = () => {
    setAction('refresh');
    onCancel();
  };

  const onCancel = () => {
    setAction('');
  };

  return (
    <div className={'class-assignment'}>
      <HeaderGroup className={'mt-0'}>
        <H1>
          {t('assignment.title')}

          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.TEACHER, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
          >
            <Button
              type={'primary'}
              shape="circle"
              icon={IconUtils.add_new}
              onClick={createAssignment}
            />
          </Authorization>
        </H1>
      </HeaderGroup>

      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.TEACHER, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <TeacherAssignmentTable action={action} classId={props.classId} />
      </Authorization>

      <Authorization
        type={'ifNotGranted'}
        roles={[RoleEnum.TEACHER, RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <StudentAssignmentTable classId={props.classId} />
      </Authorization>

      {action === 'create-assignment' && (
        <CustomModal
          header={'Add Assignment'}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <CreateAssignmentClassStyle>
              <CreateAssignment
                classId={props.classId}
                lessonId={-1}
                productTocId={-1}
                productId={-1}
                onSuccess={onCreateSuccess}
                onCancel={onCancel}
              />
            </CreateAssignmentClassStyle>
          }
          onCloseFunc={onCancel}
        />
      )}
    </div>
  );
};

const CreateAssignmentClassStyle = styled.div`
  .create-assignment-section {
    > .header-group {
      margin-top: 0;
    }
  }
`;
