import {
  CompAnswerProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';

import { TextContentProps } from '@cms/content/ContentType';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { AssetsRes } from '@modules/product/services/assets_model';
import {
  ContentViewer,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import styled from 'styled-components';

export interface ContentProps extends CompProps {
  configuration: {
    content: TextContentProps;
  };
}

export const StaticSampleData: ContentProps = {
  id: 'static_content_text',
  type: CompTypeEnum.STATIC_CONTENT,

  configuration: {
    content: {
      id: 'static-content-prop',
      type: AssetsTypeEnum.TEXT,
      data: 'Simple content',
    },
  },
};

export function StaticContentComp(props: {
  item: ContentProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: AssetsRes) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <ContentCompStyle
        className={`comp comp-static-content ${props.item.setting?.className}`}
      >
        <ContentViewerEditable
          contentData={props.item.configuration.content}
          onChange={handleOnChange}
        />
      </ContentCompStyle>
    );
  } else {
    return (
      <ContentCompStyle
        className={`comp comp-static-content ${props.item.setting?.className}`}
      >
        <ContentViewer contentData={props.item.configuration.content} />
      </ContentCompStyle>
    );
  }
}

const ContentCompStyle = styled.div`
  position: relative;

  p {
    margin-bottom: var(--cms-padding-option, 0.5em);
  }
`;
