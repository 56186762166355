import React, { useEffect, useState } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { Button, notification, Space } from 'antd';
import TablePagination from '@components/table/TablePagination';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { LessonResult } from '@modules/product/components/toc/TableOfContentProgress';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import DatePicker from '@components/date-n-time/DatePicker';
import { InputGroup } from '@components/input/InputGroup';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { H1, H5 } from '@components/typography';
import TimePicker from '@components/date-n-time/CustomTimePicker';
import ButtonGroup from '@components/button/ButtonGroup';
import { ExerciseAPI } from '@modules/assignments/service/ExerciseAPI';
import {
  ExerciseRes,
  ExerciseStatus,
  UpdateExerciseCompletedDateReq,
} from '@modules/assignments/service/exercise_model';

const ViewStudentExercisePage = () => {
  const [getAllStudentExercise, { data, isFetching }] =
    ExerciseAPI.endpoints.getAllStudentExercise.useLazyQuery();

  const [redoExercise, { isLoading }] =
    ExerciseAPI.endpoints.redoExercise.useMutation();

  const [updatedExercise, setUpdatedExercise] = useState<number[]>([]);
  const [rescore, setRescore] = useState<ExerciseRes | null>(null);

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'completedDate',
    direction: 'ascend',
  });

  useEffect(() => {
    getAllStudentExercise(params);
  }, [params]);

  const rescoreStandardExercise = (item: ExerciseRes) => {
    setRescore(item);
  };

  const onRedoExercise = (item: ExerciseRes) => {
    redoExercise(item.exerciseId)
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const processDataRow = (item: ExerciseRes) => {
    return {
      key: item.exerciseId,
      thumbnails: (
        <ThumbnailsImg
          type={'icon'}
          width={90}
          src={item.lesson.thumbnails}
          ratio={ImageScale.lesson.ratio}
        />
      ),
      lesson: item.lesson.name,
      status: item.status,
      score: (
        <>
          {item.status === ExerciseStatus.COMPLETED && (
            <LessonResult score={item.score!} maxScore={item.maxScore!} />
          )}
        </>
      ),
      completedDate: <DateAndTimeLabel label={item.completedDate} />,
      action: (
        <Space>
          {updatedExercise.indexOf(item.exerciseId) < 0 &&
            (item.status === ExerciseStatus.COMPLETED ||
              item.status === ExerciseStatus.NEED_GRADING ||
              item.status === ExerciseStatus.SUBMITTED) && (
              <Button
                type="default"
                shape="round"
                danger
                loading={isLoading}
                onClick={() => onRedoExercise(item)}
              >
                Resubmit
              </Button>
            )}

          {updatedExercise.indexOf(item.exerciseId) < 0 &&
            item.status === ExerciseStatus.COMPLETED && (
              <>
                <Button
                  type="primary"
                  shape="round"
                  danger
                  onClick={() => rescoreStandardExercise(item)}
                >
                  Modifier Submit Date
                </Button>
              </>
            )}
        </Space>
      ),
    };
  };

  const refreshData = (params: any) => {
    setParams(params);
  };

  const columns = [
    {
      title: 'thumbnails',
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: '60px',
    },
    {
      title: 'lesson',
      dataIndex: 'lesson',
      key: 'lesson',
      width: '15%',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'completedDate',
      dataIndex: 'completedDate',
      key: 'completedDate',
    },
    {
      title: 'actions',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const handleAfterUpdate = (exerciseId: number, isUpdate: boolean) => {
    if (isUpdate) {
      getAllStudentExercise(params);
      setUpdatedExercise((prev) => {
        return [...prev, exerciseId];
      });
    }

    setRescore(null);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>Exercises (Feature for testing)</H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
      />

      {rescore != null && (
        <CustomModal
          header={'Rescore Standard for Exercise'}
          className={CustomModalClassEnum.default}
          content={
            <RescoreExerciseStandardForm
              item={rescore}
              onClose={handleAfterUpdate}
            />
          }
          onCloseFunc={() => setRescore(null)}
        />
      )}
    </>
  );
};

const RescoreExerciseStandardForm = (props: {
  item: ExerciseRes;
  onClose: (exerciseId: number, isUpdate: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [calculateStandards, { isLoading }] =
    ExerciseAPI.endpoints.calculateStandards.useMutation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      exerciseId: props.item.exerciseId,
      completedDate: props.item.completedDate.split('T')[0],
      completedTime: props.item.completedDate.split('T')[1],
    },

    validationSchema: yup.object().shape({}),

    onSubmit: (values) => {
      const request: UpdateExerciseCompletedDateReq = {
        exerciseId: values.exerciseId,
        completedDate: values.completedDate + 'T' + values.completedTime,
      };

      calculateStandards(request)
        .unwrap()
        .then(() => {
          props.onClose(props.item.exerciseId, true);
        })
        .catch(() => {
          notification.error({
            message: t('exercise.warning.submit_error'),
            description: t('exercise.warning.submit_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <div className={'rescore-exercise-form'}>
      <H5>Update Completed Date and trigger calculator score</H5>

      <InputGroup label={'Update Completed Date'}>
        <DatePicker
          value={formik.values.completedDate}
          name="completedDate"
          formik={formik}
          handleChange={formik.handleChange as any}
        />
      </InputGroup>

      <InputGroup label={t('label.time')}>
        <TimePicker
          value={formik.values.completedTime}
          name="completedTime"
          formik={formik}
          handleChange={formik.handleChange}
        />
      </InputGroup>

      <ButtonGroup>
        <Button
          type={'primary'}
          loading={isLoading}
          onClick={formik.submitForm}
        >
          {t('button.update')}
        </Button>

        <Button onClick={() => props.onClose(props.item.exerciseId, false)}>
          {t('button.cancel')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ViewStudentExercisePage;
