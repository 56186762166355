import { Modal } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const useConfirmModal = () => {
  const { t } = useTranslation();
  const { confirm: confirmFunc, error: errorFunc } = Modal;

  const confirm = (
    type: 'confirm' | 'success' | 'warning' | 'danger',
    title: string | ReactNode,
    message: ReactNode | string,
    confirmLabel: ReactNode | string,
    cancelLabel: ReactNode | string,
    callBackFunc: (confirm: boolean) => void
  ) => {
    confirmFunc({
      className: 'custom-confirm-modal',
      title: title,
      content: message,
      okText: confirmLabel ?? t('label.yes'),
      cancelText: cancelLabel ?? t('label.no'),

      okButtonProps: {
        danger: type === 'danger',
        type: 'primary',
        shape: 'round',
        size: 'large',
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'large',
      },
      closable: true,
      onOk() {
        callBackFunc(true);
      },
      onCancel() {
        callBackFunc(false);
      },
    });
  };

  const warning = (
    title: string | ReactNode,
    message: ReactNode | string,
    confirmLabel: ReactNode | string,
    cancelLabel: ReactNode | string,
    callBackFunc: (confirm: boolean) => void
  ) => {
    errorFunc({
      className: 'custom-confirm-modal warning-modal',
      title: title,
      content: message,
      okText: confirmLabel ? confirmLabel : t('label.yes'),
      cancelText: null,
      okButtonProps: {
        shape: 'round',
        size: 'large',
        danger: true,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'large',
      },
      closable: false,

      onOk() {
        callBackFunc(false);
      },
    });
  };

  const error = (
    title: string | ReactNode,
    message: ReactNode | string,
    confirmLabel: ReactNode | string,
    callBackFunc: () => void
  ) => {
    errorFunc({
      className: 'custom-confirm-modal error-modal',
      title: title,
      content: message,
      okText: confirmLabel ? confirmLabel : t('label.yes'),
      cancelText: null,
      okButtonProps: {
        shape: 'round',
        size: 'large',
        danger: true,
      },
      cancelButtonProps: {
        shape: 'round',
        size: 'large',
      },
      closable: false,

      onOk() {
        callBackFunc();
      },
    });
  };

  const customConfirm = (
    title: string | ReactNode,
    message: ReactNode | string,
    confirmLabel: ReactNode | string,
    cancelLabel: ReactNode | string,
    callBackFunc: (confirm: boolean) => void
  ) => {
    confirmFunc({
      className: 'custom-confirm-modal',
      title: title,
      content: message,
      okText: confirmLabel ? confirmLabel : t('label.yes'),
      cancelText: cancelLabel ? cancelLabel : t('label.no'),
      closable: true,
      onOk() {
        callBackFunc(true);
      },
      onCancel() {
        callBackFunc(false);
      },
    });
  };

  return {
    error,
    confirm,
    warning,
    customConfirm,
  };
};

export default useConfirmModal;
