import NavLink from '@components/button/NavLink';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import React from 'react';
import styled from 'styled-components';
import { PriceTagDisplay } from '@components/product/LessonInfo';
import { H5 } from '@components/typography';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductRes } from '@modules/product/services/product_model';
import { useRouter } from '@hooks/useRouter';
import { AnonymousDoExerciseAPI } from '@modules/assignments/service/AnonymousDoExerciseAPI';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '@components/avatar/UserAvatar';
import ButtonGroup from '@components/button/ButtonGroup';

export const PublishLesson = (props: { item: LessonRes }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [createExercise, { isLoading }] =
    AnonymousDoExerciseAPI.endpoints.createExercise.useMutation();

  const onStartExercise = () => {
    if (props.item.validation != null && props.item.validation.interact) {
      createExercise({
        lessonId: props.item.lessonId,
      })
        .unwrap()
        .then((exercise) => {
          router.push(
            SiteMap.public.exercise.view_exercise_gen(exercise.exerciseId)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      router.push(
        SiteMap.public.lesson_view_gen(props.item.lessonId, props.item.name)
      );
    }
  };

  return (
    <CollectionItemStyle className={`collection-item `}>
      <div className={'collection-info'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.thumbnails}
          ratio={ImageScale.product.ratio}
          width={300}
          height={300}
        />

        <H5 className={'collection-title'}>{props.item.name}</H5>
      </div>

      <div className={'collection-actions'}>
        <ButtonGroup type={'space-between'}>
          <Button
            shape={'round'}
            type={'primary'}
            icon={IconUtils.actions.start_exercise}
            onClick={() => onStartExercise()}
            loading={isLoading}
          >
            {t('lesson.actions.practice')}
          </Button>

          {props.item.author && (
            <NavLink
              href={SiteMap.public.teacher_view_gen(props.item.author.username)}
            >
              <UserAvatar src={props.item.author.avatar} size={40} />
            </NavLink>
          )}
        </ButtonGroup>
      </div>
    </CollectionItemStyle>
  );
};

const CollectionItemStyle = styled.div`
  width: 300px;
  position: relative;

  .collection-info {
    border: 1px solid ${(props) => props.theme.app.primary};

    .collection-title {
      background: #ccc;
      color: ${(props) => props.theme.app.primary};
    }
  }

  .collection-info {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    width: 300px;
    height: 300px;
    margin-bottom: 0.5em;
    display: flex;
    overflow: hidden;

    .image-thumbnail-wrapper {
      width: 300px;
      height: 300px;
    }

    .collection-title {
      padding: 0.25em 0.5em;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: bold;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 0px;
    }
  }

  .collection-actions {
    padding: 0.125em 0;

    //display: flex;
    //justify-content: space-between;
    //
    //&.right-group {
    //  position: absolute;
    //  top: 5px;
    //  right: 5px;
    //}
  }
`;

export const PublishLessonInfo = (props: {
  className?: string;
  selected?: boolean;
  item: LessonRes;
  productTocId: number;
  product: ProductRes;
}) => {
  return (
    <NavLink
      href={SiteMap.public.product_lesson_gen(
        props.product.productId,
        props.product.name,
        props.productTocId,
        props.item.lessonId,
        props.item.name
      )}
    >
      <PublishLessonInfoStyle
        className={`lesson-info ${props.className ? props.className : ''} ${
          props.selected ? ' selected ' : ' '
        } `}
      >
        <div className={'lesson-title'}>
          <div className={'lesson-thumbnails'}>
            <ThumbnailsImg
              type={'icon'}
              src={''}
              width={32}
              ratio={ImageScale.lesson.ratio}
            />
          </div>

          <div className={'lesson-title-display'}>
            <span>{props.item.name}</span>
          </div>
        </div>

        <span className={'lesson-price'}>
          <PriceTagDisplay free={props.item.free} price={props.item.price} />
        </span>
      </PublishLessonInfoStyle>
    </NavLink>
  );
};

const PublishLessonInfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .lesson-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .lesson-thumbnails {
      display: flex;
    }

    .lesson-title-display {
      margin-left: 8px;
    }
  }

  .lesson-price {
    padding: 0 0.5em;
  }

  &.selected {
    font-weight: bold;
  }
`;
