import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextEditable } from '@cms/editable/TextEditable';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import {
  CompFeedbackAnswerIcon,
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '../ComponentGrading';

export interface FibEssaySettingProps extends CompInteractSettingProps {
  autoScore: false;
  row: number;
}

export interface FibEssayProps extends CompProps {
  type: CompTypeEnum.FIB_ESSAY;
  setting: FibEssaySettingProps;
}

export interface FibEssayAnsProps extends CompAnswerProps {
  answer: string;
}

export const FibEssayComp = (props: {
  disabled: boolean;
  item: FibEssayProps;
  answer: FibEssayAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [answer, setAnswer] = useState({
    value: '' as string,
    triggerChange: false,
  });
  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (props.answer) {
      setAnswer({ value: props.answer.answer, triggerChange: false });
    } else {
      setAnswer({ value: '', triggerChange: false });
    }
  }, [props.answer]);

  useEffect(() => {
    if (answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value !== '' ? answer.value : null,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
        correctAnswer: props.feedback.correctAnswer,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleOnChange = (textAns: string) => {
    if (!props.disabled) {
      setAnswer({
        value: textAns,
        triggerChange: true,
      });
    }
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      <FibEssayStyle
        className={`comp comp-essay ${props.item.setting?.className}`}
      >
        <div className={'comp-essay-textarea'}>
          <CompFeedbackAnswerIcon
            item={props.item}
            answer={answer.value}
            correctAnswer={feedBack.correctAnswer}
          />

          <TextEditable
            key={props.item.id + '_' + answer.value}
            initValue={answer.value != null ? answer.value : ''}
            onChange={handleOnChange}
            style={{
              lineHeight: '1.6em',
              minHeight: `${props.item.setting.row * 1.6 + 0.5}em`, // lineheight + padding....
            }}
            disabled={props.disabled}
          />

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </div>
      </FibEssayStyle>
    </CompFeedbackContextProvider>
  );
};

const FibEssayStyle = styled.div`
  display: block;

  .comp-essay-textarea {
    display: block;
    position: relative;
    padding: 0.125em 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.25em;
  }

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }

  .cms-content-editable {
    display: flex;
    align-items: stretch;
    color: ${(props) => props.theme.component.primary};

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }

    [contenteditable='true'] {
      flex-grow: 1;
    }
  }

  .feedback-icon {
    left: 0px;
    top: 0px;
    transform: translate(-30px, 0px);
    position: absolute;
  }
`;
