import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import LoginOauth from '@router/pages/login/oauth2/login';
import RedirectOauthPage from '@router/pages/login/oauth2/redirect';
import OauthApp from '@router/pages/integration/oauth_app';
import TestAccessProduct from '@router/pages/public/lti/TestAccessProduct';
import ViewProductLTI from '@router/pages/public/lti/ViewProductLTI';
import React from 'react';

export const LTIRouters: PageRouterProps[] = [
  { path: SiteMap.lti.redirect, element: <RedirectOauthPage /> },

  // CREATE OAUTH APP
  { path: SiteMap.lti.oauth_app, element: <OauthApp /> },

  // LOGIN BY USING OAUTH APP
  { path: SiteMap.lti.login_testing, element: <LoginOauth /> },

  // TEST ACCESS PRODUCT
  { path: SiteMap.lti.product_test, element: <TestAccessProduct /> },
  { path: SiteMap.lti.product_view, element: <ViewProductLTI /> },
];
