import React, { useEffect, useState } from 'react';
import { CompFeedbackProps } from '@cms/feedback/CompFeedback';
import { useAudioRecorder } from '@sarafhbk/react-audio-recorder';
import AudioInlinePlayer from '../content/audio/AudioInlinePlayer';
import FaIcons from '@components/fa-icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const AudioRecordStatus = {
  IDLE: 'idle',
  RECORDING: 'recording',
  PAUSED: 'paused',
};

const AudioRecordingItemContent = (props: {
  disabled: boolean;
  answer: string;
  onChange: (answer: string) => void;
  feedback: CompFeedbackProps;
}) => {
  const { t } = useTranslation();

  const [answer, setCurrentAnswer] = useState('');
  const [action, setAction] = useState('');

  const { audioResult, timer, startRecording, stopRecording, status } =
    useAudioRecorder();

  useEffect(() => {
    if (props.answer) {
      setCurrentAnswer(props.answer);
    } else {
      setCurrentAnswer('');
    }
  }, [props.answer]);

  useEffect(() => {
    if (action === 'saving-recording' && audioResult) {
      setCurrentAnswer(audioResult);
      // call api to saving response --> then fire event
      props.onChange(audioResult);
    }
  }, [action, audioResult]);

  const handleClearAnswer = () => {
    setCurrentAnswer('');
    setAction('');
  };

  const handleStartRecording = () => {
    try {
      startRecording();
      setAction('');
    } catch (e) {
      setAction('');
    }
  };

  const handleStopRecording = () => {
    try {
      stopRecording();
      setAction('saving-recording');
    } catch (e) {
      setAction('saving-recording');
    }
  };

  return (
    <AudioRecordingItemContentStyle className={'audio-recording-comp'}>
      {answer != null && answer !== '' ? (
        <div className={'audio-recording-toolbar'}>
          <button
            className={'audio-recording-btn circle'}
            onClick={handleClearAnswer}
            title={t('component.recording.clear')}
          >
            {FaIcons.microphoneSlash()}
          </button>

          <AudioInlinePlayer source={answer}>
            <button
              className={'audio-recording-btn circle'}
              title={t('component.recording.play')}
            >
              {FaIcons.play()}
            </button>
          </AudioInlinePlayer>
        </div>
      ) : (
        <div className={'audio-recording-toolbar'}>
          {status === AudioRecordStatus.IDLE && (
            <button
              className={'audio-recording-btn circle'}
              onClick={handleStartRecording}
              title={t('component.recording.start')}
            >
              {FaIcons.microphone()}
            </button>
          )}

          {status === AudioRecordStatus.RECORDING && (
            <>
              <button
                className={'audio-recording-btn round'}
                onClick={handleStopRecording}
                title={t('component.recording.stop')}
              >
                {FaIcons.stop()}{' '}
                <span className={'audio-time'}>
                  {new Date(timer * 1000).toISOString().substr(14, 5)}
                </span>
              </button>
            </>
          )}
        </div>
      )}
    </AudioRecordingItemContentStyle>
  );
};

export default AudioRecordingItemContent;

const AudioRecordingItemContentStyle = styled.div`
  .audio-recording-toolbar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;

    .audio-inline-comp-content {
      margin-left: 0.25em;
    }
  }

  .audio-time {
    font-size: 80%;
  }

  button.audio-recording-btn {
    display: inline-flex;
    align-items: center;

    background: none;
    border: 1px solid #ccc;
    cursor: pointer;

    .audio-time {
      font-size: 60%;
      margin-left: 0.5em;
    }

    &.circle {
      padding: 0.25em;
      border-radius: 100%;
    }

    &.round {
      padding: 0.125em 0.5em;
      border-radius: 1em;
    }

    svg {
      color: ${(props) => props.theme.component.primary};
      display: inline-block;
      width: 0.8em;
      height: 0.8em;
    }
  }
`;
