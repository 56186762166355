import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';

import SchoolsManagement from '@router/pages/management/school/manage_schools';
import { RoleEnum } from '@app/redux/slices/roles';
import CreateOrUpdateSchool from '@router/pages/management/school/create_school';
import ReviewSchool from '@router/pages/management/school/review_school';

export const ManageSchoolRouter: PageRouterProps[] = [
  {
    path: SiteMap.management.school.list,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<SchoolsManagement />} />
    ),
  },
  {
    path: SiteMap.management.school.update,
    element: (
      <PrivateRouter
        roles={[RoleEnum.SCHOOL]}
        element={<CreateOrUpdateSchool />}
      />
    ),
  },
  {
    path: SiteMap.management.school.review,
    element: (
      <PrivateRouter roles={[RoleEnum.SCHOOL]} element={<ReviewSchool />} />
    ),
  },

  // duplicated. remove later
  {
    path: SiteMap.management.school.info,
    element: (
      <PrivateRouter
        roles={SiteMap.management.school.info_roles}
        element={<CreateOrUpdateSchool />}
      />
    ),
  },
  // duplicated. remove later
  {
    path: SiteMap.management.school.info_review,
    element: (
      <PrivateRouter
        roles={SiteMap.management.school.info_roles}
        element={<ReviewSchool />}
      />
    ),
  },

  // users
];
