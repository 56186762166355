import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { EntityStatusEnum } from '@services/model/common';
import Card, { CardClassEnum } from '@components/card';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import {
  ComposingStatus,
  ComposingWithStatus,
} from '@components/common/lesson/ComposingStatus';
import { DateLabel } from '@components/text/DateLabel';
import TablePagination from '@components/table/TablePagination';
import { ResponsiveScreen } from '@hooks/useMedia';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { ProductLicenseFilterForm } from '@modules/product/components/product/ProductLicenseFilter';
import { PublishProductAction } from '@modules/product/components/product/PublishProductAction';
import { ProductItemStyle } from '@components/product/style';
import { H5 } from '@components/typography';
import { InputGroup } from '@components/input/InputGroup';
import { ProductRes } from '@modules/product/services/product_model';

export const ManageProductLicenseTable = () => {
  const { t } = useTranslation();

  const [getCompletedProducts, { data, isFetching, isLoading }] =
    ProductAPI.endpoints.getCompletedProducts.useLazyQuery({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',

    searchTerm: '',
    status: EntityStatusEnum.PUBLISHED,
  });

  const columns = [
    {
      title: t('label.thumbnails'),
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: 90,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('label.completed_date'),
      dataIndex: 'completedDate',
      key: 'publishedDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    getCompletedProducts(params);
  }, [params]);

  const renderMobile = (item: ProductRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <ProductLicenseItem item={item} onChange={refreshPage} />
      </Card>
    );
  };

  const processDataRow = (item: ProductRes) => {
    return {
      key: item.productId,
      thumbnails: (
        <ThumbnailsImg
          type={'icon'}
          src={item.icon}
          ratio={ImageScale.product.ratio}
        />
      ),
      name: (
        <ComposingWithStatus status={item.status}>
          {item.name}
        </ComposingWithStatus>
      ),
      completedDate: <DateLabel label={item.publishedDate} />,
      status: <ComposingStatus status={item.status} />,
      actions: <PublishProductAction item={item} onChange={refreshPage} />,
    };
  };

  const handleOnSubmit = (keyword: string) => {
    setParams((prev) => {
      return {
        ...prev,
        searchTerm: keyword,
        page: 1,
      };
    });
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return {
        ...prev,
        ...params,
      };
    });
  };

  return (
    <>
      <Card className={CardClassEnum.rectangle_box}>
        <ProductLicenseFilterForm
          keyword={params.searchTerm}
          onSubmit={handleOnSubmit}
        />
      </Card>

      <TablePagination
        params={params}
        isLoading={isFetching || isLoading}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </>
  );
};

export const ProductLicenseItem = (props: {
  item: ProductRes;
  onChange: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <ProductItemStyle className={`product-student-item`}>
      <div className={'product-info'}>
        <ThumbnailsImg
          type={'icon'}
          src={props.item.icon}
          ratio={ImageScale.product.ratio}
        />
      </div>

      <div className={'product-title'}>
        <ComposingWithStatus status={props.item.status}>
          <H5>{props.item.name}</H5>
        </ComposingWithStatus>

        <InputGroup label={t('label.createdDate')}>
          <DateLabel label={props.item.createdDate} />
        </InputGroup>

        <InputGroup label={t('label.publishedDate')}>
          <DateLabel label={props.item.publishedDate} />
        </InputGroup>
      </div>

      <div className={'product-actions'}>
        <PublishProductAction item={props.item} onChange={props.onChange} />
      </div>
    </ProductItemStyle>
  );
};
