import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ButtonGroupStyle = styled.div`
  &.align-left {
    .ant-btn {
      margin-right: 0.5em;
    }
  }

  &.align-center {
    display: flex;
    justify-content: center;

    .ant-btn {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  &.align-right {
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }

  &.align-space-between {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }

  &.small {
    &.align-left {
      .ant-btn {
        margin-right: 0.5em;
      }
    }

    &.align-center {
      .ant-btn {
        margin-left: 0.25em;
        margin-right: 0.25em;
      }
    }

    &.align-right {
      .ant-btn {
        margin-left: 0.5em;
      }
    }

    &.align-space-between {
      gap: 0.5em;
    }
  }
`;

const ButtonGroup = (props: {
  size?: 'small' | 'middle' | 'large';
  type?: 'left' | 'right' | 'center' | 'space-between';
  style?: Record<string, any>;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <ButtonGroupStyle
      className={`button-group align-${props.type ?? 'left'} ${
        props.className ?? ''
      } ${props.size ?? ''}`}
      style={props.style ?? {}}
    >
      {props.children}
    </ButtonGroupStyle>
  );
};

export default ButtonGroup;
