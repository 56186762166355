import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GridWordProps } from '@cms/comps/game/word-search/WordSearchUtils';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { WordPuzzleUtils } from '@cms/comps/game/word-puzzle/WordPuzzleUtils';
import { CompMode } from '@cms/ComponentInteface';
import { CLEAR_KEYCODE_ARRAY } from '@cms/utils/ComponentUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import {
  MOVING_KEYCODE,
  MovingType,
  WordPuzzleCompProps,
} from '@cms/comps/game/word-puzzle/WordPuzzleComp';

const WordPuzzleContext = createContext({});

export interface WordPuzzleContextProps {
  gridWords: GridWordProps[][];
  setGridWords: (gridWords: GridWordProps[][]) => void;
  selectedWord: string;
  setSelectedWords: (value: string) => void;
  correctWordIds: string[];

  currentBox: GridWordProps | null;
  setCurrentBox: (value: GridWordProps) => void;

  handleOnKeyPress: (event: any) => void;
}

export const WordPuzzleCompProvider = (props: {
  item: WordPuzzleCompProps;

  onCompleted: (points: number) => void;
  onCorrectPart: (points: number) => void;
  children: any;
}) => {
  const { mode } = useResourceContext();
  const puzzleWordUtils = useRef<WordPuzzleUtils | undefined>();

  const [action, setAction] = useState({ type: '', timestamp: 0 });

  const [gridWords, setGridWords] = useState<GridWordProps[][]>([]);
  const [currentBox, setCurrentBox] = useState<GridWordProps | null>(null);
  const [correctWordIds, setCorrectWordIds] = useState<string[]>([]);

  const [selectedWord, setSelectedWords] = useState<string>(() => {
    if (
      mode === CompMode.COMPOSE &&
      props.item.configuration.defaultWord != null &&
      props.item.configuration.defaultWord !== ''
    ) {
      return props.item.configuration.defaultWord;
    } else if (props.item.configuration.sourceItems.length > 0) {
      return props.item.configuration.sourceItems[0].id;
    } else if (props.item.configuration.targetItems!.length > 0) {
      return props.item.configuration.sourceItems[0].id;
    } else {
      return '';
    }
  });

  useEffect(() => {
    puzzleWordUtils.current = new WordPuzzleUtils(
      props.item.configuration.sourceItems,
      props.item.configuration.targetItems!,
      props.item.setting
    );
    setGridWords(puzzleWordUtils.current.getResult());
  }, [JSON.stringify(props.item)]);

  useEffect(() => {
    if (
      correctWordIds.length ===
      props.item.configuration.sourceItems?.length +
        props.item.configuration.targetItems?.length
    ) {
      props.onCompleted(correctWordIds.length);
    } else {
      props.onCorrectPart(correctWordIds.length);
    }
  }, [correctWordIds]);

  useEffect(() => {
    if (
      currentBox != null &&
      currentBox.wordId != null &&
      currentBox.wordId.length > 0
    ) {
      if (!currentBox.wordId.includes(selectedWord)) {
        setSelectedWords(currentBox.wordId[0]);
      }
    }
  }, [JSON.stringify(currentBox)]);

  useEffect(() => {
    if (
      action.type === 'check-answer' &&
      selectedWord != null &&
      selectedWord.trim() !== ''
    ) {
      let isCorrect = true;

      gridWords.forEach((words) => {
        words.forEach((word) => {
          if (
            word.wordId &&
            word.wordId.includes(selectedWord) &&
            word.answer !== word.char
          ) {
            isCorrect = false;
          }
        });
      });

      if (isCorrect) {
        setGridWords((prevState) => {
          return [...prevState].map((rows) => {
            return rows.map((col) => {
              if (col.wordId && col.wordId.includes(selectedWord)) {
                return { ...col, correct: true };
              } else {
                return col;
              }
            });
          });
        });

        setCorrectWordIds((prevState) => {
          return [...prevState, selectedWord];
        });
      }
    }
  }, [action]);

  const handleOnKeyPress = (event: any) => {
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    const keyCode = event.keyCode;

    // then check keycode.
    if (keyCode === 16) {
      return false;
    } else if (CLEAR_KEYCODE_ARRAY.includes(keyCode)) {
      return false;
    } else if (MOVING_KEYCODE.includes(keyCode)) {
      if (keyCode === 37 || keyCode === 38) {
        movingToNextBox(MovingType.previous);
      } else {
        movingToNextBox(MovingType.next);
      }
    } else {
      const str = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );

      if (regex.test(str)) {
        updateGridValue(str.charAt(0));
      } else {
        updateGridValue('');
      }
    }
  };

  const updateGridValue = (value: string) => {
    if (currentBox) {
      setGridWords((prevState) => {
        const results = [...prevState];
        results[currentBox.row][currentBox.column].answer = value;
        return results;
      });

      movingToNextBox(MovingType.next);
      setAction({
        type: 'check-answer',
        timestamp: DateAndTimeUtils.getCurrentTime(),
      });
    }
  };

  const movingToNextBox = (type: MovingType) => {
    if (currentBox != null && selectedWord != null && selectedWord !== '') {
      const sourceItems = props.item.configuration.sourceItems.filter(
        (word) => {
          return word.id === selectedWord;
        }
      );

      if (sourceItems.length > 0) {
        const word = sourceItems[0];

        if (
          type === MovingType.next &&
          word.offset != null &&
          currentBox.column <
            word.offset.column + word.word.data.trim().length - 1
        ) {
          setCurrentBox(gridWords[currentBox.row][currentBox.column + 1]);
        } else if (
          type === MovingType.previous &&
          word.offset != null &&
          currentBox.column > word.offset.column
        ) {
          setCurrentBox(gridWords[currentBox.row][currentBox.column - 1]);
        } else {
          setCurrentBox(null);
        }
      } else if (
        props.item.configuration.targetItems != null &&
        props.item.configuration.targetItems.length > 0
      ) {
        const targetItems = props.item.configuration.targetItems.filter(
          (word) => {
            return word.id === selectedWord;
          }
        );

        if (targetItems.length > 0) {
          const theWord = targetItems[0];

          if (
            type === MovingType.next &&
            theWord.offset != null &&
            currentBox.row <
              theWord.offset.row + theWord.word.data.trim().length - 1
          ) {
            setCurrentBox(gridWords[currentBox.row + 1][currentBox.column]);
          } else if (
            type === MovingType.previous &&
            theWord.offset != null &&
            currentBox.row > theWord.offset.row
          ) {
            setCurrentBox(gridWords[currentBox.row - 1][currentBox.column]);
          } else {
            setCurrentBox(null);
          }
        }
      }
    }
  };

  return (
    <WordPuzzleContext.Provider
      value={{
        gridWords,
        setGridWords,
        selectedWord,
        setSelectedWords,
        correctWordIds,
        currentBox,
        setCurrentBox,
        handleOnKeyPress,
        updateGridValue,
      }}
    >
      {props.children}
    </WordPuzzleContext.Provider>
  );
};

export const useWordPuzzleContext = () => {
  const context = useContext(WordPuzzleContext);

  if (!context) {
    throw new Error('You must wrap container by ResourceViewContext.Provider');
  }
  return context as WordPuzzleContextProps;
};
