import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Button, notification, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import ContentEditor from '@components/editor/WysyEditor';
import KeywordInput from '@components/input/KeywordInput';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import SingleUpload from '@components/input/SingleUpload';
import { ImageScale } from '@utils/ImageScale';
import IconImage from '@components/images/IconImage';
import { IconUtils } from '@utils/IconUtils';
import YoutubeInput from '@components/input/YoutubeInput';
import {
  ProductRes,
  UpdateProductMetadataReq,
} from '@modules/product/services/product_model';

const initData = {
  productId: -1,

  name: '',
  keyword: '',
  description: '',

  instruction: '',
  shortInstruction: '',

  video: '',
  thumbnails: '',
};

export default function ProductMetadata(props: {
  type: 'publish' | 'edit';
  productId: number;
  onCancel: () => void;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();

  const [getProduct, { data, isSuccess }] =
    ProductAPI.endpoints.getProduct.useLazyQuery({});

  const [updateMetadata, { isLoading }] =
    ProductAPI.endpoints.updateMetadata.useMutation({});

  const [ready, setReady] = useState(false);
  const [keywords, setListKeywords] = useState<string[]>([]);

  useEffect(() => {
    setReady(false);
    getProduct(props.productId);
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      updateProductData(data);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initData,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateProductMetadataReq = {
        productId: values.productId,
        name: values.name,

        keyword: keywords.join(','),
        description: values.description,

        instruction: values.instruction,
        shortInstruction: values.shortInstruction,

        video: values.video,
        thumbnails: values.thumbnails,
      };

      updateMetadata(request)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('product.warning.update_product_metadata_success'),
            description: t(
              'product.warning.update_product_metadata_success_message'
            ),
            placement: 'bottomRight',
          });
          if (props.onSuccess) {
            props.onSuccess();
          }
        })
        .catch(() => {
          notification.error({
            message: t('product.warning.update_product_metadata_error'),
            description: t(
              'product.warning.update_product_metadata_error_message'
            ),
            placement: 'bottomRight',
          });
        });
    },
  });

  const updateProductData = (data: ProductRes) => {
    formik.setValues({
      productId: data.productId,
      name: data.name,
      keyword: data.keyword,
      description: data.description,

      instruction: data.instruction,
      shortInstruction: data.shortInstruction,

      thumbnails: data.thumbnails,
      video: data.video,
    });

    setListKeywords(
      data.keyword != null && data.keyword.trim() !== ''
        ? data.keyword.split(',')
        : []
    );
    setReady(true);
  };

  const handleOnFileUploaded = (thumbnails: string) => {
    formik.setFieldValue('thumbnails', thumbnails);
  };

  return (
    <>
      <div className={'update-product-info'}>
        {!ready && (
          <>
            <Skeleton paragraph={{ rows: 4 }} />
            <Skeleton paragraph={{ rows: 4 }} />
          </>
        )}

        {ready && (
          <FormikProvider value={formik}>
            <Input
              required={true}
              name="name"
              type="text"
              label={t('label.name')}
              placeholder={t('label.name')}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            <InputGroup label={t('label.keyword')}>
              {data && (
                <KeywordInput
                  keywords={data.keyword}
                  placeholder={t('label.enter_keyword')}
                  enterButton={t('label.add')}
                  size={'large'}
                  onKeywordChange={(values) => setListKeywords(values)}
                />
              )}
            </InputGroup>

            <Input
              label={t('label.description')}
              type={'textarea'}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              maxLength={512}
              style={{ minHeight: 120 }}
            />

            <InputGroup label={t('label.thumbnails')}>
              <SingleUpload
                scale={ImageScale.scale169.scale}
                accept={'image/jpg, image/jpeg, image/png'}
                multiple={false}
                onSuccessFunc={handleOnFileUploaded}
              >
                <IconImage
                  src={formik.values.thumbnails}
                  width={ImageScale.scale169.width}
                  height={ImageScale.scale169.height}
                />
              </SingleUpload>
            </InputGroup>

            <YoutubeInput
              label={t('label.youtube_url')}
              name="video"
              onChange={formik.handleChange}
              value={formik.values.video}
            />

            <InputGroup label={t('label.instruction')}>
              {data && (
                <ContentEditor
                  initValue={data?.instruction}
                  onChange={(value: string) =>
                    formik.setFieldValue('instruction', value)
                  }
                />
              )}
            </InputGroup>

            <InputGroup label={t('label.short_instruction')}>
              {data && (
                <ContentEditor
                  initValue={data?.shortInstruction}
                  onChange={(value: string) =>
                    formik.setFieldValue('shortInstruction', value)
                  }
                />
              )}
            </InputGroup>

            {props.type === 'publish' && (
              <div className={'product-publish-step'}>
                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                  size={'large'}
                >
                  {IconUtils.actions.previous} {t('button.step.previous')}
                </Button>

                <Button
                  type={'primary'}
                  shape={'round'}
                  loading={isLoading}
                  onClick={formik.submitForm}
                  size={'large'}
                >
                  {t('button.step.next')} {IconUtils.actions.next}
                </Button>
              </div>
            )}

            {props.type === 'edit' && (
              <div className="submit-container" style={{ marginTop: '2em' }}>
                <ButtonGroup>
                  <Button
                    type={'primary'}
                    shape={'round'}
                    loading={isLoading}
                    icon={IconUtils.actions.save}
                    onClick={formik.submitForm}
                  >
                    {t('button.update')}
                  </Button>

                  <Button
                    type="default"
                    shape={'round'}
                    onClick={props.onCancel}
                  >
                    {t('button.cancel')}
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </FormikProvider>
        )}
      </div>
    </>
  );
}
