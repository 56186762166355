import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { MultipleChoiceCompProps } from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';

export const MultipleChoiceExampleData: MultipleChoiceCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.MULTIPLE_CHOICE,

  configuration: {
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
      {
        label: 'd',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Grape',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'vertical',
    showLabel: true,
    showOption: true,
    multiple: false,
  },
};
