import { useState } from 'react';
import { ResponsiveScreen, ResponsiveSize } from '@hooks/useMedia';
import { useOnContainerResize } from '@hooks/useOnContainerResize';

const useContainerMediaQuery = (containerRef: any) => {
  const [value, setValue] = useState<ResponsiveScreen>(ResponsiveScreen.empty);

  const updateLayout = (container_w: number) => {
    if (container_w >= ResponsiveSize.xxl) {
      setValue(ResponsiveScreen.xxl);
    } else if (container_w >= ResponsiveSize.xl) {
      setValue(ResponsiveScreen.xl);
    } else if (container_w >= ResponsiveSize.lg) {
      setValue(ResponsiveScreen.lg);
    } else if (container_w >= ResponsiveSize.md) {
      setValue(ResponsiveScreen.md);
    } else if (container_w >= ResponsiveSize.xs) {
      setValue(ResponsiveScreen.xs);
    } else if (value !== ResponsiveScreen.sm) {
      setValue(ResponsiveScreen.sm);
    }
  };

  useOnContainerResize(containerRef, updateLayout, 500);

  return value;
};

export default useContainerMediaQuery;
