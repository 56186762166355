import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { HolderOutlined } from '@ant-design/icons';
import {
  CompFeedbackProps,
  CompFeedbackResultIcon,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import React, { useEffect, useState } from 'react';
import {
  OrderingAnsProps,
  OrderingOptionProps,
  OrderingProps,
} from '@cms/comps/interact/ordering/OrderingComp';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { CompAnswerProps } from '@cms/ComponentInteface';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import DragNDropContext, {
  DragNDropAction,
  DragNDropActionType,
} from '@components/react-dnd-bt/DragNDropContext';

export const OrderingItems = (props: {
  disabled: boolean;
  item: OrderingProps;
  answer: OrderingAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [answer, setAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedback] = useState<CompFeedbackProps>(initCompFeedback);

  const [orderingItems, setOrderingItems] = useState<OrderingOptionProps[]>(
    () => {
      return [...props.item.configuration.sourceItems];
    }
  );

  useEffect(() => {
    setOrderingItems([...props.item.configuration.sourceItems]);
  }, [props.item]);

  useEffect(() => {
    if (props.answer && props.answer.answer) {
      const orderingMapping: Record<string, number> = {};

      props.answer.answer.forEach((ans, index) => {
        orderingMapping[ans] = index;
      });

      const newOrdering = [...props.item.configuration.sourceItems].sort(
        (a, b) => {
          return orderingMapping[a.label] - orderingMapping[b.label];
        }
      );
      setOrderingItems(newOrdering);
    } else {
      setOrderingItems([...props.item.configuration.sourceItems]);
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedback({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedback(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  const handleOnChange = (action: DragNDropAction) => {
    if (action.action === DragNDropActionType.order) {
      const sourceData = orderingItems.filter((s) => {
        return s.label === action.params?.new?.draggableId;
      });
      const newDatas = [...orderingItems];

      newDatas.splice(action.params!.old!.index, 1);
      newDatas.splice(action.params!.new!.index, 0, sourceData[0]);

      const newAnswer = newDatas.map((dt) => {
        return dt.label;
      });

      setOrderingItems(newDatas);
      setAnswer({
        value: newAnswer,
        triggerChange: true,
      });
    }
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      <DragNDropContext id={props.item.id} onChange={handleOnChange}>
        <DropItem droppableId={'ordering'} acceptType={'ordering-item'}>
          {orderingItems.map((option, index) => {
            return (
              <DragItem
                key={JSON.stringify(option) + '_' + index}
                acceptType={'ordering-item'}
                index={index}
                draggableId={option.label}
              >
                <ComposeModeContent>
                  <HolderOutlined />
                </ComposeModeContent>
                <CompFeedbackResultIcon answer={option.label} />
                <ContentViewer contentData={option.content} />
              </DragItem>
            );
          })}
        </DropItem>
      </DragNDropContext>
    </CompFeedbackContextProvider>
  );
};
