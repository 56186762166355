import {
  CalendarOutlined,
  ClockCircleOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { H4 } from '@components/typography';
import React, { useState } from 'react';
import { Avatar, Button, notification } from 'antd';
import { CircleAvatar, UserAvatar } from '@components/avatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NavLink from '@components/button/NavLink';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import UpdateSessionDataModal, {
  UpdateSessionAction,
} from '@modules/calendar/components/UpdateSessionDataModal';
import { DateLabel } from '@components/text/DateLabel';
import { useTheme } from '@app/styled/StyledProvider';
import { SiteMap } from '@router/SiteMap';
import { SessionRes } from '@services/model/session';

const CalendarEventItem = (props: {
  type: 'view' | 'edit';
  session: SessionRes;
  onChange: () => void;
  onView?: (event: SessionRes) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [action, setAction] = useState(UpdateSessionAction.DEFAULT);
  const [title, updateTitle] = useState('');

  const handleOnEdit = () => {
    setAction(UpdateSessionAction.METADATA);
  };

  const handleOnView = () => {
    if (props.type === 'view' && props.onView) {
      props.onView(props.session);
    }
  };

  const handleOnAddStudent = () => {
    if (props.type === 'edit') {
      setAction(UpdateSessionAction.STUDENT);
    }
  };

  const handleOnChange = () => {
    setAction(UpdateSessionAction.DEFAULT);
    props.onChange();
  };

  const warningResult = (isSuccess: boolean) => {
    if (isSuccess) {
      notification.success({
        message: t('class_room.warning.update_success'),
        description: t('class_room.warning.update_success_message'),
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: t('class_room.warning.update_error'),
        description: t('class_room.warning.update_error_message'),
        placement: 'bottomRight',
      });
    }
  };

  return (
    <CalendarEventItemStyle className={'session-event-item'}>
      {props.type === 'view' ? (
        <H4 onClick={handleOnView}>{props.session.name}</H4>
      ) : (
        <H4 onClick={handleOnEdit}>
          {props.session.name} <EditOutlined />
        </H4>
      )}

      <div dangerouslySetInnerHTML={{ __html: props.session.note }} />

      <p>
        <CalendarOutlined />
        &nbsp;
        <DateLabel label={props.session.startDate} /> &nbsp; &nbsp; &nbsp;
        <ClockCircleOutlined />
        &nbsp; {props.session.duration}
      </p>

      <div className={'student-group'}>
        <div className={'students'} onClick={handleOnAddStudent}>
          <p>
            <label>{t('label.students')}</label>
          </p>

          <Avatar.Group
            maxCount={6}
            maxStyle={{
              color: theme.app.primary,
              backgroundColor: theme.app.primary_bgr,
            }}
          >
            {props.session.students.map((st) => {
              return <UserAvatar key={st.userId} src={st.avatar} size={40} />;
            })}

            <CircleAvatar size={40} icon={<PlusOutlined />} />
          </Avatar.Group>

          <div className={'actions'} style={{ float: 'right' }}>
            <NavLink
              href={SiteMap.class_room.class_room_gen(
                props.session.code,
                '',
                'dashboard'
              )}
            >
              <Button type={'primary'} shape={'round'}>
                {t('class_room.actions.join')}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>

      {action !== UpdateSessionAction.DEFAULT && (
        <CustomModal
          header={title ? title : t('session.actions.update_time')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <UpdateSessionDataModal
              action={action}
              warningResult={warningResult}
              sessionData={props.session}
              onClose={handleOnChange}
              onTabChange={() =>
                updateTitle(title ? title : t('session.actions.update_time'))
              }
            />
          }
          onCloseFunc={() => setAction(UpdateSessionAction.DEFAULT)}
        />
      )}
    </CalendarEventItemStyle>
  );
};

export default CalendarEventItem;

const CalendarEventItemStyle = styled.div`
  p {
    margin-bottom: 0.5em;
  }
`;
