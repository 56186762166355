import React from 'react';

import styled from 'styled-components';
import { CircleUnderGroupItem } from '@cms/comps/interact/circle-underline/CircleUnderGroupItem';
import { useCircleUnderlineContext } from '@cms/comps/interact/circle-underline/CircleUnderlineCompContext';

const CircleUnderlineGroupItems = () => {
  const { modifiedTime, targetItems } = useCircleUnderlineContext();

  return (
    <CircleUnderlineGroupItemsStyle className={`circle-underline-group-items`}>
      {targetItems &&
        targetItems.map((text, index) => {
          return (
            <CircleUnderGroupItem
              key={text.label + '_' + text.content.id + '_' + modifiedTime}
              drop={text}
              index={index}
            />
          );
        })}
    </CircleUnderlineGroupItemsStyle>
  );
};

export default CircleUnderlineGroupItems;

const CircleUnderlineGroupItemsStyle = styled.div`
  .circle-underline-group-item {
    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }
  }
`;
