import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import LicenseProducts from '@modules/product/container/student/LicenseProducts';
import StudentLessonGroup from '@modules/product/container/student/StudentLessonGroup';

export default function MyStudentProductPage() {
  const { t } = useTranslation();

  return (
    <Tabs
      defaultActiveKey="products"
      tabPosition={'top'}
      className={'navigation-right'}
      items={[
        {
          key: 'products',
          label: <h2>{t('header.products')}</h2>,
          children: <LicenseProducts />,
        },
        {
          key: 'lessons',
          label: <h2>{t('header.lessons')}</h2>,
          children: <StudentLessonGroup />,
        },
      ]}
    />
  );
}
