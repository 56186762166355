import React from 'react';
import Head from '@app/header';
import DragNDropBeautyExample from '@components/react-dnd-bt/DragNDropExample';
import { H1 } from '@components/typography';
import { useTranslation } from 'react-i18next';
// import ReactDndExample from '@components/react-dnd/example/Example';
// import { TinyEditorExample } from '@components/editor/TinyMCE';
// import ExampleDragNDrop from '@components/drag-n-drop/Example';

const DnDTestingExample = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t('organization')} - Template</title>
        <meta name="description" content="Parent registration" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div>
        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-01'} />

        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-2'} />

        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-3'} />

        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-4'} />

        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-5'} />

        <H1>Drag N Drop Beauty.</H1>

        <DragNDropBeautyExample id={'example-6'} />
        {/*<TinyEditorExample />*/}

        {/*<ExampleDragNDrop />*/}

        {/*<ReactDndExample />*/}
      </div>
    </>
  );
};

export default DnDTestingExample;
