import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { CheckBoxCompProps } from '@cms/comps/interact/checkbox/CheckBoxComp';

export const CheckBoxExampleData: CheckBoxCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.CHECK_BOX,

  configuration: {
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Agree',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Disagree',
        },
      },
    ],
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: '__________',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'vertical',
    showCheckbox: true,
    showLabel: false,
    showOption: true,
    multiple: false,
  },
};

export const CheckBoxImageExampleData: CheckBoxCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.CHECK_BOX,
  configuration: {
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Is the A is correct?: __________',
          editType: 'simple',
        },
      },
    ],
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.IMAGE,
          data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/Hh2Bi97LP1Fch68n.incorrect.jpg',
          width: 2,
          align: 'center',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.IMAGE,
          data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/DsPT8FJgw6hSjSTl.correct.jpg',
          width: 2,
          align: 'center',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'horizontal',
    showOption: true,
    multiple: false,
    showLabel: false,
  },
};
