import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import { GlossaryGameCompProps } from '@cms/comps/game/GameUtils';
import WordBoxItem from '@cms/comps/game/word-box/WordBoxItem';
import styled from 'styled-components';
import {
  GlossaryGameActionEnum,
  useGlossaryGameItemContext,
} from '@cms/lesson-template/glossary-game/GlossaryGameContext';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { GlossaryWordBank } from '@cms/comps/game/GlossaryWordBank';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';

export interface WordBoxSettingProps extends CompInteractSettingProps {
  layout: 'middle';
  tryTimes: number;
  scramble: boolean;
}

export interface WordBoxCompProps extends GlossaryGameCompProps {
  setting: WordBoxSettingProps;
}

export function WordBoxComp(props: {
  item: WordBoxCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);

  const context = useGlossaryGameItemContext();

  const [wordBanks] = useState(() => {
    if (props.item.setting.scramble) {
      return [...props.item.configuration.sourceItems].sort(
        () => 0.5 - Math.random()
      );
    } else {
      return props.item.configuration.sourceItems;
    }
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(wordBanks[currentIndex]);
  const [isLastQuestion, setIsLastQuestion] = useState(false);

  useEffect(() => {
    setCurrentWord(wordBanks[currentIndex]);

    if (currentIndex === wordBanks.length - 1) {
      setIsLastQuestion(true);
    }
  }, [currentIndex, wordBanks]);

  useEffect(() => {
    if (
      context.action &&
      context.action.type === GlossaryGameActionEnum.view_next_part
    ) {
      viewNextWord();
    }
  }, [context.action]);

  const handleOnIncorrect = () => {
    if (isLastQuestion) {
      context.dispatchAction(
        GlossaryGameActionEnum.incorrect,
        0,
        CompTypeEnum.WORD_BOX
      );
    } else {
      context.dispatchAction(
        GlossaryGameActionEnum.incorrect_part,
        0,
        CompTypeEnum.WORD_BOX
      );
    }
  };

  const handleOnCorrect = () => {
    if (isLastQuestion) {
      context.dispatchAction(
        GlossaryGameActionEnum.correct,
        1,
        CompTypeEnum.WORD_BOX
      );
    } else {
      context.dispatchAction(
        GlossaryGameActionEnum.correct_part,
        1,
        CompTypeEnum.WORD_BOX
      );
    }
  };

  const viewNextWord = () => {
    if (!isLastQuestion) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const handleComponentChange = (newComps: WordBoxCompProps) => {
    const answer = newComps.configuration.sourceItems.map((ans) => {
      return ans.word.data;
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );
    setShowComponent(true);
  };

  return (
    <WordBoxCompStyle className={'comp comp-word-box'}>
      {showComponent && (
        <>
          <WordBoxItem
            item={currentWord}
            setting={props.item.setting}
            onIncorrect={handleOnIncorrect}
            onCorrect={handleOnCorrect}
          />

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </WordBoxCompStyle>
  );
}

const WordBoxCompStyle = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  display: flex;
  border: 1px solid #ccc;

  .word-box-item {
    width: 100%;
    height: 100%;
    padding: var(--cms-padding-option, 0.5em);
  }
`;
