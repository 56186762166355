import React, { createContext, ReactNode, useContext, useState } from 'react';

import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';

import { ContentOperationEnum } from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { DropDownOptionProps } from '@cms/comps/interact/dropdown/DropdownComp';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import { useFibWordCompContext } from '@cms/comps/interact/inline/FibWordCompContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';

const FibWordEditableContext = createContext({
  editItem: '',
  changeToEdit: (id: string) => {
    console.log(id);
  },

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: DropDownOptionProps
  ) => {
    console.log(type, index, option);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: DropDownOptionProps
  ) => {
    console.log(operation, index, option);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: DropDownOptionProps
  ) => {
    console.log(data, index, option);
  },
});

export const FibWordEditableProvider = (props: { children: ReactNode }) => {
  const {
    modifiedTime,
    answers,
    targetItems,
    updateOptions,
    updateAnswers,
    updateComponent,
  } = useFibWordCompContext();

  const [editItem, setEditItem] = useState('');

  const changeToEdit = (editId: string) => {
    setEditItem(editId);
  };

  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: DropDownOptionProps
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    const newTargetItems = OptionGroupUtils.updateOption(
      index,
      newOption,
      targetItems
    );

    updateOptions(newTargetItems);
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: DropDownOptionProps
  ) => {
    let newOptions: DropDownOptionProps[] = [];
    let newAnswers: string[] = [];

    if (operation === ContentOperationEnum.ADD) {
      newOptions = OptionGroupUtils.insertOption(
        index,
        option,
        targetItems,
        OptionTypeEnum.NUMBER
      );

      newAnswers = handleInsertGroup(index, answers);
    } else {
      newOptions = OptionGroupUtils.removeOption(
        index,
        targetItems,
        OptionTypeEnum.NUMBER
      );

      newAnswers = handleRemoveGroup(index + 1, answers);
    }

    updateOptions(newOptions);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: DropDownOptionProps
  ) => {
    const newOption = { label: option.label, content: { ...data } };
    const newOptions = OptionGroupUtils.updateOption(
      index,
      newOption,
      targetItems
    );

    updateOptions(newOptions);
    changeToEdit('');
  };

  return (
    <FibWordEditableContext.Provider
      value={{
        editItem,
        changeToEdit,

        updateOptionType,
        updateGroupOptions,
        updateOptionData,
      }}
    >
      {props.children}

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>
    </FibWordEditableContext.Provider>
  );
};

export const useFibWordEditable = () => {
  const context = useContext(FibWordEditableContext);
  if (!context) {
    throw new Error('You must wrap container by FibWordEditableProvider');
  }
  return context;
};

const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseFibWordAnswer(ans);

    if (ansData.index > index + 1) {
      return generateFibWordAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseFibWordAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseFibWordAnswer(ans);

      if (ansData.index > index) {
        return generateFibWordAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

const parseFibWordAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    optionLabel: answerPart,
  };
};

export const generateFibWordAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};
