import React from 'react';
import { connect } from 'formik';
import { get as getPath } from 'lodash';
import { Radio as AntRadio, RadioProps as AntRadioProps } from 'antd';
import { ErrMessageStyle, StyledInput } from '@components/input/style';

interface RadioOptions {
  value: any;
  label: any;
  disabled?: boolean;
}

export interface RadioProps extends AntRadioProps {
  name: string;
  label?: string;
  formik?: any;

  options: RadioOptions[];
  addedClass?: string;
}

const Radio = ({
  name,
  options,
  addedClass,
  label,
  formik,
  ...props
}: RadioProps) => {
  return (
    <StyledInput
      className={'form-group' + (addedClass ? ' ' + addedClass : '')}
    >
      {label && <div className={'ant-form-label'}>{label}</div>}

      <div className={'ant-form-controls'}>
        <AntRadio.Group name={name} {...props}>
          {options.map((op) => {
            return (
              <AntRadio value={op.value} key={op.value} disabled={op.disabled}>
                {op.label}
              </AntRadio>
            );
          })}
        </AntRadio.Group>

        {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
          <ErrMessageStyle className="error-message">
            {getPath(formik?.errors, name)}
          </ErrMessageStyle>
        )}
      </div>
    </StyledInput>
  );
};

export default connect(Radio);
