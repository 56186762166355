import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import Checkbox from '@components/input/Checkbox';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import { WordScrambleSettingProps } from '@cms/comps/game/word-scramble/WordScrambleComp';
import { useTranslation } from 'react-i18next';

export const WordScrambleSetting = (props: {
  setting: WordScrambleSettingProps;
  onSettingChange: (newSetting: WordScrambleSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <Input
          name="rows"
          type="number"
          label={t('component.glossary_word.max_incorrect_times')}
          placeHolder={t('component.glossary_word.max_incorrect_times')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.tryTimes}
          min={1}
          max={5}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={'middle'} value={'middle'}>
              {t('component.glossary_word.middle_content')}
            </Select.Option>

            <Select.Option key={'top'} value={'top'}>
              {t('component.glossary_word.top_content')}
            </Select.Option>

            <Select.Option key={'bottom'} value={'bottom'}>
              {t('component.glossary_word.bottom_content')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Checkbox
          name={'scramble'}
          checked={formik.values.scramble}
          value={formik.values.scramble}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.flash_card.scramble')}
        </Checkbox>
      </FormikProvider>
    </SettingStyle>
  );
};
