import React from 'react';

import {
  ResourceContextProvider,
  ResourceItem,
} from '@cms/context/ResourceContextProvider';
import {CompProps} from "@cms/ComponentInteface";

export default function ResourceViewItem(props: {resourceId: number,  components: CompProps[] }) {
  return (
    <ResourceContextProvider
      resourceId={props.resourceId}
      comps={props.components}
      answer={[]}
      feedback={[]}
    >
      <ResourceItem />
    </ResourceContextProvider>
  );
}
