import React from 'react';
import { CalendarContextProvider } from '@modules/calendar/context/CalendarContext';
import { Col, Row } from 'antd';
import UserCalendar from '@modules/calendar/container/UserCalendar';
import { Default_Gutter } from '@components/grid';

const MyCalendarPage = (props: { classId?: number }) => {
  return (
    <CalendarContextProvider>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <UserCalendar classId={props.classId} />
        </Col>
      </Row>
    </CalendarContextProvider>
  );
};

export default MyCalendarPage;
