import {
  CompProps,
  CompMode,
  CompAnswerProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';

import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { TemplateCompEditable } from './TemplateCompEditable';
import styled from 'styled-components';

export interface TemplateCompProps extends CompProps {
  type: CompTypeEnum.TEMPLATE;

  configuration: {
    content: string;
  };
}

export function TemplateComp(props: {
  item: TemplateCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: string) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <TemplateCompStyle className={'comp comp-template-content'}>
        <TemplateCompEditable item={props.item} onChange={handleOnChange} />
      </TemplateCompStyle>
    );
  } else {
    return (
      <TemplateCompStyle
        className={`comp comp-template-content ${props.item.setting?.className}`}
      >
        <div
          className={'template-content'}
          dangerouslySetInnerHTML={{ __html: props.item.configuration.content }}
        />
      </TemplateCompStyle>
    );
  }
}

const TemplateCompStyle = styled.div`
  position: relative;

  p {
    margin-bottom: var(--cms-padding-section, 2em);
  }
`;
