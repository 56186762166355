import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { GradingExerciseAPI } from '@modules/assignments/service/ExerciseGradingAPI';
import { ExerciseFilter } from '@modules/assignments/components/ExerciseFilter';
import { CompMode } from '@cms/ComponentInteface';
import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { Default_Gutter } from '@components/grid';

export default function GradeAssignmentPage(props: {
  assignmentInstanceId: number;
  exerciseId?: number;
}) {
  const [findExercises, { data, isSuccess }] =
    GradingExerciseAPI.endpoints.findExercises.useLazyQuery();

  const [exerciseId, setExerciseId] = useState(
    props.exerciseId != null && props.exerciseId > 0 ? props.exerciseId : -1
  );

  useEffect(() => {
    findExercises(props.assignmentInstanceId);
  }, [props.assignmentInstanceId]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.length > 0) {
        setExerciseId(data[0].exerciseId);
      } else {
        console.log('All is grade....');
      }
    }
  }, [data]);

  const handleOnSelect = (exerciseId: number) => {
    setExerciseId(exerciseId);
  };

  return (
    <>
      {data && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <div style={{ padding: '1em 2em 0em' }}>
              <ExerciseFilter
                name={'exerciseId'}
                value={exerciseId}
                items={data}
                onChange={handleOnSelect}
              />
            </div>
          </Col>
        </Row>
      )}

      {exerciseId > 0 && (
        <ViewExercisePage
          key={exerciseId}
          type={CompMode.GRADING_ASSIGNMENT}
          exerciseId={Number(exerciseId)}
        />
      )}
    </>
  );
}
