import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import React from 'react';

import { ComponentScoringItem } from '../common/ComponentScoringItem';
import { ComponentGradingItem } from '../common/ComponentGradingItem';
import styled from 'styled-components';
import {
  GradingExerciseMode,
  ReviewExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';

export enum ComponentGradingClassName {
  default = '',
  absolute_position = 'absolute-position',
}

export const ComponentGrading = (props: {
  className?: string;
  feedback: ComponentResponseProps;
}) => {
  return (
    <>
      <GradingExerciseMode>
        <ComponentGradingBgrStyle className={'component-grading-background'} />

        <ComponentGradingItem
          className={props.className}
          feedback={props.feedback}
        />
      </GradingExerciseMode>

      <ReviewExerciseMode>
        <ComponentGradingBgrStyle className={'component-grading-background'} />

        <ComponentScoringItem
          className={props.className}
          feedback={props.feedback}
        />
      </ReviewExerciseMode>
    </>
  );
};

const ComponentGradingBgrStyle = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.06);
  opacity: 0;
  z-index: 0;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0px;
    top: 20px;
    transform: translate(100%, 0%);
  }
`;
