import { FullscreenButton } from '@cms/lesson-template/components/buttons/compose/FullScreenButton';
import { PrintLessonButton } from '@cms/lesson-template/components/buttons/compose/PrintLessonButton';
import React from 'react';
import { ExerciseToolbarStyle } from './style';
import { ReviewExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ReviewExerciseButton';
import { ExitGradingButton } from '@cms/lesson-template/components/buttons/grading/ExitGradingButton';
import ExerciseGradingScoreStatus from '../../buttons/ExerciseGradingScoreStatus';
import { ExitExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ExitExerciseButton';

export const GradingExerciseToolbar = () => {
  return (
    <ExerciseToolbarStyle className={'lesson-toolbar grading-exercise-toolbar'}>
      <div className={'header-group'}>
        <ExitExerciseButton />
      </div>

      <div className={'right-group'}>
        <ExerciseGradingScoreStatus />

        <FullscreenButton />
        <PrintLessonButton />
        <ReviewExerciseButton />
        <ExitGradingButton />
      </div>
    </ExerciseToolbarStyle>
  );
};
