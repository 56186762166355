import { Select, SelectProps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { UserRes } from '@modules/users/services/model';
import { StudentInfoSimple } from '@components/info/StudentInfo';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import useFilterOptions from '@hooks/useFilterOptions';
import { compareKeywords } from '@utils/common';
import { SelectWithSearch } from '@modules/common/components/SelectWithSearch';

export interface SelectStudentProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  name: string;
  excludedIds: number[];
  value?: number;
  onChange?: (value: number) => void;
}

const SelectStudent = (props: SelectStudentProps) => {
  const { t } = useTranslation();

  const [getStudents, { data, isSuccess, isFetching }] =
    StudentAPI.endpoints.getStudents.useLazyQuery();

  const [students, setStudents] = useState<UserRes[]>([]);

  useEffect(() => {
    getStudents({ selectAll: true });
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      if (props.excludedIds) {
        setStudents(
          data.content.filter((st) => {
            return !props.excludedIds.includes(st.userId);
          })
        );
      } else {
        setStudents(data.content);
      }
    }
  }, [data, props.excludedIds]);

  const filter = useFilterOptions<UserRes>(students, (opt, keyword) => {
    const keywords =
      keyword != null && keyword.trim() !== ''
        ? keyword.toLowerCase().trim().split(' ')
        : null;

    return (
      keywords == null ||
      compareKeywords(opt.username, keywords) ||
      compareKeywords(opt.email, keywords) ||
      compareKeywords(opt.phone, keywords) ||
      compareKeywords(opt.firstName + '' + opt.lastName, keywords)
    );
  });

  const handleOnInputChange = (value: number) => {
    if (props.onChange) {
      props.onChange(value);
    } else if (props.formik) {
      props.formik.setFieldValue(props.name, value);
    }
  };

  return (
    <InputGroup label={props.label ?? t('invitation.select_students')}>
      <SelectWithSearch
        placeholder={props.placeholder ?? t('invitation.select_students')}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={props.value ?? props.formik.values[props.name]}
        loading={isFetching || filter.isSearching}
        searchValue={filter.keyword}
        onSearch={filter.onSearch}
      >
        <Select.Option key={-1} value={-1}>
          <>{t('invitation.select_students')}</>
        </Select.Option>

        {filter.options.map((item) => {
          return (
            <Select.Option key={item.userId} value={item.userId}>
              <StudentInfoSimple className={'no-padding'} item={item} />
            </Select.Option>
          );
        })}
      </SelectWithSearch>
    </InputGroup>
  );
};

export default SelectStudent;
