import React from 'react';
import { Col, Row } from 'antd';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useTranslation } from 'react-i18next';
import LoginAdminForm from '../container/LoginAdminForm';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { SiteMap } from '@router/SiteMap';

const LoginAdminPage = () => {
  const { t } = useTranslation();

  const handleLoginSuccess = () => {
    window.location.href = SiteMap.private.dashboard;
  };

  return (
    <>
      <AuthorizeStyle>
        <div className={'home-block-container'}>
          <div className={'gstudy-container'}>
            <H1>{t('login.header')}</H1>

            <h3>System admin</h3>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <LoginAdminForm successHandle={handleLoginSuccess} />
              </Col>
            </Row>
          </div>
        </div>
      </AuthorizeStyle>
    </>
  );
};

export default LoginAdminPage;
