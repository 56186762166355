import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { Button } from 'antd';
import {
  ResourceContextProvider,
  ResourceItem,
} from '../context/ResourceContextProvider';
import { CompMode } from '@cms/ComponentInteface';
import TablePagination, {
  TablePaginationRes,
} from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import { PaginationReq } from '@services/model/PaginationRes';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { ResourceContentItem } from '@modules/product/components/resource/ResourceContentItem';
import { SiteMap } from '@router/SiteMap';
import { ResourceRes } from '@modules/product/services/resource_model';

export const ListEditResourceBanks = (props: {
  isFetching: boolean;
  data?: TablePaginationRes<ResourceRes>;
  params: PaginationReq;
  refresh: (params: PaginationReq) => void;
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: t('label.content'),
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const processDataRow = (record: ResourceRes) => {
    const resource = ResourceUtils.convert(record, '', -1);

    return {
      key: record.resourceId,
      name: record.name,
      content: (
        <div className={'resource-content-viewer show-correct-answer'}>
          <ResourceContextProvider
            mode={CompMode.REVIEW}
            resourceId={record.resourceId}
            comps={resource.components}
            answer={resource.correctAnswer}
            feedback={[]}
          >
            <ResourceItem />
          </ResourceContextProvider>
        </div>
      ),
      actions: (
        <NavLink href={SiteMap.content.resource.compose_gen(record.resourceId)}>
          <Button
            type={'primary'}
            shape={'circle'}
            icon={IconUtils.actions.edit}
          />
        </NavLink>
      ),
    };
  };

  const renderMobile = (item: ResourceRes) => {
    return (
      <Card
        hoverable
        className={`${CardClassEnum.rectangle_box} show-correct-answer`}
      >
        <ResourceContentItem item={item} />
      </Card>
    );
  };

  return (
    <FilterResourceStyle className={'list-resource-bank'}>
      <TablePagination
        params={props.params}
        isLoading={props.isFetching}
        columns={columns}
        data={props.data}
        refresh={props.refresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </FilterResourceStyle>
  );
};

export default function ListResourceBanks(props: {
  type: 'add' | 'replace';
  resources: ResourceProps[];
  selectedResources: ResourceProps[];
  onSelect: (data: ResourceProps) => void;
}) {
  const { t } = useTranslation();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useEffect(() => {
    const listSelectedRes = props.selectedResources.map((item) => {
      return item.resourceId;
    });

    setSelectedIds(listSelectedRes);
  }, [props.selectedResources]);

  return (
    <FilterResourceStyle
      className={'list-resource-bank scroll-container show-correct-answer'}
    >
      {props.resources != null &&
        props.resources.map((rsData) => {
          return (
            <div
              key={rsData.resourceId}
              className={
                'resource-item' +
                (selectedIds.includes(rsData.resourceId)
                  ? ' used-resource '
                  : '')
              }
            >
              <ResourceContextProvider
                mode={CompMode.REVIEW}
                resourceId={rsData.resourceId}
                comps={rsData.components}
                answer={rsData.correctAnswer}
                feedback={[]}
              >
                <ResourceItem />
              </ResourceContextProvider>

              <div className={'select-background'}>
                <Button
                  shape={'round'}
                  type={'primary'}
                  size={'small'}
                  onClick={() => props.onSelect(rsData)}
                  disabled={selectedIds.includes(rsData.resourceId)}
                  icon={IconUtils.add_new}
                >
                  {props.type === 'add'
                    ? t('resource.actions.add_component')
                    : t('resource.actions.replace_components')}
                </Button>
              </div>
            </div>
          );
        })}
    </FilterResourceStyle>
  );
}

export const ListSelectResourceBanks = (props: {
  resources: ResourceProps[];
  selectedResources: number[];
  handleOnClick: (resource: ResourceProps) => void;
}) => {
  return (
    <FilterResourceStyle className={'list-resource-bank'}>
      {props.resources != null &&
        props.resources.map((rsData) => {
          const isSelected = props.selectedResources.includes(
            rsData.resourceId
          );

          return (
            <div
              className={
                'resource-item' + (isSelected ? ' used-resource ' : '')
              }
              onClick={() => props.handleOnClick(rsData)}
              key={rsData.resourceId}
            >
              <ResourceContextProvider
                resourceId={rsData.resourceId}
                comps={rsData.components}
                answer={[]}
              >
                <ResourceItem />

                <div className={'select-background'}>
                  <Button shape={'round'} danger={isSelected} type={'primary'}>
                    {isSelected ? 'Click to unselect' : 'Click to select'}
                  </Button>
                </div>
              </ResourceContextProvider>
            </div>
          );
        })}
    </FilterResourceStyle>
  );
};

const FilterResourceStyle = styled.div`
  .resource-item {
    padding: 0.5em 1em;
    border: 1px solid #ccc;
    margin-bottom: 1em;
    position: relative;

    .select-background {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.03);
    }

    &:hover {
      .select-background {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    &.used-resource {
      border: 1px solid #0474bb;
      outline: 1px solid #0474bb;

      .select-background {
        background: rgba(99, 102, 241, 0.2);
      }
    }

    .ant-btn {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .resource-comps {
    width: 100%;
  }

  .ant-list-item {
    position: relative;

    .edit-when-hover-bgr {
      display: none;
    }

    &:hover {
      .edit-when-hover-bgr {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        background: rgba(0, 0, 0, 0.1);

        display: flex;
        justify-content: center;
        align-items: center;

        .ant-btn {
          position: absolute;
          right: 5px;
          bottom: 5px;
        }
      }
    }
  }
`;
