import useScript from './useScript';
import { useEffect, useState } from 'react';
import { JsLib } from '@cms/script/LoadLibraryUtils';

const useHighlightTextLib = (onSuccess: () => void) => {
  const [state, setState] = useState<Record<string, boolean>>({
    [JsLib.jquery.name]: false,
    [JsLib.highlight_text.name]: false,
  });

  useEffect(() => {
    let incomplete = Object.keys(state).some((lib) => {
      return !state[lib];
    });

    if (!incomplete) {
      setTimeout(() => {
        onSuccess();
      }, 250);
    }
  }, [state]);

  useScript(JsLib.jquery.url, JsLib.jquery.id, () => {
    setState((prevState) => {
      return { ...prevState, ...{ [JsLib.jquery.name]: true } };
    });
  });

  useScript(JsLib.highlight_text.url, JsLib.highlight_text.id, () => {
    setState((prevState) => {
      return {
        ...prevState,
        ...{ [JsLib.highlight_text.name]: true },
      };
    });
  });
};

export default useHighlightTextLib;
