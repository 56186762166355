import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AssetsRes } from '@modules/product/services/assets_model';
import { FlashCardTermProps } from '@cms/comps/game/matching/FlashCardMatchingComp';

export interface FlashCardMatchingOptionProps {
  compId: string;
  selected: boolean;
  disabled: boolean;
  target: FlashCardTermProps;
  onClick: (target: FlashCardTermProps) => void;
  handleOnContentChange: (data: AssetsRes) => void;
}

const FlashCardMatchingOption = ({
  target,
  onClick,
  disabled,
  selected,
}: FlashCardMatchingOptionProps) => {
  const [select, setSelect] = useState(false);

  const handleOnClick = () => {
    if (!disabled && !select && target.content != null) {
      setSelect(true);

      setTimeout(() => {
        setSelect(false);
        onClick(target);
      }, 1000);
    }
  };

  const getClassName = () => {
    const className: string[] = ['comps-matching-item'];
    if (target.content == null) {
      className.push('flashcard-empty');
    }

    if (disabled || selected || select) {
      className.push('selected');
    }

    return className.join(' ');
  };

  return (
    <FlashCardMcOptionStyle className={getClassName()} onClick={handleOnClick}>
      <div className={'flip-card'}>
        <div className={'flip-card-inner'}>
          <div className={'flip-card-front'}></div>

          <div className={'flip-card-back'}>
            {target.content && <ContentViewer contentData={target.content} />}
          </div>
        </div>
      </div>
    </FlashCardMcOptionStyle>
  );
};
export default FlashCardMatchingOption;

const FlashCardMcOptionStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  box-shadow: 0px 0px 6px 0px #ccc;
  border-radius: 0.125em;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  &.flashcard-empty {
    opacity: 0.1;
    cursor: default;
  }

  .flip-card {
    background-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  &.selected .flip-card .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
  }

  .flip-card-front {
    background-color: #bbb;
    color: black;
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }
`;
