import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import ResourceBankComp, {
  ResourceBankCompMode,
} from '@cms/resource-bank/ResourceBank';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ComposeResource } from '@cms/lesson-template/compose/lesson/ComposeResource';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { warningMessage } from '@app/redux/slices/composeLesson';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import {
  ComposeContentLayout,
  ComposeFooterLayout,
  ComposeLayout,
  LessonBankLayout,
  max_question_bank,
} from '@cms/lesson-template/compose/layout';
import {
  LessonTemplateLayoutProvider,
  LessonTemplateWrapper,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import ComposeSectionToolbar from '@cms/lesson-template/compose/resource/ComposeSectionToolbar';
import { ViewExerciseWrapper } from '@cms/lesson-template/exercise/ViewExerciseWrapper';
import styled from 'styled-components';
import { AddResourceToLessonReq } from '@modules/product/services/resource_model';

const BuildLessonTemplate = () => {
  const dispatch = useDispatch();

  const [addResourceToLesson] =
    LessonAPI.endpoints.addResourceToLesson.useMutation({});

  const { lesson, dispatchAction } = useLessonEditableContext();

  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (lesson != null && lesson.sections) {
      setResources(ResourceUtils.getResources(lesson.sections));
    }
  }, [lesson]);

  const handleOnSelect = (rsData: ResourceProps) => {
    if (lesson) {
      const request: AddResourceToLessonReq = {
        lessonId: lesson.lessonId,
        resourceId: rsData.resourceId,
        displayOrder: resources.length + 1,
      };

      addResourceToLesson(request)
        .unwrap()
        .then(() => {
          dispatchAction(LessonEditableActionEnum.refresh, -1);
        })
        .catch(() => {
          dispatch(warningMessage(false));
        });
    }
  };

  return (
    <>
      <LessonBankLayout className={'lesson-sections'} width={max_question_bank}>
        <ResourceBankComp
          layout={'vertical'}
          mode={ResourceBankCompMode.select}
          selectedResources={resources}
          onSelect={handleOnSelect}
        />
      </LessonBankLayout>

      <ComposeLayout
        className={'show-correct-answer compose-build-question-page'}
      >
        <ComposeContentLayout className={'compose-content-layout'}>
          <LessonTemplateLayoutProvider lesson={lesson}>
            {lesson && (
              <ViewExerciseWrapper
                className={'exercise-content-display'}
                ratio={{ width: 16, height: 9 }}
                minFontSize={12}
              >
                <LessonTemplateWrapper>
                  {resources != null &&
                    resources.map((rsData, index) => {
                      return (
                        <ComposeResource
                          key={rsData.resourceId + '_' + index}
                          item={rsData}
                          index={index}
                        />
                      );
                    })}

                  <EmptyDirection
                    className={'empty-space'}
                    style={{ textAlign: 'center' }}
                  >
                    Select content in the left panel
                  </EmptyDirection>
                </LessonTemplateWrapper>
              </ViewExerciseWrapper>
            )}
          </LessonTemplateLayoutProvider>
        </ComposeContentLayout>

        <ComposeFooterLayout className={'compose-footer-layout'}>
          <ComposeSectionToolbar type={lesson?.type} />
        </ComposeFooterLayout>
      </ComposeLayout>
    </>
  );
};

export default BuildLessonTemplate;

const EmptyDirection = styled.div`
  display: flex;
  min-height: 3em;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em;
  //border: 2px dashed #ccc;
  //background: rgba(0, 0, 0, 0.03) !important;
  white-space: normal;
  color: ${(props) => props.theme.color.dark_grey};
`;
