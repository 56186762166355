import { FormikProvider, useFormik } from 'formik';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  SchoolSettingAPI,
  SchoolSettingRes,
  UpdateSchoolLanguageSettingReq,
} from '@services/private/SchoolSettingAPI';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { useLoadSchoolSetting } from '@modules/setting/container/school-setting/useLoadSchoolSetting';
import { SchoolLanguageSelect } from '@modules/setting/components/school/SchoolLanguageSelecter';
import { InputGroup } from '@components/input/InputGroup';
import { LanguageRes } from '@services/setting/LanguageAPI';
import { LanguageEnum } from '@components/language/LanguageIcon';
import { H2 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

export const SchoolLanguageSettingForm = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onError: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();

  const { data, isFetching, isSuccess } = useLoadSchoolSetting(props.schoolId);

  const [activeLanguages, setActiveLanguages] = useState<LanguageRes[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<
    LanguageRes | undefined
  >(undefined);

  const [updateLanguageSetting, { isLoading }] =
    SchoolSettingAPI.endpoints.updateLanguageSetting.useMutation({});

  const [updateLanguageSettingAdmin, { isLoading: isAdminLoading }] =
    SchoolSettingAPI.endpoints.updateLanguageSettingAdmin.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      languages: [LanguageEnum.English],
      defaultLanguage: LanguageEnum.English,
    },

    onSubmit: (values) => {
      if (props.schoolId != null && props.schoolId > 0) {
        updateSchoolSettingAdmin({ ...values, schoolId: props.schoolId });
      } else {
        updateSchoolSetting(values);
      }
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      restoreSettingData(data);
    }
  }, [data]);

  const restoreSettingData = (data: SchoolSettingRes) => {
    formik.setValues({
      languages: data.languages.map((lang) => lang.code),
      defaultLanguage: data.defaultLanguage.code,
    });

    setActiveLanguages(data.languages);
    setDefaultLanguage(data.defaultLanguage);
  };

  const updateSchoolSetting = (request: UpdateSchoolLanguageSettingReq) => {
    updateLanguageSetting(request)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  const updateSchoolSettingAdmin = (
    setting: Required<UpdateSchoolLanguageSettingReq>
  ) => {
    updateLanguageSettingAdmin(setting)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch(() => {
        props.onError();
      });
  };

  const handleOnLanguageChange = (
    languages: LanguageEnum[],
    defaultLanguage: LanguageEnum
  ) => {
    formik.setFieldValue('defaultLanguage', defaultLanguage);
    formik.setFieldValue('languages', languages);
  };

  return (
    <>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>School Language</H2>
      </HeaderGroup>

      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <InputGroup label={'Select Default Language'}>
              <SchoolLanguageSelect
                languages={activeLanguages}
                defaultLanguage={defaultLanguage}
                onChange={handleOnLanguageChange}
              />
            </InputGroup>
          </Col>
        </Row>

        <div
          className="d-flex submit-container"
          style={{ justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button
              type="primary"
              shape={'round'}
              onClick={formik.submitForm}
              icon={IconUtils.save}
              loading={isLoading || isFetching || isAdminLoading}
            >
              <>{t('button.update')}</>
            </Button>

            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </FormikProvider>
    </>
  );
};
