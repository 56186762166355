import { Col, List, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserRes } from '@modules/users/services/model';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import env from '@utils/config';
import { H4 } from '@components/typography';
import StudentListItem, {
  SelectStudentActionEnum,
} from '@modules/users/component/StudentListItem';
import LinkToStudent from './LinkToStudentField';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';
import { LinkToStudentProps, LinkToStudentReq } from '../services/model';

const LinkToStudentForm = (props: {
  onChange: (students: LinkToStudentProps[]) => void;
}) => {
  const { t } = useTranslation();

  const [linkToStudent] = AuthAPI.endpoints.linkToStudent.useMutation({});

  const [students, setStudents] = useState<UserRes[]>([]);
  const [linkToStudents, setLinkToStudents] = useState<LinkToStudentProps[]>(
    []
  );

  useEffect(() => {
    props.onChange(linkToStudents);
  }, [linkToStudents]);

  const onRemoveStudent = (studentId: number) => {
    setLinkToStudents((prev) => {
      return [...prev].filter((st) => {
        return st.userId !== studentId;
      });
    });

    setStudents((prev) => {
      return [...prev].filter((st) => {
        return st.userId !== studentId;
      });
    });
  };

  const onLinkToStudent = (userName: string, password: string) => {
    const request: LinkToStudentReq = {
      username: userName,
      password: password,
      clientId: env.CLIENT_ID,
    };

    linkToStudent(request)
      .unwrap()
      .then((res) => {
        setStudents((prev) => {
          const reduceSt = [...prev].filter((st) => {
            return st.userId !== res.userId;
          });
          return [...reduceSt, res];
        });

        setLinkToStudents((prev) => {
          const reduceSt = [...prev].filter((st) => {
            return st.userId !== res.userId;
          });
          return [
            ...reduceSt,
            {
              userId: res.userId,
              username: request.username,
              password: request.password,
            },
          ];
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot link to student.',
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div className={'link-to-student'} style={{ marginTop: '2em' }}>
      <H4>{t('login.link_to_students')}</H4>

      {students.length > 0 && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <List
              style={{ width: '100%', marginBottom: '1em' }}
              bordered
              dataSource={students}
              renderItem={(item) => (
                <StudentListItem
                  item={item}
                  type={SelectStudentActionEnum.remove}
                  onRemove={onRemoveStudent}
                />
              )}
            />
          </Col>
        </Row>
      )}

      <LinkToStudent type={'modal'} onSubmit={onLinkToStudent} />
    </div>
  );
};

export default LinkToStudentForm;
