import React, { useEffect, useRef, useState } from 'react';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import FullCalendarReact from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import dayGridPlugin from '@fullcalendar/daygrid';
import { CalendarEventProps } from '@modules/calendar/container/ManageCalendar';
import { useLanguage } from '@hooks/useLanguage';
import {
  DateAndTimeUtils,
  DayLabels,
  ServerDateAndTimeFormat,
} from '@utils/DateAndTimeUtils';
import { CalendarOptions } from '@fullcalendar/common';
import { CalendarStyle } from '@components/calendar/style';
import listPlugin from '@fullcalendar/list';
import useCalendarConfig from '@modules/calendar/components/CalendarConfig';
import CalendarViewDropdown, {
  CalendarViewType,
} from '@components/calendar/CalendarViewType';
import dayjs from 'dayjs';

export interface MonthCalendarProps<T> extends CalendarOptions {
  defaultDate: string;
  events: any[];
  getEventClass: (event: T) => string;
  eventRendering?: (info: any) => { html: string };
  goToDate?: (date: string) => void;
}

export default function Calendar({
  defaultDate,
  events,
  getEventClass,
  eventRendering,
  ...props
}: MonthCalendarProps<any>) {
  const [defaultView, setDefaultView] = useState<CalendarViewType>(
    CalendarViewType.month
  );
  const language = useLanguage();
  const calendarRef = useRef<any>(null);
  const calendarConfig = useCalendarConfig();

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(defaultDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(defaultView);
    }
  }, [defaultView]);

  const getEventClassNames = (info: any) => {
    const event = info.event._def.extendedProps as CalendarEventProps;
    return getEventClass(event);
  };

  const goToDate = (date: string) => {
    if (props.goToDate) {
      props.goToDate(date);
    }
  };

  const customDayHeader = (args: any) => {
    const currentDay = args.date;

    if (defaultView === CalendarViewType.day) {
      const containers: any[] = [];

      const ranger = DateAndTimeUtils.getWeekRangeFromDate(currentDay);
      const days = DateAndTimeUtils.getDaysInRanger(
        ranger.startDate,
        ranger.endDate
      );

      days.forEach((day, index) => {
        if (!calendarConfig.hideDays.includes(index)) {
          const innerHTML: string[] = [];

          innerHTML.push(
            `<div class="day-header-group ${getHeaderClassColumn(
              currentDay,
              day
            )}">`
          );
          innerHTML.push(
            `<span class="day-header-number">
            ${DateAndTimeUtils.getDateStr(day, 'D')}
            </span>`
          );
          innerHTML.push(
            `<span class="day-header-label">${DayLabels[index]}</span>`
          );
          innerHTML.push('</div>');

          const containerEl = document.createElement('div');
          containerEl.className = 'custom-day-header';
          containerEl.innerHTML = innerHTML.join('');
          containerEl.onclick = () => {
            goToDate(day);
          };
          containers.push(containerEl);
        }
      });

      return { domNodes: containers };
    } else {
      const containerEl = document.createElement('div');
      const innerHTML: string[] = [];

      if (defaultView === CalendarViewType.week) {
        innerHTML.push(
          `<div class="day-header-group ${getHeaderClassName(args)}">`
        );
        innerHTML.push(
          `<span class="day-header-number">
            ${DateAndTimeUtils.getDateStr(currentDay, 'D')}
            </span>`
        );
      }

      innerHTML.push(`<span class="day-header-label">${args.text}</span>`);
      innerHTML.push('</div>');

      containerEl.className = 'custom-day-header';
      containerEl.innerHTML = innerHTML.join('');
      return { domNodes: [containerEl] };
    }
  };

  const customSlotLabel = (args: any) => {
    const containerEl = document.createElement('div');
    containerEl.className = 'custom-slot-label';
    containerEl.innerHTML = `${DateAndTimeUtils.getDateStr(args.date, 'hh a')}`;
    return { domNodes: [containerEl] };
  };

  return (
    <CalendarStyle
      className={`calendar-container ${
        defaultView === CalendarViewType.month
          ? 'month-view'
          : defaultView === CalendarViewType.week
          ? 'week-view'
          : 'day-view'
      }`}
    >
      <CalendarViewDropdown
        type={defaultView}
        onChange={(type) => setDefaultView(type)}
      />

      <FullCalendarReact
        ref={calendarRef}
        initialDate={defaultDate}
        initialView={defaultView}
        locale={language}
        plugins={[listPlugin, timeGridPlugin, interactionPlugin, dayGridPlugin]}
        headerToolbar={{
          left: 'title',
          center: 'prev,next today',
          right: '',
        }}
        selectable
        events={events}
        eventContent={eventRendering ?? null}
        timeZone={'local'}
        allDaySlot={false}
        height={'auto'}
        expandRows={true}
        nowIndicator={true}
        eventClassNames={getEventClassNames}
        // custom config....
        dayHeaders={true}
        dayHeaderFormat={{ weekday: 'short' }}
        dayHeaderContent={customDayHeader}
        slotLabelContent={customSlotLabel}
        fixedWeekCount={true}
        views={{
          dayGrid: {
            dayMaxEvents: 3,
          },
          timeGrid: {
            titleFormat: {
              month: 'long',
              year: 'numeric',
            },
            eventMaxStack: 3,
          },
          dayGridMonth: {
            titleFormat: {
              month: 'long',
              year: 'numeric',
            },
            dayMaxEvents: 3,
          },
          timeGridWeek: {
            titleFormat: {
              month: 'long',
              year: 'numeric',
            },
            // eventMaxStack : 2,
          },
        }}
        // setup business hours
        businessHours={calendarConfig.workSpec}
        slotMinTime={calendarConfig.workMin}
        slotMaxTime={calendarConfig.workMax}
        hiddenDays={calendarConfig.hideDays}
        {...props}
      />
    </CalendarStyle>
  );
}

const getHeaderClassColumn = (currentDay: any, dateStr: string) => {
  let start = dayjs(currentDay);
  let end = dayjs(dateStr, ServerDateAndTimeFormat.DATE);

  const isPast = !end.isSame(start);
  const isToday = end.isSame(start);

  return getHeaderClassName({ isFuture: false, isPast, isToday });
};

const getHeaderClassName = (data: {
  isFuture: boolean;
  isPast: boolean;
  isToday: boolean;
}): string => {
  const className = [];

  if (data.isFuture) {
    className.push('is-future');
  }
  if (data.isPast) {
    className.push('is-past');
  }
  if (data.isToday) {
    className.push('is-today');
  }

  return className.join(' ');
};
