import React from 'react';
import { useRouter } from '@hooks/useRouter';

import { ViewExercisePage } from '@cms/lesson-template/ViewExercise';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { CompMode } from '@cms/ComponentInteface';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const ReviewExercise = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const { exerciseId, productId, productTocId } = router.query as unknown as {
    exerciseId: number;
    productTocId: number;
    productId: number;
  };

  return (
    <EmptyLayout className={'review-exercise-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={
          productId
            ? NavigationMenuEnum.LIBRARY_PRODUCTS
            : NavigationMenuEnum.LIBRARY_LESSONS
        }
        title={t('exercise.actions.review')}
        description={t('exercise.actions.review')}
      />

      <ViewExercisePage
        key={exerciseId + '_' + CompMode.REVIEW_EXERCISE}
        type={CompMode.REVIEW_EXERCISE}
        exerciseId={Number(exerciseId)}
        productTocId={productTocId}
        productId={productId}
      />
    </EmptyLayout>
  );
};

export default ReviewExercise;
