import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, notification } from 'antd';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import TablePagination from '@components/table/TablePagination';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { LessonTypeAPI } from '@modules/admin/service/LessonTypeAPI';
import { LessonTypeEditForm } from '@modules/admin/container/LessonTypeEditForm';
import { H1 } from '@components/typography';
import { DateLabel } from '@components/text/DateLabel';
import { EntityStatus } from '@components/status/EntityStatus';
import useConfirmModal from '@components/modal/ConfirmModal';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { MenuProps } from 'antd/es/menu';
import { IconUtils } from '@utils/IconUtils';
import { LessonTypeRes } from '../service/model';

enum LessonTypePageMode {
  DEFAULT = '',
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

const LessonTypeManagementPage = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [findAll, { data, isFetching }] =
    LessonTypeAPI.endpoints.findAll.useLazyQuery({});

  const [deleteLessonType] =
    LessonTypeAPI.endpoints.deleteLessonType.useMutation({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'displayOrder',
    direction: 'ascend',
  });

  const [action, setAction] = useState<LessonTypePageMode>(
    LessonTypePageMode.DEFAULT
  );
  const [lessonTypeData, setLessonTypeData] = useState<LessonTypeRes | null>(
    null
  );

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      align: 'center',
      width: 150,
    },
    {
      title: t('label.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: false,
      width: 150,
    },
    {
      title: t('label.actions'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    findAll(params);
  }, [params]);

  useEffect(() => {
    return () => {
      setParams({
        page: 1,
        size: MAX_PAGE_SIZE,
        sort: 'createdDate',
        direction: 'descend',
      });
    };
  }, []);

  const handleClickMenu = (item: LessonTypeRes, action: LessonTypePageMode) => {
    setLessonTypeData(item);
    setAction(action);
  };

  const processDataRow = (item: LessonTypeRes) => {
    const menuItems: MenuProps['items'] = [
      {
        key: LessonTypePageMode.UPDATE,
        icon: IconUtils.actions.edit,
        onClick: () => handleClickMenu(item, LessonTypePageMode.UPDATE),
        label: t('label.update'),
      },
      {
        key: LessonTypePageMode.DELETE,
        icon: IconUtils.actions.delete,
        onClick: () => handleClickRemove(item),
        label: t('label.remove'),
      },
    ];

    return {
      key: item.lessonTypeId + item.modifiedDate,
      name: item.name,
      description: <LanguageContentTag multiple content={item.description} />,
      status: <EntityStatus status={item.status} />,
      createdDate: <DateLabel label={item.createdDate} />,
      action: (
        <Dropdown menu={{ items: menuItems }} placement="topRight" arrow>
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      ),
    };
  };

  const refreshData = (params: PaginationReq) => {
    setParams(params);
  };

  const handleOnSuccess = () => {
    setLessonTypeData(null);
    setAction(LessonTypePageMode.DEFAULT);
    findAll(params);
  };

  const handleOnCancel = () => {
    setLessonTypeData(null);
    setAction(LessonTypePageMode.DEFAULT);
  };

  const handleClickRemove = (item: LessonTypeRes) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('confirm.confirmRemoveItem'),
      t('label.remove'),
      t('label.cancel'),
      (result) => {
        if (result) {
          handleRemoveItem(item);
        }
      }
    );
  };

  const handleRemoveItem = (item: LessonTypeRes) => {
    deleteLessonType(item.lessonTypeId)
      .unwrap()
      .then(() => {
        setAction(LessonTypePageMode.DELETE);
        findAll(params);
      })
      .catch((e) => {
        notification.error({
          message: t('header.error'),
          description: t(e.data.error_description),
          placement: 'bottomRight',
        });
        setAction(LessonTypePageMode.DEFAULT);
      });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('lesson_type.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(LessonTypePageMode.ADD)}
          />
        </H1>
      </HeaderGroup>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
      />

      {action === LessonTypePageMode.ADD && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('lesson_type.add')}
          content={
            <LessonTypeEditForm
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action === LessonTypePageMode.UPDATE && lessonTypeData != null && (
        <CustomModal
          className={CustomModalClassEnum.medium_modal}
          header={t('lesson_type.update')}
          content={
            <LessonTypeEditForm
              lessonTypeId={lessonTypeData.lessonTypeId}
              successHandle={handleOnSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default LessonTypeManagementPage;
