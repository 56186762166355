import React from 'react';
import ViewProductPage from '../../product/pages/ViewProductPage';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import LtiProductTocPage from '@modules/lti/pages/LtiProductTocPage';
import { ProductRes } from '@modules/product/services/product_model';

const LtiViewProductPage = (props: { product: ProductRes }) => {
  return (
    <div className={'block-container'}>
      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
        <ViewProductPage productId={props.product.productId} />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        {props.product.owner ? (
          <LtiProductTocPage productId={props.product.productId} />
        ) : (
          <ViewProductPage productId={props.product.productId} />
        )}
      </Authorization>
    </div>
  );
};

export default LtiViewProductPage;
