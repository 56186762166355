import { getIn } from 'formik';
import dayjs from 'dayjs';
import { ClientDateAndTimeFormat } from '@utils/DateAndTimeUtils';
import styled from 'styled-components';

import { DatePicker as AntDatePicker } from 'antd';

export const InputMessageStyle = styled.span<{
  error: boolean | undefined;
  success: boolean | undefined;
}>`
  font-size: 12px;
  display: inline-block;
  margin-top: 5px;
  width: 100% !important;
`;

interface DatePickerProps {
  handleChange: any;
  name?: string;
  formik?: any;
  error?: boolean;
  message?: any;
  success?: boolean;
  value?: string;
}

const DatePicker = ({
  value,
  handleChange,
  name,
  formik,
  error,
  message,
  success,
}: DatePickerProps) => {
  const onChange = (date: any) => {
    const fakeEvent = {
      target: {
        name,
        value: dayjs(date).format(ClientDateAndTimeFormat.DATE),
      },
    };
    handleChange(fakeEvent);
  };

  const isFormikError =
    !!name &&
    !!getIn(formik, `errors.${name}`) &&
    !!getIn(formik, `touched.${name}`);

  const isError = error || isFormikError;

  const errorMessage = message || (name ? getIn(formik, `errors.${name}`) : '');

  const getPopupContainer = (trigger: any) => {
    return trigger.parentNode;
  };

  return (
    <>
      <AntDatePicker
        size={'large'}
        value={dayjs(value, ClientDateAndTimeFormat.DATE)}
        format={ClientDateAndTimeFormat.DATE}
        placeholder={ClientDateAndTimeFormat.DATE}
        className="gstudy-date-picker"
        onChange={onChange}
        allowClear={false}
        style={{ width: '100%' }}
        // @ts-ignore
        getPopupContainer={getPopupContainer}
      />

      {((errorMessage && isError) || success) && (
        <InputMessageStyle
          className="error-message"
          success={success}
          error={isError}
        >
          {errorMessage}
        </InputMessageStyle>
      )}
    </>
  );
};

export default DatePicker;
