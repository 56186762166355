import { createSlice } from '@reduxjs/toolkit';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { CompAnswerProps, CompGradingProps } from '@cms/ComponentInteface';
import { ExerciseResponseRes } from '@modules/assignments/service/exercise_model';

const defaultResource = {
  resourceId: -1,
  description: '',
  keyword: '',

  components: [],
  correctAnswer: [],
  scoring: [],

  displayOrder: 1,
  questionNumber: 1,
  showQuestionNumber: true,
};

export interface ResourceAnswerProps {
  resourceId: number;
  sectionId?: number;
  answers: CompAnswerProps[];
}

export interface ResourceGradingProps {
  resourceId: number;
  sectionId?: number;
  grading: CompGradingProps[];
}

export interface ViewResourceProps {
  ready: boolean;

  currentAnswer: Record<number, CompAnswerProps[]>;
  currentFeedback: Record<number, ExerciseResponseRes>;
  currentGrading: Record<number, CompGradingProps[]>;
  grading: ResourceGradingProps[];
  submitted: boolean;
  resource: ResourceProps;
}

const initialState: ViewResourceProps = {
  ready: false,

  currentAnswer: {} as Record<number, CompAnswerProps[]>,
  currentFeedback: {} as Record<number, ExerciseResponseRes>,
  currentGrading: {} as Record<number, CompGradingProps[]>,

  grading: [],
  submitted: false,
  resource: defaultResource,
};

const viewResource = createSlice({
  name: 'viewResource',
  initialState,

  reducers: {
    restoreExercise(state, action: { payload: ResourceAnswerProps[] }) {
      state.ready = true;
      // state.answers = action.payload;
      state.currentAnswer = {};

      action.payload.forEach((ans) => {
        state.currentAnswer[ans.resourceId] = ans.answers;
      });
    },

    viewResourceExercise(state, action) {
      state.resource = action.payload;
    },

    showExerciseResults(
      state,
      action: {
        payload: ExerciseResponseRes[];
      }
    ) {
      state.ready = true;
      state.submitted = true;
      // state.feedBacks = action.payload;
      state.currentFeedback = {};

      action.payload.forEach((fb) => {
        state.currentFeedback[fb.resourceId] = fb;
      });
    },

    updateResourceAnswer(state, action: { payload: ResourceAnswerProps }) {
      state.currentAnswer[action.payload.resourceId] = action.payload.answers;
    },

    updateGrading(state, action: { payload: ResourceGradingProps }) {
      state.currentGrading[action.payload.resourceId] = action.payload.grading;

      const exits = [...state.grading].findIndex((rs) => {
        return rs.resourceId === action.payload.resourceId;
      });

      if (exits > -1) {
        const newGrading = [...state.grading].map((ans) => {
          if (ans.resourceId === action.payload.resourceId) {
            return { ...action.payload };
          } else {
            return { ...ans };
          }
        });

        state.grading = newGrading;
      } else {
        state.grading = [...state.grading, { ...action.payload }];
      }
    },

    resetData(state) {
      Object.assign(state, initialState);
    },
  },
});

const { actions, reducer } = viewResource;
export const {
  showExerciseResults,
  restoreExercise,
  updateResourceAnswer,
  updateGrading,
  viewResourceExercise,
  resetData,
} = actions;
export default reducer;
