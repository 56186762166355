import React from 'react';
import {
  CompAnswerProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { SocialMediaPlayer } from '@cms/comps/presentation/social-video/SocialMediaPlayer';
import styled from 'styled-components';

export interface SocialVideoPlayerProps {
  id: string;
  name: string;
  scale: number; // height / width
  type: 'facebook' | 'youtube' | 'twister';
  src: string;
}

export interface SocialVideoProps extends CompProps {
  type: CompTypeEnum.SOCIAL_VIDEO;
  configuration: {
    sourceItems: SocialVideoPlayerProps[];
  };
}

export interface SocialVideoAnsProps extends CompAnswerProps {
  answer: string;
}

export const SocialVideoComps = (props: {
  disabled?: boolean;
  item: SocialVideoProps;
  answer: SocialVideoAnsProps | null;
  onChange: (newAns: SocialVideoAnsProps) => void;
}) => {
  return (
    <SocialVideoStyle
      className={`comp comp-social-video ${props.item.setting?.className}`}
    >
      <SocialMediaPlayer
        disabled={props.disabled}
        item={props.item}
        answer={props.answer}
        onChange={props.onChange}
      />
    </SocialVideoStyle>
  );
};

const SocialVideoStyle = styled.div`
  position: relative;
`;
