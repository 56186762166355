import React, { useEffect, useRef, useState } from 'react';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import styled from 'styled-components';
import { useDropDownEditable } from '@cms/comps/interact/dropdown/DropdownEditable';
import { useDropDownCompContext } from '@cms/comps/interact/dropdown/DropdownCompContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';

export const DropdownInlineComp = (props: {
  sourceData: string;
  answer: string;
  onChange: (newAns: string) => void;
}) => {
  const ref = useRef(null);

  const { modifiedTime, disabled, feedback, sourceItems } =
    useDropDownCompContext();

  const { editItem, changeToEdit } = useDropDownEditable();

  const [toggleDropdownMenu, setToggleDropdownMenu] = useState(false);

  const [answerLabelDisplay, setAnswerLabelDisplay] = useState<
    TextContentProps | ImageContentProps | AudioContentProps
  >(sourceItems[0].content);

  const [width, setWidth] = useState(10);

  useEffect(() => {
    const maxWidth = sourceItems.reduce((prev, current) => {
      if (current.content.data.length + 3 > prev) {
        return current.content.data.length + 3;
      } else {
        return prev;
      }
    }, 10);

    setWidth(maxWidth);
  }, []);

  useEffect(() => {
    const selectOptions = sourceItems.filter((ans) => {
      return ans.label === props.answer;
    });

    if (selectOptions.length > 0) {
      setAnswerLabelDisplay(selectOptions[0].content);
    } else {
      setAnswerLabelDisplay(sourceItems[0].content);
    }
  }, [props.answer]);

  useOnClickOutside(ref, () => {
    setToggleDropdownMenu(false);
  });

  const toggleDropdown = () => {
    setToggleDropdownMenu(true);
  };

  const onSelectAnswer = (label: string) => {
    setToggleDropdownMenu(false);

    if (!disabled) {
      props.onChange(label);
    }
  };

  const handleOnEdit = () => {
    changeToEdit('source-items');
    setToggleDropdownMenu(false);
  };

  return (
    <CompFeedbackContextProvider feedBack={feedback}>
      <DropdownInlineStyle
        className={`comp comp-dropdown-inline ${
          toggleDropdownMenu ? 'active' : ''
        }`}
        style={{ minWidth: `${width}ch` }}
        ref={ref}
      >
        <div className={'dp-label'} onClick={toggleDropdown}>
          <CompFeedbackResultIcon answer={props.answer} />
          <ContentViewer contentData={answerLabelDisplay} />
          <span className={'dp-toggle'} />
        </div>

        <ComposeModeContent>
          {editItem !== 'source-items' && (
            <Button
              className={'edit-dropdown-option-btn'}
              size={'small'}
              type={'primary'}
              shape={'circle'}
              onClick={handleOnEdit}
              icon={IconUtils.table_of_content}
            />
          )}
        </ComposeModeContent>

        {toggleDropdownMenu && (
          <div className={'dp-menu'}>
            {sourceItems.map((option, index) => {
              const selected = props.answer === option.label;

              return (
                <div
                  className={`dp-menu-item ${selected ? 'selected' : ''}`}
                  onClick={() => onSelectAnswer(option.label)}
                  key={
                    option.label +
                    '-' +
                    index +
                    '-' +
                    option.content.id +
                    '-' +
                    modifiedTime
                  }
                >
                  <CompFeedbackResultIcon answer={option.label} />

                  <div className={'dp-option'}>
                    <ContentViewer contentData={option.content} />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <CompFeedbackResultIcon
          answer={props.answer ? props.sourceData + ':' + props.answer : ''}
        />
      </DropdownInlineStyle>
    </CompFeedbackContextProvider>
  );
};

const DropdownInlineStyle = styled.div`
  position: relative;
  display: inline-block;

  .dp-label {
    position: relative;
    padding: 0 1em 0 0.5em;
    cursor: pointer;
    border-radius: 0.25em;
    line-height: 1.6;
    flex-grow: 1;
  }

  .edit-dropdown-option-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  .dp-label {
    border: 1px solid #ccc;
    color: ${(props) => props.theme.component.primary};

    .show-correct-answer & {
      color: ${(props) => props.theme.component.correct_answer};
    }

    .dp-toggle {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.4em 0.4em 0 0.4em;
      border-color: ${(props) => props.theme.component.disabled} transparent
        transparent transparent;

      position: absolute;
      right: 0.25em;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  &.active {
    .dp-label {
      border-radius: 0.5em 0.5em 0px 0px;
      border-bottom: none;

      .dp-toggle {
        transform: translate(0%, -50%) rotate(180deg);
      }
    }

    .dp-menu {
      position: absolute;
      top: 100%;
      left: 0px;
      min-width: 100%;
      background: #fff;
      z-index: 2;
      border: 1px solid #ccc;
      border-radius: 0px 0px 0.5em 0.5em;
      padding: 0.25em 0em;

      .dp-menu-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 1em 0 0.5em;

        .dp-option {
          white-space: nowrap;
        }

        &:hover {
          font-weight: 600;
          background: rgba(0, 0, 0, 0.05);
        }

        &.selected {
          font-weight: 600;
          background: rgba(0, 0, 0, 0.07);
        }
      }
    }
  }

  .feedback-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
  }
`;
