import React from 'react';

import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import ManageParentPage from '@modules/users/pages/ManageParentPage';
import { PageHeader } from '@app/header/PageHeader';

const ParentManagement = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'school-parent-page'}>
      <PageHeader
        menu={NavigationMenuEnum.USERS}
        subMenu={NavigationMenuEnum.USERS_PARENTS}
        title={t('parent.header')}
        description={t('parent.header')}
      />

      <ManageParentPage />
    </ManagementLayout>
  );
};

export default ParentManagement;
