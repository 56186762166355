import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';

import {
  ClassItem,
  ViewClassInfo,
} from '@modules/users/component/classes/ClassInfo';
import { MyClassAPI } from '@services/private/users/MyClassAPI';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import Card, { CardClassEnum } from '@components/card';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { ClassRes, FilterClassReq } from '@modules/users/services/clazz';

export const MyClassTable = (props: { action: string }) => {
  const { t } = useTranslation();

  const [getMyClasses, { data }] =
    MyClassAPI.endpoints.getMyClasses.useLazyQuery();

  const [selectClass, setSelectClass] = useState<ClassRes | null>(null);

  const [params] = useState<FilterClassReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    selectAll: true,
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  useEffect(() => {
    if (props.action && props.action === 'refresh') {
      refreshData();
    }
  }, [props.action]);

  const refreshData = () => {
    getMyClasses(params);
  };

  const handleOnClick = (clz: ClassRes | null) => {
    setSelectClass(clz);
  };

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        {data &&
          data.content &&
          data.content.map((clz) => {
            return (
              <Col
                key={clz.classId}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <Card className={CardClassEnum.rectangle_box}>
                  <ClassItem item={clz} onClick={() => handleOnClick(clz)} />
                </Card>
              </Col>
            );
          })}
      </Row>

      {selectClass && (
        <CustomModal
          header={t('class.actions.view_info')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ViewClassInfo
              item={selectClass}
              onCancel={() => handleOnClick(null)}
            />
          }
          onCloseFunc={() => handleOnClick(null)}
        />
      )}
    </>
  );
};
