import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { UserRes } from '@modules/users/services/model';

export interface ShareProductStudentReq {
  productId: number;
  studentId: number;
}

export const ShareProductAPI = createApi({
  reducerPath: 'ShareProductAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getProductSharedUser: builder.query<UserRes[], unknown>({
      query: (productId) => ({
        url: `/product/${productId}/share`,
      }),
    }),

    addStudent: builder.mutation<UserRes, ShareProductStudentReq>({
      query: (body: ShareProductStudentReq) => ({
        url: `/product/${body.productId}/share/students`,
        body,
        method: 'POST',
      }),
    }),

    removeStudent: builder.mutation<BasicMessageRes, ShareProductStudentReq>({
      query: (body: ShareProductStudentReq) => ({
        url: `/product/${body.productId}/share/students`,
        body,
        method: 'DELETE',
      }),
    }),
  }),
});
