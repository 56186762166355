import React, { useEffect, useState } from 'react';
import { SearchProps } from 'antd/lib/input';
import Search from 'antd/lib/input/Search';
import styled from 'styled-components';

interface KeywordInputProps extends SearchProps {
  type?: 'inline' | 'block';
  keywords?: string;
  onKeywordChange: (keywords: string[]) => void;
}

const KeywordInputStyle = styled.div`
  .keyword-group {
    margin-bottom: 1em;

    .keyword-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      background: ${(props) => props.theme.app.primary};
      color: ${(props) => props.theme.color.white};

      border-radius: 20px;
      padding: 2px 10px 2px 16px;
      margin-right: 5px;
      margin-bottom: 5px;

      .remove-keyword {
        margin-left: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        font-size: 22px;
        line-height: 22px;
        width: 22px;
        height: 22px;
      }
    }
  }
`;

const KeywordInput = ({
  keywords,
  onKeywordChange,
  ...props
}: KeywordInputProps) => {
  const [dataChange, setDataChange] = useState(false);
  const [keyword, setKeyword] = useState('');

  const [listKeyword, setListKeyword] = useState<string[]>(() => {
    return keywords && keywords.trim() !== '' ? keywords.split(',') : [];
  });

  useEffect(() => {
    const _keywords =
      keywords && keywords.trim() !== '' ? keywords.split(',') : [];
    setListKeyword(_keywords);
  }, [keywords]);

  useEffect(() => {
    if (dataChange) {
      onKeywordChange(listKeyword);
    }
  }, [dataChange, listKeyword]);

  const handlePushDataToArr = (value: string) => {
    const valueArr = value.split(',').map((kw) => {
      return kw.trim();
    });

    setListKeyword((prev) => {
      const newArr = [...prev];
      valueArr.forEach((item) => {
        if (item !== '' && !newArr.includes(item)) {
          newArr.push(item);
        }
      });
      return newArr;
    });

    setKeyword('');
    setDataChange(true);
  };

  const handleInputChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const removeKeyword = (rmKeyword: string) => {
    setListKeyword((prev) => {
      return [...prev].filter((item) => {
        return item !== rmKeyword;
      });
    });
    setDataChange(true);
  };

  return (
    <KeywordInputStyle className={'keyword-input-group'}>
      <div className={'keyword-group'}>
        {listKeyword &&
          listKeyword.map((kw) => {
            return (
              <span className={'keyword-item'} key={kw}>
                {kw}{' '}
                <span
                  className={'remove-keyword'}
                  onClick={() => removeKeyword(kw)}
                >
                  &times;
                </span>
              </span>
            );
          })}
      </div>

      <Search
        {...props}
        value={keyword}
        onChange={handleInputChange}
        onSearch={handlePushDataToArr}
      />
    </KeywordInputStyle>
  );
};

export default KeywordInput;
