import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProductTocAPI } from '@modules/product/services/ProductTocAPI';
import { ViewProductMode } from '@modules/product/components/view-toc/model';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';

export interface ProductTocNavigationProps {
  productTocId: number;
  name: string;
}

export interface LessonNavigationProps {
  lesson: LessonRes;
  productToc: ProductTocRes;
}

export interface TocNavigationProps {
  current: null | LessonNavigationProps;
  currentToc: null | ProductTocNavigationProps;
  previous: null | LessonNavigationProps;
  next: null | LessonNavigationProps;
}

export const getTocNavigation = (
  selectedLessonId: number,
  selectedTocId: number,
  productTocs: ProductTocRes[]
): TocNavigationProps => {
  let selectToc: null | ProductTocNavigationProps = null;
  let selectLesson: null | LessonNavigationProps = null;

  let preLesson: null | LessonNavigationProps = null;
  let nextLesson: null | LessonNavigationProps = null;

  productTocs.forEach((toc) => {
    toc.lessons.forEach((ls) => {
      const currentData: LessonNavigationProps = {
        lesson: ls,
        productToc: toc,
      };

      // if is match
      if (
        toc.productTocId === selectedTocId &&
        ls.lessonId === selectedLessonId
      ) {
        selectToc = { productTocId: toc.productTocId, name: toc.name };
        selectLesson = currentData;
      } else {
        if (selectLesson != null && nextLesson == null) {
          nextLesson = currentData;
        }

        if (selectLesson == null) {
          preLesson = currentData;
        }
      }
    });
  });

  return {
    current: selectLesson,
    currentToc: selectToc,
    previous: preLesson,
    next: nextLesson,
  };
};

export const ViewProductContext = createContext({
  ready: false as boolean,
  loading: false as boolean,
  productId: -1 as number,
  selectedProductTocId: -1 as number,
  selectLessonId: -1 as number,
  productTocs: [] as ProductTocRes[],

  navigation: {
    current: null,
    previous: null,
    next: null,
  } as TocNavigationProps,

  setSelectLesson: (lesson: LessonRes, productToc: ProductTocRes) => {
    console.log(lesson, productToc);
  },
  setProductTocs: (data: any) => {
    console.log(data);
  },
});

const ViewProductContextProvider = (props: {
  type: ViewProductMode;
  productId: number;
  productTocId: number;
  lessonId: number;
  children: ReactNode;
}) => {
  const [getProductTocs, { data, isSuccess, isFetching }] =
    ProductTocAPI.endpoints.getProductTocs.useLazyQuery({});

  const [ready, setReady] = useState(false);
  const [selectProductTocId, setSelectProductTocId] = useState(
    props.productTocId
  );
  const [selectLessonId, setSelectLessonId] = useState(props.lessonId);
  const [productTocs, setProductTocs] = useState<ProductTocRes[]>([]);

  const [navigation, setNavigation] = useState<TocNavigationProps>({
    current: null,
    currentToc: null,
    previous: null,
    next: null,
  });

  useEffect(() => {
    if (props.productId != null && props.productId > 0) {
      getProductTocs(props.productId);
    } else {
      setReady(true);
    }
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      setProductTocs(data);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (selectLessonId < 0) {
      let selectedLesson: LessonRes | null = null;
      let selectedToc: ProductTocRes | null = null;

      productTocs.forEach((item) => {
        if (
          selectedLesson == null &&
          item.lessons != null &&
          item.lessons.length > 0
        ) {
          selectedLesson = item.lessons[0];
          selectedToc = item;
        }
      });

      if (selectedLesson != null && selectedToc != null) {
        selectLesson(selectedLesson, selectedToc);
      }
    }
  }, [productTocs, selectLessonId]);

  useEffect(() => {
    setNavigation(
      getTocNavigation(selectLessonId, selectProductTocId, productTocs)
    );
  }, [selectLessonId, selectProductTocId, productTocs]);

  const selectProductToc = (productTocId: number) => {
    setSelectProductTocId(productTocId);
  };

  const selectLesson = (lesson: LessonRes, productToc: ProductTocRes) => {
    setSelectProductTocId(productToc.productTocId);
    setSelectLessonId(lesson.lessonId);
  };

  return (
    <ViewProductContext.Provider
      value={{
        loading: isFetching,
        ready: ready,
        productId: props.productId,
        navigation: navigation,
        selectLessonId: selectLessonId,
        selectedProductTocId: selectProductTocId,
        productTocs: productTocs,
        setSelectLesson: selectLesson,
        setProductTocs: selectProductToc,
      }}
    >
      {props.children}
    </ViewProductContext.Provider>
  );
};

export const ViewProductContextWrapper = (props: {
  productId: number;
  lessonId: number;
  productTocId: number;
  productTocs: ProductTocRes[];
  selectLesson: (lesson: LessonRes, productToc: ProductTocRes) => void;
  selectProductToc: (productTocId: number) => void;
  children: ReactNode;
}) => {
  const [navigation, setNavigation] = useState<TocNavigationProps>({
    current: null,
    currentToc: null,
    previous: null,
    next: null,
  });

  useEffect(() => {
    setNavigation(
      getTocNavigation(props.lessonId, props.productTocId, props.productTocs)
    );
  }, [props.lessonId, props.productTocId, props.productTocs]);

  return (
    <ViewProductContext.Provider
      value={{
        loading: true,
        ready: true,
        productId: props.productId,
        selectLessonId: props.lessonId,
        selectedProductTocId: props.productTocId,
        productTocs: props.productTocs,

        navigation: navigation,
        setSelectLesson: props.selectLesson,
        setProductTocs: props.selectProductToc,
      }}
    >
      {props.children}
    </ViewProductContext.Provider>
  );
};

export const useViewProductContext = () => {
  const context = useContext(ViewProductContext);
  if (!context) {
    throw new Error('You must wrap container by ViewProductContextProvider');
  }
  return context;
};

export default ViewProductContextProvider;
