import React, { useState } from 'react';
import { Button } from 'antd';

import SchoolEditForm from '../container/school/SchoolEditForm';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { SchoolTable } from '../components/school/SchoolTable';
import { IconUtils } from '@utils/IconUtils';
import SchoolSettingForm from '@modules/setting/container/school/SchoolSettingForm';
import { useTranslation } from 'react-i18next';

export enum ViewSchoolPageActionEnum {
  DEFAULT = '',
  REFRESH = 'refresh',

  ADD_SCHOOL = 'add',
  EDIT_SCHOOL = 'edit',
  SETTING_SCHOOL = 'setting',
}

const ViewSchoolPage = () => {
  const { t } = useTranslation();

  const [action, setAction] = useState({
    action: ViewSchoolPageActionEnum.DEFAULT,
    params: -1,
  });

  const changeAction = (action: ViewSchoolPageActionEnum, params: number) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const handleOnSuccess = () => {
    changeAction(ViewSchoolPageActionEnum.REFRESH, -1);
  };

  const onCancel = () => {
    changeAction(ViewSchoolPageActionEnum.DEFAULT, -1);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          Schools
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() =>
              setAction({
                action: ViewSchoolPageActionEnum.ADD_SCHOOL,
                params: -1,
              })
            }
          />
        </H1>
      </HeaderGroup>

      <SchoolTable action={action} changeAction={changeAction} />

      {action.action === ViewSchoolPageActionEnum.ADD_SCHOOL && (
        <CustomModal
          header={'Create school'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <SchoolEditForm onSuccess={handleOnSuccess} onCancel={onCancel} />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ViewSchoolPageActionEnum.EDIT_SCHOOL && (
        <CustomModal
          header={t('school.actions.update_school')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <SchoolEditForm
              schoolId={action.params}
              onSuccess={handleOnSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ViewSchoolPageActionEnum.SETTING_SCHOOL && (
        <CustomModal
          header={t('school.actions.update_school')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <SchoolSettingForm
              schoolId={action.params}
              onSuccess={handleOnSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </>
  );
};

export default ViewSchoolPage;
