import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collapsed: false,
  theme: '',
};

const appLayout = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.collapsed = !state.collapsed;
    },
    showHideMenu: (state, action: { payload: boolean }) => {
      state.collapsed = action.payload;
    },

    changeTheme: (state, action) => {
      if (action.payload) {
        state.theme = action.payload;
      } else {
        state.theme = '';
      }
    },
  },
});

const { actions, reducer } = appLayout;
export const { toggleMenu, showHideMenu, changeTheme } = actions;
export default reducer;
