import {
  CompProps,
  CompMode,
  CompAnswerProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';

import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { DynamicContentCompEditable } from './DynamicContentCompEditable';
import { TextContentProps } from '@cms/content/ContentType';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import styled from 'styled-components';
import { updateComponent } from '@app/redux/slices/composeResource';

export interface DynamicContentProps extends CompProps {
  type: CompTypeEnum.DYNAMIC_CONTENT;

  configuration: {
    content: TextContentProps;
  };
}

export function DynamicContentComp(props: {
  item: DynamicContentProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: string) => {
      const newData = {
        ...props.item,

        configuration: {
          content: {
            id: COMPONENT_UTILS.generateUID(),
            type: AssetsTypeEnum.TEXT,
            data: data,
          },
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <DynamicContentCompStyle
        className={`comp comp-dynamic-content content-editable ${props.item.setting?.className}`}
      >
        <DynamicContentCompEditable
          item={props.item}
          onChange={handleOnChange}
        />
      </DynamicContentCompStyle>
    );
  } else {
    return (
      <DynamicContentCompStyle
        className={`comp comp-dynamic-content ${props.item.setting?.className}`}
        dangerouslySetInnerHTML={{
          __html: props.item.configuration.content.data,
        }}
      />
    );
  }
}

const DynamicContentCompStyle = styled.div`
  p {
    margin-bottom: var(--cms-padding-option, 0.5em);
  }

  .cms-switch-button {
    z-index: 101;
  }
`;
