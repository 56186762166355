import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import useMedia, { ResponsiveScreen } from '@hooks/useMedia';
import { InvitedStudentForm } from '@components/form/InviteStudentForm';
import Alert from '@components/alert';
import Input from '@components/input/input';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import {
  MailOutlined,
  SyncOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { H5 } from '@components/typography';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import { InvitationAPI } from '@services/private/InvitationAPI';
import { InvitationTypeEnum } from '@utils/Constant';
import ButtonGroup from '@components/button/ButtonGroup';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { UserStudentAPI } from '@modules/users/services/UserStudentAPI';

export const InviteStudentForm = (props: {
  schoolId?: number;
  onSuccess: (studentId: number) => void;
  onCancel: () => void;
}) => {
  const screen = useMedia();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [userNames, setUserNames] = useState<string[]>([]);

  const createStudents = () => {
    setUserNames(email.split(' '));
  };

  const clearAllStudents = () => {
    setUserNames([]);
  };

  return (
    <InvitedStudentForm className={'invite-student-form'}>
      <Alert
        style={{ marginTop: 0 }}
        message={'Direction'}
        description={
          <div className={'invite-student-direction'}>
            {t('student.hint.create_simple_1')}
            <br />
            {t('student.hint.create_simple_2')}
            <br />
            {t('student.hint.create_simple_3')}
          </div>
        }
        type="info"
        closable
      />

      <div className={'invite-student-group'}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={'auto'}>
            <Input
              required
              addedClass={'single'}
              name="email"
              type="email"
              placeholder={t('label.email_or_username')}
              onChange={(evt) => setEmail(evt.target.value)}
              value={email}
            />
          </Col>

          <Col span={'auto'}>
            <Button
              type={'primary'}
              shape={'round'}
              onClick={createStudents}
              icon={IconUtils.actions.filter}
            >
              {t('student.actions.find')}
            </Button>
          </Col>
        </Row>
      </div>

      {userNames.length === 0 && (
        <div className={'invite-student-group'}>
          <Row
            gutter={[Default_Gutter, Default_Gutter]}
            style={{ marginTop: '2em' }}
          >
            <Col span={24}>
              <ButtonGroup type={'right'}>
                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.cancel')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      )}

      {userNames.length > 0 && (
        <div className={'invite-student-group'}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <List
                bordered={
                  screen !== ResponsiveScreen.xs &&
                  screen !== ResponsiveScreen.sm
                }
                dataSource={userNames}
                renderItem={(item) => (
                  <List.Item>
                    <GenerateStudentByEmail
                      email={item}
                      onCreateSuccess={props.onSuccess}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>

          <Row
            gutter={[Default_Gutter, Default_Gutter]}
            style={{ marginTop: '2em' }}
          >
            <Col span={24}>
              <ButtonGroup type={'right'}>
                <Button
                  danger={true}
                  type={'primary'}
                  shape={'round'}
                  icon={<SyncOutlined />}
                  onClick={clearAllStudents}
                >
                  {t('button.clear_all')}
                </Button>

                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.cancel')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      )}
    </InvitedStudentForm>
  );
};

const GenerateStudentByEmail = (props: {
  email: string;
  onCreateSuccess: (studentId: number) => void;
}) => {
  const { t } = useTranslation();

  const [findExitUser, { data, error }] =
    StudentAPI.endpoints.findExitUser.useLazyQuery({});

  const [createSimpleUser, { isLoading: isCreateStudent }] =
    UserStudentAPI.endpoints.createSimpleUser.useMutation({});

  const [inviteStudent, { isLoading: isInviteStudent }] =
    InvitationAPI.endpoints.inviteStudent.useMutation({});

  const isEmail = validateEmail(props.email);

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [status, setStatus] = useState<{
    existed: null | boolean;
    created: null | boolean;
    userId: number;
    sent: boolean;
  }>({
    existed: null,
    created: null,
    userId: -1,
    sent: false,
  });

  useEffect(() => {
    findExitUser(props.email);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  }, [props.email]);

  useEffect(() => {
    if (data) {
      setStatus({
        existed: true,
        created: true,
        userId: data.userId,
        sent: false,
      });
    } else if (error) {
      setStatus({
        existed: false,
        created: false,
        userId: -1,
        sent: false,
      });
    }
  }, [data, error, modifiedTime]);

  const handleOnCreate = () => {
    const request = {
      username: props.email,
    };

    createSimpleUser(request)
      .unwrap()
      .then((res) => {
        setStatus((prev) => {
          return { ...prev, created: true, userId: res.userId, sent: true };
        });
        props.onCreateSuccess(res.userId);
      })
      .catch(() => {});
  };

  const handleOnSendInvite = () => {
    const request = {
      type: InvitationTypeEnum.SUBSCRIBLE,
      params: -1,
      studentId: status.userId,
    };

    inviteStudent(request)
      .unwrap()
      .then(() => {
        setStatus((prev) => {
          return { ...prev, sent: true };
        });
      })
      .catch(() => {});
  };

  return (
    <StudentInfo className={'student-info'}>
      <div className={'student-email'}>
        <H5 className={'no-margin'}>
          {isEmail ? <MailOutlined /> : <UserOutlined />}
          &nbsp; {props.email}
        </H5>

        <div className={'student-message'}>
          {status.existed === true && (
            <div>{t('student.warning.student_is_exited')}</div>
          )}

          {status.existed === false && (
            <div
              dangerouslySetInnerHTML={{
                __html: t('student.warning.student_is_not_exit', {
                  username: props.email,
                }),
              }}
            />
          )}
        </div>
      </div>

      <div className={'student-actions'}>
        {status.existed === true && (
          <Button
            size={'small'}
            type={'primary'}
            shape={'round'}
            icon={IconUtils.actions.send_message}
            loading={isInviteStudent}
            onClick={handleOnSendInvite}
            disabled={status.sent}
          >
            Send invitation
          </Button>
        )}

        {status.existed === false && (
          <Button
            size={'small'}
            type={'primary'}
            shape={'round'}
            icon={<UserAddOutlined />}
            onClick={handleOnCreate}
            disabled={status.sent}
            loading={isCreateStudent}
          >
            Create Account
          </Button>
        )}
      </div>
    </StudentInfo>
  );
};
const StudentInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em 1em;
  width: 100%;

  .student-email {
    flex-grow: 1;
  }

  .student-actions {
    text-align: right;
    min-width: 150px;
  }
`;

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
