import React from 'react';
import { useRouter } from '@hooks/useRouter';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { SiteMap } from '@router/SiteMap';
import { DoAnonymousExercisePage } from '@modules/assignments/pages/ViewAnonymousExercisePage';
import { CompMode } from '@cms/ComponentInteface';

const ReviewExercisePage = () => {
  const router = useRouter();

  const { exerciseId } = router.query as unknown as {
    exerciseId: number;
  };

  const handleGoBack = () => {
    router.push(SiteMap.public.lessons);
  };

  return (
    <EmptyLayout className={'review-exercise-anonymous-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_LESSONS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={'Review Exercise'}
        description={'Review Exercise'}
      />
      <DoAnonymousExercisePage
        key={exerciseId + '_' + CompMode.REVIEW_EXERCISE}
        type={CompMode.REVIEW_EXERCISE}
        exerciseId={exerciseId}
        onExit={handleGoBack}
      />
    </EmptyLayout>
  );
};

export default ReviewExercisePage;
