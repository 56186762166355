import HeaderApp from '@components/template/app-layout/header/HeaderApp';
import { FooterApp } from '@components/template/app-layout/footer/FooterApp';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import useMediaQuery, { ResponsiveScreen } from '@hooks/useMedia';
import { showHideMenu } from '@app/redux/slices/appLayoutSlice';

import {
  SiderNavigationApp,
  SiderNavigationMobileApp,
} from './navigation/NavigationApp';

import { FloatButton, Button, Layout } from 'antd';
import { ResponsiveSwitchContent } from '@components/responsive/Responsive';
import { UpOutlined } from '@ant-design/icons';
import SessionExpiredModal from './utils/SessionExpiredModal';
import { checkIframe, showSelectUser } from '@app/redux/slices/appSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { CookieConsent } from '@components/template/common/CookieConsent';
const { Content } = Layout;

export const ManagementLayout = (props: {
  className: string;
  selectUser?: 'teacher' | 'student' | '';
  children: any;
}) => {
  const dispatch = useDispatch();
  const screen = useMediaQuery();

  const { isIframe } = useSelector((state) => state.appState);
  const { collapsed } = useSelector((state) => state.appLayout);

  useEffect(() => {
    dispatch(checkIframe());
  }, []);

  useEffect(() => {
    if (props.selectUser) {
      dispatch(showSelectUser(props.selectUser));
    }

    return () => {
      dispatch(showSelectUser(''));
    };
  }, [props.selectUser]);

  useEffect(() => {
    if (screen === ResponsiveScreen.xs || screen === ResponsiveScreen.sm) {
      dispatch(showHideMenu(false));
    } else if (
      screen === ResponsiveScreen.md ||
      screen === ResponsiveScreen.lg
    ) {
      dispatch(showHideMenu(true));
    } else if (
      screen === ResponsiveScreen.xl ||
      screen === ResponsiveScreen.xxl
    ) {
      dispatch(showHideMenu(false));
    }
  }, [screen]);

  return (
    <>
      <ManagementLayoutWrapper
        hasSider
        className={`app-layout management-layout screen-${screen} 
        ${props.className} 
        ${isIframe ? 'is-iframe' : ''}`}
      >
        {!isIframe && (
          <ResponsiveSwitchContent
            screens={[ResponsiveScreen.xs, ResponsiveScreen.sm]}
            excludeScreenContent={<SiderNavigationApp />}
          >
            <SiderNavigationMobileApp />
          </ResponsiveSwitchContent>
        )}

        <AppContentWrapper
          className={`management-content-layout 
          ${collapsed ? 'collapsed' : ''}`}
        >
          <HeaderApp />
          <Content style={{ position: 'relative' }}>{props.children}</Content>
          <FooterApp />
        </AppContentWrapper>

        <FloatButton.BackTop style={{ right: 6, bottom: 6 }}>
          <Button
            type={'primary'}
            shape={'circle'}
            size={'middle'}
            icon={<UpOutlined />}
          />
        </FloatButton.BackTop>
      </ManagementLayoutWrapper>

      <CookieConsent />
      <SessionExpiredModal />
    </>
  );
};

const ManagementLayoutWrapper = styled(Layout)`
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  &.is-iframe {
    .ant-layout-header {
      display: none !important;
    }
  }

  &.empty-layout {
    .management-content-layout .ant-layout-content {
      padding: 0px;
    }

    .management-content-layout .ant-layout-footer {
      display: none;
    }
  }
`;

const AppContentWrapper = styled(Layout)`
  margin-left: 270px;
  background: ${(props) => props.theme.app.background};

  &.collapsed {
    margin-left: 80px;
  }

  .screen-xs & {
    margin-left: 0px !important;
  }

  .screen-sm & {
    margin-left: 0px !important;
  }

  .ant-layout-header {
    margin-top: 16px;

    .screen-xs & {
      margin-top: 12px;
    }

    .screen-sm & {
      margin-top: 12px;
    }

    .screen-md & {
      margin-top: 12px;
    }
  }

  .ant-layout-header,
  .ant-layout-footer {
    padding: 0px 12px;

    .screen-xs & {
      padding: 0px 12px;
    }

    .screen-sm & {
      padding: 0px 12px;
    }

    .screen-md & {
      padding: 0px 16px;
    }

    .screen-lg & {
      padding: 0px 24px;
    }

    .screen-xl & {
      padding: 0px 32px;
    }

    .screen-xxl & {
      padding: 0px 48px;
    }
  }

  .ant-layout-content {
    padding: 10px 10px;

    .screen-xs & {
      padding: 6px 12px;
    }

    .screen-sm & {
      padding: 6px 12px;
    }

    .screen-md & {
      padding: 8px 16px;
    }

    .screen-lg & {
      padding: 12px 24px;
    }

    .screen-xl & {
      padding: 16px 32px;
    }

    .screen-xxl & {
      padding: 24px 48px;
    }
  }
`;
