import React, { useEffect, useState } from 'react';
import { Row, Col, Button, notification, Divider } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import Input from '@components/input/input';
import ContentEditor from '@components/editor/WysyEditor';
import ButtonGroup from '@components/button/ButtonGroup';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  SchoolAPI,
  SchoolRes,
  UpdateSchoolInfoRes,
} from '@services/private/SchoolAPI';
import { ImageScale } from '@utils/ImageScale';
import InputValidation from '@components/input/InputValidation';
import { Default_Gutter } from '@components/grid';
import { useLoadSchool } from '@hooks/useLoadSchool';
import { IconUtils } from '@utils/IconUtils';

export default function SchoolInstructionForm(props: {
  onSuccess: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const { ready, data } = useLoadSchool(-1);
  const [updateSchoolInfo, { isLoading }] =
    SchoolAPI.endpoints.updateSchoolInfo.useMutation({});

  const [introduce, setIntroduce] = useState('');
  const [shortInstruction, setSortInstruction] = useState('');
  const [avatar, setAvatar] = useState('');
  const [thumbnails, setThumbnails] = useState('');

  useEffect(() => {
    if (data && data.schoolId) {
      setSchoolData(data);
    }
  }, [data]);

  const setSchoolData = (data: SchoolRes) => {
    formik.setFieldValue('schoolId', data.schoolId);
    formik.setFieldValue('introduce', data.introduce || '');
    formik.setFieldValue('shortIntroduce', data.shortIntroduce || '');
    formik.setFieldValue('avatarUrl', data.avatarUrl);
    formik.setFieldValue('thumbnailsUrl', data.thumbnailsUrl);
    formik.setFieldValue('youtubeUrl', data.youtubeUrl);
    formik.setFieldValue('facebookAccount', data.facebookAccount);
    formik.setFieldValue('googleAccount', data.googleAccount);

    setIntroduce(data.introduce);
    setSortInstruction(data.shortIntroduce);
    setAvatar(data.avatarUrl);
    setThumbnails(data.thumbnailsUrl);
  };

  const handleInstructionEditorChange = (introduce: string) => {
    formik.setFieldValue('introduce', introduce);
  };

  const handleShortInstructionEditorChange = (shortIntroduce: string) => {
    formik.setFieldValue('shortIntroduce', shortIntroduce);
  };

  const handleOnAvatarUploaded = (uploadedFile: string) => {
    setAvatar(uploadedFile);
    formik.setFieldValue('avatarUrl', uploadedFile);
  };

  const handleOnThumbnailUploaded = (uploadedFile: string) => {
    setThumbnails(uploadedFile);
    formik.setFieldValue('thumbnailsUrl', uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err');
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      schoolId: -1,
      introduce: '',
      shortIntroduce: '',

      avatarUrl: '',
      thumbnailsUrl: '',
      youtubeUrl: '',

      facebookAccount: '',
      googleAccount: '',
    } as UpdateSchoolInfoRes,

    validationSchema: yup.object().shape({
      introduce: yup.string().required(t('validation.required.field')),
      shortIntroduce: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      updateSchoolData(values);
    },
  });

  const updateSchoolData = (request: UpdateSchoolInfoRes) => {
    updateSchoolInfo(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: `Cập nhật thành công`,
          description: 'Cập nhật thành công.',
          placement: 'bottomRight',
        });
        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: `Cập nhật thất bại`,
          description: 'Cập nhật thất bại.',
          placement: 'bottomRight',
        });
      });
  };

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col flex={ImageScale.avatar.width + 24 + 'px'}>
          <InputGroup label={'Avatar'}>
            <SingleUpload
              scale={ImageScale.avatar.scale}
              accept={'image/jpg, image/jpeg, image/png'}
              multiple={false}
              onSuccessFunc={handleOnAvatarUploaded}
              onErrorFunc={handleOnUploadHasErr}
            >
              <IconImage
                src={avatar}
                width={ImageScale.avatar.width}
                height={ImageScale.avatar.height}
              />
            </SingleUpload>
          </InputGroup>
        </Col>

        <Col flex={'auto'}>
          <Input
            name="youtubeUrl"
            type="text"
            label={t('label.youtube_url')}
            placeholder={t('label.youtube_url')}
            onChange={formik.handleChange}
            value={formik.values.youtubeUrl}
          />

          <Divider plain>{t('label.or')}</Divider>

          <InputGroup label={'Thumbnails'}>
            <SingleUpload
              scale={ImageScale.scale169.scale}
              accept={'image/jpg, image/jpeg, image/png'}
              multiple={false}
              onSuccessFunc={handleOnThumbnailUploaded}
              onErrorFunc={handleOnUploadHasErr}
            >
              <IconImage src={thumbnails} />
            </SingleUpload>
          </InputGroup>

          <InputGroup label={t('label.instruction')} required={true}>
            {ready && (
              <ContentEditor
                initValue={introduce}
                onChange={handleInstructionEditorChange}
                numberRows={10}
              />
            )}
            <InputValidation name={'introduce'} formik={formik} />
          </InputGroup>

          <InputGroup label={t('label.short_instruction')} required={true}>
            {ready && (
              <ContentEditor
                initValue={shortInstruction}
                onChange={handleShortInstructionEditorChange}
              />
            )}
            <InputValidation name={'shortIntroduce'} formik={formik} />
          </InputGroup>

          <Input
            name="facebookAccount"
            type="text"
            label={t('label.facebook_account')}
            placeholder={t('label.facebook_account')}
            onChange={formik.handleChange}
            value={formik.values.facebookAccount}
          />

          <ButtonGroup className={'submit-container'} type={'space-between'}>
            <Button
              type="default"
              size={'large'}
              shape={'round'}
              onClick={props.onCancel}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              size={'large'}
              shape={'round'}
              loading={isLoading}
              onClick={formik.submitForm}
              icon={IconUtils.review}
            >
              Review
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
