import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { SocialVideoProps } from '@cms/comps/presentation/social-video/SocialVideoComp';

export const SocialMediaPlayerSampleData: SocialVideoProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.SOCIAL_VIDEO,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        name: 'video1 ',
        scale: 9 / 16,
        type: 'facebook',
        src: 'https://www.facebook.com/100069806454064/videos/1543175626146822',
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        scale: 9 / 16,
        name: 'video1 ',
        type: 'youtube',
        src: 'https://www.youtube.com/watch?v=-xOYekYN-oU',
      },
    ],
  },
  setting: {
    autoScore: false,
  },
};
