import styled from 'styled-components';
import { useEffect, useState } from 'react';

const ScrollToTopEnd = styled.div``;

function ScrollToTop(props: { visible: boolean; offsetTop?: number }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisibility = () => {
    const offsetTop = props.offsetTop != null ? props.offsetTop : 100;
    if (window.pageYOffset > offsetTop) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      {props.visible && isVisible && (
        <ScrollToTopEnd className={'scroll-to-top'} onClick={scrollToTop}>
          Top
        </ScrollToTopEnd>
      )}
    </>
  );
}

export default ScrollToTop;
