import React, { useEffect, useState } from 'react';

import { Button, notification, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import { LanguageContentInput } from '@components/editor/LanguageContentInput';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';
import {
  CreateStandardSetReq,
  StandardSetRes,
} from '@modules/product/services/standard_model';

const initialValue: CreateStandardSetReq = {
  standardSetId: -1,
  code: '',
  name: '',
  description: '',
  displayOrder: 1,
};

export function EditStandardSetForm(props: {
  mode: 'manage' | 'compose';
  data: StandardSetRes | null;
  successHandle: (data: StandardSetRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess }] =
    StandardSetAPI.endpoints.findById.useLazyQuery();
  const [createStandardSet, { isLoading: createLoading }] =
    StandardSetAPI.endpoints.create.useMutation({});
  const [updateStandardSet, { isLoading: updateLoading }] =
    StandardSetAPI.endpoints.update.useMutation({});

  const { isReady: isLoadSchoolReady, school } = useSelector(
    (state) => state.userInformation
  );

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.data != null && props.data.standardSetId > 0) {
      findById(props.data.standardSetId);
    } else {
      setIsReady(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setIsReady(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (school && isLoadSchoolReady) {
      if (school != null && school.schoolId > 0) {
        formik.setFieldValue('schoolId', school.schoolId);
      }
    }
  }, [school]);

  const setFormData = (data: StandardSetRes) => {
    formik.setValues({
      standardSetId: data.standardSetId,
      code: data.code,
      name: data.name,
      description: data.description,
      displayOrder: data.displayOrder,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      code: yup.string().required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.standardSetId > 0) {
        updateStandardSet(values)
          .unwrap()
          .then((data) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle(data);
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        createStandardSet(values)
          .unwrap()
          .then((data) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });

            props.successHandle(data);
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const handleOnLanguageChange = (value: string) => {
    formik.setFieldValue('description', value);
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                required={true}
                name="code"
                type="text"
                label={t('label.code')}
                placeholder={t('label.code')}
                onChange={formik.handleChange}
                value={formik.values.code}
              />

              <Input
                required={true}
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <LanguageContentInput
                row={2}
                maxLength={512}
                initValue={data ? data.description : ''}
                onChange={handleOnLanguageChange}
              />

              <Input
                name="displayOrder"
                type="number"
                label={t('label.displayOrder')}
                placeholder={t('label.displayOrder')}
                onChange={formik.handleChange}
                value={formik.values.displayOrder}
              />

              <div className="submit-container">
                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col flex={'150px'}>
                    {props.mode === 'compose' && (
                      <Button
                        shape={'round'}
                        type="default"
                        size={'large'}
                        onClick={props.onCloseFunc}
                      >
                        {t('standard_set.actions.go_back_to_standard')}
                      </Button>
                    )}
                  </Col>

                  <Col flex={'auto'}>
                    <ButtonGroup type={'right'}>
                      <Button
                        type={'primary'}
                        loading={createLoading || updateLoading}
                        shape={'round'}
                        size={'large'}
                        onClick={formik.submitForm}
                        icon={IconUtils.actions.save}
                      >
                        {!props.data && <>{t('standard_set.actions.add')}</>}

                        {props.data && <>{t('standard_set.actions.edit')}</>}
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
