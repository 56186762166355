import styled from 'styled-components';
import React, { useEffect } from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';

import MovingResourceToolbar from '@modules/product/components/resource/toolbar/MovingResourceToolbar';
import { useDispatch } from 'react-redux';
import { addComponents } from '@app/redux/slices/composeResource';

export interface ResourceActionProps {
  key: EditResourceAction;
  callBackFunc: (resourceId: number, params?: any) => void;
}

export enum EditResourceAction {
  ADD_COMPONENT = 'restore',

  ADD_TO_LESSON = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  CLONE = 'clone',

  MOVE_UP = 'move-up',
  MOVE_DOWN = 'move-down',

  MOVE_TOP = 'move-top',
  MOVE_BOTTOM = 'move-bottom',

  SETTING = 'setting',
  HIDE_SETTING = 'hide-setting',
}

export default function MovingResourceItem(props: {
  isUsed?: boolean;
  selected?: boolean;

  questionNumber: number;
  totalQuestion: number;

  item: ResourceProps;

  onActionClick?: (
    action: EditResourceAction,
    resourceData: ResourceProps
  ) => void;

  onCloseFunc?: () => void;
  onListenActions?: (action: ResourceActionProps) => void;
  children: any;
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.onListenActions) {
      props.onListenActions({
        key: EditResourceAction.ADD_COMPONENT,

        callBackFunc: (resourceId: number, comp: any) => {
          if (resourceId === props.item.resourceId) {
            dispatch(addComponents(comp));
          }
        },
      });
    }
  }, []);

  const handleOnClick = (action: EditResourceAction) => {
    if (props.onActionClick) {
      props.onActionClick(action, props.item);
    }
  };

  const getClassName = () => {
    let classes = 'cms-edit-content-item';
    if (props.isUsed != null && props.isUsed) {
      classes += ' used-resource ';
    }
    if (props.selected != null && props.selected) {
      classes += ' selected-resource ';
    }

    return classes;
  };

  return (
    <EditResourceItemStyle className={getClassName()}>
      <MovingResourceToolbar
        item={props.item}
        disablePrevious={props.questionNumber < 2}
        disabledNext={props.questionNumber >= props.totalQuestion}
        questionNumber={props.questionNumber}
        onClick={handleOnClick}
      />
      <div className={'cms-edit-content-content'}>{props.children}</div>
    </EditResourceItemStyle>
  );
}

export const EditResourceItemStyle = styled.div`
  padding: 0.125em 0.5em 0.5em;
  border: 2px dashed rgb(153, 153, 153);
  border-radius: 8px;

  &:not(:first-child) {
    margin-top: var(--cms-padding-question, 1.5em);
  }

  &.selected-resource {
    border: 2px dashed #6610f2;
  }

  .cms-edit-content-header {
    display: flex;
    color: rgb(99, 102, 241);
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 0.125em;
    margin-bottom: 0.25em;
  }

  .cms-edit-content-content {
  }

  .cms-edit-content-footer {
    border-top: 1px solid #ccc;
    padding: 0.5em 0.5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .click-to-add-resource {
    font-size: 150%;
    min-height: 10em;
    line-height: 1.6;
    border: 3px dashed #ccc;
    background: #fff;
    color: ${(props) => props.theme.color.grey};

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .gstudy-nav-link {
      text-decoration: none;
    }
  }
`;
