export enum LessonActionEnum {
  DEFAULT = '',
  ADD_LESSON = 'add-lesson',

  EDIT_METADATA = 'edit-metadata-lesson',
  REMOVE_LESSON = 'remove-lesson',

  SHARE_LESSON = 'share-lesson',
  INVITE_STUDENT = 'invite-student',

  ASSIGN_LESSON = 'assign-lesson',
  LINK_TO_EXAMPLE = 'link-to-example',
}
