import React from 'react';

import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import ManageSchoolAdminPage from '@modules/users/pages/ManageSchoolAdminPage';

const SchoolAdminManagement = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'school-admin-page'}>
      <PageHeader
        menu={NavigationMenuEnum.USERS}
        subMenu={NavigationMenuEnum.USERS_SCHOOL_ADMIN}
        title={t('school_admin.header')}
        description={t('school_admin.header')}
      />

      <ManageSchoolAdminPage />
    </ManagementLayout>
  );
};

export default SchoolAdminManagement;
