import { CertificateRes } from '@modules/admin/service/program';
import styled from 'styled-components';
import IconImage from '@components/images/IconImage';
import { ImageScale } from '@utils/ImageScale';
import React from 'react';

export const CertificateDisplay = (props: {
  className?: string;
  item: CertificateRes;
}) => {
  return (
    <CertificateDisplayStyle
      className={`certificate-display ${props.className}`}
    >
      <IconImage
        src={props.item.icon}
        width={ImageScale.certificate.icon}
        height={ImageScale.certificate.icon}
      />

      <span className={'certificate-title'}>
        {props.item.code} - {props.item.name}
      </span>
    </CertificateDisplayStyle>
  );
};

const CertificateDisplayStyle = styled.div`
  display: flex;
  align-items: center;

  .certificate-title {
    flex-grow: 1;
    white-space: nowrap;
    margin-left: 1ch;
    text-align: left;
  }

  &.bordered {
    border: 1px solid #ccc;
    padding: 0.25em 0.75em;
    border-radius: 1.5em;
  }
`;
