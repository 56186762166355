import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { FibParagraphProps } from '@cms/comps/interact/fill-in-blank/FibParagraph';

export const FibParagraphExampleData: FibParagraphProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FIB_PARAGRAPH,

  setting: {
    autoScore: false,
  },
};
