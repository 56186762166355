import { ReactNode, useEffect, useState } from 'react';
import { Popover as AntdPopOver, Button } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import styled from 'styled-components';

const HintPopOverStyle = styled(AntdPopOver)`
  .ant-popover-title {
    font-size: 150%;
  }
`;

interface HintProps {
  visible?: boolean;
  icon: ReactNode;
  placement?: TooltipPlacement;
  header: string | ReactNode;
  content: string | ReactNode;
  onVisibleChange?: (value: boolean) => void;
}
const Hint = (props: HintProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.visible !== null) {
      setVisible(!!props.visible);
    }
  }, [props.visible]);

  const handleOnOpenChange = (data: boolean) => {
    if (props.onVisibleChange) {
      props.onVisibleChange(data);
    } else {
      setVisible(data);
    }
  };

  return (
    <HintPopOverStyle
      open={visible}
      placement={props.placement ? props.placement : 'top'}
      title={props.header}
      content={
        <div className={'hint-content'} style={{ maxWidth: 350 }}>
          {props.content}
        </div>
      }
      onOpenChange={handleOnOpenChange}
      trigger="click"
    >
      <Button className={'hint-button'} type={'text'} size={'small'}>
        {props.icon}
      </Button>
    </HintPopOverStyle>
  );
};

export default Hint;
