import React from 'react';
import { ProductTableOfContentProvider } from '../context/ProductTableOfContent';
import { TableOfContentProgress } from '../components/toc/TableOfContentProgress';

const ViewProductPage = (props: { productId: number }) => {
  return (
    <div className={'block-container'}>
      <ProductTableOfContentProvider
        productId={props.productId}
        productTocId={-1}
        lessonId={-1}
      >
        <TableOfContentProgress />
      </ProductTableOfContentProvider>
    </div>
  );
};

export default ViewProductPage;
