import {
  MathOperationEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import React, { useEffect, useState } from 'react';
import {
  AreaOperationColumnType,
  AreaOperationRowType,
  AreaOperationTableType,
  AreaOperationTableWrapper,
} from '@cms/comps/math/operation/table/AreaOperationTable';

export const AreaOperationTableEditable = (props: {
  operation: MathOperationEnum;
  expression: OperationExpProps[];
  answerExpression: OperationExpProps[];
  onChange: (index: number, expression: OperationExpProps) => void;
}) => {
  const handleOnChange = (index: number, expression: OperationExpProps) => {
    props.onChange(index, expression);
  };

  return (
    <AreaOperationTableWrapper
      className={`area-operation-table-wrapper area-operation-table-editable area-operation-table-${props.operation}`}
    >
      <AreaOperationTableType className={`area-operation-table`}>
        {props.expression.map((row, index) => {
          return (
            <React.Fragment key={JSON.stringify(row) + '_' + index}>
              <AreaOperationRow
                operation={props.operation}
                expression={row}
                answerExpression={props.answerExpression[index]}
                onChange={(expression) => handleOnChange(index, expression)}
              />
            </React.Fragment>
          );
        })}
      </AreaOperationTableType>
    </AreaOperationTableWrapper>
  );
};

const AreaOperationRow = (props: {
  operation: MathOperationEnum;
  expression: OperationExpProps;
  answerExpression: OperationExpProps;
  onChange: (expression: OperationExpProps) => void;
}) => {
  const [inputType, setInputType] = useState<{
    value: string[];
    triggerChange: boolean;
  }>({
    value: [],
    triggerChange: false,
  });

  useEffect(() => {
    const _inputType = props.expression.exp.map((exp) => {
      return exp;
    });

    setInputType({
      value: _inputType,
      triggerChange: false,
    });
  }, [props.expression]);

  useEffect(() => {
    if (inputType.triggerChange) {
      const newExpression = {
        type: props.expression.type,
        exp: inputType.value,
      };
      props.onChange(newExpression);
    }
  }, [inputType]);

  const changeToLabel = (index: number) => {
    setInputType((prev) => {
      const inputFields = [...prev.value].map((val, idx) => {
        if (index === idx) {
          return props.answerExpression.exp[idx];
        } else {
          return val;
        }
      });

      return {
        value: inputFields,
        triggerChange: true,
      };
    });
  };

  const changeToExpression = (index: number) => {
    setInputType((prev) => {
      const inputFields = [...prev.value].map((val, idx) => {
        if (index === idx) {
          return OperationUtils.VARIABLE_CHARACTER;
        } else {
          return val;
        }
      });

      return {
        value: inputFields,
        triggerChange: true,
      };
    });
  };

  return (
    <AreaOperationRowType type={props.expression.type}>
      {inputType.value.map((exp, colIndex) => {
        if (exp === OperationUtils.HIDDEN_CHARACTER) {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-hidden'}
              key={exp + '-' + colIndex}
            >
              <span>&nbsp;</span>
            </AreaOperationColumnType>
          );
        } else if (exp === OperationUtils.VARIABLE_CHARACTER) {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-input'}
              key={exp + '-' + colIndex}
            >
              <span onClick={() => changeToLabel(colIndex)}>
                <span>
                  {props.answerExpression && (
                    <>{props.answerExpression.exp[colIndex]}</>
                  )}
                </span>
              </span>
            </AreaOperationColumnType>
          );
        } else {
          return (
            <AreaOperationColumnType
              className={'area-operation-field area-operation-label'}
              key={exp + '-' + colIndex}
            >
              <span onClick={() => changeToExpression(colIndex)}>
                <span>{exp}</span>
              </span>
            </AreaOperationColumnType>
          );
        }
      })}
    </AreaOperationRowType>
  );
};
