import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { WordScrambleCompProps } from '@cms/comps/game/word-scramble/WordScrambleComp';

export const WordScrambleSampleData: WordScrambleCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.WORD_SCRAMBLE,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana Thumbnail',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry Thumbnails',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    scramble: true,
    tryTimes: 5,
    layout: 'top',
  },
};
