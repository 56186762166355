import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import { useRouter } from '@hooks/useRouter';
import ProductAccessiblePage from '@modules/product/pages/ProductLessonAccessiblePage';

const ManageProductAccessible = () => {
  const router = useRouter();
  const { productId } = router.query;

  return (
    <ManagementLayout className={'product-accessible-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={'Accessible'}
        description={'Manage Accessible'}
      />

      <ProductAccessiblePage productId={Number(productId)} />
    </ManagementLayout>
  );
};

export default ManageProductAccessible;
