import {
  LessonSectionProps,
  SectionTypeEnum,
} from '@cms/section-bank/SectionSetting';
import React from 'react';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompMode } from '@cms/ComponentInteface';
import { H5 } from '@components/typography';
import {
  SectionDropColumn,
  SectionResourceDragItem,
} from '../components/SectionDropColumn';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import { useTranslation } from 'react-i18next';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import styled from 'styled-components';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SingleQuestionToolbarHandle } from '@cms/lesson-template/components/toolbar/SingeQuestionToolbar';

export const getColumnStyle = (
  width: number,
  totalColumn: number,
  gap: string
) => {
  const gapUnit = gap.includes('px')
    ? 'px'
    : gap.includes('pt')
    ? 'pt'
    : gap.includes('rem')
    ? 'rem'
    : 'em';
  const gapValue = Number(gap.replace(gapUnit, ''));
  const gapSize = totalColumn > 1 ? gapValue / totalColumn : 0;

  return {
    width: `calc(${width}% - ${gapSize}${gapUnit})`,
  };
};

export interface SectionColumnSettingProps {
  width: number[];
  gap: string;
}

export interface OneColumnSectionProps extends LessonSectionProps {
  type: SectionTypeEnum.one_column;
  setting: SectionColumnSettingProps;

  group: {
    content: ResourceProps[];
  };
}

export const OneColumnSection = (props: {
  item: OneColumnSectionProps;
  mode: CompMode;
}) => {
  const { t } = useTranslation();
  const { config } = useLessonTemplateContext();
  const { resource } = useLessonNavigationContext();

  return (
    <ColumnSectionStyle
      className={`lesson-section one-column-section section_${props.mode.toLowerCase()}`}
    >
      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.header && (
          <div className={'lesson-section-header-container'}>
            <ContentViewer contentData={props.item.content.header} />
          </div>
        )}

      <div className={'lesson-section-container'}>
        {(props.mode === CompMode.COMPOSE ||
          (props.item.group.content != null &&
            props.item.group.content.length > 0)) && (
          <div
            className={'content-group'}
            style={getColumnStyle(
              props.item.setting.width[0],
              1,
              props.item.setting.gap
            )}
          >
            <SectionDropColumn
              title="Columns"
              group={'content'}
              code={props.item.code}
              disabled={props.mode !== CompMode.COMPOSE}
            >
              {props.item.group.content.map((item, index) => {
                const visible =
                  props.mode === CompMode.COMPOSE ||
                  Boolean(
                    !config.singleQuestionMode ||
                      resource.resourceId === item.resourceId
                  );

                if (visible) {
                  return (
                    <SectionResourceDragItem
                      key={item.resourceId + '_' + index + '_' + visible}
                      index={index}
                      item={item}
                      mode={props.mode}
                    />
                  );
                } else {
                  return null;
                }
              })}

              {props.mode === CompMode.COMPOSE && (
                <H5 className={'empty-space'}>
                  {t('section.direction.drag_question')}
                </H5>
              )}

              <SingleQuestionToolbarHandle
                sectionId={props.item.lessonSectionId}
                resources={props.item.group.content}
              />
            </SectionDropColumn>
          </div>
        )}
      </div>

      {props.mode !== CompMode.COMPOSE &&
        props.item.content &&
        props.item.content.footer && (
          <div className={'lesson-section-footer-container'}>
            <ContentViewer contentData={props.item.content.footer} />
          </div>
        )}
    </ColumnSectionStyle>
  );
};

export const OneColumnSectionData: OneColumnSectionProps = {
  lessonSectionId: -1,
  code: COMPONENT_UTILS.generateUID(),
  type: SectionTypeEnum.one_column,
  name: 'One-column layout',
  setting: {
    width: [100],
    gap: '1em',
  },
  group: {
    content: [],
  },
};

export const ColumnSectionStyle = styled.div`
  .lesson-section-header-container {
    margin-bottom: 0.5em;
  }
`;
