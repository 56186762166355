import React from 'react';
import Button from '@components/button';
import styled from 'styled-components';
import { ReportUtils } from '@modules/reports/ReportUtils';

const percent_0 = '/public/images/score/0percent.png';
const percent_25 = '/public/images/score/25percent.png';
const percent_50 = '/public/images/score/50percent.png';
const percent_67 = '/public/images/score/67percent.png';
const percent_75 = '/public/images/score/75percent.png';
const percent_100 = '/public/images/score/100percent.png';
const correct = '/public/images/score/correct.png';
const incorrect = '/public/images/score/incorrect.png';
const skipped = '/public/images/score/skipped.png';
const need_grading = '/public/images/score/grading_icn.svg';

const getRoundUpPercent = (score: number, maxScore: number) => {
  const percent = Math.round((score * 100) / maxScore);
  if (percent === 100) {
    return 100;
  } else if (percent >= 75) {
    return 75;
  } else if (percent >= 67) {
    return 67;
  } else if (percent >= 50) {
    return 50;
  } else if (percent >= 25) {
    return 25;
  } else {
    return 0;
  }
};

export const PercentScoreIcon = (props: {
  score: number;
  maxScore: number;
  onClick?: () => void;
}) => {
  return (
    <Button
      type={'text'}
      title={`${props.score}/${props.maxScore} points`}
      onClick={props.onClick}
    >
      {ReportUtils.percent(props.score, props.maxScore)}%
    </Button>
  );
};

export const ComponentScoreIcon = (props: {
  isSkip: boolean;
  score: number | null;
  maxScore: number;
}) => {
  if (props.isSkip) {
    return <SkipIco />;
  } else if (props.score == null) {
    return <NeedGradingIco maxScore={props.maxScore} />;
  } else if (props.maxScore === 1) {
    if (props.score === 1) {
      return <CorrectIco />;
    } else {
      return <IncorrectIco />;
    }
  } else {
    const percent = getRoundUpPercent(props.score, props.maxScore);
    if (percent === 0) {
      return <ZeroPercentIco score={props.score} maxScore={props.maxScore} />;
    } else if (percent === 25) {
      return (
        <TwentyFivePercentIco score={props.score} maxScore={props.maxScore} />
      );
    } else if (percent === 50) {
      return <FiftyPercentIco score={props.score} maxScore={props.maxScore} />;
    } else if (percent === 67) {
      return (
        <SixtySevenPercentIco score={props.score} maxScore={props.maxScore} />
      );
    } else if (percent === 75) {
      return (
        <SeventyFivePercentIco score={props.score} maxScore={props.maxScore} />
      );
    } else if (percent === 100) {
      return (
        <HundredPercentIco score={props.score} maxScore={props.maxScore} />
      );
    } else {
      return <NeedGradingIco maxScore={props.maxScore} />;
    }
  }
};

export const getQuestionScoreType = (
  isSkip: boolean,
  score: number | null,
  maxScore: number
) => {
  if (isSkip) {
    return 'skip';
  } else if (score == null) {
    return 'need-grading';
  } else if (maxScore === 1) {
    if (score === 1) {
      return 'correct';
    } else {
      return 'incorrect';
    }
  } else {
    const percent = getRoundUpPercent(score, maxScore);
    if (percent === 0) {
      return 'score-0-percent';
    } else if (percent === 25) {
      return 'score-25-percent';
    } else if (percent === 50) {
      return 'score-50-percent';
    } else if (percent === 67) {
      return 'score-67-percent';
    } else if (percent === 75) {
      return 'score-75-percent';
    } else if (percent === 100) {
      return 'score-100-percent';
    } else {
      return 'need-grading';
    }
  }
};

const ComponentScoreIconStyle = styled.span`
  > .anticon {
    > img {
      width: 20px !important;
      height: 20px !important;
      display: inline-block !important;
      overflow: visible !important;
    }
  }
`;

export const HundredPercentIco = (props: {
  score: number;
  maxScore: number;
}) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 100-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_100}
          alt={`${props.maxScore} points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const SeventyFivePercentIco = (props: {
  score: number;
  maxScore: number;
}) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 75-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_75}
          alt={`${props.score}/${props.maxScore} points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const SixtySevenPercentIco = (props: {
  score: number;
  maxScore: number;
}) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 67-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_67}
          alt={`${props.score}/${props.maxScore} points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};
export const FiftyPercentIco = (props: { score: number; maxScore: number }) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 50-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_50}
          alt={`${props.score}/${props.maxScore} points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const TwentyFivePercentIco = (props: {
  score: number;
  maxScore: number;
}) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 25-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_25}
          alt={`${props.score}/${props.maxScore} points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const ZeroPercentIco = (props: { score: number; maxScore: number }) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item 0-percent'}
      title={`${props.score}/${props.maxScore} points`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={percent_0}
          alt={`0 points`}
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const IncorrectIco = () => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item incorrect'}
      title={'Incorrect'}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={incorrect}
          alt="Incorrect"
        />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const CorrectIco = () => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item correct'}
      title={'Correct'}
    >
      <span className={'anticon'}>
        <img className={'component-score-image'} src={correct} alt="Correct" />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const SkipIco = () => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item skip'}
      title={'Skip'}
    >
      <span className={'anticon'}>
        <img className={'component-score-image'} src={skipped} alt="Skip" />
      </span>
    </ComponentScoreIconStyle>
  );
};

export const NeedGradingIco = (props: { maxScore: number }) => {
  return (
    <ComponentScoreIconStyle
      className={'component-score-item need-grading'}
      title={`Need Grading (Total ${props.maxScore} points)`}
    >
      <span className={'anticon'}>
        <img
          className={'component-score-image'}
          src={need_grading}
          alt="Need Grading"
        />
      </span>
    </ComponentScoreIconStyle>
  );
};
