import { CompProps } from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import CompBank from '@cms/comps-bank/CompsBank';
import ContentBanks from '@cms/comps-bank/ContentBanks';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';

import { Select, Space } from 'antd';
import styled from 'styled-components';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { useTranslation } from 'react-i18next';
import { useProUser } from '@components/button/RequireProUserButton';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ComponentLibrary = (props: {
  type?: LessonTypeEnum;
  onSelect: (compData: CompProps[]) => void;
}) => {
  const { t } = useTranslation();

  const proUser = useProUser();

  const [viewTemplates, { data, isSuccess }] =
    LessonExampleAPI.endpoints.viewTemplates.useLazyQuery({});

  const [ready, setReady] = useState(false);
  const [lessonExampleId, setLessonExampleId] = useState(proUser ? -1 : 0);

  useEffect(() => {
    viewTemplates(props.type ?? LessonTypeEnum.lesson);
  }, [props.type]);

  useEffect(() => {
    if (data && isSuccess) {
      if (data.length > 0) {
        setLessonExampleId(data[0].lessonExampleId);
      }
      setReady(true);
    }
  }, [data, isSuccess]);

  return (
    <ComponentLibraryStyle
      className={`component-library library-${
        props.type ?? LessonTypeEnum.lesson
      }`}
    >
      {ready && (
        <>
          <div className={'component-library-toolbar'}>
            <Select
              size={'large'}
              placeholder={t('component.actions.select_template')}
              onChange={(value) => setLessonExampleId(value)}
              value={lessonExampleId}
              style={{ width: '100%' }}
            >
              {proUser && (
                <Select.Option key={-1} value={-1}>
                  <Space align={'center'}>
                    <ThumbnailsImg
                      ratio={ImageScale.lesson.ratio}
                      type={'icon'}
                      width={32}
                      height={32}
                      src={''}
                    />
                    <span>{t('component.actions.component_banks')}</span>
                  </Space>
                </Select.Option>
              )}

              {data &&
                data.map((example) => {
                  return (
                    <Select.Option
                      key={example.lessonExampleId}
                      value={example.lessonExampleId}
                    >
                      <Space align={'center'}>
                        <ThumbnailsImg
                          ratio={ImageScale.lesson.ratio}
                          type={'icon'}
                          width={32}
                          height={32}
                          src={example.lesson.thumbnails}
                        />
                        <span>{example.name}</span>
                      </Space>
                    </Select.Option>
                  );
                })}
            </Select>
          </div>

          <div className={'component-library-content'}>
            {lessonExampleId > 0 ? (
              <ContentBanks
                lessonExampleId={lessonExampleId}
                onSelect={props.onSelect}
              />
            ) : (
              <CompBank onSelect={props.onSelect} />
            )}
          </div>
        </>
      )}
    </ComponentLibraryStyle>
  );
};

const ComponentLibraryStyle = styled.div`
  .component-library-toolbar {
    margin-bottom: 1em;
  }

  .component-library-content {
  }

  &.library-page {
    .resource-selectable .resource-selectable-item .resource-comps {
      width: 900px;
      transform: scale(0.4); // 366 / 900
      transform-origin: top left;
    }
  }
`;
