import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import React, { useState } from 'react';
import ContainerScale from '@components/Layout/ContainerScale';
import { ExerciseSections } from '@cms/lesson-template/exercise/ExerciseSections';
import { ExerciseTemplateStyleWrapper } from '../exercise/ExerciseTemplate';

export const GlossaryTemplate = () => {
  const { setting } = useLessonTemplateContext();

  const defaultFontSize = setting.fontSizeSetting
    ? setting.fontSizeSetting.default
    : 16;

  const [fontSize, setFontSize] = useState(defaultFontSize);

  const handleOnContainerChange = (width: number) => {
    const newFontSize = (width * defaultFontSize) / 1000;
    setFontSize(newFontSize > 14 ? newFontSize : 14);
  };

  return (
    <ExerciseTemplateStyleWrapper className={'glossary-template-container'}>
      <ContainerScale
        className={'glossary-content-display'}
        scale={9 / 16}
        onChange={handleOnContainerChange}
        style={{ fontSize: fontSize }}
      >
        <ExerciseSections />
      </ContainerScale>
    </ExerciseTemplateStyleWrapper>
  );
};
