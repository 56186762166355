import TablePagination from '@components/table/TablePagination';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { DateLabel } from '@components/text/DateLabel';
import { useTranslation } from 'react-i18next';
import Card, { CardClassEnum } from '@components/card';
import { EntityStatus } from '@components/status/EntityStatus';
import LessonExampleFilter from './LessonExampleFilter';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { LessonExampleActions } from '@modules/product/container/lesson-example/LessonExampleActions';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { EntityStatusEnum } from '@services/model/common';
import {
  FilterLessonExampleRes,
  LessonExampleRes,
  LessonTypeEnum,
} from '@modules/admin/service/model';

export const LessonExampleTable = (props: { category: LessonTypeEnum }) => {
  const { t } = useTranslation();

  const [params, setParams] = useState<FilterLessonExampleRes>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
    searchTerm: '',
    category: props.category,
  });

  const [findAll, { data, isFetching }] =
    LessonExampleAPI.endpoints.findAll.useLazyQuery({});

  const columns = [
    {
      title: t('label.code'),
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      sorter: true,
      width: 200,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 280,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },

    {
      title: t('label.created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      align: 'center',
      width: 150,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: true,
      width: 150,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
      width: 120,
    },
  ];

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    findAll(params);
  }, [params]);

  const processDataRow = (item: LessonExampleRes) => {
    return {
      key: item.lessonExampleId + '_' + item.modifiedDate,
      code: item.code,
      name: item.name,
      description: <LanguageContentTag multiple content={item.description} />,
      status: (
        <>
          <EntityStatus status={item.status} />

          {item.status === EntityStatusEnum.PUBLISHED && (
            <p>
              <DateLabel label={item.publishedDate} />
            </p>
          )}
        </>
      ),
      createdDate: <DateLabel label={item.createdDate} />,
      actions: <LessonExampleActions item={item} onChange={refreshPage} />,
    };
  };

  const handleOnSubmit = (keyword: string) => {
    setParams((prev) => {
      return { ...prev, searchTerm: keyword };
    });
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, ...params };
    });
  };

  return (
    <>
      <Card
        className={CardClassEnum.rectangle_box}
        style={{ marginBottom: '1em' }}
      >
        <LessonExampleFilter
          keyword={params.searchTerm}
          onSubmit={handleOnSubmit}
        />
      </Card>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
      />
    </>
  );
};
