import React from 'react';
import { NextSectionButton } from '../buttons/NextSectionButton';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { SelfCheckBtn } from '@cms/lesson-template/components/button-wrapper/SelfCheckBtn';
import {
  AnonymousViewMode,
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { SubmitExerciseBtn } from '@cms/lesson-template/components/button-wrapper/SubmitExerciseBtn';

export const NextSectionBtn = (props: { onClick?: () => void }) => {
  const { config } = useLessonTemplateContext();
  const { lastQuestion, lastSection } = useLessonNavigationContext();

  return (
    <>
      <AnonymousViewMode>
        <NextSectionButton onClick={props.onClick} />
      </AnonymousViewMode>

      <SelfCheckExerciseMode>
        {lastSection && lastQuestion ? (
          <SelfCheckBtn />
        ) : (
          <NextSectionButton onClick={props.onClick} />
        )}
      </SelfCheckExerciseMode>

      <DoExerciseMode>
        {config.singleQuestionMode ? (
          <>
            {lastSection && lastQuestion ? (
              <SubmitExerciseBtn />
            ) : (
              <NextSectionButton onClick={props.onClick} />
            )}
          </>
        ) : (
          <>
            {lastSection ? (
              <SubmitExerciseBtn />
            ) : (
              <NextSectionButton onClick={props.onClick} />
            )}
          </>
        )}
      </DoExerciseMode>

      <GradingExerciseMode>
        <NextSectionButton onClick={props.onClick} />
      </GradingExerciseMode>

      <ReviewExerciseMode>
        <NextSectionButton onClick={props.onClick} />
      </ReviewExerciseMode>
    </>
  );
};
