import React from 'react';
import { Button, Popover, Space } from 'antd';
import {
  GroupContentStyle,
  GroupHeaderStyle,
} from '@components/template/app-layout/header/HeaderAccountMenu';
import { useTranslation } from 'react-i18next';
import { NotificationOutlined } from '@ant-design/icons';
import { UserInvitations } from '@modules/dashboard/students/UsersInvitations';

const HeaderNotificationMenu = () => {
  const { t } = useTranslation();

  const title = (
    <GroupHeaderStyle className={'user-header-info'}>
      <h3>{t('menu.my_notification')}</h3>
    </GroupHeaderStyle>
  );

  const content = (
    <GroupContentStyle className={'user-group-info'}>
      <Space size={8} direction={'vertical'} style={{ width: '100%' }}>
        <UserInvitations />
      </Space>
    </GroupContentStyle>
  );

  return (
    <Popover
      placement="bottomRight"
      title={title}
      content={content}
      trigger={['click']}
      overlayClassName={'user-info-popup'}
    >
      <Button
        type={'default'}
        shape="circle"
        size={'large'}
        style={{
          background: '#FFF',
        }}
      >
        <NotificationOutlined />
      </Button>
    </Popover>
  );
};

export default HeaderNotificationMenu;
