import React, { useEffect, useState } from 'react';
import { Col, Divider, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { H2, H3 } from '@components/typography';
import Card, { CardClassEnum } from '@components/card';
import Button from '@components/button';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import ButtonGroup from '@components/button/ButtonGroup';
import FilterStudentContainer from '@modules/users/container/FilterStudentContainer';
import StudentAssignmentStatus from '@modules/assignments/components/StudentAssignmentStatus';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';
import { Default_Gutter } from '@components/grid';
import {
  ClassRosterReq,
  SimpleClassRosterRes,
} from '@modules/users/services/clazz';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';

enum AssignmentStudentActionEnum {
  default = '',
  select = 'select',
}

const AssignmentStudentForm = (props: {
  classId?: number;
  teacherId?: number;
  studentAssignmentStatus?: Record<number, ExerciseStatus>;

  rosterIds: ClassRosterReq[];
  rosters?: SimpleClassRosterRes[];
  onChange: (rosterIds: ClassRosterReq[]) => void;
}) => {
  const { t } = useTranslation();

  const [rosters, setRosters] = useState<SimpleClassRosterRes[]>([]);
  const [action, setAction] = useState(AssignmentStudentActionEnum.default);

  useEffect(() => {
    if (props.rosters) {
      setRosters([...props.rosters]);
    }
  }, [props.rosters]);

  const handleOnCancel = () => {
    setAction(AssignmentStudentActionEnum.default);
  };

  const handleOnSelect = (rosters: SimpleClassRosterRes[]) => {
    const _rosterIds: ClassRosterReq[] = rosters.map((ls) => {
      return {
        userId: ls.user.userId,
        classId: ls.clazz.classId,
      };
    });

    const _rosters = rosters.map((rs) => {
      return { user: rs.user, clazz: rs.clazz };
    });

    setRosters(_rosters);
    setAction(AssignmentStudentActionEnum.default);
    props.onChange(_rosterIds);
  };

  const onRemoveStudent = (studentId: number) => {
    setRosters((prev) => {
      return [...prev].filter((st) => {
        return st.user.userId !== studentId;
      });
    });
  };

  return (
    <>
      <HeaderGroup className={'header-group'}>
        <H2>
          {t('assignment.actions.select_student')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(AssignmentStudentActionEnum.select)}
          />
        </H2>
      </HeaderGroup>

      <Card className={CardClassEnum.rectangle_box}>
        <List
          bordered
          dataSource={rosters}
          renderItem={(item) => {
            return (
              <List.Item>
                <StudentAssignmentStatus
                  status={
                    props.studentAssignmentStatus
                      ? props.studentAssignmentStatus[item.user.userId]
                      : null
                  }
                  item={item.user}
                  selected={true}
                  onRemove={onRemoveStudent}
                />
              </List.Item>
            );
          }}
        />
      </Card>

      {action === AssignmentStudentActionEnum.select && (
        <CustomModal
          header={t('assignment.actions.select_student')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <PickupStudentContainer
              teacherId={props.teacherId}
              classId={props.classId}
              rosters={rosters}
              studentAssignmentStatus={props.studentAssignmentStatus}
              onSelect={handleOnSelect}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};

export default AssignmentStudentForm;

const SelectStudentContainerStyle = styled.div``;

const PickupStudentContainer = (props: {
  teacherId?: number;
  classId?: number;
  rosters: SimpleClassRosterRes[];
  studentAssignmentStatus?: Record<number, ExerciseStatus>;
  onSelect: (rosters: SimpleClassRosterRes[]) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [templateStudents, setTemplateStudents] = useState<
    SimpleClassRosterRes[]
  >([]);
  const [templateStudentIds, setTemplateStudentIds] = useState<number[]>([]);

  useEffect(() => {
    setTemplateStudents(props.rosters);
    setTemplateStudentIds(
      props.rosters.map((stu) => {
        return stu.user.userId;
      })
    );
  }, [props.rosters]);

  const onAddStudent = (student: SimpleClassRosterRes) => {
    setTemplateStudents((prev) => {
      return [...prev, student];
    });

    setTemplateStudentIds((prev) => {
      return [...prev, student.user.userId];
    });
  };

  const onRemoveStudent = (studentId: number) => {
    setTemplateStudents((preState) => {
      return preState.filter((ls) => {
        return ls.user.userId !== studentId;
      });
    });

    setTemplateStudentIds((preState) => {
      return preState.filter((st) => {
        return st !== studentId;
      });
    });
  };

  const handleCloseModal = (select: boolean) => {
    if (select) {
      props.onSelect(templateStudents);
    } else {
      props.onCancel();
    }
  };

  return (
    <SelectStudentContainerStyle className={'select-student-form'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <FilterStudentContainer
            teacherId={props.teacherId}
            classId={props.classId ?? -1}
            selectedStudentIds={templateStudentIds}
            studentAssignmentStatus={props.studentAssignmentStatus}
            disabled={{
              class: props.classId != null && props.classId > 0,
            }}
            onSelect={onAddStudent}
            onRemove={onRemoveStudent}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <div>
            <H3>{t('assignment.label.students')}</H3>

            <List
              bordered
              dataSource={templateStudents}
              size={'small'}
              renderItem={(item) => {
                const selected = templateStudentIds.includes(item.user.userId);
                const status = props.studentAssignmentStatus
                  ? props.studentAssignmentStatus[item.user.userId]
                  : null;

                return (
                  <List.Item className={selected ? 'selected' : ''}>
                    <StudentAssignmentStatus
                      status={status}
                      item={item.user}
                      selected={selected}
                      onSelect={() => onAddStudent(item)}
                      onRemove={onRemoveStudent}
                    />
                  </List.Item>
                );
              }}
            />
          </div>
        </Col>
      </Row>

      <Divider dashed />

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <ButtonGroup type={'right'}>
            <Button
              type={'default'}
              shape={'round'}
              size={'large'}
              onClick={() => handleCloseModal(false)}
            >
              {t('label.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              onClick={() => handleCloseModal(true)}
            >
              {t('label.select')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </SelectStudentContainerStyle>
  );
};
