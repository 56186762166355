import {
  CompConfiguration,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { DragNDropInlineCompProps } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineComp';

export const DragNDropInlineExampleData: DragNDropInlineCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.DRAG_N_DROP_INLINE,

  configuration: {
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
    ],

    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data:
            'This is ' +
            CompConfiguration.INLINE_COMPONENT +
            ', and ' +
            CompConfiguration.INLINE_COMPONENT +
            ', and ' +
            CompConfiguration.INLINE_COMPONENT,
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    multipleDrag: false,
    multipleDrop: true,
    eventType: 'drag',
  },
};
