import React from 'react';

import { DnDInlineSelectTargetItems } from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineDropItems';
import { DnDInlineSelectSourceItems } from '@cms/comps/interact/drag-n-drop/inline/items/DnDInlineDragItems';
import { InlineCompClickContextProvider } from '@cms/comps/interact/drag-n-drop/inline/InlineCompClickContext';
import { useDragNDropInlineCompContext } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';

const DragNDropInlineClickEvent = () => {
  const { onDropData, onRemoveData } = useDragNDropInlineCompContext();

  const handleOnSelect = (answer: string) => {
    onDropData(answer);
  };

  const handleOnRemove = (answer: string) => {
    onRemoveData(answer);
  };

  return (
    <InlineCompClickContextProvider
      onSelect={handleOnSelect}
      onRemove={handleOnRemove}
    >
      <div className={'dnd-workspace click-event'}>
        <DnDInlineSelectSourceItems />
        <DnDInlineSelectTargetItems />
      </div>
    </InlineCompClickContextProvider>
  );
};

export default DragNDropInlineClickEvent;
