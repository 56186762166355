import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { CircleUnderlineProvider } from '@cms/comps/interact/circle-underline/CircleUnderlineCompContext';
import { CircleUnderlineEditableProvider } from '@cms/comps/interact/circle-underline/CircleUnderlineEditableContext';
import styled from 'styled-components';
import CircleUnderlineGroupItems from '@cms/comps/interact/circle-underline/CircleUnderlineGroupItems';

export type CircleUnderlineActionType = 'circle' | 'underline';

export interface CircleUnderlineAnsProps extends CompAnswerProps {
  answer: string[];
}

export interface CircleUnderlineSettingProps extends CompInteractSettingProps {
  actions: CircleUnderlineActionType[];
}

export interface CircleUnderlineOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface CircleUnderlineProps extends CompProps {
  type: CompTypeEnum.CIRCLE_UNDERLINE;
  configuration: {
    targetItems: CircleUnderlineOptionProps[];
  };
  setting: CircleUnderlineSettingProps;
}

export const CircleUnderlineComp = (props: {
  disabled: boolean;
  item: CircleUnderlineProps;
  answer: CircleUnderlineAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  return (
    <CircleUnderlineProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <CircleUnderlineEditableProvider>
        <CircleUnderlineStyle
          className={`comp comp-circle-underline ${props.item.setting?.className}`}
        >
          <CircleUnderlineGroupItems />

          {props.feedback && (
            <ComponentGrading
              className={ComponentGradingClassName.absolute_position}
              feedback={props.feedback}
            />
          )}
        </CircleUnderlineStyle>
      </CircleUnderlineEditableProvider>
    </CircleUnderlineProvider>
  );
};

const CircleUnderlineStyle = styled.div``;
