import { ClassSessionAttend } from '@modules/users/component/classes/reports/ClassSessionAttend';
import { Segmented } from 'antd';
import React, { useState } from 'react';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import ButtonGroup from '@components/button/ButtonGroup';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import styled from 'styled-components';
import { ClassStudentProgressReport } from '@modules/users/component/classes/reports/ClassStudentProgressReport';
import { ClassRankingReport } from '@modules/users/component/classes/reports/ClassRankingReport';
import { ClassProficiencySkillReport } from '@modules/users/component/classes/reports/ClassProficiencySkillReport';
import { IconUtils } from '@utils/IconUtils';

enum ClassReportType {
  attend = 'attend',
  progress = 'progress',
  skill = 'skill',
  ranking = 'score',
}

export const ClassReports = (props: { classId: number }) => {
  const [activeTab, setActiveTab] = useState(ClassReportType.attend);

  return (
    <ClassReportsStyle className={'class-reports'}>
      <HeaderGroup className={'class-report-header-group'}>
        <SwitchCondition
          conditions={[
            {
              condition: activeTab === ClassReportType.attend,
              children: <H1>Report on Participants</H1>,
            },

            {
              condition: activeTab === ClassReportType.progress,
              children: <H1>Progress</H1>,
            },

            {
              condition: activeTab === ClassReportType.ranking,
              children: <H1>Ranking</H1>,
            },
          ]}
        />

        <ButtonGroup>
          <Segmented
            size={'large'}
            value={activeTab}
            onChange={(val) => setActiveTab(val as ClassReportType)}
            options={[
              {
                value: ClassReportType.attend,
                label: 'Session',
                icon: IconUtils.menu.calendar,
              },
              {
                value: ClassReportType.progress,
                label: 'Progress',
                icon: IconUtils.exercise.report,
              },

              {
                value: ClassReportType.skill,
                label: 'Proficiency Skill',
                icon: IconUtils.menu.standard,
              },
              {
                value: ClassReportType.ranking,
                label: 'Ranking',
                icon: IconUtils.menu.ranking,
              },
            ]}
          />
        </ButtonGroup>
      </HeaderGroup>

      {activeTab === ClassReportType.attend && (
        <ClassSessionAttend classId={props.classId} />
      )}

      {activeTab === ClassReportType.progress && (
        <ClassStudentProgressReport classId={props.classId} />
      )}

      {activeTab === ClassReportType.skill && (
        <ClassProficiencySkillReport classId={props.classId} />
      )}

      {activeTab === ClassReportType.ranking && (
        <ClassRankingReport classId={props.classId} />
      )}
    </ClassReportsStyle>
  );
};

const ClassReportsStyle = styled.div`
  .class-report-header-group {
    margin-top: 0;
  }
`;
