import React from 'react';
import styled from 'styled-components';
import { H4 } from '@components/typography';
import { PublishLessonInfo } from '@modules/public/components/lessons/PublishLessonInfo';
import {
  ProductRes,
  ProductTocRes,
} from '@modules/product/services/product_model';

export const PublishTableOfContents = (props: {
  items: ProductTocRes[];
  product: ProductRes;
  selectLesson?: number;
  selectProductToc?: number;
}) => {
  return (
    <TableOfContentStyle className={'table-of-contents'}>
      {props.items.map((toc) => {
        if (toc.lessons.length > 0) {
          return (
            <TableOfContent
              key={toc.productTocId + '_' + toc.displayOrder}
              item={toc}
              product={props.product}
              selectLesson={props.selectLesson}
              selectProductToc={props.selectProductToc}
            />
          );
        } else {
          return null;
        }
      })}
    </TableOfContentStyle>
  );
};

export const TableOfContent = (props: {
  item: ProductTocRes;
  product: ProductRes;
  selectLesson?: number;
  selectProductToc?: number;
}) => {
  const isSelectProduct =
    props.selectProductToc != null &&
    props.selectProductToc === props.item.productTocId;

  return (
    <PublicTableOfContentStyle
      className={`table-of-content-item ${
        isSelectProduct ? 'selected-toc' : ''
      }`}
    >
      <H4 className={'product-toc-title'}>
        <span className={'product-toc-name'}>{props.item.name}</span>
        <span className={'product-toc-lesson-number'}>
          ({props.item.lessons.length})
        </span>
      </H4>

      <div className={'product-lesson-group'}>
        {props.item.lessons.length > 0 &&
          props.item.lessons.map((lesson) => {
            return (
              <PublishLessonInfo
                key={lesson.lessonId}
                item={lesson}
                productTocId={props.item.productTocId}
                product={props.product}
                selected={
                  isSelectProduct &&
                  props.selectLesson != null &&
                  props.selectLesson === lesson.lessonId
                }
              />
            );
          })}
      </div>
    </PublicTableOfContentStyle>
  );
};

const TableOfContentStyle = styled.div`
  //max-height: 50vh;
  //overflow-y: auto;

  .table-of-content-item {
    margin-bottom: 1.25em;
  }
`;

export const PublicTableOfContentStyle = styled.div`
  padding: 0.5em 0.5em;
  border: 1px solid #333;

  &.selected-toc {
    .product-toc-title {
      color: ${(props) => props.theme.app.primary};
    }
  }

  .product-toc-title {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #ccc;
    padding-bottom: 0.25em;
    margin-bottom: 0.5em;

    .product-toc-lesson-number {
      font-size: 80%;
      font-weight: 100;
    }
  }

  .product-lesson-group {
    color: ${(props) => props.theme.color.dark_grey};

    > a {
      display: block;

      &:not(:first-child) {
        margin-top: 0.25em;
      }
    }

    .lesson-info {
      width: 100%;
      position: relative;
      cursor: pointer;

      &.selected {
        color: ${(props) => props.theme.app.primary};
        font-weight: bold;

        > * {
          position: relative;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: inline-block;
          background: ${(props) => props.theme.app.primary_bgr};
        }
      }

      &:hover {
        color: ${(props) => props.theme.app.primary};
        font-weight: bold;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: inline-block;
          background: rgba(0, 0, 0, 0.15);
        }
      }

      &:not(:first-child) {
        margin-top: 0.125em;
      }
    }
  }
`;
