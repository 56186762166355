import React from 'react';

import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import {
  SelectDragNDropItem,
  useDragNDropInlineCompContext,
} from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineCompContext';
import { DragNDropItemCompProps } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import { DragItem } from '@components/react-dnd-bt/DragItem';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { useInlineCompClickContext } from '@cms/comps/interact/drag-n-drop/inline/InlineCompClickContext';
import { CompGroupEnum } from '@cms/utils/CompProps';
import OptionEditable from '@cms/editable/OptionEditable';

import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { HolderOutlined } from '@ant-design/icons';

import { useDndInlineEditable } from '@cms/comps/interact/drag-n-drop/inline/DnDInlineEditable';

const DnDInlineDragItems = () => {
  const { disableSource, sourceItems } = useDragNDropInlineCompContext();
  const { updateOptionType, updateGroupOptions, updateOptionData } =
    useDndInlineEditable();

  return (
    <div className={'dnd-draggable-items'}>
      <DropItem
        droppableId={''}
        acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM_INLINE}
        disabledIds={disableSource}
      >
        {sourceItems &&
          sourceItems.map((drag, index) => {
            return (
              <DragItem
                key={JSON.stringify(drag) + '_' + index}
                acceptType={DragItemTypeEnum.DRAG_N_DROP_ITEM_INLINE}
                index={index}
                draggableId={drag.label}
              >
                <ComposeModeContent>
                  <HolderOutlined />
                </ComposeModeContent>

                <OptionEditable
                  type={drag.content.type}
                  disabled={{
                    addOption: false,
                    removeOption: sourceItems.length === 1,
                  }}
                  onChangeType={(newType) =>
                    updateOptionType(newType, index, drag, CompGroupEnum.source)
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(
                      operation,
                      index,
                      drag,
                      CompGroupEnum.source
                    )
                  }
                >
                  <ContentViewerEditable
                    contentData={drag.content}
                    onChange={(data) =>
                      updateOptionData(data, index, drag, CompGroupEnum.source)
                    }
                  />
                </OptionEditable>
              </DragItem>
            );
          })}
      </DropItem>
    </div>
  );
};

export default DnDInlineDragItems;

export const DnDInlineSelectSourceItems = () => {
  const { sourceItems, disableSource } = useDragNDropInlineCompContext();
  const { selectItem, onSelectItem } = useInlineCompClickContext();

  const { updateOptionType, updateGroupOptions, updateOptionData } =
    useDndInlineEditable();

  const handleOnClick = (drag: DragNDropItemCompProps) => {
    if (!disableSource.includes(drag.label)) {
      onSelectItem(drag, 'source', null);
    }
  };

  return (
    <div className={'dnd-draggable-items'}>
      <div className={'draggable-container'}>
        {sourceItems &&
          sourceItems.map((drag, index) => {
            return (
              <div
                key={
                  JSON.stringify(drag) +
                  '_' +
                  index +
                  JSON.stringify(selectItem)
                }
                className={`draggable-item click-event ${getClassName(
                  drag,
                  selectItem,
                  disableSource
                )}`}
                onClick={() => handleOnClick(drag)}
              >
                <OptionEditable
                  type={drag.content.type}
                  disabled={{
                    addOption: false,
                    removeOption: sourceItems.length === 1,
                  }}
                  onChangeType={(newType) =>
                    updateOptionType(newType, index, drag, CompGroupEnum.source)
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(
                      operation,
                      index,
                      drag,
                      CompGroupEnum.source
                    )
                  }
                >
                  <ContentViewerEditable
                    contentData={drag.content}
                    onChange={(data) =>
                      updateOptionData(data, index, drag, CompGroupEnum.source)
                    }
                  />
                </OptionEditable>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const getClassName = (
  drag: DragNDropItemCompProps,
  select: SelectDragNDropItem,
  disableSource: string[]
) => {
  if (disableSource.includes(drag.label)) {
    return 'disabled-option';
  } else if (select != null && select.item != null) {
    if (drag.label === select.item.label && select.type === 'source') {
      return 'highlight-option';
    } else {
      return '';
    }
  } else {
    return '';
  }
};
