import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateUserReq,
  DisabledUserReq,
  FilterUserReq,
} from '@modules/users/services/StudentAPI';
import { UserRes } from '@modules/users/services/model';

export const UserSchoolSupportAPI = createApi({
  reducerPath: 'UserSchoolSupportAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<UserRes>, FilterUserReq>({
      query: (params: FilterUserReq) => ({
        url: `/users/school-supports`,
        method: 'GET',
        params,
      }),
    }),

    getUserData: builder.query<UserRes, number>({
      query: (userId: number) => ({
        url: `/users/school-supports/${userId}`,
        method: 'GET',
      }),
    }),

    createUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/school-supports',
        method: 'POST',
        body,
      }),
    }),

    updateUser: builder.mutation<BasicMessageRes, CreateUserReq>({
      query: (body) => ({
        url: '/users/school-supports',
        method: 'PUT',
        body,
      }),
    }),

    disableUser: builder.mutation<BasicMessageRes, DisabledUserReq>({
      query: () => ({
        url: '/users/school-supports/disabled',
        method: 'DELETE',
      }),
    }),
  }),
});
