import React, { Suspense, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import MainRouter from '@router/MainRouters';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<div>{t('label.loading')}</div>}>
        <MainRouter />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
