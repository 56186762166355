import { TeacherClassSelect } from '@components/input/teacher/TeacherClassSelect';
import Input from '@components/input/input';
import React, { useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { StudentInfoStatic } from '@components/info/StudentInfo';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { ClassRosterRes } from '@modules/users/services/clazz';

export const SelectClassRoster = (props: {
  selectIds: number[];
  onSelect: (data: ClassRosterRes) => void;
  onRemove: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const [getClassRoster, { data, isSuccess }] =
    ClassAPI.endpoints.getClassRoster.useLazyQuery({});

  const [selectClassId, setSelectClassId] = useState(-1);
  const [keyword, setKeyword] = useState('');
  const [filterStudents, setFilterStudents] = useState<ClassRosterRes[]>([]);

  useEffect(() => {
    getClassRoster({
      classId: selectClassId,
    });
  }, [selectClassId]);

  useEffect(() => {
    if (isSuccess && data) {
      const classRoster = data.content.filter((rt) => {
        return (
          keyword === '' ||
          [
            rt.user.firstName ?? '',
            rt.user.lastName ?? '',
            rt.user.username ?? '',
            rt.user.email ?? '',
            rt.user.phone ?? '',
          ]
            .join(' ')
            .toLowerCase()
            .includes(keyword.toLowerCase().trim())
        );
      });
      setFilterStudents(classRoster);
    }
  }, [keyword, data]);

  const handleSelectClass = (classIds: number[]) => {
    setSelectClassId(classIds[0]);
  };
  return (
    <div className={'select-student'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <TeacherClassSelect
            label={t('label.class')}
            handleSelectClass={handleSelectClass}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Input
            label={t('invitation.student_invitation.student_name')}
            addedClass={'single'}
            name="keyword"
            placeholder={t('label.keyword')}
            onChange={(event) => setKeyword(event.target.value)}
            defaultValue={keyword}
          />
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <List
            bordered
            size={'small'}
            dataSource={filterStudents}
            renderItem={(item) => (
              <List.Item>
                <StudentInfoStatic
                  key={item.classRosterId}
                  selected={
                    props.selectIds != null &&
                    props.selectIds.includes(item.user.userId)
                  }
                  item={item.user}
                  onSelect={() => props.onSelect(item)}
                  onRemove={() => props.onRemove(item.user.userId)}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
