import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
} from '@cms/ComponentInteface';
import React, { useState } from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';

import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { OrderingCompEditable } from '@cms/comps/interact/ordering/OrderingCompEditable';
import { OrderingItems } from './OrderingItems';

export interface OrderingSettingProps extends CompInteractSettingProps {
  layout: 'horizontal' | 'vertical';
}

export interface OrderingOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface OrderingProps extends CompProps {
  configuration: {
    sourceItems: OrderingOptionProps[];
  };
  setting: OrderingSettingProps;
}

export interface OrderingAnsProps extends CompAnswerProps {
  answer: string[];
}

export const OrderingComp = (props: {
  disabled: boolean;
  item: OrderingProps;
  answer: OrderingAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);

  return (
    <OrderingCompStyle
      className={`comp comp-ordering ${props.item.setting?.className} ordering-${props.item.setting.layout}`}
    >
      {showComponent && (
        <>
          <OrderingItems
            disabled={props.disabled}
            item={props.item}
            answer={props.answer}
            feedback={props.feedback}
            onChange={props.onChange}
          />

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <OrderingCompEditable
            item={props.item}
            onClose={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>

      {props.feedback && (
        <ComponentGrading
          className={ComponentGradingClassName.absolute_position}
          feedback={props.feedback}
        />
      )}
    </OrderingCompStyle>
  );
};

const OrderingCompStyle = styled.div`
  position: relative;
  display: block;

  .droppable-container {
    .draggable-item {
      display: inline-flex;
      align-items: center;
      border: 1px dashed #ccc;
      border-radius: 0.25em;
      padding: 0.125em 0.75em;

      &.clone-draggable-item {
        visibility: hidden;
      }

      > .anticon {
        font-size: 80%;
        margin-right: 0.25em;
      }

      .show-correct-answer & {
        color: ${(props) => props.theme.component.correct_answer};
      }
    }
  }

  &.ordering-vertical {
    .droppable-container {
      display: flex;
      flex-direction: column;
    }

    .cms-content-actions {
      margin-bottom: 0.5em;
    }

    .draggable-item {
      &:not(:first-child) {
        margin-top: var(--cms-padding-option, 0.5em);
      }
    }
  }

  &.ordering-horizontal {
    .droppable-container {
      display: inline-flex;
      flex-direction: row;

      .draggable-item {
        &:not(:first-child) {
          margin-left: var(--cms-padding-option, 0.5em);
        }
      }
    }
  }

  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }
`;
