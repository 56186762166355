import React from 'react';
import ManageCalendar from '@modules/calendar/container/ManageCalendar';
import { CalendarContextProvider } from '../context/CalendarContext';

const CalendarManagement = (props: { classId?: number }) => {
  return (
    <CalendarContextProvider>
      <ManageCalendar classId={props.classId} />
    </CalendarContextProvider>
  );
};

export default CalendarManagement;
