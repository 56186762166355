import { createSlice } from '@reduxjs/toolkit';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import {
  CompAnswerProps,
  CompProps,
  CompScoringProps,
} from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';

export enum ComposeCompActionEnum {
  default = '',
  highlight = 'highlight',
  show_setting = 'show-setting',
}

export interface ComponentChangeProps {
  changed: boolean;
  componentChange: boolean;
  answerChange: boolean;
  scoringChange: boolean;
  timestamp: number;
}

const defaultResource = {
  resourceId: -1,
  title: 'unsaving',

  description: '',
  keyword: '',

  components: [],
  correctAnswer: [],
  scoring: [],

  displayOrder: 1,
  questionNumber: 1,
  showQuestionNumber: true,
};

export interface ComposeResourceProps {
  ready: boolean;
  changed: {
    changed: boolean;
    timestamp: number;
  };

  action: {
    action: ComposeCompActionEnum;
    params: any;
    timestamp: number;
  };
  selectComponentId: string;
  originalResource: ResourceProps;

  components: CompProps[];
  newComponents: CompProps[];

  answers: CompAnswerProps[];
  newAnswers: CompAnswerProps[];

  scoring: CompScoringProps[];
  errors: Record<string, string[]> | null;
  modifiedDate: number;
}

const initialState: ComposeResourceProps = {
  ready: false,

  changed: {
    changed: false,
    timestamp: -1,
  },
  originalResource: defaultResource,

  action: {
    action: ComposeCompActionEnum.default,
    params: null,
    timestamp: -1,
  },

  selectComponentId: '',
  components: [],
  newComponents: [],

  answers: [], // answer used to store previous data (data loaded from server).
  newAnswers: [], // new answer used to store current correct answer (data change when edit question).
  scoring: [],
  errors: null as Record<string, string[]> | null,
  // check component change
  modifiedDate: -1,
};

const composeResource = createSlice({
  name: 'composeResource',
  initialState,

  reducers: {
    clearComposeResourceData(state) {
      Object.assign(state, initialState);
    },

    initData(state, action: { payload: ResourceProps | null }) {
      state.ready = true;
      if (action.payload) {
        state.originalResource = action.payload;

        state.components = action.payload.components;
        state.newComponents = action.payload.components;

        state.answers = action.payload.correctAnswer;
        state.newAnswers = action.payload.correctAnswer;

        state.scoring = action.payload.scoring;
        state.changed = {
          changed: false,
          timestamp: -1,
        };
        state.modifiedDate = -1;
      } else {
        state.originalResource = { ...defaultResource };
        state.changed = {
          changed: false,
          timestamp: -1,
        };
        state.modifiedDate = -1;
      }

      state.errors = ResourceUtils.validateResource(
        state.newComponents,
        state.newAnswers,
        state.scoring
      );
    },

    revertData(state) {
      if (state.originalResource) {
        state.components = [...state.originalResource.components];
        state.newComponents = [...state.originalResource.components];

        state.answers = [...state.originalResource.correctAnswer];
        state.newAnswers = [...state.originalResource.correctAnswer];

        state.scoring = [...state.originalResource.scoring];
        state.changed = {
          changed: false,
          timestamp: -1,
        };
        state.modifiedDate = -1;
      } else {
        state.components = [];
        state.newComponents = [];

        state.answers = [];
        state.newAnswers = [];

        state.scoring = [];
        state.changed = {
          changed: false,
          timestamp: -1,
        };
        state.modifiedDate = -1;
      }

      state.errors = ResourceUtils.validateResource(
        state.newComponents,
        state.newAnswers,
        state.scoring
      );
    },

    updateAnswers(state, action: { payload: CompAnswerProps[] }) {
      if (action.payload == null) {
        state.newAnswers = [];
        state.answers = [];
      } else {
        state.newAnswers = action.payload;
        state.answers = action.payload;
      }

      validateData(state, false);
    },

    updateScoring(state, action: { payload: CompScoringProps }) {
      const scoring = [...state.scoring].filter((comp) => {
        return comp.id !== action.payload.id;
      });

      state.scoring = [...scoring, action.payload];

      validateData(state, false);
    },

    updateComponents(state, action: { payload: CompProps[] }) {
      state.selectComponentId = '';

      state.newComponents = [...action.payload];
      state.components = [...action.payload];

      validateData(state, true);
    },

    updateComponentAndScoring(
      state,
      action: { payload: { comps: CompProps[]; scoring: CompScoringProps[] } }
    ) {
      state.selectComponentId = '';

      state.newComponents = [...action.payload.comps];
      state.components = [...action.payload.comps];
      state.scoring = action.payload.scoring;

      validateData(state, true);
    },

    addComponents(state, action: { payload: CompProps[] }) {
      const addedComponents = [...action.payload].map((comp) => {
        return comp;
      });
      state.selectComponentId = addedComponents[0].id;

      state.newComponents = [...state.newComponents, ...addedComponents];
      state.components = state.newComponents;

      const hasInteract = action.payload.some((comp) => {
        return ResourceUtils.isInteractComp(comp.type);
      });

      if (hasInteract) {
        const newScoring = action.payload
          .filter((comp) => {
            return ResourceUtils.isInteractComp(comp.type);
          })
          .map((comp) => {
            return ResourceUtils.generateInteractScoring(comp);
          });
        state.scoring = [...state.scoring, ...newScoring];
      }

      validateData(state, true);
    },

    updateComponent(state, action: { payload: CompProps }) {
      const newCompData = {
        ...action.payload,
        timestamp: DateAndTimeUtils.getCurrentTime(),
      };

      state.selectComponentId = newCompData.id;

      const updateComps = [...state.newComponents].map((comp) => {
        if (comp.id !== newCompData.id) {
          return comp;
        } else {
          return newCompData;
        }
      });

      const components = ResourceUtils.validateComponents(updateComps);
      const validation = ResourceUtils.validateComponentAndAnswer(
        components,
        state.newAnswers
      );

      if (validation.updateComponents) {
        state.newComponents = validation.newComponents;
        state.components = validation.newComponents;
      } else {
        state.newComponents = components;
        state.components = components;
      }

      if (validation.updateAnswers) {
        state.newAnswers = validation.newAnswers;
        state.answers = validation.newAnswers;
      }

      state.scoring = ResourceUtils.validateScoring(
        [...state.scoring],
        components
      );

      validateData(state, false);
    },

    updateComponentAndAnswer(
      state,
      action: { payload: { comp: CompProps; ans: CompAnswerProps } }
    ) {
      state.selectComponentId = action.payload.comp.id;

      const updateComps = [...state.newComponents].map((comp) => {
        if (comp.id !== action.payload.comp.id) {
          return comp;
        } else {
          return action.payload.comp;
        }
      });

      let exitsAns = false;
      const updateAnswers = [...state.newAnswers].map((ans) => {
        if (ans.id === action.payload.ans.id) {
          exitsAns = true;
          return { ...action.payload.ans };
        } else {
          return { ...ans };
        }
      });

      if (!exitsAns) {
        updateAnswers.push({ ...action.payload.ans });
      }

      const components = ResourceUtils.validateComponents(updateComps);
      const validation = ResourceUtils.validateComponentAndAnswer(
        components,
        updateAnswers
      );

      if (validation.updateComponents) {
        state.newComponents = validation.newComponents;
        state.components = validation.newComponents;
      } else {
        state.newComponents = components;
        state.components = components;
      }

      if (validation.updateAnswers) {
        state.newAnswers = validation.newAnswers;
        state.answers = validation.newAnswers;
      } else {
        state.answers = updateAnswers;
        state.newAnswers = updateAnswers;
      }

      state.scoring = ResourceUtils.validateScoring(
        [...state.scoring],
        state.newComponents
      );

      validateData(state, false);
    },

    removeComponent(state, action: { payload: CompProps }) {
      // remove component...
      state.newComponents = [...state.newComponents].filter((comp) => {
        return comp.id !== action.payload.id;
      });
      state.components = state.newComponents;

      // remove answer
      const newAnswer = [...state.newAnswers].filter((ans) => {
        return ans.id !== action.payload.id;
      });

      state.answers = newAnswer;
      state.newAnswers = newAnswer;

      // remove scoring
      state.scoring = [...state.scoring].filter((sc) => {
        return sc.id !== action.payload.id;
      });

      validateData(state, true);
    },

    cloneComponent(state, action: { payload: CompProps }) {
      const clone = {
        ...action.payload,
        id: COMPONENT_UTILS.generateUID(),
      };

      const components = [...state.newComponents];
      const answers = [...state.newAnswers];
      const scoring = [...state.scoring];

      state.selectComponentId = clone.id;

      const exitCompIndex = components.findIndex((comp) => {
        return comp.id === action.payload.id;
      });

      const exitsAnswerIndex = answers.findIndex((ans) => {
        return ans.id === action.payload.id;
      });

      const exitsScoringIndex = scoring.findIndex((sc) => {
        return sc.id === action.payload.id;
      });

      if (exitCompIndex > -1) {
        const _components = COMPONENT_UTILS.insertAtIndex(
          components,
          exitCompIndex,
          clone
        );
        state.newComponents = _components;
        state.components = _components;
      } else {
        components.push(clone);
        state.newComponents = components;
        state.components = components;
      }

      if (exitsAnswerIndex > -1) {
        const cloneAns = answers[exitsAnswerIndex];
        const _answers = COMPONENT_UTILS.insertAtIndex(
          answers,
          exitsAnswerIndex,
          { ...cloneAns, id: clone.id }
        );
        state.answers = _answers;
        state.newAnswers = _answers;
      }

      if (exitsScoringIndex > -1) {
        const cloneScoring = scoring[exitsAnswerIndex];

        state.scoring = COMPONENT_UTILS.insertAtIndex(scoring, exitCompIndex, {
          ...cloneScoring,
          id: clone.id,
        });
      }

      validateData(state, true);
    },

    moveComponent(
      state,
      action: { payload: { comp: CompProps; moveUp: boolean } }
    ) {
      const exitCompIndex = [...state.newComponents].findIndex((comp) => {
        return comp.id === action.payload.comp.id;
      });

      const updateComponents = COMPONENT_UTILS.moveElement(
        [...state.newComponents],
        exitCompIndex,
        action.payload.moveUp ? exitCompIndex - 1 : exitCompIndex + 1
      );

      const newComponents = updateComponents.map((comp) => {
        return { ...comp, timestamp: DateAndTimeUtils.getCurrentTime() };
      });

      state.selectComponentId = action.payload.comp.id;
      state.components = newComponents;
      state.newComponents = newComponents;
      state.answers = state.newAnswers;

      validateData(state, true);
    },

    updateComponentSetting(
      state,
      action: { payload: { comp: CompProps; setting: any } }
    ) {
      const newComp = {
        ...action.payload.comp,
        setting: action.payload.setting,
      };

      const updateComps = [...state.newComponents].map((comp) => {
        if (comp.id !== newComp.id) {
          return comp;
        } else {
          return newComp;
        }
      });

      state.selectComponentId = action.payload.comp.id;
      const components = ResourceUtils.validateComponents(updateComps);
      const validation = ResourceUtils.validateComponentAndAnswer(
        components,
        state.newAnswers
      );

      if (validation.updateComponents) {
        state.components = validation.newComponents;
        state.newComponents = validation.newComponents;
      } else {
        state.components = components;
        state.newComponents = components;
      }

      if (validation.updateAnswers) {
        state.newAnswers = validation.newAnswers;
        state.answers = validation.newAnswers;
      } else {
        state.answers = state.newAnswers;
      }

      state.scoring = ResourceUtils.validateScoring(
        [...state.scoring],
        state.newComponents
      );

      validateData(state, false);
    },

    regenerationScoring(state) {
      state.scoring = ResourceUtils.generationScoring(state.newComponents);
      validateData(state, false);
    },

    regenerationCorrectAnswer(state) {
      state.answers = [];
      state.newAnswers = [];
      validateData(state, false);
    },

    dispatchComposeAction(
      state,
      action: { payload: { action: ComposeCompActionEnum; params: any } }
    ) {
      state.action = {
        ...action.payload,
        timestamp: DateAndTimeUtils.getCurrentTime(),
      };
    },
  },
});

const { actions, reducer } = composeResource;

export const {
  initData,
  revertData,
  updateAnswers,
  updateScoring,

  addComponents,
  updateComponent,
  removeComponent,
  cloneComponent,
  moveComponent,

  updateComponents,
  updateComponentAndScoring,

  updateComponentAndAnswer,
  updateComponentSetting,
  clearComposeResourceData,
  dispatchComposeAction,
  regenerationScoring,
  regenerationCorrectAnswer,
} = actions;
export default reducer;

const validateData = (state: any, updateTimestamp: boolean) => {
  state.changed = checkDataChange(
    state.originalResource,
    state.newComponents,
    state.newAnswers,
    state.scoring
  );

  state.errors = ResourceUtils.validateResource(
    state.newComponents,
    state.newAnswers,
    state.scoring
  );

  if (updateTimestamp) {
    state.modifiedDate = DateAndTimeUtils.getCurrentTime();
  }
};

const checkDataChange = (
  original: ResourceProps | null,
  components: CompProps[],
  answers: CompAnswerProps[],
  scoring: CompScoringProps[]
): ComponentChangeProps => {
  const isChange = original == null;

  const isComponentChange =
    original == null || compareComponents(original.components, components);

  const isAnswerChange =
    original == null || compareAnswers(original.correctAnswer, answers);

  const isScoringChange =
    original == null || compareScoring(original.scoring, scoring);

  return {
    changed: isChange || isComponentChange || isAnswerChange || isScoringChange,
    componentChange: isComponentChange,
    answerChange: isAnswerChange,
    scoringChange: isScoringChange,
    timestamp: DateAndTimeUtils.getCurrentTime(),
  };
};

const compareAnswers = (
  answer: CompAnswerProps[],
  anotherAnswers: CompAnswerProps[]
) => {
  const answerData = answer
    .map((comp) => {
      return JSON.stringify(comp, null, 2);
    })
    .sort();

  const anotherData = anotherAnswers
    .map((comp) => {
      return JSON.stringify(comp, null, 2);
    })
    .sort();

  return answerData !== anotherData;
};

const compareScoring = (
  scoring: CompScoringProps[],
  anotherScoring: CompScoringProps[]
) => {
  const scoringData = scoring
    .map((comp) => {
      return JSON.stringify(comp, null, 2);
    })
    .sort();

  const anotherData = anotherScoring
    .map((comp) => {
      return JSON.stringify(comp, null, 2);
    })
    .sort();

  return scoringData !== anotherData;
};

const compareComponents = (
  components: CompProps[],
  anotherComps: CompProps[]
) => {
  const comData = components
    .map((comp) => {
      return getComponentStr(comp);
    })
    .sort();

  const anotherData = anotherComps
    .map((comp) => {
      return getComponentStr(comp);
    })
    .sort();

  return comData !== anotherData;
};

const getComponentStr = (component: CompProps) => {
  return JSON.stringify({ ...component, timestamp: -1 }, null, 2);
};
