import { SectionSkeleton } from '@cms/design-template/lesson-placeholder/SectionSkeleton';
import styled from 'styled-components';
import React from 'react';
import { SectionToolbarSkeleton } from '@cms/design-template/lesson-placeholder/SectionToolbarSkeleton';

export const LessonSkeleton = (props: {
  header?: string;
  footer?: string;
  singleMode: boolean;
  singleQuestionMode: boolean;
  stepByStep: boolean;
  sections: number[];
}) => {
  return (
    <LessonSkeletonStyle className={'lesson-layout lesson-skeleton-item'}>
      {props.header && (
        <div
          className={'lesson-header-content'}
          dangerouslySetInnerHTML={{ __html: props.header }}
        />
      )}

      <div className={'lesson-content'}>
        {props.singleMode ? (
          <>
            <SectionSkeleton
              questionNumber={props.sections[0]}
              singleQuestionMode={props.singleQuestionMode}
            />

            <SectionToolbarSkeleton stepByStep={props.stepByStep} />
          </>
        ) : (
          <>
            {props.sections.map((sec) => {
              return (
                <SectionSkeleton
                  key={sec}
                  questionNumber={sec}
                  singleQuestionMode={props.singleQuestionMode}
                />
              );
            })}
          </>
        )}
      </div>

      {props.footer && (
        <div
          className={'lesson-footer-content'}
          dangerouslySetInnerHTML={{ __html: props.footer }}
        />
      )}
    </LessonSkeletonStyle>
  );
};

const LessonSkeletonStyle = styled.div`
  border: 1px solid #ccc;
  background: #fff;

  padding: var(--cms-padding-page, 1.5em 2em 1.5em 2.5em);
  font-size: 14px;

  .lesson-content {
    padding: var(--cms-padding-content, 0em);

    .section-skeleton-item {
      background: #ccc;
      border: 4px solid #ccc;

      &:not(:first-child) {
        margin-top: var(--cms-padding-section, 1.5em);
      }
    }
  }
`;
