import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import React, { useState } from 'react';
import styled from 'styled-components';
import { StudentLessonActionProps } from '@modules/product/container/student/StudentLessonGroup';
import { useTranslation } from 'react-i18next';
import { StartExerciseContainer } from '@modules/product/container/student/StartExerciseContainer';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonItemStudent = (props: { item: LessonRes }) => {
  const { t } = useTranslation();

  const [action, setAction] = useState<StudentLessonActionProps | null>(null);

  const handleOnClickLesson = () => {
    setAction({
      lesson: props.item,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnClose = () => {
    setAction(null);
  };

  return (
    <>
      <LessonItemStyle className={`lesson-item`}>
        <div className={'lesson-info'}>
          <ThumbnailsImg
            type={'icon'}
            src={props.item.thumbnails}
            ratio={ImageScale.lesson.ratio}
            width={300}
            height={300}
          />

          <div className={'lesson-title'}>{props.item.name}</div>
        </div>

        <div className={'lesson-actions'}>
          <div className={'button-group'}>
            <Button
              shape={'round'}
              type={'primary'}
              icon={IconUtils.actions.start_exercise}
              onClick={handleOnClickLesson}
            >
              {t('lesson.actions.practice')}
            </Button>
          </div>
        </div>
      </LessonItemStyle>

      {action && (
        <StartExerciseContainer
          lessonId={action.lesson.lessonId}
          onCancel={handleOnClose}
        />
      )}
    </>
  );
};

const LessonItemStyle = styled.div`
  width: 300px;
  position: relative;

  .lesson-info {
    border: 1px solid #0474bb;

    .lesson-title {
      background: #0474bb;
      color: ${(props) => props.theme.color.white};
    }
  }

  .lesson-info {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    width: 300px;
    height: 300px;
    margin-bottom: 0.5em;
    display: flex;
    overflow: hidden;

    .image-thumbnail-wrapper {
      width: 300px;
      height: 300px;
    }

    .lesson-title {
      padding: 0.25em 0.5em;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-weight: bold;
    }
  }

  .lesson-actions {
    padding: 0.125em 0;

    display: flex;
    justify-content: space-between;
  }
`;
