import React from 'react';
import { PresentationWorkspace } from '@classroom/container/workspace/PresentWorkspace';
import { StringUtils } from '@utils/StringUtils';
import { DynamicContainer } from '@components/Layout/DynamicContainer';
import styled from 'styled-components';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';

export const ClassRoomPresentation = () => {
  const { recording, allowInteract } = useClassRoomInteractContext();

  const className = StringUtils.classes(
    'class-room-presentation',
    recording ? ' recording ' : ' idea ',
    allowInteract ? ' allow-interact ' : ' prevent-interact '
  );

  return (
    <DynamicContainer
      className={'class-room-content-board'}
      setting={{ width: 1600, height: 900, fontSize: 24 }}
    >
      <ClassRoomPresentationStyle className={className}>
        <PresentationWorkspace />
      </ClassRoomPresentationStyle>
    </DynamicContainer>
  );
};

const ClassRoomPresentationStyle = styled.div`
  position: relative;
  min-height: 100%;
  border: 2px solid #333;

  &.recording {
    border: 2px solid ${(props) => props.theme.app.primary};
  }

  &.prevent-interact {
    .view-lesson-content-container,
    .annotation-workspace {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }

  .annotation-workspace {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
