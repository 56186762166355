import ButtonGroup from '@components/button/ButtonGroup';
import React, { useEffect, useState } from 'react';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';

import { IconUtils } from '@utils/IconUtils';
import { useTranslation } from 'react-i18next';
import LessonButton from '../../components/buttons/style';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';

import styled from 'styled-components';
import { useSelector } from '@app/redux/hook';

export const ComposeNavigationToolbar = () => {
  const { t } = useTranslation();

  const service = useLessonServiceContext();

  const { dispatchAction } = useLessonEditableContext();

  const { newComponents, newAnswers, scoring } = useSelector(
    (state) => state.composeResource
  );

  const [previousId, setPreviousId] = useState(-1);
  const [nextId, setNextId] = useState(-1);

  useEffect(() => {
    const indexOf = service.resources.findIndex((res) => {
      return res.resourceId === service.resourceId;
    });

    setPreviousId(indexOf > 0 ? service.resources[indexOf - 1].resourceId : -1);
    setNextId(
      indexOf < service.resources.length - 1
        ? service.resources[indexOf + 1].resourceId
        : -1
    );
  }, [service.resourceId, service.resources]);

  const goPreviousResource = () => {
    goToQuestion(previousId);
  };

  const goNextResource = () => {
    goToQuestion(nextId);
  };

  const goToQuestion = (resourceId: number) => {
    service.onCreateOrUpdate(
      service.resourceId,
      newComponents,
      newAnswers,
      scoring,
      () => {
        dispatchAction(LessonEditableActionEnum.view_resource, resourceId);
      }
    );
  };

  return (
    <div className={'compose-navigation-toolbar'}>
      <ButtonGroup type={'space-between'}>
        <LessonButton
          type={'primary'}
          disabled={previousId < 0}
          onClick={goPreviousResource}
          shape={'round'}
          loading={service.loading}
        >
          {IconUtils.actions.previous}

          {t('button.pagination.previous')}
        </LessonButton>

        <QuestionNavStyle className={'question-navigation-toolbar'}>
          {service.resources.map((res, index) => {
            return (
              <span
                key={res.resourceId + '-' + index}
                onClick={() => goToQuestion(res.resourceId)}
                className={`resource-icon resource-${res.resourceId}-id ${
                  res.resourceId === service.resourceId ? 'active' : ''
                }`}
              >
                {index + 1}
              </span>
            );
          })}
        </QuestionNavStyle>

        <LessonButton
          disabled={nextId < 0}
          type={'primary'}
          shape={'round'}
          onClick={goNextResource}
        >
          {t('button.pagination.next')}
          {IconUtils.actions.next}
        </LessonButton>
      </ButtonGroup>
    </div>
  );
};

const QuestionNavStyle = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 12px;
  margin-left: 24px;
  margin-right: 24px;

  .resource-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: #ccc;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;

    cursor: pointer;
    font-size: 12px;

    &.active {
      background: ${(props) => props.theme.app.primary};
      color: ${(props) => props.theme.color.white};
    }
  }
`;
