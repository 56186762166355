import React, { useEffect } from 'react';
import { Button, notification } from 'antd';
import { SessionAPI } from '@services/private/SessionAPI';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ButtonGroup from '@components/button/ButtonGroup';
import { SessionRes } from '@services/model/session';

export default function RemoveSessionForm(props: {
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean, needReload?: boolean) => void;
  onActive?: (title: string) => void;
}) {
  const { t } = useTranslation();

  const [removeSession, { isLoading }] =
    SessionAPI.endpoints.removeSession.useMutation({});

  useEffect(() => {
    if (props.onActive) {
      props.onActive(t('session.actions.cancel') as string);
    }
  }, []);

  const handleRemoveSession = () => {
    removeSession(props.sessionData.sessionId)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('session.warning.cancel_success'),
          description: t('session.warning.cancel_success_message'),
          placement: 'bottomRight',
        });

        props.warningResult(true, true);
      })
      .catch(() => {
        notification.error({
          message: t('session.warning.cancel_error'),
          description: t('session.warning.cancel_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <RemoveSessionFormStyle className={'info-group'}>
      <p>{t('session.warning.cancel')}</p>

      <ButtonGroup type={'center'}>
        <Button
          type={'primary'}
          shape={'round'}
          danger
          loading={isLoading}
          onClick={handleRemoveSession}
        >
          {t('session.actions.cancel')}
        </Button>
      </ButtonGroup>
    </RemoveSessionFormStyle>
  );
}

const RemoveSessionFormStyle = styled.div`
  marginbottom: '2em';
`;
