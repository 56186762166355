import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import ManageLessonPage from '@modules/product/pages/ManageLessonPage';
import { PageHeader } from '@app/header/PageHeader';
import { LessonTypeEnum } from '@modules/admin/service/model';

const ManageLessons = () => {
  const { t } = useTranslation();
  return (
    <ManagementLayout className={'manage-lesson-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.my_lesson')}
        description={t('lesson.my_lesson')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        <ManageLessonPage types={LessonTypeEnum.lesson} />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        <ManageLessonPage types={LessonTypeEnum.lesson} />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <ManageLessonPage types={LessonTypeEnum.template} />
      </Authorization>
    </ManagementLayout>
  );
};

export default ManageLessons;
