import { createContext, useContext } from 'react';
import {
  ExerciseRes,
  ExerciseScoreOverallRes,
} from '@modules/assignments/service/exercise_model';

export const ExerciseServiceContext = createContext({
  isLoading: false,
  params: -1,

  lessonId: -1,
  exerciseId: -1,

  viewStatus: (callBack: (res: ExerciseRes) => void) => {
    console.log(callBack);
  },

  onSave: (callBack: (res?: ExerciseRes) => void) => {
    console.log(callBack);
  },

  onSubmit: () => {},

  onGrading: (callBack: (res: ExerciseRes) => void) => {
    console.log(callBack);
  },

  viewScores: (callBack: (data: ExerciseScoreOverallRes) => void) => {
    console.log(callBack);
  },
});

export const useExerciseServiceContext = () => {
  const context = useContext(ExerciseServiceContext);

  if (!context) {
    throw new Error('You must wrap container by ExerciseServiceContext');
  }
  return context;
};
