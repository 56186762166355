import React from 'react';
import styled from 'styled-components';
import { Divider, Space } from 'antd';
import HeaderNotificationMenu from '@components/template/app-layout/header/HeaderNotificationMenu';
import HeaderAccountMenu from './HeaderAccountMenu';
import HeaderMessageMenu from '@components/template/app-layout/header/HeaderMessageMenu';
import SwitchLanguage from '@components/header/SwitchLanguage';
import HeaderSwitchChildrenMenu from '@components/template/app-layout/header/HeaderSwitchChildrenMenu';
import { SelectTeacherGroup } from '@components/header/SelectTeacherGroup';

const HeaderNavigation = () => {
  return (
    <HeaderNavigationStyle size={[10, 10]} wrap align={'center'}>
      <SelectTeacherGroup />
      <SwitchLanguage />
      <HeaderMessageMenu />
      <HeaderNotificationMenu />
      <Divider type={'vertical'} />
      <HeaderSwitchChildrenMenu />
      <HeaderAccountMenu />
    </HeaderNavigationStyle>
  );
};

export default HeaderNavigation;

const HeaderNavigationStyle = styled(Space)`
  .ant-divider.ant-divider-vertical {
    height: 30px;
    margin: 0px 10px;
    border-left-width: 2px;
  }
`;
