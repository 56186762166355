import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { DragNDropCompContextProvider } from './DragNDropCompContext';
import DragNDropDragEvent from './DragNDropDragEvent';

export interface DragNDropItemCompProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
  objectBank?: boolean;
  dropItem?: DragNDropItemCompProps;
}

export interface DragNDropSettingProps extends CompInteractSettingProps {
  layout: 'horizontal' | 'vertical' | 'table';
  multipleDrag: boolean;
  multipleDrop: boolean;
}

export interface DragNDropCompProps extends CompProps {
  type: CompTypeEnum.DRAG_N_DROP;
  configuration: {
    sourceItems: DragNDropItemCompProps[];
    targetItems: DragNDropItemCompProps[];
  };
  setting: DragNDropSettingProps;
}

export interface DragNDropAnsProps extends CompAnswerProps {
  answer: string[];
}

export const generatorDragNDropAns = (drop: string, drag: string) => {
  return `${drop}:${drag}`;
};

export const getDragNDropAns = (answerPart: string) => {
  return {
    drop: answerPart.split(':')[0],
    drag: answerPart.split(':')[1],
  };
};

export const DragNDropComp = (props: {
  disabled: boolean;
  item: DragNDropCompProps;
  answer: DragNDropAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const getComponentClassName = () => {
    const className: string[] = [
      'comp-dnd',
      props.item.setting.layout + '-layout',
    ];

    if (props.item.setting.multipleDrag) {
      className.push('multiple-drag');
    } else {
      className.push('single-drag');
    }

    if (props.item.setting.multipleDrop) {
      className.push('multiple-drop');
    } else {
      className.push('single-drop');
    }

    return className.join(' ');
  };

  return (
    <DragNDropCompContextProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <DragNDropCompStyle
        className={
          `${props.item.setting?.className} ` + getComponentClassName()
        }
      >
        <DragNDropDragEvent />

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </DragNDropCompStyle>
    </DragNDropCompContextProvider>
  );
};

export const DragNDropCompStyle = styled.div`
  &:hover {
    .component-grading-background {
      opacity: 0.6;
    }
  }

  .dnd-draggable-items {
    position: relative;
    text-align: center;
    margin-bottom: var(--cms-padding-option, 0.5em);

    .draggable-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: -0.25em;
      margin-bottom: -0.25em;
    }
  }

  .dnd-droppable-items {
    margin-top: var(--cms-padding-option, 0.5em);

    .droppable-items {
      .droppable-container {
        &.active-drop {
          opacity: 0.5;
        }

        .drag-item {
          color: ${(props) => props.theme.component.primary};

          .show-correct-answer & {
            color: ${(props) => props.theme.component.correct_answer};
          }
        }
      }
    }

    .dnd-drop-content {
      .droppable-container {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .dnd-placeholder {
        color: ${(props) => props.theme.component.disabled};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 80%;
      }

      .draggable-item {
        border: 1px solid ${(props) => props.theme.component.primary};
        color: ${(props) => props.theme.component.primary};

        .show-correct-answer & {
          border: 1px solid ${(props) => props.theme.component.correct_answer};
          color: ${(props) => props.theme.component.correct_answer};
        }
      }
    }
  }

  .draggable-item {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${(props) => props.theme.component.primary};
    border-radius: 0.25em;
    padding: 0.125em 0.5em;
    margin: 0.25em 0.5em;
    color: ${(props) => props.theme.component.primary};

    &.disabled-item {
      color: ${(props) => props.theme.component.disabled};
      border: 1px solid ${(props) => props.theme.component.disabled};
    }

    &.disabled-draggable {
      color: ${(props) => props.theme.component.disabled};
      border: 1px solid ${(props) => props.theme.component.disabled};
    }
  }

  &.horizontal-layout {
    .dnd-droppable-items {
      display: flex;
      flex-direction: row;
      gap: 0.75em;

      .dnd-drop-item {
        flex-grow: 1;

        .dnd-drop-header {
          margin-bottom: 0.25em;
        }

        .dnd-drop-content {
          .droppable-container {
            flex: 1;
            padding: 0.25em 0.25em;
            border-radius: 0.25em;
            min-height: 5em;
            border: 2px dashed #ccc;
            text-align: center;
          }
        }
      }
    }
  }

  &.vertical-layout {
    .dnd-droppable-items {
      display: flex;
      flex-direction: column;

      .dnd-drop-item {
        &:not(:first-child) {
          margin-top: var(--cms-padding-option, 0.5em);
        }

        .dnd-drop-header {
          margin-bottom: 0.25em;
        }

        .droppable-container {
          flex: 1;
          padding: 0.25em 0.25em;
          border-radius: 0.25em;
          min-height: 3em;
          border: 2px dashed #ccc;
        }
      }
    }
  }

  &.two-columns-layout {
    .dnd-droppable-items {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1em;

      .dnd-drop-item {
        width: 50%;
        max-width: calc(50% - 0.5em);
        border: 2px solid #ccc;
        display: flex;
        flex-direction: column;
        padding: 0.25em 0.5em;

        .dnd-drop-header {
          margin-bottom: 0.25em;
        }

        .dnd-drop-content {
          .droppable-container {
            flex: 1;
            padding: 0.25em 0.25em;
            border-radius: 0.25em;
            min-height: 3em;
            border: 2px dashed #ccc;
          }
        }
      }
    }
  }

  &.table-layout {
    &.single-drop {
      .droppable-items {
        .drop-item {
          text-align: center;
        }
      }
    }

    .dnd-droppable-items {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      .dnd-drop-item {
        width: 33.33%;
        border: 2px solid #ccc;
        display: flex;
        flex-direction: column;
        padding: 0.25em 0.5em;

        .dnd-drop-header {
          margin-bottom: 0.25em;
        }

        .dnd-drop-content {
          .droppable-container {
            flex: 1;
            padding: 0.25em 0.25em;
            border-radius: 0.25em;
            min-height: 3em;
            border: 2px dashed #ccc;
          }
        }
      }
    }
  }

  .dnd-droppable-inline-group {
    &:not(:first-child) {
      margin-top: 0.5em;
    }

    > * {
      margin-bottom: 0.25em;
    }
  }
`;
