import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cookie from 'js-cookie';
import { DateAndTimeFormat } from '@utils/DateAndTimeUtils';

const one_minutes_milliseconds = 60 * 1000;
const five_minutes_milliseconds = 5 * one_minutes_milliseconds;

export interface SessionExpiredProps {
  warning: boolean;
  timeout: boolean;

  remainTime: number;
  expiredTime: number;

  expiredDate: string;
}

const initialState: SessionExpiredProps = {
  warning: false,
  timeout: false,

  expiredTime: 60 * 60 * 1000,
  remainTime: 60 * 60 * 1000,

  expiredDate: cookie.get('sessionExpiredTime') ?? '',
};

const sessionExpiredSlice = createSlice({
  name: 'sessionExpired',
  initialState,
  reducers: {
    refreshExpiredData: (state, action) => {
      const remainTime = action.payload;
      const timeToExpired = dayjs().add(remainTime, 'milliseconds');

      state.warning = false;
      state.timeout = false;

      state.expiredTime = remainTime;
      state.remainTime = remainTime;

      state.expiredDate = timeToExpired.format(DateAndTimeFormat.DATE_N_TIME);
      cookie.set('sessionExpiredTime', state.expiredDate);
    },

    warningExpired: (state) => {
      const expiredDate = cookie.get('sessionExpiredTime');

      if (expiredDate) {
        const _expiredTime = dayjs(expiredDate, DateAndTimeFormat.DATE_N_TIME)
          .toDate()
          .getTime();

        const remainTime = _expiredTime - dayjs().toDate().getTime();

        state.remainTime = remainTime;

        if (remainTime <= 0) {
          state.warning = false;
          state.timeout = true;
        } else if (remainTime < five_minutes_milliseconds) {
          state.warning = true;
          state.timeout = false;
        } else {
          state.warning = false;
          state.timeout = false;
        }
      }
    },
  },
});

const { actions, reducer } = sessionExpiredSlice;

export const { refreshExpiredData, warningExpired } = actions;

export default reducer;
