import React from 'react';
import { notification, Button, Popover, List } from 'antd';

import { useTranslation } from 'react-i18next';

import { changeToPersonateMode, login } from '@app/redux/slices/profileSlice';
import useConfirmModal from '@components/modal/ConfirmModal';
import { TeamOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { StudentInfoStatic } from '@components/info/StudentInfo';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import { useDispatch, useSelector } from '@app/redux/hook';
import { SwitchStudentReq } from '@modules/authorization/services/model';
import { UserInfoRes } from '@modules/users/services/model';

const HeaderSwitchChildrenMenu = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();
  const dispatch = useDispatch();

  const [switchToStudent] = AuthAPI.endpoints.switchToStudent.useMutation({});
  const [exitPersonateMode] = AuthAPI.endpoints.exitPersonateMode.useMutation(
    {}
  );
  const { personateSecretCode } = useSelector((state) => state.profile);

  const { isReady, students } = useSelector((state) => state.userInformation);

  const handleSwitchStudentAccount = (st: UserInfoRes) => {
    confirm(
      'warning',
      t('header.confirm'),
      'Do you want to view student data?',
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          const params: SwitchStudentReq = {
            refresh_token: localStorage.getItem('refreshToken') ?? '',
            studentId: st.userId,
          };

          switchToStudent(params)
            .unwrap()
            .then((data) => {
              notification.success({
                message: t('switch_account.warning.success'),
                description: t('switch_account.warning.success_message'),
                placement: 'bottomRight',
              });

              const payload = {
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                uuid: data.uuid,
                roles: data.roles,
                schoolId: data.schoolId ?? null,
              };

              dispatch(changeToPersonateMode(data.originalId!));
              dispatch(login(payload));
              dispatch(refreshExpiredData(data.expires_in));

              setTimeout(() => {
                window.location.reload();
              }, 0);
            })
            .catch(() => {
              notification.error({
                message: t('switch_account.warning.error'),
                description: t('switch_account.warning.error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const handleSwitchParentAccount = () => {
    const params = {
      secretCode: personateSecretCode,
    };

    exitPersonateMode(params)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('switch_account.warning.success'),
          description: t('switch_account.warning.success_message'),
          placement: 'bottomRight',
        });

        const payload = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          uuid: data.uuid,
          roles: data.roles,
          schoolId: data.schoolId ?? null,
        };

        dispatch(changeToPersonateMode(null));
        dispatch(login(payload));
        dispatch(refreshExpiredData(data.expires_in));

        setTimeout(() => {
          window.location.reload();
        }, 0);
      })
      .catch(() => {
        notification.error({
          message: t('switch_account.warning.error'),
          description: t('switch_account.warning.error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const content = (
    <List
      dataSource={students}
      renderItem={(item) => (
        <List.Item>
          <StudentInfoStatic key={item.userId} selected={true} item={item} />

          <Button
            type={'primary'}
            size={'small'}
            shape={'round'}
            onClick={() => handleSwitchStudentAccount(item)}
            style={{ marginLeft: '1em' }}
          >
            View
          </Button>
        </List.Item>
      )}
    />
  );

  return (
    <>
      {isReady && students && students.length > 0 && (
        <Popover
          placement="bottomRight"
          title={<b>Switch to Children account:</b>}
          content={content}
          trigger={['click']}
          overlayClassName={'user-info-popup'}
        >
          <Button
            type={'default'}
            shape="circle"
            size={'large'}
            style={{
              background: '#FFF',
            }}
          >
            <b>
              <TeamOutlined />
            </b>
          </Button>
        </Popover>
      )}

      {personateSecretCode && personateSecretCode !== '' && (
        <Button
          danger
          type={'primary'}
          shape="round"
          size={'large'}
          onClick={handleSwitchParentAccount}
        >
          <UserSwitchOutlined /> Return my account
        </Button>
      )}
    </>
  );
};

export default HeaderSwitchChildrenMenu;
