import React, { useEffect } from 'react';
import { message } from 'antd';

import { resetComposeLesson } from '@app/redux/slices/composeLesson';
import { useTranslation } from 'react-i18next';
import { LessonEditableProvider } from '@cms/context/LessonEditableProvider';
import { EditModeEnum } from '../ComposeLessonProps';
import { ComposeWysiTemplate } from '@cms/lesson-template/compose/lesson/ComposeWysiTemplate';
import { LessonServiceProvider } from '@cms/context/service/LessonServiceProvider';
import { ComposeLessonActionHandler } from '@cms/context/ComposeLessonActionHandler';
import { useDispatch, useSelector } from '@app/redux/hook';

const ComposeSimpleLessonPage = (props: {
  from: string;
  lessonId?: number;
  resourceId?: number;
  lessonExampleId?: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { warningMsg } = useSelector((state) => state.composeLesson);

  useEffect(() => {
    return () => {
      dispatch(resetComposeLesson());
    };
  }, [props.lessonId]);

  useEffect(() => {
    if (warningMsg && warningMsg.timestamp && warningMsg.success != null) {
      warningMessage(warningMsg.success);
    }
  }, [warningMsg]);

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      message.info({
        content: t('resource.warning.update_success'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    } else {
      message.error({
        content: t('resource.warning.update_error'),
        className: 'bottom-center-message',
        duration: 2,
        style: {
          position: 'fixed',
          bottom: 10,
          right: 10,
        },
      });
    }
  };

  return (
    <LessonServiceProvider
      type={props.lessonId != null && props.lessonId > 0 ? 'db' : 'session'}
      lessonId={props.lessonId ?? -1}
      lessonExampleId={props.lessonExampleId ?? -1}
    >
      <LessonEditableProvider
        from={props.from}
        editMode={EditModeEnum.WYSI}
        lessonId={props.lessonId ?? -1}
        resourceId={props.resourceId}
        productId={-1}
        productTocId={-1}
        lessonExampleId={props.lessonExampleId}
      >
        <ComposeLessonActionHandler />
        <ComposeWysiTemplate />
      </LessonEditableProvider>
    </LessonServiceProvider>
  );
};

export default ComposeSimpleLessonPage;
