import { ExerciseReportRes } from '@services/reports/LessonReport';

import React, { useEffect, useState } from 'react';
import { ReportUtils } from '@modules/reports/ReportUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { H2 } from '@components/typography';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import { SelectExercises } from '@modules/reports/components/SelectExercises';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import RadarChart from '@modules/reports/charts/radar/RadarChart';
import ColorUtils from '@utils/ColorUtils';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';

const LessonStandardDistributed = (props: { reports: ExerciseReportRes[] }) => {
  const [exercises, setExercises] = useState<ExerciseRes[]>([]);
  const [exerciseIds, setExerciseIds] = useState<number[]>([]);

  const [exerciseCodes, setExerciseCode] = useState<string[]>([]);
  const [standardCodes, setStandardCode] = useState<string[]>([]);
  const [standardReports, setStandardReports] = useState<any[]>([]);

  useEffect(() => {
    const exercises = props.reports.map((rc, index) => {
      return rc.exercise;
    });

    const standardCodes = props.reports[0].standards.map((st) => {
      return st.standard.code;
    });
    setExercises(exercises);
    setStandardCode(standardCodes);
  }, [props.reports]);

  useEffect(() => {
    // step 1. get exercise code....
    const exerciseCodes: string[] = [];
    props.reports.forEach((ex, index) => {
      if (
        exerciseIds.length === 0 ||
        exerciseIds.includes(ex.exercise.exerciseId)
      ) {
        const exName = DateAndTimeUtils.format(
          ex.exercise.createdDate,
          DateAndTimeFormat.SHORT
        );

        exerciseCodes.push(exName);
      }
    });

    const standardReports: any[] = [];
    standardCodes.forEach((st) => {
      const record: Record<string, any> = {};
      record['group'] = st;

      props.reports.forEach((ex, index) => {
        if (
          exerciseIds.length === 0 ||
          exerciseIds.includes(ex.exercise.exerciseId)
        ) {
          ex.standards.forEach((std) => {
            if (std.standard.code === st) {
              const exName = DateAndTimeUtils.format(
                ex.exercise.createdDate,
                DateAndTimeFormat.SHORT
              );
              record[exName] = ReportUtils.percent(std.score!, std.maxScore!);
            }
          });
        }
      });

      standardReports.push(record);
    });
    setExerciseCode(exerciseCodes);
    setStandardReports(standardReports);
  }, [props.reports, standardCodes, JSON.stringify(exerciseIds.sort())]);

  const handleOnChange = (ids: number[]) => {
    setExerciseIds(ids);
  };

  const handleGetColor = (params: { index: number; key: string }) => {
    const color = ColorUtils.getDefaultColor(params.index);
    return color.background;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H2>
        Lesson Standard Distributed Report{' '}
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Distributed Report</h3>}
          content={
            <>
              <p>
                Biểu đồ này thể hiện sự phân bố các skill set theo thời gian
              </p>
            </>
          }
        />
      </H2>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={6}>
          <SelectExercises
            type={'exercise'}
            exercises={exercises}
            value={exerciseIds}
            onChange={handleOnChange}
          />
        </Col>

        <Col span={18}>
          <RadarChart
            className={'lesson-standard-distributed-chart'}
            size={500}
            keys={exerciseCodes}
            content={standardReports}
            getColor={handleGetColor}
          />
        </Col>
      </Row>
    </LessonReportChartStyle>
  );
};

export default LessonStandardDistributed;
