import { CompAnswerProps } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import {
  ContentActionEnum,
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import OptionEditable from '@cms/editable/OptionEditable';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import styled from 'styled-components';
import React from 'react';
import {
  FibNumberOptionProps,
  FibNumberProps,
} from '@cms/comps/math/fib/FibNumberComp';
import { useFibNumberCompContext } from '@cms/comps/math/fib/FibNumberContext';

const FibNumberCompEditable = (props: {
  item: FibNumberProps;
  onChange: (newAns: CompAnswerProps) => void;
  onClose: () => void;
}) => {
  const {
    modifiedTime,
    answers,
    targetItems,
    updateOptions,
    updateAnswers,
    updateComponent,
  } = useFibNumberCompContext();

  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: FibNumberOptionProps
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    const newTargetItems = OptionGroupUtils.updateOption(
      index,
      newOption,
      targetItems
    );

    updateOptions(newTargetItems);
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: FibNumberOptionProps
  ) => {
    let newOptions: FibNumberOptionProps[] = [];
    let newAnswers: string[] = [];

    if (operation === ContentOperationEnum.ADD) {
      newOptions = OptionGroupUtils.insertOption(
        index,
        option,
        targetItems,
        OptionTypeEnum.NUMBER
      );

      newAnswers = handleInsertGroup(index, answers);
    } else {
      newOptions = OptionGroupUtils.removeOption(
        index,
        targetItems,
        OptionTypeEnum.NUMBER
      );

      newAnswers = handleRemoveGroup(index + 1, answers);
    }

    // then update data...
    updateOptions(newOptions);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: FibNumberOptionProps
  ) => {
    const newOption = { label: option.label, content: { ...data } };
    const newOptions = OptionGroupUtils.updateOption(
      index,
      newOption,
      targetItems
    );
    updateOptions(newOptions);
  };

  const handleUpdateSetting = () => {
    updateComponent();
    props.onClose();
  };

  return (
    <>
      <FibNumberEditableStyle className={'fib-number-group-editable'}>
        {targetItems &&
          targetItems.map((drop, index) => {
            return (
              <div
                className={`fib-number-group`}
                key={
                  drop.label +
                  '-' +
                  index +
                  '-' +
                  drop.content.id +
                  '_' +
                  modifiedTime
                }
              >
                <OptionEditable
                  disabled={{
                    addOption: targetItems.length === 15,
                    removeOption: targetItems.length <= 1,
                  }}
                  contentTypes={[ContentActionEnum.CHANGE_TO_TEXT]}
                  type={drop.content.type}
                  onChangeType={(newType) =>
                    updateOptionType(newType, index, drop)
                  }
                  onOperation={(operation) =>
                    updateGroupOptions(operation, index, drop)
                  }
                >
                  <ContentViewerEditable
                    contentData={drop.content}
                    onChange={(data) => updateOptionData(data, index, drop)}
                  />
                </OptionEditable>
              </div>
            );
          })}
      </FibNumberEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={handleUpdateSetting}
      />

      <AutoUpdateToolbar
        lastChange={modifiedTime}
        onTimeout={updateComponent}
      />
    </>
  );
};

export default FibNumberCompEditable;

const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseFibWordAnswer(ans);

    if (ansData.index > index + 1) {
      return generateFibWordAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseFibWordAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseFibWordAnswer(ans);

      if (ansData.index > index) {
        return generateFibWordAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

const parseFibWordAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    optionLabel: answerPart,
  };
};

export const generateFibWordAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};

const FibNumberEditableStyle = styled.div`
  position: relative;
  padding: 0.5em;

  .fib-number-group {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
`;
