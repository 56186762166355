import {
  LongOperationLayoutEnum,
  OperationExpProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { LongOperationInput } from '../LongOperationInput';
import { useLongOperationTableContext } from '@cms/comps/math/operation/table/LongOperationTableContext';
import { getPlaceValueHeader, PlaceValueHeader } from './PlaceValueHeader';
import { PlaceValueInput } from '../PlaceValueInput';

export const LongOperationTable = (props: {
  layout: LongOperationLayoutEnum;
  disabled: boolean;
  answer: string[];
  expression: OperationExpProps[];
  onChange: (ans: OperationExpProps[]) => void;
}) => {
  const { focusNext } = useLongOperationTableContext();
  const [answerExpression, setAnswerExpression] = useState<{
    value: OperationExpProps[];
    triggerChange: boolean;
  }>({ value: [], triggerChange: false });

  useEffect(() => {
    if (answerExpression && answerExpression.triggerChange) {
      props.onChange(answerExpression.value);
    }
  }, [answerExpression]);

  useEffect(() => {
    if (props.answer && props.answer.length > 0) {
      const answers: OperationExpProps[] = props.answer.map((ans) => {
        const rows = ans.split('');
        return {
          type: 'empty',
          exp: rows,
        };
      });
      setAnswerExpression({
        value: answers,
        triggerChange: false,
      });
    } else {
      const answers = [...props.expression];
      setAnswerExpression({
        value: answers,
        triggerChange: false,
      });
    }
  }, [props.answer]);

  const updateOperationValue = (
    rowIndex: number,
    columnIndex: number,
    value: string
  ) => {
    setAnswerExpression((prev) => {
      const newValues = produce(prev.value, (draft) => {
        draft[rowIndex].exp[columnIndex] =
          value && value.trim() !== ''
            ? value
            : OperationUtils.VARIABLE_CHARACTER;
      });

      return {
        value: newValues,
        triggerChange: true,
      };
    });

    focusNext(rowIndex, columnIndex);
  };

  const rowType = useMemo(() => {
    return props.layout === LongOperationLayoutEnum.place_value ||
      props.layout === LongOperationLayoutEnum.place_value_block
      ? 'table'
      : 'block';
  }, [props.layout]);

  return (
    <LongOperationTableWrapper className={`long-operation-table-wrapper`}>
      <LongOperationTableType
        rowType={rowType}
        className={`long-operation-table operation-table-${props.layout}`}
      >
        {props.expression.map((row, rowIndex) => {
          return (
            <React.Fragment
              key={JSON.stringify(row) + '_' + rowIndex + '_' + props.layout}
            >
              {props.layout === LongOperationLayoutEnum.vertical &&
                (row.type === 'sum' ||
                  row.type === 'difference' ||
                  row.type === 'product') && (
                  <div className={'long-division-row'} />
                )}

              <LongOperationRow
                rowType={rowType}
                layout={props.layout}
                rowIndex={rowIndex}
                disabled={props.disabled}
                expression={row}
                answer={answerExpression.value[rowIndex]}
                onChange={(colIndex, val) =>
                  updateOperationValue(rowIndex, colIndex, val)
                }
              />

              {props.layout === LongOperationLayoutEnum.vertical &&
                row.type === 'multiplicand' && (
                  <div className={'long-division-row'} />
                )}
            </React.Fragment>
          );
        })}
      </LongOperationTableType>
    </LongOperationTableWrapper>
  );
};

const LongOperationRow = (props: {
  rowType: 'table' | 'block';
  layout: LongOperationLayoutEnum;
  rowIndex: number;
  disabled: boolean;
  expression: OperationExpProps;
  answer: OperationExpProps;
  onChange: (columnIndex: number, value: string) => void;
}) => {
  const handleOnInputChange = (value: string, columnIndex: number) => {
    props.onChange(columnIndex, value);
  };

  return (
    <LongOperationRowType type={props.expression.type} rowType={props.rowType}>
      {props.expression.exp.map((exp, colIndex) => {
        const answer =
          props.answer != null && props.answer.exp != null
            ? props.answer.exp[colIndex]
            : exp;

        if (exp === OperationUtils.HIDDEN_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-hidden'}
              key={exp + '-' + colIndex}
            >
              <span>&nbsp;</span>
            </LongOperationColumnType>
          );
        } else if (
          [
            OperationUtils.ADD_OPERATION,
            OperationUtils.SUB_OPERATION,
            OperationUtils.MUL_OPERATION,
            OperationUtils.DIV_OPERATION,
            OperationUtils.DIV_REMAIN_OPERATION,
            OperationUtils.EQUAL_OPERATION,
          ].includes(exp)
        ) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={`long-operation-field long-operation-operator operation-${OperationUtils.getOperationTypeClassName(
                exp
              )}`}
              key={exp + '-' + colIndex}
            >
              <span>
                {exp === OperationUtils.ADD_OPERATION && <span>+</span>}
                {exp === OperationUtils.SUB_OPERATION && <span>&minus;</span>}
                {exp === OperationUtils.MUL_OPERATION && <span>&times;</span>}
                {exp === OperationUtils.DIV_OPERATION && (
                  <>
                    {props.layout === LongOperationLayoutEnum.vertical ? (
                      <span>✓</span>
                    ) : (
                      <span>÷</span>
                    )}
                  </>
                )}
                {exp === OperationUtils.DIV_REMAIN_OPERATION && (
                  <span>{exp}</span>
                )}
                {exp === OperationUtils.EQUAL_OPERATION && <span>{exp}</span>}
              </span>
            </LongOperationColumnType>
          );
        } else if (exp === OperationUtils.PLACE_VALUE_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field place-value-char'}
              key={exp + '-' + colIndex}
            >
              <span>
                <PlaceValueHeader
                  exp={props.expression.exp}
                  index={colIndex + 1}
                />
              </span>
            </LongOperationColumnType>
          );
        } else if (exp === OperationUtils.VARIABLE_CHARACTER) {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-input'}
              key={exp + '-' + colIndex}
            >
              {props.layout === LongOperationLayoutEnum.place_value_block ? (
                <PlaceValueInput
                  type={getPlaceValueHeader(props.expression.exp, colIndex + 1)}
                  disabled={props.disabled}
                  value={answer}
                  onChange={(val) => handleOnInputChange(val, colIndex)}
                />
              ) : (
                <span>
                  <LongOperationInput
                    rowIndex={props.rowIndex}
                    columnIndex={colIndex}
                    disabled={props.disabled}
                    value={answer}
                    onChange={(val) => handleOnInputChange(val, colIndex)}
                  />
                </span>
              )}
            </LongOperationColumnType>
          );
        } else {
          return (
            <LongOperationColumnType
              rowType={props.rowType}
              className={'long-operation-field long-operation-label'}
              key={exp + '-' + colIndex}
            >
              <span>
                <span>{answer}</span>
              </span>
            </LongOperationColumnType>
          );
        }
      })}
    </LongOperationRowType>
  );
};

export const LongOperationTableType = (props: {
  rowType: 'table' | 'block';
  className: string;
  children: ReactNode;
}) => {
  if (props.rowType === 'table') {
    return (
      <LongOperationTableStyle className={props.className}>
        <tbody>{props.children}</tbody>
      </LongOperationTableStyle>
    );
  } else {
    return (
      <LongOperationBlockStyle className={props.className}>
        {props.children}
      </LongOperationBlockStyle>
    );
  }
};

export const LongOperationRowType = (props: {
  rowType: 'table' | 'block';
  type: string;
  children: ReactNode;
}) => {
  if (props.rowType === 'table') {
    return (
      <tr className={`long-operation-row long-operation-type-${props.type}`}>
        {props.children}
      </tr>
    );
  } else {
    return (
      <div className={`long-operation-row long-operation-type-${props.type}`}>
        {props.children}
      </div>
    );
  }
};

export const LongOperationColumnType = (props: {
  rowType: 'table' | 'block';
  className: string;
  children: ReactNode;
}) => {
  if (props.rowType === 'table') {
    return <td className={props.className}>{props.children}</td>;
  } else {
    return <span className={props.className}>{props.children}</span>;
  }
};

export const LongOperationTableWrapper = styled.div`
  &.long-operation-table-editable {
    .long-operation-row .long-operation-field {
      &.long-operation-label {
        > span {
          &:before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border-radius: 5px;
            background: rgb(240, 240, 240) !important;
            z-index: 1;
          }

          span {
            color: inherit !important;
            z-index: 2;
          }

          .show-correct-answer & {
            &:before {
              color: ${(props) => props.theme.component.correct_answer};
            }
          }
        }
      }

      &.long-operation-input {
        > span {
          span {
            color: ${(props) =>
              props.theme.component.correct_answer} !important;
            font-weight: 600;
          }

          .show-correct-answer & {
            &:before {
              background: ${(props) => props.theme.component.primary_bgr};
            }
          }
        }
      }
    }
  }

  .long-operation-row .long-operation-field {
    > span {
      width: 1.4em;
      height: 1.4em;
      text-align: center;
      cursor: pointer;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &.long-operation-input {
      > span {
        &:before {
          content: '';
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          border-radius: 4px;
          border: 1px solid #ccc;
          background: #fafafa;
          z-index: 1;
        }

        .show-correct-answer & {
          &:before {
            background: ${(props) => props.theme.component.primary_bgr};
          }
        }

        input {
          border: none !important;
          outline: none !important;
          width: 1.4em;
          height: 1.4em;
          text-align: center;
          background: transparent;
          color: ${(props) => props.theme.component.primary};
          font-weight: 600;
          z-index: 2;

          &:focus {
            border: none !important;
            outline: none !important;
          }

          .show-correct-answer & {
            color: ${(props) => props.theme.component.correct_answer};
          }
        }

        span {
          z-index: 2;
        }
      }
    }
  }
`;

export const LongOperationBlockStyle = styled.div`
  display: inline-flex;
  flex-direction: column;

  .long-operation-row {
    display: flex;

    .long-operation-field {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 2px solid transparent;
      position: relative;

      > * {
        z-index: 2;
      }
    }
  }

  &.operation-table-horizontal {
    flex-direction: row;
  }

  &.operation-table-vertical {
    .long-operation-type-quotient,
    .long-operation-type-division-subtract {
      margin-bottom: 4px;

      .long-operation-input,
      .operation-division-remain-operation,
      .long-operation-label {
        span {
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            left: -0.25em;
            right: 0px;
            bottom: -4px;
            border-bottom: 3px solid #ccc;
            transform: translate(0%, 50%);
          }
        }
      }
    }

    .long-operation-type-divisor {
      .operation-division-operation {
        span {
          overflow: hidden;
          position: relative;
          margin-top: -4px;

          > span {
            font-size: 150%;
            position: absolute;
            bottom: 0px;
            left: 75%;
            transform: translate(-50%, 15%) rotate(-12deg);
            color: ${(props) => props.theme.component.disabled};
          }
        }
      }
    }

    .long-division-row {
      margin-top: 0.125em;
      margin-bottom: 0.125em;
      border-bottom: 2px solid #666;
    }
  }
`;

const LongOperationTableStyle = styled.table`
  tr.long-operation-row {
    td.long-operation-field {
      padding: 2px;
      position: relative;
      text-align: center;

      > * {
        z-index: 2;
      }
    }
  }

  .place-value-comp & {
    tr.long-operation-row {
      td.long-operation-field {
        border-left: 1px solid #666;
        border-bottom: 1px solid #666;
      }
    }
  }

  &.operation-table-place-value,
  &.operation-table-place-value-block {
    tr.long-operation-type-place-value {
      font-size: 40%;
      font-weight: bold;

      td.place-value-char {
        background: ${(props) => props.theme.component.primary};
        color: ${(props) => props.theme.color.white};
        padding: 2px 4px;
        min-width: 5ch;

        border-top: 1px solid ${(props) => props.theme.component.primary} !important;
        border-left: 1px solid ${(props) => props.theme.component.primary} !important;
        border-bottom: 1px solid ${(props) => props.theme.component.primary} !important;

        &:last-child {
          border-right: 1px solid ${(props) => props.theme.component.primary} !important;
        }

        span {
          width: auto !important;
          text-transform: uppercase;
        }
      }
    }

    tr.long-operation-row {
      td.long-operation-field {
        padding: 0.125em 0.5em;

        &:not(:first-child) {
          border-top: 1px solid #666;
          border-left: 1px solid #666;
        }

        &:last-child {
          border-right: 1px solid #666;
        }

        &.long-operation-operator {
          padding-left: 0.25em;
          padding-right: 0.25em;

          > span {
            width: auto !important;
          }
        }

        &.long-operation-hidden {
          > span {
            width: auto !important;
          }
        }
      }

      &:last-child {
        td.long-operation-field {
          &:not(:first-child) {
            border-bottom: 1px solid #666;
          }
        }
      }

      &.long-operation-type-sum {
        border-top: 2px solid #666;
      }

      &.long-operation-type-difference {
        border-top: 2px solid #666;
      }
    }
  }
`;
