import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { ReactNode } from 'react';
import { CompMode } from '@cms/ComponentInteface';

export const AnonymousViewMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();
  if (type === CompMode.VIEW) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const SelfCheckExerciseMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();
  if (type === CompMode.SELF_CHECK) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const DoExerciseMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();
  if (type === CompMode.DO_EXERCISE || type === CompMode.DO_ASSIGNMENT) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const GradingExerciseMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();

  if (
    type === CompMode.GRADING_EXERCISE ||
    type === CompMode.GRADING_ASSIGNMENT
  ) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const ReviewExerciseMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();

  if (
    type === CompMode.REVIEW_EXERCISE ||
    type === CompMode.REVIEW_ASSIGNMENT
  ) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const PresentationMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();

  if (type === CompMode.PRESENTATION) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const OnlyExerciseMode = (props: { children: ReactNode }) => {
  const { type } = useViewLessonContext();

  if (
    type === CompMode.DO_EXERCISE ||
    type === CompMode.GRADING_EXERCISE ||
    type === CompMode.REVIEW_EXERCISE
  ) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};
