import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Divider, notification, Row } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import Input from '@components/input/input';
import ContentEditor from '@components/editor/WysyEditor';
import ButtonGroup from '@components/button/ButtonGroup';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ImageScale } from '@utils/ImageScale';
import InputValidation from '@components/input/InputValidation';
import {
  UpdateTeacherProfileReq,
  UserProfileAPI,
  UserProfileRes,
} from '@services/private/UserProfileAPI';
import Card, { CardClassEnum } from '@components/card';
import KeywordInput from '@components/input/KeywordInput';
import { H3 } from '@components/typography';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import styled from 'styled-components';

import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';
import YoutubeInput from '@components/input/YoutubeInput';

export default function PublicTeacherProfileForm(props: {
  onSuccess: () => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();

  const [getUserProfile, { data, isSuccess, error, isError }] =
    UserProfileAPI.endpoints.getUserProfile.useLazyQuery({});

  const [updateMyProfile, { isLoading }] =
    UserProfileAPI.endpoints.updateMyProfile.useMutation({});

  const { avatar, displayName } = useSelector((state) => state.userInformation);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    getUserProfile({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setUserProfileData(data);
      setReady(true);
    } else if (error && isError) {
      formik.setFieldValue('displayName', displayName);
      formik.setFieldValue('avatarUrl', avatar);
    }
  }, [data, error]);

  useEffect(() => {
    if (error && isError) {
      setReady(true);
    }
  }, [error]);

  const setUserProfileData = (data: UserProfileRes) => {
    formik.setFieldValue('avatarUrl', data.avatarUrl);
    formik.setFieldValue('name', data.name);
    formik.setFieldValue('displayName', data.displayName);

    formik.setFieldValue('keyword', data.keyword);
    formik.setFieldValue('description', data.description);
    formik.setFieldValue('introduce', data.introduce || '');
    formik.setFieldValue('shortIntroduce', data.shortIntroduce || '');
    formik.setFieldValue('thumbnailsUrl', data.thumbnailsUrl);
    formik.setFieldValue('youtubeUrl', data.youtubeUrl);

    formik.setFieldValue('facebookAccount', data.facebookAccount);
    formik.setFieldValue('googleAccount', data.googleAccount);
  };

  const handleInstructionEditorChange = (introduce: string) => {
    formik.setFieldValue('introduce', introduce);
  };

  const handleShortInstructionEditorChange = (shortIntroduce: string) => {
    formik.setFieldValue('shortIntroduce', shortIntroduce);
  };

  const handleOnAvatarUploaded = (uploadedFile: string) => {
    formik.setFieldValue('avatarUrl', uploadedFile);
  };

  const handleOnThumbnailUploaded = (uploadedFile: string) => {
    // setThumbnails(uploadedFile);
    formik.setFieldValue('thumbnailsUrl', uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err');
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      displayName: '',
      keyword: '',
      description: '',

      introduce: '',
      shortIntroduce: '',

      avatarUrl: '',
      thumbnailsUrl: '',
      youtubeUrl: '',

      facebookAccount: '',
      googleAccount: '',
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      introduce: yup.string().required(t('validation.required.field')),
      shortIntroduce: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      updateUserProfile(values);
    },
  });

  const updateUserProfile = (request: UpdateTeacherProfileReq) => {
    updateMyProfile(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('profile.warning.update_success'),
          description: t('profile.warning.update_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: t('profile.warning.update_error'),
          description: t('profile.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <UpdateProfileStyle className={'public-profile-container'}>
      <Alert
        message={t('profile.warning.direction')}
        description={<p>{t('profile.warning.direction_teacher')}</p>}
        type="info"
        closable
      />

      <FormikProvider value={formik}>
        <div className={'form-group'}>
          <H3>{t('profile.info')}</H3>

          <Card className={CardClassEnum.rectangle_box}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col flex={ImageScale.avatar.width + 24 + 'px'}>
                <InputGroup label={'Avatar'}>
                  <SingleUpload
                    scale={ImageScale.avatar.scale}
                    accept={'image/jpg, image/jpeg, image/png'}
                    multiple={false}
                    onSuccessFunc={handleOnAvatarUploaded}
                    onErrorFunc={handleOnUploadHasErr}
                  >
                    <IconImage
                      src={formik.values.avatarUrl}
                      width={ImageScale.avatar.width}
                      height={ImageScale.avatar.height}
                    />
                  </SingleUpload>
                </InputGroup>
              </Col>

              <Col flex={'auto'}>
                <Input
                  required
                  name="name"
                  type="text"
                  label={t('label.name')}
                  placeholder={t('label.name')}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                <Input
                  required
                  name="displayName"
                  type="text"
                  label={t('label.display_name')}
                  placeholder={t('label.display_name')}
                  onChange={formik.handleChange}
                  value={formik.values.displayName}
                />
              </Col>
            </Row>
          </Card>
        </div>

        <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
          <H3>{t('profile.introduce')}</H3>

          <Card className={CardClassEnum.rectangle_box}>
            <InputGroup label={t('label.keyword')}>
              <KeywordInput
                placeholder={t('label.enter_keyword')}
                enterButton={t('label.add')}
                size={'large'}
                keywords={formik.values.keyword}
                onKeywordChange={(values) =>
                  formik.setFieldValue('keyword', values.join(','))
                }
              />
            </InputGroup>

            <Input
              name="description"
              type="textarea"
              label={t('label.description')}
              placeholder={t('label.description')}
              onChange={formik.handleChange}
              value={formik.values.description}
              maxLength={512}
              row={4}
            />

            <InputGroup label={t('label.instruction')} required>
              {ready && (
                <ContentEditor
                  initValue={formik.values.introduce}
                  onChange={handleInstructionEditorChange}
                  numberRows={10}
                />
              )}
              <InputValidation name={'introduce'} formik={formik} />
            </InputGroup>

            <InputGroup label={t('label.short_instruction')} required>
              {ready && (
                <ContentEditor
                  initValue={formik.values.shortIntroduce}
                  onChange={handleShortInstructionEditorChange}
                />
              )}
              <InputValidation name={'shortIntroduce'} formik={formik} />
            </InputGroup>
          </Card>

          <Card className={CardClassEnum.rectangle_box}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={10}>
                <YoutubeInput
                  label={t('label.youtube_url')}
                  name="youtubeUrl"
                  onChange={formik.handleChange}
                  value={formik.values.youtubeUrl}
                />
              </Col>

              <Col xs={24} sm={24} md={4}>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Divider plain type={'horizontal'}>
                    {t('label.or')}
                  </Divider>
                </div>
              </Col>

              <Col xs={24} sm={24} md={10}>
                <InputGroup label={'Thumbnails'}>
                  <SingleUpload
                    scale={ImageScale.scale169.scale}
                    accept={'image/jpg, image/jpeg, image/png'}
                    multiple={false}
                    onSuccessFunc={handleOnThumbnailUploaded}
                    onErrorFunc={handleOnUploadHasErr}
                  >
                    <IconImage src={formik.values.thumbnailsUrl} />
                  </SingleUpload>
                </InputGroup>
              </Col>
            </Row>

            <Input
              name="facebookAccount"
              type="text"
              label={t('label.facebook_account')}
              placeholder={t('label.facebook_account')}
              onChange={formik.handleChange}
              value={formik.values.facebookAccount}
            />
          </Card>
        </Authorization>

        <div className={'form-group'}>
          <ButtonGroup>
            {props.onCancel && (
              <Button type="default" shape={'round'} onClick={props.onCancel}>
                {t('button.cancel')}
              </Button>
            )}
          </ButtonGroup>

          <ButtonGroup type={'right'}>
            <Button
              type={'primary'}
              loading={isLoading}
              shape={'round'}
              icon={IconUtils.review}
              onClick={formik.submitForm}
            >
              {t('button.review')}
            </Button>
          </ButtonGroup>
        </div>
      </FormikProvider>
    </UpdateProfileStyle>
  );
}

const UpdateProfileStyle = styled.div`
  .form-group {
    margin-top: 1em;
    margin-bottom: 2em;
  }
`;
