import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  ActiveAccountReq,
  ExitPersonateReq,
  JwtRes,
  LinkToStudentReq,
  LoginReq,
  LogoutRes,
  RefreshTokenReq,
  RefreshTokenRes,
  SwitchSchoolReq,
  SwitchStudentReq,
} from '@modules/authorization/services/model';
import { UserInfoRes } from '@modules/users/services/model';

export const AuthAPI = createApi({
  reducerPath: 'AuthAPI',
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    login: builder.mutation<JwtRes, LoginReq>({
      query: (body: LoginReq) => ({
        url: '/auth/signin',
        body,
        method: 'POST',
      }),
    }),

    // relogin: builder.mutation<LoginRes, LoginReq>({
    //   query: () => ({
    //     url: '/auth/relogin',
    //     method: 'POST',
    //   }),
    // }),

    linkToStudent: builder.mutation<UserInfoRes, LinkToStudentReq>({
      query: (body: LinkToStudentReq) => ({
        url: '/auth/link-to-students',
        body,
        method: 'POST',
      }),
    }),

    activeAccount: builder.mutation<JwtRes, ActiveAccountReq>({
      query: (body: ActiveAccountReq) => ({
        url: '/auth/active-account',
        body,
        method: 'POST',
      }),
    }),

    refreshToken: builder.mutation<RefreshTokenRes, RefreshTokenReq>({
      query: (body: RefreshTokenReq) => ({
        // => RefreshTokenRes
        url: '/auth/token/refresh',
        body,
        method: 'POST',
      }),
    }),

    logout: builder.mutation<LogoutRes, unknown>({
      query: (body) => ({
        url: '/auth/logout',
        body,
        method: 'POST',
      }),
    }),

    logoutAll: builder.mutation<LogoutRes, unknown>({
      query: () => ({
        url: '/auth/logout-all',
        method: 'POST',
      }),
    }),

    switchSchool: builder.mutation<JwtRes, SwitchSchoolReq>({
      query: (body) => ({
        url: '/switch/school',
        method: 'POST',
        body,
      }),
    }),

    switchToStudent: builder.mutation<JwtRes, SwitchStudentReq>({
      query: (body) => ({
        url: '/switch/student',
        method: 'POST',
        body,
      }),
    }),

    exitPersonateMode: builder.mutation<JwtRes, ExitPersonateReq>({
      query: (body) => ({
        url: '/switch/parent',
        method: 'POST',
        body,
      }),
    }),

    getServerVersion: builder.query<BasicMessageRes, unknown>({
      query: () => ({
        url: `/auth/version`,
      }),
    }),
  }),
});
