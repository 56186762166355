import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CompActionEnum, TriggerCompEventType } from '@cms/utils/CompProps';

const defaultEvent: SectionActionEventProps = {
  compId: '' as string,
  eventType: CompActionEnum.default,
  params: -1,
  group: '',
};

export interface SectionActionEventProps {
  compId: string;
  eventType: CompActionEnum;
  params: any;
  group: string;
}

const SectionEventContext = createContext({
  group: '' as string,
  event: defaultEvent as SectionActionEventProps,
  dispatchComponentEvent: (id: string, event: CompActionEnum, params: any) => {
    console.log(event, params);
  },
});

export const SectionEventsProvider = (props: {
  group: string;
  onChange?: (action: SectionActionEventProps) => void; // trigger change inside to another
  eventChange?: (handler: TriggerCompEventType) => void; // receive handler and handle event change by the handler function.
  children: ReactNode;
}) => {
  const [event, setEvent] = useState<SectionActionEventProps>({
    compId: '',
    eventType: CompActionEnum.default,
    params: -1,
    group: props.group,
  });

  useEffect(() => {
    if (props.eventChange) {
      props.eventChange(triggerEventChange);
    }
  }, [props.group]);

  useEffect(() => {
    console.log('SectionEventsProvider', event);
  }, [event]);

  const triggerEventChange = (
    compId: string,
    eventType: CompActionEnum,
    params: any
  ) => {
    setEvent({
      compId,
      eventType,
      params,
      group: props.group,
    });
  };

  const dispatchComponentEvent = (
    compId: string,
    eventType: CompActionEnum,
    params: any
  ) => {
    if (props.onChange) {
      props.onChange({ compId, eventType, params, group: props.group });
    }
  };

  return (
    <SectionEventContext.Provider
      value={{ group: props.group, event, dispatchComponentEvent }}
    >
      {props.children}
    </SectionEventContext.Provider>
  );
};

export const useSectionEventContext = () => {
  const context = useContext(SectionEventContext);
  if (!context) {
    throw new Error('You must wrap container by SectionEventsProvider');
  }
  return context;
};
