import { StandardRes } from '@modules/product/services/standard_model';
import { Tag, Typography } from 'antd';
import ColorUtils from '@utils/ColorUtils';
import React from 'react';
import { LanguageContentTag } from '@components/text/LanguageTag';
const { Text } = Typography;

export const StandardItems = (props: { item: StandardRes }) => {
  return (
    <span className={'standard-item'}>
      <Tag color={ColorUtils.getDefaultColor(props.item.standardId).color}>
        {props.item.code}
      </Tag>

      <Text ellipsis>
        <LanguageContentTag content={props.item.description} />
      </Text>
    </span>
  );
};

export const StandardItemSimple = (props: { item: StandardRes }) => {
  return (
    <span className={'standard-item'}>
      <Tag color={ColorUtils.getDefaultColor(props.item.standardId).color}>
        {props.item.code}
      </Tag>
    </span>
  );
};
