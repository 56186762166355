import React, { useEffect, useState } from 'react';
import { Button, List, Select } from 'antd';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import { UserRes } from '@modules/users/services/model';
import { StudentInfoSimple } from '@components/info/StudentInfo';

import {
  addStudentById,
  initStudents,
  removeStudentById,
} from '@app/redux/slices/addStudentSlices';
import { ShareLessonAPI } from '@services/share/ShareLessonAPI';
import { InvitedStudentForm } from '@components/form/InviteStudentForm';
import { useTranslation } from 'react-i18next';
import { H4 } from '@components/typography';
import { useDispatch, useSelector } from '@app/redux/hook';

const ShareLessonToStudentForm = (props: {
  lessonId: number;
  successHandle: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { students, selectedStudents } = useSelector(
    (state) => state.addStudent
  );

  const [addStudent] = ShareLessonAPI.endpoints.addStudent.useMutation();
  const [removeStudent] = ShareLessonAPI.endpoints.removeStudent.useMutation();

  const [getStudents, { data }] =
    StudentAPI.endpoints.getStudents.useLazyQuery();

  const [getLessonSharedUser, { data: shareData }] =
    ShareLessonAPI.endpoints.getLessonSharedUser.useLazyQuery();

  const [selectStudent, setSelectStudent] = useState(undefined);

  useEffect(() => {
    getStudents({ selectAll: true });
    getLessonSharedUser(props.lessonId);
  }, [props.lessonId]);

  useEffect(() => {
    if (selectStudent != null && selectStudent > 0) {
      shareLessonWithStudent(selectStudent);
    }
  }, [selectStudent]);

  useEffect(() => {
    if (shareData && data) {
      const payload = {
        students: data.content,
        selectedStudent: shareData,
      };
      dispatch(initStudents(payload));
    }
  }, [shareData, data]);

  const shareLessonWithStudent = (userId: number) => {
    triggerAddStudent(userId, (isSuccess) => {
      if (isSuccess) {
        setSelectStudent(undefined);
        dispatch(addStudentById(userId));
      }
    });
  };

  const onRemoveStudent = (userId: number) => {
    triggerRemoveStudent(userId, (isSuccess) => {
      if (isSuccess) {
        dispatch(removeStudentById(userId));
      }
    });
  };

  const triggerAddStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.lessonId != null && props.lessonId > 0) {
      const request = {
        lessonId: props.lessonId,
        studentId: studentId,
      };

      addStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  const triggerRemoveStudent = (
    studentId: number,
    callBack: (success: boolean) => void
  ) => {
    if (props.lessonId != null && props.lessonId > 0) {
      const request = {
        lessonId: props.lessonId,
        studentId: studentId,
      };
      removeStudent(request)
        .unwrap()
        .then(() => {
          callBack(true);
        })
        .catch(() => {
          callBack(false);
        });
    }
  };

  return (
    <InvitedStudentForm className={'share-lesson-form'}>
      <div className={'invite-student-group'}>
        <H4>{t('invitation.select_students')}:</H4>

        <Select
          size={'large'}
          placeholder={t('invitation.select_students')}
          value={selectStudent}
          onChange={(value) => setSelectStudent(value)}
          style={{ width: '100%' }}
        >
          {students.map((item: UserRes) => (
            <Select.Option key={item.userId} value={item.userId}>
              <StudentInfoSimple className={'no-padding'} item={item} />
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={'invite-student-group'}>
        <H4>{t('lesson.shared_students')}:</H4>

        <List
          bordered
          dataSource={selectedStudents}
          renderItem={(item) => (
            <List.Item>
              <div className={'selected-student'} key={item.userId}>
                <StudentInfoSimple className={'small-padding'} item={item} />

                <Button
                  size={'small'}
                  type={'primary'}
                  shape="round"
                  danger
                  onClick={() => onRemoveStudent(item.userId)}
                >
                  {t('button.remove')}
                </Button>
              </div>
            </List.Item>
          )}
        />
      </div>
    </InvitedStudentForm>
  );
};

export default ShareLessonToStudentForm;
