import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { LongSubtractionCompProps } from '@cms/comps/math/operation/subtraction/LongSubtractionComp';
import { LongOperationLayoutEnum } from '@cms/comps/math/operation/OperationUtils';

export const LongSubtractionExampleData: LongSubtractionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_SUBTRACTION,

  configuration: {
    minuend: '925',
    subtrachend: ['275'],
    expression: [
      {
        type: 'minuend',
        exp: ['_', '9', '2', '5'],
      },
      {
        type: 'subtrachend',
        exp: ['-', '2', '7', '5'],
      },
      {
        type: 'difference',
        exp: ['_', 'x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.vertical,
  },
};

export const PlaceValueSubtractionExampleData: LongSubtractionCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.LONG_SUBTRACTION,
  configuration: {
    minuend: '1029',
    subtrachend: ['275', '645'],
    expression: [
      {
        type: 'place-value',
        exp: ['_', 'U', 'U', 'U', 'U'],
      },
      {
        type: 'minuend',
        exp: ['_', '1', '0', '2', '9'],
      },
      {
        type: 'subtrachend',
        exp: ['_', '_', '2', '7', '5'],
      },
      {
        type: 'subtrachend',
        exp: ['-', '_', '6', '4', '5'],
      },
      {
        type: 'difference',
        exp: ['_', '_', 'x', 'x', 'x'],
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: LongOperationLayoutEnum.place_value,
  },
};
