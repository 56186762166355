import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Select } from 'antd';
import Input from '@components/input/input';
import { FormHorizontal, InputGroup } from '@components/input/InputGroup';
import DatePicker from '@components/date-n-time/DatePicker';
import ContentEditor from '@components/editor/WysyEditor';
import TimePicker from '@components/date-n-time/CustomTimePicker';
import SelectAssignmentGroup from '@modules/common/components/SelectAssignmentGroup';
import { Default_Gutter } from '@components/grid';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { SelectTeacher } from '@components/select/SelectTeacher';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { AssignmentExpiredType } from '@modules/assignments/service/assignment_model';

export const CreateAssignmentForm = (props: { formik: any }) => {
  const { t } = useTranslation();

  const handleEditorChange = (direction: string) => {
    props.formik.setFieldValue('direction', direction);
  };

  return (
    <div className={'edit-assignment-form'}>
      <FormHorizontal>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <SelectTeacher
                value={props.formik.values.forUserId}
                onChange={(val) => props.formik.setFieldValue('forUserId', val)}
              />

              <Divider plain>Assignment information</Divider>
            </Col>
          </Authorization>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Input
              required={true}
              name="name"
              type="text"
              label={t('label.name')}
              placeholder={t('label.name')}
              onChange={props.formik.handleChange}
              value={props.formik.values.name}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <SelectAssignmentGroup
              formik={props.formik}
              name={'assignmentGroupId'}
            />
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <InputGroup label={t('assignment.direction')}>
              <ContentEditor
                initValue={props.formik.values.direction}
                onChange={handleEditorChange}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <InputGroup label={'Expired By'}>
              <Select
                size={'large'}
                placeholder={'Expired by'}
                value={props.formik.values.expiredType}
                onChange={(value) =>
                  props.formik.setFieldValue('expiredType', value)
                }
              >
                {[
                  AssignmentExpiredType.date_n_time,
                  AssignmentExpiredType.duration,
                ].map((type) => (
                  <Select.Option key={type} value={type}>
                    {type === AssignmentExpiredType.date_n_time ? (
                      <>
                        <CalendarOutlined /> Date and Time
                      </>
                    ) : (
                      <>
                        <ClockCircleOutlined /> After n minutes
                      </>
                    )}
                  </Select.Option>
                ))}
              </Select>
            </InputGroup>
          </Col>

          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            {props.formik.values.expiredType ===
            AssignmentExpiredType.date_n_time ? (
              <Col span={24}>
                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                    <InputGroup label={t('assignment.unlockAt')}>
                      <DatePicker
                        value={props.formik.values.unlockAtDate}
                        name="unlockAtDate"
                        formik={props.formik}
                        handleChange={props.formik.handleChange as any}
                      />
                    </InputGroup>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                    <InputGroup label={t('label.time')}>
                      <TimePicker
                        value={props.formik.values.unlockAtTime}
                        name="unlockAtTime"
                        formik={props.formik}
                        handleChange={props.formik.handleChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                    <InputGroup label={t('assignment.lockAt')}>
                      <DatePicker
                        value={props.formik.values.lockAtDate}
                        name="lockAtDate"
                        formik={props.formik}
                        handleChange={props.formik.handleChange as any}
                      />
                    </InputGroup>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                    <InputGroup label={t('label.time')}>
                      <TimePicker
                        value={props.formik.values.lockAtTime}
                        name="lockAtTime"
                        formik={props.formik}
                        handleChange={props.formik.handleChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={12}>
                <Row gutter={[Default_Gutter, Default_Gutter]}>
                  <Col xs={24} sm={24} md={24}>
                    <Input
                      name="duration"
                      type="number"
                      label={t('label.duration')}
                      placeholder={t('label.duration')}
                      onChange={props.formik.handleChange}
                      value={props.formik.values.duration}
                      step={5}
                      addonAfter={
                        <>
                          <ClockCircleOutlined /> Minutes
                        </>
                      }
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Col>
        </Row>
      </FormHorizontal>
    </div>
  );
};
