import React, { useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { CMSAssetsAPI } from '@modules/product/services/AssetsAPI';
import { AssetsRes } from '@modules/product/services/assets_model';
const { Dragger } = Upload;

interface AddStaticResourceProps {
  fileType?: AssetsTypeEnum;
  onUploadFile: (file: AssetsRes) => void;
  children: any;
}

export enum AssetsTypeEnum {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  DOC = 'DOC',
  PDF = 'PDF',
  EPUB = 'EPUB',
}

export default function AddStaticResource(props: AddStaticResourceProps) {
  const [uploadFile] = CMSAssetsAPI.endpoints.uploadFile.useMutation({});

  const [defaultFileList, setDefaultFileList] = useState([]);

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const formData = new FormData();
      formData.append('file', file);

      uploadFile(formData)
        .unwrap()
        .then((data: AssetsRes) => {
          onSuccess('Ok');

          props.onUploadFile(data);
        })
        .catch((err) => {
          onError({ err });
        });
    } catch (err) {
      onError({ err });
    }
  };

  const handleOnChange = (params: any) => {
    setDefaultFileList(params.fileList);
  };

  return (
    <AddResourceStyle className={'add-resource'}>
      <Dragger
        multiple={true}
        fileList={[]}
        customRequest={uploadImage}
        onChange={handleOnChange}
        defaultFileList={defaultFileList}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>

        {props.children}
      </Dragger>
    </AddResourceStyle>
  );
}

const AddResourceStyle = styled.div`
  //max-height: 5em;
  margin: 2em auto;

  .ant-upload.ant-upload-drag {
    border: 2px dashed #ccc;
  }
`;
