import React, { useEffect } from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { StringUtils } from '@utils/StringUtils';
import {
  CreatePageSiteReq,
  PageSiteAPI,
  PageSiteRes,
  UpdatePageSiteReq,
} from '@services/page-site/PageSiteAPI';
import { SelectLanguage } from '@components/select/SelectLanguage';
import { InputGroup } from '@components/input/InputGroup';
import { Default_Gutter } from '@components/grid';
import { getDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { IconUtils } from '@utils/IconUtils';

const initialValue = {
  path: '',
  name: '',
  description: '',
  language: getDefaultLanguage(),
};

export default function CreatePageSiteForm(props: {
  pageSiteId?: number;
  onSuccess: (isAdded: boolean, data: PageSiteRes) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [viewPageSite, { data, isSuccess, isFetching }] =
    PageSiteAPI.endpoints.viewPageSite.useLazyQuery({});

  const [createPageSite, { isLoading: isCreateLoading }] =
    PageSiteAPI.endpoints.createPageSite.useMutation({});

  const [updatePageSite, { isLoading: isUpdateLoading }] =
    PageSiteAPI.endpoints.updatePageSite.useMutation({});

  useEffect(() => {
    if (props.pageSiteId != null && props.pageSiteId > 0) {
      viewPageSite(props.pageSiteId);
    }
  }, [props.pageSiteId]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreFormData(data);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().trim().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.pageSiteId != null && props.pageSiteId > 0) {
        const payload: UpdatePageSiteReq = {
          pageSiteId: props.pageSiteId,
          path: values.path,
          name: values.name,
          description: values.description,
        };
        handleOnUpdate(payload);
      } else {
        const payload: CreatePageSiteReq = {
          path: values.path,
          name: values.name,
          description: values.description,
          language: values.language,
        };
        handleOnCreate(payload);
      }
    },
  });

  const restoreFormData = (data: PageSiteRes) => {
    formik.setValues({
      path: data.path,
      name: data.name,
      description: data.description,
      language: getDefaultLanguage(),
    });
  };

  const handleOnUpdate = (request: UpdatePageSiteReq) => {
    updatePageSite(request)
      .unwrap()
      .then((data) => {
        props.onSuccess(false, data);

        notification.success({
          message: t('page_site.warning.update_success'),
          description: t('page_site.warning.update_success_message'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.update_error'),
          description: t('lesson.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleOnCreate = (payload: CreatePageSiteReq) => {
    createPageSite(payload)
      .unwrap()
      .then((data) => {
        props.onSuccess(true, data);

        notification.success({
          message: t('page_site.warning.add_success'),
          description: t('page_site.warning.add_success_message'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        notification.error({
          message: t('page_site.warning.add_error'),
          description: t('page_site.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handlePathChange = (value: string) => {
    const newPath = StringUtils.replaceAll(value.toLowerCase(), ' ', '-');
    formik.setFieldValue('path', StringUtils.replaceAll(newPath, '--', '-'));
  };

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="path"
            type="text"
            label={t('label.path')}
            placeholder={t('label.path')}
            onChange={(event) => handlePathChange(event.target.value)}
            value={formik.values.path}
          />

          <Input
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <Input
            label={t('label.description')}
            type={'textarea'}
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            style={{ minHeight: 120 }}
          />

          <InputGroup label={t('language.header')}>
            <SelectLanguage
              value={formik.values.language}
              onChange={(lang) => formik.setFieldValue('language', lang)}
            />
          </InputGroup>

          <ButtonGroup type={'right'} className="submit-container">
            <Button
              type="default"
              shape={'round'}
              size={'large'}
              onClick={props.onClose}
            >
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              loading={isFetching || isUpdateLoading || isCreateLoading}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              {props.pageSiteId != null && props.pageSiteId > 0
                ? t('button.update')
                : t('button.create')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
