import { useTranslation } from 'react-i18next';
import React from 'react';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import { LessonExampleActionEnum } from '@modules/product/container/lesson-example/LessonExampleActions';
import LessonExampleEditForm from '@modules/product/container/lesson-example/LessonExampleEditForm';

export const LessonExampleActionsHandle = (props: {
  action: { action: LessonExampleActionEnum; params: number };
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {props.action.action === LessonExampleActionEnum.EDIT && (
        <CustomModal
          header={t('lesson_example.actions.edit')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <LessonExampleEditForm
              lessonExampleId={props.action.params}
              onSuccess={props.onCancel}
              onClose={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}

      {props.action.action === LessonExampleActionEnum.EDIT_METADATA && (
        <CustomModal
          header={t('lesson_example.actions.edit_metadata')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <LessonMetadata
              lessonId={props.action.params}
              onSuccess={props.onCancel}
              onCancel={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}
    </>
  );
};
