import React, { useEffect } from 'react';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import { Button, Col, Divider, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import { ImageScale } from '@utils/ImageScale';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import ButtonGroup from '@components/button/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '@components/avatar/UserAvatar';

export const ViewStudentInfo = (props: {
  schoolId?: number;
  studentId: number;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getStudentData, { data, isSuccess }] =
    StudentAPI.endpoints.getStudentData.useLazyQuery();

  useEffect(() => {
    if (props.studentId != null && props.studentId > 0) {
      getStudentData(props.studentId);
    }
  }, [props.studentId]);

  return (
    <>
      {data && isSuccess && (
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={ImageScale.avatar.width + 24 + 'px'}>
            <InputGroup label={t('label.avatar')}>
              <InputValue type={'textarea'}>
                <UserAvatar src={data?.avatar} size={ImageScale.avatar.width} />
              </InputValue>
            </InputGroup>
          </Col>

          <Col flex="auto">
            <InputGroup label={t('label.username')}>
              <InputValue type={'textarea'} copyable={true}>
                {data.username}
              </InputValue>
            </InputGroup>

            <InputGroup label={t('label.password')}>
              <InputValue type={'textarea'} copyable={true}>
                {data.password}
              </InputValue>
            </InputGroup>

            <Divider />

            <InputGroup label={t('label.firstName')}>
              <InputValue type={'textarea'}>{data.firstName}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.lastName')}>
              <InputValue type={'textarea'}>{data.lastName}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.email')}>
              <InputValue type={'textarea'}>{data.email}</InputValue>
            </InputGroup>

            <InputGroup label={t('label.phone')}>
              <InputValue type={'textarea'}>{data.phone}</InputValue>
            </InputGroup>

            <div className="submit-container">
              <ButtonGroup type={'right'}>
                <Button
                  type={'default'}
                  shape={'round'}
                  onClick={props.onCancel}
                >
                  {t('button.close')}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
