import { UserProfileRes } from '@services/private/UserProfileAPI';
import React, { useMemo } from 'react';
import { Divider } from 'antd';
import ContainerScale from '@components/Layout/ContainerScale';
import styled from 'styled-components';
import { TeacherPublicLesson } from '@modules/public/components/public/TeacherPublicLesson';
import { H1 } from '@components/typography';

export const ViewTeacherProfilePage = (props: {
  shortName: string;
  item: UserProfileRes;
}) => {
  const coverImg = useMemo(() => {
    if (props.item.youtubeUrl != null && props.item.youtubeUrl) {
      return (
        <ContainerScale scale={9 / 16} minHeight={300}>
          <iframe
            title={props.item.displayName}
            className="video-instruction content-scale"
            width="100%"
            height="100%"
            src={props.item.youtubeUrl}
          />
        </ContainerScale>
      );
    } else if (props.item.thumbnailsUrl != null && props.item.thumbnailsUrl) {
      return (
        <ContainerScale scale={9 / 16} minHeight={300}>
          <img
            className={'content-scale'}
            alt={props.item.name}
            src={props.item.thumbnailsUrl}
            style={{ width: '100%', height: 'auto' }}
          />
        </ContainerScale>
      );
    } else {
      return null;
    }
  }, [props.item]);

  return (
    <ViewTeacherProfileStyle className={'teacher-profile-detail'}>
      <div className={'container'}>
        {coverImg && <div className={'site-introduction'}>{coverImg}</div>}

        <div className={'site-header'}>
          <div className={'site-avatar'}>
            <img alt={props.item.shortIntroduce} src={props.item.avatarUrl} />
          </div>

          <div className={'site-title-display'}>
            <H1>{props.item.displayName}</H1>

            <div
              className={'site-introduce'}
              dangerouslySetInnerHTML={{ __html: props.item.introduce }}
            />
          </div>
        </div>

        <Divider />

        <TeacherPublicLesson username={props.shortName} />
      </div>
    </ViewTeacherProfileStyle>
  );
};

const ViewTeacherProfileStyle = styled.div`
  .site-header {
    margin-top: 2em;
    display: flex;
    gap: 2em;

    .site-avatar {
      min-width: 200px;
      width: 200px;
      height: 200px;
    }

    .site-title-display {
      flex-grow: 1;
    }
  }
`;
