import { H2, H5 } from '@components/typography';
import ButtonGroup from '@components/button/ButtonGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfileRes } from '@services/private/UserProfileAPI';
import RequireLoginButton from '@components/button/RequireLoginButton';
import styled from 'styled-components';
import NavLink from '@components/button/NavLink';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { PurchaseProductButton } from '../licenses/PurchaseProductButton';
import { SiteMap } from '@router/SiteMap';
import { ProductRes } from '@modules/product/services/product_model';

export const ProductInfo = (props: {
  product: ProductRes;
  author: UserProfileRes;
}) => {
  const { t } = useTranslation();

  const addProductToLibrary = () => {
    console.log('add product to library');
  };

  return (
    <ProductInfoStyle className={'product-info-group'}>
      <H2 className={'product-title'}>
        {props.product.name}

        <ButtonGroup type={'right'}>
          {props.product.free ? (
            <RequireLoginButton
              type={'primary'}
              shape="round"
              onClick={addProductToLibrary}
            >
              <AppstoreAddOutlined /> {t('button.add_to_library')}
            </RequireLoginButton>
          ) : (
            <PurchaseProductButton
              type={'purchase'}
              productId={props.product.productId}
              name={props.product.name}
              price={props.product.price!}
              productTocId={-1}
              lessonId={-1}
            />
          )}
        </ButtonGroup>
      </H2>

      <H5 className={'product-author'}>
        Author:
        <NavLink href={SiteMap.public.teacher_view_gen(props.author.name)}>
          {props.author.displayName}
        </NavLink>
      </H5>
    </ProductInfoStyle>
  );
};

const ProductInfoStyle = styled.div`
  .product-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .product-author {
    color: ${(props) => props.theme.color.grey};
  }
`;
