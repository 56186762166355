import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { AppstoreAddOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { PublishSchoolAPI } from '@services/publish/PublishSchool';
import { H4 } from '@components/typography';
import { Space } from 'antd';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { PriceTagDisplay } from '@components/product/LessonInfo';
import Table from '@components/table/Table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProductPriceRes } from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const ViewProductPrices = (props: {
  productId: number;
  productTocId: number;
  lessonId: number;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const [getProductPrices, { data, isSuccess, isLoading }] =
    PublishSchoolAPI.endpoints.getProductPrices.useLazyQuery({});

  const [productPrices, setProductPrices] = useState<ProductPriceRes | null>(
    null
  );

  useEffect(() => {
    getProductPrices(props.productId);
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      setProductPrices(data);
    }
  }, [data]);

  const purchaseLicense = () => {
    props.onClose();
  };

  const columns = [
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('license.label.three_month'),
      dataIndex: 'threeMonth',
      key: 'threeMonth',
      width: 100,
    },
    {
      title: t('license.label.six_month'),
      dataIndex: 'sixMonth',
      key: 'sixMonth',
      width: 100,
    },
    {
      title: t('license.label.one_year'),
      dataIndex: 'oneYear',
      key: 'oneYear',
      width: 100,
    },
    {
      title: t('license.label.forever'),
      dataIndex: 'forever',
      key: 'forever',
      width: 100,
    },
  ];

  const dataSource = useMemo(() => {
    if (data) {
      const lessonLicense = [];

      lessonLicense.push({
        key: 'product-' + data.productId,
        name: (
          <Space size={12}>
            <ThumbnailsImg
              type={'icon'}
              src={data.icon}
              width={ImageScale.product.iconLarge}
              ratio={ImageScale.product.ratio}
            />

            <H4 className={'lesson-display'}>{data.name}</H4>
          </Space>
        ),
        threeMonth: <PriceTagDisplay free={false} price={data.threeMonth} />,
        sixMonth: <PriceTagDisplay free={false} price={data.sixMonth} />,
        oneYear: <PriceTagDisplay free={false} price={data.oneYear} />,
        forever: <PriceTagDisplay free={false} price={data.forever} />,
      });

      lessonLicense.push({
        key: 'product-header-' + data.productId,
        name: <H4>{t('license.hint.allow_separate_lesson')}</H4>,
        threeMonth: '',
        sixMonth: '',
        oneYear: '',
        forever: '',
      });

      data.productTocs.forEach((item) => {
        lessonLicense.push({
          key: 'product-toc-' + item.productTocId,
          name: <b>{item.name}</b>,
          threeMonth: '',
          sixMonth: '',
          oneYear: '',
          forever: '',
        });

        if (item.lessons.length > 0) {
          item.lessons.forEach((ls) => {
            lessonLicense.push({
              key: 'lesson-' + ls.lessonId,
              lesson: ls,
              productTocId: item.productTocId,
              name: (
                <div className={'lesson-info'} style={{ marginLeft: 20 }}>
                  <Space>
                    <ThumbnailsImg
                      type={'icon'}
                      src={ls.thumbnails}
                      width={ImageScale.product.icon}
                      ratio={ImageScale.product.ratio}
                    />

                    <span className={'lesson-display'}>{ls.name}</span>
                  </Space>
                </div>
              ),
              threeMonth: (
                <PriceTagDisplay free={false} price={ls.threeMonth} />
              ),
              sixMonth: <PriceTagDisplay free={false} price={ls.sixMonth} />,
              oneYear: <PriceTagDisplay free={false} price={ls.oneYear} />,
              forever: <PriceTagDisplay free={false} price={ls.forever} />,
            });
          });
        }
      });
      return lessonLicense;
    } else {
      return [];
    }
  }, [productPrices]);

  const getRowKeyFunc = (record: any) => {
    return record.key;
  };

  const getRowClassNameFunc = ({
    lesson,
    productTocId,
  }: {
    lesson: LessonRes | null;
    productTocId: number | null;
  }) => {
    if (
      lesson != null &&
      lesson.lessonId === props.lessonId &&
      productTocId != null &&
      productTocId === props.productTocId
    ) {
      return 'highlight-lesson-row';
    } else {
      return '';
    }
  };

  return (
    <ProductLicenseStyle className={'product-license'}>
      {data && isSuccess && (
        <div className={'product-license-info'}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey={getRowKeyFunc}
            rowClassName={getRowClassNameFunc}
          />
        </div>
      )}

      <div
        className={'product-license-action'}
        style={{ paddingTop: '3em', paddingBottom: '1.5em' }}
      >
        <ButtonGroup type={'center'}>
          <Button
            size={'large'}
            shape={'round'}
            type={'primary'}
            loading={isLoading}
            onClick={purchaseLicense}
          >
            <AppstoreAddOutlined /> {t('button.purchase')}
          </Button>
        </ButtonGroup>
      </div>
    </ProductLicenseStyle>
  );
};

const ProductLicenseStyle = styled.div`
  tr.highlight-lesson-row td {
    background: #e2dfdf;
    font-weight: bold;
  }
`;
