import { MultipleChoiceSettingProps } from '@cms/comps/interact/multiple-choice/MultipleChoiceComp';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { Select } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import Checkbox from '@components/input/Checkbox';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';

export const MultipleChoiceSetting = (props: {
  setting: MultipleChoiceSettingProps;
  onSettingChange: (newSetting: MultipleChoiceSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={'vertical'} value={'vertical'}>
              {t('component.label.vertical')}
            </Select.Option>

            <Select.Option key={'horizontal'} value={'horizontal'}>
              {t('component.label.horizontal')}
            </Select.Option>

            <Select.Option key={'2x2'} value={'2x2'}>
              {t('component.label.table_2_2')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Checkbox
          name={'showLabel'}
          checked={formik.values.showLabel}
          value={formik.values.showLabel}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.check_box.show_label')}
        </Checkbox>

        <Checkbox
          name={'showOption'}
          checked={formik.values.showOption}
          value={formik.values.showOption}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.check_box.show_option')}
        </Checkbox>

        <Checkbox
          name={'multiple'}
          checked={formik.values.multiple}
          value={formik.values.multiple}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.check_box.multiple')}
        </Checkbox>
      </FormikProvider>
    </SettingStyle>
  );
};
