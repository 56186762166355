import { Select, SelectProps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { ClassAPI } from '@modules/users/services/ClassAPI';
import { SmallColorCircle } from '@components/input/ColorCircle';
import styled from 'styled-components';
import { ClassRes } from '@modules/users/services/clazz';

export interface SelectClassProps extends SelectProps {
  formik?: any;
  label?: ReactNode;
  placeholder?: ReactNode;

  teacherId?: number;
  name: string;
  value?: number;
  onChange?: (value: number) => void;
}

const SelectClass = ({
  formik,
  label,
  placeholder,
  teacherId,
  name,
  value,
  onChange,
  ...props
}: SelectClassProps) => {
  const { t } = useTranslation();

  const [getMyClasses, { data, isSuccess, isFetching }] =
    ClassAPI.endpoints.getMyClasses.useLazyQuery({});

  const [options, setOptions] = useState<ClassRes[]>([]);

  useEffect(() => {
    getMyClasses({
      selectAll: true,
      teacherId: teacherId,
    });
  }, []);
  useEffect(() => {
    if (data && isSuccess) {
      setOptions(data.content);
    }
  }, [data]);

  const handleOnInputChange = (value: number) => {
    if (onChange) {
      onChange(value);
    } else if (formik) {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <InputGroup label={label ?? t('label.selectClass')}>
      <Select
        style={{ width: '100%' }}
        size={'large'}
        placeholder={placeholder}
        onChange={(_value) => handleOnInputChange(_value as number)}
        value={value ?? formik.values[name]}
        loading={isFetching}
        {...props}
      >
        <Select.Option key={-1} value={-1}>
          <SelectClassItemStyle className={'class-option-item'}>
            <SmallColorCircle color={'#ccc'} />
            &nbsp;
            {t('label.selectClass')}
          </SelectClassItemStyle>
        </Select.Option>

        {options.map((op) => {
          return (
            <Select.Option key={op.classId} value={op.classId}>
              <SelectClassItemStyle className={'class-option-item'}>
                <SmallColorCircle color={op.color}>{op.code}</SmallColorCircle>{' '}
                &nbsp;
                {op.name}
              </SelectClassItemStyle>
            </Select.Option>
          );
        })}
      </Select>
    </InputGroup>
  );
};

export default SelectClass;

const SelectClassItemStyle = styled.span`
  display: flex;
  align-items: center;
  min-height: 40px;
`;
