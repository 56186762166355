import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { DynamicContentProps } from '@cms/comps/content/dynamic/DynamicContentComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';

export const DynamicContentTemplateData: DynamicContentProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.DYNAMIC_CONTENT,

  configuration: {
    content: {
      id: COMPONENT_UTILS.generateUID(),
      type: AssetsTypeEnum.TEXT,
      data: `
            <h2 editable="true" style="font-size: 2em; text-align: center; color: #0474BB; line-height: 2">Title of the passage</h2>
            <h4 editable="true" style="font-size: 1.2em; text-align: center; color: #0474BB; line-height: 2;">Subtitle of the passage</h4>
            <div class="passage-content" editable="group">
                <p editable="true">Paragraph.....</p>
            </div>
`,
    },
  },
};
