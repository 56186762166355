import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import LicenseProducts from '@modules/product/container/student/LicenseProducts';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import ComposeProductGroup from '@modules/product/container/product/ComposeProductGroup';

const MyLibraryProduct = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-products-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.LIBRARY_PRODUCTS}
        title={t('library.header')}
        description={t('library.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        <ComposeProductGroup />
      </Authorization>

      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
      >
        <LicenseProducts />
      </Authorization>
    </ManagementLayout>
  );
};

export default MyLibraryProduct;
