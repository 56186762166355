import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { Divider, List } from 'antd';
import LessonListItem, {
  LessonSelectionEnum,
} from '@modules/product/components/lesson/SelectLessonItem';

import { H5 } from '@components/typography';
import FilterLessonTree from '@modules/product/components/toc/FilterLessonTree';
import { EntityStatusEnum } from '@services/model/common';
import { LessonRes } from '@modules/product/services/lesson_model';
import {
  FilterProductLessonReq,
  TableOfContentRes,
} from '@modules/product/services/product_model';

export enum ProductFilter {
  NOT_ARRANGE = -1,
  ALL_LESSONS = 0,
}

const SelectLessonForm = (props: {
  teacherId?: number;
  productId: number;
  productTocId?: number;
  initLessonIds: number[];
  selectedLessonIds: number[];
  setLessons?: (lessons: LessonRes[]) => void;

  onSelect: (lessonData: LessonRes) => void;
  onRemove: (lessonId: number) => void;

  selectProduct?: boolean;
}) => {
  const [filterLessons, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.filterLessons.useLazyQuery({});

  const [firstLoading, setFirstLoading] = useState(true);
  const [tableOfContent, setTableOfContent] =
    useState<TableOfContentRes | null>(null);

  useEffect(() => {
    if (
      tableOfContent &&
      firstLoading &&
      props.initLessonIds != null &&
      props.initLessonIds.length > 0 &&
      props.setLessons != null
    ) {
      const lessons: LessonRes[] = [];

      tableOfContent.tableOfContent.forEach((res) => {
        res.lessons.forEach((ls) => {
          if (props.initLessonIds.includes(ls.lessonId)) {
            lessons.push(ls);
          }
        });
      });

      props.setLessons(lessons);
      setFirstLoading(false);
    }
  }, [props.initLessonIds, tableOfContent]);

  useEffect(() => {
    if (props.productId > 0) {
      filterLessons({
        productId: props.productId,
        keyword: '',
        status: EntityStatusEnum.EMPTY,
      });
    }
  }, [props.productId]);

  useEffect(() => {
    if (isSuccess && data) {
      setTableOfContent(data);
    }
  }, [data]);

  const handleOnSubmit = (data: FilterProductLessonReq) => {
    filterLessons({
      productId: data.productId,
      keyword: data.keyword,
      status: data.status,
    });
  };

  return (
    <LessonTreeStyle className={'lesson-tree'}>
      <div className={'product-filter'}>
        <FilterLessonTree
          teacherId={props.teacherId}
          productId={
            props.productId ??
            (props.selectProduct ? null : ProductFilter.NOT_ARRANGE)
          }
          setting={{
            selectProduct: props.selectProduct ?? false,
            showRemovedLesson: false,
          }}
          onSubmit={handleOnSubmit}
        />
      </div>

      <Divider />

      <TableOfContentStyle
        className={'table-of-content scrollable-container scroll-flex'}
        style={{
          minHeight: 200,
          maxHeight: 'calc(100vh - 400px)',
          overflowY: 'auto',
        }}
      >
        {tableOfContent &&
          tableOfContent.tableOfContent.map((item) => {
            if (item.lessons != null && item.lessons.length > 0) {
              return (
                <div
                  key={JSON.stringify(item)}
                  className={'table-of-content-group'}
                >
                  {item.name !== 'not-arranged' && (
                    <H5 className={'toc-title'}>{item.name}</H5>
                  )}

                  <div className={'lesson-group'}>
                    <List
                      bordered
                      loading={isLoading}
                      dataSource={item.lessons}
                      style={{ width: '100%' }}
                      renderItem={(item) => {
                        const selected = props.selectedLessonIds.includes(
                          item.lessonId
                        );
                        return (
                          <List.Item className={selected ? 'selected' : ''}>
                            {selected ? (
                              <LessonListItem
                                item={item}
                                selected={selected}
                                type={LessonSelectionEnum.remove}
                                onRemove={props.onRemove}
                              />
                            ) : (
                              <LessonListItem
                                item={item}
                                selected={selected}
                                type={LessonSelectionEnum.add}
                                onSelect={props.onSelect}
                              />
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </TableOfContentStyle>
    </LessonTreeStyle>
  );
};

export default SelectLessonForm;

const LessonTreeStyle = styled.div`
  .ant-list-item .draggable-item {
    width: 100%;
    background: transparent !important;

    &.dragging {
      background: #fff !important;
    }
  }
`;

const TableOfContentStyle = styled.div`
  .table-of-content-group {
    &:not(:last-child) {
      margin-bottom: 2em;
    }

    .toc-title {
      margin-bottom: 0.25em;
    }
  }

  .ant-list-items .ant-list-item.selected {
    font-weight: bold;
    background: ${(props) => props.theme.app.primary_bgr};
  }
`;
