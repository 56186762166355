import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { SiteMap } from '@router/SiteMap';
import { LinkSample } from '@modules/public/features/Link';
import { RoleEnum } from '@app/redux/slices/roles';
import { RoleDisplay } from '@components/role/RoleDisplay';

export const CalendarFeature = () => {
  return (
    <div className={'group'}>
      <FeatureGroup
        header={'Tạo event'}
        roles={[RoleEnum.SCHOOL, RoleEnum.CREATE_CALENDAR]}
      >
        <div>
          Chức năng này cho phép giáo viên tạo các buổi học online và mời học
          sinh tham gia.
          <br />
          Giáo viên có thể soạn slide trước để chuẩn bị giảng dạy, và có thể
          thêm các bài học để chia sẻ với học sinh.
          <br />
          Giáo viên phải có role <RoleDisplay role={RoleEnum.CREATE_CALENDAR} />
          mới tạo được calendar.
          <br />
          Xem chức năng phân quyền
        </div>

        <dd>
          <dt>Tạo events</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang Calendar
                <LinkSample href={SiteMap.private.my_calendar} />{' '}
              </li>
              <li>Click vào 1 ngày bất kì để tạo sự kiện</li>
              <li>Nhập thông tin</li>
              <li>Sự kiện xuất hiện trong Calendar</li>
            </ol>
          </dl>

          <dt>Cập nhật thông tin</dt>
          <dl>
            <ol>
              <li>
                Click vào sự kiện ở trong Calendar{' '}
                <LinkSample href={SiteMap.private.my_calendar} />
              </li>
              <li>Cập nhật thông tin sự kiện (thời gian, tiêu đề)</li>
              <li>Cập nhật học sinh</li>
              <li>Cập nhật bài học</li>
            </ol>
          </dl>

          <dt>Tham gia buổi học (dành cho học sinh và giáo viên)</dt>
          <dl>
            <ol>
              <li>
                Click vào sự kiện ở trong Calendar{' '}
                <LinkSample href={SiteMap.private.my_calendar} />
              </li>
              <li>Click vào lớp học button.</li>
              <li>Owner có thể vào lớp mọi lúc (để chuẩn bị bài học)</li>
              <li className={'incompleted'}>
                Học viên chỉ có thể vào trước khi buổi học diễn ra 5p và được
                xem lại sau khi buổi học kết thúc.
              </li>
            </ol>
          </dl>

          <dt>Lên lịch cho giáo viên</dt>
          <dl className={'incompleted'}>
            <div>
              Chức năng này giúp School admin lên lịch cho giáo viên, và giáo
              viên chỉ cần tiến hành dạy học theo lịch quy định sẵn.
            </div>

            <ol>
              <li>School Admin truy cập tới trang Calendar</li>
              <li>School Admin chọn giáo viên trong danh sách.</li>
              <li>Xem thời khóa biểu của giáo viên</li>
              <li>Click vào calendar để tạo event cho giáo viên đó.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup
        header={'Class Room'}
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
      >
        <dd>
          <dt>Truy cập vào buổi học</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào Calendar, Click vào sự kiện và click tiếp Join
                Class Room để tiến hành vào lớp.
                <LinkSample href={SiteMap.content.lesson.list} />{' '}
              </li>
            </ol>
          </dl>

          <dt>Tính năng soạn slide</dt>
          <dl>
            <ol>
              <li>
                Tính năng soạn slide tương tự với tính năng biên soạn bài học.
              </li>

              <li>Giáo viên click vào biểu tượng + để tạo slide.</li>

              <li>
                Một slide là 1 danh sách các câu hỏi, giáo viên có thể lựa chọn
                trong danh sách bài học hoặc trong library của mình
              </li>
            </ol>
          </dl>

          <dt>Tính năng giảng bài</dt>
          <dl>
            <ol>
              <li>
                Giáo viên click vào slide và Click vào record/ presentation
              </li>

              <li>
                Các thao tác của giáo viên xuất hiện ở màn hình của học sinh
              </li>

              <li>
                Các annotation và các sự kiện như cập nhật nội dung câu hỏi
              </li>
            </ol>
          </dl>

          <dt>Tính năng chat</dt>
          <dl className={'incompleted'}>
            <ol>
              <li>
                Giáo viên xem danh sách học sinh đang online/offline trong buổi
                học
              </li>

              <li>Giáo viên nhắn tin cho từng học sinh</li>

              <li>Giáo viên nhắn tin trong lesson</li>
            </ol>
          </dl>

          <dt>Tính năng điểm danh</dt>
          <dl className={'incompleted'}>
            <ol>
              <li>
                Khi học sinh tham gia vào lớp, giáo viên nhận được thông báo
              </li>

              <li>
                Cần 1 báo cáo chi tiết cho cái này, lúc học viên join/ left,
                duration.
              </li>
            </ol>
          </dl>

          <dt>Tính năng assign bài</dt>
          <dl className={'incompleted'}>
            <ol>
              <li>
                Giáo viên có thể yêu cầu các học sinh/ toàn bộ học sinh trả lời
                các câu hỏi.
              </li>

              <li>Giáo viên xem câu trả lời của học sinh và kết quả.</li>

              <li>
                Giáo viên lựa chọn 1 học sinh để trình chiếu và feedback dựa
                trên đó.
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
