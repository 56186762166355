import useFullScreen from '@cms/script/useFullScreen';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Tooltip } from '@components/tooltip/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LessonButton from '@cms/lesson-template/components/buttons/style';

export const FullscreenButton = () => {
  const { t } = useTranslation();
  const { isFullscreen, toggleFullScreen } = useFullScreen();

  return (
    <Tooltip title={t('exercise.button.full_screen')}>
      <LessonButton
        type={'default'}
        shape={'circle'}
        onClick={toggleFullScreen}
        icon={
          isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
        }
      />
    </Tooltip>
  );
};
