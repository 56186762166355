import React from 'react';

import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import FindCoursePage from '@modules/public/pages/FindCoursePage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';

const Courses = () => {
  const { t } = useTranslation();

  return (
    <PublicLayout className={'courses-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_COURSE}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('course.title')}
        description={t('course.title')}
      />

      <PublicPageContainer>
        <FindCoursePage />
      </PublicPageContainer>
    </PublicLayout>
  );
};

export default Courses;
