import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useRouter } from '@hooks/useRouter';
import { PublishPageSitePage } from '@modules/page-site/pages/PublishPageSitePage';
import { PageHeader } from '@app/header/PageHeader';

const PublishPageContent = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const { id } = router.query as unknown as {
    id: number;
  };

  return (
    <ManagementLayout className={'publish-page-content-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PAGES}
        subMenu={NavigationMenuEnum.PAGES_STATIC_PAGE}
        title={t('menu.school_content')}
        description={t('menu.school_content')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        <PublishPageSitePage pageSiteId={id} />
      </Authorization>
    </ManagementLayout>
  );
};

export default PublishPageContent;
