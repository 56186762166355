import React from 'react';
import { NextQuestionNav } from '@cms/lesson-template/components/button-wrapper/NextQuestionBtn';
import styled from 'styled-components';
import { ReviewExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ReviewExerciseButton';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useTranslation } from 'react-i18next';
import {
  ExerciseEventEnum,
  PracticeActionEnum,
  PracticeResourceState,
  usePracticeContext,
} from '@cms/lesson-template/context/PracticeContext';

export interface PracticeToolbarProps {
  resourceIds: number[];
  interactive?: boolean;
  state: PracticeResourceState;
  answered: boolean;
  onClick: (action: PracticeActionEnum) => void;
}

const PracticeToolbar = (props: PracticeToolbarProps) => {
  const { t } = useTranslation();

  const { triggerEvent } = usePracticeContext();

  const onCheckAnswer = () => {
    props.onClick(PracticeActionEnum.checkAnswer);
  };

  const onTryAgain = () => {
    props.onClick(PracticeActionEnum.clearAnswer);
  };

  const onLoadNextQuestion = () => {
    triggerEvent(ExerciseEventEnum.exit_question, props.resourceIds);
  };

  return (
    <PracticeToolbarStyle
      className={`step-by-step-toolbar ${props.state.toLowerCase()}-state`}
    >
      {props.interactive ? (
        <>
          {props.state === PracticeResourceState.IN_PROGRESS &&
            props.answered && (
              <LessonButton
                type={'primary'}
                shape={'round'}
                onClick={onCheckAnswer}
                icon={IconUtils.exercise.check_answer}
              >
                {t('exercise.button.check_answer')}
              </LessonButton>
            )}

          {props.state === PracticeResourceState.IN_PROGRESS_INCORRECT && (
            <LessonButton
              type={'primary'}
              shape={'round'}
              danger={true}
              onClick={onTryAgain}
              icon={IconUtils.exercise.try_again}
            >
              {t('exercise.button.try_again')}
            </LessonButton>
          )}

          {props.state === PracticeResourceState.COMPLETED_CORRECT && (
            <NextQuestionNav onClick={onLoadNextQuestion} />
          )}

          {props.state === PracticeResourceState.COMPLETED_INCORRECT && (
            <NextQuestionNav onClick={onLoadNextQuestion} />
          )}

          {props.state === PracticeResourceState.EXIT && (
            <ReviewExerciseButton />
          )}
        </>
      ) : (
        <NextQuestionNav />
      )}
    </PracticeToolbarStyle>
  );
};

export default PracticeToolbar;

const PracticeToolbarStyle = styled.div``;
