import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import { WordPuzzleSettingProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { useTranslation } from 'react-i18next';

export const WordPuzzleSetting = (props: {
  sourceItems: GlossaryGameContent[];
  targetItems: GlossaryGameContent[];
  setting: WordPuzzleSettingProps;
  onSettingChange: (newSetting: WordPuzzleSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const [minRow, setMinRow] = useState(0);
  const [minColumn, setMinColumn] = useState(0);

  useEffect(() => {
    const maxWord = props.sourceItems.reduce((max, word) => {
      return word.word.data.trim().length > max
        ? word.word.data.trim().length
        : max;
    }, 0);
    setMinColumn(maxWord);
  }, [JSON.stringify(props.sourceItems)]);

  useEffect(() => {
    const maxWord = props.targetItems.reduce((max, word) => {
      return word.word.data.trim().length > max
        ? word.word.data.trim().length
        : max;
    }, 0);
    setMinRow(maxWord);
  }, [JSON.stringify(props.targetItems)]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={'middle'} value={'middle'}>
              {t('component.glossary_word.middle_content')}
            </Select.Option>

            <Select.Option key={'top'} value={'top'}>
              {t('component.glossary_word.top_content')}
            </Select.Option>

            <Select.Option key={'bottom'} value={'bottom'}>
              {t('component.glossary_word.bottom_content')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Input
          name="maxRows"
          type="number"
          label={t('component.label.number_row')}
          placeholder={t('component.label.number_row')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.maxRows}
          min={minRow}
          max={30}
        />

        <Input
          name="maxColumns"
          type="number"
          label={t('component.label.number_column')}
          placeholder={t('component.label.number_column')}
          onBlur={formik.handleChange}
          defaultValue={formik.values.maxColumns}
          min={minColumn}
          max={30}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
