import styled from 'styled-components';

export const EntityStatusStyle = styled.span`
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    min-width: 10px;
    border-radius: 100%;
    margin-right: 0.75ch;
  }

  &.active-status {
    color: ${(props) => props.theme.status.activated};

    &:before {
      background: ${(props) => props.theme.status.activated};
    }
  }

  &.inactive-status {
    color: ${(props) => props.theme.status.inactivated};

    &:before {
      background: ${(props) => props.theme.status.inactivated};
    }
  }

  &.pending-status {
    color: ${(props) => props.theme.status.pending};

    &:before {
      background: ${(props) => props.theme.status.pending};
    }
  }

  &.published-status {
    color: ${(props) => props.theme.status.published};

    &:before {
      background: ${(props) => props.theme.status.published};
    }
  }

  &.removed-status {
    color: ${(props) => props.theme.status.removed};

    &:before {
      background: ${(props) => props.theme.status.removed};
    }
  }

  &.empty-status {
    color: ${(props) => props.theme.status.empty};
    &:before {
      background: ${(props) => props.theme.status.empty};
    }
  }
`;
