import { ClassAPI } from '@modules/users/services/ClassAPI';
import React, { useEffect, useState } from 'react';
import { ClassFilterReq } from '@modules/users/services/clazz';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { ResponsiveScreen } from '@hooks/useMedia';
import TablePagination from '@components/table/TablePagination';
import {
  SessionRes,
  SessionStudentRes,
  SessionUserHistoryRes,
} from '@services/model/session';
import Card, { CardClassEnum } from '@components/card';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { SiteMap } from '@router/SiteMap';
import { Button } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import NavLink from '@components/button/NavLink';

export const ClassStudentSession = (props: { classId: number }) => {
  const { t } = useTranslation();

  const [getStudentSessionHistory, { data, isFetching }] =
    ClassAPI.endpoints.getStudentSessionHistory.useLazyQuery({});

  const [params, setParams] = useState<ClassFilterReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    classId: props.classId,
    selectAll: true,
    sort: 'session.startDate',
    direction: 'ascend',
  });

  useEffect(() => {
    getStudentSessionHistory(params);
  }, [params]);

  const columns = [
    {
      title: 'Session',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },

    {
      title: 'Start Time',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: 'Join Time',
      dataIndex: 'joinDate',
      key: 'joinDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: 'Left Time',
      dataIndex: 'lastJoinDate',
      key: 'lastJoinDate',
      sorter: true,
      align: 'center',
      width: 200,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 200,
    },
  ];

  const processDataRow = (item: SessionUserHistoryRes) => {
    return {
      key: item.history.sessionStudentId,
      name: item.session.name,

      startDate: <DateAndTimeLabel label={item.session.startDate} />,
      joinDate: <DateAndTimeLabel label={item.history.joinDate} />,
      lastJoinDate: <DateAndTimeLabel label={item.history.lastJoinDate} />,

      actions: (
        <NavLink
          href={SiteMap.class_room.class_room_gen(
            item.session.code,
            item.session.publicCode,
            'calendar'
          )}
        >
          <Button
            type="primary"
            key="join"
            shape={'round'}
            icon={IconUtils.class_room.join_classroom}
          >
            {t('class_room.actions.join')}
          </Button>
        </NavLink>
      ),

      session: item.session,
      history: item.history,
    };
  };

  const renderMobile = (item: SessionUserHistoryRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        {JSON.stringify(item)}
      </Card>
    );
  };

  const onParamsChange = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...prev, ...params };
    });
  };

  const getRowClassName = (record: {
    session: SessionRes;
    history: SessionStudentRes;
  }) => {
    const isPass = record.session.unlockInMs < 0;
    if (isPass) {
      return record.history.joinDate != null ? 'joined' : 'error-row';
    } else {
      return '';
    }
  };

  return (
    <div className={'class-student-session'}>
      <HeaderGroup className={'mt-0'}>
        <H1>Class Session</H1>
      </HeaderGroup>

      <p>Class Session hiển thị các buổi học online diễn ra trong lớp.</p>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={onParamsChange}
        processDataRow={processDataRow}
        rowClassName={getRowClassName}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </div>
  );

  //
};
