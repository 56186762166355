import EmptyLayout from '@components/Layout/EmptyLayout';
import React from 'react';
import { useRouter } from '@hooks/useRouter';
import {
  LoginComp,
  LoginOauthReq,
  LogoutComp,
} from '@modules/authorization/components/LogoutComp';

const SchoolLaunch = () => {
  const router = useRouter();
  const data = router.query as unknown as LoginOauthReq;

  return (
    <EmptyLayout>
      {data.error ? <LogoutComp /> : <LoginComp data={data} />}
    </EmptyLayout>
  );
};

export default SchoolLaunch;
