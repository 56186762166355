import React from 'react';

import { Button } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

export default function ActiveAccountForm(props: {
  loading: boolean;
  email: string;
  onSubmit: (newPw: string) => void;
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      password: '',
      repeatPassword: '',
    },

    validationSchema: yup.object().shape({
      password: yup.string().required(t('registration.required.password')),
      repeatPassword: yup
        .string()
        .required(t('registration.required.repeat_password'))
        .oneOf([yup.ref('password')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      props.onSubmit(values.password);
    },
  });

  return (
    <FormikProvider value={formik}>
      <div className="mb-3">
        <div className={'ant-form-label'}>{t('label.email')}:</div>

        <div className={'ant-form-controls'}>
          <h3>{props.email}</h3>
        </div>
      </div>

      <Input
        id="password"
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('form.enter_password')}
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <Input
        id="repeatPassword"
        name="repeatPassword"
        type="password"
        label={t('label.password_again')}
        placeholder={t('form.enter_password_again')}
        onChange={formik.handleChange}
        value={formik.values.repeatPassword}
      />

      <p className={'hint-text'}>* {t('alert.saveYourEmail')}</p>

      <div className="submit-container">
        <ButtonGroup>
          <Button
            type={'primary'}
            loading={props.loading}
            onClick={formik.submitForm}
          >
            {t('profile.actions.change_password')}
          </Button>

          <NavLink href={SiteMap.index}>
            <Button type="default">{t('button.back')}</Button>
          </NavLink>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
}
