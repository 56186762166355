import { useTranslation } from 'react-i18next';

import { useProUser } from '@components/button/RequireProUserButton';
import { useSecurity } from '@utils/authorization';

import React, { useMemo } from 'react';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import { RoleEnum } from '@app/redux/slices/roles';
import {
  AppstoreAddOutlined,
  AuditOutlined,
  BarsOutlined,
  CloudServerOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  DollarOutlined,
  ExceptionOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  ReadOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { updateSelectMenu } from '@app/redux/slices/appSlice';
import styled from 'styled-components';
import { Menu, MenuProps } from 'antd';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useDispatch, useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';

const NavigationMenu = (props: { onClick?: () => void }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const proUser = useProUser();

  const { ifAnyGranted } = useSecurity();

  const { isAuth, authorities } = useSelector((state) => state.profile);
  const { menu, submenu } = useSelector((state) => state.appState);

  const getStudentMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const my_calendar = getMenuItem({
      key: NavigationMenuEnum.CALENDAR,
      label: (
        <NavLink href={SiteMap.private.my_calendar}>
          {t('menu.my_calendar')}
        </NavLink>
      ),
      icon: IconUtils.menu.calendar,
    });

    const my_assignment = getMenuItem({
      key: NavigationMenuEnum.ASSIGNMENTS,
      label: (
        <NavLink href={SiteMap.private.my_assignment.list}>
          {t('menu.my_assignments')}
        </NavLink>
      ),
      icon: IconUtils.menu.assignment,
    });

    const my_product = getMenuItem({
      key: NavigationMenuEnum.LIBRARY,
      label: (
        <NavLink href={SiteMap.private.my_library.products}>
          {t('menu.my_products')}
        </NavLink>
      ),
      icon: IconUtils.menu.products,
    });

    const my_collection = getMenuItem({
      key: NavigationMenuEnum.MY_LESSON,
      label: (
        <NavLink href={SiteMap.private.my_lesson.list}>
          {t('menu.my_lesson')}
        </NavLink>
      ),
      icon: IconUtils.menu.collection,
    });

    const my_class = getMenuItem({
      key: NavigationMenuEnum.MY_CLASS,
      label: (
        <NavLink href={SiteMap.private.my_class.list}>
          {t('menu.my_class')}
        </NavLink>
      ),
      icon: IconUtils.menu.class,
    });

    if (ifAnyGranted([RoleEnum.STUDENT])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(my_assignment);
      menuItems.push(my_collection);
      menuItems.push(my_product);
      menuItems.push(my_class);
    } else if (ifAnyGranted([RoleEnum.PARENT])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(my_assignment);
      menuItems.push(my_product);
      menuItems.push(my_class);
    }

    return menuItems;
  };

  const getAdminMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const exampleMenu = getItem(
      t('menu.lesson_example'),
      NavigationMenuEnum.LESSON_EXAMPLE,
      <FileTextOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.content.example.collection}>
            {t('menu.lesson_example_collection')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_COLLECTION,
          <FileTextOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.content.example.lesson}>
            {t('menu.lesson_example_lesson')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_LESSON,
          <FileTextOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.content.example.page}>
            {t('menu.lesson_example_page')}
          </NavLink>,
          NavigationMenuEnum.LESSON_EXAMPLE_PAGE,
          <FileTextOutlined />
        ),
      ]
    );

    const adminProductMenu = getItem(
      t('menu.my_resource'),
      NavigationMenuEnum.PRODUCTS,
      <ReadOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.content.metadata.lesson_type}>
            {t('menu.lesson_type')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_LESSON_TYPE,
          <AuditOutlined />
        ),

        getItem(
          <NavLink href={SiteMap.content.metadata.lesson_template}>
            {t('menu.lesson_template')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE,
          <FileTextOutlined />
        ),

        {
          type: 'divider', // Must have
        },

        getItem(
          <NavLink href={SiteMap.content.resource.list}>
            {t('menu.my_resource')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_RESOURCE,
          <AppstoreAddOutlined />
        ),

        getItem(
          <NavLink href={SiteMap.content.resource.assets}>
            {t('menu.my_assets_files')}
          </NavLink>,
          NavigationMenuEnum.PRODUCTS_MY_ASSETS,
          <CloudServerOutlined />
        ),
      ]
    );

    const oganizationMenu = getItem(
      t('menu.school'),
      NavigationMenuEnum.ORGANIZATION,
      <HomeOutlined />,
      [
        getItem(
          <NavLink href={SiteMap.management.school.list}>
            {t('menu.school')}
          </NavLink>,
          NavigationMenuEnum.ORGANIZATION_SCHOOL,
          <HomeOutlined />
        ),
      ]
    );

    const settingMenu = getItem(
      t('menu.setting'),
      NavigationMenuEnum.SETTING,
      IconUtils.menu.setting,
      [
        getItem(
          <NavLink href={SiteMap.management.setting.language}>
            {t('menu.language')}
          </NavLink>,
          NavigationMenuEnum.SETTING_LANGUAGE,
          <GlobalOutlined />
        ),

        {
          type: 'divider', // Must have
        },

        getItem(
          <NavLink href={SiteMap.management.setting.currency}>
            {t('menu.payment_currency')}
          </NavLink>,
          NavigationMenuEnum.SETTING_PAYMENT_CURRENCY,
          <DollarOutlined />
        ),
        getItem(
          <NavLink href={SiteMap.management.setting.payment_method}>
            {t('menu.payment_method')}
          </NavLink>,
          NavigationMenuEnum.SETTING_PAYMENT_METHOD,
          <CreditCardOutlined />
        ),
      ]
    );

    menuItems.push(my_dashboard);
    menuItems.push(exampleMenu);
    menuItems.push(adminProductMenu);
    menuItems.push(oganizationMenu);
    menuItems.push(settingMenu);

    return menuItems;
  };

  const getTeacherMenu = (): MenuItem[] => {
    const menuItems: MenuItem[] | any[] = [];

    const my_dashboard = getMenuItem({
      key: NavigationMenuEnum.DASHBOARD,
      label: (
        <NavLink href={SiteMap.private.dashboard}>
          {t('menu.dashboard')}
        </NavLink>
      ),
      icon: IconUtils.menu.dashboard,
    });

    const my_calendar = getMenuItem({
      key: NavigationMenuEnum.CALENDAR,
      label: (
        <NavLink href={SiteMap.private.my_calendar}>
          {t('menu.my_calendar')}
        </NavLink>
      ),
      icon: IconUtils.menu.calendar,
    });

    const assignment = getMenuItem({
      key: NavigationMenuEnum.ASSIGNMENTS,
      label: (
        <NavLink href={SiteMap.assignments.list}>
          {t('menu.my_assignments')}
        </NavLink>
      ),
      icon: IconUtils.menu.assignment,
    });

    if (ifAnyGranted([RoleEnum.TEACHER])) {
      const my_collection = getMenuItem({
        key: NavigationMenuEnum.MY_LESSON,
        label: (
          <NavLink href={SiteMap.private.my_lesson.list}>
            {t('menu.my_lesson')}
          </NavLink>
        ),
        icon: IconUtils.menu.collection,
      });

      const my_product = getMenuItem({
        key: NavigationMenuEnum.LIBRARY,
        label: (
          <NavLink href={SiteMap.private.my_library.products}>
            {t('menu.my_products')}
          </NavLink>
        ),
        icon: IconUtils.menu.products,
      });

      const my_class = getMenuItem({
        key: NavigationMenuEnum.MY_CLASS,
        label: (
          <NavLink href={SiteMap.private.my_class.list}>
            {t('menu.my_class')}
          </NavLink>
        ),
        icon: IconUtils.menu.class,
      });

      const my_students = getMenuItem({
        key: NavigationMenuEnum.USERS_STUDENTS,
        label: (
          <NavLink href={SiteMap.management.students.path}>
            {t('menu.students_users')}
          </NavLink>
        ),
        icon: IconUtils.menu.user,
        visible: ifAnyGranted(SiteMap.management.students.roles),
      });

      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(assignment);

      menuItems.push({
        type: 'divider', // Must have
      });
      menuItems.push(my_product);
      menuItems.push(my_collection);

      menuItems.push(my_class);
      menuItems.push(my_students);
    } else if (ifAnyGranted([RoleEnum.SUPPORT])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(assignment);
      menuItems.push({
        type: 'divider', // Must have
      });
    } else if (ifAnyGranted([RoleEnum.SCHOOL])) {
      menuItems.push(my_dashboard);
      menuItems.push(my_calendar);
      menuItems.push(assignment);
      menuItems.push({
        type: 'divider', // Must have
      });
    }

    return menuItems;
  };

  const items: MenuItem[] = useMemo(() => {
    let menuItems: MenuItem[] | any[] = [];
    // first  group
    if (ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT])) {
      menuItems = getStudentMenu();
    } else if (ifAnyGranted([RoleEnum.ADMIN])) {
      menuItems = getAdminMenu();
    } else {
      menuItems = getTeacherMenu();
    }
    // add first group menu....

    const courseMenu = getMenuItem({
      key: NavigationMenuEnum.COURSES,
      label: t('menu.my_courses'),
      icon: IconUtils.menu.course,
      children: [
        {
          key: NavigationMenuEnum.COURSES_COURSES,
          label: (
            <NavLink href={SiteMap.management.courses.path}>
              {t('menu.my_courses')}
            </NavLink>
          ),
          icon: IconUtils.menu.course,
          visible: ifAnyGranted(SiteMap.management.courses.roles),
        },

        {
          key: NavigationMenuEnum.COURSES_REQUESTS,
          label: (
            <NavLink href={SiteMap.management.courses.request}>
              {t('menu.course_requests')}
            </NavLink>
          ),
          icon: <WhatsAppOutlined />,
          visible: ifAnyGranted(SiteMap.management.courses.roles),
        },

        {
          key: NavigationMenuEnum.COURSES_SURVEY,
          label: (
            <NavLink href={SiteMap.survey.list}>{t('menu.survey')}</NavLink>
          ),
          icon: IconUtils.menu.survey,
          visible: ifAnyGranted(SiteMap.survey.roles),
        },
      ],
    });
    menuItems.push(courseMenu);

    const usersMenu = getMenuItem({
      key: NavigationMenuEnum.USERS,
      label: t('menu.users'),
      icon: IconUtils.menu.user,
      children: [
        {
          key: NavigationMenuEnum.USERS_CLASSES,
          label: (
            <NavLink href={SiteMap.management.classes.path}>
              {t('menu.my_classes')}
            </NavLink>
          ),
          icon: IconUtils.menu.class,
          visible: ifAnyGranted(SiteMap.management.classes.roles),
        },
        {
          key: NavigationMenuEnum.USERS_STUDENTS,
          label: (
            <NavLink href={SiteMap.management.students.path}>
              {t('menu.students_users')}
            </NavLink>
          ),
          icon: IconUtils.menu.user,
          visible: ifAnyGranted(SiteMap.management.students.roles),
        },

        {
          key: NavigationMenuEnum.USERS_PARENTS,
          label: (
            <NavLink href={SiteMap.management.parents.path}>
              {t('menu.parents_users')}
            </NavLink>
          ),
          icon: IconUtils.menu.user,
          visible: ifAnyGranted(SiteMap.management.parents.roles),
        },

        {
          key: NavigationMenuEnum.USERS_TEACHERS,
          label: (
            <NavLink href={SiteMap.management.teachers.path}>
              {t('menu.teachers_users')}
            </NavLink>
          ),
          icon: IconUtils.menu.user,
          visible: ifAnyGranted(SiteMap.management.teachers.roles),
        },

        {
          key: NavigationMenuEnum.USERS_SCHOOL_SUPPORT,
          label: (
            <NavLink href={SiteMap.management.supports.path}>
              {t('menu.school_support_users')}
            </NavLink>
          ),
          icon: IconUtils.menu.user,
          visible: ifAnyGranted(SiteMap.management.supports.roles),
        },

        {
          key: NavigationMenuEnum.USERS_SCHOOL_ADMIN,
          label: (
            <NavLink href={SiteMap.management.school_admins.path}>
              {t('menu.school_admin_users')}
            </NavLink>
          ),
          icon: IconUtils.menu.user,
          visible: ifAnyGranted(SiteMap.management.school_admins.roles),
        },
      ],
    });

    if (proUser) {
      menuItems.push(usersMenu);
    }

    const productMenu = getMenuItem({
      key: NavigationMenuEnum.PRODUCTS,
      label: t('menu.my_products'),
      icon: IconUtils.menu.library,
      children: [
        {
          key: NavigationMenuEnum.PRODUCTS_PRODUCT,
          label: (
            <NavLink href={SiteMap.content.product.list}>
              {t('menu.my_products')}
            </NavLink>
          ),
          icon: IconUtils.menu.products,
          visible: ifAnyGranted([
            RoleEnum.CREATE_PRODUCT,
            RoleEnum.BOOK_LICENSE,
            RoleEnum.PUBLISH_LICENSE,
            RoleEnum.SCHOOL,
          ]),
        },

        {
          key: NavigationMenuEnum.PRODUCTS_LESSON,
          label: (
            <NavLink href={SiteMap.content.lesson.list}>
              {t('menu.my_lesson')}
            </NavLink>
          ),
          icon: IconUtils.menu.lesson,
          visible: ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
        },

        {
          key: NavigationMenuEnum.PRODUCTS_RESOURCE,
          label: (
            <NavLink href={SiteMap.content.resource.list}>
              {t('menu.my_resource')}
            </NavLink>
          ),
          icon: IconUtils.menu.resource,
          visible: ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
        },

        {
          key: NavigationMenuEnum.PRODUCTS_MY_STANDARD_SET,
          label: (
            <NavLink href={SiteMap.content.resource.standards_set}>
              {t('menu.my_standards_set')}
            </NavLink>
          ),
          icon: IconUtils.menu.standard,
          visible:
            proUser && ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
        },

        {
          key: NavigationMenuEnum.PRODUCTS_MY_ASSETS,
          label: (
            <NavLink href={SiteMap.content.resource.assets}>
              {t('menu.my_assets_files')}
            </NavLink>
          ),
          icon: IconUtils.menu.assets,

          visible:
            proUser && ifAnyGranted([RoleEnum.COMPOSE_LESSON, RoleEnum.SCHOOL]),
        },

        {
          key: NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE,
          label: (
            <NavLink href={SiteMap.content.metadata.lesson_template}>
              {t('menu.lesson_template')}
            </NavLink>
          ),
          icon: <FileTextOutlined />,
          visible:
            proUser &&
            ifAnyGranted([RoleEnum.CREATE_LESSON_TEMPLATE, RoleEnum.SCHOOL]),
        },
      ],
    });

    if (proUser) {
      menuItems.push(productMenu);
    }

    const licenseAndPaymentMenu = getMenuItem({
      key: NavigationMenuEnum.LICENSE,
      label: 'Licenses',
      icon: IconUtils.menu.license,
      visible: ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL]),
      children: [
        {
          key: NavigationMenuEnum.LICENSE_BOOKS,
          label: <NavLink href={SiteMap.license.book}>Licenses</NavLink>,
          icon: IconUtils.menu.license,
          visible: ifAnyGranted([RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL]),
        },
      ],
    });

    if (proUser) {
      menuItems.push(licenseAndPaymentMenu);
    }

    const schoolPageMenu = getMenuItem({
      key: NavigationMenuEnum.PAGES,
      icon: IconUtils.menu.pages,
      label: t('menu.pages'),
      children: [
        {
          key: NavigationMenuEnum.PAGES_STATIC_PAGE,
          label: (
            <NavLink href={SiteMap.page_content.static_page}>
              {t('menu.school_content')}
            </NavLink>
          ),
          icon: IconUtils.menu.pages,
          visible: ifAnyGranted(SiteMap.page_content.static_page_roles),
        },

        // {
        //   key: NavigationMenuEnum.PAGES_STATIC_PAGE_CONTENT,
        //   label: (
        //     <NavLink href={SiteMap.page_content.content}>
        //       {t('menu.school_content')}
        //     </NavLink>
        //   ),
        //   icon: IconUtils.menu.pages,
        //   visible: ifAnyGranted(SiteMap.page_content.content_roles),
        // },

        {
          key: NavigationMenuEnum.PAGES_PAGE_TEMPLATE,
          label: (
            <NavLink href={SiteMap.page_content.page_template}>
              {t('menu.page_template')}
            </NavLink>
          ),
          icon: IconUtils.menu.pages_template,
          visible: ifAnyGranted(SiteMap.page_content.publish_static_page_roles),
        },
      ],
    });
    menuItems.push(schoolPageMenu);

    const schoolProgram = getMenuItem({
      key: NavigationMenuEnum.PROGRAM,
      label: t('menu.program'),
      icon: IconUtils.menu.program,
      visible: ifAnyGranted([RoleEnum.SCHOOL]),
      children: [
        {
          key: NavigationMenuEnum.PROGRAM_PROGRAM,
          label: (
            <NavLink href={SiteMap.program.program}>
              {t('menu.program')}
            </NavLink>
          ),
          icon: IconUtils.menu.program,
          visible: ifAnyGranted(SiteMap.program.roles),
        },
        {
          key: NavigationMenuEnum.PROGRAM_CERTIFICATE,
          label: (
            <NavLink href={SiteMap.program.certificate}>
              {t('menu.certificate')}
            </NavLink>
          ),
          icon: IconUtils.menu.certificate,
          visible: ifAnyGranted(SiteMap.program.roles),
        },
        {
          key: NavigationMenuEnum.PROGRAM_SUBJECT,
          label: (
            <NavLink href={SiteMap.program.subjects}>
              {t('menu.subject')}
            </NavLink>
          ),
          icon: <BarsOutlined />,
          visible: ifAnyGranted(SiteMap.program.roles),
        },
        {
          key: NavigationMenuEnum.PROGRAM_CATEGORY,
          label: (
            <NavLink href={SiteMap.program.categories}>
              {t('menu.category')}
            </NavLink>
          ),
          icon: <DatabaseOutlined />,
          visible: ifAnyGranted(SiteMap.program.roles),
        },

        {
          key: NavigationMenuEnum.PROGRAM_GRADE,
          label: (
            <NavLink href={SiteMap.program.grades}>{t('menu.grade')}</NavLink>
          ),
          icon: <ExceptionOutlined />,
          visible: ifAnyGranted(SiteMap.program.roles),
        },
      ],
    });
    menuItems.push(schoolProgram);

    return menuItems;
  }, [authorities, i18next.language, proUser]);

  const handleOnChange = (params: any[]) => {
    dispatch(updateSelectMenu(params));
  };

  const handleOnSelect = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const selectedKeys = useMemo(() => {
    return [...menu, ...submenu];
  }, [menu, submenu]);

  if (isAuth) {
    return (
      <NavigationMenuStyle
        mode="inline"
        multiple={false}
        style={{ borderRight: 0 }}
        openKeys={menu}
        selectedKeys={selectedKeys}
        onSelect={handleOnSelect}
        onOpenChange={handleOnChange}
        items={items}
      />
    );
  } else {
    return null;
  }
};

export default NavigationMenu;

const NavigationMenuStyle = styled(Menu)`
  &.ant-menu {
    font-size: var(--default-menu-size, 16px);
    font-weight: 600;

    .ant-menu-item-icon {
      font-size: 20px;
    }

    .ant-menu-item {
      border-radius: 0.5em;
      height: auto;

      padding: 0.25em 16px !important;
      margin: 5px 0 !important;
      margin-right: 0px !important;
      width: 100% !important;

      > .ant-menu-title-content {
        font-size: var(--default-menu-size, 16px);
        font-weight: 600;
        line-height: 2.2;
      }

      &:hover,
      &:active {
        background: ${(props) => props.theme.menu.background};
        color: ${(props) => props.theme.menu.text};
      }

      &.ant-menu-item-active {
        background: ${(props) => props.theme.menu.background};
        color: ${(props) => props.theme.menu.text};
      }

      &.ant-menu-item-selected {
        background: ${(props) => props.theme.menu.active.background};
        color: ${(props) => props.theme.menu.active.text};

        &:after {
          border-right-color: ${(props) => props.theme.menu.active.text};
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        border-radius: 0.5em;
        height: auto;
        padding: 0.25em 16px !important;
        margin: 5px 0 5px 0 !important;
        width: 100% !important;

        .ant-menu-title-content {
          font-size: var(--default-menu-size, 16px);
          font-weight: 600;
          line-height: 2.2;
        }
      }

      .ant-menu {
        .ant-menu-item {
          .ant-menu-title-content {
            font-size: var(--default-submenu-size, 15px);
          }

          &:hover,
          &:active {
            background: ${(props) => props.theme.menu.active.background};
            color: ${(props) => props.theme.menu.active.text};
          }
        }
      }

      &.ant-menu-submenu-selected {
        .ant-menu-submenu-title {
        }

        .ant-menu {
          background: ${(props) => props.theme.menu.active.submenu_bgr};
          border-radius: 0.75em;
          padding: 0px 8px;

          .ant-menu-item {
            margin: 8px 0 8px 0 !important;
          }
        }
      }

      &.ant-menu-submenu-active {
        background: ${(props) => props.theme.menu.active.background};
        color: ${(props) => props.theme.menu.active.text};
      }
    }

    .ant-menu-item-divider {
      border-color: #cccccc;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  type?: 'group' | '',
  visible?: boolean
): MenuItem {
  if (visible == null || visible) {
    return {
      key,
      icon,
      children,
      label,
      type,
      visible,
    } as MenuItem;
  } else {
    return null;
  }
}

interface ItemMenuProps {
  key: React.Key;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: ItemMenuProps[] | null;
  visible?: boolean;
}

const getMenuItem = (props: ItemMenuProps): MenuItem => {
  if (props.visible == null || props.visible) {
    // check if has sub menu ---
    if (props.children != null && props.children.length > 0) {
      // filter visible children.
      const children = props.children.filter((child) => {
        return child.visible == null || child.visible;
      });

      // if multiple menu ->
      if (children.length > 1) {
        const childMenu = children.map((child) => {
          return getMenuItem(child);
        });

        return {
          key: props.key,
          icon: props.icon,
          label: props.label,
          children: childMenu,
        } as MenuItem;

        // if single menu -> replace menu by label = child label, icon = child icon.
      } else if (children.length === 1) {
        const childMenu = children[0];
        return {
          key: props.key,
          icon: childMenu.icon,
          label: childMenu.label,
        } as MenuItem;

        // if  empty => didn't show
      } else {
        return null;
      }
    } else {
      return {
        key: props.key,
        icon: props.icon,
        label: props.label,
      } as MenuItem;
    }
  } else {
    return null;
  }
};
