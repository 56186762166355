import React from 'react';
import { ToggleCorrectAnswer } from '../../buttons/compose/ToggleCorrectAnswerButton';
import { PrintLessonButton } from '../../buttons/compose/PrintLessonButton';
import { CreateAssignmentButton } from '@cms/lesson-template/components/buttons/compose/CreateAssignmentButton';
import { ExerciseToolbarStyle } from './style';
import { ExitExerciseButton } from '../../buttons/exercise/ExitExerciseButton';
import { FullscreenButton } from '@cms/lesson-template/components/buttons/compose/FullScreenButton';
import { CreateSurveyButton } from '@cms/lesson-template/components/buttons/compose/CreateSurveyButton';

export const ReviewLessonToolbar = () => {
  return (
    <ExerciseToolbarStyle className={'lesson-toolbar review-lesson-toolbar'}>
      <div className={'left-group'}>
        <ExitExerciseButton />
      </div>

      <div className={'right-group'}>
        <FullscreenButton />
        <ToggleCorrectAnswer />
        <PrintLessonButton />
        <CreateAssignmentButton />
        <CreateSurveyButton />
      </div>
    </ExerciseToolbarStyle>
  );
};
