import { useEffect } from 'react';

import {
  logout,
  changeToLogout,
  getCurrentData,
  login,
  logoutAllTabs,
  relogin,
} from '@app/redux/slices/profileSlice';
import { MyProfileAPI } from '@services/private/MyProfileAPI';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import { useDispatch, useSelector } from '@app/redux/hook';

// require login : only visit page if user is logon. If not -> redirect to Login page.
// access_token: if access_token is provided, we should load login data. The login return

const useLogin = (requireLogin?: boolean, accessToken?: string) => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.profile);

  const [getLoginData, { data, isSuccess, error, isError }] =
    MyProfileAPI.endpoints.getLoginData.useLazyQuery();

  useEffect(() => {
    logoutAllTabs();
  }, []);

  useEffect(() => {
    if (!isAuth) {
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        getLoginData({});
      } else if (localStorage.getItem('accessToken')) {
        const request = getCurrentData();
        if (request.uuid) {
          dispatch(relogin(request));
        }
      }
    }
  }, [isAuth, accessToken]);

  useEffect(() => {
    if (data && isSuccess) {
      const payload = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        uuid: data.uuid,
        roles: data.roles,
        schoolId: data.schoolId,
      };

      dispatch(login(payload));
      dispatch(refreshExpiredData(data.expires_in));
    }
  }, [data]);

  useEffect(() => {
    if (error && isError) {
      if (requireLogin) {
        dispatch(logout());
      } else {
        dispatch(changeToLogout());
      }
    }
  }, [error]);
};

export default useLogin;
