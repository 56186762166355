import React, { useState } from 'react';

import { Divider, Space, Tooltip } from 'antd';
import {
  AppstoreAddOutlined,
  ControlOutlined,
  PicLeftOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import UpdateLessonDataModal, {
  UpdateLessonDataEnum,
} from '@modules/product/components/lesson/lesson-data/LessonDataModal';
import useConfirmModal from '@components/modal/ConfirmModal';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { RequireProUser } from '@components/button/RequireProUserButton';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { IconUtils } from '@utils/IconUtils';
import { ComposeToolbarStyle } from '../layout';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { ExitComposeBtn } from '@cms/lesson-template/compose/buttons/ExitComposeBtn';
import { EditResourceJSON } from '@cms/lesson-template/compose/resource/EditResourceJSON';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useRouter } from '@hooks/useRouter';
import { useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';

const ComposeResourceToolbar = (props: { type?: LessonTypeEnum }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { confirm } = useConfirmModal();

  const { changed, originalResource } = useSelector(
    (state) => state.composeResource
  );

  const { loading } = useLessonServiceContext();

  const {
    lessonId,
    productId,
    productTocId,
    editMode,
    dispatchAction,
    mode,
    changeToMode,
  } = useLessonEditableContext();

  const [action, setAction] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  const handleSaveQuestion = () => {
    dispatchAction(LessonEditableActionEnum.save_resource, -1);
  };

  const confirmChange = (callBackFunc: () => void) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('resource.warning.exit_without_saving'),
      t('label.quit'),
      t('label.cancel'),
      (r) => {
        if (r) {
          callBackFunc();
        }
      }
    );
  };

  const goToBuildSection = () => {
    if (editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(() => {
        router.push(
          SiteMap.content.lesson.build_section_gen(
            lessonId,
            productId,
            productTocId
          )
        );
      });
    } else {
      router.push(
        SiteMap.content.lesson.build_section_gen(
          lessonId,
          productId,
          productTocId
        )
      );
    }
  };

  const goToBuildLesson = () => {
    if (editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(() => {
        router.push(
          SiteMap.content.lesson.build_resource_gen(
            lessonId,
            productId,
            productTocId
          )
        );
      });
    } else {
      router.push(
        SiteMap.content.lesson.build_resource_gen(
          lessonId,
          productId,
          productTocId
        )
      );
    }
  };

  const resetAllActions = () => {
    setAction(UpdateLessonDataEnum.DEFAULT);
  };

  return (
    <>
      <ComposeToolbarStyle className={'lesson-toolbar'}>
        <div className={'toolbar-group left-group'}>
          <ExitComposeBtn />

          <Divider type={'vertical'} />

          {editMode === EditModeEnum.MULTIPLE_QUESTION && (
            <Tooltip
              title={t('lesson_compose.warning.show_components')}
              placement={'topLeft'}
            >
              <LessonButton
                type={mode === LessonMode.edit ? 'default' : 'primary'}
                shape={'round'}
                className={'action-button'}
                onClick={() => changeToMode(LessonMode.edit)}
                icon={IconUtils.compose.component_banks}
              >
                {t('lesson_compose.actions.show_components')}
              </LessonButton>
            </Tooltip>
          )}

          {lessonId && lessonId > 0 && (
            <Authorization
              roles={[
                RoleEnum.TEACHER,
                RoleEnum.SUPPORT,
                RoleEnum.SCHOOL,
                RoleEnum.ADMIN,
              ]}
              type={'ifAnyGranted'}
            >
              <Tooltip
                title={t('lesson_compose.actions.metadata')}
                placement={'topLeft'}
              >
                <LessonButton
                  type={'default'}
                  shape={'circle'}
                  className={'action-button'}
                  onClick={() => setAction(UpdateLessonDataEnum.METADATA)}
                  icon={IconUtils.actions.view_info}
                />
              </Tooltip>

              <Tooltip
                title={t('lesson_compose.actions.review')}
                placement={'topLeft'}
              >
                <LessonButton
                  type={'default'}
                  shape={'circle'}
                  className={'action-button'}
                  onClick={() => setAction(UpdateLessonDataEnum.REVIEW)}
                  icon={IconUtils.lesson.review}
                />
              </Tooltip>
            </Authorization>
          )}

          <RequireProUser>
            <Tooltip
              title={t('lesson_compose.actions.view_json')}
              placement={'topLeft'}
            >
              <LessonButton
                type={'default'}
                shape={'circle'}
                className={'action-button'}
                onClick={() => setAction(UpdateLessonDataEnum.EXTRACT)}
                icon={<ControlOutlined />}
              />
            </Tooltip>
          </RequireProUser>

          <RequireProUser>
            <Tooltip
              title={t('lesson_compose.actions.build')}
              placement={'topLeft'}
            >
              <LessonButton
                type={'default'}
                shape={'circle'}
                className={'action-button'}
                onClick={goToBuildLesson}
              >
                <AppstoreAddOutlined />
              </LessonButton>
            </Tooltip>
          </RequireProUser>

          <RequireProUser>
            <Tooltip
              title={t('lesson_compose.actions.section')}
              placement={'topLeft'}
            >
              <LessonButton
                type={'default'}
                shape={'circle'}
                className={'action-button'}
                onClick={goToBuildSection}
              >
                <PicLeftOutlined />
              </LessonButton>
            </Tooltip>
          </RequireProUser>
        </div>

        <div className={'toolbar-group right-group'}>
          <Space>
            {changed.changed && (
              <Tooltip title={t('resource.warning.update_question_content')}>
                <LessonButton
                  shape={'round'}
                  type={'primary'}
                  onClick={handleSaveQuestion}
                  icon={IconUtils.actions.save}
                  loading={loading}
                >
                  {originalResource && originalResource.resourceId > 0 ? (
                    <>{t('button.update')}</>
                  ) : (
                    <>{t('button.create')}</>
                  )}
                </LessonButton>
              </Tooltip>
            )}
          </Space>
        </div>
      </ComposeToolbarStyle>

      {(action === UpdateLessonDataEnum.METADATA ||
        action === UpdateLessonDataEnum.STANDARDS) && (
        <CustomModal
          header={t('lesson_compose.actions.metadata')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <UpdateLessonDataModal
              type={'edit'}
              lessonType={props.type}
              action={action}
              lessonId={lessonId}
              onCancel={resetAllActions}
              onChange={(mode) => {
                setAction(mode);
              }}
            />
          }
          onCloseFunc={resetAllActions}
        />
      )}

      {action === UpdateLessonDataEnum.REVIEW && (
        <CustomModal
          header={t('lesson.actions.view')}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={lessonId} />}
          onCloseFunc={resetAllActions}
        />
      )}

      {action === UpdateLessonDataEnum.EXTRACT && (
        <CustomModal
          header={t('lesson_compose.actions.view_resource_data')}
          className={CustomModalClassEnum.full_size_modal}
          content={<EditResourceJSON onClose={resetAllActions} />}
          onCloseFunc={resetAllActions}
        />
      )}
    </>
  );
};

export default ComposeResourceToolbar;
