import styled from 'styled-components';
import {
  ComponentDragNDropSkeleton,
  ComponentFibSkeleton,
  ComponentSkeleton,
} from '@cms/design-template/lesson-placeholder/ComponentSkeleton';
import { SkeletonParagraph } from '@cms/design-template/lesson-placeholder/common';
import React from 'react';

export const QuestionSkeleton = (props: { questionNumber: number }) => {
  return (
    <QuestionSkeletonStyle className={'question-skeleton-item'}>
      <div className={'question-number'}>{props.questionNumber}.&nbsp;</div>

      <div className={'question-content'}>
        <div className={'component-skeleton-item component-direction'}>
          <SkeletonParagraph />
        </div>

        <ComponentSkeleton />
      </div>
    </QuestionSkeletonStyle>
  );
};

export const Question2Skeleton = (props: { questionNumber: number }) => {
  return (
    <QuestionSkeletonStyle className={'question-skeleton-item'}>
      <div className={'question-number'}>{props.questionNumber}.&nbsp;</div>

      <div className={'question-content'}>
        <div className={'component-skeleton-item component-direction'}>
          <SkeletonParagraph />
        </div>

        <ComponentDragNDropSkeleton />
      </div>
    </QuestionSkeletonStyle>
  );
};

export const Question3Skeleton = (props: { questionNumber: number }) => {
  return (
    <QuestionSkeletonStyle className={'question-skeleton-item'}>
      <div className={'question-number'}>{props.questionNumber}.&nbsp;</div>

      <div className={'question-content'}>
        <div className={'component-skeleton-item component-direction'}>
          <SkeletonParagraph />
        </div>

        <ComponentFibSkeleton />
      </div>
    </QuestionSkeletonStyle>
  );
};

const QuestionSkeletonStyle = styled.div`
  display: flex;
  align-items: baseline;
  border: 1px dotted #ccc;
  background: ${(props) => props.theme.app.primary_bgr};

  .question-number {
    min-width: 3em;
    text-align: right;
    font-weight: bold;
  }

  .question-content {
    flex-grow: 1;
  }

  .component-skeleton-item {
    width: 100%;
    border: 2px solid ${(props) => props.theme.warning.warning};
    background: #fff;

    &:not(:first-child) {
      margin-top: var(--cms-padding-component, 0.5em);
    }
  }
`;
