import styled from 'styled-components';
import React from 'react';
import { SkeletonParagraph } from '@cms/design-template/lesson-placeholder/common';

export const ComponentFibSkeleton = () => {
  return (
    <ComponentFibSkeletonStyle className={'component-skeleton-item'}>
      <div className={'textarea-box'}>Enter your answer here...</div>
    </ComponentFibSkeletonStyle>
  );
};

export const ComponentDragNDropSkeleton = () => {
  return (
    <ComponentDragNDropSkeletonStyle className={'component-skeleton-item'}>
      <div className={'drag-options'}>
        <div className={'component-option'}>Option 1</div>

        <div className={'component-option'}>Option 2</div>

        <div className={'component-option'}>Option 3</div>
      </div>

      <div className={'drop-options'}>
        <div className={'component-option'}>
          <div className={'drop-header'}>Group 1</div>
          <div className={'drop-box'}>Drop your answer here...</div>
        </div>

        <div className={'component-option'}>
          <div className={'drop-header'}>Group 2</div>

          <div className={'drop-box'}>Drop your answer here...</div>
        </div>

        <div className={'component-option'}>
          <div className={'drop-header'}>Group 3</div>

          <div className={'drop-box'}>Drop your answer here...</div>
        </div>
      </div>
    </ComponentDragNDropSkeletonStyle>
  );
};

export const ComponentSkeleton = () => {
  return (
    <ComponentSkeletonStyle className={'component-skeleton-item'}>
      <div className={'component-options'}>
        <div className={'component-option'}>
          <div className={'component-option-label'}>a.&nbsp;</div>
          <div className={'component-option-content'}>
            <SkeletonParagraph />
          </div>
        </div>

        <div className={'component-option'}>
          <div className={'component-option-label'}>b.&nbsp;</div>
          <div className={'component-option-content'}>
            <SkeletonParagraph />
          </div>
        </div>

        <div className={'component-option'}>
          <div className={'component-option-label'}>c.&nbsp;</div>
          <div className={'component-option-content'}>
            <SkeletonParagraph />
          </div>
        </div>
      </div>
    </ComponentSkeletonStyle>
  );
};

const ComponentSkeletonStyle = styled.div`
  .component-direction {
    width: 100%;
  }

  .component-option {
    display: flex;
    margin-top: var(--cms-padding-option, 0.5em);

    .component-option-label {
      min-width: 3em;
      text-align: right;
    }

    .component-option-content {
      flex-grow: 1;
      max-width: 66%;
    }
  }
`;

const ComponentFibSkeletonStyle = styled.div`
  .textarea-box {
    min-height: 4em;
    background: rgba(190, 190, 190, 0.2);
    padding: 0.5em;
    font-size: 80%;
    color: ${(props) => props.theme.component.disabled};
  }
`;

const ComponentDragNDropSkeletonStyle = styled.div`
  .drag-options {
    width: 100%;
    text-align: center;

    .component-option {
      display: inline-flex;
      margin-right: 0.5em;
      border: 1px solid #ccc;
      padding: 0.125em 0.5em;
    }
  }

  .drag-options,
  .drop-options {
    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }
  }

  .drop-options {
    display: flex;
    gap: 1em;

    .component-option {
      width: calc(33% - 1em);
    }

    .drop-header {
      font-weight: bold;
    }

    .drop-box {
      min-height: 4em;
      background: rgba(190, 190, 190, 0.2);
      margin-top: 0.5em;
      padding: 0.5em;
      font-size: 80%;
      color: ${(props) => props.theme.component.disabled};
    }
  }
`;
