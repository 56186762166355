import styled from 'styled-components';

const ErrorBoxStyle = styled.div`
  padding: 43px 50px;
  max-width: 600px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  min-height: 400px;
  margin: 0 auto;
  flex-direction: column;
  text-align: center;
`;


const ErrorBox = (props: any) => {
  return (
    <ErrorBoxStyle>
      {props.children}
    </ErrorBoxStyle>
  );
};

export default ErrorBox;
