import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginLayout } from '@modules/authorization/layout/LoginLayout';
import Card, { CardClassEnum } from '@components/card';
import ForgotPasswordForm from '@modules/authorization/container/ForgotPasswordForm';

export default function ForgotPasswordPage(props: any) {
  const { t } = useTranslation();

  const handleSuccess = () => {
    // do nothing....
  };

  return (
    <LoginLayout>
      <h1 className={'text-center'}>{t('header.forgot_password')}</h1>

      <Card className={CardClassEnum.rectangle_box}>
        <p>{t('forgot_password.direction_2')}</p>

        <p>{t('forgot_password.direction_3')}</p>

        <ForgotPasswordForm successHandle={handleSuccess} />
      </Card>
    </LoginLayout>
  );
}
