import {
  CertificateRes,
  CertificateScoreRes,
  UserCertificateRes,
  UserCertificateScoreRes,
} from '@modules/admin/service/program';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';
import React, { useEffect, useState } from 'react';
import { TableStyle } from '@components/table/style';
import { Col, Row, Progress } from 'antd';
import styled from 'styled-components';
import { EntityStatusEnum } from '@services/model/common';
import { DateLabel } from '@components/text/DateLabel';
import { Default_Gutter } from '@components/grid';

import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { UserCertificateDisplay } from '@modules/admin/container/UserCertificateDisplay';
import ButtonGroup from '@components/button/ButtonGroup';

export const CertificateScoreTable = (props: {
  classId: number;
  item: CertificateRes;
}) => {
  const [findById, { data, isSuccess }] =
    CertificateAPI.endpoints.findById.useLazyQuery();

  const [getUserCertificate, { data: userCertificate }] =
    CertificateAPI.endpoints.getUserCertificate.useLazyQuery();

  const [items, setItems] = useState<CertificateScoreRes[]>([]);

  useEffect(() => {
    findById(props.item.certificateId);
  }, [props.item.certificateId]);

  useEffect(() => {
    getUserCertificate({
      classId: props.classId,
      certificateId: props.item.certificateId,
    });
  }, [props.classId, props.item.certificateId]);

  useEffect(() => {
    if (data && isSuccess && data.scores) {
      setItems(data.scores);
    }
  }, [data]);

  return (
    <UserCertificateScoreStyle className={'student-certificate-score-detail'}>
      {userCertificate &&
        userCertificate.map((score) => {
          return (
            <div className={'score-detail-table'} key={score.userCertificateId}>
              <StudentCertificateScoreTable items={items} score={score} />
            </div>
          );
        })}
    </UserCertificateScoreStyle>
  );
};

const UserCertificateScoreStyle = styled.div`
  .score-detail-table {
    margin-bottom: 1em;
  }

  .score-index,
  .score-score {
    text-align: center;
  }
`;

export const StudentCertificateScoreTable = (props: {
  items?: CertificateScoreRes[];
  score: UserCertificateRes;
}) => {
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  const [userScore, setUserScore] = useState<
    Record<number, UserCertificateScoreRes>
  >({});

  useEffect(() => {
    if (props.score) {
      const scoreData: Record<number, UserCertificateScoreRes> = {};

      props.score.scores.forEach((scr) => {
        scoreData[scr.certificateScore.certificateScoreId] = scr;
      });
      setUserScore(scoreData);
    }
  }, [props.score]);

  return (
    <>
      <TableStyle
        className={'score-setting-table'}
        cellSpacing={0}
        cellPadding={0}
      >
        <thead>
          <tr className={'score-setting-row'}>
            <th className={'score-index'} style={{ width: 50 }}>
              #
            </th>

            <th className={'score-name'}>Name</th>

            <th className={'score-type'} style={{ width: 200 }}>
              Type
            </th>

            <th className={'score-score'} style={{ width: 200 }}>
              Points
            </th>

            <th className={'score-score'} style={{ width: 250 }}>
              My Score
            </th>
          </tr>
        </thead>

        <tbody>
          {props.items != null && props.items.length > 0 ? (
            <>
              {props.items.map((scr, index) => {
                const score: UserCertificateScoreRes =
                  userScore[scr.certificateScoreId];

                return (
                  <ViewScoreSettingRow
                    key={scr.certificateScoreId}
                    index={index}
                    item={scr}
                    score={score}
                  />
                );
              })}
            </>
          ) : (
            <>
              {props.score.scores.map((scr, index) => {
                return (
                  <ViewScoreSettingRow
                    key={scr.certificateScore.certificateScoreId}
                    index={index}
                    item={scr.certificateScore}
                    score={scr}
                  />
                );
              })}
            </>
          )}
        </tbody>
      </TableStyle>

      {props.score.status === EntityStatusEnum.PUBLISHED && (
        <Row gutter={[Default_Gutter, Default_Gutter]} className={'mt-5'}>
          <Col flex={'auto'}>
            <div className={'certificate-info'}>
              Đã được cấp chứng chỉ vào ngày{' '}
              <DateLabel label={props.score.publishedDate} />{' '}
            </div>
          </Col>

          <Col flex={'180px'}>
            <Button
              block
              type={'primary'}
              icon={IconUtils.menu.certificate}
              shape={'round'}
              onClick={() => setAction('share-certificate')}
            >
              View Certificate
            </Button>
          </Col>
        </Row>
      )}

      {action === 'share-certificate' && (
        <CustomModal
          header={'Certificate'}
          className={CustomModalClassEnum.large_modal}
          content={
            <>
              <UserCertificateDisplay
                shareable={true}
                code={props.score.code}
              />

              <ButtonGroup className="submit-container" type={'center'}>
                <Button
                  type="default"
                  size={'large'}
                  shape={'round'}
                  onClick={() => setAction('')}
                >
                  {t('button.close')}
                </Button>
              </ButtonGroup>
            </>
          }
          onCloseFunc={() => setAction('')}
        />
      )}
    </>
  );
};

const ViewScoreSettingRow = (props: {
  index: number;
  item: CertificateScoreRes;
  score: UserCertificateScoreRes | undefined;
}) => {
  return (
    <tr className={'score-setting-row'}>
      <td className={'score-index'}>
        <b>{props.index + 1}</b>
      </td>

      <td className={'score-name'}>{props.item.name}</td>

      <td className={'score-type'}>
        {props.item.type === 'manual'
          ? 'Giáo viên tự chấm'
          : 'Kết quả kiểm tra'}
      </td>

      <td className={'score-score'}>{props.item.score}</td>

      <td className={'my-score'}>
        <div className={'score-input-style'}>
          {props.score != null && props.score.score >= 0 ? (
            <Progress
              steps={10}
              percent={Math.round(
                (props.score.score * 100) / props.score.maxScore
              )}
              format={(percent) => percent + '%'}
            />
          ) : (
            <span className={'score-label'}>
              <b>N/A</b>
            </span>
          )}
        </div>
      </td>
    </tr>
  );
};
