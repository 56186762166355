import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useState } from 'react';
import { Button, Dropdown, Menu, notification } from 'antd';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { LessonExampleActionsHandle } from '@modules/product/container/lesson-example/LessonExampleActionsHandle';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonExampleRes } from '@modules/admin/service/model';

export enum LessonExampleActionEnum {
  DEFAULT = '',
  COMPOSE = 'compose',
  ADD = 'add',
  EDIT = 'edit',
  EDIT_METADATA = 'edit-metadata',
  REMOVE = 'remove',
  PUBLISH = 'publish',
}

export const LessonExampleActions = ({
  item,
  onChange,
}: {
  item: LessonExampleRes;
  onChange: () => void;
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const proUser = useProUser();

  const [publishLessonExample] =
    LessonExampleAPI.endpoints.publishLessonExample.useMutation({});

  const [deleteLessonExample] =
    LessonExampleAPI.endpoints.deleteLessonExample.useMutation();

  const [action, setAction] = useState<{
    action: LessonExampleActionEnum;
    params: number;
  }>({ action: LessonExampleActionEnum.DEFAULT, params: -1 });

  const handleOnCancel = () => {
    setAction({ action: LessonExampleActionEnum.DEFAULT, params: -1 });
    onChange();
  };

  const onCompose = (lessonId: number) => {
    router.push(SiteMap.content.lesson.compose_gen(lessonId, proUser));
  };

  const onEdit = (pageSiteId: number) => {
    setAction({ action: LessonExampleActionEnum.EDIT, params: pageSiteId });
  };

  const editMetadata = (lessonId: number) => {
    setAction({
      action: LessonExampleActionEnum.EDIT_METADATA,
      params: lessonId,
    });
  };

  const onPublish = (lessonTemplateId: number) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('lesson_example.warning.publish_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          publishLessonExample(lessonTemplateId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('page_site.warning.update_success'),
                description: t('page_site.warning.update_success_message'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('lesson.warning.update_error'),
                description: t('lesson.warning.update_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const removeLessonExample = (lessonId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('lesson_example.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteLessonExample(lessonId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('lesson_example.warning.remove_success'),
                description: t('lesson_example.warning.remove_success_message'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('lesson_example.warning.remove_error'),
                description: t('lesson_example.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: LessonExampleActionEnum.EDIT,
              icon: IconUtils.actions.edit,
              label: t('lesson_example.actions.edit'),
              onClick: () => onEdit(item.lessonExampleId),
            },

            {
              key: LessonExampleActionEnum.EDIT_METADATA,
              icon: IconUtils.actions.edit_metadata,
              label: t('lesson_example.actions.edit_metadata'),
              onClick: () => editMetadata(item.lesson.lessonId),
            },

            {
              key: LessonExampleActionEnum.REMOVE,
              icon: IconUtils.actions.delete,
              label: t('lesson_example.actions.remove'),
              onClick: () => removeLessonExample(item.lessonExampleId),
            },
            {
              key: 'divider-1',
              label: <Menu.Divider />,
            },
            {
              key: LessonExampleActionEnum.COMPOSE,
              icon: IconUtils.compose_lesson,
              label: t('lesson_example.actions.compose'),
              onClick: () => onCompose(item.lesson.lessonId),
            },
            {
              key: LessonExampleActionEnum.PUBLISH,
              icon: IconUtils.actions.publish,
              label: t('lesson_example.actions.publish'),
              onClick: () => onPublish(item.lessonExampleId),
            },
          ],
        }}
        trigger={['click']}
        placement={'bottom'}
      >
        <Button type="default" shape="circle" icon={IconUtils.more_icon} />
      </Dropdown>

      <LessonExampleActionsHandle action={action} onCancel={handleOnCancel} />
    </>
  );
};
