import React from 'react';
import { H3 } from '@components/typography';
import { useTranslation } from 'react-i18next';
import { InComingEventStyle } from '@modules/dashboard/components/IncomingEvents';
import MyTeacherRegistrationCourse from '@modules/course/container/MyTeacherRegistrationCourse';

const CourseRegistration = () => {
  const { t } = useTranslation();

  return (
    <InComingEventStyle className={'incoming-events'}>
      <H3>{t('dashboard.label.course_registration')}</H3>

      <MyTeacherRegistrationCourse pageSize={5} />
    </InComingEventStyle>
  );
};

export default CourseRegistration;
