import {
  CompTypeEnum,
  CompProps,
  CompAnswerProps,
} from '@cms/ComponentInteface';
import React from 'react';
import { EpubContentProps } from '@cms/content/ContentType';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import EpubViewer from '@cms/content/EpubViewer';
import styled from 'styled-components';

export interface EpubCompProps extends CompProps {
  type: CompTypeEnum.EPUB;

  configuration: {
    content: EpubContentProps;
  };
}

export function EpubComp(props: {
  item: EpubCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  return (
    <EpubCompStyle
      className={`comp comp-epub ${props.item.setting?.className}`}
    >
      <EpubViewer
        title={props.item.configuration.content.name}
        fileUrl={props.item.configuration.content.data}
      />
    </EpubCompStyle>
  );
}

const EpubCompStyle = styled.div`
  position: relative;
`;
