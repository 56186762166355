import { SchoolRes } from '@services/private/SchoolAPI';
import { ClassRosterReq } from '@modules/users/services/clazz';
import { PaginationReq } from '@services/model/PaginationRes';
import {
  ExerciseRes,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';
import { UserInfoRes } from '@modules/users/services/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum AssignmentExpiredType {
  empty = '',
  date_n_time = 'date-n-time',
  duration = 'duration',
}

export interface FilterStudentAssignmentReq extends PaginationReq {
  classId?: number;
}

export interface AssignmentRes {
  assignmentId: number;

  name: string;
  direction: string;
  expiredType: string;
  duration?: number;
  unlockAt?: string;
  lockAt?: string;

  createdDate: string;

  unlockInMs: number;
  expiredInMs: number;

  assignmentGroup?: AssignmentGroupRes;
  school?: SchoolRes;
}

export interface AssignmentInstanceRes {
  assignmentInstanceId: number;

  assignment: AssignmentRes;
  assignmentGroup?: AssignmentGroupRes;

  name: string;
  direction: string;
  expiredType: AssignmentExpiredType;
  duration: number;
  unlockAt: string;
  lockAt: string;

  createdDate: string;

  exercise: ExerciseRes;
  status: ExerciseStatus;

  unlockInMs: number;
  expiredInMs: number;

  results: ExerciseRes[];
  lesson: LessonRes;

  assignBy: UserInfoRes;
}

export interface CreateAssignmentReq {
  assignmentId?: number;
  assignmentGroupId?: number;

  name: string;
  direction: string;
  expiredType: AssignmentExpiredType;
  duration: number;
  unlockAt: string;
  lockAt: string;

  lessonIds: number[];
  rosters: ClassRosterReq[];
  forUserId?: number;
}

export interface UpdateAssignmentInstanceReq {
  assignmentInstanceId: number;
  assignmentGroupId: number;

  name: string;
  direction: string;
  expiredType: AssignmentExpiredType;
  duration: number;
  unlockAt: string;
  lockAt: string;

  rosters: ClassRosterReq[];
}

export interface AssignmentGroupRes {
  assignmentGroupId: number;
  name: string;
  description: string;
  school: SchoolRes | null;
  createdDate: string;
  modifiedDate: string;
}

export interface CreateAssignmentGroupReq {
  assignmentGroupId?: number;
  name: string;
  description: string;
}

export interface AssignmentInstanceStatusRes {
  assignmentInstanceStatusId: number;

  assignment: AssignmentRes;
  assignmentInstance: AssignmentInstanceRes;
  notStarted: number;
  viewed: number;
  inProgress: number;
  submitted: number;
  needGrading: number;
  completed: number;
  total: number;
}

export interface FilterAssignmentReq extends PaginationReq {
  forUserId: number;
  classId?: number;
}
