import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import LessonExamplePage from '@modules/product/pages/LessonExamplePage';
import { PageHeader } from '@app/header/PageHeader';
import { LessonTypeEnum } from '@modules/admin/service/model';

const PageExample = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'page-example-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LESSON_EXAMPLE}
        subMenu={NavigationMenuEnum.LESSON_EXAMPLE_PAGE}
        title={t('lesson_example.page.title')}
        description={t('lesson_example.page.title')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <LessonExamplePage category={LessonTypeEnum.page} />
      </Authorization>
    </ManagementLayout>
  );
};

export default PageExample;
