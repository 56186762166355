import React from 'react';
import { useTranslation } from 'react-i18next';
import RegisterUserForm from '@modules/authorization/container/RegisterStudentForm';
import Card, { CardClassEnum } from '@components/card';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LoginLayout } from '@modules/authorization/layout/LoginLayout';

export default function StudentRegistration(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();

  const handleRegisterSuccess = () => {
    router.push(SiteMap.auth.login);
  };

  return (
    <LoginLayout>
      <h1 className={'text-center'}>{t('header.student_registration')}</h1>

      <Card className={CardClassEnum.rectangle_box}>
        <RegisterUserForm
          disabled={props.disabled}
          successHandle={handleRegisterSuccess}
          autoLogin={false}
        />
      </Card>
    </LoginLayout>
  );
}
