import React from 'react';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';

export default function ViewLessonPage(props: {
  lessonId: number;
  productId?: number;
  productTocId?: number;
}) {
  return <LessonReview lessonId={props.lessonId} />;
}
