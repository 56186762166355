import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import {
  CompConfiguration,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import { FibNumberProps } from '@cms/comps/math/fib/FibNumberComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';

export const FibNumberExampleData: FibNumberProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FIB_NUMBER,

  configuration: {
    targetItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data:
            '10 &times; 5 =  ' +
            CompConfiguration.INLINE_COMPONENT +
            ' &times; ' +
            CompConfiguration.INLINE_COMPONENT +
            '.',
        },
      },
    ],
  },

  setting: {
    autoScore: false,
    digit: 'xxx',
    numberType: 'integer',
  },
};
