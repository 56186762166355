import { Button, notification } from 'antd';
import React, { useState } from 'react';
import { EventAPI } from '@services/event/EventAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { H1 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

export const SyncEventPage = () => {
  const [syncAllData, { isLoading }] =
    EventAPI.endpoints.syncAllData.useMutation({});

  const [success, setSuccess] = useState(false);

  const handleSyncEvents = () => {
    syncAllData({})
      .unwrap()
      .then(() => {
        notification.success({
          message: `Cập nhật thành công`,
          description: 'Cập nhật thành công.',
          placement: 'bottomRight',
        });
        setSuccess(true);
      })
      .catch(() => {
        notification.error({
          message: `Cập nhật thất bại`,
          description: 'Cập nhật thất bại.',
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div>
      <HeaderGroup className="header-group">
        <H1>Sync events</H1>
      </HeaderGroup>

      <p>Click button to sync events for whole school.</p>

      {!success && (
        <ButtonGroup type={'right'}>
          <Button
            type={'primary'}
            loading={isLoading}
            onClick={handleSyncEvents}
          >
            Sync Events of School
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};
