import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';

import appState from './slices/appSlice';
import appLayout from './slices/appLayoutSlice';
import appLanguage from './slices/appLanguageSlice';
import appSetting from './slices/appSettingSlice';
import profile from './slices/profileSlice';
import userInformation from './slices/userInfomationSlice';
import alertNotify from './slices/alertSlice';
import addStudent from './slices/addStudentSlices';
import composeLesson from './slices/composeLesson';
import composeResource from './slices/composeResource';
import viewResource from './slices/viewResource';
import filterProductAndCourse from './slices/filterProductAndCourse';
import sessionExpired from './slices/sessionExpiredSlice';

import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import { RegisterAPI } from '@modules/authorization/services/RegisterAPI';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { StudentAPI } from '@modules/users/services/StudentAPI';
import { InvitationAPI } from '@services/private/InvitationAPI';
import { UploadAPI } from '@modules/product/services/UploadAPI';
import { MyProfileAPI } from '@services/private/MyProfileAPI';
import { ClassAPI } from '@modules/users/services/ClassAPI';

// CMS APIs
import { CMSAssetsAPI } from '@modules/product/services/AssetsAPI';
import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { ProductTocAPI } from '@modules/product/services/ProductTocAPI';
import { SessionAPI } from '@services/private/SessionAPI';
import { SchoolAPI } from '@services/private/SchoolAPI';
import { CourseAPI } from '@services/private/CourseAPI';

// programs.
import { SubjectAPI } from '@modules/admin/service/SubjectAPI';
import { CategoryAPI } from '@modules/admin/service/CategoryAPI';
import { GradeAPI } from '@modules/admin/service/GradeAPI';
import { ProgramAPI } from '@modules/admin/service/ProgramAPI';
import { CertificateAPI } from '@modules/admin/service/CertificateAPI';

import { MetadataAPI } from '@services/metadata/MetadataAPI';
import { PublishProductAPI } from '@services/publish/PublishProductAPI';
import { PublishCourseAPI } from '@services/publish/PublishCourseAPI';

import { ClassRoomAPI } from '@modules/calendar/service/ClassRoomAPI';
import { ShareLessonAPI } from '@services/share/ShareLessonAPI';
import { ShareProductAPI } from '@services/share/ShareProductAPI';
import { StudentLessonAPI } from '@services/student/StudentLessonAPI';
import { StudentSessionAPI } from '@services/student/StudentSessionAPI';
import { ExerciseAPI } from '@modules/assignments/service/ExerciseAPI';
import { PracticeExerciseAPI } from '@modules/assignments/service/PracticeExerciseAPI';
import { LessonTypeAPI } from '@modules/admin/service/LessonTypeAPI';
import { FilterProductAndCourseAPI } from '@services/public/FilterProductAndCourseAPI';
import { GlossaryExerciseAPI } from '@modules/assignments/service/GlossaryExerciseAPI';
import { StandardAPI } from '@modules/product/services/StandardAPI';
import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import { LessonReportAPI } from '@services/reports/LessonReport';
import { AssignmentGroupAPI } from '@modules/assignments/service/AssignmentGroupAPI';
import { AssignmentAPI } from '@modules/assignments/service/AssignmentAPI';
import { StudentAssignmentAPI } from '@modules/assignments/service/StudentAssignmentAPI';
import { TeacherAssignmentAPI } from '@modules/assignments/service/TeacherAssignmentAPI';
import { AssignmentReportAPI } from '@services/reports/AssignmentReport';
import { GradingExerciseAPI } from '@modules/assignments/service/ExerciseGradingAPI';
import { AdminPublishSchoolAPI } from '@modules/admin/service/AcceptPublishSchoolAPI';
import { ProgressReportAPI } from '@services/reports/ProgressReport';
import { EmailTemplateAPI } from '@services/setting/EmailTemplateAPI';
import { UserParentAPI } from '@modules/users/services/UserParentAPI';
import { UserSchoolAdminAPI } from '@modules/users/services/UserSchoolAdminAPI';
import { UserSchoolSupportAPI } from '@modules/users/services/UserSchoolSupportAPI';
import { UserTeacherAPI } from '@modules/users/services/UserTeacherAPI';
import { EmailSettingAPI } from '@services/setting/EmailSettingAPI';
import { UserProfileAPI } from '@services/private/UserProfileAPI';
import { CurrencyAPI } from '@modules/payment/services/CurrencyAPI';
import { PaymentMethodAPI } from '@modules/payment/services/PaymentMethodAPI';
import { SchoolPaymentMethodAPI } from '@modules/payment/services/SchoolPaymentMethodAPI';
import { OauthAppAPI } from '@services/private/OauthAppAPI';
import { PublishSchoolAPI } from '@services/publish/PublishSchool';
import { LessonTemplateAPI } from '@modules/product/services/LessonTemplateAPI';
import { LessonSectionAPI } from '@modules/product/services/LessonSectionAPI';
import { PageSiteAPI } from '@services/page-site/PageSiteAPI';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';
import { UserSubscriptionAPI } from '@services/private/UserSubscriptionAPI';
import { LanguageAPI } from '@services/setting/LanguageAPI';
import { SchoolSettingAPI } from '@services/private/SchoolSettingAPI';
import { LessonCollectionAPI } from '@modules/product/services/CollectionAPI';
import { PublishLessonAPI } from '@services/publish/PublishLessonAPI';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { UserStudentAPI } from '@modules/users/services/UserStudentAPI';
import { EventAPI } from '@services/event/EventAPI';
import { UserRequestAPI } from '@services/UserRequestAPI';
import { ProductLicenseAPI } from '@modules/license/services/ProductLicenseAPI';
import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import { MyClassAPI } from '@services/private/users/MyClassAPI';
import { SurveyAPI } from '@modules/assignments/service/SurveyAPI';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';
import { AnonymousDoExerciseAPI } from '@modules/assignments/service/AnonymousDoExerciseAPI';

const rootReducer = combineReducers({
  appState,
  appLayout,
  appLanguage,
  appSetting,

  profile,
  userInformation,
  alertNotify,
  addStudent,
  sessionExpired,
  composeLesson,
  composeResource,
  viewResource,
  filterProductAndCourse,

  [AuthAPI.reducerPath]: AuthAPI.reducer,

  [RegisterAPI.reducerPath]: RegisterAPI.reducer,
  [UserRequestAPI.reducerPath]: UserRequestAPI.reducer,

  [UploadAPI.reducerPath]: UploadAPI.reducer,
  [InvitationAPI.reducerPath]: InvitationAPI.reducer,

  [MyProfileAPI.reducerPath]: MyProfileAPI.reducer,
  [MyClassAPI.reducerPath]: MyClassAPI.reducer,
  [UserProfileAPI.reducerPath]: UserProfileAPI.reducer,
  [OauthAppAPI.reducerPath]: OauthAppAPI.reducer,

  // MANAGE USERS....
  [UserSchoolAdminAPI.reducerPath]: UserSchoolAdminAPI.reducer,
  [UserSchoolSupportAPI.reducerPath]: UserSchoolSupportAPI.reducer,
  [UserTeacherAPI.reducerPath]: UserTeacherAPI.reducer,
  [UserParentAPI.reducerPath]: UserParentAPI.reducer,
  [UserStudentAPI.reducerPath]: UserStudentAPI.reducer,
  [ClassAPI.reducerPath]: ClassAPI.reducer,
  [CourseAPI.reducerPath]: CourseAPI.reducer,

  [StudentAPI.reducerPath]: StudentAPI.reducer,
  [SchoolAPI.reducerPath]: SchoolAPI.reducer,

  [ProductAPI.reducerPath]: ProductAPI.reducer,
  [LessonAPI.reducerPath]: LessonAPI.reducer,
  [LessonCollectionAPI.reducerPath]: LessonCollectionAPI.reducer,
  [LessonTemplateAPI.reducerPath]: LessonTemplateAPI.reducer,
  [LessonSectionAPI.reducerPath]: LessonSectionAPI.reducer,
  [ResourceAPI.reducerPath]: ResourceAPI.reducer,
  [ProductTocAPI.reducerPath]: ProductTocAPI.reducer,

  [EventAPI.reducerPath]: EventAPI.reducer,
  [SessionAPI.reducerPath]: SessionAPI.reducer,

  // ADMIN APISupdate
  [SubjectAPI.reducerPath]: SubjectAPI.reducer,
  [CategoryAPI.reducerPath]: CategoryAPI.reducer,
  [GradeAPI.reducerPath]: GradeAPI.reducer,
  [ProgramAPI.reducerPath]: ProgramAPI.reducer,
  [CertificateAPI.reducerPath]: CertificateAPI.reducer,

  [LessonTypeAPI.reducerPath]: LessonTypeAPI.reducer,
  [MetadataAPI.reducerPath]: MetadataAPI.reducer,
  [AdminPublishSchoolAPI.reducerPath]: AdminPublishSchoolAPI.reducer,

  // Share

  [ShareLessonAPI.reducerPath]: ShareLessonAPI.reducer,
  [ShareProductAPI.reducerPath]: ShareProductAPI.reducer,

  // publish product apis
  [PublishProductAPI.reducerPath]: PublishProductAPI.reducer,
  [PublishLessonAPI.reducerPath]: PublishLessonAPI.reducer,
  [PublishCourseAPI.reducerPath]: PublishCourseAPI.reducer,

  // student APIS
  [StudentLessonAPI.reducerPath]: StudentLessonAPI.reducer,
  [StudentSessionAPI.reducerPath]: StudentSessionAPI.reducer,

  // CMS APIs
  [CMSAssetsAPI.reducerPath]: CMSAssetsAPI.reducer,
  [StandardAPI.reducerPath]: StandardAPI.reducer,
  [StandardSetAPI.reducerPath]: StandardSetAPI.reducer,

  [ClassRoomAPI.reducerPath]: ClassRoomAPI.reducer,

  // EXERCISE APIS....
  [ExerciseAPI.reducerPath]: ExerciseAPI.reducer,
  [DoExerciseAPI.reducerPath]: DoExerciseAPI.reducer,
  [AnonymousDoExerciseAPI.reducerPath]: AnonymousDoExerciseAPI.reducer,

  [SurveyAPI.reducerPath]: SurveyAPI.reducer,
  [GradingExerciseAPI.reducerPath]: GradingExerciseAPI.reducer,
  [PracticeExerciseAPI.reducerPath]: PracticeExerciseAPI.reducer,
  [GlossaryExerciseAPI.reducerPath]: GlossaryExerciseAPI.reducer,
  [AssignmentGroupAPI.reducerPath]: AssignmentGroupAPI.reducer,
  [AssignmentAPI.reducerPath]: AssignmentAPI.reducer,
  [StudentAssignmentAPI.reducerPath]: StudentAssignmentAPI.reducer,
  [TeacherAssignmentAPI.reducerPath]: TeacherAssignmentAPI.reducer,

  // status
  [ProgressReportAPI.reducerPath]: ProgressReportAPI.reducer,

  // REPORT APIS
  [LessonReportAPI.reducerPath]: LessonReportAPI.reducer,
  [AssignmentReportAPI.reducerPath]: AssignmentReportAPI.reducer,

  // SETTING APIS
  [LessonExampleAPI.reducerPath]: LessonExampleAPI.reducer,
  [PageSiteAPI.reducerPath]: PageSiteAPI.reducer,

  [SchoolSettingAPI.reducerPath]: SchoolSettingAPI.reducer,
  [EmailTemplateAPI.reducerPath]: EmailTemplateAPI.reducer,
  [EmailSettingAPI.reducerPath]: EmailSettingAPI.reducer,

  [LanguageAPI.reducerPath]: LanguageAPI.reducer,
  [CurrencyAPI.reducerPath]: CurrencyAPI.reducer,
  [PaymentMethodAPI.reducerPath]: PaymentMethodAPI.reducer,
  [SchoolPaymentMethodAPI.reducerPath]: SchoolPaymentMethodAPI.reducer,

  [ProductLicenseAPI.reducerPath]: ProductLicenseAPI.reducer,
  [UserProductLicenseAPI.reducerPath]: UserProductLicenseAPI.reducer,

  // PUBLIC APIS
  [PublicPageAPI.reducerPath]: PublicPageAPI.reducer,

  // PUBLISH APIS
  [UserSubscriptionAPI.reducerPath]: UserSubscriptionAPI.reducer,
  [PublishSchoolAPI.reducerPath]: PublishSchoolAPI.reducer,
  [FilterProductAndCourseAPI.reducerPath]: FilterProductAndCourseAPI.reducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(AuthAPI.middleware)
        .concat(RegisterAPI.middleware)
        .concat(UserRequestAPI.middleware)
        .concat(UploadAPI.middleware)
        .concat(InvitationAPI.middleware)
        .concat(MyProfileAPI.middleware)
        .concat(MyClassAPI.middleware)
        .concat(UserProfileAPI.middleware)
        .concat(OauthAppAPI.middleware)

        // USER ACCOUNTS
        .concat(UserSchoolAdminAPI.middleware)
        .concat(UserSchoolSupportAPI.middleware)
        .concat(UserStudentAPI.middleware)
        .concat(UserTeacherAPI.middleware)
        .concat(UserParentAPI.middleware)
        .concat(ClassAPI.middleware)
        .concat(CourseAPI.middleware)

        .concat(StudentAPI.middleware)
        .concat(SchoolAPI.middleware)
        .concat(ProductAPI.middleware)
        .concat(LessonAPI.middleware)
        .concat(LessonCollectionAPI.middleware)
        .concat(LessonTemplateAPI.middleware)
        .concat(LessonSectionAPI.middleware)
        .concat(ResourceAPI.middleware)
        .concat(ProductTocAPI.middleware)
        .concat(EventAPI.middleware)
        .concat(SessionAPI.middleware)
        .concat(SubjectAPI.middleware)
        .concat(CategoryAPI.middleware)
        .concat(GradeAPI.middleware)
        .concat(ProgramAPI.middleware)
        .concat(CertificateAPI.middleware)
        .concat(LessonTypeAPI.middleware)
        .concat(MetadataAPI.middleware)
        .concat(AdminPublishSchoolAPI.middleware)
        .concat(ShareLessonAPI.middleware)
        .concat(ShareProductAPI.middleware)
        .concat(PublishProductAPI.middleware)
        .concat(ProductLicenseAPI.middleware)
        .concat(UserProductLicenseAPI.middleware)

        .concat(PublishLessonAPI.middleware)
        .concat(PublishCourseAPI.middleware)
        .concat(StudentLessonAPI.middleware)
        .concat(StudentSessionAPI.middleware)
        .concat(CMSAssetsAPI.middleware)
        .concat(StandardAPI.middleware)
        .concat(StandardSetAPI.middleware)
        .concat(ClassRoomAPI.middleware)
        .concat(ExerciseAPI.middleware)
        .concat(DoExerciseAPI.middleware)
        .concat(AnonymousDoExerciseAPI.middleware)
        .concat(SurveyAPI.middleware)
        .concat(GradingExerciseAPI.middleware)
        .concat(PracticeExerciseAPI.middleware)
        .concat(GlossaryExerciseAPI.middleware)
        .concat(AssignmentGroupAPI.middleware)
        .concat(AssignmentAPI.middleware)
        .concat(StudentAssignmentAPI.middleware)
        .concat(TeacherAssignmentAPI.middleware)
        .concat(ProgressReportAPI.middleware)
        .concat(LessonReportAPI.middleware)
        .concat(AssignmentReportAPI.middleware)
        .concat(LessonExampleAPI.middleware)
        .concat(PageSiteAPI.middleware)
        .concat(SchoolSettingAPI.middleware)
        .concat(EmailTemplateAPI.middleware)
        .concat(EmailSettingAPI.middleware)
        .concat(LanguageAPI.middleware)
        .concat(CurrencyAPI.middleware)
        .concat(PaymentMethodAPI.middleware)
        .concat(SchoolPaymentMethodAPI.middleware)
        .concat(PublicPageAPI.middleware)
        .concat(UserSubscriptionAPI.middleware)
        .concat(PublishSchoolAPI.middleware)
        .concat(FilterProductAndCourseAPI.middleware),
  });
}

const store = makeStore();

export type RootState = ReturnType<typeof rootReducer>;

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
