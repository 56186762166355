import React, { useEffect, useRef, useState } from 'react';
import {
  PlayCircleOutlined,
  PushpinOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import WaveSurfer from 'wavesurfer.js';
import { useTheme } from '@app/styled/StyledProvider';

export default function AudioWaveSuffer(props: {
  url: string;
  onClick: (time: number) => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const waveformRef = useRef(null);
  const wavesurfer = useRef<any>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    create();

    return () => {
      if (wavesurfer.current) {
        // @ts-ignore
        wavesurfer.current.destroy();
      }
    };
  }, []);

  const formWaveSurferOptions = (ref: any) => ({
    container: ref,
    waveColor: theme.component.secondary,
    progressColor: theme.component.primary_bgr,
    cursorColor: theme.component.primary,
    barWidth: 5,
    barRadius: 5,
    responsive: true,
    height: 80,
    normalize: true,
    partialRender: true,
  });

  const create = async () => {
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.load(props.url);

    wavesurfer.current.on('audioprocess', () => {
      if (wavesurfer.current.isPlaying()) {
        const currentTime = wavesurfer.current.getCurrentTime();
        setCurrentTime(currentTime);
      }
    });
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
  };

  const handleInsertSubtitle = () => {
    props.onClick(Number(currentTime.toFixed(2)));
  };

  return (
    <AudioWaveSufferStyle className={'audio-wave-suffer'}>
      <div className={'audio-wave-suffer-container'} ref={waveformRef} />

      <div className="audio-wave-suffer-controls">
        <Space size={40} align={'center'}>
          <Button
            type={'primary'}
            shape={'round'}
            onClick={handlePlayPause}
            icon={playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          >
            {playing ? t('component.audio.pause') : t('component.audio.play')}
          </Button>

          <b className={'current-time'}>{currentTime.toFixed(2)}</b>
        </Space>

        <Button type={'primary'} shape={'round'} onClick={handleInsertSubtitle}>
          <PushpinOutlined /> {t('component.audio.add_subtitle')}
        </Button>
      </div>
    </AudioWaveSufferStyle>
  );
}

const AudioWaveSufferStyle = styled.div`
  background: #fafafa;
  padding: 0.125em 0.25em;
  border: 2px solid #ccc;
  font-size: 16px;

  .audio-wave-suffer-controls {
    margin-top: 0.5em;

    display: flex;
    justify-content: space-between;

    .current-time {
      font-size: 16px;
    }
  }
`;
