import { CompProps, CompTypeEnum } from '@cms/ComponentInteface';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import { StringUtils } from '@utils/StringUtils';
import { AssetsRes } from '@modules/product/services/assets_model';

export const GlossaryGameComponentTypes: CompTypeEnum[] = [
  CompTypeEnum.GAME_MATCHING,
  CompTypeEnum.WORD_BOX,
  CompTypeEnum.WORD_SEARCH,
  CompTypeEnum.WORD_SCRAMBLE,
  CompTypeEnum.WORD_PUZZLE,
];

// component that need next button to go to other page...
export const GlossaryMultipleQuestionComponentTypes = [
  CompTypeEnum.WORD_BOX,
  CompTypeEnum.WORD_SCRAMBLE,
];

export interface GlossaryGameContent {
  id: string;
  offset?: { row: number; column: number };
  word: TextContentProps | ImageContentProps | AudioContentProps | AssetsRes;
  description:
    | TextContentProps
    | ImageContentProps
    | AudioContentProps
    | AssetsRes;
}

export interface GlossaryGameCompProps extends CompProps {
  configuration: {
    defaultWord?: string;
    sourceItems: GlossaryGameContent[];
    targetItems?: GlossaryGameContent[];
  };
  setting: any;
}

const SPLIT_CHARACTER = '_';
const HIDDEN_CHARACTER = '*';

export const GameUtils = {
  splitCharacter: SPLIT_CHARACTER,
  hiddenCharacter: HIDDEN_CHARACTER,

  getHiddenAnswer: (answer: string) => {
    const charaters = StringUtils.replaceAll(
      answer,
      ' ',
      SPLIT_CHARACTER
    ).split('');

    return charaters.map((char) => {
      if (char === SPLIT_CHARACTER) {
        return SPLIT_CHARACTER;
      } else {
        return HIDDEN_CHARACTER;
      }
    });
  },

  convertAnswer: (answer: string) => {
    const charaters = StringUtils.replaceAll(
      answer,
      ' ',
      SPLIT_CHARACTER
    ).split('');

    return charaters.map((char) => {
      if (char === SPLIT_CHARACTER) {
        return SPLIT_CHARACTER;
      } else {
        return char.toLowerCase();
      }
    });
  },
};
