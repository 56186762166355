import React from 'react';

import { Button, Divider, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { useDispatch } from 'react-redux';
import { login } from '@app/redux/slices/profileSlice';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import NavLink from '@components/button/NavLink';
import env from '@utils/config';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';

const LoginForm = (props: {
  clientId?: string;
  grantType?: string;
  onSuccess: () => void;
  onCancel?: () => void;
  hideForgotPassword?: boolean;
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [doLogin, { isLoading, isSuccess }] =
    AuthAPI.endpoints.login.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      username: '',
      password: '',
    },

    validationSchema: yup.object().shape({
      username: yup.string().required(t('validation.required.field')),
      password: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload = {
        grantType: props.grantType ?? 'password',
        clientId: props.clientId ?? env.CLIENT_ID,
        username: values.username.trim(),
        password: values.password,
      };

      doLogin(payload)
        .unwrap()
        .then((data) => {
          const payload = {
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            uuid: data.uuid,
            roles: data.roles,
            schoolId: data.schoolId,
          };

          dispatch(login(payload));
          dispatch(refreshExpiredData(data.expires_in));
          props.onSuccess();

          notification.success({
            message: t('login.warning.login_success'),
            description: t('login.warning.login_success_message'),
            placement: 'bottomRight',
          });
        })
        .catch((e) => {
          formik.setFieldValue('password', '');
          formik.setFieldTouched('password', false);
          notification.error({
            message: t('login.warning.login_error'),
            description: t('errors.username_or_password_is_uncorrect'),
            placement: 'bottomRight',
          });
        });
    },
  });

  const handleOnKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      formik.submitForm();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Input
        name="username"
        type="username"
        label={t('label.username')}
        placeholder={t('label.username')}
        onChange={formik.handleChange}
        value={formik.values.username}
      />

      <Input
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('label.password')}
        onChange={formik.handleChange}
        onKeyDown={handleOnKeyDown}
        value={formik.values.password}
      />

      <ButtonGroup className="submit-container" type={'center'}>
        <Button
          loading={isLoading || isSuccess}
          type={'primary'}
          shape={'round'}
          size={'large'}
          onClick={formik.submitForm}
          icon={IconUtils.login}
        >
          {t('button.login')}
        </Button>

        {props.onCancel && (
          <Button
            type="default"
            shape={'round'}
            size={'large'}
            onClick={props.onCancel}
          >
            {t('button.back')}
          </Button>
        )}
      </ButtonGroup>

      {!props.hideForgotPassword && (
        <>
          <Divider />

          <div style={{ textAlign: 'center' }}>
            <NavLink href={SiteMap.auth.forgot_password}>
              <Button type="link">{t('button.forgot_password')}</Button>
            </NavLink>

            <NavLink href={SiteMap.register.index}>
              <Button type="link">{t('button.register')}</Button>
            </NavLink>
          </div>
        </>
      )}
    </FormikProvider>
  );
};

export default LoginForm;
