import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import Dashboard from '@router/pages/private/dashboard';
import MyCalendar from '@router/pages/private/my_calendar';
import MyMessage from '@router/pages/private/my_message';
import MyNotification from '@router/pages/private/my_notification';
import React from 'react';
import MyClass from '@router/pages/private/my-class/my_class';
import ViewClass from '@router/pages/private/my-class/view_class';

export const PrivateUserRouters: PageRouterProps[] = [
  {
    path: SiteMap.private.dashboard,
    element: <PrivateRouter element={<Dashboard />} />,
  },

  {
    path: SiteMap.private.my_calendar,
    element: <PrivateRouter element={<MyCalendar />} />,
  },

  {
    path: SiteMap.private.my_class.list,
    element: (
      <PrivateRouter
        roles={SiteMap.private.my_class.roles}
        element={<MyClass />}
      />
    ),
  },

  {
    path: SiteMap.private.my_class.view,
    element: (
      <PrivateRouter
        roles={SiteMap.private.my_class.roles}
        element={<ViewClass />}
      />
    ),
  },

  {
    path: SiteMap.private.my_messages,
    element: <PrivateRouter element={<MyMessage />} />,
  },

  {
    path: SiteMap.private.my_notification,
    element: <PrivateRouter element={<MyNotification />} />,
  },
];
