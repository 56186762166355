import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ProductLicenseFilterForm = (props: {
  span?: number;
  loading?: boolean;
  keyword?: string;
  onSubmit: (keyword: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      keyword: props.keyword ?? '',
    },

    onSubmit: (values) => {
      props.onSubmit(values.keyword);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]} align="bottom">
        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <Input
            name="keyword"
            type="text"
            label={t('label.keyword')}
            placeholder={t('label.enter_keyword')}
            onChange={formik.handleChange}
            value={formik.values.keyword}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={props.span ?? 8}
          lg={props.span ?? 8}
          xl={props.span ?? 8}
          xxl={props.span ?? 8}
        >
          <InputGroup
            label={
              props.span != null && props.span === 12 ? <>&nbsp;</> : undefined
            }
          >
            <Button
              type={'primary'}
              size={'large'}
              loading={props.loading}
              onClick={formik.submitForm}
            >
              <SearchOutlined /> {t('button.filter')}
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};
