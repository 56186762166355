import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import InProgressPage from '@modules/authorization/pages/InProgressPage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const MyMessage = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-message-page'}>
      <PageHeader
        menu={NavigationMenuEnum.NOTIFICATION}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('message.my_message')}
        description={t('message.my_message')}
      />

      <InProgressPage />
    </ManagementLayout>
  );
};

export default MyMessage;
