import { GridWordProps } from '@cms/comps/game/word-search/WordSearchUtils';
import { GameUtils, GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { WordPuzzleSettingProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import { WordPuzzleData } from '@cms/comps/game/word-puzzle/WordPuzzleWordBanks';

export interface WordPuzzleBoxProps {
  word: WordPuzzleData;
  type: 'across' | 'down';
  offset: { row: number; column: number };
}

export class WordPuzzleUtils {
  crossWords;
  downWords;

  setting;
  wordGridArray;
  wordOffset;
  tryTimes;
  resetTimes;

  constructor(
    crossWords: GlossaryGameContent[],
    downWords: GlossaryGameContent[],
    setting: WordPuzzleSettingProps
  ) {
    this.tryTimes = 0;
    this.resetTimes = 0;

    this.crossWords = [...crossWords];
    this.downWords = [...downWords];

    this.setting = setting;

    this.wordGridArray = this.initWordList();
    this.wordOffset = [] as any[];

    // then place words...
    this.placeWordsToGrid();
  }

  placeWordsToGrid = () => {
    this.wordGridArray = this.initWordList();

    if (this.crossWords.length > 0) {
      this.crossWords.forEach((word) => {
        if (word.offset) {
          this.placeWordAtOffset({
            word: {
              id: word.id,
              word: word.word.data,
              questionNumber: 1,
              offset: word.offset,
            },
            type: 'across',
            offset: word.offset,
          });
        }
      });
    }

    if (this.downWords.length > 0) {
      this.downWords.forEach((word) => {
        if (word.offset) {
          this.placeWordAtOffset({
            word: {
              id: word.id,
              word: word.word.data,
              questionNumber: 1,
              offset: word.offset,
            },
            type: 'down',
            offset: word.offset,
          });
        }
      });
    }

    return this.wordGridArray;
  };

  placeWordAtOffset = (arrData: WordPuzzleBoxProps) => {
    if (arrData.type === 'across') {
      let startIndex = arrData.offset.column;
      if (startIndex + arrData.word.word.length > this.setting.maxColumns) {
        startIndex = this.setting.maxColumns - arrData.word.word.length;
      }

      for (let i = startIndex; i < startIndex + arrData.word.word.length; i++) {
        const boxData = this.wordGridArray[arrData.offset.row][i];

        if (boxData != null) {
          const char = arrData.word.word.charAt(i - startIndex).toUpperCase();
          boxData.correct = false;
          boxData.answer = '';

          // then add the correct answer.
          boxData.wordId =
            boxData.wordId && boxData.wordId.length > 0
              ? [...boxData.wordId, arrData.word.id]
              : [arrData.word.id];

          boxData.char =
            boxData.char === GameUtils.hiddenCharacter
              ? char
              : boxData.char.includes(char)
              ? boxData.char
              : boxData.char + char;
        }
      }
    } else {
      let startIndex = arrData.offset.row;
      if (startIndex + arrData.word.word.length > this.setting.maxRows) {
        startIndex = this.setting.maxRows - arrData.word.word.length;
      }

      for (let i = startIndex; i < startIndex + arrData.word.word.length; i++) {
        const boxData = this.wordGridArray[i][arrData.offset.column];

        if (boxData != null) {
          const char = arrData.word.word.charAt(i - startIndex).toUpperCase();
          boxData.correct = false;
          boxData.answer = '';

          // then add the correct answer.
          boxData.wordId =
            boxData.wordId && boxData.wordId.length > 0
              ? [...boxData.wordId, arrData.word.id]
              : [arrData.word.id];

          boxData.char =
            boxData.char === GameUtils.hiddenCharacter
              ? char
              : boxData.char.includes(char)
              ? boxData.char
              : boxData.char + char;
        }
      }
    }
    return this.wordGridArray;
  };

  initWordList = (): GridWordProps[][] => {
    const wordList = [];

    for (let i = 0; i < this.setting.maxRows; i++) {
      const word_grid_row: any[] = [];

      for (let j = 0; j < this.setting.maxColumns; j++) {
        word_grid_row.push({
          id: `row-${i}-column-${j}`,

          row: i,
          column: j,

          char: GameUtils.hiddenCharacter,
          correct: false,
        });
      }

      wordList.push(word_grid_row);
    }

    return wordList;
  };

  getResult = (): GridWordProps[][] => {
    return this.wordGridArray;
  };
}
