import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import React, { useEffect, useState } from 'react';
import { PaginationReq } from '@services/model/PaginationRes';
import { Select, Tag, Typography } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import { PlusOutlined } from '@ant-design/icons';
import Button from '@components/button';
import ColorUtils from '@utils/ColorUtils';
import { H5 } from '@components/typography';
import { useTranslation } from 'react-i18next';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { useSelector } from '@app/redux/hook';
const { Option, OptGroup } = Select;
const { Text } = Typography;

const SelectStandards = (props: {
  standardGroup?: number; // using standard group to create an standard set....
  formik: any;
  changeToAddMode?: () => void;
}) => {
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    StandardSetAPI.endpoints.findAll.useLazyQuery();
  const { isReady } = useSelector((state) => state.userInformation);

  const [params] = useState<PaginationReq>({
    page: 0,
    size: 0,
    selectAll: true,
    sort: 'displayOrder',
    direction: 'descend',
  });
  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (isReady) {
      reloadData();
    }
  }, [isReady]);

  const reloadData = () => {
    findAll({ ...params });
  };

  const handleOnSearch = (keyword: string) => {
    setKeyword(keyword);
  };

  const handleValueChange = (value: any) => {
    setKeyword('');
    props.formik.setFieldValue('standards', value);
  };

  useEffect(() => {
    if (data && data.content) {
      const filterOptions: any[] = [];
      const kw = keyword.trim().toLowerCase();

      data.content.forEach((stSet) => {
        const matching =
          kw === '' ||
          stSet.code.toLowerCase().indexOf(kw) > -1 ||
          stSet.name.toLowerCase().indexOf(kw) > -1 ||
          stSet.description.toLowerCase().indexOf(kw) > -1;

        const standardOptions: any[] = [];

        stSet.standards.forEach((st) => {
          const stMatching =
            kw === '' ||
            st.code.toLowerCase().indexOf(kw) > -1 ||
            st.name.toLowerCase().indexOf(kw) > -1 ||
            st.description.toLowerCase().indexOf(kw) > -1;

          if (matching || stMatching) {
            standardOptions.push({
              label: (
                <>
                  <Tag color={ColorUtils.getDefaultColor(st.standardId).color}>
                    {st.code}
                  </Tag>
                  <b>{st.name}: &nbsp;</b>
                  <Text>
                    <i>
                      <LanguageContentTag content={st.description} />
                    </i>
                  </Text>
                </>
              ),
              standardId: st.standardId,
            });
          }
        });

        if (standardOptions.length > 0) {
          const optionGroup = {
            label: (
              <H5>
                <Tag
                  color={ColorUtils.getDefaultColor(stSet.standardSetId).color}
                >
                  {stSet.code}
                </Tag>
                <b>{stSet.name}: &nbsp;</b>
                <Text>
                  <i>
                    <LanguageContentTag content={stSet.description} />
                  </i>
                </Text>
              </H5>
            ),
            value: stSet.standardSetId + '-group-set',
            options: [...standardOptions],
          };
          filterOptions.push(optionGroup);
        }
      });

      setOptions(filterOptions);
    } else {
      setOptions([]);
    }
  }, [data, keyword]);

  return (
    <InputGroup
      label={
        <>
          {t('standard.actions.select')}

          <Button
            type={'primary'}
            shape="circle"
            size={'small'}
            onClick={props.changeToAddMode}
          >
            <PlusOutlined />
          </Button>
        </>
      }
    >
      <Select
        size={'large'}
        placeholder={t('standard.actions.select_standards')}
        style={{ width: '100%' }}
        loading={isFetching}
        showSearch
        mode={'multiple'}
        value={props.formik.values.standards}
        onSearch={handleOnSearch}
        onChange={handleValueChange}
        getPopupContainer={(child) => {
          return child.parentNode;
        }}
      >
        {options.map((data, groupIndex) => {
          return (
            <OptGroup
              label={data.label}
              key={data.value + '_' + groupIndex + '_' + keyword}
            >
              {data.options.map((option: any, index: number) => {
                return (
                  <Option
                    key={option.standardId + '_' + index + '_' + keyword}
                    value={option.standardId}
                  >
                    {option.label}
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
    </InputGroup>
  );
};

export default SelectStandards;
