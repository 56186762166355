import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { NextQuestionBtn } from '@cms/lesson-template/components/button-wrapper/NextQuestionBtn';
import { PreviousQuestionBtn } from '@cms/lesson-template/components/button-wrapper/PreviousQuestionBtn';

export const LessonPresentationLayout = (props: {
  style?: any;
  children: ReactNode;
}) => {
  return (
    <WrapStyle className={'cms-presentation-layout'} style={props.style ?? {}}>
      <div className={'section-action left-action'}>
        <PreviousQuestionBtn />
      </div>

      {props.children}

      <div className={'section-action right-action'}>
        <NextQuestionBtn />
      </div>
    </WrapStyle>
  );
};

const WrapStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .exercise-toolbar {
    display: none;
  }

  .section-action {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.01);

    &:hover {
      background: rgba(0, 0, 0, 0.1);

      .ant-btn {
        background: #fff;
      }
    }

    &.left-action {
      left: 0;
      z-index: 1;
    }

    &.right-action {
      right: 0;
      z-index: 1;
    }
  }
`;
