import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { SchoolRes } from '@services/private/SchoolAPI';
import { LanguageRes } from '@services/setting/LanguageAPI';
import { UserCertificateRes } from '@modules/admin/service/program';
import { JwtRes } from '@modules/authorization/services/model';

export interface PublicUserProfile {
  userId: number;
  uuid: string;
  username: string;

  avatar: string;

  firstName: string;
  lastName: string;

  email: string;
  phone: string;

  displayName: string;
  shortName: string;

  schools: SchoolRes[];
  school: SchoolRes | null;
}

export interface MyProfileRes {
  userId: number;
  uuid: string;
  userName: string;

  avatar: string;

  firstName: string;
  lastName: string;

  email: string;
  phone: string;

  gender: string;
  birthday: string;
  //
  // displayName: string;
  // shortName: string;
  //
  schools: SchoolRes[];
  school: SchoolRes | null;

  theme?: string;
  language: LanguageRes;
  proUser: boolean;
}

export interface UpdateMyProfileReq {
  avatar: string;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  gender: string;
  birthday: string;
  language: string;
  theme: string;
  proUser?: boolean;
}

export interface UpdatePasswordReq {
  password: string;
  newPassword: string;
}

export interface ZoomAccountRes {
  zoomEmail: string;
  zoomApiKey: string;
  zoomApiSecret: string;
}

export interface UpdateZoomAccountReq {
  zoomEmail: string;
  zoomApiKey: string;
  zoomApiSecret: string;
}

export interface BecomeProUserReq {
  proUser: boolean;
}

export const MyProfileAPI = createApi({
  reducerPath: 'MyProfileAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getLoginData: builder.query<JwtRes, unknown>({
      query: () => ({
        url: `/profile/refresh`,
      }),
    }),

    getMyProfile: builder.query<MyProfileRes, unknown>({
      query: () => ({
        url: `/profile`,
      }),
    }),

    updateMyProfile: builder.mutation<MyProfileRes, UpdateMyProfileReq>({
      query: (body: UpdateMyProfileReq) => ({
        url: `/profile`,
        method: 'PUT',
        body,
      }),
    }),

    updatePassword: builder.mutation<BasicMessageRes, UpdatePasswordReq>({
      query: (body: UpdatePasswordReq) => ({
        url: '/profile/password',
        body,
        method: 'PUT',
      }),
    }),

    becomeProUser: builder.mutation<BasicMessageRes, BecomeProUserReq>({
      query: (body: BecomeProUserReq) => ({
        url: '/profile/become-pro-user',
        body,
        method: 'PUT',
      }),
    }),

    getZoomAccountProfile: builder.query<ZoomAccountRes, unknown>({
      query: () => ({
        url: `/profile/zoom-account`,
        method: 'GET',
      }),
    }),

    updateZoomAccount: builder.mutation<ZoomAccountRes, UpdateZoomAccountReq>({
      query: (body: UpdateZoomAccountReq) => ({
        url: '/profile/zoom-account',
        body,
        method: 'PUT',
      }),
    }),

    getCertificates: builder.query<UserCertificateRes[], unknown>({
      query: () => ({
        url: `/profile/certificates`,
        method: 'GET',
      }),
    }),
  }),
});
