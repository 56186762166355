import React, { useEffect } from 'react';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import LoginSchoolPage from '@modules/authorization/pages/LoginSchoolPage';
import { useRouter } from '@hooks/useRouter';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';

const LoginToAnotherSchool = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { clientId } = router.query as unknown as { clientId: string };

  useEffect(() => {
    console.log(router);
  }, [router]);

  return (
    <PublicLayout>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('login.title')}
        description={t('login.title')}
      />
      <LoginSchoolPage clientId={clientId} />
    </PublicLayout>
  );
};

export default LoginToAnotherSchool;
