import React from 'react';

import { AuthorizeStyle } from '../styled/PageStyle';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import RegisterUserForm from '@modules/authorization/container/RegisteUserForm';
import { RoleEnum } from '@app/redux/slices/roles';
import Card from '@components/card';
import { H1 } from '@components/typography';
import { Default_Gutter } from '@components/grid';

import { SiteMap } from '@router/SiteMap';
import { useRouter } from '@hooks/useRouter';

export default function ParentRegistration(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const router = useRouter();

  const handleRegisterSuccess = () => {
    router.push(SiteMap.auth.login);
  };

  return (
    <AuthorizeStyle>
      <div className={'home-block-container'}>
        <div className={'gstudy-container'}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
              <p>
                Trong xã hội nhộn nhịp hiện nay, thật khó để chúng ta có thể
                dành thời gian quan tâm đến con cái.
              </p>

              <p>
                Gstudy thấu hiểu điều đó, và hi vọng có thể được đồng hành cùng
                phụ huynh trong quá trình trưởng thành của con trẻ.
              </p>

              <p>
                Bạn có thể dễ dàng tìm thấy các nội dung chất lượng cao trên hệ
                thống của chúng tôi và gửi tặng cho con như một món quà.
              </p>

              <p>
                Không chỉ vậy, bạn có thể theo dõi tiến trình học tập của con
                bất cứ lúc nào, cũng như liên lạc với giáo viên khi gặp phải
                những vấn đề cần thiết.
              </p>

              <p>
                Với Gstudy, bạn có thể luôn ở bên con, đồng hành cùng con.
                Gstudy chia sẻ các công cụ giúp bạn và con có thể tương tác với
                nhau qua internet, xóa bỏ những trở ngại về khoảng cách.
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
              <Card>
                <H1>{t('header.parent_registration')}</H1>

                <RegisterUserForm
                  disabled={props.disabled}
                  role={RoleEnum.PARENT}
                  successHandle={handleRegisterSuccess}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </AuthorizeStyle>
  );
}
