import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  ExerciseRes,
  ExerciseResponseRes,
  FilterExerciseReportReq,
  LessonExerciseFilterReq,
  ShowCorrectAnswerReq,
  UpdateExerciseCompletedDateReq,
} from '@modules/assignments/service/exercise_model';

export const ExerciseAPI = createApi({
  reducerPath: 'ExerciseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAllStudentExercise: builder.query<
      PaginationRes<ExerciseRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/exercises`,
        params,
      }),
    }),

    calculateStandards: builder.mutation<
      BasicMessageRes,
      UpdateExerciseCompletedDateReq
    >({
      query: (body) => ({
        url: `/exercises/${body.exerciseId}/calculate-standards`,
        method: 'POST',
        body,
      }),
    }),

    redoExercise: builder.mutation<BasicMessageRes, number>({
      query: (exerciseId) => ({
        url: `/exercises/${exerciseId}/redo`,
        method: 'POST',
      }),
    }),

    getExercise: builder.query<ExerciseRes[], LessonExerciseFilterReq>({
      query: (params) => ({
        url: `/exercises/lessons/${params.lessonId}`,
        params,
      }),
    }),

    viewExerciseInfo: builder.query<ExerciseRes, number>({
      query: (exerciseId) => ({
        url: `/exercises/${exerciseId}/info`,
      }),
    }),

    showCorrectAnswer: builder.mutation<
      ExerciseResponseRes[],
      ShowCorrectAnswerReq
    >({
      query: (body) => ({
        url: `/exercises/self-check/show-correct-answer`,
        body,
        method: 'POST',
      }),
    }),

    viewExerciseReports: builder.query<
      PaginationRes<ExerciseRes>,
      FilterExerciseReportReq
    >({
      query: (params) => ({
        url: `/exercises/lessons/${params.lessonId}/view`,
        params,
      }),
    }),
  }),
});
