import React, { useEffect, useState } from 'react';

import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { CompMode, CompProps } from '@cms/ComponentInteface';
import {
  ResourceContextProvider,
  ResourceItem,
} from '@cms/context/ResourceContextProvider';
import Button from '@components/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LessonExampleAPI } from '@modules/admin/service/LessonExampleAPI';

export default function ContentBanks(props: {
  lessonExampleId: number;
  onSelect: (compData: CompProps[]) => void;
}) {
  const { t } = useTranslation();

  const [viewLessonExampleContent, { data, isSuccess }] =
    LessonExampleAPI.endpoints.viewLessonExampleContent.useLazyQuery({});

  const [resources, setResources] = useState<ResourceProps[]>([]);

  useEffect(() => {
    if (props.lessonExampleId != null && props.lessonExampleId > 0) {
      viewLessonExampleContent(props.lessonExampleId);
    }
  }, [props.lessonExampleId]);

  useEffect(() => {
    if (data && isSuccess) {
      setResources(ResourceUtils.getResources(data.sections));
    }
  }, [data, isSuccess]);

  const handleOnClick = (res: ResourceProps) => {
    props.onSelect(res.components);
  };

  return (
    <ContentBanksStyle className={'content-bank lesson-template'}>
      {resources.map((res) => {
        return (
          <div
            className={'resource-selectable show-correct-answer'}
            key={res.resourceId}
          >
            <div className={'resource-selectable-item'}>
              <ResourceContextProvider
                mode={CompMode.REVIEW}
                resourceId={res.resourceId}
                comps={res.components}
                answer={res.correctAnswer}
                feedback={[]}
              >
                <ResourceItem />
              </ResourceContextProvider>
            </div>

            <div className={'resource-action'}>
              <Button
                size={'small'}
                shape={'round'}
                type={'primary'}
                onClick={() => handleOnClick(res)}
              >
                {t('component.actions.add')}
              </Button>
            </div>
          </div>
        );
      })}
    </ContentBanksStyle>
  );
}

export const ContentBanksStyle = styled.div`
  .resource-selectable {
    position: relative;
    background: #fff;
    margin-bottom: 10px;
    padding: 0.5em;
    max-width: 100%;
    overflow-x: hidden;

    .resource-selectable-item {
      position: relative;
      min-height: 50px;
      max-height: 225px;
      overflow: hidden;
      z-index: 1;

      .resource-comps {
        width: 100%;
        font-size: 10px;

        .cms-component-full-size {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }

    .resource-action {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      &:hover {
        opacity: 1;
        background: rgba(0, 0, 0, 0.35);
      }

      .ant-btn {
        position: absolute;
        right: 5px;
        bottom: 5px;
      }
    }
  }
`;
