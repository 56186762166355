import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@hooks/useRouter';
import { useSelector } from '@app/redux/hook';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { SiteMap } from '@router/SiteMap';

const NotFoundPageStyle = styled.div`
  .not-available-page-content {
    font-size: 30px;
  }
`;

const Page404 = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { isAuth } = useSelector((state) => state.profile);

  const redirectHomePage = () => router.push(SiteMap.index);
  const redirectDashboardPage = () => router.push(SiteMap.private.dashboard);

  return (
    <PublicLayout>
      <PageHeader
        menu={NavigationMenuEnum.EMPTY}
        subMenu={NavigationMenuEnum.EMPTY}
        title={`${t('organization')} - ${t('page_not_found.header')}`}
        description={t('page_not_found.header')}
      />

      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <NotFoundPageStyle>
          <div className="not-available-page-content">
            <p className="warning-title">{t('page_not_found.header')}</p>
            <p className="warning-description">{t('page_not_found.message')}</p>

            {isAuth && (
              <Button type="primary" onClick={redirectDashboardPage}>
                {t('page_not_found.actions.back_to_dashboard')}
              </Button>
            )}

            {!isAuth && (
              <Button type="primary" onClick={redirectHomePage}>
                {t('page_not_found.actions.back_to_index')}
              </Button>
            )}
          </div>
        </NotFoundPageStyle>
      </div>
    </PublicLayout>
  );
};

export default Page404;
