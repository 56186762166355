import React from 'react';
import { CompConfiguration } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { useAudioRecordingCompContext } from '@cms/comps/recording/AudioRecordingCompContext';
import AudioRecordingItemContent from './AudioRecordingItemComp';

const AudioRecordingGroupItemStyle = styled.div`
  .audio-recording-inline-group-item {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
`;

const AudioRecordingGroupItems = () => {
  const {
    modifiedTime,
    disabled,
    feedback,
    sourceItems,
    answers,
    handleOnAnswerChange,
  } = useAudioRecordingCompContext();

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });

    return inlineAns;
  };

  return (
    <AudioRecordingGroupItemStyle className={'audio-recording-inline-group'}>
      {sourceItems &&
        sourceItems.map((drop, index) => {
          const textArray =
            drop.content && drop.content.data
              ? drop.content.data.split(CompConfiguration.INLINE_COMPONENT)
              : [];

          return (
            <div
              className={`audio-recording-inline-group-item  group-${index}`}
              key={drop.label + '_' + drop.content.id + '_' + modifiedTime}
            >
              {textArray.map((text, idx) => {
                const sourceData = `${drop.label}|${idx}`;

                return (
                  <React.Fragment key={text + '_' + idx}>
                    {idx > 0 && (
                      <AudioRecordingItemContent
                        disabled={disabled}
                        answer={getInlineAnswer(sourceData)}
                        feedback={feedback}
                        onChange={(answer) =>
                          handleOnAnswerChange(sourceData + ':' + answer)
                        }
                      />
                    )}

                    <div
                      className={'dropdown-inline-text'}
                      dangerouslySetInnerHTML={{ __html: text + '&nbsp;' }}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </AudioRecordingGroupItemStyle>
  );
};

export default AudioRecordingGroupItems;
