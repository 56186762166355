import React, { useState } from 'react';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import RequireLoginButton from '@components/button/RequireLoginButton';
import { ShoppingCartOutlined, UnlockOutlined } from '@ant-design/icons';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ViewProductPrices } from '@modules/public/components/licenses/ViewProductPrices';
import { useTranslation } from 'react-i18next';

enum ViewProductAction {
  default = '',
  purchase_license = 'purchase_license',
}

export const PurchaseProductButton = (props: {
  type: 'unlock' | 'purchase';
  price: number;
  lessonId: number;
  productTocId: number;
  productId: number;
  name: string;
}) => {
  const { t } = useTranslation();

  const [action, setAction] = useState({
    action: ViewProductAction.default,
    timestamp: -1,
  });

  const getProductLessonLicenses = () => {
    setAction({
      action: ViewProductAction.purchase_license,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  const handleOnClose = () => {
    setAction({
      action: ViewProductAction.default,
      timestamp: DateAndTimeUtils.getCurrentTime(),
    });
  };

  return (
    <>
      <RequireLoginButton
        size={'large'}
        shape={'round'}
        type={'primary'}
        onClick={getProductLessonLicenses}
      >
        {props.type === 'unlock' ? (
          <>
            <UnlockOutlined />{' '}
            {t('publish.lesson.spend_to_unlock', { price: props.price })}
          </>
        ) : (
          <>
            <ShoppingCartOutlined /> <b>{props.price}Gps</b>
          </>
        )}
      </RequireLoginButton>

      {action.action === ViewProductAction.purchase_license && (
        <CustomModal
          header={t('publish.lesson.purchase_license')}
          className={`${CustomModalClassEnum.large_modal} ${CustomModalClassEnum.no_padding}`}
          content={
            <ViewProductPrices
              lessonId={props.lessonId}
              productId={props.productId}
              productTocId={props.productTocId}
              onClose={handleOnClose}
            />
          }
          onCloseFunc={handleOnClose}
        />
      )}
    </>
  );
};
