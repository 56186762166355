import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { EntityStatusEnum } from '@services/model/common';
import SelectEntityStatus from '@modules/common/components/SelectEntityStatus';
import { H4 } from '@components/typography';
import { Button, notification } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';

export default function ProductStatusForm(props: {
  productId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const [getProduct, { data, isSuccess, isFetching }] =
    ProductAPI.endpoints.getProduct.useLazyQuery({});

  const [updateProductStatus, { isLoading: isUpdateLoading }] =
    ProductAPI.endpoints.updateProductStatus.useMutation({});

  useEffect(() => {
    getProduct(props.productId);
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setFieldValue('status', data.status);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      status: null as EntityStatusEnum | null,
    },
    onSubmit: (values) => {
      if (values.status) {
        const payload = {
          productId: props.productId,
          status: values.status,
        };

        updateProductStatus(payload)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('product.warning.update_product_metadata_success'),
              description: t(
                'product.warning.update_product_metadata_success_message'
              ),
              placement: 'bottomRight',
            });

            props.onSuccess();
          })
          .catch(() => {
            notification.error({
              message: t('product.warning.update_product_metadata_error'),
              description: t(
                'product.warning.update_product_metadata_error_message'
              ),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      {data && <H4>{data.name}</H4>}

      <SelectEntityStatus
        formik={formik}
        name={'status'}
        value={formik.values.status}
        selectOptions={[
          EntityStatusEnum.ACTIVE,
          EntityStatusEnum.PUBLISHED,
          EntityStatusEnum.REMOVED,
        ]}
      />

      <div className="submit-container">
        <ButtonGroup>
          <Button
            type={'primary'}
            loading={isFetching || isUpdateLoading}
            onClick={formik.submitForm}
          >
            {t('button.update')}
          </Button>

          <Button type="default" onClick={props.onCancel}>
            {t('button.cancel')}
          </Button>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
}
