const JqueryUtils = {
    getBBox : ($element: any, $container: any) => {
        const elOffset = $element.offset();
        const ctnOffset = $container.offset();

        return {
            x: elOffset.left - ctnOffset.left,
            y: elOffset.top - ctnOffset.top,
            width: $element.outerWidth(),
            height: $element.outerHeight(),
        };
    }
};

export default JqueryUtils;