import { Button, Col, notification, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { SelectStudentForm } from '@components/common/student-selector/SelectStudent';
import { UserRes } from '@modules/users/services/model';
import { ProductLicenseRes } from '@modules/license/services/model';

export const AssignProductLicense = (props: {
  productId: number;
  onSuccess: (data: ProductLicenseRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [assignLicenseForUsers] =
    UserProductLicenseAPI.endpoints.assignLicenseForUsers.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      userIds: [],
      availableDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      expiredDate: DateAndTimeUtils.add(
        DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
        DateAndTimeFormat.YYYY_MM_DD,
        5,
        'year'
      ),
    },
    onSubmit: (values) => {
      handleOnSubmit(values.userIds, values.availableDate, values.expiredDate);
    },
  });

  const handleOnSubmit = (
    userIds: number[],
    availableDate: string,
    expiredDate: string
  ) => {
    const request = {
      productId: props.productId,
      availableDate: availableDate,
      expiredDate: expiredDate,
      userIds: userIds,
    };

    assignLicenseForUsers(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('license.warning.add_success'),
          description: t('license.warning.add_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('license.warning.add_error'),
          description: t('license.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleSelectData = (data: UserRes) => {
    formik.setFieldValue('userIds', [...formik.values.userIds, data.userId]);
  };

  const handleClickRemove = (removeId: number) => {
    formik.setFieldValue(
      'userIds',
      [...formik.values.userIds].filter((id) => {
        return id !== removeId;
      })
    );
  };

  return (
    <FormikProvider value={formik}>
      {/*<Row gutter={[Default_Gutter, Default_Gutter]}>*/}
      {/*  <Col span={12}>*/}
      {/*    <InputGroup label={t('label.available_date')}>*/}
      {/*      <DatePicker*/}
      {/*        formik={formik}*/}
      {/*        name="availableDate"*/}
      {/*        value={formik.values.availableDate}*/}
      {/*        handleChange={formik.handleChange}*/}
      {/*      />*/}
      {/*    </InputGroup>*/}
      {/*  </Col>*/}

      {/*  <Col span={12}>*/}
      {/*    <InputGroup label={t('label.expired_date')}>*/}
      {/*      <DatePicker*/}
      {/*        formik={formik}*/}
      {/*        name="expiredDate"*/}
      {/*        value={formik.values.expiredDate}*/}
      {/*        handleChange={formik.handleChange}*/}
      {/*      />*/}
      {/*    </InputGroup>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <SelectStudentForm
            selectIds={formik.values.userIds}
            onSelect={handleSelectData}
            onRemove={handleClickRemove}
          />
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup type={'right'} className={'submit-container'}>
            <Button size={'large'} shape={'round'} onClick={props.onCancel}>
              {t('label.cancel')}
            </Button>

            <Button
              type={'primary'}
              size={'large'}
              shape={'round'}
              icon={IconUtils.menu.license}
              onClick={formik.submitForm}
              disabled={formik.values.userIds.length === 0}
            >
              {t('license.actions.assign_license')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};
