import React from 'react';
import styled from 'styled-components';

const ColorCircle = ({ size, color, children, ...props }: any) => {
  return (
    <ColorCircleStyle className={'color-circle'} color={color} {...props}>
      {children}
    </ColorCircleStyle>
  );
};

export const MediumColorCircle = ({ size, color, children, ...props }: any) => {
  return (
    <ColorCircleStyle
      className={'color-circle medium'}
      color={color}
      {...props}
    >
      {children}
    </ColorCircleStyle>
  );
};

export const SmallColorCircle = ({ color, children, ...props }: any) => {
  return (
    <SmallColorCircleStyle
      className={'small-color-circle'}
      color={color}
      {...props}
    >
      <span className={'label-display'}>{children}</span>
    </SmallColorCircleStyle>
  );
};

const ColorCircleStyle = styled.span`
  background-color: ${(props) => props.color};
  border: 1px solid #bebebe;

  width: 64px;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;

  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.color.white};

  &.medium {
    width: 44px;
    height: 44px;
    font-size: 14px;
  }

  &.small {
  }
`;

const SmallColorCircleStyle = styled.span`
  background-color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};

  width: 32px;
  height: 32px;
  font-size: 16px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;

  color: ${(props) => props.theme.color.white};

  .label-display {
    font-size: 70%;
  }
`;

export default ColorCircle;
