import { LeftOutlined } from '@ant-design/icons';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useTheme } from '@app/styled/StyledProvider';

export const ExitExerciseButton = () => {
  const theme = useTheme();
  const { exitExercise } = useViewLessonContext();

  return (
    <LessonButton
      className={'exit-exercise-btn'}
      size="large"
      type={'default'}
      shape={'circle'}
      onClick={exitExercise}
      style={{
        background: theme.exercise.exit_grading,
        color: theme.exercise.exit_grading_text,
      }}
      icon={<LeftOutlined />}
    />
  );
};
