import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import SchoolContentPages from '@modules/page-site/pages/SchoolContentPage';

const ManagePages = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-page-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PAGES}
        subMenu={NavigationMenuEnum.PAGES_STATIC_PAGE}
        title={t('menu.school_content')}
        description={t('menu.school_content')}
      />
      <SchoolContentPages />
    </ManagementLayout>
  );
};

export default ManagePages;
