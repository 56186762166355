import { OneColumnSectionData } from '@cms/section-bank/columns/OneColumnSection';
import { TwoColumnSectionData } from '@cms/section-bank/columns/TwoColumnSection';
import { ThreeColumnSectionData } from '@cms/section-bank/columns/ThreeColumnSection';
import { FixedGroupSectionData } from '@cms/section-bank/fixed-group/FixedGroupSection';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { InteractVideoSectionData } from '@cms/section-bank/interact-video/InteractVideoSection';

export interface SectionBankProps {
  name: string;
  item: LessonSectionProps;
}

export const sectionBankTemplates: SectionBankProps[] = [
  { name: 'One Column', item: OneColumnSectionData },
  { name: 'Two Columns', item: TwoColumnSectionData },
  { name: 'Three Columns', item: ThreeColumnSectionData },
  { name: 'Fixed Groups', item: FixedGroupSectionData },
  { name: 'Interact Video', item: InteractVideoSectionData },
];
