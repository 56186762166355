export interface AccessClassRoomReq {
  code?: string;
  token?: string;
  accessCode?: string;
}

export enum ClassRoomErrorCode {
  session_not_found = 'session.not.found',
  access_deny = 'access.session.forbidden',
  access_code_require = 'access.code.require',
}
