import React from 'react';
import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import Licenses from '@router/pages/license/licenses';
import ManagerBookLicense from '@router/pages/license/book_license';

export const LicenseRouters: PageRouterProps[] = [
  {
    path: SiteMap.license.book,
    element: (
      <PrivateRouter
        roles={SiteMap.license.book_roles}
        element={<Licenses />}
      />
    ),
  },

  {
    path: SiteMap.license.book_license,
    element: (
      <PrivateRouter
        roles={SiteMap.license.book_license_roles}
        element={<ManagerBookLicense />}
      />
    ),
  },
];
