import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import {
  LessonNavigationAction,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import { useEffect } from 'react';
import { CompMode } from '@cms/ComponentInteface';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import { UpdateExerciseStatusReq } from '@modules/assignments/service/exercise_model';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';

const useUpdateExerciseStatus = () => {
  const { type } = useViewLessonContext();
  const { exerciseId } = useExerciseServiceContext();
  const { action, resource, section, updateNavigation } =
    useLessonNavigationContext();

  const [updateExercise] = DoExerciseAPI.endpoints.updateExercise.useMutation();

  useEffect(() => {
    if (action.action === LessonNavigationAction.UPDATE_NAVIGATION) {
      if (type === CompMode.DO_ASSIGNMENT || type === CompMode.DO_EXERCISE) {
        updateExerciseStatus();
      }
    }
  }, [action.action]);

  const updateExerciseStatus = () => {
    const request: UpdateExerciseStatusReq = {
      exerciseId: exerciseId,
      sectionId: section.sectionId,
      resourceId: resource.resourceId,
    };

    updateExercise(request)
      .unwrap()
      .then(() => {
        updateNavigation(LessonNavigationAction.DEFAULT, null);
      })
      .catch((err) => {
        console.log('Have error when save exercise', err);
      });
  };
};

export default useUpdateExerciseStatus;
