import { ProductAPI } from '@modules/product/services/ProductAPI';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, notification } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React, { useEffect, useMemo, useState } from 'react';
import NavLink from '@components/button/NavLink';

import { SiteMap } from '@router/SiteMap';
import { ProductActionEnum } from '@modules/product/container/product/TeacherProductGroup';
import useConfirmModal from '@components/modal/ConfirmModal';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import ProductManageForm from '@modules/product/components/product/ProductManageForm';
import i18next from 'i18next';
import ProductStatusForm from '@modules/product/components/product/ProductStatusForm';
import { AssignProductLicense } from '@modules/license/components/AssignProductLicense';
import { ProductRes } from '@modules/product/services/product_model';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';

export const ComposeProductAction = (props: {
  item: ProductRes;
  onChange: () => void;
}) => {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  const [action, setAction] = useState<ProductActionEnum>(
    ProductActionEnum.DEFAULT
  );

  const handleOnActionChange = (action: ProductActionEnum) => {
    if (action === ProductActionEnum.REFRESH) {
      props.onChange();
      handleOnCancel();
    } else {
      setAction(action);
    }
  };

  const handleOnCancel = () => {
    setAction(ProductActionEnum.DEFAULT);
  };

  const handleOnChange = () => {
    handleOnCancel();
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: 'product-review',
              icon: IconUtils.actions.review,
              label: (
                <NavLink
                  href={SiteMap.content.product.review_gen(
                    props.item.productId
                  )}
                >
                  {t('button.review')}
                </NavLink>
              ),
            },
            {
              key: 'lesson-manager',
              icon: IconUtils.table_of_content,
              label: (
                <NavLink
                  href={SiteMap.content.product.toc_gen(props.item.productId)}
                >
                  {t('product.table_of_content')} {IconUtils.edit}
                </NavLink>
              ),
            },
            {
              key: 'divider-1',
              type: 'divider',
            },
            {
              key: 'edit',
              icon: IconUtils.edit,
              label: t('label.edit'),
              onClick: () => setAction(ProductActionEnum.EDIT_PRODUCT),
            },

            {
              key: 'remove',
              icon: IconUtils.actions.delete,
              label: t('button.remove'),
              onClick: () => setAction(ProductActionEnum.REMOVE_PRODUCT),
            },
            {
              key: 'divider-2',
              type: 'divider',
            },

            {
              key: 'assign-license',
              icon: IconUtils.actions.assign_license,
              label: t('license.actions.assign_license'),
              onClick: () => setAction(ProductActionEnum.ASSIGN_LICENSE),
            },

            {
              key: 'update-status',
              icon: IconUtils.lesson.status,
              label: t('product.actions.update_status'),
              onClick: () => setAction(ProductActionEnum.UPDATE_STATUS),
            },

            ifAnyGranted([RoleEnum.BOOK_LICENSE])
              ? {
                  key: 'publish',
                  icon: IconUtils.actions.publish,
                  label: (
                    <NavLink
                      href={SiteMap.content.product.publish_gen(
                        props.item.productId
                      )}
                    >
                      {t('product.publish')}
                    </NavLink>
                  ),
                }
              : null,
          ],
        }}
        trigger={['click']}
      >
        <Button type="default" shape="circle" icon={IconUtils.more_icon} />
      </Dropdown>

      <ComposeProductActionHandle
        action={action}
        item={props.item}
        onChange={handleOnChange}
        onActionChange={handleOnActionChange}
        onCancel={handleOnCancel}
      />
    </>
  );
};

const ComposeProductActionHandle = (props: {
  action: ProductActionEnum;
  item: ProductRes;
  onActionChange: (action: ProductActionEnum) => void;
  onChange: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [deleteProduct] = ProductAPI.endpoints.deleteProduct.useMutation();
  const [validateProduct] = ProductAPI.endpoints.validateProduct.useMutation();

  const handleDelete = () => {
    confirm(
      'danger',
      t('header.confirm'),
      t('product.warning.remove_confirm'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteProduct(props.item.productId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('product.warning.remove_success'),
                description: t('product.warning.remove_success_message'),
                placement: 'bottomRight',
              });
              props.onChange();
            })
            .catch(() => {
              notification.error({
                message: t('product.warning.remove_error'),
                description: t('product.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        } else {
          props.onActionChange(ProductActionEnum.DEFAULT);
        }
      }
    );
  };

  const handleValidation = () => {
    validateProduct(props.item.productId)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('product.warning.update_product_success'),
          description: t('product.warning.update_product_success_message'),
          placement: 'bottomRight',
        });
        props.onChange();
      })
      .catch(() => {
        notification.error({
          message: t('product.warning.update_product_error'),
          description: t('product.warning.update_product_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  useEffect(() => {
    if (props.action === ProductActionEnum.REMOVE_PRODUCT) {
      handleDelete();
    } else if (props.action === ProductActionEnum.VALIDATION) {
      handleValidation();
    }
  }, [props.action]);

  const headerModal = useMemo(() => {
    if (props.action === ProductActionEnum.EDIT_PRODUCT) {
      return t('product.actions.edit');
    } else if (props.action === ProductActionEnum.SHARE_PRODUCT) {
      return t('product.actions.share');
    } else if (props.action === ProductActionEnum.INVITE_STUDENT) {
      return t('product.actions.invite');
    } else if (props.action === ProductActionEnum.EDIT_METADATA) {
      return t('product.actions.update');
    } else if (props.action === ProductActionEnum.SHARE_ACCESS_CODE) {
      return t('product.actions.share_public_link');
    } else if (props.action === ProductActionEnum.UPDATE_STATUS) {
      return t('product.actions.update_status');
    } else {
      return '';
    }
  }, [props.action, i18next.language]);

  return (
    <>
      {(props.action === ProductActionEnum.EDIT_PRODUCT ||
        props.action === ProductActionEnum.EDIT_METADATA ||
        props.action === ProductActionEnum.SHARE_PRODUCT ||
        props.action === ProductActionEnum.INVITE_STUDENT ||
        props.action === ProductActionEnum.SHARE_ACCESS_CODE) && (
        <CustomModal
          header={headerModal}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ProductManageForm
              productId={props.item.productId}
              type={props.action}
              onSuccess={() => props.onActionChange(ProductActionEnum.REFRESH)}
              onClose={props.onChange}
              handleOnChangeTab={props.onActionChange}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}

      {props.action === ProductActionEnum.UPDATE_STATUS && (
        <CustomModal
          header={headerModal}
          className={CustomModalClassEnum.default}
          content={
            <ProductStatusForm
              productId={props.item.productId}
              onSuccess={() => props.onActionChange(ProductActionEnum.REFRESH)}
              onCancel={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}

      {props.action === ProductActionEnum.ASSIGN_LICENSE && (
        <CustomModal
          header={t('license.actions.assign_license')}
          className={CustomModalClassEnum.large_modal}
          content={
            <AssignProductLicense
              productId={props.item.productId}
              onSuccess={() => props.onActionChange(ProductActionEnum.REFRESH)}
              onCancel={props.onCancel}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}
    </>
  );
};
