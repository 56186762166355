import React from 'react';
import { Col, Row } from 'antd';

import { PublishTableOfContents } from '../components/table-of-content/TableOfContent';
import { H3 } from '@components/typography';
import { PublicLessonRes } from '@services/publish/PublishSchool';
import { AnonymousViewLesson } from '@cms/lesson-template/AnnonymousViewLesson';
import NavLink from '@components/button/NavLink';
import { ProductInfoBox } from '@modules/public/components/products/ProductInfoBox';
import Card, { CardClassEnum } from '@components/card';
import { useTranslation } from 'react-i18next';
import { ViewTableOfContentToolbar } from '@modules/product/components/view-toc/ViewTableOfContentToolbar';
import { ViewProductContextWrapper } from '@modules/product/components/view-toc/ViewTableOfContentContext';
import { ViewMode } from '@services/model/common';
import styled from 'styled-components';
import Button from '@components/button';
import ButtonGroup from '@components/button/ButtonGroup';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';
import { ProductTocRes } from '@modules/product/services/product_model';
import { CompMode } from '@cms/ComponentInteface';
import { DoAnonymousExercisePage } from '@modules/assignments/pages/ViewAnonymousExercisePage';

export const LessonDetailPage = (props: PublicLessonRes) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleOnGoBack = () => {
    router.push(
      SiteMap.public.product_gen(props.product.productId, props.product.name)
    );
  };

  const selectLesson = (lesson: LessonRes, productToc: ProductTocRes) => {
    router.push(
      SiteMap.public.product_lesson_gen(
        props.product.productId,
        props.product.name,
        productToc.productTocId,
        lesson.lessonId,
        lesson.name
      )
    );
  };

  const selectProductToc = (productTocId: number) => {
    alert('go to lesson ' + productTocId);
  };

  return (
    <>
      {props.exerciseId > 0 ? (
        <ViewProductContextWrapper
          productId={props.product.productId}
          productTocId={props.productTocId}
          lessonId={props.lesson.lessonId}
          productTocs={props.tableOfContent}
          selectLesson={selectLesson}
          selectProductToc={selectProductToc}
        >
          {props.lesson.validation?.interact ? (
            <DoAnonymousExercisePage
              key={props.exerciseId + '_' + CompMode.DO_EXERCISE}
              type={CompMode.DO_EXERCISE}
              exerciseId={props.exerciseId}
              onExit={handleOnGoBack}
            />
          ) : (
            <AnonymousViewLesson
              data={props.lesson}
              key={props.lesson.lessonId}
              goBack={handleOnGoBack}
            />
          )}

          <ViewTableOfContentToolbar type={ViewMode.view}>
            <ButtonGroup type={'center'}>
              <NavLink
                href={SiteMap.public.product_gen(
                  props.product.productId,
                  props.product.name
                )}
              >
                <Button size={'large'} type={'primary'} shape={'round'} danger>
                  {t('button.back')}
                </Button>
              </NavLink>
            </ButtonGroup>
          </ViewTableOfContentToolbar>
        </ViewProductContextWrapper>
      ) : (
        <PublicProductDetailStyle className={'product-info-detail'}>
          <Row gutter={40}>
            <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={17}>
              <AnonymousViewLesson
                data={props.lesson}
                key={props.lesson.lessonId}
                goBack={handleOnGoBack}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={7}>
              <Card
                className={CardClassEnum.rectangle_box}
                style={{ marginBottom: '2.5em' }}
              >
                <ProductInfoBox product={props.product} author={props.author} />
              </Card>

              <NavLink
                href={SiteMap.public.product_gen(
                  props.product.productId,
                  props.product.name
                )}
              >
                <H3>{t('publish.product.view')}</H3>
              </NavLink>

              <PublishTableOfContents
                items={props.tableOfContent}
                product={props.product}
                selectLesson={props.lesson.lessonId}
                selectProductToc={props.productTocId}
              />
            </Col>
          </Row>
        </PublicProductDetailStyle>
      )}
    </>
  );
};

const PublicProductDetailStyle = styled.div`
  .product-info-group {
    margin-bottom: 1.5em;
  }
`;
