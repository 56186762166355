import React, { useEffect, useRef, useState } from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { CompProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';

import { AssetsRes } from '@modules/product/services/assets_model';
import ResourceEditableSingle from '@modules/product/components/resource/ResourceEditableSingle';
import styled from 'styled-components';

import {
  addComponents,
  clearComposeResourceData,
  initData,
} from '@app/redux/slices/composeResource';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { EditResourceJSON } from './EditResourceJSON';
import { useConfirmBrowserExit } from '@hooks/useConfirmBrowserExit';
import { ResourceRelativeData } from '@cms/lesson-template/compose/resource/ResourceRelativeData';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { IconUtils } from '@utils/IconUtils';
import ResourceWysiEditable from '@modules/product/components/resource/ResourceWysiEditable';
import LessonButton from '../../components/buttons/style';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { useDispatch, useSelector } from '@app/redux/hook';

const dropResourcePlaceHolder: ResourceProps = {
  resourceId: -1,

  title: '',
  description: '',
  keyword: '',

  components: [],
  correctAnswer: [],
  scoring: [],

  displayOrder: 1,
  questionNumber: 1,
  showQuestionNumber: true,
};

interface ComposeResourceSetting {
  type: 'complex' | 'simple';
  autoUpdate: boolean;
  allowDragToUpload: boolean;
}

const ComposeResourceWysiTemplate = (props: {
  resourceId: number;
  setting?: ComposeResourceSetting;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);

  const service = useLessonServiceContext();

  const { editMode, mode, changeToMode, action } = useLessonEditableContext();

  const [showResourceData, setShowResourceData] = useState(false);

  const { changed } = useSelector((state) => state.composeResource);

  const { enable, disable } = useConfirmBrowserExit(
    true,
    'Confirm before exit... '
  );

  // load resource data from resource id.
  useEffect(() => {
    if (props.resourceId && props.resourceId >= 0) {
      service.findById(props.resourceId);
    } else {
      dispatch(initData(null));
    }
    return () => {
      dispatch(clearComposeResourceData());
    };
  }, [props.resourceId]);

  useEffect(() => {
    dispatch(initData(service.resource));
  }, [service.resource]);

  useEffect(() => {
    if (changed.changed) {
      enable();
    } else {
      disable();
    }
  }, [changed]);

  // for lesson edit resource....

  const handleOnUploadFiles = (file: AssetsRes) => {
    const fileComp: CompProps = COMPONENT_UTILS.generateComps(file);
    dispatch(addComponents([fileComp]));
  };

  useEffect(() => {
    if (action.action === LessonEditableActionEnum.add_component) {
      if (container.current) {
        container.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [action, container]);

  return (
    <>
      <EditResourceTemplateStyle
        className={'lesson-content-editable show-correct-answer'}
      >
        <div className={'lesson-resources'}>
          {editMode === EditModeEnum.WYSI ? (
            <ResourceWysiEditable item={dropResourcePlaceHolder} />
          ) : (
            <ResourceEditableSingle
              item={dropResourcePlaceHolder}
              allowDragToUpload={
                props.setting ? props.setting.allowDragToUpload : true
              }
              handleOnUploadFiles={handleOnUploadFiles}
            />
          )}
        </div>

        <div className={'bottom-editable-resource'} ref={container} />

        {editMode === EditModeEnum.MULTIPLE_QUESTION && (
          <Space align={'end'} style={{ width: '100%' }}>
            <LessonButton
              type={mode === LessonMode.edit ? 'default' : 'primary'}
              shape={'round'}
              className={'action-button'}
              onClick={() => changeToMode(LessonMode.edit)}
              icon={IconUtils.compose.component_banks}
            >
              {t('lesson_compose.actions.show_components')}
            </LessonButton>
          </Space>
        )}

        {editMode !== EditModeEnum.WYSI && <ResourceRelativeData />}
      </EditResourceTemplateStyle>

      {showResourceData && (
        <CustomModal
          header={t('lesson_compose.actions.view_resource_data')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <EditResourceJSON onClose={() => setShowResourceData(false)} />
          }
          onCloseFunc={() => setShowResourceData(false)}
        />
      )}
    </>
  );
};

export default ComposeResourceWysiTemplate;

const EditResourceTemplateStyle = styled.div`
  padding-bottom: 50px;

  .lesson-subtitle {
    margin-bottom: 0.5em;

    .lesson-title-display {
      font-size: 200%;
    }
  }

  .resource-subtitle {
    margin-top: 0.5em;
    margin-bottom: 1em;

    .resource-title-display {
      font-size: 125%;
    }
  }
`;
