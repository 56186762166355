import React from 'react';
import { FeatureGroup } from './FeatureGroup';
import { RoleEnum } from '@app/redux/slices/roles';
import Paragraph from 'antd/lib/typography/Paragraph';

export const LicenseFeatures = () => {
  return (
    <div className={'feature-group'}>
      <Paragraph>
        Sau khi đã soạn bài xong, editor sẽ đánh dấu giáo trình này hoàn tất và
        hiệu trưởng hoặc Book license có thể assign quyển sách này cho các học
        sinh và giáo viên trong trường.
      </Paragraph>

      <Paragraph>
        Chỉ user nào có license của quyển sách này mới có thể
      </Paragraph>

      <FeatureGroup
        header={'Quản lý license.'}
        roles={[RoleEnum.BOOK_LICENSE, RoleEnum.SCHOOL]}
      >
        <p></p>
        <ol>
          <li>Đăng kí phụ huynh với link to children.</li>

          <li>Theo dõi các tài liệu mà con đã xem.</li>

          <li>Theo dõi kết quả học tâp</li>

          <li>Tạo session để học chung với con nếu ở xa.</li>
        </ol>
      </FeatureGroup>
    </div>
  );
};
