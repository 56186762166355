import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import { useEffect } from 'react';

export const useJumpToQuestion = (resourceId: number, contentRef: any) => {
  const { action } = useViewLessonContext();

  useEffect(() => {
    if (
      action.action === ExerciseActionEnum.go_to_question &&
      action.params === resourceId
    ) {
      if (contentRef.current != null) {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [action]);
};
