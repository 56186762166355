import { createSlice } from '@reduxjs/toolkit';
import { LanguageEnum } from '@components/language/LanguageIcon';
import i18next from 'i18next';
import cookie from 'js-cookie';
import env from '@utils/config';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';

export const getCurrentLanguage = (context: any): LanguageEnum => {
  return context.req &&
    context.req.cookies &&
    context.req.cookies.defaultLanguage
    ? context.req.cookies.defaultLanguage
    : env.DEFAULT_LANGUAGE;
};

export const getDefaultLanguage = (): LanguageEnum => {
  if (cookie.get('defaultLanguage')) {
    return cookie.get('defaultLanguage') as LanguageEnum;
  } else {
    return env.DEFAULT_LANGUAGE;
  }
};

const initialState = {
  ready: false,
  timestamp: -1,
  language: env.DEFAULT_LANGUAGE,
  languages: [] as LanguageEnum[],
  defaultLanguage: cookie.get('defaultLanguage')
    ? (cookie.get('defaultLanguage') as LanguageEnum)
    : null,
};

const appLanguageSlice = createSlice({
  name: 'appLanguage',
  initialState,

  reducers: {
    updateLanguage: (
      state,
      action: {
        payload: { languages: LanguageEnum[]; defaultLanguage: LanguageEnum };
      }
    ) => {
      state.ready = true;
      state.language = action.payload.defaultLanguage;
      state.languages = action.payload.languages;
      state.timestamp = -1;

      if (state.defaultLanguage === null) {
        i18next.changeLanguage(action.payload.defaultLanguage, () => {
          state.defaultLanguage = action.payload.defaultLanguage;
          cookie.set('defaultLanguage', action.payload.defaultLanguage);
        });
      }
    },

    updateDefaultLanguage: (state, action: { payload: LanguageEnum }) => {
      i18next.changeLanguage(action.payload, () => {
        cookie.set('defaultLanguage', action.payload);
        state.defaultLanguage = action.payload;
        state.timestamp = DateAndTimeUtils.getCurrentTime();
      });
    },
  },
});

const { actions, reducer } = appLanguageSlice;
export const { updateLanguage, updateDefaultLanguage } = actions;
export default reducer;
