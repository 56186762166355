import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  AssignmentGroupRes,
  CreateAssignmentGroupReq,
} from '@modules/assignments/service/assignment_model';

export const AssignmentGroupAPI = createApi({
  reducerPath: 'AssignmentGroupAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<AssignmentGroupRes>, PaginationReq>({
      query: (params) => ({
        url: `/assignment-groups`,
        params,
      }),
    }),

    findById: builder.query<AssignmentGroupRes, number>({
      query: (assignmentGroupId) => ({
        url: `/assignment-groups/${assignmentGroupId}`,
      }),
    }),

    createAssignmentGroup: builder.mutation<
      AssignmentGroupRes,
      CreateAssignmentGroupReq
    >({
      query: (body) => ({
        url: '/assignment-groups',
        method: 'POST',
        body,
      }),
    }),

    updateAssignmentGroup: builder.mutation<
      AssignmentGroupRes,
      CreateAssignmentGroupReq
    >({
      query: (body) => ({
        url: '/assignment-groups',
        body,
        method: 'PUT',
      }),
    }),

    deleteAssignmentGroup: builder.mutation<BasicMessageRes, number>({
      query: (assignmentGroupId) => ({
        url: `/assignment-groups/${assignmentGroupId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
