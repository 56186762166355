import { Link as LinkRouter } from 'react-router-dom';
import { ReactNode } from 'react';

export const Link = (props: {
  href: string;
  className?: string;
  passHref?: boolean;
  replace?: boolean;
  children: ReactNode;
}) => {
  return (
    <LinkRouter className={props.className} to={props.href}>
      {props.children}
    </LinkRouter>
  );
};
