import React, { useEffect, useState } from 'react';
import { StudentAssignmentAPI } from '@modules/assignments/service/StudentAssignmentAPI';
import { PaginationReq } from '@services/model/PaginationRes';
import { AssignmentItem } from '@modules/assignments/components/AssignmentItem';
import { List } from 'antd';
import styled from 'styled-components';

const UncompletedAssignmentStudent = () => {
  const [getUncompletedAssignment, { data, isSuccess }] =
    StudentAssignmentAPI.endpoints.getUncompletedAssignment.useLazyQuery();

  const [params] = useState<PaginationReq>({
    page: 1,
    size: 6,
    sort: 'createdDate',
    direction: 'descend',
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    getUncompletedAssignment(params);
  };

  return (
    <>
      {isSuccess && data && data.content.length > 0 && (
        <AssignmentStudentStyle className={'incoming-events'}>
          <List
            className={'in-progress-assignment'}
            itemLayout="horizontal"
            dataSource={data.content}
            renderItem={(assignment) => (
              <List.Item>
                <AssignmentItem
                  key={assignment.assignmentInstanceId}
                  item={assignment}
                />
              </List.Item>
            )}
          />
        </AssignmentStudentStyle>
      )}
    </>
  );
};

export default UncompletedAssignmentStudent;

export const AssignmentStudentStyle = styled.div`
  .ant-list-item {
    width: 100%;

    .ant-card {
      width: 100%;
      border-radius: 12px;

      .ant-card-body {
        padding: 12px;
      }
    }
  }
`;
