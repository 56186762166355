import { StandardWeightRes } from '@services/reports/LessonReport';

import ColorUtils from '@utils/ColorUtils';
import PieChartReport, {
  PieRecordData,
} from '@modules/reports/charts/pie/PieChart';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import Hint from '@components/text/Hint';
import React from 'react';
import { LessonReportChartStyle } from '@modules/reports/lesson/components/style';
import { StandardItems } from '@app/interact/StandardItem';
import { H3 } from '@components/typography';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonStandardWeight = (props: {
  lesson: LessonRes;
  standards: StandardWeightRes[];
}) => {
  const renderFunc = (data: StandardWeightRes): PieRecordData => {
    return {
      id: data.standard.code,
      label: data.standard.code,
      value: data.weight,
      color: ColorUtils.getDefaultColor(data.standard.standardId).background,
    };
  };

  const customGetColor = (params: { id: string; data: PieRecordData }) => {
    return params.data.color;
  };

  return (
    <LessonReportChartStyle className={'lesson-standard-group'}>
      <H3>
        Lesson Standard Chart{' '}
        <Hint
          icon={<InfoCircleOutlined />}
          header={<h3>Lesson Standard Chart</h3>}
          content={
            <>
              <p>
                Biểu đồ này thể hiện sự phân bố của các standard trong bài học
              </p>

              <p>
                Thông thường, các standard set thể hiện sự phân bố của các kĩ
                năng trong 1 bài kiểm tra
              </p>
            </>
          }
        />
      </H3>

      <div className={'standard-set-description'}>
        {props.standards.map((item) => {
          return (
            <LessonStandardInfo key={item.standard.standardId} item={item} />
          );
        })}
      </div>

      <PieChartReport
        className={'lesson-standard'}
        content={props.standards}
        size={300}
        render={renderFunc}
        getColor={customGetColor}
      />
    </LessonReportChartStyle>
  );
};

const LessonStandardInfoStyle = styled.div`
  margin-bottom: 1em;

  .standard-icon {
    width: 1.2em;
    height: 1.2em;
    border: 1px solid #000;
    display: inline-flex;
    margin-right: 1em;
  }
`;

export const LessonStandardInfo = (props: { item: StandardWeightRes }) => {
  return (
    <LessonStandardInfoStyle className={'standard-item'}>
      <StandardItems item={props.item.standard} />
    </LessonStandardInfoStyle>
  );
};
