import styled from 'styled-components';

const TimeLabelStyle = styled.span`
  white-space: nowrap;

  &.time-ranger-label {
  }
`;

export const DateLabel = (props: { label: string }) => {
  return (
    <TimeLabelStyle className={'date-label'}>
      {props.label ? props.label.split('T')[0] : ''}
    </TimeLabelStyle>
  );
};

export const TimeLabel = (props: { label: string }) => {
  return (
    <TimeLabelStyle className={'time-label'}>
      {props.label ? props.label.split('T')[1].substring(0, 5) : ''}
    </TimeLabelStyle>
  );
};

export const TimeRanger = (props: { from: string; to: string }) => {
  const fromDate = parseTime(props.from);
  const toDate = parseTime(props.to);

  if (fromDate.date === toDate.date) {
    return (
      <TimeLabelStyle className={'time-ranger-label'}>
        <TimeLabelStyle className={'time-duration'}>
          {fromDate.time}&nbsp; &rarr; &nbsp;{toDate.time}
        </TimeLabelStyle>
      </TimeLabelStyle>
    );
  } else {
    return (
      <TimeLabelStyle className={'time-ranger-label'}>
        <DateAndTimeLabel type={'block'} label={props.from} />
        &nbsp; &rarr; &nbsp;
        <DateAndTimeLabel type={'block'} label={props.to} />
      </TimeLabelStyle>
    );
  }
};

const parseTime = (date: string) => {
  return {
    date: date.split('T')[0],
    time: date.split('T')[1].substring(0, 5),
  };
};

export const DateAndTimeLabel = (props: {
  label?: string;
  type?: 'inline' | 'block';
  separator?: string;
}) => {
  if (props.label) {
    return (
      <DateAndTimeLabelStyle className={props.type ? props.type : 'block'}>
        <TimeLabelStyle className={'date-label'}>
          {props.label
            ? props.label.split(props.separator ? props.separator : 'T')[0]
            : ''}
        </TimeLabelStyle>

        <TimeLabelStyle className={'time-label'}>
          {props.label
            ? props.label.split(props.separator ? props.separator : 'T')[1]
            : ''}
        </TimeLabelStyle>
      </DateAndTimeLabelStyle>
    );
  } else {
    return null;
  }
};

const DateAndTimeLabelStyle = styled.span`
  text-align: center;
  display: inline-flex;

  &.inline {
    flex-direction: row;

    .time-label {
      margin-left: 1ch;
    }
  }

  &.block {
    flex-direction: column;
  }
`;
