import React, { useEffect, useState } from 'react';
import { SocialVideoPlayerProps } from '@cms/comps/presentation/social-video/SocialVideoComp';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import SocialVideo from '@cms/content/SocialVideoPlayList';

export const SocialMediaPlayerWorkSpace = (props: {
  item: SocialVideoPlayerProps | null;
  onClick?: () => void;
}) => {
  const [scale, setScale] = useState(() => {
    if (props.item) {
      return props.item.scale;
    } else {
      return 9 / 16;
    }
  });

  useEffect(() => {
    if (props.item) {
      setScale(props.item.scale);
    } else {
      setScale(9 / 16);
    }
  }, [props.item]);

  return (
    <SocialMediaPlayerWorkSpaceStyle
      className={`social-media-player-container`}
      style={{ paddingBottom: scale * 100 + '%' }}
    >
      {props.item ? (
        <SocialVideo type={props.item.type} src={props.item.src} />
      ) : (
        <div className={'social-media-player-empty'} onClick={props.onClick}>
          <PlusOutlined /> Add Video
        </div>
      )}
    </SocialMediaPlayerWorkSpaceStyle>
  );
};

export const SocialMediaPlayerReview = (props: {
  scale: number;
  src: string;
  type: 'facebook' | 'youtube' | 'twister';
}) => {
  return (
    <SocialMediaPlayerWorkSpaceStyle
      className={`social-media-player-container review-container`}
      style={{ paddingBottom: props.scale * 100 + '%' }}
    >
      {props.src ? (
        <SocialVideo type={props.type} src={props.src} />
      ) : (
        <div className={'social-media-player-empty'}>Please add Src url</div>
      )}
    </SocialMediaPlayerWorkSpaceStyle>
  );
};

const SocialMediaPlayerWorkSpaceStyle = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  .social-media-player-empty {
    font-size: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0.06);

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
