import React, { ReactNode } from 'react';
import { PreviousQuestionBtn } from '@cms/lesson-template/components/button-wrapper/PreviousQuestionBtn';
import { NextQuestionButton } from '@cms/lesson-template/components/buttons/NextQuestionButton';

export const LessonTwoPageLayout = (props: { children: ReactNode }) => {
  return (
    <div className={'lesson-two-page-layout'}>
      <PreviousQuestionBtn />
      {props.children}
      <NextQuestionButton />
    </div>
  );
};
