import React, { useEffect, useState } from 'react';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1, H4 } from '@components/typography';
import {
  UpdateLessonTemplateContentReq,
  LessonTemplateAPI,
  LessonTemplateLayoutEnum,
  LessonTemplateRes,
} from '@modules/product/services/LessonTemplateAPI';
import { useTranslation } from 'react-i18next';
import LessonTemplateContextSetting from '@cms/design-template/layout/DesignTemplateContext';
import { Affix, Button, Col, notification, Row, Steps } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import LessonTemplateConfig from '@cms/design-template/layout/LessonTemplateConfig';
import ViewLessonLayoutTemplate from '@cms/design-template/layout/ViewLessonLayoutTemplate';
import { Default_Gutter } from '@components/grid';
import { useFormik } from 'formik';
import { getDefaultTemplateStyle } from '@cms/design-template/layout/LessonTemplateLayoutEditable';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
const { Step } = Steps;

const LessonTemplateComposePage = (props: { lessonTemplateId: number }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [findById, { data, isSuccess }] =
    LessonTemplateAPI.endpoints.findById.useLazyQuery();
  const [updateContent, { isLoading }] =
    LessonTemplateAPI.endpoints.updateContent.useMutation({});

  const [current, setCurrent] = useState(0);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      singleMode: false,
      singleQuestionMode: false,
      stepByStep: false,
      layout: LessonTemplateLayoutEnum.responsive,
      ...getDefaultTemplateStyle(),
    },

    onSubmit: () => {
      handleSaveLessonTemplate();
    },
  });

  useEffect(() => {
    findById(props.lessonTemplateId);
  }, [props.lessonTemplateId]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreLessonData(data);
    }
  }, [data]);

  const restoreLessonData = (data: LessonTemplateRes) => {
    const setting =
      data.content != null && data.content !== ''
        ? JSON.parse(data.content)
        : getDefaultTemplateStyle();

    formik.setValues({
      ...setting,
      singleMode: data.singleMode,
      singleQuestionMode: data.singleQuestionMode,
      stepByStep: data.stepByStep,
      layout: data.layout ?? LessonTemplateLayoutEnum.responsive,
    });
  };

  const handleOnUpdateConfig = () => {
    setCurrent(1);
  };

  const handleOnUpdateSetting = () => {
    setCurrent(2);
  };

  const handleSaveLessonTemplate = () => {
    const request: UpdateLessonTemplateContentReq = {
      lessonTemplateId: props.lessonTemplateId,
      // config
      singleMode: formik.values.singleMode,
      singleQuestionMode: formik.values.singleQuestionMode,
      stepByStep: formik.values.stepByStep,
      layout: formik.values.layout ?? LessonTemplateLayoutEnum.responsive,
      // setting
      content: JSON.stringify(
        {
          fontSizeSetting: formik.values.fontSizeSetting,
          spacingSetting: formik.values.spacingSetting,
          colorSetting: formik.values.colorSetting,
          stylesheet: formik.values.stylesheet,
          script: formik.values.script,
          header: formik.values.header,
          footer: formik.values.footer,
        },
        null,
        2
      ),
    };

    updateContent(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('alert.success'),
          description: t('alert.update_success_message'),
          placement: 'bottomRight',
        });
        handleBackToLessonTemplate();
      })
      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotUpdate'),
          placement: 'bottomRight',
        });
      });
  };

  const handleBackToLessonTemplate = () => {
    router.push(SiteMap.content.metadata.lesson_template);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('lesson_template.header')}
          {data && (
            <span className={'default-text'}>&nbsp;-&nbsp;{data.name}</span>
          )}
        </H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={20} xl={16} xxl={14}>
          <Steps current={current}>
            <Step title="Lesson Config" />
            <Step title="Style and Layout" />
            <Step title="Review" />
          </Steps>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div
            className="steps-content"
            style={{ marginTop: '3em', marginBottom: '3em' }}
          >
            {(current === 0 || current === 1) && (
              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                  {current === 0 && (
                    <LessonTemplateConfig
                      formik={formik}
                      onCancel={handleBackToLessonTemplate}
                      onNext={handleOnUpdateConfig}
                    />
                  )}

                  {current === 1 && (
                    <LessonTemplateContextSetting
                      formik={formik}
                      onCancel={() => setCurrent(0)}
                      onNext={handleOnUpdateSetting}
                    />
                  )}
                </Col>

                <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                  <Affix offsetTop={0}>
                    <ViewLessonLayoutTemplate
                      singleMode={formik.values.singleMode}
                      singleQuestionMode={formik.values.singleQuestionMode}
                      stepByStep={formik.values.stepByStep}
                      type={formik.values.layout}
                      setting={{
                        fontSizeSetting: formik.values.fontSizeSetting,
                        spacingSetting: formik.values.spacingSetting,
                        colorSetting: formik.values.colorSetting,

                        stylesheet: formik.values.stylesheet,
                        script: formik.values.script,
                        header: formik.values.header,
                        footer: formik.values.footer,
                      }}
                    />
                  </Affix>
                </Col>
              </Row>
            )}

            {current === 2 && (
              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
                  <H4>Review layout again before updating.</H4>

                  <ViewLessonLayoutTemplate
                    singleMode={formik.values.singleMode}
                    singleQuestionMode={formik.values.singleQuestionMode}
                    stepByStep={formik.values.stepByStep}
                    type={formik.values.layout}
                    setting={{
                      fontSizeSetting: formik.values.fontSizeSetting,
                      spacingSetting: formik.values.spacingSetting,
                      colorSetting: formik.values.colorSetting,

                      stylesheet: formik.values.stylesheet,
                      script: formik.values.script,
                      header: formik.values.header,
                      footer: formik.values.footer,
                    }}
                  />

                  <ButtonGroup type={'space-between'} className="mt-5">
                    <Button
                      type="default"
                      shape={'round'}
                      size={'large'}
                      onClick={() => setCurrent(0)}
                    >
                      Back to Configuration Step.
                    </Button>

                    <Button
                      type="primary"
                      size={'large'}
                      shape={'round'}
                      loading={isLoading}
                      onClick={handleSaveLessonTemplate}
                    >
                      Update template
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LessonTemplateComposePage;
