import { ResourceProps } from '@modules/product/components/resource/Resource';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  ResourceItem,
  useResourceContext,
} from '@cms/context/ResourceContextProvider';
import { ComponentScoreIcon } from '@cms/icons/ComponentScoreIcon';
import { ComposeResourceButton } from '../compose/actions/ComposeResourceButton';

import { useJumpToQuestion } from '@cms/lesson-template/hook/useJumpToQuestion';

export const ExerciseResource = (props: {
  item: ResourceProps;
  questionNumber: number | null;
}) => {
  const { scoring } = useResourceContext();
  const contentRef = useRef<HTMLDivElement>(null);

  useJumpToQuestion(props.item.resourceId, contentRef);

  const getClassName = () => {
    return `question q-${props.item.resourceId} ${props.item.className ?? ''}`;
  };

  return (
    <ExerciseResourceStyle ref={contentRef} className={getClassName()}>
      <ComposeResourceButton resourceId={props.item.resourceId} />

      {props.questionNumber != null && props.questionNumber > 0 ? (
        <>
          <div className={'question-number'}>
            {scoring != null && (
              <ComponentScoreIcon
                isSkip={scoring.skip != null && scoring.skip}
                score={scoring.score}
                maxScore={scoring.maxScore}
              />
            )}
            <span className={'question-number-display'}>
              {props.questionNumber}.&nbsp;
            </span>
          </div>

          <div className={'question-content'}>
            <ResourceItem />
          </div>
        </>
      ) : (
        <div className={'question-content'}>
          {scoring != null && (
            <ComponentScoreIcon
              isSkip={scoring.skip != null && scoring.skip}
              score={scoring.score}
              maxScore={scoring.maxScore}
            />
          )}

          <ResourceItem />
        </div>
      )}
    </ExerciseResourceStyle>
  );
};

export const EmptyResourceItem = () => {
  return <ExerciseResourceStyle className={'question empty-row'} />;
};

const ExerciseResourceStyle = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;

  &:not(:first-child) {
    margin-top: var(--cms-padding-question, 1.5em);
  }

  > div {
    vertical-align: baseline;
  }

  &.empty-row {
    min-height: 1.5em !important;
    height: 1.5em !important;
  }

  .question-number,
  .question-content {
    position: relative;
  }

  .question-number {
    .component-score-item {
      position: absolute;
      left: -0.5em;
      transform: translate(-100%, 0%);
    }

    .exercise-score {
      position: absolute;
      left: -0.5em;
      top: 50%;
      transform: translate(-100%, -50%);
    }
  }

  .question-content {
    width: 100%;

    .resource-comps {
      position: relative;

      .cms-component-full-size,
      .cms-component-block {
        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
