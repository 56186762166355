import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';

import { useTranslation } from 'react-i18next';
import { DoAssignmentPage } from '@cms/lesson-template/DoAssignmentPage';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const StartAssignment = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { assignmentInstanceId } = router.query as unknown as {
    assignmentInstanceId: string;
  };

  return (
    <EmptyLayout className={'start-assignment-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('do_assignment.title')}
        description={t('do_assignment.title')}
      />

      <DoAssignmentPage assignmentInstanceId={Number(assignmentInstanceId)} />
    </EmptyLayout>
  );
};

export default StartAssignment;
