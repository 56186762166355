import { SessionAPI } from '@services/private/SessionAPI';
import React, { useEffect } from 'react';
import CalendarEventItem from '@modules/calendar/components/calendar-event/CalendarEvent';
import Card, { CardClassEnum } from '@components/card';
import styled from 'styled-components';
import { H3 } from '@components/typography';
import { useTranslation } from 'react-i18next';

const InComingEvent = () => {
  const { t } = useTranslation();

  const [getIncomingEvent, { data }] =
    SessionAPI.endpoints.getIncomingEvent.useLazyQuery({});

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    getIncomingEvent({});
  };

  return (
    <>
      <InComingEventStyle className={'incoming-events'}>
        <H3>{t('dashboard.label.incoming_event')}</H3>

        {data?.content.map((event) => {
          return (
            <Card
              className={CardClassEnum.rectangle_box}
              hoverable
              key={event.sessionId}
            >
              <CalendarEventItem
                type={'edit'}
                session={event}
                onChange={refreshData}
              />
            </Card>
          );
        })}
      </InComingEventStyle>
    </>
  );
};

export default InComingEvent;

export const InComingEventStyle = styled.div`
  //.ant-card,
  //.ant-list-empty-text {
  //  width: 100%;
  //  border-radius: 12px;
  //  margin-bottom: 40px;
  //
  //  .screen-xs & {
  //    margin-bottom: 12px;
  //  }
  //
  //  .screen-sm & {
  //    margin-bottom: 16px;
  //  }
  //
  //  .screen-md & {
  //    margin-bottom: 24px;
  //  }
  //
  //  .screen-lg & {
  //    margin-bottom: 32px;
  //  }
  //}

  .ant-list-empty-text {
    background: #fff;
  }
`;
