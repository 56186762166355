import React from 'react';
import styled from 'styled-components';
import useUpdateExerciseStatus from '@cms/lesson-template/components/toolbar/handler/useUpdateExerciseStatus';
import { PresentationToolbar } from './PresentationToolbar';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import {
  LessonTemplateWrapper,
  useLessonTemplateContext,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import LessonSection from '@cms/section-bank/components/SectionItem';
import useHandleExerciseAction from '@cms/lesson-template/components/toolbar/handler/useHandleExerciseAction';

export default function PresentationTemplate() {
  const { section } = useLessonNavigationContext();
  const { type, sections } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  useUpdateExerciseStatus();
  useHandleExerciseAction();

  return (
    <PresentationTemplateStyle className={'presentation-template '}>
      <LessonTemplateWrapper>
        <div className={'lesson-section-group exercise-sections'}>
          {sections.map((item, index) => {
            const visible = Boolean(
              !config.singleMode || section.sectionId === item.lessonSectionId
            );

            return (
              <React.Fragment
                key={JSON.stringify(item) + '_' + index + '_' + visible}
              >
                <LessonSection visible={visible} item={item} mode={type} />
              </React.Fragment>
            );
          })}
        </div>

        <PresentationToolbar />
      </LessonTemplateWrapper>
    </PresentationTemplateStyle>
  );
}

const PresentationTemplateStyle = styled.div`
  width: 100%;
  position: relative;

  .lesson-layout {
    padding: 2em 5em;
  }

  .lesson-section-group {
    .lesson-section {
      margin-top: var(--cms-padding-section, 2em);
    }
  }

  .class-room-section-presentation {
    width: 100%;
  }

  .presentation-section-toolbar {
    display: none;
  }

  .lesson-section {
    margin: 2em 0 !important;
  }
`;
