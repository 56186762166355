import { UserRes } from '@modules/users/services/model';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { InputGroup } from '@components/input/InputGroup';
import { Button, Dropdown } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserInforMobileStyle } from './style';

export const SchoolAdminInfo = ({
  item,
  onEdit,
  onDelete,
}: {
  item: UserRes;
  onEdit: (userId: number) => void;
  onDelete: (userId: number) => void;
}) => {
  const { t } = useTranslation();
  return (
    <UserInforMobileStyle className={'parent-info'}>
      <div className={'user-info-thumbnails'}>
        <UserAvatar src={item.avatar} size={72} />

        <InputGroup label={t('label.username')}>{item.username}</InputGroup>
      </div>

      <div className={'user-info-info'}>
        <InputGroup
          label={
            <>
              {t('label.firstName')} {t('label.lastName')}
            </>
          }
        >
          {item.firstName} {item.lastName}
        </InputGroup>

        <InputGroup label={t('label.email')}>{item.email}</InputGroup>

        <InputGroup label={t('label.phone')}>{item.phone}</InputGroup>
      </div>

      <div className={'user-info-action'}>
        <Dropdown
          menu={{
            items: [
              {
                key: 'edit',
                icon: IconUtils.actions.edit,
                label: t('school_admin.actions.update'),
                onClick: () => onEdit(item.userId),
              },
              {
                key: 'remove',
                icon: IconUtils.actions.delete,
                label: t('school_admin.actions.remove'),
                onClick: () => onDelete(item.userId),
              },
            ],
          }}
          trigger={['click']}
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      </div>
    </UserInforMobileStyle>
  );
};
