import React from 'react';
import styled from 'styled-components';
import { GameUtils } from '@cms/comps/game/GameUtils';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { DragItem } from '@components/react-dnd-bt/DragItem';

const WordScrambleDragItems = (props: {
  randomAnswers: string[];
  disabledIds: number[];
}) => {
  return (
    <WordScrambleDragItemsStyle className={'word-scramble-drag-items'}>
      <DropItem
        acceptType={'word-scramble-char'}
        droppableId={''}
        className={'draggable-containers'}
      >
        {props.randomAnswers.map((char, index) => {
          if (char === GameUtils.splitCharacter) {
            return null;
          } else if (props.disabledIds.includes(index)) {
            return (
              <div
                key={'word-chard-disabled-item-' + index}
                className={'word-scramble-item disabled-item'}
              >
                {' '}
                <span>{char}</span>
              </div>
            );
          } else {
            return (
              <DragItem
                className={'word-scramble-item'}
                acceptType={'word-scramble-char'}
                draggableId={char + '|' + index}
                index={index}
                key={'word-chard-drag-item-' + index}
              >
                <span>{char}</span>
              </DragItem>
            );
          }
        })}
      </DropItem>
    </WordScrambleDragItemsStyle>
  );
};

export default WordScrambleDragItems;

const WordScrambleDragItemsStyle = styled.div`
  .draggable-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    max-width: 30em; // n =  alphabet / 2, max-with = 2n * .125 * n
    margin: 0 auto;
  }

  .word-scramble-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border: 1px solid #ccc;
    margin: 0.125em;
    font-weight: bold;

    cursor: pointer;

    &.disabled-item {
      background: #ccc;
      cursor: default;
    }
  }
`;
