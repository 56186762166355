import { useCallback, useEffect, useState } from 'react';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';

import sanitizeHtml from 'sanitize-html';
import ContentEditable from 'react-contenteditable';

export const ContentInlineEditable = (props: {
  value: string;
  onChange: (val: string) => void;
}) => {
  const [content, setContent] = useState(props.value);

  useEffect(() => {
    if (props.value !== content) {
      setContent(props.value);
    }
  }, [props.value]);

  const handleInsertInput = () => {
    insertHTML(' __________');
  };

  const handleOnSave = () => {
    props.onChange(content);
  };

  const handleOnCancel = () => {
    setContent(props.value);
  };

  const onContentChange = useCallback((innerHTML: string) => {
    const sanitizeConf = {
      allowedTags: ['b', 'i', 'strong'],
    };
    setContent(sanitizeHtml(innerHTML, sanitizeConf));
  }, []);

  return (
    <ContentInlineEditableStyle className={'content-inline-editor'}>
      <ContentEditable
        onChange={(evt) => onContentChange(evt.currentTarget.innerHTML)}
        onBlur={(evt) => onContentChange(evt.currentTarget.innerHTML)}
        html={content}
      />

      <div className={'button-group'}>
        <Button
          shape={'round'}
          onClick={handleInsertInput}
          type={'default'}
          size={'small'}
        >
          Add input
        </Button>

        <div className={'button-group-item'}>
          <Button
            shape={'circle'}
            type={'default'}
            onClick={handleOnCancel}
            size={'small'}
            icon={IconUtils.actions.revert}
          />

          <Button
            shape={'circle'}
            type={'primary'}
            onClick={handleOnSave}
            size={'small'}
            icon={IconUtils.actions.save}
          />
        </div>
      </div>
    </ContentInlineEditableStyle>
  );
};

const ContentInlineEditableStyle = styled.div`
  [contenteditable='true'] {
    padding: 0.25em;
    background: rgba(0, 0, 0, 0.03) !important;

    &:focus,
    &:hover {
      outline: none;
      background: rgba(0, 0, 0, 0.15) !important;
      color: ${(props) => props.theme.color.black};
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;

    .button-group-item {
      display: flex;
      justify-items: flex-end;

      .ant-btn {
        margin-left: 0.5em;
      }
    }
  }
`;

function insertHTML(html: string) {
  try {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const temp = document.createElement('div');
      const insertion = document.createDocumentFragment();

      temp.innerHTML = html;

      while (temp.firstChild) {
        insertion.appendChild(temp.firstChild);
      }

      range.deleteContents();
      range.insertNode(insertion);
    }
  } catch (z) {
    try {
      if (document.getSelection()) {
        // @ts-ignore
        document.getSelection().createRange().pasteHTML(html);
      }
    } catch (z) {}
  }
}
