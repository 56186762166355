import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';

import { FibNumberContextProvider } from './FibNumberContext';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import FibNumberCompEditable from '@cms/comps/math/fib/FibNumberEditableData';
import FibNumberGroupItems from './FibNumberGroupItem';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';

export interface FibNumberOptionProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface FibNumberSettingProps extends CompInteractSettingProps {
  digit: string;
  numberType: 'integer' | 'decimal';
}

export interface FibNumberProps extends CompProps {
  type: CompTypeEnum.FIB_NUMBER;
  configuration: {
    targetItems: FibNumberOptionProps[];
  };
  setting: FibNumberSettingProps;
}

export interface FibNumberAnsProps extends CompAnswerProps {
  answer: string[];
}

export const FibNumberComp = (props: {
  disabled: boolean;
  item: FibNumberProps;
  answer: FibNumberAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);

  return (
    <FibNumberContextProvider
      disabled={props.disabled}
      item={props.item}
      answer={props.answer}
      feedback={props.feedback}
      onChange={props.onChange}
    >
      <FibNumbStyle
        className={`comp comp-fib-word ${props.item.setting?.className}`}
      >
        {showComponent && (
          <>
            <FibNumberGroupItems />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </>
        )}

        <ComposeModeContent>
          <div className={'fib-inline-group'}>
            {!showComponent && (
              <FibNumberCompEditable
                item={props.item}
                onChange={props.onChange}
                onClose={() => setShowComponent(true)}
              />
            )}
          </div>
        </ComposeModeContent>

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </FibNumbStyle>
    </FibNumberContextProvider>
  );
};

const FibNumbStyle = styled.div`
  position: relative;

  .comps-fib-input-group {
    display: flex;
    align-items: baseline;
  }
`;
