import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useTheme } from '@app/styled/StyledProvider';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';

export const SubmitExerciseButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { dispatchExerciseAction } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  if (config.layout === LessonTemplateLayoutEnum.presentation) {
    return (
      <LessonButton
        className={'submit-exercise-btn'}
        type={'primary'}
        shape={'circle'}
        style={{ background: theme.exercise.submit }}
        icon={IconUtils.exercise.submit}
        onClick={() =>
          dispatchExerciseAction(ExerciseActionEnum.request_submit, '')
        }
      />
    );
  } else {
    return (
      <LessonButton
        className={'submit-exercise-btn'}
        type={'primary'}
        shape={'round'}
        style={{ background: theme.exercise.submit }}
        icon={IconUtils.exercise.submit}
        onClick={() =>
          dispatchExerciseAction(ExerciseActionEnum.request_submit, '')
        }
      >
        {t('exercise.button.submit')}
      </LessonButton>
    );
  }
};
