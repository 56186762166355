import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import PublicProfileTeacherPage from '@modules/setting/pages/PublicProfileTeacherPage';
import PublicProfileStudentPage from '@modules/setting/pages/PublicProfileStudentPage';
import { PageHeader } from '@app/header/PageHeader';

const AccountProfile = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'account-profile-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_ACCOUNT}
        subMenu={NavigationMenuEnum.MY_ACCOUNT_PUBLIC_PROFILE}
        title={t('profile.public_profile')}
        description={t('profile.public_profile')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        <PublicProfileTeacherPage />
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
        <PublicProfileStudentPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default AccountProfile;
