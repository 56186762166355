import {
  ResourceContextProvider,
  ResourceItem,
} from '@cms/context/ResourceContextProvider';
import React from 'react';
import { CompMode } from '@cms/ComponentInteface';
import MovingResourceItem from '@modules/product/components/resource/toolbar/MovingResourceItem';
import { ResourceProps } from '@modules/product/components/resource/Resource';

import { useMovingResourceContext } from '@modules/product/components/resource/toolbar/MovingResourceUtils';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { useSelector } from '@app/redux/hook';

export const ComposeResource = ({
  item,
  index,
}: {
  item: ResourceProps;
  index: number;
}) => {
  const { navigation, resources } = useSelector((state) => state.composeLesson);

  const { onListenActions, handleOnActionClick } = useMovingResourceContext(
    EditModeEnum.SINGLE_QUESTION
  );

  return (
    <MovingResourceItem
      selected={navigation != null && navigation.currentId === item.resourceId}
      questionNumber={index + 1}
      totalQuestion={resources.length}
      item={item}
      onListenActions={onListenActions}
      onActionClick={handleOnActionClick}
    >
      <ResourceContextProvider
        mode={CompMode.REVIEW}
        resourceId={item.resourceId}
        comps={item.components}
        answer={item.correctAnswer}
      >
        <ResourceItem />
      </ResourceContextProvider>
    </MovingResourceItem>
  );
};

export const ComposeResourceItem = ({
  item,
}: {
  item: ResourceProps;
  index: number;
}) => {
  return (
    <ResourceContextProvider
      mode={CompMode.REVIEW}
      resourceId={item.resourceId}
      comps={item.components}
      answer={item.correctAnswer}
    >
      <ResourceItem />
    </ResourceContextProvider>
  );
};
