import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Avatar, Button, Card } from 'antd';
import IconImage from '@components/images/IconImage';
import ColorCircle, { SmallColorCircle } from '@components/input/ColorCircle';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { UserInfoRes, UserRes } from '@modules/users/services/model';
import { AvatarSize } from 'antd/es/avatar/AvatarContext';
const { Meta } = Card;

const StudentStyle = styled.div`
  label {
    font-size: 12px;
  }

  .student-content {
    display: flex;

    .student-avatar {
      position: relative;
      width: 72px;
      height: 72px;
      margin-right: 10px;

      .ant-image {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
    }

    .student-detail {
      flex-grow: 1;
      margin-left: 10px;

      h1 {
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        color: #ccc;
        border: 2px dashed;

        max-width: 70%;
        height: 100px;
        margin: 0 auto;

        &:hover {
          color: #141414;
        }
      }
    }
  }
`;

const StudentSimpleStyle = styled.div`
  .display-name {
    word-break: break-word;
  }
`;

export const StudentNameDisplay = (props: { item: UserRes }) => {
  return <span>{getDisplayName(props.item)}</span>;
};

function getDisplayName(item: UserRes) {
  if (item.firstName && item.lastName) {
    return `${item.firstName} ${item.lastName}`;
  } else if (item.email) {
    return item.email + '';
  } else {
    return item.username + '';
  }
}

function getShortName(item: UserRes) {
  if (item) {
    return getDisplayName(item).substring(0, 2);
  } else {
    return '';
  }
}

function getShortNameByData(
  firstName: string,
  lastName: string,
  email: string
) {
  const shortName =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : email.substring(0, 2);

  return shortName;
}

export const PublicStudentCircleInfo = (props: { item: UserRes }) => {
  if (props.item.avatar) {
    return <UserAvatar src={props.item.avatar} size={32} />;
  } else {
    const shortName = getShortName(props.item);
    return <SmallColorCircle color={'#6366F1'}>{shortName}</SmallColorCircle>;
  }
};

export const StudentAvatarInfo = (props: {
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
}) => {
  if (props.avatar) {
    return <IconImage src={props.avatar} width={'100%'} height={'100%'} />;
  } else {
    const shortName = getShortNameByData(
      props.firstName,
      props.lastName,
      props.email
    );
    return <ColorCircle color={'#6366F1'}>{shortName}</ColorCircle>;
  }
};

export const StudentInfo = (props: { item: UserRes }) => {
  const { t } = useTranslation();

  const displayName = getDisplayName(props.item);

  return (
    <StudentStyle className={'student-info'}>
      <Card hoverable>
        <div className={'student-content'}>
          <span className={'student-avatar'}>
            <StudentAvatarInfo
              avatar={props.item.avatar}
              firstName={props.item.firstName}
              lastName={props.item.lastName}
              email={props.item.email}
            />
          </span>

          <div className={'student-detail'}>
            <Meta title={<h3>{displayName}</h3>} />

            {props.item.phone && (
              <>
                <div className={'created-date'}>
                  <label>{t('label.phone')}</label>
                  <h4>{props.item.phone}</h4>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </StudentStyle>
  );
};

export const StudentIconDisplay = (props: {
  item: UserRes;
  size: AvatarSize;
}) => {
  const avatarIcon = useMemo(() => {
    return props.item.avatar ? (
      <IconImage src={props.item.avatar} width={'100%'} height={'100%'} />
    ) : (
      <>{getShortName(props.item)}</>
    );
  }, [props.item]);

  return (
    <Avatar size={props.size ? props.size : 'default'} icon={avatarIcon} />
  );
};

export const StudentInfoSimple = (props: {
  className?: 'no-padding' | 'small-padding';
  item: UserRes | UserInfoRes;
}) => {
  const avatarIcon = props.item.avatar ? (
    <IconImage src={props.item.avatar} width={'100%'} height={'100%'} />
  ) : (
    <>{getShortName(props.item)}</>
  );
  const titleDisplay = (
    <>
      <Avatar size={'default'} icon={avatarIcon} />
      <span className={'display-name'} style={{ marginLeft: '1em' }}>
        {getDisplayName(props.item)}
      </span>
    </>
  );

  return (
    <StudentSimpleStyle
      className={'student-info ' + (props.className ? props.className : '')}
    >
      {titleDisplay}
    </StudentSimpleStyle>
  );
};

const StudentInfoStaticStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .student-title {
    flex-grow: 1;
  }
`;

export const StudentInfoStatic = (props: {
  selected: boolean;
  item: UserRes;
  onSelect?: (data: UserRes) => void;
  onRemove?: (userId: number) => void;
}) => {
  const { t } = useTranslation();

  const handleOnRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.item.userId);
    }
  };

  return (
    <>
      <StudentInfoStaticStyle
        className={'student-info-static' + (props.selected ? ' selected' : '')}
      >
        <div className={'student-title'}>
          <StudentInfoSimple item={props.item} />
        </div>

        <div className={'student-action'}>
          {props.selected && props.onRemove != null && (
            <Button
              size={'small'}
              type={'primary'}
              shape="round"
              danger
              onClick={handleOnRemove}
              title={t('confirm.remove_student_from_list')}
            >
              {t('button.remove')}
            </Button>
          )}

          {!props.selected && props.onSelect && (
            <Button
              size={'small'}
              type={'primary'}
              shape="round"
              onClick={() => props.onSelect!(props.item)}
              title={t('confirm.remove_student_from_list')}
            >
              {t('label.add')}
            </Button>
          )}
        </div>
      </StudentInfoStaticStyle>
    </>
  );
};
