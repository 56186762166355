import { PaginationReq } from '@services/model/PaginationRes';

export enum AssetsTypeEnum {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  DOC = 'DOC',
  PDF = 'PDF',
  EPUB = 'EPUB',
}

export interface AssetsRes {
  key?: number;
  assetsId?: number;
  id: string;
  data: string;
  type: AssetsTypeEnum;

  name?: string;
  path?: string;

  keyword?: string;
  description?: string;

  free?: boolean;

  editType?: string;

  // for image
  align?: 'left' | 'right' | 'center';
  width?: number;
  height?: number;

  createdDate?: string;
  modifiedDate?: string;
}

export interface FilterAssetsReq extends PaginationReq {
  type: AssetsTypeEnum;
  keyword: string;
}

export interface UpdateAssetMetadataReq {
  assetsId: number;
  id: string;
  keyword: string;
  description: string;
  free: boolean;
}

export interface FileResponse {
  fileUrl: string;
  type: string;
}
