import { ReactNode } from 'react';

export interface SwitchConditionProps {
  condition?: boolean;
  children: ReactNode;
}

const SwitchCondition = (props: { conditions: SwitchConditionProps[] }) => {
  const index = props.conditions.findIndex((rs) => {
    return rs.condition || rs.condition === null;
  });

  if (index > -1) {
    return <>{props.conditions[index].children}</>;
  } else {
    return null;
  }
};

export default SwitchCondition;
