import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { WordPuzzleCompProps } from './WordPuzzleComp';

export const WordPuzzleSampleData: WordPuzzleCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.WORD_PUZZLE,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        offset: { row: 2, column: 4 },
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        offset: { row: 3, column: 0 },
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana Thumbnail',
        },
      },
    ],
    targetItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        offset: { row: 0, column: 4 },
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        offset: { row: 0, column: 0 },
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Bluberry Thumbnails',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: '',
    maxRows: 8,
    maxColumns: 9,
  },
};
