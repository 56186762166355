import { LessonAPI } from '@modules/product/services/LessonAPI';
import { useTranslation } from 'react-i18next';
import useConfirmModal from '@components/modal/ConfirmModal';
import React, { useState } from 'react';
import { LessonActionEnum } from '@modules/product/container/actions';
import { Button, Dropdown, Menu, notification } from 'antd';
import { LessonActionHandleTeacher } from '@components/common/lesson/actions/LessonActionHandleTeacher';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonActionAdmin = ({
  type,
  item,
  onChange,
}: {
  type: 'table' | 'box';
  item: LessonRes;
  onChange: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const router = useRouter();
  const proUser = useProUser();

  const [deleteLesson] = LessonAPI.endpoints.deleteLesson.useMutation();
  const [validateLesson] = LessonAPI.endpoints.validateLesson.useMutation();

  const [action, setAction] = useState<{
    action: LessonActionEnum;
    params: number;
  }>({ action: LessonActionEnum.DEFAULT, params: -1 });

  const handleOnCancel = () => {
    setAction({
      action: LessonActionEnum.DEFAULT,
      params: -1,
    });
    onChange();
  };

  const removeLesson = (lessonId: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('lesson.warning.confirm_remove'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          deleteLesson(lessonId)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('lesson.warning.success'),
                description: t('lesson.warning.remove_success'),
                placement: 'bottomRight',
              });
              onChange();
            })
            .catch(() => {
              notification.error({
                message: t('lesson.warning.error'),
                description: t('lesson.warning.remove_error'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const handleValidateLesson = (lessonId: number) => {
    validateLesson(lessonId)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('lesson.warning.success'),
          description: t('lesson.warning.update_success'),
          placement: 'bottomRight',
        });
        onChange();
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.error'),
          description: t('lesson.warning.update_error'),
          placement: 'bottomRight',
        });
      });
  };

  const onEdit = (lessonId: number) => {
    router.push(SiteMap.content.lesson.compose_gen(lessonId, proUser));
  };

  const editMetadata = (lessonId: number) => {
    setAction({
      action: LessonActionEnum.EDIT_METADATA,
      params: lessonId,
    });
  };

  const reviewLesson = (lessonId: number) => {
    router.push(SiteMap.content.lesson.review_gen(lessonId));
  };

  const onLinkToExample = (lessonId: number) => {
    setAction({
      action: LessonActionEnum.LINK_TO_EXAMPLE,
      params: lessonId,
    });
  };

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: 'edit',
              icon: IconUtils.lesson.edit,
              label: t('lesson.actions.compose'),
              onClick: () => onEdit(item.lessonId),
            },
            {
              key: 'review-lesson',
              icon: IconUtils.lesson.review,
              label: t('lesson.actions.review'),
              onClick: () => reviewLesson(item.lessonId),
            },
            {
              key: 'divider-1',
              label: <Menu.Divider />,
            },
            {
              key: 'edit-metadata',
              icon: IconUtils.lesson.edit_metadata,
              label: t('lesson.actions.update_metadata'),
              onClick: () => editMetadata(item.lessonId),
            },

            {
              key: 'validate-metadata',
              icon: IconUtils.lesson.validate,
              label: t('lesson.actions.validate'),
              onClick: () => handleValidateLesson(item.lessonId),
            },

            {
              key: 'remove',
              icon: IconUtils.lesson.remove,
              label: t('lesson.actions.remove_lesson'),
              onClick: () => removeLesson(item.lessonId),
            },
            {
              key: 'divider-2',
              type: 'divider',
            },

            {
              key: 'link_to_example',
              icon: IconUtils.lesson.link_to_example,
              label: t('lesson.actions.link_to_example'),
              onClick: () => onLinkToExample(item.lessonId),
            },
          ],
        }}
        trigger={['click']}
        placement={type === 'table' ? 'bottom' : 'bottomRight'}
      >
        <Button
          size={type === 'table' ? 'middle' : 'small'}
          type="default"
          shape="circle"
          icon={IconUtils.more_icon}
        />
      </Dropdown>

      <LessonActionHandleTeacher
        action={action}
        handleOnCancel={handleOnCancel}
      />
    </>
  );
};
