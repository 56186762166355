import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { CourseRes } from '@services/private/CourseAPI';
import { SchoolRes } from '@services/private/SchoolAPI';
import env from '@utils/config';
import { ProductRes } from '@modules/product/services/product_model';

export interface FilterProductRes {
  product: ProductRes;
  school: SchoolRes;
}

export interface FilterCourseRes {
  course: CourseRes;
  school: SchoolRes;
}

export interface FilterProductAndCourseReq {
  keyword: string;
  grades: number[];
  subjects: number[];
  categories: number[];
}

export interface FilterProductAndCourseRes {
  products: PaginationRes<FilterProductRes>;
  courses: PaginationRes<FilterCourseRes>;
}

export const FilterProductAndCourseAPI = createApi({
  reducerPath: 'FilterProductAndCourseAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // filterProductAndCourse: builder.query<FilterProductAndCourseRes, FilterProductAndCourseReq>({
    //     query: (params) => ({
    //         url: `/public{/{CLIENT_ID}/filter/product-or-course`,
    //         params
    //     }),
    // }),

    filterProducts: builder.query<
      PaginationRes<ProductRes>,
      FilterProductAndCourseReq
    >({
      query: (params) => ({
        url: `/public/school/${env.CLIENT_ID}/products`,
        params,
      }),
    }),

    filterCourses: builder.query<
      PaginationRes<CourseRes>,
      FilterProductAndCourseReq
    >({
      query: (params) => ({
        url: `/public/school/${env.CLIENT_ID}/courses`,
        params,
      }),
    }),
  }),
});
