import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, notification, Row } from 'antd';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { SurveyAPI } from '@modules/assignments/service/SurveyAPI';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import {
  LessonDisplay,
  LessonDisplayComp,
} from '@components/product/LessonDisplay';
import { Default_Gutter } from '@components/grid';
import {
  CreateSurveyReq,
  SurveyRes,
  UpdateSurveyReq,
} from '@modules/assignments/service/survey_model';

export const CreateSurveyForm = (props: {
  lessonId: number;
  surveyId?: number;
  onSuccess: (req: SurveyRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isFetching }] =
    SurveyAPI.endpoints.findById.useLazyQuery();

  const [createSurvey, { isLoading: isCreateLoading }] =
    SurveyAPI.endpoints.createSurvey.useMutation({});

  const [updateSurvey, { isLoading: isUpdateLoading }] =
    SurveyAPI.endpoints.updateSurvey.useMutation({});

  useEffect(() => {
    if (props.surveyId) {
      findById(props.surveyId);
    }
  }, [props.surveyId]);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setValues({
        name: data.name,
        direction: data.direction,
        lessonId: data.lesson.lessonId,
      });
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      direction: '',
      lessonId: props.lessonId,
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      direction: yup.string(),
    }),

    onSubmit: (values) => {
      if (props.surveyId) {
        const request: UpdateSurveyReq = {
          name: values.name,
          direction: values.direction,
          lessonId: values.lessonId,
          surveyId: props.surveyId,
        };
        onUpdateSurvey(request);
      } else {
        const request: CreateSurveyReq = {
          name: values.name,
          direction: values.direction,
          lessonId: values.lessonId,
        };

        onCreateSurvey(request);
      }
    },
  });

  const onCreateSurvey = (request: CreateSurveyReq) => {
    createSurvey(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('assignment.warning.create_success'),
          description: t('assignment.warning.create_success_msg'),
          placement: 'bottomRight',
        });
        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('assignment.warning.create_error'),
          description: t('assignment.warning.create_error_msg'),
          placement: 'bottomRight',
        });
      });
  };

  const onUpdateSurvey = (request: UpdateSurveyReq) => {
    updateSurvey(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('assignment.warning.create_success'),
          description: t('assignment.warning.create_success_msg'),
          placement: 'bottomRight',
        });
        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('assignment.warning.create_error'),
          description: t('assignment.warning.create_error_msg'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <div className={'create-survey-form'}>
      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Input
              required={true}
              name="name"
              type="text"
              label={t('survey.label.name')}
              placeholder={t('survey.label.name')}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {props.lessonId && props.lessonId > 0 && (
              <InputGroup label={t('survey.label.lesson')}>
                <LessonDisplayComp lessonId={props.lessonId} />
              </InputGroup>
            )}

            {data && data.lesson && (
              <InputGroup label={t('survey.label.lesson')}>
                <LessonDisplay item={data.lesson} />
              </InputGroup>
            )}
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <InputGroup label={t('survey.label.direction')}>
              <Input
                type={'textarea'}
                name="direction"
                onChange={formik.handleChange}
                value={formik.values.direction}
                maxLength={512}
                style={{ minHeight: 120 }}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <div className={'submit-container'}>
              <ButtonGroup type={'right'}>
                <Button
                  type="default"
                  size="large"
                  onClick={props.onCancel}
                  shape={'round'}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  shape={'round'}
                  size="large"
                  icon={IconUtils.actions.save}
                  onClick={formik.submitForm}
                  loading={isFetching || isUpdateLoading || isCreateLoading}
                >
                  {props.surveyId && props.surveyId > 0
                    ? t('survey.actions.update')
                    : t('survey.actions.create')}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </FormikProvider>
    </div>
  );
};
