import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateSectionReq,
  DeleteSectionReq,
  LessonSectionRes,
  RemoveResourceFromSectionReq,
  ReorderSectionReq,
  UpdateSectionReq,
  UpdateSectionResourceReq,
} from '@modules/product/services/lesson_model';
import { ResourceRes } from '@modules/product/services/resource_model';

export const LessonSectionAPI = createApi({
  reducerPath: 'LessonSectionAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findByLesson: builder.query<LessonSectionRes[], number>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/sections`,
      }),
    }),

    findEmptySection: builder.query<ResourceRes[], number>({
      query: (lessonId) => ({
        url: `/lessons/${lessonId}/sections/resources/empty`,
      }),
    }),

    createSection: builder.mutation<LessonSectionRes, CreateSectionReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections`,
        method: 'POST',
        body,
      }),
    }),

    updateSection: builder.mutation<LessonSectionRes, UpdateSectionReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections`,
        method: 'PUT',
        body,
      }),
    }),

    deleteSection: builder.mutation<BasicMessageRes, DeleteSectionReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections/${body.lessonSectionId}`,
        method: 'DELETE',
        body,
      }),
    }),

    reorderSections: builder.mutation<BasicMessageRes, ReorderSectionReq>({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections/reorder`,
        method: 'PUT',
        body,
      }),
    }),

    addResourceToSection: builder.mutation<
      LessonSectionRes,
      UpdateSectionResourceReq
    >({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections/resources`,
        body,
        method: 'PUT',
      }),
    }),

    removeResourceFromSection: builder.mutation<
      LessonSectionRes,
      RemoveResourceFromSectionReq
    >({
      query: (body) => ({
        url: `/lessons/${body.lessonId}/sections/resources`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});
