import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { RegisterAPI } from '@modules/authorization/services/RegisterAPI';
import { useTranslation } from 'react-i18next';
import { LoginOutlined } from '@ant-design/icons';
import env from '@utils/config';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';
import { ActiveUserReq } from '@modules/authorization/services/model';

const ActiveUserPage = (props: { code: string; token: string }) => {
  const { t } = useTranslation();

  const [activeUser, { error, isError, isSuccess }] =
    RegisterAPI.endpoints.activeUser.useMutation({});

  useEffect(() => {
    if (props.code && props.token) {
      handleActiveUser(props.code, props.token);
    }
  }, [props.code, props.token]);

  const handleActiveUser = (code: string, token: string) => {
    const request: ActiveUserReq = {
      code: code,
      token: token,
      clientId: env.CLIENT_ID,
    };
    activeUser(request)
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  if (isSuccess) {
    return (
      <Result
        status="success"
        title={t('registration.warning.active_success')}
        subTitle={t('registration.warning.active_success_message')}
        extra={[
          <NavLink href={SiteMap.auth.login} key={'login'}>
            <Button type="primary" size={'large'} shape={'round'}>
              {' '}
              <LoginOutlined /> {t('menu.login')}
            </Button>
            ,
          </NavLink>,
        ]}
      />
    );
  } else if (isError && error) {
    return (
      <Result
        status="error"
        title={t('registration.warning.active_error')}
        subTitle={t('registration.warning.active_error_message')}
      />
    );
  } else {
    return <>{t('label.loading')}</>;
  }
};

export default ActiveUserPage;
