import { H2 } from '@components/typography';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import React, { useEffect } from 'react';
import { LessonRes } from '@modules/product/services/lesson_model';
import { PublishLesson } from '@modules/public/components/lessons/PublishLessonInfo';
import styled from 'styled-components';

export const TeacherPublicLesson = (props: { username: string }) => {
  const [viewTeacherLessons, { data }] =
    PublicPageAPI.endpoints.viewTeacherLessons.useLazyQuery();

  useEffect(() => {
    viewTeacherLessons(props.username);
  }, [props.username]);

  return (
    <div className={'teacher-public-lesson'}>
      <H2>Các bài học đã chia sẻ:</H2>

      {data && <TeacherPublicLessonTable items={data} />}
    </div>
  );
};

const TeacherPublicLessonTable = (props: { items: LessonRes[] }) => {
  return (
    <CollectionTableStyle className={'teacher-public-lesson'}>
      {props.items.map((ls) => {
        return <PublishLesson key={ls.lessonId} item={ls} />;
      })}
    </CollectionTableStyle>
  );
};

const CollectionTableStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
`;
