import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';

const GradingExerciseContext = createContext({
  unscoredQuestion: null as number | null,
  totalManualScore: -1 as number,
});

export const GradingExerciseContextProvider = (props: {
  children: ReactNode;
}) => {
  const { gradings } = useViewLessonContext();

  const [unscoredQuestion, setUnscoredQuestion] = useState<number | null>(null);
  const [totalManualQuestion, setTotalManualQuestion] = useState<number>(
    Object.keys(gradings).length
  );

  useEffect(() => {
    const resourceIds = Object.keys(gradings);
    let _unscoredQuestion = 0;

    resourceIds.forEach((rsId) => {
      const questionGrading = gradings[+rsId];
      const unscored = questionGrading.some((scoring) => {
        return scoring.score == null || scoring.score < 0;
      });

      if (unscored) {
        _unscoredQuestion += 1;
      }
    });

    setTotalManualQuestion(resourceIds.length);
    setUnscoredQuestion(_unscoredQuestion);
  }, [gradings]);

  return (
    <GradingExerciseContext.Provider
      value={{
        unscoredQuestion: unscoredQuestion,
        totalManualScore: totalManualQuestion,
      }}
    >
      {props.children}
    </GradingExerciseContext.Provider>
  );
};

export const useGradingExerciseContext = () => {
  const context = useContext(GradingExerciseContext);
  if (!context) {
    throw new Error(
      'You must wrap container by GradingExerciseContextProvider'
    );
  }
  return context;
};
