import { FilterUserReq } from '@modules/users/services/StudentAPI';
import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE } from '@services/model/PaginationRes';
import { UserRes } from '@modules/users/services/model';
import TablePagination from '@components/table/TablePagination';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { ManageUserActionEnum } from '@modules/users/pages/ManageTeacherPage';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import useConfirmModal from '@components/modal/ConfirmModal';
import { UserParentAPI } from '@modules/users/services/UserParentAPI';
import { CreateParentUser } from '@modules/users/component/users/CreateParentUserForm';
import LinkToStudentParentForm from '@modules/users/component/link-to-student/LinkToStudentParentForm';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { ManageParentDropDown, ParentInforMobile } from './ParentInfor';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import { InputGroup } from '@components/input/InputGroup';
import { ResetUserPasswordForm } from '@modules/users/container/users/ResetUserPasswordForm';
import { ViewParentInfo } from '@modules/users/component/users/ViewParentInfo';
import { EntityWithStatus } from '@components/status/EntityStatus';

const ParentUserTable = (props: {
  schoolId?: number;
  reload: { reload: boolean; timestamp: number };
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [params, setParams] = useState<FilterUserReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'user.username',
    direction: 'ascend',
    keyword: '',
    schoolId:
      props.schoolId != null && props.schoolId > 0 ? props.schoolId : -1,
  });

  const [action, setAction] = useState({
    action: ManageUserActionEnum.default,
    params: -1,
  });

  const [findAll, { data, isFetching }] =
    UserParentAPI.endpoints.findAll.useLazyQuery();

  const [disableUser] = UserParentAPI.endpoints.disableUser.useMutation({});

  useEffect(() => {
    reloadData();
  }, [params, props.reload]);

  const reloadData = () => {
    findAll(params);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'avatar',
      key: 'avatar',
      align: 'center',
      width: 90,
    },
    {
      title: t('label.username'),
      dataIndex: 'user.username',
      key: 'user.username',
      width: 200,
      sorter: true,
    },
    {
      title: t('label.firstName'),
      dataIndex: 'user.firstName',
      key: 'user.firstName',
      sorter: true,
    },
    {
      title: t('label.lastName'),
      dataIndex: 'user.lastName',
      key: 'user.lastName',
      sorter: true,
    },
    {
      title: t('label.email'),
      dataIndex: 'user.email',
      key: 'user.email',
      width: 220,
      sorter: true,
    },
    {
      title: t('label.phone'),
      dataIndex: 'user.phone',
      key: 'user.phone',
      width: 180,
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      align: 'center',
    },
  ];

  const renderMobile = (record: UserRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <ParentInforMobile
          item={record}
          onView={onView}
          onEdit={onEdit}
          onLinkToStudents={onLinkToStudents}
          onResetPassword={onResetPassword}
          onActive={onActivated}
        />
      </Card>
    );
  };

  const processDataRow = (record: UserRes) => {
    return {
      key: record.userId,
      avatar: <UserAvatar src={record.avatar} size={64} />,
      'user.username': (
        <>
          <EntityWithStatus status={record.status}>
            {record.username}
          </EntityWithStatus>

          {record.lastLogin && (
            <InputGroup
              addedClass={'small-group'}
              label={t('label.last_login')}
            >
              <DateAndTimeLabel type="inline" label={record.lastLogin} />
            </InputGroup>
          )}
        </>
      ),
      'user.firstName': record.firstName,
      'user.lastName': record.lastName,
      'user.email': record.email,
      'user.phone': record.phone,
      actions: (
        <ManageParentDropDown
          item={record}
          onView={onView}
          onEdit={onEdit}
          onLinkToStudents={onLinkToStudents}
          onResetPassword={onResetPassword}
          onActive={onActivated}
        />
      ),
    };
  };

  const onView = (parentId: number) => {
    setAction({ action: ManageUserActionEnum.view_info, params: parentId });
  };

  const onEdit = (parentId: number) => {
    setAction({ action: ManageUserActionEnum.update, params: parentId });
  };

  const onLinkToStudents = (parentId: number) => {
    setAction({
      action: ManageUserActionEnum.link_to_student,
      params: parentId,
    });
  };

  const onResetPassword = (teacherId: number) => {
    setAction({
      action: ManageUserActionEnum.reset_password,
      params: teacherId,
    });
  };

  const onActivated = (userId: number, activated: boolean) => {
    confirm(
      activated ? 'warning' : 'danger',
      t('header.confirm'),
      activated ? t('parent.warning.activated') : t('parent.warning.disabled'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          const request = {
            userId: userId,
            disabled: activated,
          };

          disableUser(request)
            .unwrap()
            .then(() => {
              notification.success({
                message: t('parent.warning.remove_success'),
                description: t('parent.warning.remove_success_message'),
                placement: 'bottomRight',
              });
              reloadData();
            })
            .catch(() => {
              notification.error({
                message: t('parent.warning.remove_error'),
                description: t('parent.warning.remove_error_message'),
                placement: 'bottomRight',
              });
            });
        }
      }
    );
  };

  const refreshData = (params: any) => {
    setParams({ ...params });
  };

  const onEditSuccess = () => {
    reloadData();
    onCancel();
  };

  const onCancel = () => {
    setAction({ action: ManageUserActionEnum.default, params: -1 });
  };

  const addStudentToParent = () => {
    reloadData();
    onCancel();
  };

  return (
    <>
      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={refreshData}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />

      {action.action === ManageUserActionEnum.view_info && (
        <CustomModal
          header={t('parent.actions.view_info')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ViewParentInfo parentId={action.params} onCancel={onCancel} />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ManageUserActionEnum.update && (
        <CustomModal
          header={t('parent.actions.update')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CreateParentUser
              schoolId={props.schoolId}
              userId={action.params}
              onSuccess={onEditSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ManageUserActionEnum.link_to_student && (
        <CustomModal
          header={t('parent.actions.link_to_student')}
          className={CustomModalClassEnum.large_modal}
          content={
            <LinkToStudentParentForm
              parentId={action.params}
              onClose={addStudentToParent}
            />
          }
          onCloseFunc={onCancel}
        />
      )}

      {action.action === ManageUserActionEnum.reset_password && (
        <CustomModal
          header={t('users.actions.reset_password')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <ResetUserPasswordForm
              schoolId={props.schoolId}
              userId={action.params}
              onSuccess={onEditSuccess}
              onCancel={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </>
  );
};

export default ParentUserTable;
