import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import {
  MathOperationEnum,
  OperationExpProps,
} from '@cms/comps/math/operation/OperationUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import React, { useEffect, useState } from 'react';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import styled from 'styled-components';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { AreaDivisionEditable } from './AreaDivisionEditable';
import { AreaOperationTable } from '../table/AreaOperationTable';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';

export type AreaDivisionSettingProps = CompInteractSettingProps;

export interface AreaDivisionCompProps extends CompProps {
  type: CompTypeEnum.AREA_DIVISION;
  configuration: {
    dividend: string;
    divisor: string;
    expression: OperationExpProps[];
  };
  setting: AreaDivisionSettingProps;
}

export interface AreaDivisionAnsProps extends CompAnswerProps {
  answer: string[];
}

export const AreaDivisionComp = (props: {
  disabled: boolean;
  item: AreaDivisionCompProps;
  answer: AreaDivisionAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();
  const [showComponent, setShowComponent] = useState(true);

  const [answer, setAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (
      props.answer &&
      props.answer.answer &&
      JSON.stringify(props.answer.answer) !== JSON.stringify(answer.value)
    ) {
      setAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleOnAnswerChange = (answerExpression: OperationExpProps[]) => {
    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('|');
    });

    setAnswer({
      value: answer,
      triggerChange: true,
    });
  };

  const handleOnEditableChange = (
    dividend: string,
    divisor: string,
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => {
    const newCompData = produce(props.item, (draft) => {
      draft.configuration.dividend = dividend;
      draft.configuration.divisor = divisor;
      draft.configuration.expression = expression;
    });

    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('|');
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newCompData,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );

    setAnswer({
      value: answer,
      triggerChange: false,
    });
    setShowComponent(true);
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      {showComponent && (
        <>
          <AreaDivisionCompStyle
            className={`comp comp-area-division ${props.item.setting?.className}`}
          >
            <AreaOperationTable
              operation={MathOperationEnum.area_division}
              disabled={props.disabled || mode === CompMode.COMPOSE}
              expression={props.item.configuration.expression}
              answer={answer.value}
              onChange={handleOnAnswerChange}
            />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </AreaDivisionCompStyle>
        </>
      )}

      {!showComponent && (
        <ComposeModeContent>
          <AreaDivisionEditable
            dividend={props.item.configuration.dividend}
            divisor={props.item.configuration.divisor}
            expression={props.item.configuration.expression}
            onChange={handleOnEditableChange}
          />
        </ComposeModeContent>
      )}
    </CompFeedbackContextProvider>
  );
};

const AreaDivisionCompStyle = styled.div`
  position: relative;
`;
