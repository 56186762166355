import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import {  PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  AssignLicenseForUserReq,
  CreateProductLicenseReq,
  ProductLicenseRes, RemoveProductLicenseReq, UpdateProductLicenseReq,
  ViewUserProductLicense
} from "@modules/license/services/model";


export const UserProductLicenseAPI = createApi({
  reducerPath: 'UserProductLicenseAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserProductLicense: builder.query<
      PaginationRes<ProductLicenseRes>,
      ViewUserProductLicense
    >({
      query: (params: ViewUserProductLicense) => ({
        url: `/licenses/users/${params.userId}/products`,
        method: 'GET',
        params,
      }),
    }),

    assignLicense: builder.mutation<ProductLicenseRes, CreateProductLicenseReq>(
      {
        query: (body) => ({
          url: `/licenses/users/${body.userId}/products`,
          method: 'POST',
          body,
        }),
      }
    ),

    assignLicenseForUsers: builder.mutation<
      ProductLicenseRes,
      AssignLicenseForUserReq
    >({
      query: (body) => ({
        url: `/licenses/products/${body.productId}/users`,
        method: 'POST',
        body,
      }),
    }),

    updateLicense: builder.mutation<ProductLicenseRes, UpdateProductLicenseReq>(
      {
        query: (body) => ({
          url: `/licenses/users/${body.userId}/products/${body.productLicenseId}`,
          method: 'PUT',
          body,
        }),
      }
    ),

    removeLicense: builder.mutation<BasicMessageRes, RemoveProductLicenseReq>({
      query: (body) => ({
        url: `/licenses/users/${body.userId}/products/${body.productLicenseId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
