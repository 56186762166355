import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import React from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import { PageRouterProps } from '@router/modules/props';

import ManageLessonType from '@router/pages/content/metadata/lesson_type';
import ManageLessonTemplate from '@router/pages/content/metadata/lesson-template';
import LessonTemplateCompose from '@router/pages/content/metadata/lesson-template-compose';
import ManageLessonExample from '@router/pages/content/example/example_list';
import CollectionExample from '@router/pages/content/example/collection_example';
import PageExample from '@router/pages/content/example/page_example';
import LessonExample from '@router/pages/content/example/lesson_example';

export const ContentMetadataRouter: PageRouterProps[] = [
  {
    path: SiteMap.content.metadata.lesson_type,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<ManageLessonType />} />
    ),
  },

  {
    path: SiteMap.content.metadata.lesson_template,
    element: <PrivateRouter element={<ManageLessonTemplate />} />,
  },

  {
    path: SiteMap.content.metadata.lesson_template_compose,
    element: (
      <PrivateRouter
        roles={SiteMap.content.metadata.lesson_template_roles}
        element={<LessonTemplateCompose />}
      />
    ),
  },

  // example
  {
    path: SiteMap.content.example.list,
    element: (
      <PrivateRouter
        roles={[RoleEnum.ADMIN]}
        element={<ManageLessonExample />}
      />
    ),
  },
  {
    path: SiteMap.content.example.collection,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<CollectionExample />} />
    ),
  },
  {
    path: SiteMap.content.example.lesson,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<LessonExample />} />
    ),
  },
  {
    path: SiteMap.content.example.page,
    element: (
      <PrivateRouter roles={[RoleEnum.ADMIN]} element={<PageExample />} />
    ),
  },
];
