import styled from 'styled-components';
import { Link } from '@components/link/Link';

const StyledLink = styled(Link)`
  color: inherit;
`;

export interface NavLinkProps {
  href: string;
  className?: string;
  props?: any;
  children?: any;
}

const NavLink = ({ className, href, children, ...props }: NavLinkProps) => {
  return (
    <StyledLink
      className={`gstudy-nav-link ${className ?? ''}`}
      passHref
      href={href}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export const NavLinkNewTab = ({
  href,
  className,
  children,
  props,
}: NavLinkProps) => {
  return (
    <StyledLink
      href={href}
      passHref
      className={`gstudy-nav-link ${className ?? ''}`}
      replace
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default NavLink;
