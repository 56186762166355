import { Navigate } from 'react-router-dom';
import React, { ReactNode, useMemo } from 'react';
import { useSelector } from '@app/redux/hook';
import { useSecurity } from '@utils/authorization';
import useLoadSchoolSetting from '@hooks/useLoadSchoolSetting';
import { SiteMap } from '@router/SiteMap';
import { RoleEnum } from '@app/redux/slices/roles';
import useRefreshUserData from '@hooks/useRefreshUserData';
import { useTranslation } from 'react-i18next';

export const PrivateRouter = ({
  roles,
  element,
}: {
  roles?: RoleEnum[];
  element: ReactNode;
}) => {
  const { t } = useTranslation();
  const { isReady } = useSelector((state) => state.profile);
  const { ifAnyGranted } = useSecurity();

  useRefreshUserData();
  useLoadSchoolSetting(true);

  const hasPermission = useMemo(() => {
    return Boolean(roles == null || roles.length === 0 || ifAnyGranted(roles));
  }, [roles, isReady, ifAnyGranted]);

  if (isReady) {
    return hasPermission ? (
      <>{element}</>
    ) : (
      <Navigate to={SiteMap.public.page_403} />
    );
  } else {
    return <div>{t('label.loading')}</div>;
  }
};
