import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import NavLink from '@components/button/NavLink';
import { SiteMap } from '@router/SiteMap';

export const AppLogo = (props: { children?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <div className={'logo'}>
      <NavLink href={SiteMap.index}>{t('app.name')}</NavLink>

      {props.children && <>{props.children}</>}
    </div>
  );
};
