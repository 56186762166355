import {
  ComponentScoreIcon,
  NeedGradingIco,
} from '@cms/icons/ComponentScoreIcon';
import React from 'react';
import {
  ExerciseResponseRes,
  ExerciseResponseStatusEnum,
} from '@modules/assignments/service/exercise_model';

export const StudentAnswerResponse = (props: { item: ExerciseResponseRes }) => {
  if (props.item.status === ExerciseResponseStatusEnum.NEED_GRADING) {
    return <NeedGradingIco maxScore={props.item.maxScore} />;
  } else {
    return (
      <ComponentScoreIcon
        isSkip={props.item.skip != null && props.item.skip}
        score={props.item.score}
        maxScore={props.item.maxScore}
      />
    );
  }
};
