import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateLessonExampleReq,
  LessonExampleRes,
} from '@modules/admin/service/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonExampleAPI = createApi({
  reducerPath: 'LessonExampleAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<LessonExampleRes>, PaginationReq>({
      query: (params) => ({
        url: `/lesson-example`,
        params,
      }),
    }),

    viewTemplates: builder.query<LessonExampleRes[], string>({
      query: (category) => ({
        url: `/lesson-example/categories/${category}`,
      }),
    }),

    viewLessonExample: builder.query<LessonExampleRes, number>({
      query: (pageSiteId) => ({
        url: `/lesson-example/${pageSiteId}`,
        method: 'GET',
      }),
    }),

    viewLessonExampleContent: builder.query<LessonRes, number>({
      query: (pageSiteId) => ({
        url: `/lesson-example/${pageSiteId}/view`,
        method: 'GET',
      }),
    }),

    createLessonExample: builder.mutation<
      LessonExampleRes,
      CreateLessonExampleReq
    >({
      query: (body: LessonExampleRes) => ({
        url: '/lesson-example',
        body,
        method: 'POST',
      }),
    }),

    updateLessonExample: builder.mutation<
      LessonExampleRes,
      CreateLessonExampleReq
    >({
      query: (body: CreateLessonExampleReq) => ({
        url: `/lesson-example/${body.lessonExampleId}`,
        body,
        method: 'PUT',
      }),
    }),

    deleteLessonExample: builder.mutation<BasicMessageRes, number>({
      query: (pageSiteId: number) => ({
        url: `/lesson-example/${pageSiteId}`,
        method: 'DELETE',
      }),
    }),

    publishLessonExample: builder.mutation<LessonExampleRes, number>({
      query: (lessonId) => ({
        url: `/lesson-example/${lessonId}/publish`,
        method: 'PUT',
      }),
    }),
  }),
});
