import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';
import {
  ExerciseRes,
  ExerciseResponseRes,
} from '@modules/assignments/service/exercise_model';
import { ResourceRes } from '@modules/product/services/resource_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface ExerciseResourceStandardRes {
  exerciseResourceStandardId: number;
  resource: ResourceRes;
  standard: StandardRes;
  score: number;
  maxScore: number;
  createdDate: number;
}
//
export interface ExerciseStandardRes {
  exerciseStandardId: number;
  standard: StandardRes;
  score: number;
  maxScore: number;
  createdDate: string;
}
//
// export interface ExerciseResponseRes {
//   exerciseResponseId: number;
//   resource: ResourceRes;
//   displayOrder: number;
//   status: ExerciseResponseStatusEnum;
//   answer: string;
//   duration: number;
//   skip: boolean;
//   score: number;
//   maxScore: number;
//   tryTimes: number;
//   comment: string;
//   result: string;
// }

export interface ExerciseReportRes {
  exercise: ExerciseRes;
  responses: ExerciseResponseRes[];
  standards: ExerciseStandardRes[];
  resourceStandards: any;
}

export interface StandardSetWeightRes {
  standardSet: StandardSetRes;
  weight: number;
}

export interface StandardWeightRes {
  standard: StandardRes;
  weight: number;
}

export interface LessonScoreReport {
  lesson: LessonRes;
  standardSets: StandardSetWeightRes[];
  standards: StandardWeightRes[];
  exercises: ExerciseReportRes[];
}

export const LessonReportAPI = createApi({
  reducerPath: 'LessonReportAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getLessonReport: builder.query<LessonScoreReport, number>({
      query: (lessonId) => ({
        url: `/reports/lesson-score/${lessonId}`,
      }),
    }),
  }),
});
