import React, { useEffect, useState } from 'react';
import { StudentAssignmentAPI } from '@modules/assignments/service/StudentAssignmentAPI';
import { PaginationReq } from '@services/model/PaginationRes';
import Card, { CardClassEnum } from '@components/card';
import { AssignmentItem } from '@modules/assignments/components/AssignmentItem';
import { List } from 'antd';
import { AssignmentStudentStyle } from '@modules/dashboard/components/UncompleteAssignmentStudent';

const CompleteAssignmentStudent = () => {
  const [getCompletedAssignment, { data, isSuccess }] =
    StudentAssignmentAPI.endpoints.getCompletedAssignment.useLazyQuery();

  const [params] = useState<PaginationReq>({
    page: 1,
    size: 6,
    sort: 'createdDate',
    direction: 'descend',
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    getCompletedAssignment(params);
  };

  useEffect(() => {
    if (data && isSuccess) {
      console.log(data);
    }
  }, [data]);

  return (
    <>
      {isSuccess && data && (
        <AssignmentStudentStyle className={'incoming-events'}>
          <List
            className={'complete-assignment'}
            itemLayout="horizontal"
            dataSource={data.content}
            renderItem={(assignment) => (
              <List.Item>
                <Card
                  hoverable
                  className={CardClassEnum.rectangle}
                  key={assignment.assignmentInstanceId}
                >
                  <AssignmentItem item={assignment} />
                </Card>
              </List.Item>
            )}
          />
        </AssignmentStudentStyle>
      )}
    </>
  );
};

export default CompleteAssignmentStudent;
