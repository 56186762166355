import { FullscreenButton } from '@cms/lesson-template/components/buttons/compose/FullScreenButton';
import { PrintLessonButton } from '@cms/lesson-template/components/buttons/compose/PrintLessonButton';
import React from 'react';
import { DoAssignmentGroupBtn } from '@cms/lesson-template/components/button-wrapper/DoAssignmentGroupBtn';
import { ExitExerciseButton } from '@cms/lesson-template/components/buttons/exercise/ExitExerciseButton';
import { ExerciseToolbarStyle } from '@cms/lesson-template/components/toolbar/common/style';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { H3 } from '@components/typography';
import { RemainTimeBtn } from '@cms/lesson-template/components/buttons/RemainTimeBtn';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';

export const DoExerciseToolbar = () => {
  const { lesson } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  return (
    <ExerciseToolbarStyle className={'lesson-toolbar do-exercise-toolbar'}>
      <div className={'header-group'}>
        <ExitExerciseButton />

        <H3>{lesson.name}</H3>
      </div>

      <div className={'right-group'}>
        <FullscreenButton />
        <PrintLessonButton />

        {!config.singleMode && <DoAssignmentGroupBtn />}

        <RemainTimeBtn />
      </div>
    </ExerciseToolbarStyle>
  );
};
