import { Button, Col, Skeleton, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollToTop from '@components/feature/ScrollToTop';
import { PublishCourseStyle } from '@modules/course/container/publish/CourseInformationStep';
import {
  CourseInfoRes,
  PublishCourseAPI,
} from '@services/publish/PublishCourseAPI';

import { Common } from '@services/model/common';
import { CourseRes } from '@services/private/CourseAPI';
import { SchoolRes } from '@services/private/SchoolAPI';
import Card from '@components/card';

export default function CourseReviewStep(props: {
  schoolData: SchoolRes;
  courseData: CourseRes;
  goPrevious: () => void;
  goNext: (course: CourseRes) => void;
}) {
  const { t } = useTranslation();

  const [getReviewCourseData, { data, isSuccess, isLoading }] =
    PublishCourseAPI.endpoints.getReviewCourseData.useLazyQuery();
  const [publishCourse, { isLoading: publishLoading }] =
    PublishCourseAPI.endpoints.publishCourse.useMutation({});

  const [publishCourseData, setPublishCourseData] =
    useState<CourseInfoRes | null>(null);

  useEffect(() => {
    getReviewCourseData(props.courseData.courseId);
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setPublishCourseData(data);
    }
  }, [data]);

  const handlePublishCourse = () => {
    publishCourse(props.courseData.courseId)
      .unwrap()
      .then((result: Common) => {
        if (result.success) {
          warningMessage(true);
          props.goNext(props.courseData);
        } else {
          warningMessage(false);
        }
      })
      .catch(() => {
        warningMessage(false);
      });
  };

  const warningMessage = (isSuccess: boolean) => {
    if (isSuccess) {
      notification.success({
        message: t('course.warning.publish_success'),
        description: t('course.warning.publish_success_message'),
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: t('course.warning.publish_error'),
        description: t('course.warning.publish_error_message'),
        placement: 'bottomRight',
      });
    }
  };

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishCourseStyle className={'publish-course-section'}>
        <h1>{t('publish.basic_info.review')}</h1>

        <div className={'course-publish-content'}>
          {!data && (
            <>
              <Skeleton paragraph={{ rows: 4 }} />
              <Skeleton paragraph={{ rows: 4 }} />
            </>
          )}

          {publishCourseData && (
            <>
              <Row gutter={[24, 24]} align="top" justify="center">
                <Col span={8}></Col>

                <Col span={16}>
                  <Card></Card>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className={'course-publish-step'}>
          <Button type={'default'} shape="round" onClick={props.goPrevious}>
            {t('button.step.previous')}
          </Button>

          <Button
            type={'primary'}
            shape="round"
            onClick={handlePublishCourse}
            loading={publishLoading || isLoading}
          >
            {t('button.step.publish')}
          </Button>
        </div>
      </PublishCourseStyle>
    </>
  );
}
