import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { LoginLayout } from '@modules/authorization/layout/LoginLayout';
import Card, { CardClassEnum } from '@components/card';
import LoginForm from '@modules/authorization/container/LoginForm';

const LoginPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleLoginSuccess = () => {
    router.push(SiteMap.private.dashboard);
  };

  const handleOnCancel = () => {
    router.push(SiteMap.index);
  };

  return (
    <LoginLayout>
      <h1 className={'text-center'}>{t('login.header')}</h1>

      <Card className={CardClassEnum.rectangle_box}>
        <LoginForm onCancel={handleOnCancel} onSuccess={handleLoginSuccess} />
      </Card>
    </LoginLayout>
  );
};

export default LoginPage;
