import { ImageContentProps, TextContentProps } from '@cms/content/ContentType';

export enum CompMode {
  // for compose -->
  COMPOSE = 'COMPOSE',
  VIEW = 'VIEW',
  REVIEW = 'REVIEW',

  // for self practice
  ANONYMOUS_VIEW = 'ANONYMOUS-VIEW', // anonymous revise
  SELF_CHECK = 'SELF-CHECK', // teacher review the lesson and submit

  // for exercise....
  DO_EXERCISE = 'DO_EXERCISE',
  GRADING_EXERCISE = 'GRADING-EXERCISE',
  REVIEW_EXERCISE = 'REVIEW_EXERCISE',

  // for assignment....
  DO_ASSIGNMENT = 'DO_ASSIGNMENT',
  GRADING_ASSIGNMENT = 'GRADING-GRADING_ASSIGNMENT',
  REVIEW_ASSIGNMENT = 'REVIEW_ASSIGNMENT',

  // for presentation
  PRESENTATION = 'presentation',
}

export enum CompTypeEnum {
  EMPTY = '',

  // Static content
  STATIC_CONTENT = 'STATIC-CONTENT',
  DYNAMIC_CONTENT = 'DYNAMIC-CONTENT',
  TEMPLATE = 'TEMPLATE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  WORD = 'WORD',
  EPUB = 'EPUB',
  SOCIAL_VIDEO = 'SOCIAL_VIDEO',

  // Interact
  MATCHING = 'MATCHING',
  DRAG_N_DROP = 'DRAG_N_DROP',
  DRAG_N_DROP_INLINE = 'DRAG_N_DROP_INLINE',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  CHECK_BOX = 'CHECK_BOX',
  ORDERING = 'ORDERING',

  SELECT_TEXT = 'SELECT_TEXT',

  FIB_WORD = 'FIB_WORD',
  CIRCLE_UNDERLINE = 'CIRCLE_UNDERLINE',

  FIB_PARAGRAPH = 'FIB_PARAGRAPH',
  FIB_ESSAY = 'FIB_ESSAY',
  AUDIO_RECORDING = 'AUDIO_RECORDING',

  // glossary game
  GLOSSARY = 'GLOSSARY',
  FLASH_CARD = 'FLASH_CARD',
  GAME_MATCHING = 'GAME_MATCHING',
  WORD_BOX = 'WORD_BOX',
  WORD_SEARCH = 'WORD_SEARCH',
  WORD_SCRAMBLE = 'WORD_SCRAMBLE',
  WORD_PUZZLE = 'WORD_PUZZLE',

  // math operation
  FIB_NUMBER = 'FIB_NUMBER',
  FIB_FRACTION = 'FIB_FRACTION',
  FIB_EQUATION = 'FIB-EQUATION',

  LONG_ADDITION = 'LONG_ADDITION',
  LONG_SUBTRACTION = 'LONG_SUBTRACTION',
  LONG_MULTIPLICATION = 'LONG_MULTIPLICATION',
  LONG_DIVISION = 'LONG_DIVISION',
  AREA_MULTIPLICATION = 'AREA_MULTIPLICATION',
  AREA_DIVISION = 'AREA_DIVISION',

  PLACE_VALUE = 'PLACE_VALUE',

  COORDINATE = 'COORDINATE',
}

export const INTERACT_COMPONENT_TYPES: CompTypeEnum[] = [
  CompTypeEnum.MATCHING,
  CompTypeEnum.DRAG_N_DROP,
  CompTypeEnum.DRAG_N_DROP_INLINE,
  CompTypeEnum.DROPDOWN,
  CompTypeEnum.MULTIPLE_CHOICE,
  CompTypeEnum.CHECK_BOX,
  CompTypeEnum.ORDERING,

  CompTypeEnum.FIB_WORD,
  CompTypeEnum.CIRCLE_UNDERLINE,

  CompTypeEnum.FIB_PARAGRAPH,
  CompTypeEnum.FIB_ESSAY,
  CompTypeEnum.AUDIO_RECORDING,

  CompTypeEnum.GAME_MATCHING,
  CompTypeEnum.WORD_BOX,
  CompTypeEnum.WORD_SEARCH,
  CompTypeEnum.WORD_SCRAMBLE,
  CompTypeEnum.WORD_PUZZLE,

  CompTypeEnum.FIB_NUMBER,
  CompTypeEnum.FIB_FRACTION,
  CompTypeEnum.FIB_EQUATION,

  CompTypeEnum.LONG_ADDITION,
  CompTypeEnum.LONG_SUBTRACTION,
  CompTypeEnum.LONG_MULTIPLICATION,
  CompTypeEnum.LONG_DIVISION,
  CompTypeEnum.AREA_MULTIPLICATION,
  CompTypeEnum.AREA_DIVISION,

  CompTypeEnum.PLACE_VALUE,
  CompTypeEnum.COORDINATE,
];

export const MANUAL_SCORING_COMPONENT_TYPES: CompTypeEnum[] = [
  CompTypeEnum.FIB_PARAGRAPH,
  CompTypeEnum.FIB_ESSAY,
  // CompTypeEnum.AUDIO_RECORDING,
  CompTypeEnum.COORDINATE,
];

export enum CompConfiguration {
  INLINE_COMPONENT = '__________',
  INLINE_COMPONENT_CLASS = 'inline-component',
  INLINE_COMPONENT_EL = `<div class="inline-component"></div>`,
  SEPARATE_CONTENT = '//',
}

export interface CompType {
  type: CompTypeEnum;
}

export interface CompProps extends CompType {
  id: string;
  configuration?: any;
  setting?: CompInteractSettingProps | any;
  timestamp?: number;
}

export interface CompAnswerProps {
  id: string;
  type: CompTypeEnum;
  answer: any;
  timestamp?: number;
}

export interface CompScoringProps {
  id: string;
  type: CompTypeEnum;
  manualScore: boolean;
  maxScore: number;
}

export interface CompInteractSettingProps {
  className?: string;
  autoScore?: boolean;
}

export interface CompGradingProps {
  id: string;
  score: number | null;
  comment: string | null;
}

export interface TimelineProps {
  label: string;
  from: number;
  to: number;
  content: TextContentProps | ImageContentProps;
}
