import { MatchingSettingProps } from '@cms/comps/interact/matching/MatchingComp';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { InputGroup } from '@components/input/InputGroup';
import { Select } from 'antd';
import Radio from '@components/input/Radio';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';

export const MatchingSetting = (props: {
  setting: MatchingSettingProps;
  onSettingChange: (newSetting: MatchingSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <InputGroup label={t('component.label.select_layout')}>
          <Select
            size={'large'}
            placeholder={t('component.label.select_layout')}
            onChange={(value) => formik.setFieldValue('layout', value)}
            value={formik.values.layout}
          >
            <Select.Option key={'one-to-one'} value={'one-to-one'}>
              {t('component.label.one_to_one')}
            </Select.Option>

            <Select.Option key={'one-to-many'} value={'one-to-many'}>
              {t('component.label.one_to_many')}
            </Select.Option>

            <Select.Option key={'many-to-many'} value={'many-to-many'}>
              {t('component.label.many_to_many')}
            </Select.Option>
          </Select>
        </InputGroup>

        <Radio
          label={t('component.drag_n_drop.event_type')}
          name={'eventType'}
          value={formik.values.eventType}
          onChange={formik.handleChange}
          options={[
            { value: 'drag', label: t('component.drag_n_drop.drag') },
            { value: 'click', label: t('component.drag_n_drop.click') },
          ]}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
