import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { LessonRes } from '@modules/product/services/lesson_model';

export enum LessonEditableActionEnum {
  default = '',
  refresh = 'refresh',
  view_resource = 'view-resource',
  clone_resource = 'clone-resource',
  insert_resource = 'insert-resource',
  delete_resource = 'delete-resource',
  show_setting = 'show-setting',
  save_resource = 'save-resource',

  add_component = 'add-component',

  create_lesson = 'create-lesson',
  view_data = 'view-data',
}

export enum EditLessonTypeEnum {
  simple = 'simple',
  complex = 'complex',
}

export enum LessonMode {
  view = 'view',
  edit = 'edit',
  view_question_banks = 'view-question-banks',
}

const LessonEditableContext = createContext({
  from: '' as string,
  editMode: EditModeEnum.REVIEW as EditModeEnum,

  lessonId: -1 as number,
  productId: -1 as number,
  productTocId: -1 as number,
  lessonExampleId: -1 as number,

  lesson: null as LessonRes | null,
  resources: [] as ResourceProps[],

  mode: LessonMode.view as LessonMode,
  changeToMode: (mode: LessonMode) => {
    console.log(mode);
  },

  action: {
    action: LessonEditableActionEnum.default as LessonEditableActionEnum,
    params: -1 as number,
  },
  dispatchAction: (action: LessonEditableActionEnum, params: number) => {
    console.log(action, params);
  },
});

export const LessonEditableProvider = (props: {
  from: string;
  lessonId: number;
  resourceId?: number;
  productId?: number;
  productTocId?: number;
  lessonExampleId?: number;
  editMode: EditModeEnum;
  children: ReactNode;
}) => {
  const service = useLessonServiceContext();

  const [mode, setMode] = useState<LessonMode>(
    props.lessonId != null && props.lessonId > 0
      ? LessonMode.view
      : LessonMode.edit
  );
  const [lessonExampleId, setLessonExampleId] = useState(
    props.lessonExampleId ?? -1
  );

  const [action, setAction] = useState({
    action: LessonEditableActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    setLessonExampleId(props.lessonExampleId ?? -1);
  }, [props.lessonExampleId]);

  useEffect(() => {
    if (service.lesson != null) {
      if (
        service.lesson.lessonExample != null &&
        service.lesson.lessonExample.lessonExampleId > 0
      ) {
        setLessonExampleId(service.lesson.lessonExample.lessonExampleId);
      }
    }
  }, [service.lesson]);

  const dispatchAction = (action: LessonEditableActionEnum, params: number) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const changeToMode = (mode: LessonMode) => {
    if (props.editMode === EditModeEnum.SINGLE_QUESTION) {
      setMode(LessonMode.edit);
    } else {
      setMode(mode);
    }
  };

  return (
    <LessonEditableContext.Provider
      value={{
        from: props.from,
        lessonId: props.lessonId,
        productId: props.productId ?? -1,
        productTocId: props.productTocId ?? -1,
        lessonExampleId: lessonExampleId,
        editMode: props.editMode,

        lesson: service.lesson,
        resources: service.resources,

        mode: mode,
        changeToMode: changeToMode,
        action: action,
        dispatchAction: dispatchAction,
      }}
    >
      {props.children}
    </LessonEditableContext.Provider>
  );
};

export const useLessonEditableContext = () => {
  const context = useContext(LessonEditableContext);
  if (!context) {
    throw new Error('You must wrap container by LessonEditableProvider');
  }
  return context;
};
