import { ButtonProps as AntButtonProps } from 'antd';
import React, { ReactNode } from 'react';
import Button from '@components/button/index';
import { useSelector } from '@app/redux/hook';

export const RequireProUser = (props: { children: ReactNode }) => {
  const { proUser } = useSelector((state) => state.userInformation);

  if (proUser != null && proUser) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export const useProUser = () => {
  const { proUser } = useSelector((state) => state.userInformation);
  return proUser != null && proUser;
};

const RequireProUserButton = ({ ...props }: AntButtonProps) => {
  const { proUser } = useSelector((state) => state.userInformation);
  return <>{proUser != null && proUser && <Button {...props} />}</>;
};

export default RequireProUserButton;
