import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DownOutlined, FieldTimeOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import styled from 'styled-components';
import { Tooltip } from '../tooltip/Tooltip';
import { getIn } from 'formik';
import { InputMessageStyle } from '@components/date-n-time/DatePicker';

declare type PeriodType = 'AM' | 'PM';

interface ITime {
  hour: string;
  minute: string;
  period: PeriodType;
}

interface TimePickerProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  name?: string;
  handleChange?: (e: any) => void;

  formik: any;
  error?: boolean;
  message?: any;
  success?: boolean;
}

const TimepickerStyle = styled.div`
  .ant-input {
    color: rgba(0, 0, 0, 0.85) !important;
  }
`;

export default function TimePicker({
  value,
  onChange,
  name,
  handleChange,
  disabled,

  formik,
  error,
  message,
  success,
}: TimePickerProps) {
  const isFirstTime = useRef(false);

  const isFormikError =
    !!name &&
    !!getIn(formik, `errors.${name}`) &&
    !!getIn(formik, `touched.${name}`);
  const isError = error || isFormikError;
  const errorMessage = message || (name ? getIn(formik, `errors.${name}`) : '');

  const getTimeFromValue = (value: string) => {
    if (value) {
      const hours = Number(value.split(':')[0]);
      const minutes = Number(value.split(':')[1]);
      return hours * 60 + minutes;
    } else {
      return 0;
    }
  };

  const convertTimeToString = (timeValue: number): ITime => {
    const minutes = timeValue % 60;
    const hours = (timeValue - minutes) / 60;
    const period = hours >= 12 ? 'PM' : 'AM';

    const hour_12 = hours < 13 ? hours : hours % 12;
    const hourDisplay =
      hour_12 === 0 ? '12' : hour_12 < 10 ? '0' + hour_12 : hour_12 + '';
    const minuteDisplay = minutes < 10 ? '0' + minutes : minutes + '';

    return {
      hour: hourDisplay,
      minute: minuteDisplay,
      period: period,
    };
  };

  const [timeValue, updateTimeValue] = useState<number>(
    getTimeFromValue(value ?? '')
  );

  const [timeInput, setTimeInput] = useState<ITime>(() => {
    return convertTimeToString(getTimeFromValue(value ?? ''));
  });

  useEffect(() => {
    const timeValue = getTimeFromValue(value ?? '');
    setTimeValue(timeValue);
  }, [value]);

  useEffect(() => {
    const timeDisplay = convertTimeToString(timeValue);
    setTimeInput(timeDisplay);
  }, [timeValue]);

  useEffect(() => {
    if (isFirstTime.current && (onChange || handleChange)) {
      const minutes = timeValue % 60;
      const hours = (timeValue - minutes) / 60;

      const hoursDisplay = hours < 10 ? '0' + hours : hours;
      const minuteDisplay = minutes < 10 ? '0' + minutes : minutes;

      const currentValue = `${hoursDisplay}:${minuteDisplay}:00`;

      if (currentValue !== value) {
        if (onChange) {
          onChange(currentValue);
        }

        if (handleChange) {
          const ev = new CustomEvent<ChangeEvent<any>>('timePicker', {
            bubbles: true,
          });
          const target = {
            value: currentValue,
            name,
          };
          Object.defineProperty(ev, 'target', {
            value: target,
            writable: false,
          });
          handleChange(ev);
        }
      }
    }
  }, [timeValue]);

  useEffect(() => {
    isFirstTime.current = true;
  }, []);

  const setTimeValue = (value: number) => {
    if (value < 0) {
      updateTimeValue(value + 24 * 60);
    } else {
      updateTimeValue(value % (24 * 60));
    }
  };

  return (
    <TimepickerStyle>
      <Tooltip
        placement="bottomLeft"
        trigger={'click'}
        color={'#FFF'}
        title={
          <HourMinutePicker
            timeValue={timeValue}
            timeInput={timeInput}
            setTimeInput={setTimeInput}
            setTimeValue={setTimeValue}
          />
        }
      >
        <Input
          size={'large'}
          name=""
          readOnly
          value={`${timeInput.hour}:${timeInput.minute} ${timeInput.period}`}
          suffix={<FieldTimeOutlined />}
          className="cursor-pointer"
          disabled={disabled}
        />
      </Tooltip>

      {((errorMessage && isError) || success) && (
        <InputMessageStyle
          className="error-message"
          success={success}
          error={isError}
        >
          {errorMessage}
        </InputMessageStyle>
      )}
    </TimepickerStyle>
  );
}

const HourMinutePickerStyle = styled.table`
  td {
    text-align: center;
    height: 30px;
    margin: 0;
    padding: 2px;

    input {
      width: 50px;
      min-width: 40px !important;

      margin: 0;
      text-align: center;
      border-radius: 0 !important;
      height: 40px;
      font-size: 16px !important;

      background: #fff;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
      padding: 6.5px 11px 6.5px;

      &:focus {
        outline: 0;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
          0 0 8px rgba(82, 168, 236, 0.6);
      }
    }
  }

  td:not(.separator) {
    min-width: 30px;
  }

  button {
    border: 1px transparent solid;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 8px 0;
    outline: 0;
    color: #333;
    background: transparent;

    &:hover {
      text-decoration: none;
      background-color: #eee;
      border-radius: 4px;
      border-color: #ddd;
    }
  }
`;
const HourMinutePicker = ({
  timeInput,
  timeValue,
  setTimeInput,
  setTimeValue,
}: {
  timeInput: any;
  timeValue: any;
  setTimeInput: any;
  setTimeValue: any;
}) => {
  const adjustMinutes = (adjustTimes: number) => {
    const customTime = adjustTimes < 60 && timeValue % 15 !== 0;
    let adjustMins = adjustTimes;

    if (customTime) {
      adjustMins =
        // round up to 15 or degres to 15.
        adjustMins > 0
          ? adjustMins - (timeValue % 15)
          : adjustMins + (15 - (timeValue % 15));
    }

    setTimeValue(timeValue + adjustMins);
  };

  const handleChangeTime = (e: any) => {
    const name: 'hour' | 'minute' | 'period' = e.target.name;
    const value = e.target.value;

    setTimeInput((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlurTime = (e: any) => {
    const name: 'hour' | 'minute' | 'period' = e.target.name;
    const value = e.target.value;

    if (name === 'hour') {
      let newTimeValue;

      if (timeInput.period === 'PM') {
        newTimeValue = Number(value) * 60 + Number(timeInput.minute) + 12 * 60;
      } else {
        newTimeValue = (Number(value) % 12) * 60 + Number(timeInput.minute);
      }
      setTimeValue(newTimeValue);
    } else if (name === 'minute') {
      const newTimeValue =
        (timeInput.period === 'PM' ? 12 * 60 : 0) +
        (Number(timeInput.hour) % 12) * 60 +
        Number(value);
      setTimeValue(newTimeValue);
    } else if (name === 'period') {
      const newTimeValue =
        (value === 'PM' ? 12 * 60 : 0) +
        (Number(timeInput.hour) % 12) * 60 +
        Number(timeInput.minute);
      setTimeValue(newTimeValue);
    }
  };

  return (
    <HourMinutePickerStyle cellSpacing={0} cellPadding={0}>
      <tbody>
        <tr>
          <td>
            <Button
              type={'default'}
              onClick={() => adjustMinutes(60)}
              icon={<UpOutlined />}
            />
          </td>
          <td className="separator">&nbsp;</td>
          <td>
            <Button
              type={'default'}
              icon={<UpOutlined />}
              onClick={() => adjustMinutes(15)}
            />
          </td>
          <td className="separator">&nbsp;</td>
          <td>
            <Button
              type={'default'}
              icon={<UpOutlined />}
              onClick={() => adjustMinutes(12 * 60)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Input
              size={'large'}
              maxLength={2}
              name="hour"
              value={timeInput.hour}
              onChange={handleChangeTime}
              onBlur={handleBlurTime}
            />
          </td>
          <td className="separator">:</td>
          <td>
            <Input
              size={'large'}
              maxLength={2}
              name="minute"
              value={timeInput.minute}
              onChange={handleChangeTime}
              onBlur={handleBlurTime}
            />
          </td>
          <td className="separator">&nbsp;</td>
          <td>
            <Input
              size={'large'}
              maxLength={2}
              name="period"
              value={timeInput.period}
              onChange={handleChangeTime}
              onBlur={handleBlurTime}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Button
              type={'default'}
              onClick={() => adjustMinutes(-60)}
              icon={<DownOutlined />}
            />
          </td>
          <td className="separator">&nbsp;</td>
          <td>
            <Button
              type={'default'}
              onClick={() => adjustMinutes(-15)}
              icon={<DownOutlined />}
            />
          </td>
          <td className="separator">&nbsp;</td>
          <td>
            <Button
              type={'default'}
              onClick={() => adjustMinutes(-(12 * 60))}
              icon={<DownOutlined />}
            />
          </td>
        </tr>
      </tbody>
    </HourMinutePickerStyle>
  );
};
