import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';

import React from 'react';
import MyAccount from '@router/pages/private/my_account/my_account';
import { RoleEnum } from '@app/redux/slices/roles';
import PublicProfile from '@router/pages/private/my_account/public_profile';
import AccountProfile from '@router/pages/private/my_account/account_profile';
import MyCertificate from '@router/pages/private/my_account/MyCertificate';

export const MyAccountRouters: PageRouterProps[] = [
  {
    path: SiteMap.private.my_account.index,
    element: <PrivateRouter element={<MyAccount />} />,
  },

  {
    path: SiteMap.private.my_account.public_profile,
    element: <PrivateRouter element={<AccountProfile />} />,
  },

  {
    path: SiteMap.private.my_account.review,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
        element={<PublicProfile />}
      />
    ),
  },

  {
    path: SiteMap.private.my_account.certificate,
    element: (
      <PrivateRouter
        roles={[RoleEnum.STUDENT, RoleEnum.PARENT]}
        element={<MyCertificate />}
      />
    ),
  },
];
