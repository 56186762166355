import styled from 'styled-components';
import { ClassRoomImg } from '@components/background/calendar';
import React, { ReactNode } from 'react';

export const ClassRoomWaiting = (props: { children: ReactNode }) => {
  return (
    <WaitingClassRoomBgr className={'classroom-waiting'}>
      {props.children}
    </WaitingClassRoomBgr>
  );
};

export const WaitingClassRoomBgr = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;

  background: #ffffff url('${ClassRoomImg}') no-repeat center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
`;
