import React from 'react';

import TableOfContentPage from '@modules/product/pages/TableOfContentPage';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { ComposeTocProvider } from '@modules/product/components/toc/ComposeTocProvider';
import { PageHeader } from '@app/header/PageHeader';

const TableOfContentProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id } = router.query;

  return (
    <ManagementLayout className={'product-table-of-content-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={t('table_of_content.header')}
        description={t('table_of_content.header')}
      />

      <ComposeTocProvider productId={Number(id)}>
        <TableOfContentPage />
      </ComposeTocProvider>
    </ManagementLayout>
  );
};

export default TableOfContentProduct;
