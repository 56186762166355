import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useState } from 'react';
import {
  GlossaryGameCompProps,
  GlossaryGameContent,
} from '@cms/comps/game/GameUtils';
import { WordPuzzleItems } from '@cms/comps/game/word-puzzle/WordPuzzleItem';
import styled from 'styled-components';
import {
  GlossaryGameActionEnum,
  useGlossaryGameItemContext,
} from '@cms/lesson-template/glossary-game/GlossaryGameContext';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { GlossaryWordBank } from '@cms/comps/game/GlossaryWordBank';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { WordPuzzleCompProvider } from './WordPuzzleCompProvider';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';

export const MOVING_KEYCODE = [37, 39, 38, 40];

export interface WordPuzzleSettingProps extends CompInteractSettingProps {
  layout: string;
  maxRows: number;
  maxColumns: number;
}

export interface WordPuzzleCompProps extends GlossaryGameCompProps {
  configuration: {
    defaultWord?: string;
    sourceItems: GlossaryGameContent[];
    targetItems: GlossaryGameContent[];
  };
  setting: WordPuzzleSettingProps;
}

export enum MovingType {
  previous = 'previous',
  next = 'next',
}

export function WordPuzzleComp(props: {
  item: WordPuzzleCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);

  const context = useGlossaryGameItemContext();

  const onCompletePart = (points: number) => {
    if (context && context.dispatchAction) {
      context.dispatchAction(
        GlossaryGameActionEnum.correct_part,
        points,
        CompTypeEnum.WORD_PUZZLE
      );
    }
  };

  const onComplete = (points: number) => {
    if (context && context.dispatchAction) {
      context.dispatchAction(
        GlossaryGameActionEnum.correct,
        points,
        CompTypeEnum.WORD_PUZZLE
      );
    }
  };

  const handleComponentChange = (newComps: WordPuzzleCompProps) => {
    const answer = newComps.configuration.sourceItems.map((ans) => {
      return ans.word.data;
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );
    setShowComponent(true);
  };

  return (
    <WordPuzzleCompProvider
      item={props.item}
      onCompleted={onComplete}
      onCorrectPart={onCompletePart}
    >
      {showComponent && (
        <WordPuzzleCompStyle className={'comp comp-word-puzzle'}>
          <WordPuzzleItems item={props.item} />

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </WordPuzzleCompStyle>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </WordPuzzleCompProvider>
  );
}

const WordPuzzleCompStyle = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  display: flex;
  border: 1px solid #ccc;
  padding: 0.5em;

  .word-puzzle-item {
    width: 100%;
    height: 100%;
  }
`;
