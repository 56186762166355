import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  LinkToLanguageReq,
  PageSiteAPI,
  PageSiteRes,
} from '@services/page-site/PageSiteAPI';

import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';
import styled from 'styled-components';
import Button from '@components/button';
import { Popover, List, notification } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { H4 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useSelector } from '@app/redux/hook';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export const PageSiteLanguagePages = (props: { item: PageSiteRes }) => {
  const { languages } = useSelector((state) => state.appLanguage);
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <PageSiteLessonContent item={props.item} onClose={handleOnClose} />
      }
      title={<H4 style={{ marginBottom: 0 }}>{props.item.name}</H4>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button type={'text'}>
        <PageSiteLanguagePageStyle>
          {languages.map((lang) => {
            const lessons = props.item.pages.filter((ls) => {
              return ls.language.code === lang;
            });

            return (
              <div
                key={lang}
                className={`language-lesson-item ${
                  lessons.length > 0 ? ' active' : ''
                }`}
              >
                <LanguageIcon height={20} language={lang} />
              </div>
            );
          })}
        </PageSiteLanguagePageStyle>
      </Button>
    </Popover>
  );
};

const PageSiteLessonContent = (props: {
  item: PageSiteRes;
  onClose: () => void;
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const proUser = useProUser();

  const { languages } = useSelector((state) => state.appLanguage);
  const [linkToLanguage] = PageSiteAPI.endpoints.linkToLanguage.useMutation({});

  const composeLesson = (lessonId: number) => {
    router.push(SiteMap.content.lesson.compose_gen(lessonId, proUser));
  };

  const onReport = (lessonId: number) => {
    router.push(SiteMap.reports.lesson_report_gen(lessonId));
  };

  const onDesignLesson = (language: LanguageEnum) => {
    const request: LinkToLanguageReq = {
      pageSiteId: props.item.pageSiteId,
      language: language,
    };

    linkToLanguage(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('page_site.warning.add_success'),
          description: t('page_site.warning.add_success_message'),
          placement: 'bottomRight',
        });

        composeLesson(data.lessonId);
      })
      .catch(() => {
        notification.error({
          message: t('page_site.warning.add_error'),
          description: t('page_site.warning.add_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <PageSiteLessonContentStyle>
      <List
        itemLayout="horizontal"
        dataSource={languages}
        renderItem={(lang) => {
          const lessons = props.item.pages.filter((ls) => {
            return ls.language.code === lang;
          });

          return (
            <List.Item
              className={lessons.length > 0 ? ' active' : ''}
              actions={
                lessons.length > 0
                  ? [
                      <Button
                        key={'compose'}
                        type={'default'}
                        shape={'round'}
                        size={'small'}
                        onClick={() =>
                          composeLesson(lessons[0].lesson.lessonId)
                        }
                        icon={IconUtils.compose_lesson}
                      >
                        {t('page_site.actions.compose')}
                      </Button>,

                      <Button
                        key={'report'}
                        type={'primary'}
                        shape={'round'}
                        size={'small'}
                        onClick={() => onReport(lessons[0].lesson.lessonId)}
                        icon={IconUtils.exercise.report}
                      >
                        {t('button.report')}
                      </Button>,
                    ]
                  : [
                      <Button
                        key={'compose'}
                        type={'primary'}
                        shape={'round'}
                        size={'small'}
                        onClick={() => onDesignLesson(lang)}
                        icon={IconUtils.compose_lesson}
                      >
                        {t('button.create')}
                      </Button>,
                    ]
              }
            >
              <div key={lang} className={`language-lesson-item`}>
                <LanguageIcon height={20} language={lang} showLabel />
              </div>
            </List.Item>
          );
        }}
      />

      <ButtonGroup type={'right'} className="submit-container">
        <Button type={'default'} shape={'round'} onClick={props.onClose}>
          {t('button.close')}
        </Button>
      </ButtonGroup>
    </PageSiteLessonContentStyle>
  );
};

const PageSiteLessonContentStyle = styled.div`
  min-width: 300px;

  .ant-list-item {
    .language-lesson-item {
      filter: grayscale(0.9);
    }

    &.active {
      .language-lesson-item {
        filter: grayscale(0);
      }
    }
  }
`;

const PageSiteLanguagePageStyle = styled.div`
  display: flex;
  flex-wrap: wrap;

  .language-lesson-item {
    filter: grayscale(1);
    cursor: pointer;
    margin: 0.125em 0.5em;

    &.active {
      filter: grayscale(0);
    }
  }
`;
