import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { LessonScoreReport } from '@services/reports/LessonReport';
import { AssignmentInstanceRes } from '@modules/assignments/service/assignment_model';

export enum LessonReportTypeEnum {
  score = 'score',
  history = 'history',
  standard = 'standard',
}

export interface StudentAssignmentStandardResponse extends LessonScoreReport {
  assignmentInstance: AssignmentInstanceRes;
}

export const AssignmentReportAPI = createApi({
  reducerPath: 'AssignmentReportAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAssignmentReports: builder.query<
      StudentAssignmentStandardResponse,
      number
    >({
      query: (assignmentInstanceId) => ({
        url: `/reports/assignment-score/${assignmentInstanceId}`,
      }),
    }),
  }),
});
