import React, { useEffect } from 'react';

import { Button, notification } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { PaymentMethodAPI } from '@modules/payment/services/PaymentMethodAPI';
import { Default_Gutter } from '@components/grid';
import {
  CreatePaymentMethodReq,
  PaymentMethodRes,
} from '@modules/payment/services/model';

const initialValue: CreatePaymentMethodReq = {
  paymentMethodId: -1,
  code: '',
  name: '',
  description: '',
  setting: '',
};

export function CreatePaymentMethodForm(props: {
  paymentMethodId: number | null;
  successHandle: () => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess }] =
    PaymentMethodAPI.endpoints.findById.useLazyQuery();

  const [createPaymentMethod, { isLoading: isSaveLoading }] =
    PaymentMethodAPI.endpoints.create.useMutation({});
  const [updatePaymentMethod, { isLoading: isUpdateLoading }] =
    PaymentMethodAPI.endpoints.update.useMutation({});

  useEffect(() => {
    if (props.paymentMethodId != null && props.paymentMethodId > 0) {
      findById(props.paymentMethodId);
    }
  }, [props.paymentMethodId]);

  useEffect(() => {
    if (data && isSuccess) {
      setFormData(data);
    }
  }, [data]);

  const setFormData = (data: PaymentMethodRes) => {
    formik.setValues({
      paymentMethodId: data.paymentMethodId,
      code: data.code,
      name: data.name,
      description: data.description,
      setting: data.setting,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      code: yup.string().required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (props.paymentMethodId != null && props.paymentMethodId > 0) {
        updatePaymentMethod(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('payment_method.warning.update_success'),
              description: t('payment_method.warning.update_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })
          .catch(() => {
            notification.error({
              message: t('payment_method.warning.update_error'),
              description: t('payment_method.warning.update_error_message'),
              placement: 'bottomRight',
            });
          });
      } else {
        createPaymentMethod(values)
          .unwrap()
          .then(() => {
            notification.success({
              message: t('payment_method.warning.add_success'),
              description: t('payment_method.warning.add_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle();
          })

          .catch(() => {
            notification.error({
              message: t('payment_method.warning.add_error'),
              description: t('payment_method.warning.add_error_message'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="code"
            type="text"
            label={t('label.code')}
            placeholder={t('label.code')}
            onChange={formik.handleChange}
            value={formik.values.code}
          />

          <Input
            required={true}
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <Input
            label={t('label.description')}
            type={'textarea'}
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            maxLength={512}
            style={{ minHeight: 120 }}
          />

          <Input
            label={t('label.setting')}
            type={'textarea'}
            name="setting"
            onChange={formik.handleChange}
            value={formik.values.setting}
            maxLength={512}
            style={{ minHeight: 120 }}
          />

          <div className="submit-container">
            <ButtonGroup>
              <Button
                type={'primary'}
                loading={isSaveLoading || isUpdateLoading}
                onClick={formik.submitForm}
              >
                {props.paymentMethodId != null && props.paymentMethodId > 0 ? (
                  <>{t('button.update')}</>
                ) : (
                  <>{t('button.create')}</>
                )}
              </Button>

              <Button type="default" onClick={props.onCloseFunc}>
                {t('button.cancel')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </FormikProvider>
  );
}
