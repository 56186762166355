import styled from 'styled-components';
import { ImageScale } from '@utils/ImageScale';

export const ProductItemStyle = styled.div`
  position: relative;
  display: flex;
  gap: 1em;

  .product-info {
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    display: flex;
    overflow: hidden;

    width: ${ImageScale.product.icon * 3}px;
    height: ${ImageScale.product.icon * 3}px;
    min-width: ${ImageScale.product.icon * 3}px;
  }

  .product-title {
    cursor: pointer;
    flex-grow: 1;

    .ant-typography {
      color: ${(props) => props.theme.app.primary};
      white-space: normal;
    }

    .ant-progress {
      width: 100%;
    }
  }

  .product-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;
