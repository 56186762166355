import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompMode,
  CompProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import {
  LongOperationLayoutEnum,
  OperationExpProps,
} from '@cms/comps/math/operation/OperationUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import React, { useEffect, useState } from 'react';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import styled from 'styled-components';
import { LongAdditionEditable } from './LongAdditionEditable';
import { LongOperationTable } from '../table/LongOperationTable';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import produce from 'immer';
import { LongOperationTableProvider } from '../table/LongOperationTableContext';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';

export interface LongAdditionSettingProps extends CompInteractSettingProps {
  layout: LongOperationLayoutEnum;
}

export interface LongAdditionCompProps extends CompProps {
  type: CompTypeEnum.LONG_ADDITION;
  configuration: {
    addend: string[];
    expression: OperationExpProps[];
  };
  setting: LongAdditionSettingProps;
}

export interface LongAdditionAnsProps extends CompAnswerProps {
  answer: string[];
}

export const LongAdditionComp = (props: {
  disabled: boolean;
  item: LongAdditionCompProps;
  answer: LongAdditionAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();
  const [showComponent, setShowComponent] = useState(true);

  const [answer, setAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedBack, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (
      props.answer &&
      props.answer.answer &&
      JSON.stringify(props.answer.answer) !== JSON.stringify(answer.value)
    ) {
      setAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  const handleOnAnswerChange = (answerExpression: OperationExpProps[]) => {
    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('');
    });

    setAnswer({
      value: answer,
      triggerChange: true,
    });
  };

  const handleOnEditableChange = (
    addends: string[],
    expression: OperationExpProps[],
    answerExpression: OperationExpProps[]
  ) => {
    const newCompData = produce(props.item, (draft) => {
      draft.configuration.addend = addends;
      draft.configuration.expression = expression;
    });

    const answer = answerExpression.map((ans) => {
      const row = ans.exp.map((input) => {
        return input;
      });
      return row.join('');
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newCompData,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );

    setAnswer({
      value: answer,
      triggerChange: false,
    });

    setShowComponent(true);
  };

  return (
    <CompFeedbackContextProvider feedBack={feedBack}>
      {showComponent ? (
        <LongOperationTableProvider
          expression={props.item.configuration.expression}
          type={
            props.item.setting.layout === LongOperationLayoutEnum.horizontal
              ? 'left-to-right'
              : 'right-to-left'
          }
        >
          <LongAdditionCompStyle
            className={`comp comp-addition ${props.item.setting?.className}`}
          >
            <LongOperationTable
              layout={props.item.setting.layout}
              disabled={props.disabled || mode === CompMode.COMPOSE}
              expression={props.item.configuration.expression}
              answer={answer.value}
              onChange={handleOnAnswerChange}
            />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </LongAdditionCompStyle>
        </LongOperationTableProvider>
      ) : (
        <ComposeModeContent>
          <LongAdditionEditable
            layout={props.item.setting.layout}
            addends={props.item.configuration.addend}
            expression={props.item.configuration.expression}
            onChange={handleOnEditableChange}
          />
        </ComposeModeContent>
      )}
    </CompFeedbackContextProvider>
  );
};

const LongAdditionCompStyle = styled.div`
  position: relative;
`;
