import TablePagination from '@components/table/TablePagination';
import React, { useCallback, useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { DateLabel } from '@components/text/DateLabel';
import { useTranslation } from 'react-i18next';
import Card, { CardClassEnum } from '@components/card';
import { PageSiteAPI, PageSiteRes } from '@services/page-site/PageSiteAPI';
import FilterPageSite from './FilterPageSite';
import { PageSiteActions } from './PageSiteActions';
import { EntityStatus } from '@components/status/EntityStatus';
import { EntityStatusEnum } from '@services/model/common';
import { PageSiteLanguagePages } from './PageSiteLanguagePages';
import { H4 } from '@components/typography';
import { InputGroup } from '@components/input/InputGroup';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { ResponsiveScreen } from '@hooks/useMedia';
import { Default_Gutter } from '@components/grid';

export const ManagePageSiteTable = () => {
  const { t } = useTranslation();

  const [findAll, { data, isFetching }] =
    PageSiteAPI.endpoints.findAll.useLazyQuery({});

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
    searchTerm: '',
  });

  useEffect(() => {
    refreshPage();
  }, [params]);

  const refreshPage = useCallback(() => {
    findAll(params);
  }, [params]);

  const columns = [
    {
      title: t('label.path'),
      dataIndex: 'path',
      key: 'path',
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('label.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: true,
    },
    {
      title: t('label.language'),
      dataIndex: 'language',
      key: 'language',
      align: 'center',
    },
    {
      title: t('label.created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      sorter: false,
      align: 'center',
    },
  ];

  const processDataRow = (item: PageSiteRes) => {
    return {
      key: item.pageSiteId,
      path: <PagePathStyle>{item.path}</PagePathStyle>,
      name: item.name,
      description: item.description,
      status: (
        <div className={'page-site-status'}>
          <EntityStatus status={item.status} />

          {item.status === EntityStatusEnum.PUBLISHED && (
            <div style={{ marginTop: '0.5em' }}>
              <DateLabel label={item.publishedDate} />
            </div>
          )}
        </div>
      ),
      language: <PageSiteLanguagePages item={item} />,
      createdDate: <DateLabel label={item.createdDate} />,
      actions: <PageSiteActions item={item} onChange={refreshPage} />,
    };
  };

  const renderMobile = (item: PageSiteRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <PageItemStyle className={'page-item'}>
          <div className={'page-info'}>
            <H4>{item.name}</H4>

            <InputGroup label={t('label.description')}>
              {item.description}
            </InputGroup>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col span={12}>
                <InputGroup label={t('label.path')}>
                  <PagePathStyle>{item.path}</PagePathStyle>
                </InputGroup>

                <InputGroup label={t('label.language')}>
                  <PageSiteLanguagePages item={item} />
                </InputGroup>
              </Col>

              <Col span={12}>
                <InputGroup label={t('label.status')}>
                  <div className={'page-site-status'}>
                    <EntityStatus status={item.status} />

                    {item.status === EntityStatusEnum.PUBLISHED && (
                      <div style={{ marginTop: '0.5em' }}>
                        <DateLabel label={item.publishedDate} />
                      </div>
                    )}
                  </div>
                </InputGroup>
              </Col>
            </Row>
          </div>

          <div className={'page-actions'}>
            <PageSiteActions item={item} onChange={refreshPage} />
          </div>
        </PageItemStyle>
      </Card>
    );
  };

  const handleOnSubmit = (keyword: string) => {
    setParams((prev) => {
      return { ...prev, searchTerm: keyword };
    });
  };

  const handleOnRefresh = (params: PaginationReq) => {
    setParams((prev) => {
      return { ...params, searchTerm: prev.searchTerm };
    });
  };

  return (
    <>
      <Card
        className={CardClassEnum.rectangle_box}
        style={{ marginBottom: '1em' }}
      >
        <FilterPageSite keyword={params.searchTerm} onSubmit={handleOnSubmit} />
      </Card>

      <TablePagination
        params={params}
        isLoading={isFetching}
        columns={columns}
        data={data}
        refresh={handleOnRefresh}
        processDataRow={processDataRow}
        responsive={{
          screen: [
            ResponsiveScreen.xs,
            ResponsiveScreen.sm,
            ResponsiveScreen.md,
          ],
          render: renderMobile,
        }}
      />
    </>
  );
};

const PageItemStyle = styled.div`
  position: relative;

  .form-group {
    margin-top: 1em;
  }

  .page-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const PagePathStyle = styled.span`
  word-break: break-word;
  max-width: 15em;
  display: inline-block;
`;
