import styled from 'styled-components';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import {
  ClassRoomInteractAction,
  ClassRoomWorkspaceEnum,
} from '@classroom/context/actions';
import SwitchLanguage from '@components/header/SwitchLanguage';
import Button from '@components/button';
import { QuestionOutlined } from '@ant-design/icons';
import { ClassRoomHost } from '@classroom/context/ClassRoomMode';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { CurrentPresentUser } from '@classroom/components/CurrentPresentUser';

const workspaceArr = [
  ClassRoomWorkspaceEnum.class_room,
  ClassRoomWorkspaceEnum.presentation,
  ClassRoomWorkspaceEnum.exercise,
];

export const ClassRoomHeader = () => {
  const { workspace, onWorkspaceChange } = useClassRoomContext();
  const { onActionChange, onAllowPresent } = useClassRoomInteractContext();

  const onHelp = () => {
    onActionChange(ClassRoomInteractAction.HELP, '');
  };

  const onStopPresent = (uuid: string) => {
    onAllowPresent(false, uuid);
  };

  return (
    <ClassRoomHeaderStyle className={'class-room-header'}>
      <div className={'left-actions'}>
        <SwitchLanguage placement={'topLeft'} />

        <Button
          type={'default'}
          shape={'circle'}
          icon={<QuestionOutlined />}
          onClick={onHelp}
        />
      </div>

      <div className={'center-actions'}>
        <ClassRoomHost>
          {workspaceArr.map((wk) => {
            const className = `class-room-tab ${
              workspace === wk ? 'active' : ''
            }`;
            return (
              <span
                key={wk}
                className={className}
                onClick={() => onWorkspaceChange(wk)}
              >
                <ClassRoomHeaderDisplay name={wk} />
              </span>
            );
          })}
        </ClassRoomHost>
      </div>

      <div className={'right-actions'}>
        <CurrentPresentUser onStopPresent={onStopPresent} />
      </div>
    </ClassRoomHeaderStyle>
  );
};

const ClassRoomHeaderDisplay = (props: { name: ClassRoomWorkspaceEnum }) => {
  switch (props.name) {
    case ClassRoomWorkspaceEnum.exercise:
      return <>Exercise</>;

    case ClassRoomWorkspaceEnum.class_room:
      return <>Class Room</>;

    case ClassRoomWorkspaceEnum.presentation:
      return <>Presentation</>;

    default:
      return null;
  }
};

const ClassRoomHeaderStyle = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;

  .center-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .class-room-tab {
    padding: 0.5em 1em;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-bottom: 3px solid transparent;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

    &.active {
      color: ${(props) => props.theme.app.primary};
      border-bottom: 3px solid ${(props) => props.theme.app.primary};
    }
  }
`;
