import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';

import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import ComposeLessonPage from '@modules/product/components/lesson/pages/ComposeLessonPage';
import { PageHeader } from '@app/header/PageHeader';

const CreateResource = () => {
  const { t } = useTranslation();

  return (
    <ComposeLessonLayout
      className={'compose-lesson-layout compose-resource-simple'}
    >
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_RESOURCE}
        title={t('resource.header')}
        description={t('resource.header')}
      />

      <ComposeLessonPage
        from={'resource'}
        lessonId={-1}
        resourceId={-1}
        productId={-1}
        productTocId={-1}
        editMode={EditModeEnum.SINGLE_QUESTION}
      />
    </ComposeLessonLayout>
  );
};

export default CreateResource;
