import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import Checkbox from '@components/input/Checkbox';
import { DragNDropInlineSettingProps } from '@cms/comps/interact/drag-n-drop/inline/DragNDropInlineComp';
import Radio from '@components/input/Radio';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';

export const DragNDropInlineSetting = (props: {
  setting: DragNDropInlineSettingProps;
  onSettingChange: (newSetting: DragNDropInlineSettingProps) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.setting,
    onSubmit: () => {},
  });

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />

        <Checkbox
          name={'multipleDrag'}
          checked={formik.values.multipleDrag}
          value={formik.values.multipleDrag}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.drag_n_drop.multiple_drag')}
        </Checkbox>

        <Checkbox
          name={'multipleDrop'}
          checked={formik.values.multipleDrop}
          value={formik.values.multipleDrop}
          formik={formik}
          onChange={formik.handleChange}
        >
          {t('component.drag_n_drop.multiple_drop')}
        </Checkbox>

        <Radio
          label={t('component.drag_n_drop.event_type')}
          name={'eventType'}
          value={formik.values.eventType}
          onChange={formik.handleChange}
          options={[
            { value: 'drag', label: t('component.drag_n_drop.drag') },
            {
              value: 'click',
              label: t('component.drag_n_drop.click'),
              disabled: true,
            },
          ]}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
