import { ComponentValidationProps } from '@cms/utils/ResourceUtils';
import {
  MatchingCompAnsProps,
  MatchingCompProps,
} from '@cms/comps/interact/matching/MatchingComp';

export const MatchingValidation = {
  validateComponentAndAnswer: (
    component: MatchingCompProps,
    answer: MatchingCompAnsProps
  ): ComponentValidationProps => {
    let needUpdateAns = false;
    const currentAns = { ...answer };

    const dragItems: string[] = component.configuration.sourceItems.map(
      (sr) => {
        return sr.label;
      }
    );

    const dropItems: string[] = component.configuration.targetItems.map(
      (sr) => {
        return sr.label;
      }
    );

    const newAnswer: string[] = [];
    answer.answer.forEach((ans) => {
      const answerPart = ans.split(':');
      if (
        dragItems.indexOf(answerPart[0]) < 0 ||
        dropItems.indexOf(answerPart[1]) < 0
      ) {
        needUpdateAns = true;
      } else {
        newAnswer.push(ans);
      }
    });

    currentAns.answer = newAnswer;

    return {
      updateComponent: false,
      updateAnswer: needUpdateAns,
      newComponent: component,
      newAnswer: currentAns,
    };
  },
};
