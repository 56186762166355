import { H2 } from '@components/typography';
import React, { useState } from 'react';
import { Segmented } from 'antd';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import UncompletedAssignmentStudent from '@modules/dashboard/components/UncompleteAssignmentStudent';
import CompleteAssignmentStudent from '@modules/dashboard/components/CompleteAssignmentStudent';
import { InComingEventStyle } from './IncomingEvents';
import Card, { CardClassEnum } from '@components/card';

export const MyStudentAssignment = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState('incomplete');

  return (
    <InComingEventStyle className={'my-student-assignment'}>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>{t('assignment.title')}</H2>

        <Segmented
          block
          value={value}
          onChange={(val) => setValue(val as 'incomplete' | 'completed')}
          options={[
            {
              value: 'incomplete',
              label: 'Incomplete',
              title: 'Incomplete',
            },
            {
              value: 'completed',
              label: 'Completed',
              title: 'Completed',
            },
          ]}
          style={{ width: 250 }}
        />
      </HeaderGroup>

      {value === 'incomplete' ? (
        <Card hoverable className={CardClassEnum.rectangle}>
          <UncompletedAssignmentStudent />
        </Card>
      ) : (
        <Card hoverable className={CardClassEnum.rectangle}>
          <CompleteAssignmentStudent />
        </Card>
      )}
    </InComingEventStyle>
  );
};
