import React, { useEffect } from 'react';

import { Drawer, Layout } from 'antd';
import styled from 'styled-components';
import { showHideMenu } from '@app/redux/slices/appLayoutSlice';
import useMediaQuery from '@hooks/useMedia';
import NavLink from '@components/button/NavLink';
import { useTranslation } from 'react-i18next';
import NavigationMenu from './NavigationMenu';
import { useDispatch, useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';
const { Sider } = Layout;

const LogoGroup = (props: { type: 'full' | 'auto' }) => {
  const { t } = useTranslation();
  const { collapsed } = useSelector((state) => state.appLayout);

  return (
    <LogoGroupStyle className="logo-group">
      <NavLink href={SiteMap.index}>
        {collapsed && props.type === 'auto'
          ? t('app.short_name')
          : t('app.name')}
      </NavLink>
    </LogoGroupStyle>
  );
};

export const SiderNavigationApp = () => {
  const { collapsed } = useSelector((state) => state.appLayout);

  return (
    <NavigationToolbarStyle
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={collapsed ? 80 : 270}
      style={{
        minWidth: collapsed ? 80 : 270,
      }}
      breakpoint="sm"
      className={'hide-scrollbar'}
    >
      <LogoGroup type={'auto'} />
      <NavigationMenu />
    </NavigationToolbarStyle>
  );
};

export const SiderNavigationMobileApp = () => {
  const dispatch = useDispatch();
  const screen = useMediaQuery();

  const { collapsed } = useSelector((state) => state.appLayout);

  useEffect(() => {
    dispatch(showHideMenu(false));
  }, [screen]);

  const closeMenu = () => {
    dispatch(showHideMenu(false));
  };

  return (
    <Drawer
      title={<LogoGroup type={'full'} />}
      placement={'left'}
      closable={true}
      onClose={closeMenu}
      open={collapsed}
    >
      <NavigationMenu onClick={closeMenu} />
    </Drawer>
  );
};

const NavigationToolbarStyle = styled(Sider)`
  &.ant-layout-sider.ant-layout-sider-dark {
    box-shadow: 0 12px 30px rgb(80 143 244 / 10%);
    transition: 0.3s;
    padding: 16px 12px;

    background: ${(props) => props.theme.menu.bgr};
    border-right: 1px solid ${(props) => props.theme.menu.border};

    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

    .logo-group {
      a {
        color: ${(props) => props.theme.menu.text};
      }
    }

    .ant-layout-sider-children {
      height: auto;
      padding-bottom: 12px;
    }
  }

  .screen-xs & {
    &.ant-layout-sider.ant-layout-sider-dark {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .screen-sm & {
    &.ant-layout-sider.ant-layout-sider-dark {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .screen-md & {
    &.ant-layout-sider.ant-layout-sider-dark {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

const LogoGroupStyle = styled.div`
  font-size: 32px;
  heigth: 40px;
  line-height: 40px;
  font-weight: bold;
  color: ${(props) => props.theme.menu.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    color: inherit;
  }

  .toggle-menu-button {
    position: absolute;
    right: 0px;
  }
`;
