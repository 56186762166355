import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import LessonTemplateComposePage from '@modules/product/pages/LessonTemplateComposePage';
import { useRouter } from '@hooks/useRouter';
import { PageHeader } from '@app/header/PageHeader';

const LessonTemplateCompose = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { id } = router.query as unknown as { id: number };

  return (
    <ManagementLayout className={'compose-lesson-template-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON_TEMPLATE}
        title={t('lesson_template.header')}
        description={t('lesson_template.header')}
      />

      <LessonTemplateComposePage lessonTemplateId={Number(id)} />
    </ManagementLayout>
  );
};

export default LessonTemplateCompose;
