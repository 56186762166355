import React from 'react';
import { CompConfiguration } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { CompFeedbackResultIcon } from '@cms/feedback/CompFeedback';
import { useFibNumberCompContext } from '@cms/comps/math/fib/FibNumberContext';
import { InputNumberComp } from './InputNumberComp';

const FibNumberGroupItems = () => {
  const {
    modifiedTime,
    disabled,
    targetItems,
    answers,
    setting,
    handleOnChange,
  } = useFibNumberCompContext();

  const getInlineAnswer = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans.split(':')[1];
      }
    });

    return inlineAns;
  };

  const getAnswerDisplay = (sourceId: string): string => {
    let inlineAns = '';
    answers.forEach((ans) => {
      if (ans.split(':')[0] === sourceId) {
        inlineAns = ans;
      }
    });

    return inlineAns;
  };

  return (
    <FibNumberGroupStyle className={`fib-number-group`}>
      {targetItems &&
        targetItems.map((drop, index) => {
          const textArray = drop.content.data.split(
            CompConfiguration.INLINE_COMPONENT
          );

          return (
            <div
              className={`fib-number-group-item group-${index}`}
              key={drop.label + '_' + drop.content.id + '_' + modifiedTime}
            >
              {textArray.map((text, idx) => {
                const sourceData = drop.label + '|' + idx;

                return (
                  <React.Fragment key={text + '_' + idx}>
                    {idx > 0 && (
                      <div
                        className={`component-and-feedback fib-number-part part-${idx}`}
                      >
                        <CompFeedbackResultIcon
                          answer={getAnswerDisplay(sourceData)}
                        />

                        <InputNumberComp
                          disabled={disabled}
                          value={getInlineAnswer(sourceData)}
                          onChange={(val) =>
                            handleOnChange(sourceData + ':' + val)
                          }
                          digit={setting.digit}
                        />
                      </div>
                    )}

                    {text.trim() !== '' && (
                      <>
                        {text
                          .split(CompConfiguration.SEPARATE_CONTENT)
                          .map((content, index) => {
                            return (
                              <span
                                className={'fib-number-text'}
                                key={content + '_' + index}
                                dangerouslySetInnerHTML={{
                                  __html: '&nbsp;' + content,
                                }}
                              />
                            );
                          })}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </FibNumberGroupStyle>
  );
};

export default FibNumberGroupItems;

const FibNumberGroupStyle = styled.div`
  .fib-number-group-item {
    &:not(:first-child) {
      margin-top: var(--cms-padding-option, 0.5em);
    }
  }

  .fib-number-group-item {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .fib-number-text {
    display: inline;
  }

  .component-and-feedback {
    position: relative;
    padding: 0 0.25em;
    display: inline-flex;
    align-items: baseline;

    .feedback-icon {
      position: relative;
    }
  }
`;
