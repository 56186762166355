import { useEffect, useState } from 'react';
import i18next from 'i18next';

export const useLanguage = () => {
  const [language, setLanguage] = useState(() => {
    return i18next.language;
  });

  useEffect(() => {
    setLanguage(i18next.language);
  }, [i18next.language]);

  return language;
};
