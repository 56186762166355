import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import OauthAppManagementPage from '@modules/lti/pages/OathAppManagementPage';
import { PageHeader } from '@app/header/PageHeader';

const OauthApp = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-oauth-app-page'}>
      <PageHeader
        menu={NavigationMenuEnum.EMPTY}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('oauth_app.header')}
        description={t('oauth_app.header')}
      />
      <OauthAppManagementPage />
    </ManagementLayout>
  );
};

export default OauthApp;
