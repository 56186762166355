import { useState } from 'react';


export interface UseModalProps{
    toggle: () => void,
    visible: boolean,
    show: () => void,
    hide: () => void;
}

const useModal = (_visible?: boolean) => {
    const [visible, setVisible] = useState(() => {
        return _visible ? true : false;
    });

    const toggle = () => {
        setVisible((prev) => !prev);
    };

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };

    return { toggle, visible, show, hide };
};

export default useModal;
