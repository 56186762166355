export enum ClassRoomInteractAction {
  DEFAULT = '',

  JOIN = 'join', // user access the session
  QUIT = 'quit', // user quit the session

  VIEW_PARTICIPANTS = 'view-participants', // teacher view participants and their absents
  SHOW_INFO = 'show-info', // user click to view the session info.

  ALLOW_PRESENT = 'allow-present',
  STOP_PRESENT = 'stop-allow-present',

  ASSIGN = 'assign',

  HELP = 'help',
}

export enum ClassRoomAction {
  DEFAULT = '',

  REFRESH = 'refresh', // reload all data....

  PRESENT_SECTION = 'present-section', // teacher click to the section to review before present.
  PRESENT_EXERCISE = 'present-exercise', // view student exercises.....
  ASSIGN_EXERCISE = 'assign-exercise',
}

export interface ClassRoomActionProps {
  uuid: string;
  action: any;
  data: any;
  timestamp: number;
}

export enum ClassRoomWorkspaceEnum {
  class_room = 'class-room',
  presentation = 'presentation',
  exercise = 'exercise',
}

export enum ClassRoomToUserType {
  all_students = 'all-student',
  teacher = 'teacher',
  empty = '',
}

export enum ClassRoomMessageType {
  empty = '',
  assign_task = 'assign-task',
  end_task = 'end-task',
}

export interface UserTaskStatus {
  taskId: number;
  taskInstanceId: number;
  exerciseId: number;
  submitted: boolean;
}
