import React from 'react';
import { CompConfiguration } from '@cms/ComponentInteface';
import styled from 'styled-components';
import { useCheckBoxCompContext } from '@cms/comps/interact/checkbox/CheckBoxCompContext';
import CheckBoxOptions from './CheckBoxOptions';
import DOMPurify from 'dompurify';

const CheckBoxGroupItems = () => {
  const { modifiedTime, targetItems, answers } = useCheckBoxCompContext();

  return (
    <CheckBoxGroupItemStyle
      className={'checkbox-inline-group'}
      key={modifiedTime}
    >
      {targetItems &&
        targetItems.map((drop, groupIndex) => {
          const textArray = drop.content.data.split(
            CompConfiguration.INLINE_COMPONENT
          );
          return (
            <div
              className={`checkbox-inline-group-item group-${groupIndex}`}
              key={drop.label + '_' + drop.content.id}
            >
              {textArray.map((text, partIndex) => {
                return (
                  <React.Fragment
                    key={
                      groupIndex +
                      '_' +
                      partIndex +
                      '_' +
                      text +
                      answers.join('-')
                    }
                  >
                    {partIndex > 0 && (
                      <CheckBoxOptions
                        groupIndex={groupIndex}
                        partIndex={partIndex}
                      />
                    )}

                    {text.trim() !== '' && (
                      <div
                        className={'checkbox-inline-text'}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(text) + '&nbsp;',
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
    </CheckBoxGroupItemStyle>
  );
};

export default CheckBoxGroupItems;

const CheckBoxGroupItemStyle = styled.div`
  .checkbox-inline-group-item {
    display: flex;
    align-items: start;
    flex-wrap: wrap;

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  .comps-checkbox-panel {
    display: inline-block;
  }
`;
