import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import PublishCoursePage from '@modules/course/pages/PublishCoursePage';

const PublishCourse = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { id } = router.query;

  return (
    <ManagementLayout className={'publish-course-page'}>
      <PageHeader
        menu={NavigationMenuEnum.COURSES}
        subMenu={NavigationMenuEnum.COURSES_COURSES}
        title={t('publish.course.header')}
        description={t('publish.course.header')}
      />

      <PublishCoursePage courseId={Number(id)} />
    </ManagementLayout>
  );
};

export default PublishCourse;
