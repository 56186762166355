import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import React from 'react';
import { UserCertificateTable } from '@modules/setting/container/my-profile/UserCertificateTable';

export const MyCertificatePage = () => {
  return (
    <>
      <HeaderGroup className="header-group">
        <H1>My Certificate</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={20}>
          <UserCertificateTable />
        </Col>
      </Row>
    </>
  );
};
