import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  AssignmentInstanceRes,
  AssignmentRes,
  CreateAssignmentReq,
  UpdateAssignmentInstanceReq,
} from '@modules/assignments/service/assignment_model';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';

export const AssignmentAPI = createApi({
  reducerPath: 'AssignmentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    filter: builder.query<PaginationRes<AssignmentRes>, PaginationReq>({
      query: (params) => ({
        url: `/assignments`,
        params,
      }),
    }),

    findById: builder.query<AssignmentRes, number>({
      query: (assignmentId) => ({
        url: `/assignments/${assignmentId}`,
      }),
    }),

    findAssignmentInstance: builder.query<AssignmentInstanceRes, number>({
      query: (assignmentInstanceId) => ({
        url: `/assignments/instances/${assignmentInstanceId}`,
      }),
    }),

    viewAssignmentInstance: builder.query<ExerciseRes, number>({
      query: (assignmentInstanceId) => ({
        url: `/assignments/instances/${assignmentInstanceId}/view`,
      }),
    }),

    getAssignmentInstanceStatus: builder.query<
      PaginationRes<AssignmentRes>,
      { assignmentInstanceId: number; params: PaginationReq }
    >({
      query: (params) => ({
        url: `/assignments/instances/${params.assignmentInstanceId}/status`,
        params: params.params,
      }),
    }),

    createAssignment: builder.mutation<AssignmentRes, CreateAssignmentReq>({
      query: (body) => ({
        url: '/assignments',
        method: 'POST',
        body,
      }),
    }),

    updateAssignment: builder.mutation<
      BasicMessageRes,
      UpdateAssignmentInstanceReq
    >({
      query: (body) => ({
        url: `/assignments/${body.assignmentInstanceId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteAssignment: builder.mutation<BasicMessageRes, number>({
      query: (assignmentInstanceId) => ({
        url: `/assignments/${assignmentInstanceId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
