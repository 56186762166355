import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import Select from '@components/select/Select';
import { DefaultOptionType } from 'antd/lib/select';
import { LessonTemplateAPI } from '@modules/product/services/LessonTemplateAPI';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';

const SelectLessonTemplate = (props: {
  formik: any;
  name: string;
  value: number;
  onChange: (lessonTemplateId: number) => void;
}) => {
  const { t } = useTranslation();

  const [params] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'ascend',
    selectAll: true,
  });

  const [findAll, { data, isSuccess }] =
    LessonTemplateAPI.endpoints.findAll.useLazyQuery();

  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>([
    { key: -1, value: -1, label: t('lesson.actions.select_template') },
  ]);

  useEffect(() => {
    findAll(params);
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      const options = data.content.map((lt) => {
        return {
          key: lt.lessonTemplateId,
          value: lt.lessonTemplateId,
          label: (
            <span>
              <b>{lt.name}</b>
              <br /> {lt.description}
            </span>
          ),
        };
      });

      setSelectOptions([
        { key: -1, value: -1, label: t('lesson.actions.select_template') },
        ...options,
      ]);
    }
  }, [data]);

  return (
    <InputGroup label={t('lesson.form.lesson_template')}>
      <Select
        size={'large'}
        placeholder={t('lesson_template.title')}
        onChange={(value) => props.onChange(value)}
        name={props.name}
        value={props.value}
        options={selectOptions}
      />
    </InputGroup>
  );
};

export default SelectLessonTemplate;
