import { LanguageEnum, LanguageIcon } from '@components/language/LanguageIcon';
import React, { useEffect, useState } from 'react';
import { LanguageAPI, LanguageRes } from '@services/setting/LanguageAPI';
import styled from 'styled-components';
import { Checkbox, List, Space } from 'antd';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

export const SchoolLanguageSelect = (props: {
  languages?: LanguageRes[];
  defaultLanguage?: LanguageRes;
  onChange: (languages: LanguageEnum[], defaultLanguage: LanguageEnum) => void;
}) => {
  const { t } = useTranslation();

  const [findActivated, { data, isSuccess }] =
    LanguageAPI.endpoints.findActivated.useLazyQuery({});

  const [languages, setLanguages] = useState<LanguageRes[]>([]);
  const [selectLanguages, setSelectLanguages] = useState<LanguageEnum[]>([
    LanguageEnum.English,
  ]);
  const [defaultLanguage, setDefaultLanguage] = useState(LanguageEnum.English);

  useEffect(() => {
    if (props.languages) {
      setSelectLanguages(
        props.languages.map((lang) => {
          return lang.code;
        })
      );
    }

    if (props.defaultLanguage) {
      setDefaultLanguage(props.defaultLanguage.code);
    }
  }, [props.languages, props.defaultLanguage]);

  useEffect(() => {
    findActivated({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      setLanguages(data);
    }
  }, [data]);

  useEffect(() => {
    props.onChange(selectLanguages, defaultLanguage);
  }, [selectLanguages, defaultLanguage]);

  const handleDefaultLanguageChange = (value: LanguageEnum) => {
    setDefaultLanguage(value);

    setSelectLanguages((prev) => {
      if (prev.includes(value)) {
        return [...prev];
      } else {
        return [...prev, value];
      }
    });
  };

  const handleLanguageChange = (lang: LanguageEnum, type: 'remove' | 'add') => {
    if (type === 'add') {
      setSelectLanguages((prev) => {
        return [...prev, lang];
      });
    } else {
      setSelectLanguages((prev) => {
        return [...prev].filter((lg) => {
          return lg !== lang;
        });
      });
    }
  };

  return (
    <SchoolLanguageSelectStyle>
      <List
        itemLayout="horizontal"
        dataSource={languages}
        renderItem={(lang) => {
          const activated = selectLanguages.includes(lang.code);
          const isDefaultLanguage = lang.code === defaultLanguage;

          return (
            <List.Item
              className={activated ? 'activated' : ''}
              actions={
                !isDefaultLanguage
                  ? [
                      <Button
                        size={'small'}
                        type={'default'}
                        shape={'round'}
                        key="select"
                        onClick={() => handleDefaultLanguageChange(lang.code)}
                      >
                        {t('school_language.actions.set_as_default_language')}
                      </Button>,
                    ]
                  : [
                      <Button
                        size={'small'}
                        type={'primary'}
                        shape={'round'}
                        key="un-select"
                      >
                        {t('school_language.actions.default_language')}
                      </Button>,
                    ]
              }
            >
              <div className={'select-language-item'}>
                <Checkbox
                  checked={activated}
                  disabled={isDefaultLanguage}
                  onChange={(e) =>
                    handleLanguageChange(
                      lang.code,
                      e.target.checked ? 'add' : 'remove'
                    )
                  }
                >
                  &nbsp;
                </Checkbox>

                <Space>
                  <div className={'select-language-item'}>
                    <LanguageIcon language={lang.code} height={20} showLabel />
                  </div>
                </Space>
              </div>
            </List.Item>
          );
        }}
      />
    </SchoolLanguageSelectStyle>
  );
};

const SchoolLanguageSelectStyle = styled.div`
  .ant-list-items {
    .ant-list-item {
      filter: grayscale(1);

      &.activated {
        filter: grayscale(0);
      }
    }

    .select-language-item {
      display: flex;
      align-items: center;

      .language-item-label-display {
        margin-left: 1em;
      }
    }
  }
`;
