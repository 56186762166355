import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateCurrencyReq,
  CurrencyRes,
} from '@modules/payment/services/model';

export const CurrencyAPI = createApi({
  reducerPath: 'CurrencyAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<CurrencyRes>, unknown>({
      query: () => ({
        url: `/currency`,
      }),
    }),

    findById: builder.query<CurrencyRes, unknown>({
      query: (id) => ({
        url: `/currency/${id}`,
        method: 'GET',
      }),
    }),

    create: builder.mutation<CurrencyRes, CreateCurrencyReq>({
      query: (body) => ({
        url: '/currency',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<CurrencyRes, CreateCurrencyReq>({
      query: (body) => ({
        url: '/currency',
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (currencyId) => ({
        url: `/currency/${currencyId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
