import React from 'react';
import { StudentAssignmentTable } from '@modules/assignments/container/StudentAssignmentTable';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';

export default function MyStudentAssignmentPage() {
  const { t } = useTranslation();

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('assignment.list')}</H1>
      </HeaderGroup>

      <StudentAssignmentTable />
    </>
  );
}
