import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import PublishProductGroup from '@modules/product/container/product/PublishProductGroup';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';

const Licenses = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-license-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LICENSE}
        subMenu={NavigationMenuEnum.LICENSE_BOOKS}
        title={t('license.book.title')}
        description={t('license.book.description')}
      />
      <PublishProductGroup />
    </ManagementLayout>
  );
};

export default Licenses;
