import React from 'react';
import { ViewLessonContentProvider } from '@cms/lesson-template/context/ViewLessonContext';
import { CompMode } from '@cms/ComponentInteface';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import { PageLayout } from './PageLayout';
import { LessonTemplateLayoutProvider } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { PageServiceProvider } from '@cms/service/PageServiceProvider';
import { LessonRes } from '@modules/product/services/lesson_model';

export const PageContent = (props: { path: string; page: LessonRes }) => {
  const onExitExercise = () => {};

  return (
    <ViewLessonContentProvider
      type={CompMode.DO_EXERCISE}
      lesson={props.page}
      lessonType={props.page.type ?? LessonTypeEnum.page}
      answers={[]}
      feedBacks={[]}
      gradings={[]}
      exitExercise={onExitExercise}
    >
      <PageServiceProvider lessonId={props.page.lessonId}>
        <LessonTemplateLayoutProvider lesson={props.page}>
          <PageLayout page={props.page}>
            <ViewLessonContent />
          </PageLayout>
        </LessonTemplateLayoutProvider>
      </PageServiceProvider>
    </ViewLessonContentProvider>
  );
};
