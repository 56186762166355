import React from 'react';
import { useExerciseView } from '@cms/lesson-template/hook/useExerciseView';
import ViewLessonContent from '@cms/lesson-template/ViewLessonContent';
import { ViewLessonContentProvider } from '@cms/lesson-template/context/ViewLessonContext';
import { CompMode } from '@cms/ComponentInteface';
import { ProductTableOfContentProvider } from '@modules/product/context/ProductTableOfContent';
import { LessonTemplateLayoutProvider } from '../design-template/layout/LessonTemplateLayoutProvider';
import { useRouter } from '@hooks/useRouter';
import { LessonTypeEnum } from '@modules/admin/service/model';
import { ExerciseServiceProvider } from '@cms/service/ExerciseServiceProvider';
import { OnlyExerciseMode } from '@cms/lesson-template/components/mode/ExerciseMode';
import { TableOfContentProgressToolbar } from '@modules/product/components/toc/TableOfContentProgressToolbar';
import { Loading } from '@components/loading/Loading';
import { CustomLessonSettingProps } from '@cms/lesson-template/context/props';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { ViewExerciseProvider } from '@cms/lesson-template/context/ViewExerciseContext';
import ExerciseUtils from '@modules/assignments/utils/ExerciseUtils';
import { useSecurity } from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';

export const ViewExercisePage = (props: {
  type: CompMode;
  exerciseId: number;
  productTocId?: number;
  productId?: number;
  setting?: CustomLessonSettingProps;
  trigger?: {
    onSave?: () => void;
    onSubmit: (exercise: ExerciseRes) => void;
  };
}) => {
  const router = useRouter();
  const { ifAnyGranted } = useSecurity();

  const {
    ready,
    lesson,
    exercise,
    mode,
    answers,
    feedBacks,
    gradings,
    productId,
    productTocId,
  } = useExerciseView(props.exerciseId, props.type, props.setting);

  const onExitExercise = () => {
    ExerciseUtils.onExit(
      exercise,
      router,
      lesson,
      productId,
      ifAnyGranted([RoleEnum.STUDENT, RoleEnum.PARENT])
    );
  };

  return (
    <Loading loading={!ready} className={'loading-exercise-page'}>
      {lesson && exercise && (
        <ProductTableOfContentProvider
          productId={productId}
          productTocId={productTocId}
          lessonId={-1}
        >
          <ViewExerciseProvider
            exercise={exercise}
            exitExercise={onExitExercise}
          >
            <ViewLessonContentProvider
              type={mode}
              exercise={exercise}
              lesson={lesson}
              lessonType={lesson.type ?? LessonTypeEnum.lesson}
              answers={answers}
              feedBacks={feedBacks}
              gradings={gradings}
              exitExercise={onExitExercise}
            >
              <ExerciseServiceProvider
                lessonId={lesson.lessonId}
                exerciseId={props.exerciseId}
                onSubmit={props.trigger?.onSubmit}
              >
                <LessonTemplateLayoutProvider
                  lesson={lesson}
                  config={props.setting?.config}
                >
                  <ViewLessonContent />
                </LessonTemplateLayoutProvider>
              </ExerciseServiceProvider>
            </ViewLessonContentProvider>
          </ViewExerciseProvider>

          <OnlyExerciseMode>
            <TableOfContentProgressToolbar
              type={mode}
              lessonId={lesson.lessonId}
              productTocId={productTocId}
            />
          </OnlyExerciseMode>
        </ProductTableOfContentProvider>
      )}
    </Loading>
  );
};
