import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { SessionAPI } from '@services/private/SessionAPI';

import { AnnotationContextProvider } from '@classroom/container/annotation/AnnnotationContext';
import { ViewLessonDataChange } from '@cms/lesson-template/context/ViewLessonContext';
import { ClassRoomWorkspace } from '@classroom/container/ClassRoomWorkspace';
import {
  ClassRoomAction,
  ClassRoomWorkspaceEnum,
  UserTaskStatus,
} from '@classroom/context/actions';
import { useSelector } from '@app/redux/hook';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { FireBaseService } from '@services/FireStoreService';
import { ClassRoomInteractHandle } from '@classroom/container/ClassRoomInteractHandle';
import { StringUtils } from '@utils/StringUtils';
import { SessionRes, SessionSectionHistoryRes } from '@services/model/session';

export const ClassRoomContextProvider = (props: { session: SessionRes }) => {
  const { uuid } = useSelector((state) => state.userInformation);

  const { recording, onAllowPresent } = useClassRoomInteractContext();

  const stateService = useRef(
    new FireBaseService(uuid, `/sessions/${props.session.code}`, '/state')
  );

  const taskServices = useRef(
    new FireBaseService(uuid, `/sessions/${props.session.code}`, '/tasks')
  );

  // load last state of session
  const [getClassRoomState] =
    SessionAPI.endpoints.getClassRoomState.useLazyQuery();
  // load last state of section
  const [getLatestSectionState] =
    SessionAPI.endpoints.getLatestSectionState.useLazyQuery();

  const [saveSessionActions] =
    SessionAPI.endpoints.saveSessionActions.useMutation({});

  const [historyUuid, setHistoryUid] = useState('');

  const [workspace, setWorkspace] = useState<ClassRoomWorkspaceEnum>(
    props.session.isHost
      ? ClassRoomWorkspaceEnum.class_room
      : ClassRoomWorkspaceEnum.presentation
  );

  const [enableAnnotation, setEnableAnnotation] = useState(false);

  const [selectSectionId, setSelectSectionId] = useState(-1);
  const [selectExerciseId, setSelectExerciseId] = useState(-1);
  const [sessionTaskId, setSessionTaskId] = useState(-1);

  const [questionState, setQuestionState] = useState<ViewLessonDataChange>({
    checkAnswerMode: false,
    showCorrectAnswerMode: false,
    answers: {},
    feedbacks: {},
    gradings: {},
  });

  const [annotationState, setAnnotationState] = useState<any>({});

  const [taskStatus, setTaskStatus] = useState<Record<number, UserTaskStatus>>(
    {}
  );

  const [sectionData, setSectionData] =
    useState<SessionSectionHistoryRes | null>(null);

  const [action, setAction] = useState({
    action: ClassRoomAction.DEFAULT,
    params: -1,
  });

  useEffect(() => {
    if (recording) {
      onChangeClassRoomState(
        enableAnnotation,
        selectSectionId,
        selectExerciseId,
        sessionTaskId,
        questionState,
        annotationState,
        () => {
          console.log('...............');
        }
      );
    }
  }, [
    recording,
    enableAnnotation,

    selectSectionId,
    selectExerciseId,
    sessionTaskId,

    StringUtils.stringify(questionState, '[]'),
    StringUtils.stringify(annotationState, '[]'),
  ]);

  useEffect(() => {
    stateService.current.onSelectAll(_onHistoryChange);
    taskServices.current.onSelectAll(_onTaskStatusChange);
  }, [props.session.sessionId]);

  useEffect(() => {
    if (
      action.action === ClassRoomAction.PRESENT_SECTION &&
      action.params > 0
    ) {
      // stop present before data is ready...
      onAllowPresent(false, uuid);

      loadSectionData(action.params, () => {
        // then after every ok, resume present...
        setWorkspace(ClassRoomWorkspaceEnum.presentation);
        setTimeout(() => {
          onAllowPresent(true, uuid);
        }, 500);
      });
    } else if (
      action.action === ClassRoomAction.PRESENT_EXERCISE &&
      action.params > 0
    ) {
      onAllowPresent(false, uuid);
      clearAllState(-1, action.params, -1);

      onChangeClassRoomState(
        false,
        -1,
        action.params,
        -1,
        {
          checkAnswerMode: false,
          showCorrectAnswerMode: false,
          answers: {},
          feedbacks: {},
          gradings: {},
        },
        {},
        () => {
          setWorkspace(ClassRoomWorkspaceEnum.presentation);
        }
      );
    } else if (
      action.action === ClassRoomAction.ASSIGN_EXERCISE &&
      action.params > 0
    ) {
      onAllowPresent(false, uuid);
      clearAllState(-1, -1, action.params);

      onChangeClassRoomState(
        false,
        -1,
        -1,
        action.params,
        {
          checkAnswerMode: false,
          showCorrectAnswerMode: false,
          answers: {},
          feedbacks: {},
          gradings: {},
        },
        {},
        () => {
          setWorkspace(ClassRoomWorkspaceEnum.exercise);
        }
      );
    }
  }, [action]);

  const clearAllState = (
    _sectionId: number,
    _exerciseId: number,
    _sessionTaskId: number
  ) => {
    setEnableAnnotation(false);

    setSelectSectionId(_sectionId);
    setSelectExerciseId(_exerciseId);
    setSessionTaskId(_sessionTaskId);

    setQuestionState({
      checkAnswerMode: false,
      showCorrectAnswerMode: false,
      answers: {},
      feedbacks: {},
      gradings: {},
    });
    setAnnotationState({});
    setSectionData(null);
  };

  const loadSectionData = (sectionId: number, callBack: () => void) => {
    getLatestSectionState({
      sessionId: props.session.sessionId,
      sectionId: sectionId,
    })
      .unwrap()
      .then((data) => {
        if (data.uuid !== historyUuid) {
          restoreSectionData(data);
          setHistoryUid(data.uuid);
        }
        callBack();
      })
      .catch(() => {
        clearAllState(sectionId, -1, -1);
        callBack();
      });
  };

  const restoreSectionData = (data: SessionSectionHistoryRes) => {
    setEnableAnnotation(data.enableAnnotation);
    setSelectSectionId(data.lessonSectionId);
    setSelectExerciseId(data.exerciseId);
    setSessionTaskId(data.sessionTaskId);

    setAnnotationState(JSON.parse(data.annotation));
    setQuestionState(JSON.parse(data.question));

    setSectionData(data);
  };

  const dispatchAction = (action: ClassRoomAction, params: number) => {
    setAction({
      action: action,
      params: params,
    });
  };

  const onHistoryChange = (uid: string) => {
    setHistoryUid(uid);
  };

  const onEnableAnnotation = (isEnable: boolean) => {
    setEnableAnnotation(isEnable);
  };

  const onWorkspaceChange = (workspace: ClassRoomWorkspaceEnum) => {
    setWorkspace(workspace);
  };

  const onQuestionChange = (state: ViewLessonDataChange) => {
    setQuestionState(state);
  };

  const onAnnotationChange = (state: any) => {
    setAnnotationState(state);
  };

  const _onHistoryChange = (data: any[]) => {
    const lastMessage = data.length > 0 ? data[data.length - 1] : null;

    if (lastMessage != null && lastMessage.current_uid !== historyUuid) {
      restoreHistoryData(lastMessage.current_uid);
    }
  };

  const restoreHistoryData = (stateUid: string) => {
    getClassRoomState({
      sessionId: props.session.sessionId,
      historyUid: stateUid,
    })
      .unwrap()
      .then((data) => {
        if (data.uuid !== historyUuid) {
          restoreSectionData(data);
          setHistoryUid(data.uuid);
        }
      })
      .catch(() => {
        clearAllState(-1, -1, -1);
      });
  };

  const onChangeClassRoomState = (
    enableAnnotation: boolean,
    selectSectionId: number,
    selectExerciseId: number,
    sessionTaskId: number,
    questionState: any,
    annotationState: any,
    callBack?: () => void
  ) => {
    const request = {
      sessionId: props.session.sessionId,

      enableAnnotation: enableAnnotation,

      sectionId: selectSectionId,
      exerciseId: selectExerciseId,
      sessionTaskId: sessionTaskId,

      question: JSON.stringify(questionState),
      annotation: JSON.stringify(annotationState),
    };

    saveSessionActions(request)
      .unwrap()
      .then((res) => {
        setHistoryUid(res.uuid);

        stateService.current
          .create(
            {
              current_uid: res.uuid,
            },
            props.session.code
          )
          .then(() => {
            if (callBack) {
              callBack();
            }
          })
          .catch((err: any) => {});
      })
      .catch(() => {
        alert('Have error when save session activities.');
      });
  };

  const _onTaskStatusChange = (data: UserTaskStatus[]) => {
    const taskStatus: Record<number, UserTaskStatus> = {};
    data.forEach((task) => {
      taskStatus[task.taskInstanceId] = task;
    });

    setTaskStatus(taskStatus);
  };

  const onSubmitTask = (
    taskId: number,
    taskInstanceId: number,
    exerciseId: number
  ) => {
    taskServices.current.create(
      {
        taskId: taskId,
        taskInstanceId: taskInstanceId,
        exerciseId: exerciseId,
        submitted: true,
      },
      taskInstanceId
    );
  };

  return (
    <ClassRoomContext.Provider
      value={{
        session: props.session,
        historyUuid,
        sectionData: sectionData, // section data is presenting...

        // class room setting
        workspace,
        enableAnnotation,

        sectionId: selectSectionId, // section is presenting
        exerciseId: selectExerciseId, // exercise id is presenting
        sessionTaskId: sessionTaskId,

        // class room data....
        questionState: questionState, // question data
        annotationState: annotationState, // annotation data.

        // current each state...
        action: action,
        taskStatus,
        dispatchAction,

        onHistoryChange,
        onEnableAnnotation,
        onWorkspaceChange,

        onQuestionChange,
        onAnnotationChange,

        onSubmitTask,
      }}
    >
      <AnnotationContextProvider>
        <ClassRoomWorkspace />
        <ClassRoomInteractHandle />
      </AnnotationContextProvider>
    </ClassRoomContext.Provider>
  );
};

const ClassRoomContext = createContext({
  // metadata ....
  session: {} as SessionRes,
  historyUuid: '',

  workspace: ClassRoomWorkspaceEnum.class_room as ClassRoomWorkspaceEnum,

  enableAnnotation: false as boolean, // togger annotation or not
  sectionId: -1 as number, // current section
  exerciseId: -1 as number, // current exercise
  sessionTaskId: -1 as number,

  questionState: {} as any, // current answer
  annotationState: {} as any, // current annotation

  sectionData: null as SessionSectionHistoryRes | null,

  taskStatus: {} as Record<number, UserTaskStatus>,

  action: { action: ClassRoomAction.DEFAULT, params: -1 }, // params maybe section or exercise or anything
  dispatchAction: (action: ClassRoomAction, params: any) => {
    console.log(action, params);
  },

  onHistoryChange: (newUid: string) => {
    console.log(newUid);
  },

  onEnableAnnotation: (enable: boolean) => {
    console.log('.....', enable);
  },

  onWorkspaceChange: (workspace: ClassRoomWorkspaceEnum) => {
    console.log(workspace);
  },

  onQuestionChange: (state: ViewLessonDataChange) => {
    console.log(state);
  },

  onAnnotationChange: (state: any) => {
    console.log(state);
  },

  onSubmitTask: (
    taskId: number,
    taskInstanceId: number,
    exerciseId: number
  ) => {
    console.log(taskInstanceId, exerciseId);
  },
});

export const useClassRoomContext = () => {
  const context = useContext(ClassRoomContext);
  if (!context) {
    throw new Error('You must wrap container by ClassRoomContextProvider');
  }
  return context;
};
