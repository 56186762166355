import React from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import ListResourceBanks from '@cms/resource-bank/ListResourceBank';
import styled from 'styled-components';

export const SelectLessonResource = (props: {
  type: 'add' | 'replace';
  resources: ResourceProps[];
  onSelect: (resource: ResourceProps) => void;
}) => {
  return (
    <SelectLessonResourceStyle className="select-lesson-resource">
      <ListResourceBanks
        type={props.type}
        resources={props.resources}
        selectedResources={[]}
        onSelect={props.onSelect}
      />
    </SelectLessonResourceStyle>
  );
};

const SelectLessonResourceStyle = styled.div`
  .resource-item {
    aspect-ratio: 16 / 9;
  }
`;
