import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { SessionRes } from '@services/model/session';
import { AssignmentInstanceRes } from '@modules/assignments/service/assignment_model';

export enum EventTypeEnum {
  SESSION = 'session',
  ASSIGNMENT = 'assignment',
}

export interface CalendarEventRes extends EventRes {
  // on calendar
  clone?: boolean;
  allDay?: boolean;
  stick?: boolean;
  startTimeOrg?: string;
  endTimeOrg?: string;
}

export interface EventRes {
  eventId: number;
  type: EventTypeEnum;
  name: string;
  startTime: string;
  endTime: string;

  owner: boolean;
  session: SessionRes;
  assignment: AssignmentInstanceRes;
}

export const EventAPI = createApi({
  reducerPath: 'EventAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // for validate data
    syncAllData: builder.mutation<BasicMessageRes, unknown>({
      query: (body) => ({
        url: `/events/sync`,
        method: 'POST',
        body,
      }),
    }),

    findAll: builder.query<
      EventRes[],
      { forUserId?: number; classId?: number }
    >({
      query: (params) => ({
        url: `/events`,
        params,
      }),
    }),

    getIncomingEvents: builder.query<EventRes[], unknown>({
      query: () => ({
        url: `/events/incoming`,
      }),
    }),
  }),
});
