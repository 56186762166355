import React, { useEffect, useState } from 'react';

import { Button, Col, notification, Row } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';

import { InputGroup } from '@components/input/InputGroup';

import { CourseAPI, RegisterCourseReq } from '@services/private/CourseAPI';
import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';

export default function RegisterCourseForm(props: {
  courseId: number;
  schoolId: number;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { isAuth } = useSelector((state) => state.profile);
  const { isReady, firstName, lastName, email, phone } = useSelector(
    (state) => state.userInformation
  );
  const [registerCourse] = CourseAPI.endpoints.registerCourse.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },

    validationSchema: yup.object().shape({
      firstName: yup.string().required(t('validation.required.field')),
      lastName: yup.string().required(t('validation.required.field')),
      email: yup
        .string()
        .email(t('registration.required.email.valid'))
        .required(t('registration.required.email.valid')),
      phone: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      setLoading(true);

      const request: RegisterCourseReq = {
        courseId: props.courseId,
        schoolId: props.schoolId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        message: values.message,
      };

      registerCourse(request)
        .unwrap()
        .then((res: any) => {
          setLoading(false);

          if (res.success) {
            notification.success({
              message: t('registration.course.warning.success'),
              description: t('registration.course.warning.success_message'),
              placement: 'bottomRight',
            });

            props.onSuccess();
          } else {
            notification.error({
              message: t('registration.course.warning.error'),
              description: t('registration.course.warning.error_message'),
              placement: 'bottomRight',
            });
          }
        })
        .catch(() => {
          setLoading(false);

          notification.error({
            message: t('registration.course.warning.error'),
            description: t('registration.course.warning.error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  useEffect(() => {
    if (isAuth && isReady) {
      formik.setFieldValue('firstName', firstName);
      formik.setFieldValue('lastName', lastName);
      formik.setFieldValue('phone', phone);
      formik.setFieldValue('email', email);
    }
  }, [isReady]);

  return (
    <>
      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={16}>
            <Input
              name="firstName"
              type="text"
              label={t('label.firstName')}
              placeholder={t('label.firstName')}
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />

            <Input
              name="lastName"
              type="text"
              label={t('label.lastName')}
              placeholder={t('label.lastName')}
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={16}>
            <Input
              name="email"
              type="email"
              label={t('label.email')}
              placeholder={t('label.email')}
              onChange={formik.handleChange}
              value={formik.values.email}
              // readOnly={true}
            />

            <Input
              name="phone"
              type="text"
              label={t('label.phone')}
              placeholder={t('label.phone')}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <InputGroup label={t('label.message')}>
              <Input
                type={'textarea'}
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <div className="submit-container">
              <ButtonGroup>
                <Button
                  type={'primary'}
                  loading={loading}
                  onClick={formik.submitForm}
                >
                  {t('button.register')}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </FormikProvider>
    </>
  );
}
