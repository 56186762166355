import { createSlice } from '@reduxjs/toolkit';
import { SchoolSettingRes } from '@services/private/SchoolSettingAPI';

const initialState = {
  ready: false,

  allowStudentRegister: false,
  allowTeacherRegister: false,
  allowParentRegister: false,

  // enableClassRoom: false,
  // enableStandard: false,
  // allowUsedLessonBank: false,
  // allowUpdateScoring: false,
};

const appSettingSlice = createSlice({
  name: 'appSetting',
  initialState,
  reducers: {
    updateSetting: (state, action: { payload: SchoolSettingRes }) => {
      state.ready = true;

      state.allowStudentRegister = action.payload.allowStudentRegister;
      state.allowTeacherRegister = action.payload.allowTeacherRegister;
      state.allowParentRegister = action.payload.allowParentRegister;

      // state.enableClassRoom = action.payload.enableClassRoom;
      // state.enableStandard = action.payload.enableStandard;
      // state.allowUsedLessonBank = action.payload.allowUsedLessonBank;
      // state.allowUpdateScoring = action.payload.allowUpdateScoring;
    },
  },
});

const { actions, reducer } = appSettingSlice;
export const { updateSetting } = actions;
export default reducer;
