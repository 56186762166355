import { SchoolStatusEnum } from '@services/model/common';
import { EntityStatusStyle } from '@components/status/style';

export const SchoolStatus = (props: { status: SchoolStatusEnum }) => {
  switch (props.status) {
    case SchoolStatusEnum.ACTIVE:
      return (
        <EntityStatusStyle className={'entity-status active-status'}>
          Active
        </EntityStatusStyle>
      );
    case SchoolStatusEnum.INACTIVE:
      return (
        <EntityStatusStyle className={'entity-status inactive-status'}>
          Inactive
        </EntityStatusStyle>
      );
    case SchoolStatusEnum.PENDING:
      return (
        <EntityStatusStyle className={'entity-status pending-status'}>
          Pending
        </EntityStatusStyle>
      );
    case SchoolStatusEnum.PUBLISHED:
      return (
        <EntityStatusStyle className={'entity-status published-status'}>
          Published
        </EntityStatusStyle>
      );
    case SchoolStatusEnum.REJECT:
      return (
        <EntityStatusStyle className={'entity-status removed-status'}>
          Removed
        </EntityStatusStyle>
      );
    default:
      return (
        <EntityStatusStyle className={'entity-status empty-status'}>
          &nbsp;
        </EntityStatusStyle>
      );
  }
};
