import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import ExerciseReportTable from '../exercise/ExerciseRecordTable';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { DoExerciseAPI } from '@modules/assignments/service/DoExerciseAPI';
import { ExerciseAPI } from '@modules/assignments/service/ExerciseAPI';

export const StartExerciseContainer = (props: {
  autoStart?: boolean;
  lessonId: number;
  productId?: number;
  productTocId?: number;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [getExercise, { data: exercises, isSuccess }] =
    ExerciseAPI.endpoints.getExercise.useLazyQuery({});

  const [createExercise] = DoExerciseAPI.endpoints.createExercise.useMutation(
    {}
  );

  useEffect(() => {
    if (props.lessonId > 0) {
      getExercise({
        lessonId: props.lessonId,
        productId: props.productId,
        productTocId: props.productTocId,
      });
    }
  }, [props.lessonId]);

  useEffect(() => {
    if (exercises && isSuccess) {
      if (exercises.length === 0) {
        startNewExercise();
      }
    }
  }, [exercises]);

  const startNewExercise = () => {
    const request = {
      lessonId: props.lessonId,
      productId: props.productId,
      productTocId: props.productTocId,
      getLatest: props.autoStart ?? false,
    };

    createExercise(request)
      .unwrap()
      .then((exercise) => {
        router.push(
          SiteMap.private.my_exercise.start_gen(
            exercise.exerciseId,
            props.productId,
            props.productTocId
          )
        );
      })
      .catch(() => {
        notification.error({
          message: t('alert.warning'),
          description: t('alert.cannotUpdate'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <>
      {exercises && exercises.length > 0 && (
        <CustomModal
          header={t('exercise.actions.review_result')}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <ExerciseReportTable
              exercises={exercises}
              lessonId={props.lessonId}
              productId={props.productId}
              productTocId={props.productTocId}
              onCloseFunc={props.onCancel}
              onStartExercise={startNewExercise}
            />
          }
          onCloseFunc={props.onCancel}
        />
      )}
    </>
  );
};
