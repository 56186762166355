import { H1 } from '@components/typography';
import { Button, Col, notification, Row, Steps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSiteAPI } from '@services/page-site/PageSiteAPI';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import Card, { CardClassEnum } from '@components/card';
import ButtonGroup from '@components/button/ButtonGroup';
import styled from 'styled-components';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { Default_Gutter } from '@components/grid';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
const { Step } = Steps;

export enum PublishPageStepEnum {
  INFORMATION = 1,
  REVIEW = 2,
}

export const PublishPageSitePage = (props: { pageSiteId: number }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [viewPageSite, { data }] =
    PageSiteAPI.endpoints.viewPageSite.useLazyQuery({});

  const handleGoBack = () => {
    router.push(SiteMap.page_content.static_page);
  };

  const [publishPageSite, { isLoading }] =
    PageSiteAPI.endpoints.publishPageSite.useMutation({});

  const [currentState, setCurrentState] = useState<PublishPageStepEnum>(
    PublishPageStepEnum.INFORMATION
  );

  useEffect(() => {
    viewPageSite(props.pageSiteId);
  }, [props.pageSiteId]);

  const getStatusType = (status: PublishPageStepEnum) => {
    return status === currentState
      ? 'process'
      : status < currentState
      ? 'finish'
      : 'wait';
  };

  const handleUpdateMetadata = () => {
    setCurrentState(PublishPageStepEnum.REVIEW);
  };

  const handlePublishPage = () => {
    publishPageSite(props.pageSiteId)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('page_site.warning.update_success'),
          description: t('page_site.warning.update_success_message'),
          placement: 'bottomRight',
        });
        router.push('/' + data.path);
      })
      .catch(() => {
        notification.error({
          message: t('lesson.warning.update_error'),
          description: t('lesson.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          <Button
            type={'default'}
            shape={'circle'}
            onClick={handleGoBack}
            style={{ marginLeft: 0 }}
          >
            <LeftOutlined />
          </Button>
          &nbsp;{t('product.actions.publish')}
          {data && (
            <>
              :<span className={'text-default'}>&nbsp;{data.name}</span>
            </>
          )}
        </H1>
      </HeaderGroup>

      <PublishPageSitePageStyle className={'publish-page-site'}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
            <Steps>
              <Step
                status={getStatusType(PublishPageStepEnum.INFORMATION)}
                title={t('publish.basic_info.header')}
              />
              <Step
                status={getStatusType(PublishPageStepEnum.REVIEW)}
                title={t('publish.basic_info.introduction')}
              />
            </Steps>
          </Col>
        </Row>

        {data && (
          <div style={{ marginTop: '2em' }}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              {currentState === PublishPageStepEnum.INFORMATION && (
                <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
                  <Card className={CardClassEnum.rectangle_box}>
                    <LessonMetadata
                      lessonId={data.lesson.lessonId}
                      onSuccess={() => handleUpdateMetadata()}
                      onCancel={() => {}}
                    />
                  </Card>

                  <ButtonGroup>
                    <Button
                      type={'default'}
                      shape={'round'}
                      onClick={() => {}}
                      disabled={true}
                    >
                      <LeftOutlined /> {t('button.step.previous')}
                    </Button>

                    <Button
                      type={'primary'}
                      shape={'round'}
                      onClick={handleUpdateMetadata}
                    >
                      {t('button.step.next')} <RightOutlined />
                    </Button>
                  </ButtonGroup>
                </Col>
              )}

              {currentState === PublishPageStepEnum.REVIEW && (
                <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
                  <LessonReview lessonId={data.lesson.lessonId} />

                  <ButtonGroup>
                    <Button
                      type={'default'}
                      shape={'round'}
                      onClick={() =>
                        setCurrentState(PublishPageStepEnum.INFORMATION)
                      }
                    >
                      <LeftOutlined /> {t('button.step.previous')}
                    </Button>

                    <Button
                      type={'primary'}
                      shape={'round'}
                      onClick={handlePublishPage}
                      loading={isLoading}
                    >
                      {t('button.step.publish')}
                    </Button>
                  </ButtonGroup>
                </Col>
              )}
            </Row>
          </div>
        )}
      </PublishPageSitePageStyle>
    </>
  );
};

const PublishPageSitePageStyle = styled.div`
  .view-lesson-content-container {
    border: 1px solid #000;
    background: #fff;
    margin-bottom: 2em;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 3em;
    margin-bottom: 2em;
  }
`;
