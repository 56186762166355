import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import { SchoolRegisterSettingRes } from '@services/private/SchoolSettingAPI';
import {
  ActiveUserReq,
  JwtRes,
  StudentRegister,
  UserRegister,
} from '@modules/authorization/services/model';

export const RegisterAPI = createApi({
  reducerPath: 'RegisterAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getSchoolRegisterSetting: builder.query<SchoolRegisterSettingRes, string>({
      query: (clientId: string) => ({
        url: `/public/school/${clientId}/setting/register`,
      }),
    }),

    studentRegister: builder.mutation<JwtRes, StudentRegister>({
      query: (body) => ({
        url: '/public/register/signup-as-student',
        body,
        method: 'POST',
      }),
    }),

    teacherRegister: builder.mutation<BasicMessageRes, UserRegister>({
      query: (body) => ({
        url: '/public/register/signup-as-teacher',
        body,
        method: 'POST',
      }),
    }),

    parentRegister: builder.mutation<BasicMessageRes, UserRegister>({
      query: (body) => ({
        url: '/public/register/signup-as-parent',
        body,
        method: 'POST',
      }),
    }),

    activeUser: builder.mutation<BasicMessageRes, ActiveUserReq>({
      query: (body) => ({
        url: '/public/register/active-user',
        method: 'POST',
        body,
      }),
    }),
  }),
});
