import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Dropdown, List, Space } from 'antd';
import { Tooltip } from '@components/tooltip/Tooltip';
import { DragItemTypeEnum } from '@components/react-dnd/DragNDropTypes';
import styled from 'styled-components';
import {
  TableOfContentAction,
  useComposeTocContext,
} from '@modules/product/components/toc/ComposeTocProvider';
import { ComposeTocLesson } from './ComposeTocLesson';
import Card, { CardClassEnum } from '@components/card';
import { H4 } from '@components/typography';
import Input from 'antd/lib/input/Input';
import { DropItem } from '@components/react-dnd-bt/DropItem';
import { IconUtils } from '@utils/IconUtils';
import { MenuProps } from 'antd/es/menu';
import { ProductTocRes } from '@modules/product/services/product_model';

export const ComposeTocUnit = (props: {
  type: 'view' | 'edit';

  first: boolean;
  last: boolean;

  item: ProductTocRes;
  onAddLesson: (lessonId: number, productTocId: number) => void;
}) => {
  const { t } = useTranslation();

  const { dispatchAction, loading } = useComposeTocContext();

  const [newTitle, setNewTitle] = useState(props.item.name);

  const handleUpdateTitle = (event: any) => {
    setNewTitle(event.target.value);
  };

  const updateProductToc = () => {
    dispatchAction(TableOfContentAction.update_unit, {
      productTocId: props.item.productTocId,
      name: newTitle,
    });
  };

  const cancelProductToc = () => {
    setNewTitle(props.item.name);
    dispatchAction(TableOfContentAction.select_unit, -1);
  };

  const handleRemoveUnit = () => {
    dispatchAction(TableOfContentAction.remove_unit, props.item.productTocId);
  };

  const handleInsertLessonToUnit = () => {
    dispatchAction(TableOfContentAction.insert_lesson_bellow, props.item);
  };

  const addProductTocBellow = () => {
    dispatchAction(TableOfContentAction.insert_unit_below, props.item);
  };

  const onSelectUnit = () => {
    dispatchAction(TableOfContentAction.select_unit, props.item.productTocId);
  };

  const moveProductToc = (type: 'up' | 'down') => {
    if (type === 'up') {
      dispatchAction(TableOfContentAction.move_up, props.item.productTocId);
    } else {
      dispatchAction(TableOfContentAction.move_down, props.item.productTocId);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'add-product-toc-below',
      onClick: addProductTocBellow,
      icon: IconUtils.actions.add_more,
      label: t('table_of_content.actions.add'),
    },
    {
      key: 'remove',
      onClick: handleRemoveUnit,
      icon: IconUtils.actions.remove,
      label: t('table_of_content.actions.remove'),
    },
    {
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'add-new-lesson',
      onClick: handleInsertLessonToUnit,
      icon: IconUtils.actions.add_more,
      label: t('table_of_content.actions.add_new_lesson'),
    },
  ];

  const handleDropData = (lessonId: number, productTocId: number) => {
    props.onAddLesson(lessonId, productTocId);
  };

  return (
    <ComposeTocUnitStyle className={'product-toc-info'}>
      <div className={'product-toc-title'}>
        {props.type === 'view' && (
          <div className={'title-display'}>
            <H4 onClick={onSelectUnit}>
              {props.item.name} {IconUtils.edit}
            </H4>

            <Space size={12}>
              <Tooltip title={t('table_of_content.actions.move_up')}>
                <Button
                  size={'small'}
                  shape={'circle'}
                  disabled={props.first}
                  onClick={() => moveProductToc('up')}
                  icon={IconUtils.actions.move_up}
                />
              </Tooltip>

              <Tooltip title={t('table_of_content.actions.move_down')}>
                <Button
                  shape={'circle'}
                  size={'small'}
                  disabled={props.last}
                  onClick={() => moveProductToc('down')}
                  icon={IconUtils.actions.move_down}
                />
              </Tooltip>

              <Dropdown menu={{ items }} trigger={['click']} arrow>
                <Button
                  size={'small'}
                  shape="circle"
                  type={'default'}
                  icon={IconUtils.more_icon}
                />
              </Dropdown>
            </Space>
          </div>
        )}

        {props.type === 'edit' && (
          <div className={'edit-unit-toc'}>
            <div className={'title-display'}>
              <Input
                size={'large'}
                name={'product-toc-name'}
                placeholder=""
                value={newTitle}
                onChange={handleUpdateTitle}
                style={{ width: '100%' }}
              />
            </div>

            <div className={'edit-product-toc-actions'}>
              <Button
                shape={'round'}
                type={'primary'}
                onClick={updateProductToc}
                icon={IconUtils.actions.update}
              >
                {t('label.update')}
              </Button>

              <Button
                shape={'round'}
                type={'primary'}
                danger
                onClick={cancelProductToc}
                icon={IconUtils.actions.cancel}
              >
                {t('label.cancel')}
              </Button>
            </div>
          </div>
        )}
      </div>

      {props.type === 'view' && (
        <Card className={`${CardClassEnum.rectangle} no-vertical-spacing`}>
          <DropItem
            acceptType={DragItemTypeEnum.LESSON_ITEM}
            droppableId={props.item.productTocId + ''}
            className={
              'product-toc-lessons' +
              (props.item.lessons == null || props.item.lessons.length === 0
                ? ' empty '
                : '')
            }
            handleOnDrop={(lessonId) =>
              handleDropData(Number(lessonId), props.item.productTocId)
            }
            placeHolder={true}
          >
            {props.item.lessons != null && props.item.lessons.length > 0 && (
              <List
                loading={loading}
                bordered
                size={'small'}
                dataSource={props.item.lessons}
                style={{ width: '100%' }}
                renderItem={(item, index) => (
                  <List.Item>
                    <ComposeTocLesson
                      first={index === 0}
                      last={index === props.item.lessons.length - 1}
                      productToc={props.item}
                      item={item}
                    />
                  </List.Item>
                )}
              />
            )}

            {(props.item.lessons == null ||
              props.item.lessons.length === 0) && (
              <>{t('table_of_content.warning.drag_lesson_here')}</>
            )}
          </DropItem>
        </Card>
      )}
    </ComposeTocUnitStyle>
  );
};

const ComposeTocUnitStyle = styled.div`
  .product-toc-title {
    white-space: nowrap;
    position: relative;
    line-height: 1.6;
    cursor: pointer;

    .title-display {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      display: flex;
      justify-content: space-between;

      h2 {
        font-weight: bold;
      }
    }

    .product-toc-actions {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0%, -50%);
    }

    .edit-product-toc-actions {
      display: flex;
      justify-items: flex-end;
      align-items: center;
    }
  }

  .edit-unit-toc {
    display: flex;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1.5em;

    .title-display {
      flex-grow: 1;
      margin-right: 1em;
    }

    .edit-product-toc-actions {
      .ant-btn {
        margin-left: 0.5em;
      }
    }
  }

  .no-vertical-spacing {
    margin-bottom: 2.5em !important;
  }

  .droppable-container {
    color: ${(props) => props.theme.app.grey};
    text-align: center;
    background: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.dragging {
      background: ${(props) => props.theme.color.grey};
    }

    &.empty {
      border: 1px dotted ${(props) => props.theme.color.dark_grey};
      margin: 20px;
      color: ${(props) => props.theme.color.dark_grey};
      min-height: 80px;
    }

    .lesson-info-static {
      width: 100%;
    }
  }
`;
