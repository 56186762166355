import React, { useMemo } from 'react';
import {
  generateCheckBoxAnswer,
  parseCheckBoxAnswer,
  useCheckBoxCompContext,
} from '@cms/comps/interact/checkbox/CheckBoxCompContext';
import CheckBoxOption from './CheckBoxOption';

const CheckBoxOptions = (props: { groupIndex: number; partIndex: number }) => {
  const {
    modifiedTime,
    disabled,
    setting,
    sourceItems,
    answers,
    onSelectOption,
  } = useCheckBoxCompContext();

  const answer = useMemo((): string[] => {
    const inlineAns: string[] = [];

    answers.forEach((ans) => {
      const answerPart = parseCheckBoxAnswer(ans);
      if (
        answerPart.groupIndex === props.groupIndex &&
        answerPart.partIndex === props.partIndex
      ) {
        inlineAns.push(answerPart.optionLabel);
      }
    });
    return inlineAns;
  }, [answers, props.groupIndex, props.partIndex]);

  const handleOnClick = (label: string) => {
    onSelectOption(
      generateCheckBoxAnswer(props.groupIndex, props.partIndex, label)
    );
  };

  return (
    <div className={'comps-checkbox-panel source-items'}>
      {sourceItems &&
        sourceItems.map((option, index) => {
          return (
            <div
              className={`comps-checkbox-option`}
              key={
                option.label +
                '-' +
                index +
                '-' +
                option.content.id +
                '_' +
                modifiedTime
              }
            >
              <CheckBoxOption
                checked={answer.includes(option.label)}
                disabled={disabled}
                option={option}
                answer={answer}
                setting={setting}
                onClick={handleOnClick}
              />
            </div>
          );
        })}
    </div>
  );
};

export default CheckBoxOptions;
