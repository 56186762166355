import React from 'react';
import { ExerciseRes } from '@modules/assignments/service/exercise_model';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ReportUtils } from '@modules/reports/ReportUtils';
import styled from 'styled-components';
import ColorUtils from '@utils/ColorUtils';
import { StudentInfoSimple } from '@components/info/StudentInfo';

export const SelectExercises = (props: {
  type: 'student' | 'exercise';
  exercises: ExerciseRes[];
  value: number[];
  onChange: (value: number[]) => void;
}) => {
  const handleOnClick = (exerciseId: number) => {
    if (props.value.includes(exerciseId)) {
      props.onChange(
        props.value.filter((id) => {
          return id !== exerciseId;
        })
      );
    } else {
      props.onChange([...props.value, exerciseId]);
    }
  };
  return (
    <SelectExercisesStyle className={'select-exercise'}>
      {props.exercises.map((ex, index) => {
        const color = ColorUtils.getDefaultColor(index);
        return (
          <div
            className={`exercise-info ${
              props.value.includes(ex.exerciseId) ? 'active' : ''
            }`}
            key={ex.exerciseId}
            onClick={() => handleOnClick(ex.exerciseId)}
          >
            {props.type === 'exercise' ? (
              <span className={'exercise-start-date'}>
                <span
                  className={'exercise-icon'}
                  style={{
                    backgroundColor: color.color,
                    borderColor: color.color,
                  }}
                />
                {DateAndTimeUtils.format(
                  ex.createdDate,
                  DateAndTimeFormat.SHORT
                )}
              </span>
            ) : (
              <span className={'exercise-start-date'}>
                <span
                  className={'exercise-icon'}
                  style={{
                    backgroundColor: color.color,
                    borderColor: color.color,
                  }}
                />
                <StudentInfoSimple item={ex.student!} />
              </span>
            )}

            <span className={'exercise-score'}>
              {ReportUtils.percent(ex.score!, ex.maxScore!)}%
            </span>
          </div>
        );
      })}
    </SelectExercisesStyle>
  );
};

const SelectExercisesStyle = styled.div`
  width: 100%;
  font-size: 16px;

  .exercise-info {
    display: flex;
    justify-content: space-between;
    padding: 0.25em;
    border: 1px solid #ccc;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    &:hover {
      background: ${(props) => props.theme.app.primary_bgr};
    }

    &.active {
      font-weight: bold;
      color: ${(props) => props.theme.app.primary};
      border-color: ${(props) => props.theme.app.primary};
    }

    .exercise-start-date {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .exercise-icon {
        width: 32px;
        height: 32px;
        border: 1px solid;
        display: inline-block;
        margin-right: 0.5em;
      }
    }

    .exercise-score {
      min-width: 2em;
    }
  }
`;
