import {
  CompAnswerProps,
  CompProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useMemo, useState } from 'react';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';

import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

import {
  ComponentGrading,
  ComponentGradingClassName,
} from '@cms/comps/interact/ComponentGrading';
import { CheckBoxCompContextProvider } from './CheckBoxCompContext';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import CheckBoxCompEditable from './CheckBoxCompEditable';
import CheckBoxGroupItems from './CheckBoxCompGroupItem';

export interface CheckBoxItemProps {
  label: string;
  content: TextContentProps | ImageContentProps | AudioContentProps;
}

export interface CheckBoxSettingProps extends CompInteractSettingProps {
  layout: 'horizontal' | 'vertical' | '2x2';
  showCheckbox?: boolean;
  showLabel: boolean;
  showOption: boolean;
  multiple: boolean;
}

export interface CheckBoxCompProps extends CompProps {
  type: CompTypeEnum.CHECK_BOX;
  configuration: {
    sourceItems: CheckBoxItemProps[];
    targetItems: CheckBoxItemProps[];
  };
  setting: CheckBoxSettingProps;
}

export interface CheckBoxAnsProps extends CompAnswerProps {
  answer: string[];
}

export const CheckBoxComp = (props: {
  disabled: boolean;
  item: CheckBoxCompProps;
  answer: CheckBoxAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);

  const componentClassName = useMemo(() => {
    let className = '';

    if (props.item.setting) {
      if (props.item.setting.showCheckbox) {
        className += ' show-checkbox ';
      } else {
        className += ' hide-checkbox ';
      }

      if (props.item.setting.showOption) {
        className += ' show-option ';
      } else {
        className += ' hide-option ';
      }

      if (props.item.setting.showLabel) {
        className += ' show-label ';
      } else {
        className += ' hide-label ';
      }

      if (props.item.setting.multiple) {
        className += ' multiple-select ';
      } else {
        className += ' single-select ';
      }

      if (props.item.setting.layout) {
        className += ' layout-' + props.item.setting.layout;
      }
    }

    return className;
  }, [props.item.setting]);

  return (
    <CheckBoxCompStyle
      className={
        `comps-checkbox ${props.item.setting?.className}` + componentClassName
      }
    >
      <CheckBoxCompContextProvider
        disabled={props.disabled}
        item={props.item}
        answer={props.answer}
        feedback={props.feedback}
        onChange={props.onChange}
      >
        {showComponent && (
          <>
            <CheckBoxGroupItems />

            <ComponentSettingToolbar
              showComponent={showComponent}
              onClick={() => setShowComponent((prevState) => !prevState)}
            />
          </>
        )}

        <ComposeModeContent>
          {!showComponent && (
            <CheckBoxCompEditable onClose={() => setShowComponent(true)} />
          )}
        </ComposeModeContent>

        {props.feedback && (
          <ComponentGrading
            className={ComponentGradingClassName.absolute_position}
            feedback={props.feedback}
          />
        )}
      </CheckBoxCompContextProvider>
    </CheckBoxCompStyle>
  );
};

const CheckBoxCompStyle = styled.div`
  position: relative;

  .comps-checkbox-option {
    display: inline-flex;
  }

  &.hide-option {
    .comps-checkbox-panel {
      gap: 0.5em;

      .comps-checkbox-content {
        margin: 0;
      }
    }

    &.layout-2x2 {
      .comps-checkbox-panel .comps-checkbox-option {
        max-width: calc(50% - 0.5em);
      }
    }
  }

  &:hover {
    .comps-checkbox-panel {
      z-index: 3;
    }

    .component-grading-background {
      opacity: 0.6;
    }
  }

  &.layout-horizontal {
    .comps-checkbox-panel {
      display: inline-flex;
      flex-direction: row;
      gap: 1em;
    }
  }

  &.layout-vertical {
    .comps-checkbox-panel {
      display: inline-flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .comps-checkbox-option {
    &.correct-part .comps-checkbox-item {
      color: ${(props) => props.theme.component.correct} !important;
    }

    &.incorrect-part .comps-checkbox-item {
      color: ${(props) => props.theme.component.incorrect} !important;
    }
  }
`;
