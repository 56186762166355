import React from 'react';
import styled from 'styled-components';

export const ModuleTable = () => {
  return (
    <ModuleTableStyle className={'role-description-table'}>
      <div className={'direction-group'}>
        This table show how modules in the group is allocated.
      </div>
      <table
        className={'permission-table'}
        style={{ width: '100%' }}
        border={1}
      >
        <thead>
          <tr>
            <th>Modules</th>
            <th style={{ width: '30%' }}>Entity</th>
            <th style={{ width: '20%' }}>Path</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Authorization</td>
            <td>
              users roles refreshtoken school schoolusers schooluserpermission
            </td>
            <td>authorization</td>
          </tr>

          <tr>
            <td>Account Module </td>
            <td>
              userprofile accountbalance accounttracking accounttransaction
            </td>
            <td>account</td>
          </tr>

          <tr>
            <td>User Manage Module</td>
            <td>
              class classroster classresource invitation userrequest
              usersubscription studentparent studentteacher course studentcourse
              coursecategory coursegrade coursesubject courseroster
              courseregistration
            </td>
            <td>users</td>
          </tr>

          <tr>
            <td>CMS Module </td>
            <td>
              program programproduct product producttoc producttoclesson lesson
              lessonvalidation lessonsection lessonresource resource
              resourcevalidation resourcestandard lessonexample lessontype
              lessontemplate assets standard standardset
            </td>
            <td>content</td>
          </tr>

          <tr>
            <td>Assignment Module </td>
            <td>
              assignment assignmentgroup assignmentinstance
              assignmentinstancestatus exercise exerciseresponse
              exerciseresourcestandard exercisestandard
            </td>
            <td>assignment</td>
          </tr>

          <tr>
            <td>License Module </td>
            <td>license productlicense sharelesson shareproduct userlicense</td>
            <td>license</td>
          </tr>

          <tr>
            <td>Report Module</td>
            <td>
              standarddailyscore standardscorehistory usertracking
              viewproductlessontracking viewproducttracking{' '}
            </td>
            <td>report</td>
          </tr>

          <tr>
            <td>Session Module</td>
            <td>
              event session studentsession sessionlesson sessionsectionhistory
              sessiontask sessiontaskinstance
            </td>
            <td>session</td>
          </tr>

          <tr>
            <td>Public Module</td>
            <td>
              pagesite pagesitecontent survey surveyresponse publishcourse
              publishlesson publishproduct
            </td>
            <td>public</td>
          </tr>

          <tr>
            <td>Payment Module</td>
            <td>order currency paymentmethod schoolpaymentmethod</td>
            <td>payment</td>
          </tr>

          <tr>
            <td>Setting Module</td>
            <td>
              language schoollanguage schoolsetting emailmessage emailsetting
              emailtemplate schoolrolesetting
            </td>
            <td>setting</td>
          </tr>

          <tr>
            <td>Certificate Module</td>
            <td>
              certificate certificatescore usercertificate usercertificatescore
            </td>
            <td>certificate</td>
          </tr>

          <tr>
            <td>Chat Module</td>
            <td>conversation groupmember message</td>
            <td>message</td>
          </tr>

          <tr>
            <td>Oauth App Module</td>
            <td>oauthapp</td>
            <td>oauth</td>
          </tr>

          <tr>
            <td>Category Module </td>
            <td>
              category grade subject productgrade productsubject productcategory
            </td>
            <td>category</td>
          </tr>
        </tbody>
      </table>
    </ModuleTableStyle>
  );
};

export const ModuleTableStyle = styled.div`
  .direction-group {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
  }

  table.permission-table {
    thead tr th,
    tbody tr th.group,
    tbody tr th.group-empty {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 13px;
    }

    thead tr th {
      text-align: center;
      background: ${(props) => props.theme.app.primary};
      color: #fff;
    }

    tr td,
    tr th {
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 8px 16px;

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }

    tr:last-child {
      th,
      td {
        border-bottom: 1px solid #ccc;
      }
    }

    tr td:first-child {
      text-align: left;
    }

    tr th {
      text-align: left;
      background: ${(props) => props.theme.app.primary_bgr};
      color: #000;
    }
  }
`;
