import React from 'react';

import TeacherRegistration from '@modules/authorization/pages/RegisterTeacher';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@app/redux/store';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';

const RegisterTeacher = () => {
  const { t } = useTranslation();

  const { allowTeacherRegister } = useSelector(
    (state: RootState) => state.appSetting
  );

  return (
    <PublicLayout className={'register-teacher-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('registration.actions.teacher')}
        description={t('registration.actions.teacher')}
      />

      <TeacherRegistration disabled={!allowTeacherRegister} />
    </PublicLayout>
  );
};

export default RegisterTeacher;
