import { Col, Layout, Row } from 'antd';
import React from 'react';
import SwitchLanguage from '@components/header/SwitchLanguage';
import styled from 'styled-components';
import PublicNavigation from '@components/template/public-layout/navigation/PublicNavigation';
import { ResponsiveScreen } from '@hooks/useMedia';
import { Responsive } from '@components/responsive/Responsive';
import { AppLogo } from '@components/header/AppLogo';
import { Default_Gutter } from '@components/grid';

const { Header } = Layout;

export const HeaderWrapper = styled(Header)`
  font-size: 18px;
  height: auto;
  background: #fff;
  color: #333;

  .header-navigation {
    position: relative;
  }

  .header-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      font-size: 24px;
      font-weight: bold;
    }

    .ant-divider {
      border-left: 1px solid ${(props) => props.theme.menu.color};
    }
  }

  .language-group {
    display: inline-flex;
    font-size: 14px;
    margin-left: 1em;

    .ant-btn {
      background: transparent;
      border-color: transparent;
    }
  }
`;

const PublicHeader = () => {
  return (
    <HeaderWrapper
      style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}
    >
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Responsive
            screens={[
              ResponsiveScreen.xs,
              ResponsiveScreen.sm,
              ResponsiveScreen.md,
            ]}
          >
            <div className={'header-group'}>
              <PublicNavigation />

              <AppLogo>
                <SwitchLanguage />
              </AppLogo>
            </div>
          </Responsive>

          <Responsive
            excludeScreens={[
              ResponsiveScreen.md,
              ResponsiveScreen.xs,
              ResponsiveScreen.sm,
            ]}
          >
            <div className={'header-group'}>
              <AppLogo>
                <SwitchLanguage />
              </AppLogo>

              <PublicNavigation />
            </div>
          </Responsive>
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

export default PublicHeader;
