import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useRef, useState } from 'react';
import { GlossaryGameCompProps } from '@cms/comps/game/GameUtils';
import FlashCardMatchingOption from '@cms/comps/game/matching/FlashCardMatchingOption';
import {
  AudioContentProps,
  ImageContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import styled from 'styled-components';
import {
  GlossaryGameActionEnum,
  useGlossaryGameItemContext,
} from '@cms/lesson-template/glossary-game/GlossaryGameContext';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { AssetsRes } from '@modules/product/services/assets_model';
import { useDispatch } from 'react-redux';
import { GlossaryWordBank } from '@cms/comps/game/GlossaryWordBank';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';

export enum FlashCardTermGroup {
  word = 'word',
  definition = 'definition',
  empty = 'empty',
}

export interface FlashCardTermProps {
  id: string;
  index: number;
  displayOrder: number;

  type: FlashCardTermGroup;
  content:
    | TextContentProps
    | ImageContentProps
    | AudioContentProps
    | AssetsRes
    | null;
}

export interface MatchingGameSettingProps extends CompInteractSettingProps {
  columns: number;
  rows: number;
}

export interface MatchingGameCompProps extends GlossaryGameCompProps {
  type: CompTypeEnum.GAME_MATCHING;
  setting: MatchingGameSettingProps;
}

export function FlashCardMatchingComp(props: {
  item: MatchingGameCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);
  const context = useGlossaryGameItemContext();

  const [flashCards, setFlashCards] = useState<FlashCardTermProps[]>([]);
  const [selectSource, setSelectSource] = useState<string | null>(null);
  const [disabledCardIndex, setDisabledCardIndex] = useState<number[]>([]);

  const selectItem = useRef('');

  // step 1. Generate list card items.....
  useEffect(() => {
    let flashCards: FlashCardTermProps[] = [];

    props.item.configuration.sourceItems.forEach((item, index) => {
      flashCards.push({
        id: item.id,
        index: index,
        type: FlashCardTermGroup.word,
        content: item.word,
        displayOrder: Math.random(),
      });
      flashCards.push({
        id: item.id,
        content: item.description,
        index: index,
        type: FlashCardTermGroup.definition,
        displayOrder: Math.random(),
      });
    });

    const totalFlashCard = props.item.setting.rows * props.item.setting.columns;

    if (totalFlashCard > flashCards.length) {
      const index = flashCards.length;

      for (let i = index; i < totalFlashCard; i++) {
        flashCards.push({
          id: `empty-flash-card-id-${i}`,
          content: null,
          index: i,
          type: FlashCardTermGroup.empty,
          displayOrder: Math.random(),
        });
      }
    }

    flashCards = flashCards.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });

    setFlashCards(flashCards);
  }, [props.item]);

  useEffect(() => {
    if (disabledCardIndex.length > 0) {
      if (
        disabledCardIndex.length === props.item.configuration.sourceItems.length
      ) {
        context.dispatchAction(
          GlossaryGameActionEnum.correct,
          disabledCardIndex.length,
          CompTypeEnum.GAME_MATCHING
        );
      } else {
        context.dispatchAction(
          GlossaryGameActionEnum.correct_part,
          disabledCardIndex.length,
          CompTypeEnum.GAME_MATCHING
        );
      }
    }
  }, [disabledCardIndex]);

  useEffect(() => {
    if (selectSource) {
      selectItem.current = selectSource;
    } else {
      selectItem.current = '';
    }
  }, [selectSource]);

  const handleClick = (target: FlashCardTermProps) => {
    // if 2nd select.....
    if (selectItem.current != null && selectItem.current !== '') {
      const selectedIndex = Number(selectItem.current.split('_')[0]);

      if (selectedIndex === target.index) {
        // trigger correct
        setSelectSource(null);
        setDisabledCardIndex((prev) => {
          return [...prev, target.index];
        });
      } else {
        setSelectSource(null);
      }
    } else {
      setSelectSource(target.index + '_' + target.type);
    }
  };

  const handleComponentChange = (newComps: MatchingGameCompProps) => {
    const answer = newComps.configuration.sourceItems.map((ans) => {
      return ans.word.data;
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );
    setShowComponent(true);
  };

  return (
    <FlashCardMCCompStyle className={'comp comp-flashcard-mc'}>
      {showComponent && (
        <>
          {flashCards.map((target) => {
            return (
              <div
                className={`flash-card-mc-option`}
                style={{ width: 100 / props.item.setting.columns + '%' }}
                key={JSON.stringify(target.index + '_' + target.type)}
              >
                <FlashCardMatchingOption
                  compId={props.item.id}
                  target={target}
                  onClick={handleClick}
                  selected={
                    selectSource != null &&
                    selectSource === target.index + '_' + target.type
                  }
                  disabled={disabledCardIndex.includes(target.index)}
                  handleOnContentChange={() => {}}
                />
              </div>
            );
          })}

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </FlashCardMCCompStyle>
  );
}

const FlashCardMCCompStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .flash-card-mc-option {
    position: relative;
    height: 15ch;
    padding: 0.25em;
  }
`;
