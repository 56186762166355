import NavLink from '@components/button/NavLink';
import { Tooltip } from '@components/tooltip/Tooltip';
import { Button, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { SiteMap } from '@router/SiteMap';
import { AssignmentInstanceStatusRes } from '@modules/assignments/service/assignment_model';
import {
  ExerciseRes,
  ExerciseStatus,
} from '@modules/assignments/service/exercise_model';

const AssignmentActionTeacher = ({
  item,
}: {
  item: AssignmentInstanceStatusRes;
}) => {
  const { t } = useTranslation();

  const sendAssignmentRemind = () => {
    alert('Send notification for student!.');
  };

  return (
    <Space size={5}>
      <NavLink
        href={SiteMap.assignments.edit_gen(
          item.assignmentInstance.assignmentInstanceId
        )}
      >
        <Tooltip placement="top" title={t('assignment.actions.edit')}>
          <Button
            type={'default'}
            shape="circle"
            icon={IconUtils.assignment.edit}
          />
        </Tooltip>
      </NavLink>

      <NavLink
        href={SiteMap.assignments.grade_gen(
          item.assignmentInstance.assignmentInstanceId
        )}
      >
        <Tooltip placement="top" title={t('assignment.actions.grade')}>
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.assignment.grade}
            disabled={item.needGrading <= 0}
          />
        </Tooltip>
      </NavLink>

      <NavLink
        href={SiteMap.reports.view_exercise_gen(
          item.assignmentInstance.assignmentInstanceId
        )}
      >
        <Tooltip placement="top" title={t('assignment.actions.report')}>
          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.assignment.report}
            disabled={item.completed <= 0}
          />
        </Tooltip>
      </NavLink>

      <Tooltip placement="top" title={t('assignment.actions.remind')}>
        <Button
          type={'default'}
          shape="circle"
          onClick={sendAssignmentRemind}
          disabled={
            !(item.notStarted > 0 || item.inProgress > 0 || item.viewed > 0)
          }
          icon={IconUtils.assignment.remind}
        />
      </Tooltip>
    </Space>
  );
};

export const ExerciseActionTeacher = ({
  assignmentInstanceId,
  item,
}: {
  assignmentInstanceId: number;
  item: ExerciseRes;
}) => {
  const { t } = useTranslation();

  const sendAssignmentRemind = () => {
    alert('Send notification for student!.');
  };

  return (
    <Space size={5}>
      {item.status === ExerciseStatus.NEED_GRADING && (
        <NavLink
          href={SiteMap.assignments.grade_gen(
            assignmentInstanceId,
            item.exerciseId
          )}
        >
          <Tooltip placement="top" title={t('assignment.actions.grade')}>
            <Button shape="circle" icon={IconUtils.assignment.grade} />
          </Tooltip>
        </NavLink>
      )}

      {item.status === ExerciseStatus.COMPLETED && (
        <NavLink href={SiteMap.private.my_exercise.review_gen(item.exerciseId)}>
          <Tooltip placement="top" title={t('assignment.actions.report')}>
            <Button
              shape="circle"
              type={'primary'}
              icon={IconUtils.assignment.report}
            />
          </Tooltip>
        </NavLink>
      )}

      {item.status !== ExerciseStatus.NEED_GRADING &&
        item.status !== ExerciseStatus.COMPLETED && (
          <Tooltip placement="top" title={t('assignment.actions.remind')}>
            <Button
              shape="circle"
              onClick={sendAssignmentRemind}
              icon={IconUtils.assignment.remind}
            />
          </Tooltip>
        )}
    </Space>
  );
};

export default AssignmentActionTeacher;
