import * as React from 'react';

const SocialVideoCp = (props: {
  type: 'facebook' | 'youtube' | 'twister';
  src: string;
}) => {
  if (props.type === 'facebook') {
    return (
      <iframe
        title={'facebook'}
        src={`https://www.facebook.com/plugins/video.php?href=${props.src}&show_text=0`}
        scrolling="no"
        frameBorder="0"
        allowTransparency
        allowFullScreen
      />
    );
  } else if (props.type === 'youtube') {
    return (
      <iframe
        title={'youtube'}
        src={`//www.youtube.com/embed/${getEmbedYoutubeUrl(props.src)}`}
        frameBorder="0"
        allowFullScreen
      />
    );
  } else if (props.type === 'twister') {
    return null;
  } else {
    return null;
  }
};

const SocialVideo = React.memo(SocialVideoCp);
export default SocialVideo;

const getEmbedYoutubeUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};
