import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { SettingStyle } from '@cms/comps/common/SettingStyle';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import { CompInteractSettingProps } from '@cms/ComponentInteface';

export const ComponentCommonSetting = (props: {
  setting: CompInteractSettingProps;
  onSettingChange: (newSetting: any) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      className: props.setting ? props.setting.className : '',
      autoScore: false,
    },
    onSubmit: () => {},
  });

  console.log(setTimeout);

  useEffect(() => {
    props.onSettingChange(formik.values);
  }, [JSON.stringify(formik.values)]);

  return (
    <SettingStyle>
      <FormikProvider value={formik}>
        <Input
          label={t('component.label.class_name')}
          placeholder={t('component.label.class_name')}
          name="className"
          value={formik.values.className ?? ''}
          onChange={formik.handleChange}
        />
      </FormikProvider>
    </SettingStyle>
  );
};
