import { UserProductLicenseAPI } from '@modules/license/services/UserProductLicenseAPI';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, notification, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { H4 } from '@components/typography';
import { InputGroup } from '@components/input/InputGroup';
import DatePicker from '@components/date-n-time/DatePicker';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import styled from 'styled-components';
import React from 'react';
import { ProductLicenseRes } from '@modules/license/services/model';

export const EditProductLicenseForm = (props: {
  userId: number;
  data: ProductLicenseRes;
  onSuccess: (data: ProductLicenseRes) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [updateLicense] =
    UserProductLicenseAPI.endpoints.updateLicense.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      productId: -1,
      availableDate: props.data.availableDate,
      expiredDate: props.data.expiredDate,
    },
    onSubmit: (values) => {
      handleUpdateLicense(values.availableDate, values.expiredDate);
    },
  });

  const handleUpdateLicense = (availableDate: string, expiredDate: string) => {
    const request = {
      userId: props.userId,
      productLicenseId: props.data.productLicenseId,
      availableDate: availableDate,
      expiredDate: expiredDate,
    };

    updateLicense(request)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('license.warning.update_success'),
          description: t('license.warning.add_success_message'),
          placement: 'bottomRight',
        });

        props.onSuccess(data);
      })
      .catch(() => {
        notification.error({
          message: t('license.warning.update_error'),
          description: t('license.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  return (
    <FormikProvider value={formik}>
      <EditLicenseFormStyle className={'edit-license-form'}>
        <Row gutter={[Default_Gutter, Default_Gutter]} align={'bottom'}>
          <Col span={12}>
            <div className={'product-info'}>
              <ThumbnailsImg
                type={'icon'}
                src={props.data.product.icon}
                width={ImageScale.product.iconLarge}
                height={ImageScale.product.iconLarge}
              />

              <H4>{props.data.product.name}</H4>
            </div>
          </Col>

          <Col span={6}>
            <InputGroup label={'Available Date'}>
              <DatePicker
                formik={formik}
                name="availableDate"
                value={formik.values.availableDate}
                handleChange={formik.handleChange}
              />
            </InputGroup>
          </Col>

          <Col span={6}>
            <InputGroup label={'Expired Date'}>
              <DatePicker
                formik={formik}
                name="expiredDate"
                value={formik.values.expiredDate}
                handleChange={formik.handleChange}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row gutter={[Default_Gutter, Default_Gutter]} align={'bottom'}>
          <Col span={24}>
            <ButtonGroup type={'right'} className={'submit-container'}>
              <Button
                type={'primary'}
                size={'large'}
                shape={'round'}
                icon={IconUtils.actions.update}
                onClick={formik.submitForm}
              >
                {t('label.update')}
              </Button>

              <Button size={'large'} shape={'round'} onClick={props.onCancel}>
                {t('label.cancel')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </EditLicenseFormStyle>
    </FormikProvider>
  );
};

const EditLicenseFormStyle = styled.div`
  .product-info {
    display: flex;
    align-items: center;

    .ant-typography {
      margin-left: 1em;
    }
  }
`;
