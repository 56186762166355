import React from 'react';

import ActivateAccountPage from '@modules/authorization/pages/ActiveAccountPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useRouter } from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';

const RegisterActiveAccount = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { token, invitedCode } = router.query as {
    token: string;
    invitedCode: string;
  };

  return (
    <PublicLayout className={'register-active-account-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PUBLIC_REGISTER}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('active_account.header')}
        description={t('active_account.header')}
      />

      <ActivateAccountPage token={token} invitedCode={invitedCode} />
    </PublicLayout>
  );
};

export default RegisterActiveAccount;
