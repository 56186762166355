import React, { useEffect } from 'react';
import { Button, Col, Divider, notification, Row } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import SingleUpload from '@components/input/SingleUpload';
import IconImage from '@components/images/IconImage';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  MyProfileAPI,
  MyProfileRes,
  UpdateMyProfileReq,
} from '@services/private/MyProfileAPI';
import { ImageScale } from '@utils/ImageScale';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from '@app/redux/slices/userInfomationSlice';
import Select, { Option } from '@components/select/Select';
import DatePicker from '@components/date-n-time/DatePicker';
import {
  ClientDateAndTimeFormat,
  DateAndTimeUtils,
} from '@utils/DateAndTimeUtils';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import Checkbox from '@components/input/Checkbox';
import { SelectLanguage } from '@components/select/SelectLanguage';
import { getDefaultLanguage } from '@app/redux/slices/appLanguageSlice';
import { SelectTheme } from '@components/select/SelectTheme';
import { changeTheme } from '@app/redux/slices/appLayoutSlice';
import { H2 } from '@components/typography';

const getCurrentDate = () => {
  return DateAndTimeUtils.getDate(ClientDateAndTimeFormat.DATE);
};

export default function UpdateProfileForm(props: {
  onSuccess: (newData: MyProfileRes) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getMyProfile, { data, isSuccess, isFetching }] =
    MyProfileAPI.endpoints.getMyProfile.useLazyQuery();

  const [updateMyProfile, { isLoading }] =
    MyProfileAPI.endpoints.updateMyProfile.useMutation({});

  useEffect(() => {
    getMyProfile({});
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      updateMyProfileData(data);
    }
  }, [isFetching]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      avatar: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      birthday: getCurrentDate(),
      language: getDefaultLanguage(),
      theme: '',
      proUser: false,
    },

    validationSchema: yup.object().shape({
      email: yup.string().email(t('registration.validation.email_invalid')),
      firstName: yup.string().required(t('validation.required.field')),
      lastName: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const request: UpdateMyProfileReq = {
        avatar: values.avatar,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        gender: values.gender,
        birthday: values.birthday,
        language: values.language,
        theme: values.theme,
        proUser: values.proUser,
      };

      updateMyProfile(request)
        .unwrap()
        .then((saveData) => {
          notification.success({
            message: t('profile.warning.update_success'),
            description: t('profile.warning.update_success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess(saveData);
          dispatch(updateUserInfo(saveData));
          dispatch(changeTheme(saveData.theme));
        })
        .catch(() => {
          notification.error({
            message: t('profile.warning.update_error'),
            description: t('profile.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  const updateMyProfileData = (myProfile: MyProfileRes) => {
    formik.setValues({
      avatar: myProfile.avatar,
      firstName: myProfile.firstName,
      lastName: myProfile.lastName,
      email: myProfile.email,
      phone: myProfile.phone,

      gender: myProfile.gender ?? '',
      birthday: myProfile.birthday ? myProfile.birthday : getCurrentDate(),
      language: myProfile.language
        ? myProfile.language.code
        : getDefaultLanguage(),
      theme: myProfile.theme ?? '',
      proUser: myProfile.proUser,
    });
  };

  const handleOnFileUploaded = (uploadedFile: string) => {
    formik.setFieldValue('avatar', uploadedFile);
  };

  const handleOnUploadHasErr = () => {
    console.log('has err when upload file....');
  };

  return (
    <>
      <H2>{t('profile.actions.update_info')}</H2>

      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col flex={ImageScale.avatar.width + 24 + 'px'}>
            <InputGroup label={t('label.avatar')}>
              <SingleUpload
                scale={ImageScale.avatar.scale}
                accept={'image/jpg, image/jpeg, image/png'}
                multiple={false}
                onSuccessFunc={handleOnFileUploaded}
                onErrorFunc={handleOnUploadHasErr}
              >
                <IconImage
                  src={formik.values.avatar}
                  width={ImageScale.avatar.width}
                  height={ImageScale.avatar.height}
                />
              </SingleUpload>
            </InputGroup>
          </Col>

          <Col flex={'auto'}>
            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={12}>
                <Input
                  required
                  name="firstName"
                  type="text"
                  label={t('label.firstName')}
                  placeholder={t('label.firstName')}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Input
                  required
                  name="lastName"
                  type="text"
                  label={t('label.lastName')}
                  placeholder={t('label.lastName')}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
              </Col>
            </Row>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={12}>
                <Input
                  name="email"
                  type="text"
                  label={t('label.email')}
                  placeholder={t('label.email')}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled={data && data.email != null && data.email !== ''}
                />
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Input
                  name="phone"
                  type="text"
                  label={t('label.phone')}
                  placeholder={t('label.phone')}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </Col>
            </Row>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={12}>
                <InputGroup label={t('profile.label.birthday')}>
                  <DatePicker
                    formik={formik}
                    value={formik.values.birthday}
                    name="birthday"
                    handleChange={formik.handleChange as any}
                  />
                </InputGroup>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <InputGroup label={t('profile.label.gender')}>
                  <Select
                    size={'large'}
                    placeholder={t('profile.label.gender')}
                    name={'gender'}
                    value={formik.values.gender}
                    onChange={(val) => formik.setFieldValue('gender', val)}
                    style={{ width: '100%' }}
                  >
                    <Option key={''} value={''}>
                      {t('profile.label.select_gender')}
                    </Option>

                    <Option key={'male'} value={'male'}>
                      {t('profile.label.male')}
                    </Option>

                    <Option key={'female'} value={'female'}>
                      {t('profile.label.female')}
                    </Option>
                  </Select>
                </InputGroup>
              </Col>
            </Row>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={24}>
                <Divider />
              </Col>
            </Row>

            <Row gutter={[Default_Gutter, Default_Gutter]}>
              <Col xs={24} sm={24} md={12}>
                <InputGroup label={t('profile.label.default_language')}>
                  <SelectLanguage
                    size={'large'}
                    placeholder={t('profile.label.default_language')}
                    value={formik.values.language}
                    onChange={(val) => formik.setFieldValue('language', val)}
                    style={{ width: '100%' }}
                  />
                </InputGroup>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <InputGroup label={t('profile.label.theme')}>
                  <SelectTheme
                    size={'large'}
                    placeholder={t('profile.label.theme')}
                    value={formik.values.theme}
                    onChange={(val) => formik.setFieldValue('theme', val)}
                    style={{ width: '100%' }}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
              <Row gutter={[Default_Gutter, Default_Gutter]}>
                <Col xs={24} sm={24} md={12}>
                  <Checkbox
                    name={'proUser'}
                    checked={formik.values.proUser}
                    value={formik.values.proUser}
                    formik={formik}
                    onChange={formik.handleChange}
                  >
                    Pro User
                  </Checkbox>
                </Col>
              </Row>
            </Authorization>

            <ButtonGroup className="submit-container" type={'right'}>
              <Button
                type={'primary'}
                shape={'round'}
                size={'large'}
                loading={isFetching || isLoading}
                onClick={formik.submitForm}
                icon={IconUtils.save}
              >
                {t('button.update')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormikProvider>
    </>
  );
}
