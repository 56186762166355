import React from 'react';
import { useSelector } from '@app/redux/hook';
import styled from 'styled-components';
import { RoleEnum } from '@app/redux/slices/roles';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { Space } from 'antd';
import { SimpleUserRes } from '@modules/users/services/model';

export const CurrentUserDisplay = () => {
  const { firstName, lastName, email, username } = useSelector(
    (state) => state.userInformation
  );

  return (
    <UserDisplayName
      username={username}
      firstName={firstName}
      lastName={lastName}
      email={email}
    />
  );
};

export const CurrentUserRolesDisplay = () => {
  const { authorities } = useSelector((state) => state.profile);
  return <PermissionDisplay layout={'inline'} permissions={authorities} />;
};

export const PermissionDisplay = (props: {
  layout: 'block' | 'inline';
  permissions: RoleEnum[];
}) => {
  const preventClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <UserPermissionStyle className={`user-permissions ${props.layout}`}>
      {props.permissions.map((role) => {
        return (
          <span className={'user-permission'} key={role} onClick={preventClick}>
            {role}
          </span>
        );
      })}
    </UserPermissionStyle>
  );
};

export const UserThumbnailDisplay = (props: {
  size?: number;
  layout?: 'column' | 'row';
  item: SimpleUserRes;
}) => {
  if (props.layout === 'column') {
    return (
      <div
        className={'user-n-thumbnails column-layout'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <UserAvatar src={props.item.avatar} size={props.size ?? 72} />
        <UserDisplay item={props.item} />
      </div>
    );
  } else {
    return (
      <Space align={'center'}>
        <UserAvatar src={props.item.avatar} size={props.size ?? 72} />
        <UserDisplay item={props.item} />
      </Space>
    );
  }
};

export const UserDisplay = (props: { item: SimpleUserRes }) => {
  return (
    <UserDisplayName
      username={props.item.username}
      firstName={props.item.firstName}
      lastName={props.item.lastName}
      email={props.item.email}
    />
  );
};

export const UserDisplayName = ({
  username,
  firstName,
  lastName,
  email,
}: {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}) => {
  if (firstName && lastName) {
    return (
      <span className={'user-display-name'}>
        {firstName} {lastName}
      </span>
    );
  } else if (email) {
    return <span className={'user-display-name'}>{email}</span>;
  } else {
    return <span className={'user-display-name'}>{username}</span>;
  }
};

const UserPermissionStyle = styled.div`
  text-align: center;

  &.block {
    .user-permission {
      display: block;
    }
  }

  &.inline {
    .user-permission {
      display: inline-block;
    }
  }

  .user-permission {
    margin: 0.125em;
    padding: 0.125em 0.75em;
    font-size: 10px;
    background: #ccc;
    color: #333;
    border-radius: 1em;
  }
`;
