import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  notification,
  Row,
  Select,
} from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import DatePicker from '@components/date-n-time/DatePicker';
import { SessionAPI } from '@services/private/SessionAPI';
import ContentEditor from '@components/editor/WysyEditor';
import TextWrap from '@components/text/TextWrap';
import NavLink from '@components/button/NavLink';
import TimePicker from '@components/date-n-time/CustomTimePicker';
import { Default_Gutter } from '@components/grid';
import { SiteMap } from '@router/SiteMap';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { SelectTeacher } from '@components/select/SelectTeacher';
import { IconUtils } from '@utils/IconUtils';
import { SessionRes } from '@services/model/session';
import { CreateSessionReq } from '@services/model/session_request';

export const ViewSession = (props: {
  selectedDate: string;
  sessionData: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onClose: (session: SessionRes | null) => void;
  onDidMount?: () => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.onDidMount) {
      props.onDidMount();
    }
  }, [props.onDidMount]);

  return (
    <div className={'view-session-group'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <InputGroup label={t('label.name')}>
            <InputValue>{props.sessionData.name}</InputValue>
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <InputGroup label={t('label.note')}>
            <InputValue type={'textarea'}>{props.sessionData.note}</InputValue>
          </InputGroup>
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('label.date')}>
            <DateAndTimeLabel label={props.sessionData.createdDate} />
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={t('label.hour')}>
            <InputValue>{props.sessionData.duration}</InputValue>
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('label.duration')}>
            <InputValue>{props.sessionData.duration}</InputValue>
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={t('session.max_student')}>
            <InputValue>{props.sessionData.maxStudent}</InputValue>
          </InputGroup>
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('session.public')}>
            <Checkbox disabled checked={props.sessionData.publicSession}>
              {t('session.warning.allow_register')}
            </Checkbox>
          </InputGroup>
        </Col>

        <Col span={12}>
          {props.sessionData && props.sessionData.meetingUrl && (
            <InputGroup label={'Zoom URL'}>
              <a href={props.sessionData.meetingUrl} target={'__blank'}>
                <TextWrap>{props.sessionData.meetingUrl}</TextWrap>
              </a>
            </InputGroup>
          )}
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <InputGroup label={t('label.secretCode')}>
            <InputValue type={'textarea'} copyable>
              {props.sessionData.secretCode}
            </InputValue>
          </InputGroup>
          <p>{t('class_room.warning.add_password')}</p>
        </Col>
      </Row>

      <ButtonGroup className="submit-container" type={'right'}>
        <Button
          type={'default'}
          shape={'round'}
          size={'large'}
          onClick={() => props.onClose(null)}
        >
          {t('button.cancel')}
        </Button>

        <NavLink
          href={SiteMap.class_room.class_room_gen(
            props.sessionData.code,
            '',
            'calendar'
          )}
        >
          <Button size={'large'} type={'primary'} shape={'round'}>
            {t('class_room.actions.join')}
          </Button>
        </NavLink>
      </ButtonGroup>
    </div>
  );
};

export default function CreateSessionForm(props: {
  selectedDate: string;
  userId?: number;
  sessionData?: SessionRes;
  warningResult: (isSuccess: boolean) => void;
  onClose: (session: SessionRes | null) => void;
  onDidMount?: () => void;
}) {
  const { t } = useTranslation();
  const [createSession, { isLoading: isCreateLoading }] =
    SessionAPI.endpoints.createSession.useMutation({});

  const [updateSession, { isLoading: isUpdateLoading }] =
    SessionAPI.endpoints.updateSession.useMutation({});

  const [note, setNote] = useState('');

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: '',
      note: '',

      date: '',
      time: '',

      duration: 45,
      maxStudent: 5,

      publicSession: false,
      secretCode: '',
      userId: props.userId ?? -1,
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      const payload: CreateSessionReq = {
        sessionId: props.sessionData ? props.sessionData.sessionId : -1,
        name: values.name,
        note: values.note,

        startDate: values.date + 'T' + values.time,

        duration: values.duration,
        maxStudent: values.maxStudent,

        publicSession: values.publicSession,
        secretCode: values.secretCode,
        userId: values.userId,
      };

      if (payload.sessionId && payload.sessionId > 0) {
        updateSessionEvent(payload);
      } else {
        createSessionEvent(payload);
      }
    },
  });

  useEffect(() => {
    if (props.onDidMount) {
      props.onDidMount();
    }
  }, []);

  useEffect(() => {
    if (props.sessionData) {
      formik.setValues({
        name: props.sessionData.name,
        note: props.sessionData.note,

        date: props.sessionData.startDate.split('T')[0],
        time: props.sessionData.startDate.split('T')[1],

        duration: props.sessionData.duration,
        maxStudent: props.sessionData.maxStudent,

        publicSession: props.sessionData.publicSession,
        secretCode: props.sessionData.secretCode,
        userId: props.sessionData.owner.userId,
      });

      setNote(props.sessionData.note);
    } else {
      const date = props.selectedDate.split('T')[0];
      const time = props.selectedDate.split('T')[1];

      formik.setFieldValue('date', date);
      formik.setFieldValue('time', time ?? '08:00:AM');
    }
  }, [props.sessionData, props.selectedDate]);

  const updateSessionEvent = (payload: CreateSessionReq) => {
    updateSession(payload)
      .unwrap()
      .then(() => {
        props.warningResult(true);
      })
      .catch(() => {
        props.warningResult(false);
      });
  };

  const createSessionEvent = (payload: CreateSessionReq) => {
    createSession(payload)
      .unwrap()
      .then((data) => {
        notification.success({
          message: t('session.warning.create_success'),
          description: t('session.warning.create_success_message'),
          placement: 'bottomRight',
        });
        props.onClose(data);
      })
      .catch(() => {
        notification.error({
          message: t('session.warning.create_error'),
          description: t('session.warning.create_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleEditorChange = (note: string) => {
    formik.setFieldValue('note', note);
  };

  return (
    <FormikProvider value={formik}>
      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
      >
        <SelectTeacher
          disabled={props.sessionData != null}
          value={formik.values.userId}
          onChange={(val) => formik.setFieldValue('userId', val)}
        />
        <Divider plain>Calendar Information</Divider>
      </Authorization>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            required={true}
            name="name"
            type="text"
            label={t('label.name')}
            placeholder={t('label.name')}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <InputGroup label={t('label.note')}>
            <ContentEditor initValue={note} onChange={handleEditorChange} />
          </InputGroup>
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('label.date')}>
            <DatePicker
              value={formik.values.date}
              name="date"
              formik={formik}
              handleChange={formik.handleChange as any}
            />
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={t('label.hour')}>
            <TimePicker
              value={formik.values.time}
              name="time"
              formik={formik}
              handleChange={formik.handleChange as any}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('label.duration')}>
            <Select
              size={'large'}
              placeholder={t('label.duration')}
              value={formik.values.duration}
              onChange={(value) => formik.setFieldValue('duration', value)}
            >
              {[30, 45, 60, 90, 120, 180].map((min) => (
                <Select.Option key={min} value={min}>
                  {min} {t('label.minutes')}
                </Select.Option>
              ))}
            </Select>
          </InputGroup>
        </Col>

        <Col span={12}>
          <InputGroup label={t('session.max_student')}>
            <Select
              size={'large'}
              placeholder={t('session.max_student_hint')}
              value={formik.values.maxStudent}
              onChange={(value) => formik.setFieldValue('maxStudent', value)}
            >
              {[1, 3, 5, 10, 15, 20, 30].map((st) => (
                <Select.Option key={st} value={st}>
                  {st} {t('label.student')}
                </Select.Option>
              ))}
            </Select>
          </InputGroup>
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputGroup label={t('session.public')}>
            <Checkbox
              checked={formik.values.publicSession}
              onChange={(e) =>
                formik.setFieldValue('publicSession', e.target.checked)
              }
            >
              {t('session.warning.allow_register')}
            </Checkbox>
          </InputGroup>
        </Col>

        <Col span={12}>
          {props.sessionData && props.sessionData.meetingUrl && (
            <InputGroup label={'Zoom URL'}>
              <a href={props.sessionData.meetingUrl} target={'__blank'}>
                <TextWrap>{props.sessionData.meetingUrl}</TextWrap>
              </a>
            </InputGroup>
          )}
        </Col>
      </Row>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <Input
            name="secretCode"
            type="text"
            label={t('label.secretCode')}
            placeholder={t('label.secretCode')}
            onChange={formik.handleChange}
            value={formik.values.secretCode}
          />
          <p>{t('class_room.warning.add_password')}</p>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup className="submit-container" type={'right'}>
            <Button
              type={'default'}
              shape={'round'}
              size={'large'}
              onClick={() => props.onClose(null)}
            >
              {t('button.cancel')}
            </Button>

            <Button
              loading={isCreateLoading || isUpdateLoading}
              type={'primary'}
              shape={'round'}
              size={'large'}
              onClick={formik.submitForm}
              icon={IconUtils.actions.save}
            >
              {!props.sessionData && t('button.create')}
              {props.sessionData && t('button.update')}
            </Button>

            {props.sessionData != null && props.sessionData.sessionId > 0 && (
              <NavLink
                href={SiteMap.class_room.class_room_gen(
                  props.sessionData.code,
                  '',
                  'calendar'
                )}
              >
                <Button
                  type={'primary'}
                  shape={'round'}
                  size={'large'}
                  style={{ float: 'right' }}
                  icon={IconUtils.class_room.join_classroom}
                >
                  {t('class_room.actions.join')}
                </Button>
              </NavLink>
            )}
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
}
