import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from '@app/redux/slices/roles';
import Authorization from '@utils/authorization';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { SchoolSettingManagementPage } from '@modules/setting/pages/school/SchoolSettingManagementPage';
import { PageHeader } from '@app/header/PageHeader';

const SchoolSetting = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'school-setting-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_SCHOOL}
        title={t('school_setting.header')}
        description={t('school_setting.header')}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.SCHOOL]}>
        <SchoolSettingManagementPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default SchoolSetting;
