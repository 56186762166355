import {
  Question2Skeleton,
  Question3Skeleton,
  QuestionSkeleton,
} from '@cms/design-template/lesson-placeholder/QuestionSkeleton';
import styled from 'styled-components';

export const SectionSkeleton = (props: {
  questionNumber: number;
  singleQuestionMode: boolean;
}) => {
  return (
    <SectionSkeletonStyle className={'section-skeleton-item'}>
      {props.singleQuestionMode ? (
        <>
          <QuestionSkeleton questionNumber={props.questionNumber} />
        </>
      ) : (
        <>
          <QuestionSkeleton questionNumber={props.questionNumber + 0} />
          <Question2Skeleton questionNumber={props.questionNumber + 1} />
          <Question3Skeleton questionNumber={props.questionNumber + 2} />
        </>
      )}
    </SectionSkeletonStyle>
  );
};

const SectionSkeletonStyle = styled.div`
  .question-skeleton-item {
    border: 4px solid ${(props) => props.theme.app.primary};
    background: ${(props) => props.theme.app.primary_bgr};

    &:not(:first-child) {
      margin-top: var(--cms-padding-question, 1.5em);
    }
  }
`;
