import { Col, Row } from 'antd';
import InputLabel from '@components/input/InputLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRes } from '@modules/users/services/model';
import { Default_Gutter } from '@components/grid';

export const StudentRegistrationInfo = (props: { item: UserRes }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputLabel
            label={t('label.firstName')}
            value={props.item.firstName}
          />
        </Col>

        <Col span={12}>
          <InputLabel label={t('label.lastName')} value={props.item.lastName} />
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <InputLabel label={t('label.email')} value={props.item.email} />
        </Col>

        <Col span={12}>
          <InputLabel label={t('label.phone')} value={props.item.phone} />
        </Col>
      </Row>
    </>
  );
};
