import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { useRouter } from '@hooks/useRouter';
import ViewClassPage from '@modules/users/pages/ViewClassPage';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';

const ViewClass = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { classId } = router.query as unknown as {
    classId: string;
  };

  return (
    <ManagementLayout className={'view-class-page'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_CLASS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('classes.my_class')}
        description={t('classes.my_class')}
      />
      <ViewClassPage classId={Number(classId)} />
    </ManagementLayout>
  );
};

export default ViewClass;
