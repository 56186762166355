import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import ManageAssignmentPage from '@modules/assignments/pages/MyTeacherAssignmentPage';

const ManageAssignment = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout
      className={'manage-assignment-page'}
      selectUser={'teacher'}
    >
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('assignment.title')}
        description={t('assignment.title')}
      />

      <ManageAssignmentPage />
    </ManagementLayout>
  );
};

export default ManageAssignment;
