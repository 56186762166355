import React, { useEffect, useState } from 'react';
import { MetadataAPI } from '@services/metadata/MetadataAPI';
import { SimpleSwitch } from '@components/input/SimpleSwitch';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { useTranslation } from 'react-i18next';
import { PublishGroupStyle } from '@modules/product/container/publish/style';
import env from '@utils/config';
import { SubjectRes } from '@modules/admin/service/model';

export default function PublishSubjectContainer(props: {
  ids: number[];
  onChange: (ids: number[]) => void;
}) {
  const { t } = useTranslation();

  const [ids, setIds] = useState<number[]>(() => {
    return props.ids;
  });

  const [findAllSubjects, { data }] =
    MetadataAPI.endpoints.findAllSubjects.useLazyQuery();

  useEffect(() => {
    findAllSubjects(env.CLIENT_ID);
  }, []);

  useEffect(() => {
    props.onChange(ids);
  }, [ids]);

  const handleSwitchChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setIds((prev) => {
        const newArr = [...prev];
        newArr.push(id);
        return newArr;
      });
    } else {
      setIds((prev) => {
        return [...prev].filter((item) => {
          return item !== id;
        });
      });
    }
  };

  return (
    <PublishGroupStyle className={'publish-group'}>
      <h2 className="welcome">{t('subject.header')}</h2>

      <div className={'checkbox-group'}>
        {data &&
          data.map((item: SubjectRes) => {
            return (
              <SimpleSwitch
                key={item.subjectId}
                value={item.subjectId}
                checked={ids.includes(item.subjectId)}
                onChange={handleSwitchChange}
              >
                <LanguageContentTag content={item.description} />
              </SimpleSwitch>
            );
          })}
      </div>
    </PublishGroupStyle>
  );
}
