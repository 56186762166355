import React from 'react';

import MySitePage from '@modules/setting/pages/CreateSchoolPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const PublicProfile = () => {
  const { t } = useTranslation();
  return (
    <ManagementLayout className={'public-profile-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_INFO}
        title={t('profile.header')}
        description={t('profile.header')}
      />
      <MySitePage />
    </ManagementLayout>
  );
};

export default PublicProfile;
