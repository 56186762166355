import { ResourceProps } from '@modules/product/components/resource/Resource';
import { TextContentProps } from '@cms/content/ContentType';

export enum SectionTypeEnum {
  presentation = 'presentation',
  fixed_group = 'fixed_group',
  one_column = 'one-column',
  two_column = 'two-column',
  three_column = 'three-column',
  interact_video = 'interact-video',
}

export interface LessonSectionProps {
  lessonSectionId: number;
  code: string;
  name: string;
  type: SectionTypeEnum;
  setting: Record<string, any>;

  content?: {
    header: TextContentProps;
    footer: TextContentProps;
  };

  group: {
    header?: ResourceProps[];
    content?: ResourceProps[];
    footer?: ResourceProps[];
  };
}
