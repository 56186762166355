import { CompAnswerProps, CompMode, CompProps } from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import { VideoContentProps } from '@cms/content/ContentType';
import styled from 'styled-components';

import { AssetsRes } from '@modules/product/services/assets_model';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { updateComponent } from '@app/redux/slices/composeResource';
import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { useSectionEventContext } from '@cms/section-bank/context/SectionGroupContext';
import { CompActionEnum } from '@cms/utils/CompProps';
import { VideoPlayer } from '@cms/content/VideoViewer';

export interface VideoCompProps extends CompProps {
  configuration: {
    content: VideoContentProps;
  };
}

export function VideoComp(props: {
  item: VideoCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();
  const { event, dispatchComponentEvent } = useSectionEventContext();

  const [videoAction, setVideoAction] = useState({
    action: CompActionEnum.default,
    params: -1,
  });

  useEffect(() => {
    if (event.compId === props.item.id) {
      if (event.eventType === CompActionEnum.video_pause) {
        setVideoAction({
          action: CompActionEnum.video_pause,
          params: event.params,
        });
      } else if (event.eventType === CompActionEnum.video_resume) {
        setVideoAction({
          action: CompActionEnum.video_resume,
          params: event.params,
        });
      }
    }
  }, [event]);

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: AssetsRes) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <VideoCompStyle
        className={`comp comp-video ${props.item.setting?.className}`}
      >
        <ContentViewerEditable
          contentData={props.item.configuration.content}
          onChange={handleOnChange}
        />
      </VideoCompStyle>
    );
  } else {
    const handleOnChange = (time: number) => {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: time,
      });
      dispatchComponentEvent(props.item.id, CompActionEnum.video_play, time);
    };

    return (
      <VideoCompStyle
        className={`comp comp-video ${props.item.setting?.className}`}
      >
        <VideoPlayer
          action={videoAction}
          videoSrc={props.item.configuration.content.data}
          title={props.item.configuration.content.name}
          onChange={handleOnChange}
        />
      </VideoCompStyle>
    );
  }
}

const VideoCompStyle = styled.div`
  width: 100%;

  video {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 700px;
  }
`;
