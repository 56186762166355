import styled from 'styled-components';

export const StyledInput = styled.div`
  margin-bottom: 1.5em;

  .screen-xs & {
    margin-bottom: 1em;
  }

  .screen-sm & {
    margin-bottom: 1em;
  }

  .screen-md & {
    margin-bottom: 1.25em;
  }

  .required-character {
    color: ${(props) => props.theme.warning.error};
    display: inline-flex;
    margin-left: 3px;
    transform: translate(0%, -3px);
  }

  &.single {
    margin-bottom: 0px !important;
  }

  &.small-group {
    margin-top: 0.5em;

    .ant-form-label {
      font-size: 9px;
      margin-bottom: 0;
    }
  }

  .ant-form-label {
    color: #5a5a5a;
    font-size: 10px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4px;

    .default-text {
      text-transform: inherit;
    }
  }

  .input-label {
    color: #000;
  }

  .ant-input,
  .ant-input-password {
    //border-radius: 0;

    &:focus {
      box-shadow: none;
      border-color: ${(props) => props.theme.app.primary};
    }
  }

  .ant-input-password {
  }

  .ant-input-affix-wrapper-textarea-with-clear-btn {
    textarea.ant-input {
      padding-right: 20px;
    }
  }

  .ant-input-textarea-show-count::after {
    font-size: 80%;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      .ant-select-selection-item {
      }
    }

    .ant-select-selector .ant-select-selection-item {
      .ant-space {
        .ant-space-item {
          height: 38px;
        }
      }
    }
  }

  .input-value-display {
    color: #000;
    position: relative;

    .ant-typography-copy {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translate(0%, -50%);
    }

    &:empty {
      &:before {
        content: ' ';
        display: inline-block;
      }
    }

    &.textarea {
      min-height: 2em;
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid #ccc;
      border-radius: 0.25em;
      padding: 0.5em;
    }
  }

  .error-message {
    margin-top: 3px;
    font-size: 12px;
    color: ${(props) => props.theme.warning.error};
  }

  input[readonly] {
    color: #ccc;
  }
`;

export const ErrMessageStyle = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.warning.error};
  text-transform: none;
  font-weight: 600;
  font-size: 12px;
`;
