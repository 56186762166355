import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';

import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import SchoolSettingForm from '@modules/setting/container/school/SchoolSettingForm';

export const SchoolSettingManagementPage = () => {
  const { t } = useTranslation();

  const onSuccess = () => {
    // do nothing...
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('school_setting.header')}</H1>
      </HeaderGroup>

      <SchoolSettingManagementStyle>
        <SchoolSettingForm onSuccess={onSuccess} />
      </SchoolSettingManagementStyle>
    </>
  );
};

const SchoolSettingManagementStyle = styled.div`
  font-size: 16px;
`;
