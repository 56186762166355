import React from 'react';

import { useRouter } from '@hooks/useRouter';
import GradeAssignmentPage from '@modules/assignments/pages/GradeAssignmentPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const GradeAssignment = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: assignmentInstanceId, exerciseId } = router.query as unknown as {
    id: number;
    exerciseId?: number;
  };

  return (
    <EmptyLayout className={'assignment-grading-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ASSIGNMENTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('grade_assignment.header')}
        description={t('grade_assignment.header')}
      />

      <GradeAssignmentPage
        assignmentInstanceId={assignmentInstanceId}
        exerciseId={exerciseId}
      />
    </EmptyLayout>
  );
};

export default GradeAssignment;
