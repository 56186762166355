import React from 'react';

import MyCoursePage from '@modules/course/pages/MyCoursePage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const ManageCourse = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-course-page'}>
      <PageHeader
        menu={NavigationMenuEnum.COURSES}
        subMenu={NavigationMenuEnum.COURSES_COURSES}
        title={t('course.header')}
        description={t('course.header')}
      />

      <MyCoursePage />
    </ManagementLayout>
  );
};

export default ManageCourse;
