import { CompProps, CompMode, CompAnswerProps } from '@cms/ComponentInteface';
import React from 'react';
import styled from 'styled-components';
import { AssetsContentProps } from '@cms/content/ContentType';

import { AssetsRes } from '@modules/product/services/assets_model';
import {
  ContentViewer,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { useDispatch } from 'react-redux';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useResourceContext } from '@cms/context/ResourceContextProvider';
import { updateComponent } from '@app/redux/slices/composeResource';

export interface ImageCompProps extends CompProps {
  configuration: {
    content: AssetsContentProps;
  };
}

export function ImageComp(props: {
  item: ImageCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const { mode } = useResourceContext();

  if (mode === CompMode.COMPOSE) {
    const handleOnChange = (data: AssetsRes) => {
      const newData = {
        ...props.item,

        configuration: {
          content: data,
        },
      };
      dispatch(updateComponent(newData));
    };

    return (
      <ImgCompStyle
        className={`comp comp-image ${props.item.setting?.className}`}
        style={getContainerStyle(props.item.configuration.content.align)}
      >
        <ContentViewerEditable
          contentData={props.item.configuration.content}
          onChange={handleOnChange}
        />
      </ImgCompStyle>
    );
  } else {
    return (
      <ImgCompStyle
        className={`comp comp-image ${props.item.setting?.className}`}
        style={getContainerStyle(props.item.configuration.content.align)}
      >
        <ContentViewer contentData={props.item.configuration.content} />
      </ImgCompStyle>
    );
  }
}

const ImgCompStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img.image-component {
    max-width: 100%;
    height: auto;
    position: relative;
    border: 1px solid #ccc;
  }
`;

const getContainerStyle = (align?: string) => {
  const style: Record<string, any> = {};

  if (align) {
    if (align === 'left') {
      style['justifyContent'] = 'flex-start';
    } else if (align === 'right') {
      style['justifyContent'] = 'flex-end';
    }
  }

  return style;
};
