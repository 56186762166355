import React, { ReactNode } from 'react';
import Button from '@components/button';
import styled from 'styled-components';
import {
  BgColorsOutlined,
  PlusOutlined,
  FontSizeOutlined,
  LineOutlined,
  DeleteOutlined,
  BorderOutlined,
  SendOutlined,
  SyncOutlined,
  SmallDashOutlined,
  DashOutlined,
  ArrowRightOutlined,
  ExpandAltOutlined,
  SubnodeOutlined,
  ScanOutlined,
  NodeIndexOutlined,
  HighlightOutlined,
  Loading3QuartersOutlined,
  EditOutlined,
  ExportOutlined,
  // FileImageOutlined,
} from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import { CoordinateActionEnum } from '@cms/comps/interact/coordinate/CoordinateEnum';

export interface CoordinateActionGroupProps {
  group: string;
  icon: ReactNode;
  actions: {
    action: CoordinateActionEnum;
    icon: ReactNode;
  }[];
}

export const coordinateActionGroups: CoordinateActionGroupProps[] = [
  {
    group: 'select',
    icon: <SendOutlined />,
    actions: [{ action: CoordinateActionEnum.SELECT, icon: <SendOutlined /> }],
  },

  {
    group: 'point',
    icon: <PlusOutlined />,
    actions: [
      {
        action: CoordinateActionEnum.POINT,
        icon: <PlusOutlined />,
      },
    ],
  },
  {
    group: 'connect-line',
    icon: <SubnodeOutlined />,
    actions: [
      {
        action: CoordinateActionEnum.CONNECT_LINE,
        icon: <SubnodeOutlined />,
      },
      {
        action: CoordinateActionEnum.POLYGON,
        icon: <BorderOutlined />,
      },
    ],
  },
  {
    group: 'line',
    icon: <LineOutlined />,
    actions: [
      { action: CoordinateActionEnum.LINE, icon: <LineOutlined /> },
      { action: CoordinateActionEnum.VECTOR, icon: <ArrowRightOutlined /> },
      { action: CoordinateActionEnum.LINEAR, icon: <ExpandAltOutlined /> },
      { action: CoordinateActionEnum.DOT_LINE, icon: <SmallDashOutlined /> },
      { action: CoordinateActionEnum.DASH_LINE, icon: <DashOutlined /> },
    ],
  },
  {
    group: 'shape',
    icon: <ScanOutlined />,
    actions: [
      {
        action: CoordinateActionEnum.ELLIPSE,
        icon: <Loading3QuartersOutlined />,
      },
      {
        action: CoordinateActionEnum.CIRCLE,
        icon: <Loading3QuartersOutlined />,
      },
      { action: CoordinateActionEnum.RECTANGLE, icon: <BorderOutlined /> },
    ],
  },
  {
    group: 'text',
    icon: <NodeIndexOutlined />,
    actions: [
      { action: CoordinateActionEnum.PENCIL, icon: <EditOutlined /> },
      { action: CoordinateActionEnum.HIGHLIGHT, icon: <HighlightOutlined /> },
      { action: CoordinateActionEnum.TEXT, icon: <FontSizeOutlined /> },
      // { action: CoordinateActionEnum.IMAGE, icon: <FileImageOutlined /> },
    ],
  },

  {
    group: 'change-color',
    icon: <BgColorsOutlined />,
    actions: [
      { action: CoordinateActionEnum.CHANGE_COLOR, icon: <BgColorsOutlined /> },
    ],
  },

  {
    group: 'delete',
    icon: <DeleteOutlined />,
    actions: [
      { action: CoordinateActionEnum.REMOVE, icon: <DeleteOutlined /> },
    ],
  },

  {
    group: 'reset',
    icon: <SyncOutlined />,
    actions: [
      { action: CoordinateActionEnum.RESET, icon: <SyncOutlined /> },
      { action: CoordinateActionEnum.EXPORT, icon: <ExportOutlined /> },
    ],
  },
];

export const CoordinateActionGroup = (props: {
  action: CoordinateActionEnum;
  item: CoordinateActionGroupProps;
  onClick: (act: CoordinateActionEnum, params: any) => void;
}) => {
  return (
    <CoordinateActionGroupStyle className={'coordinate-action-group'}>
      {props.item.actions.length === 1 ? (
        <Button
          key={props.item.group}
          type={
            props.item.actions[0].action === props.action
              ? 'primary'
              : 'default'
          }
          shape={'default'}
          block
          onClick={() => props.onClick(props.item.actions[0].action, null)}
        >
          {props.item.actions[0].icon}
        </Button>
      ) : (
        <Popover
          content={
            <CoordinateGroupStyle className={'coordinate-action-group-items'}>
              {props.item.actions.map((act) => {
                return (
                  <Button
                    key={act.action}
                    type={act.action === props.action ? 'primary' : 'default'}
                    shape={'default'}
                    block
                    onClick={() => props.onClick(act.action, null)}
                  >
                    {act.icon}
                  </Button>
                );
              })}
            </CoordinateGroupStyle>
          }
          placement={'left'}
          trigger="click"
        >
          <Button
            key={props.item.group}
            shape={'default'}
            block
            type={
              props.item.actions.some((act) => {
                return act.action === props.action;
              })
                ? 'primary'
                : 'default'
            }
          >
            {props.item.actions[0].icon}
          </Button>
        </Popover>
      )}
    </CoordinateActionGroupStyle>
  );
};

const CoordinateActionGroupStyle = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.25em;
  }

  .ant-btn {
    font-size: inherit !important;
    height: auto !important;
    padding: 0.125em 0.5em;

    &:not(:first-child) {
      margin-top: 0.25em;
    }
  }
`;

const CoordinateGroupStyle = styled.div`
  display: flex;
  flex-direction: column;

  .ant-btn {
    &:not(:first-child) {
      margin-top: 0.25em;
    }
  }
`;
