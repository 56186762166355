import React, { useEffect, useState } from 'react';

import { PublicLessonRes } from '@services/publish/PublishSchool';
import { LessonDetailPage } from '@modules/public/pages/ViewLessonDetailPage';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { useRouter } from '@hooks/useRouter';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { PageHeader } from '@app/header/PageHeader';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { SiteMap } from '@router/SiteMap';
import { AnonymousDoExerciseAPI } from '@modules/assignments/service/AnonymousDoExerciseAPI';

interface ViewProductLessonParams {
  productId: string;
  productName: string;
  lessonId: string;
  lessonName: string;
  productTocId: string;
}

const ViewProductLesson = () => {
  const router = useRouter();

  const { productId, lessonId, productTocId } =
    router.query as unknown as ViewProductLessonParams;

  const [viewProductLesson, { data, isSuccess, error, isError }] =
    PublicPageAPI.endpoints.viewProductLesson.useLazyQuery();

  const [createExercise] =
    AnonymousDoExerciseAPI.endpoints.createExercise.useMutation();

  const [exerciseId, setExerciseId] = useState(-1);
  const [lsData, setLsData] = useState<PublicLessonRes | null>(null);

  useEffect(() => {
    viewProductLesson({
      productId: productId,
      lessonId: lessonId,
      productTocId: productTocId ?? -1,
    });
  }, [productId, lessonId, productTocId]);

  useEffect(() => {
    if (data && isSuccess) {
      setLsData(data);
      onStartExercise(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  const onStartExercise = (data: PublicLessonRes) => {
    if (data.lesson.validation != null && data.lesson.validation.interact) {
      createExercise({
        lessonId: data.lesson.lessonId,
        productId: productId ? Number(productId) : -1,
        productTocId: productTocId ? Number(productTocId) : -1,
      })
        .unwrap()
        .then((exercise) => {
          setExerciseId(exercise.exerciseId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setExerciseId(0);
    }
  };

  return (
    <EmptyLayout className={'view-lesson-product-page'}>
      {lsData && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_PRODUCTS}
            subMenu={NavigationMenuEnum.EMPTY}
            title={lsData.lesson.keyword}
            description={lsData.lesson.description}
          />

          {exerciseId >= 0 && (
            <LessonDetailPage
              exerciseId={exerciseId}
              productTocId={lsData.productTocId}
              lesson={lsData.lesson}
              free={lsData.free}
              product={lsData.product}
              school={lsData.school}
              author={lsData.author}
              tableOfContent={lsData.tableOfContent}
            />
          )}
        </>
      )}
    </EmptyLayout>
  );
};

export default ViewProductLesson;
