import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import LessonManageForm from '@modules/product/container/LessonManageForm';
import React, { useEffect, useMemo, useState } from 'react';
import { LessonActionEnum } from '@modules/product/container/actions';
import { useLanguage } from '@hooks/useLanguage';
import LessonExampleEditForm from '@modules/product/container/lesson-example/LessonExampleEditForm';

export const LessonActionHandleTeacher = ({
  action,
  handleOnCancel,
}: {
  action: { action: LessonActionEnum; params: number };
  handleOnCancel: () => void;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  const [activeTab, setActiveTabs] = useState<LessonActionEnum>(
    LessonActionEnum.EDIT_METADATA
  );

  useEffect(() => {
    setActiveTabs(action.action);
  }, [action.action]);

  const handleOnChangeTabs = (type: LessonActionEnum) => {
    setActiveTabs(type);
  };

  const headerModal = useMemo(() => {
    if (activeTab === LessonActionEnum.EDIT_METADATA) {
      return t('lesson.actions.update_metadata');
    } else if (activeTab === LessonActionEnum.SHARE_LESSON) {
      return t('lesson.actions.share_to_student');
    } else if (activeTab === LessonActionEnum.INVITE_STUDENT) {
      return t('lesson.actions.invite_students');
    } else if (activeTab === LessonActionEnum.LINK_TO_EXAMPLE) {
      return 'Link To Example';
    } else {
      return '';
    }
  }, [activeTab, language]);

  return (
    <>
      {(activeTab === LessonActionEnum.SHARE_LESSON ||
        activeTab === LessonActionEnum.EDIT_METADATA ||
        activeTab === LessonActionEnum.INVITE_STUDENT) && (
        <CustomModal
          header={headerModal}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <LessonManageForm
              lessonId={action.params}
              type={activeTab}
              onSuccess={() => {}}
              onClose={handleOnCancel}
              handleOnChangeTab={handleOnChangeTabs}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {activeTab === LessonActionEnum.LINK_TO_EXAMPLE && (
        <CustomModal
          header={'Link To Example'}
          className={CustomModalClassEnum.full_size_modal}
          content={
            <LessonExampleEditForm
              lessonId={action.params}
              onSuccess={handleOnCancel}
              onClose={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
};
