import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { UserRes } from '@modules/users/services/model';
import { CreateUserReq } from '@modules/users/services/StudentAPI';
import { notification } from 'antd';
import { CreateUserForm } from '@modules/users/component/users/CreateUserForm';
import { UserSchoolAdminAPI } from '@modules/users/services/UserSchoolAdminAPI';

export const CreateSchoolAdminUser = (props: {
  schoolId?: number;
  userId?: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [getUserData, { data, isSuccess, isLoading }] =
    UserSchoolAdminAPI.endpoints.getUserData.useLazyQuery();

  const [createUser, { isLoading: isCreateLoading }] =
    UserSchoolAdminAPI.endpoints.createUser.useMutation({});

  const [updateUser, { isLoading: isUpdateLoading }] =
    UserSchoolAdminAPI.endpoints.updateUser.useMutation({});

  const [userData, setUserData] = useState<UserRes | null>(null);

  useEffect(() => {
    if (props.userId != null && props.userId > 0) {
      getUserData(props.userId);
    }
  }, [props.userId]);

  useEffect(() => {
    if (data && isSuccess) {
      setUserData(data);
    }
  }, [data]);

  const handleOnSubmit = (values: CreateUserReq) => {
    if (props.userId != null && props.userId > 0) {
      updateUser(values)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('school_admin.warning.update_success'),
            description: t('school_admin.warning.update_success_message'),
            placement: 'bottomRight',
          });
          props.onSuccess();
        })
        .catch(() => {
          notification.error({
            message: t('school_admin.warning.update_error'),
            description: t('school_admin.warning.update_error_message'),
            placement: 'bottomRight',
          });
        });
    } else {
      createUser(values)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('school_admin.warning.add_success'),
            description: t('school_admin.warning.add_success_message'),
            placement: 'bottomRight',
          });
          props.onSuccess();
        })
        .catch(() => {
          notification.error({
            message: t('school_admin.warning.add_error'),
            description: t('school_admin.warning.add_error_message'),
            placement: 'bottomRight',
          });
        });
    }
  };
  return (
    <CreateUserForm
      loading={isLoading || isCreateLoading || isUpdateLoading}
      schoolId={props.schoolId}
      userId={props.userId}
      userData={userData}
      onCancel={props.onCancel}
      onSubmit={handleOnSubmit}
    />
  );
};
