import React, { useState } from 'react';
import { Button, notification } from 'antd';

import ButtonGroup from '@components/button/ButtonGroup';
import { BecomeProUserReq, MyProfileAPI } from '@services/private/MyProfileAPI';
import { useDispatch } from 'react-redux';
import { H2, H4 } from '@components/typography';
import { useTranslation } from 'react-i18next';
import { updateProUser } from '@app/redux/slices/userInfomationSlice';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { Carousel } from '@components/carousel';
import useConfirmModal from '@components/modal/ConfirmModal';
import { useProUser } from '@components/button/RequireProUserButton';
import Alert from '@components/alert';

export default function BecomeProUserForm() {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const dispatch = useDispatch();
  const proUser = useProUser();

  const [becomeProUser, { isLoading }] =
    MyProfileAPI.endpoints.becomeProUser.useMutation({});

  const [discover, setDiscover] = useState(false);

  const handleDiscovery = () => {
    setDiscover(true);
  };

  const changeToNormalUser = () => {
    confirm(
      'warning',
      t('header.confirm'),
      'Bạn có muốn thay đổi giao diện về loại cơ bản? Một số tính năng sẽ bị ẩn.',
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          handleBecomeProUser(false);
        }
      }
    );
  };

  const changeToProUser = () => {
    handleBecomeProUser(true);
  };

  const handleBecomeProUser = (proUser: boolean) => {
    const request: BecomeProUserReq = {
      proUser: proUser,
    };

    becomeProUser(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('profile.warning.update_success'),
          description: t('profile.warning.update_success_message'),
          placement: 'bottomRight',
        });

        dispatch(updateProUser(request.proUser));
        handleOnCancel();
      })
      .catch(() => {
        notification.error({
          message: t('profile.warning.update_error'),
          description: t('profile.warning.update_error_message'),
          placement: 'bottomRight',
        });
      });
  };

  const handleOnCancel = () => {
    setDiscover(false);
  };

  return (
    <div className={'become-pro-user'}>
      {proUser ? (
        <>
          <H2>Change to simple layout</H2>

          <Alert
            message={<H4>Quay trở lại giao diện người dùng</H4>}
            description={
              <div>
                <p>
                  Với xác nhận này, bạn sẽ chuyển đổi sang giao diện mặc định
                  của hệ thống.
                </p>

                <p>
                  Các tính năng phức tạp của hệ thống được gỡ bỏ và các tính
                  năng đặc biệt sẽ bị hạn chế truy cập.
                </p>

                <p>
                  Bạn có thể truy cập vào đây và chuyển sang giao diện chuyên
                  nghiệp bất cứ lúc nào.
                </p>
              </div>
            }
            type="info"
          />

          <ButtonGroup type={'center'}>
            <Button
              type={'primary'}
              shape={'round'}
              size={'large'}
              danger
              loading={isLoading}
              onClick={changeToNormalUser}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <div>
          <H2>{t('profile.actions.become_pro_user')}</H2>

          <Alert
            message={<H4>Trở thành người dùng chuyên nghiệp.</H4>}
            description={
              <div>
                <p>
                  Hệ thống được thiết kế để phù hợp với tất cả mọi người. Vì vậy
                  các tính năng của nó không thể quá phức tạp.
                </p>

                <p>
                  Hệ thống đã ẩn các chức năng nâng cao được xây dựng để phục vụ
                  cho người dùng chuyên nghiệp. Bạn có thể truy cập vào đây và
                  mở khóa các tính năng này.
                </p>

                <p>
                  Bạn có thể truy cập vào đây và khôi phục lại như ban đầu nếu
                  muốn.
                </p>
              </div>
            }
            type="info"
          />

          <ButtonGroup type={'center'}>
            <Button
              onClick={handleDiscovery}
              type={'primary'}
              shape={'round'}
              size={'large'}
              loading={isLoading}
            >
              Khám phá
            </Button>
          </ButtonGroup>
        </div>
      )}

      {discover && (
        <CustomModal
          header={'Trở thành người dùng chuyên nghiệp'}
          className={CustomModalClassEnum.medium_modal}
          content={
            <Carousel
              dotPosition={'bottom'}
              items={[
                {
                  header: 'Tùy chỉnh giao diện bài học',
                  content: (
                    <div className={'carousel-slide-01'}>
                      <p>
                        Tạo bài học từ thư viện nội dung thay vì từ mẫu bài học
                      </p>
                    </div>
                  ),
                },

                {
                  header: 'Thay đổi điểm số mặc định',
                  content: (
                    <div className={'carousel-slide-02'}>
                      <p>Điều chỉnh điểm số cho từng câu hỏi</p>
                      <p>chuyển từ chế độ chấm tự động sang chấm thủ công.</p>
                    </div>
                  ),
                },

                {
                  header:
                    'Thêm kĩ năng cho câu hỏi và mở khóa báo cáo theo kĩ năng',
                  content: (
                    <div className={'carousel-slide-03'}>
                      <p>
                        Mở khóa Standard Set và cho phép liên kết câu hỏi tới
                        các kĩ năng này
                      </p>
                      <p>
                        Khi có standard, thì khi xem kết quả câu hỏi có thể thấy
                        các Standard Reports.
                      </p>
                    </div>
                  ),
                },

                {
                  header: 'Xây dựng câu hỏi từ thư viện',
                  content: (
                    <div className={'carousel-slide-04'}>
                      <p>
                        Sử dụng lại các câu hỏi bằng chức năng Build Resource
                      </p>
                    </div>
                  ),
                },

                {
                  header: 'Chức năng xây dựng bài học nâng cao',
                  content: (
                    <div className={'carousel-slide-05'}>
                      <p>Mở khóa Mãu bài học.</p>
                      <p>
                        Mẫu bài học quy đinh cách hiển thị của từng câu hỏi
                        trong bài học và hành vi của bài học.
                      </p>
                      <p>
                        Một bài học bao gồm nhiều Section, giáo viên có thể lựa
                        chọn xem toàn bộ section, xem từng section, hoặc hiển
                        thị từng câu hỏi.
                      </p>
                      <p>Xây dựng Section từ các câu hỏi đang có.</p>
                    </div>
                  ),
                },

                {
                  header:
                    'Tôi muốn chuyển sang giao diện người dùng chuyên nghiệp',
                  content: (
                    <div
                      className={'carousel-slide-06'}
                      style={{ marginTop: '3em' }}
                    >
                      <ButtonGroup>
                        <Button
                          onClick={changeToProUser}
                          type={'primary'}
                          shape={'round'}
                          loading={isLoading}
                        >
                          {t('profile.actions.become_pro_user')}
                        </Button>
                      </ButtonGroup>
                    </div>
                  ),
                },
              ]}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </div>
  );
}
