export const ImageScale = {
  program: {
    ratio: '150 / 150',
    scale: 150 / 150,
    width: 150,
    height: 150,
    icon: 32,
    iconLarge: 90,
  },

  certificate: {
    ratio: '150 / 150',
    scale: 150 / 150,
    width: 150,
    height: 150,
    icon: 32,
    iconLarge: 90,
  },

  product: {
    ratio: '150 / 150',
    scale: 150 / 150,
    width: 150,
    height: 150,
    icon: 32,
    iconLarge: 90,
  },

  lesson: {
    ratio: '150 / 150',
    scale: 150 / 150,
    icon: 32,
    width: 150,
    height: 150,
  },

  course: {
    ratio: '16 / 9',
    scale: 9 / 16,
    width: 160 * 2,
    height: 90 * 2,
  },

  avatar: {
    ratio: '1 / 1',
    scale: 1,
    width: 150,
    height: 150,
  },

  scale169: {
    ratio: '16 / 9',
    scale: 9 / 16,
    width: 1600,
    height: 900,
  },
};
