import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LessonTemplateUtil } from '@modules/product/components/lesson/Config';
import useMediaQuery from '@hooks/useMedia';

export function LessonA4TemplateEditable(props: {
  name?: string | ReactNode;
  children: any;
}) {
  const screen = useMediaQuery();
  const ctnRef = useRef(null);

  const [style, setStyle] = useState({});
  const [containerSize, setContainerSize] = useState(0);

  useEffect(() => {
    if (ctnRef.current != null) {
      // @ts-ignore
      setContainerSize(ctnRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (ctnRef.current != null) {
        // @ts-ignore
        setContainerSize(ctnRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (containerSize > 0) {
      setStyle(LessonTemplateUtil.A4.getStyle(containerSize, screen));
    }
  }, [containerSize]);

  return (
    <LessonA4TemplateStyle
      className={'lesson-template lesson-a4-template'}
      ref={ctnRef}
      style={style}
    >
      {props.name && <h1 className={'lesson-title'}>{props.name}</h1>}

      <div className={'lesson-resources'}>{props.children}</div>
    </LessonA4TemplateStyle>
  );
}

const LessonA4TemplateStyle = styled.div`
  .lesson-title {
    font-size: 150%;
    font-weight: 100;
    line-height: 1.6;

    .ant-input {
      font-size: inherit;
      line-height: 1;
    }
  }

  .lesson-subtitle {
    font-size: 100%;
    font-weight: 100;
    line-height: 1.6;

    .ant-input {
      font-size: inherit;
      line-height: 1;
    }
  }

  .lesson-resources {
    line-height: 1.6;
    margin-top: 1em;
  }

  .question-number {
    font-size: 1em;
    font-weight: bold;
  }

  &.view-mode {
    .lesson-resources {
      .question-item {
        margin-bottom: 1.5em;
      }
    }
  }
`;
