import React, { useEffect, useState } from 'react';

import { Button, notification, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';

import {
  CreateLessonTemplateReq,
  LessonTemplateAPI,
  LessonTemplateRes,
} from '@modules/product/services/LessonTemplateAPI';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';

const initialValue: CreateLessonTemplateReq = {
  lessonTemplateId: -1,
  name: '',
  description: '',
};

export function EditLessonTemplateForm(props: {
  lessonTemplateId: number;
  successHandle: (data: LessonTemplateRes) => void;
  onCloseFunc: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isFetching }] =
    LessonTemplateAPI.endpoints.findById.useLazyQuery();
  const [createItem, { isLoading: isAddLoading }] =
    LessonTemplateAPI.endpoints.createItem.useMutation({});
  const [updateItem, { isLoading: isUpdateLoading }] =
    LessonTemplateAPI.endpoints.updateItem.useMutation({});

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.lessonTemplateId > 0) {
      findById(props.lessonTemplateId);
    } else {
      setIsReady(true);
    }
  }, [props.lessonTemplateId]);

  useEffect(() => {
    if (data && isSuccess) {
      setFormData(data);
      setIsReady(true);
    }
  }, [data]);

  const setFormData = (data: LessonTemplateRes) => {
    formik.setValues({
      lessonTemplateId: data.lessonTemplateId,
      name: data.name,
      description: data.description,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initialValue,

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.lessonTemplateId > 0) {
        updateItem(values)
          .unwrap()
          .then((res) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });

            props.successHandle(res);
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        createItem(values)
          .unwrap()
          .then((res) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });

            props.successHandle(res);
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <Input
                required={true}
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <Input
                name="description"
                type="text"
                label={t('label.description')}
                placeholder={t('label.description')}
                onChange={formik.handleChange}
                value={formik.values.description}
              />

              <ButtonGroup className="submit-container" type={'right'}>
                <Button
                  type="default"
                  size={'large'}
                  shape={'round'}
                  onClick={props.onCloseFunc}
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  type={'primary'}
                  loading={isFetching || isAddLoading || isUpdateLoading}
                  onClick={formik.submitForm}
                  size={'large'}
                  shape={'round'}
                  icon={IconUtils.actions.save}
                >
                  {props.lessonTemplateId > 0 ? (
                    <>{t('button.update')}</>
                  ) : (
                    <>{t('button.create')}</>
                  )}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
