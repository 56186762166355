import { FormikProvider, useFormik } from 'formik';
import { RoleEnum } from '@app/redux/slices/roles';
import { Button } from 'antd';
import { HintText } from '@components/text/HintText';
import { PermissionCheckBox } from '@modules/users/component/permission/PermissionCheckBox';
import ButtonGroup from '@components/button/ButtonGroup';
import { IconUtils } from '@utils/IconUtils';
import React, { useEffect } from 'react';
import { PermissionTableStyle } from '@modules/users/component/permission/PermissionTableForm';
import { useTranslation } from 'react-i18next';
import Alert from '@components/alert';
import { SchoolSettingAPI } from '@services/private/SchoolSettingAPI';
import { H2 } from '@components/typography';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';

export const SchoolRoleAndRegistration = (props: {
  schoolId?: number;
  onSuccess: () => void;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();

  const [getDefaultRoles, { data, isFetching, isSuccess }] =
    SchoolSettingAPI.endpoints.getDefaultRoles.useLazyQuery();

  const [updateDefaultRoles, { isLoading }] =
    SchoolSettingAPI.endpoints.updateDefaultRoles.useMutation({});

  useEffect(() => {
    getDefaultRoles({});
  }, []);

  useEffect(() => {
    if (data && isSuccess) {
      formik.setFieldValue('permissions', data);
    }
  }, [data]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      permissions: [] as RoleEnum[],
    },

    onSubmit: (values) => {
      onUpdatePermission(values.permissions);
    },
  });

  const onUpdatePermission = (roles: RoleEnum[]) => {
    updateDefaultRoles(roles)
      .unwrap()
      .then(() => {
        props.onSuccess();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (value: RoleEnum, activated: boolean) => {
    if (activated) {
      formik.setFieldValue('permissions', [
        ...formik.values.permissions,
        value,
      ]);
    } else {
      formik.setFieldValue(
        'permissions',
        formik.values.permissions.filter((rl) => {
          return rl !== value;
        })
      );
    }
  };

  return (
    <PermissionTableStyle className={'permission-table-form'}>
      <HeaderGroup style={{ marginTop: 0, marginBottom: 20 }}>
        <H2>Teacher Permission</H2>
      </HeaderGroup>

      <Alert
        message={t('label.warning')}
        description={
          <>
            <div>
              Sau khi đăng kí/ tạo tài khoản thành công, mặc định giáo viên sẽ
              được cấp các quyền dưới đây.
            </div>
          </>
        }
        type="info"
        closable
      />

      <FormikProvider value={formik}>
        <table
          className={'permission-table'}
          style={{ width: '100%', textAlign: 'center' }}
          border={1}
        >
          <thead>
            <tr>
              <td style={{ borderTop: 'none', borderLeft: 'none' }} />
              <th style={{ width: 180 }}>Support</th>
              <th style={{ width: 180 }}>Admin</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th colSpan={3} className={'group'}>
                MANAGER USER AND CLASS
              </th>
            </tr>

            <tr>
              <td>
                Manage Course
                <HintText>Allow user create and publish courses</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_COURSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Manage Class And Students
                <HintText>Allow user to manage classes and students</HintText>
              </td>
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_CLASS}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
              <td />
            </tr>

            <tr>
              <td>
                Create Calendar Event
                <HintText>Allow user to create Calendar events.</HintText>
              </td>
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_CALENDAR}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
              <td />
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER CMS (BOOK AND LESSON)
              </th>
            </tr>

            <tr>
              <td>
                Compose Lesson
                <HintText>
                  Allow user to compose lesson. User can access all lesson
                  created by him.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.COMPOSE_LESSON}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Create product
                <HintText>
                  Allow user to create product. User can access all products
                  created by him.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_PRODUCT}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Create Lesson Template
                <HintText>Allow user to create lesson template.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.CREATE_LESSON_TEMPLATE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER LICENSE
              </th>
            </tr>

            <tr>
              <td>
                Assign Book License
                <HintText>
                  Allow user to assign completed product to teachers. User can
                  access all products and all lessons.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.BOOK_LICENSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Publish Books
                <HintText>
                  Allow user to publish products. User can access all products
                  and all lessons.
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.PUBLISH_LICENSE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER SCHOOL
              </th>
            </tr>

            <tr>
              <td>
                Design Page Content
                <HintText>Design landing page and other pages.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.DESIGN_PAGE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Publish Page Content
                <HintText>Publish Page content after completed..</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.PUBLISH_PAGE}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Update School Setting
                <HintText>
                  Allow user to update school setting includes
                </HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.UPDATE_SCHOOL_SETTING}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>
                Manage Payment
                <HintText>Update Payment information.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.UPDATE_SCHOOL_SETTING}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <th className={'group'} colSpan={3}>
                MANAGER LTI
              </th>
            </tr>

            <tr>
              <td>
                Manage Oauth App
                <HintText>Manage Oauth App.</HintText>
              </td>
              <td />
              <td>
                <PermissionCheckBox
                  value={RoleEnum.MANAGE_OAUTH_APP}
                  selectValues={formik.values.permissions}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <ButtonGroup className="submit-container" type={'center'}>
          <Button
            size={'large'}
            loading={isLoading || isFetching}
            type={'primary'}
            shape={'round'}
            onClick={formik.submitForm}
            icon={IconUtils.actions.save}
          >
            {t('button.update')}
          </Button>

          <Button
            type="default"
            shape={'round'}
            size={'large'}
            onClick={props.onCancel}
          >
            {t('button.back')}
          </Button>
        </ButtonGroup>
      </FormikProvider>
    </PermissionTableStyle>
  );
};
