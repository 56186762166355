import React from 'react';

import { useRouter } from '@hooks/useRouter';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';
import ReviewProductPage from '@modules/product/pages/ReviewProductPage';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { ViewProductMode } from '@modules/product/components/view-toc/model';

const ReviewViewProduct = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: productId } = router.query as unknown as { id: number };

  return (
    <EmptyLayout className={'review-view-product-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={t('product.actions.review')}
        description={t('product.actions.review')}
      />

      <ReviewProductPage
        productId={Number(productId)}
        type={ViewProductMode.teacher_view}
      />
    </EmptyLayout>
  );
};

export default ReviewViewProduct;
