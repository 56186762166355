import React from 'react';
import { connect } from 'formik';
import { get as getPath } from 'lodash';
import {
  Checkbox as AntCheckBox,
  CheckboxProps as AntCheckboxProps,
} from 'antd';
import { ErrMessageStyle, StyledInput } from '@components/input/style';

export interface CheckboxProps extends AntCheckboxProps {
  name: string;
  addedClass?: string;
  label?: string;
  formik?: any;
}

const CheckBox = ({
  name,
  addedClass,
  label,
  children,
  formik,
  ...props
}: CheckboxProps) => {
  return (
    <StyledInput
      className={'form-group' + (addedClass ? ' ' + addedClass : '')}
    >
      {label && <div className={'ant-form-label'}>{label}</div>}

      <div className={'ant-form-controls'}>
        <AntCheckBox name={name} {...props}>
          {children}
        </AntCheckBox>

        {getPath(formik?.errors, name) && getPath(formik?.touched, name) && (
          <ErrMessageStyle className="error-message">
            {getPath(formik?.errors, name)}
          </ErrMessageStyle>
        )}
      </div>
    </StyledInput>
  );
};

export default connect(CheckBox);

export const CheckboxGroup = AntCheckBox.Group;
