import { UserRes } from '@modules/users/services/model';
import { UserAvatar } from '@components/avatar/UserAvatar';
import { InputGroup } from '@components/input/InputGroup';
import { Button, Dropdown } from 'antd';
import { IconUtils } from '@utils/IconUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeamOutlined } from '@ant-design/icons';
import { UserInforMobileStyle } from './style';
import { EntityStatusEnum } from '@services/model/common';
import { EntityWithStatus } from '@components/status/EntityStatus';

export const ParentInforMobile = (props: {
  item: UserRes;
  onView: (parentId: number) => void;
  onEdit: (parentId: number) => void;
  onLinkToStudents: (parentId: number) => void;
  onResetPassword: (parentId: number) => void;
  onActive: (parentId: number, isActivate: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <UserInforMobileStyle className={'parent-info'}>
      <div className={'user-info-thumbnails'}>
        <UserAvatar src={props.item.avatar} size={72} />

        <InputGroup label={t('label.username')}>
          <EntityWithStatus status={props.item.status}>
            {props.item.username}
          </EntityWithStatus>
        </InputGroup>
      </div>

      <div className={'user-info-info'}>
        <InputGroup
          label={
            <>
              {t('label.firstName')} {t('label.lastName')}
            </>
          }
        >
          {props.item.firstName} {props.item.lastName}
        </InputGroup>

        <InputGroup label={t('label.email')}>{props.item.email}</InputGroup>

        <InputGroup label={t('label.phone')}>{props.item.phone}</InputGroup>
      </div>

      <div className={'user-info-action'}>
        <ManageParentDropDown {...props} />
      </div>
    </UserInforMobileStyle>
  );
};

export const ManageParentDropDown = (props: {
  item: UserRes;
  onView: (parentId: number) => void;
  onEdit: (parentId: number) => void;
  onLinkToStudents: (parentId: number) => void;
  onResetPassword: (parentId: number) => void;
  onActive: (parentId: number, isActivate: boolean) => void;
}) => {
  const { t } = useTranslation();
  const activeUser =
    props.item.status != null && EntityStatusEnum.ACTIVE === props.item.status;

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'view',
            icon: IconUtils.actions.view_info,
            label: t('parent.actions.view_info'),
            onClick: () => props.onView(props.item.userId),
          },

          {
            key: 'edit',
            icon: IconUtils.actions.edit,
            label: t('parent.actions.update'),
            onClick: () => props.onEdit(props.item.userId),
          },
          {
            key: 'link-to-student',
            icon: <TeamOutlined />,
            label: t('parent.actions.link_to_student'),
            onClick: () => props.onLinkToStudents(props.item.userId),
          },

          {
            key: 'remove',
            icon: activeUser
              ? IconUtils.actions.disabled
              : IconUtils.actions.enabled,
            label: activeUser
              ? t('student.actions.disable_user')
              : t('student.actions.enable_user'),
            onClick: () => props.onActive(props.item.userId, activeUser),
          },

          {
            type: 'divider',
          },
          {
            key: 'reset-password',
            icon: IconUtils.password,
            label: 'Reset Password',
            onClick: () => props.onResetPassword(props.item.userId),
          },
        ],
      }}
      trigger={['click']}
    >
      <Button type="default" shape="circle" icon={IconUtils.more_icon} />
    </Dropdown>
  );
};
