import React from 'react';

import ShareLessonToStudentForm from '@components/form/ShareLessonToStudentForm';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import { LessonActionEnum } from '@modules/product/container/actions';
import { ResponsiveTabs } from '@components/tabs/ResponsiveTabs';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
// import InviteStudentLessonForm from '@components/form/InviteStudentLessonForm';

export default function LessonManageForm(props: {
  lessonId: number;
  type: LessonActionEnum;
  onSuccess: () => void;
  onClose: () => void;
  handleOnChangeTab: (type: LessonActionEnum) => void;
}) {
  const { t } = useTranslation();

  return (
    <ResponsiveTabs
      onChange={(type) => props.handleOnChangeTab(type as LessonActionEnum)}
      items={[
        {
          icon: IconUtils.actions.edit_metadata,
          key: LessonActionEnum.EDIT_METADATA,
          label: t('lesson.actions.update_metadata'),
          children: (
            <LessonMetadata
              lessonId={props.lessonId}
              onSuccess={props.onSuccess}
              onCancel={props.onClose}
            />
          ),
        },
        {
          icon: IconUtils.actions.share,
          key: LessonActionEnum.SHARE_LESSON,
          label: t('lesson.actions.share_to_student'),
          children: (
            <ShareLessonToStudentForm
              lessonId={props.lessonId}
              successHandle={() => {}}
            />
          ),
        },

        // {
        //   icon: IconUtils.actions.invite_student,
        //   key: LessonActionEnum.INVITE_STUDENT,
        //   label: t('lesson.actions.invite_students'),
        //   children: (
        //     <InviteStudentLessonForm
        //       lessonId={props.lessonId}
        //       successHandle={() => {}}
        //     />
        //   ),
        // },
      ]}
      activeKey={props.type}
    />
  );
}
