import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';

import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';

import { useTranslation } from 'react-i18next';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';
import { AnonymousExerciseStatus } from './AnonymousExerciseStatus';
import { ExerciseResponseStatus } from '@cms/lesson-template/components/exercise-status/ExerciseResponseStatus';

const ExerciseAnswerStatus = () => {
  const { t } = useTranslation();
  const { action, dispatchExerciseAction } = useViewLessonContext();
  const { exerciseId } = useExerciseServiceContext();

  return (
    <>
      {action.action === ExerciseActionEnum.review && (
        <>
          {exerciseId > 0 ? (
            <CustomModal
              header={t('exercise.actions.status')}
              className={CustomModalClassEnum.default}
              content={<ExerciseResponseStatus />}
              onCloseFunc={() =>
                dispatchExerciseAction(ExerciseActionEnum.default, null)
              }
            />
          ) : (
            <CustomModal
              header={t('exercise.actions.status')}
              className={CustomModalClassEnum.default}
              content={<AnonymousExerciseStatus />}
              onCloseFunc={() =>
                dispatchExerciseAction(ExerciseActionEnum.default, null)
              }
            />
          )}
        </>
      )}

      {action.action === ExerciseActionEnum.review_n_confirm_submit && (
        <>
          {exerciseId > 0 ? (
            <CustomModal
              header={t('exercise.actions.status')}
              className={CustomModalClassEnum.default}
              content={<ExerciseResponseStatus />}
              onCloseFunc={() =>
                dispatchExerciseAction(ExerciseActionEnum.default, null)
              }
            />
          ) : (
            <>Empty</>
          )}
        </>
      )}
    </>
  );
};

export default ExerciseAnswerStatus;
