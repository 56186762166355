import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { UserRes } from '@modules/users/services/model';
import { LessonRes } from '@modules/product/services/lesson_model';

export interface ShareLessonStudentRes {
  lesson: LessonRes;
  user: UserRes;
  createdDate: string;
  modifiedDate: string;
  status: number;
}

export interface ShareLessonRes {
  lesson: LessonRes;
  users: UserRes[];
}

export interface UpdateShareLessonReq {
  lessonId: number;
  userIds: number[];
}

export interface ShareLessonStudentReq {
  lessonId: number;
  studentId: number;
}

export const ShareLessonAPI = createApi({
  reducerPath: 'ShareLessonAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAllShared: builder.query<PaginationRes<ShareLessonStudentRes>, unknown>({
      query: () => ({
        url: `/share-lesson`,
      }),
    }),

    getLessonSharedUser: builder.query<UserRes[], unknown>({
      query: (lessonId) => ({
        url: `/share-lesson/${lessonId}`,
      }),
    }),

    updateShareLesson: builder.mutation<ShareLessonRes, UpdateShareLessonReq>({
      query: (body: UpdateShareLessonReq) => ({
        url: '/share-lesson/update',
        method: 'POST',
        body,
      }),
    }),

    addStudent: builder.mutation<UserRes, ShareLessonStudentReq>({
      query: (body: ShareLessonStudentReq) => ({
        url: '/share-lesson/add-student',
        body,
        method: 'POST',
      }),
    }),

    removeStudent: builder.mutation<BasicMessageRes, ShareLessonStudentReq>({
      query: (body: ShareLessonStudentReq) => ({
        url: '/share-lesson/remove-student',
        body,
        method: 'DELETE',
      }),
    }),
  }),
});
