import {
  AcceptRegisterCourseReq,
  CourseAPI,
  RegistrationCourseRes,
} from '@services/private/CourseAPI';
import { Button, Checkbox, Col, Divider, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import { TeacherClassSelect } from '@components/input/teacher/TeacherClassSelect';
import { HintText } from '@components/text/HintText';
import { StudentRegistrationInfo } from '@modules/course/components/StudentRegistrationInfo';
import { CourseInfo } from '../components/CourseInfo';
import { InputGroup, InputValue } from '@components/input/InputGroup';
import { H3 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { Default_Gutter } from '@components/grid';

export default function ViewCourseRegistrationForm(props: {
  item: RegistrationCourseRes | null;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [selectedClass, setSelectedClass] = useState<number[]>([]);
  const [removed, setRemoved] = useState(false);

  const [acceptRegistration] =
    CourseAPI.endpoints.acceptRegistration.useMutation();

  useEffect(() => {
    if (selectedClass.length > 0) {
      setRemoved(true);
    } else {
      setRemoved(false);
    }
  }, [selectedClass]);

  if (props.item == null) {
    return null;
  }

  const accept = () => {
    if (props.item != null) {
      const request: AcceptRegisterCourseReq = {
        courseRegistrationId: props.item.courseRegistrationId,
        classIds: selectedClass,
        accepted: true,
        removed: removed,
      };
      acceptRegistration(request)
        .unwrap()
        .then((res: any) => {
          warningMessage(res.success, 'accept');
          props.onSuccess();
        })
        .catch(() => {
          warningMessage(false, 'accept');
        });
    }
  };

  const reject = () => {
    if (props.item != null) {
      const request: AcceptRegisterCourseReq = {
        courseRegistrationId: props.item.courseRegistrationId,
        classIds: [],
        accepted: false,
        removed: removed,
      };

      acceptRegistration(request)
        .unwrap()
        .then((res: any) => {
          warningMessage(res.success, 'reject');
          props.onSuccess();
        })
        .catch(() => {
          warningMessage(false, 'reject');
        });
    }
  };

  const warningMessage = (isSuccess: boolean, type: 'accept' | 'reject') => {
    if (isSuccess) {
      notification.success({
        message: t('course.warning.accept_student_success'),
        description:
          type === 'accept'
            ? t('course.warning.accept_student_success_message')
            : t('course.warning.reject_student_success_message'),
        placement: 'bottomRight',
      });
    } else {
      notification.error({
        message: t('course.warning.accept_student_error'),
        description:
          type === 'accept'
            ? t('course.warning.accept_student_error_message')
            : t('course.warning.reject_student_error_message'),
        placement: 'bottomRight',
      });
    }

    setLoading(false);
  };

  const handleSelectClass = (ids: number[]) => {
    setSelectedClass(ids);
  };

  return (
    <ViewCourseRegistrationFormStyle>
      <CourseInfo item={props.item.course} />

      <Divider />

      <H3>{t('label.registration_info')}</H3>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <StudentRegistrationInfo item={props.item.student} />

          <InputGroup label={t('label.message')}>
            <InputValue type={'textarea'}>{props.item.message}</InputValue>
          </InputGroup>
        </Col>
      </Row>

      <Divider plain>
        <H3>{t('course.actions.action')}</H3>
      </Divider>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <TeacherClassSelect
            label={t('label.class')}
            handleSelectClass={handleSelectClass}
            mode="multiple"
          />
          <HintText>{t('course.warning.accept')}</HintText>
        </Col>

        <Col span={24}>
          <Checkbox
            checked={removed}
            disabled={selectedClass.length > 0}
            onChange={(e) => setRemoved(e.target.checked)}
          >
            {t('course.warning.reject')}
          </Checkbox>
        </Col>

        <Col span={24}>
          <div className="submit-container">
            <ButtonGroup type={'space-between'}>
              <Button
                type={'primary'}
                shape={'round'}
                danger
                loading={loading}
                onClick={reject}
                icon={IconUtils.actions.reject}
              >
                {t('button.reject')}
              </Button>

              <Button
                disabled={selectedClass.length === 0}
                type={'primary'}
                shape={'round'}
                loading={loading}
                onClick={accept}
                icon={IconUtils.actions.accept}
              >
                {t('button.accept')}
              </Button>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
    </ViewCourseRegistrationFormStyle>
  );
}

const ViewCourseRegistrationFormStyle = styled.div`
  .course-registration-group {
    margin-bottom: 2em;
  }
`;
