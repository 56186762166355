import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompTypeEnum,
} from '@cms/ComponentInteface';
import React, { useEffect, useState } from 'react';
import { GlossaryGameCompProps } from '@cms/comps/game/GameUtils';
import styled from 'styled-components';
import WordScrambleItem from '@cms/comps/game/word-scramble/WordScrambleItem';
import {
  GlossaryGameActionEnum,
  useGlossaryGameItemContext,
} from '@cms/lesson-template/glossary-game/GlossaryGameContext';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { useDispatch } from 'react-redux';

import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { GlossaryWordBank } from '@cms/comps/game/GlossaryWordBank';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import DragNDropContext from '@components/react-dnd-bt/DragNDropContext';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';

export interface WordScrambleSettingProps extends CompInteractSettingProps {
  scramble: boolean;
  layout: string;
  tryTimes: number;
}

export interface WordScrambleCompProps extends GlossaryGameCompProps {
  setting: WordScrambleSettingProps;
}

export function WordScrambleComp(props: {
  item: WordScrambleCompProps;
  answer: CompAnswerProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
}) {
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(true);

  const context = useGlossaryGameItemContext();

  const [wordBanks] = useState(() => {
    if (props.item.setting.scramble) {
      return [...props.item.configuration.sourceItems].sort(
        () => 0.5 - Math.random()
      );
    } else {
      return props.item.configuration.sourceItems;
    }
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState(wordBanks[currentIndex]);
  const [isLastQuestion, setIsLastQuestion] = useState(false);

  useEffect(() => {
    setCurrentWord(wordBanks[currentIndex]);

    if (currentIndex === wordBanks.length - 1) {
      setIsLastQuestion(true);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (
      context.action &&
      context.action.type === GlossaryGameActionEnum.view_next_part
    ) {
      viewNextWord();
    }
  }, [context.action]);

  const handleOnIncorrect = () => {
    if (isLastQuestion) {
      context.dispatchAction(
        GlossaryGameActionEnum.incorrect,
        1,
        CompTypeEnum.WORD_SCRAMBLE
      );
    } else {
      context.dispatchAction(
        GlossaryGameActionEnum.incorrect_part,
        1,
        CompTypeEnum.WORD_SCRAMBLE
      );
    }
  };

  const handleOnCorrect = () => {
    if (isLastQuestion) {
      context.dispatchAction(
        GlossaryGameActionEnum.correct,
        1,
        CompTypeEnum.WORD_SCRAMBLE
      );
    } else {
      context.dispatchAction(
        GlossaryGameActionEnum.correct_part,
        1,
        CompTypeEnum.WORD_SCRAMBLE
      );
    }
  };

  const viewNextWord = () => {
    if (!isLastQuestion) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const handleComponentChange = (newComps: WordScrambleCompProps) => {
    const answer = newComps.configuration.sourceItems.map((ans) => {
      return ans.word.data;
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer,
        },
      })
    );
    setShowComponent(true);
  };

  return (
    <WordScrambleCompStyle className={'comp comp-word-box'}>
      {showComponent && (
        <DragNDropContext id={currentWord.id}>
          <WordScrambleItem
            item={currentWord}
            setting={props.item.setting}
            onIncorrect={handleOnIncorrect}
            onCorrect={handleOnCorrect}
          />

          <ComponentSettingToolbar
            showComponent={showComponent}
            onClick={() => setShowComponent((prevState) => !prevState)}
          />
        </DragNDropContext>
      )}

      <ComposeModeContent>
        {!showComponent && (
          <GlossaryWordBank
            item={props.item as GlossaryGameCompProps}
            onChange={handleComponentChange}
            onCancel={() => setShowComponent(true)}
          />
        )}
      </ComposeModeContent>
    </WordScrambleCompStyle>
  );
}

const WordScrambleCompStyle = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  display: flex;
  border: 1px solid #ccc;

  .word-box-item {
    width: 100%;
    height: 100%;
    padding: var(--cms-padding-option, 0.5em);
  }
`;
