import React from 'react';

import UpdateProfileForm from '@modules/setting/container/my-profile/UpdateProfileForm';
import UpdatePasswordForm from '@modules/setting/container/my-profile/UpdatePasswordForm';
import { RoleEnum } from '@app/redux/slices/roles';
import UpdateZoomAccountForm from '@modules/setting/container/my-profile/UpdateZoomAccountForm';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import {
  ResponsiveTabs,
  ResponsiveTabsClass,
} from '@components/tabs/ResponsiveTabs';
import { H1 } from '@components/typography';
import { useSecurity } from '@utils/authorization';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import {
  KeyOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Default_Gutter } from '@components/grid';
import BecomeProUserForm from '@modules/setting/container/my-profile/BecomeProUserForm';
import Card, { CardClassEnum } from '@components/card';

export const UPDATE_PROFILE_ACTION = {
  UPDATE_PROFILE: 'update-profile',
  CHANGE_PASSWORD: 'change-password',
  UPDATE_ZOOM_ACCOUNT: 'update-zoom-account',
  BECOME_PRO_USER: 'become-pro-user',
  LOGOUT: 'logout',
};

export default function MyAccountPage() {
  const { t } = useTranslation();
  const { ifAnyGranted } = useSecurity();

  return (
    <MyProfilePageStyle>
      <HeaderGroup className="header-group">
        <H1>{t('profile.header')}</H1>
      </HeaderGroup>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={18}>
          <Card className={CardClassEnum.rectangle_box}>
            <ResponsiveTabs
              className={ResponsiveTabsClass.left_panel_align_right}
              activeKey={UPDATE_PROFILE_ACTION.UPDATE_PROFILE}
              items={[
                {
                  key: UPDATE_PROFILE_ACTION.UPDATE_PROFILE,
                  icon: <UserOutlined />,
                  label: t('profile.actions.update_info'),
                  children: <UpdateProfileForm onSuccess={() => {}} />,
                },
                {
                  key: UPDATE_PROFILE_ACTION.CHANGE_PASSWORD,
                  icon: <KeyOutlined />,
                  label: t('profile.actions.change_password'),
                  children: <UpdatePasswordForm />,
                },

                {
                  key: UPDATE_PROFILE_ACTION.UPDATE_ZOOM_ACCOUNT,
                  icon: <VideoCameraOutlined />,
                  label: t('profile.actions.update_zoom_account'),
                  children: <UpdateZoomAccountForm onSuccess={() => {}} />,
                  visible: ifAnyGranted([RoleEnum.TEACHER]),
                },
                {
                  key: UPDATE_PROFILE_ACTION.BECOME_PRO_USER,
                  icon: <UserOutlined />,
                  label: t('profile.actions.become_pro_user'),
                  children: <BecomeProUserForm />,
                  visible: ifAnyGranted([
                    RoleEnum.TEACHER,
                    RoleEnum.SUPPORT,
                    RoleEnum.SCHOOL,
                    RoleEnum.ADMIN,
                  ]),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </MyProfilePageStyle>
  );
}

const MyProfilePageStyle = styled.div`
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          text-align: left !important;
          justify-content: flex-start !important;
          padding-left: 0px;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    padding-left: 24px;

    .ant-tabs-content {
      .ant-tabs-tabpane {
        padding-left: 0px;
      }
    }
  }
`;
