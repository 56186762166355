import React from 'react';

import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import ViewSchoolPage from '@modules/setting/pages/ViewSchoolPage';
import { PageHeader } from '@app/header/PageHeader';

const SchoolsManagement = () => {
  return (
    <ManagementLayout className={'manage-schools-page'}>
      <PageHeader
        menu={NavigationMenuEnum.ORGANIZATION}
        subMenu={NavigationMenuEnum.ORGANIZATION_SCHOOL}
        title={'List School'}
        description={'List School'}
      />

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <ViewSchoolPage />
      </Authorization>
    </ManagementLayout>
  );
};

export default SchoolsManagement;
