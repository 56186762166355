import { useTranslation } from 'react-i18next';
import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Row } from 'antd';
import Input from '@components/input/input';
import { SearchOutlined } from '@ant-design/icons';
import { FormGroup, InputGroup } from '@components/input/InputGroup';
import { EntityStatusEnum } from '@services/model/common';
import { Default_Gutter } from '@components/grid';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { SelectTeacher } from '@components/select/SelectTeacher';
import { FilterProductLessonReq } from '@modules/product/services/product_model';
import SelectProduct from '@modules/common/components/SelectProduct';

export const FilterLessonTree = (props: {
  teacherId?: number;
  productId: number;
  onSubmit: (data: FilterProductLessonReq) => void;
  setting?: {
    selectProduct: boolean;
    showRemovedLesson: boolean;
  };
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      teacherId: props.teacherId ?? -1,
      productId: props.productId,
      keyword: '',
      status: EntityStatusEnum.EMPTY,
    },
    onSubmit: (values) => {
      props.onSubmit({
        productId: values.productId,
        keyword: values.keyword,
        status: values.status,
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormGroup className={'no-padding'}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Authorization
            type={'ifAnyGranted'}
            roles={[RoleEnum.SUPPORT, RoleEnum.SCHOOL]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <SelectTeacher
                label={'Teacher'}
                value={formik.values.teacherId}
                onChange={(val: number) =>
                  formik.setFieldValue('teacherId', val)
                }
                disabled={props.teacherId != null && props.teacherId > 0}
              />
            </Col>
          </Authorization>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <SelectProduct
              formik={formik}
              label={t('select.select_a_product')}
              placeholder={t('select.select_a_product')}
              name={'productId'}
              value={formik.values.productId}
              onChange={(val: number) => formik.setFieldValue('productId', val)}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Input
              name="keyword"
              type="text"
              label={t('label.keyword')}
              placeholder={t('label.enter_keyword')}
              onChange={formik.handleChange}
              value={formik.values.keyword}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <InputGroup label={<>&nbsp;</>}>
              <Button type={'primary'} onClick={formik.submitForm}>
                <SearchOutlined /> {t('button.filter')}
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    </FormikProvider>
  );
};

export default FilterLessonTree;
