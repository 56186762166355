import styled from 'styled-components';
import { Layout } from 'antd';
const { Footer } = Layout;

const FooterAppStyle = styled(Footer)`
  text-align: center;

  .footer-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const FooterApp = () => {
  return (
    <FooterAppStyle>
      <div className={'footer-content'}>Gstudy.net Copyright © 2022.</div>
    </FooterAppStyle>
  );
};
