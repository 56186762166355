import React, { useEffect } from 'react';
import {
  LessonEditableActionEnum,
  LessonMode,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { ComponentLibrary } from '@cms/comps-bank/ComponentLibrary';
import { CompProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { updateComponentAndScoring } from '@app/redux/slices/composeResource';

import {
  LessonTemplateLayoutProvider,
  LessonTemplateWrapper,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import {
  ComposeContentLayout,
  ComposeFooterLayout,
  ComposeLayout,
  max_bank_size,
} from '../layout';
import { H4 } from '@components/typography';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import ComposeWysiResourceToolbar from '../resource/ComposeWysiResourceToolbar';
import { ComposeNavigationToolbar } from '../resource/ComposeNavigationToolbar';
import { ViewExerciseWrapper } from '@cms/lesson-template/exercise/ViewExerciseWrapper';
import ComposeResourceWysiTemplate from '../resource/ComposeResourceWysiTemplate';
import { SelectLessonResource } from '@cms/lesson-template/compose/resource/SelectLessonResource';
import { useViewLessonExample } from '@cms/lesson-template/context/useViewLessonExample';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '@cms/lesson-template/components/buttons/style';

import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { useDispatch, useSelector } from '@app/redux/hook';
import { LessonTypeEnum } from '@modules/admin/service/model';

export const ComposeWysiTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const service = useLessonServiceContext();

  const { lesson, mode, changeToMode, dispatchAction, lessonExampleId } =
    useLessonEditableContext();
  const { newComponents, newAnswers, scoring } = useSelector(
    (state) => state.composeResource
  );
  const relative = useViewLessonExample(lessonExampleId);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [service.resourceId]);

  useEffect(() => {
    if (
      relative.relativeData != null &&
      relative.resources != null &&
      relative.resources.length === 1 &&
      newComponents.length === 0
    ) {
      handleOnSelect(relative.resources[0]);
    }
  }, [relative, service.resourceId]);

  const handleOnSelectComponents = (components: CompProps[]) => {
    const newComps = components.map((compData) => {
      return COMPONENT_UTILS.generateComponent(compData);
    });
    replaceComponents(newComps);
  };

  const handleOnSelect = (resource: ResourceProps) => {
    const newComps = resource.components.map((compData) => {
      return COMPONENT_UTILS.generateComponent(compData);
    });
    replaceComponents(newComps);
  };

  const replaceComponents = (newComps: CompProps[]) => {
    const scoring = ResourceUtils.generationScoring(newComps);
    dispatch(updateComponentAndScoring({ comps: newComps, scoring: scoring }));

    dispatchAction(LessonEditableActionEnum.add_component, -1);
    changeToMode(LessonMode.view);
  };

  const handleOnClose = () => {
    changeToMode(LessonMode.view);
  };

  const handleOnReplaceComponent = () => {
    if (
      relative.relativeData != null &&
      relative.resources != null &&
      relative.resources.length === 1 &&
      newComponents.length === 0
    ) {
      handleOnSelect(relative.resources[0]);
    } else {
      changeToMode(LessonMode.edit);
    }
  };

  const handleOnRemove = () => {
    service.onDelete(service.resourceId, (rmId) => {
      console.log(rmId);
    });
  };

  const handleOnInsert = () => {
    service.onCreateOrUpdate(
      service.resourceId,
      newComponents,
      newAnswers,
      scoring,
      (resourceId) => {
        dispatchAction(LessonEditableActionEnum.insert_resource, resourceId);
      }
    );
  };

  return (
    <>
      <ComposeLayout className={'show-correct-answer compose-wysi-page'}>
        <ComposeContentLayout className={'compose-content-layout'}>
          <LessonTemplateLayoutProvider lesson={lesson}>
            <ViewExerciseWrapper
              className={'exercise-content-display'}
              ratio={{ width: 16, height: 6 }}
              minFontSize={12}
            >
              <LessonTemplateWrapper>
                <ComposeResourceWysiTemplate
                  key={service.resourceId}
                  resourceId={service.resourceId}
                />
              </LessonTemplateWrapper>
            </ViewExerciseWrapper>

            <ComposeNavigationToolbar />
          </LessonTemplateLayoutProvider>

          <ButtonGroup
            className={'toggle-lesson-button'}
            type={'space-between'}
          >
            <LessonButton
              type={'primary'}
              shape={'round'}
              icon={
                newComponents.length === 0
                  ? IconUtils.add_new
                  : IconUtils.actions.swap
              }
              onClick={handleOnReplaceComponent}
            >
              {newComponents.length === 0
                ? t('resource.actions.add_component')
                : t('resource.actions.replace_components')}
            </LessonButton>

            <ButtonGroup type={'right'} size={'small'}>
              <LessonButton
                type={'primary'}
                shape={'circle'}
                icon={IconUtils.actions.delete}
                onClick={handleOnRemove}
                loading={service.loading}
                danger
              />

              <LessonButton
                type={'primary'}
                shape={'circle'}
                icon={IconUtils.add_new}
                loading={service.loading}
                onClick={handleOnInsert}
              />
            </ButtonGroup>
          </ButtonGroup>
        </ComposeContentLayout>

        <ComposeFooterLayout className={'compose-footer-layout'}>
          <ComposeWysiResourceToolbar />
        </ComposeFooterLayout>
      </ComposeLayout>

      <Drawer
        className={'compose-lesson-bank'}
        width={max_bank_size}
        title={
          <H4 style={{ marginBottom: 0 }}>
            {t('my_lesson.actions.select_a_content')}
          </H4>
        }
        placement="left"
        open={mode === LessonMode.edit}
        closable={false}
        onClose={handleOnClose}
      >
        {mode === LessonMode.edit && (
          <div>
            {relative.relativeData ? (
              <SelectLessonResource
                type={newComponents.length === 0 ? 'add' : 'replace'}
                resources={relative.resources}
                onSelect={handleOnSelect}
              />
            ) : (
              <ComponentLibrary
                type={lesson ? lesson.type : LessonTypeEnum.lesson}
                onSelect={handleOnSelectComponents}
              />
            )}
          </div>
        )}

        <ButtonGroup type={'center'}>
          <Button shape={'round'} danger onClick={handleOnClose}>
            {t('button.close')}
          </Button>
        </ButtonGroup>
      </Drawer>
    </>
  );
};
