import React from 'react';

import MyStudentProductPage from '@modules/product/pages/StudentProductPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const MyLibrary = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-library-page'}>
      <PageHeader
        menu={NavigationMenuEnum.LIBRARY}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('library.header')}
        description={t('library.header')}
      />

      <MyStudentProductPage />
    </ManagementLayout>
  );
};

export default MyLibrary;
