import { useTranslation } from 'react-i18next';
import React from 'react';
import { DateLabel } from '@components/text/DateLabel';
import { InputGroup } from '@components/input/InputGroup';
import { Default_Gutter } from '@components/grid';
import { Col, Row } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { SessionRes } from '@services/model/session';

export const PublicSessionMetadata = (props: {
  title: string;
  item: SessionRes;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'public-session-info'}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <InputGroup label={t('label.startDate')}>
            <CalendarOutlined /> <DateLabel label={props.item.startDate} />
          </InputGroup>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <InputGroup label={t('label.duration')}>
            <ClockCircleOutlined /> {props.item.duration} mins
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};
