import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';

import { useRouter } from '@hooks/useRouter';
import ComposeLessonLayout from '@components/template/compose-lesson-layout/ComposeLessonLayout';
import ComposeSimpleLessonPage from '@modules/product/components/lesson/pages/ComposeSimpleLessonPage';
import { PageHeader } from '@app/header/PageHeader';

const ComposeLessonResourceSimple = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { lessonId, resourceId } = router.query as unknown as {
    lessonId: string;
    resourceId: string;
  };

  return (
    <ComposeLessonLayout
      className={'compose-lesson-layout compose-resource-in-lesson-simple'}
    >
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.actions.compose')}
        description={t('lesson.actions.compose')}
      />

      <ComposeSimpleLessonPage
        from={'lesson'}
        lessonId={Number(lessonId)}
        resourceId={Number(resourceId)}
      />
    </ComposeLessonLayout>
  );
};

export default ComposeLessonResourceSimple;
