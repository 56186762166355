import { useTranslation } from 'react-i18next';
import { AuthAPI } from '@modules/authorization/services/AuthAPI';
import React, { useEffect, useRef } from 'react';
import {
  refreshExpiredData,
  warningExpired,
} from '@app/redux/slices/sessionExpiredSlice';
import { logout, updateAccessToken } from '@app/redux/slices/profileSlice';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { Button } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import styled from 'styled-components';
import { Tooltip } from '@components/tooltip/Tooltip';
import { useDispatch, useSelector } from '@app/redux/hook';
import {
  RefreshTokenReq,
  RefreshTokenRes,
} from '@modules/authorization/services/model';

const one_minutes_milliseconds = 60 * 1000;

const SessionExpiredModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.profile);
  const [refreshToken] = AuthAPI.endpoints.refreshToken.useMutation();

  const timer = useRef<number | null>(null);

  const { warning, timeout, expiredDate, expiredTime, remainTime } =
    useSelector((state) => state.sessionExpired);

  useEffect(() => {
    clearTimer();
    checkTimeout();

    // @ts-ignore
    timer.current = setInterval(() => {
      checkTimeout();
    }, one_minutes_milliseconds);

    return () => {
      clearTimer();
    };
  }, [expiredDate]);

  const checkTimeout = () => {
    dispatch(warningExpired());
  };

  const clearTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  const handleWarningExpired = () => {
    const params: RefreshTokenReq = {
      refresh_token:
        localStorage.getItem('refreshToken') != null
          ? localStorage.getItem('refreshToken') + ''
          : '',
    };

    refreshToken(params)
      .unwrap()
      .then((data: RefreshTokenRes) => {
        clearTimer();
        dispatch(updateAccessToken(data));
        dispatch(refreshExpiredData(data.expires_in));
      })
      .catch(() => {
        dispatch(logout());
      });
  };

  const handleTimeout = () => {
    clearTimer();
    dispatch(logout());
  };

  if (isAuth) {
    if (timeout) {
      return (
        <CustomModal
          header={t('sessionExpired.expired.title')}
          className={CustomModalClassEnum.default}
          closable={false}
          content={<p>{t('sessionExpired.expired.message')}</p>}
          footer={
            <ButtonGroup type={'center'}>
              <Button
                type={'primary'}
                shape={'round'}
                danger
                onClick={handleTimeout}
              >
                Login
              </Button>
            </ButtonGroup>
          }
          onOk={handleTimeout}
          onCloseFunc={handleTimeout}
        />
      );
    } else if (warning) {
      return (
        <CustomModal
          closable={false}
          header={t('sessionExpired.warning.title')}
          className={CustomModalClassEnum.default}
          content={
            <p>
              {t('sessionExpired.warning.message', {
                mins: DateAndTimeUtils.getDisplayTime(remainTime / 1000),
              })}
            </p>
          }
          onOk={handleWarningExpired}
          onCloseFunc={handleWarningExpired}
          footer={
            <ButtonGroup type={'center'}>
              <Button
                type={'primary'}
                shape={'round'}
                onClick={handleWarningExpired}
              >
                Continuous
              </Button>
            </ButtonGroup>
          }
        />
      );
    } else {
      return (
        <Tooltip
          placement="topLeft"
          title={`Session will be expired in ${DateAndTimeUtils.getDisplayTime(
            remainTime / 1000
          )}`}
        >
          <SessionExpiredBarStyle
            className={'session-expired-modal'}
            style={{ fontSize: 8 }}
          >
            <span
              className={'time-out-progress'}
              style={{ right: `${(remainTime / expiredTime) * 100}%` }}
            />
          </SessionExpiredBarStyle>
        </Tooltip>
      );
    }
  } else {
    return null;
  }
};

export default SessionExpiredModal;

const SessionExpiredBarStyle = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 6px;
  z-index: 999;

  background: #cccccc;

  .time-out-progress {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 0 6px 6px 0;
    display: inline-block;
    background: ${(props) => props.theme.app.primary};
  }
`;
