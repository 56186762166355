import React, { useEffect, useState } from 'react';

import { Button, notification, Skeleton } from 'antd';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import { Row, Col } from 'antd';
import { StandardAPI } from '@modules/product/services/StandardAPI';
import { StandardSetDisplay } from '@modules/product/components/standards/StandardSetDisplay';
import { InputGroup } from '@components/input/InputGroup';
import { PlusOutlined } from '@ant-design/icons';
import SelectStandardSet from '@app/interact/SelectStandardSet';
import { LanguageContentInput } from '@components/editor/LanguageContentInput';
import { Default_Gutter } from '@components/grid';
import { useSelector } from '@app/redux/hook';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';

export function EditStandardForm(props: {
  standardSet: StandardSetRes | null;
  data: StandardRes | null;
  successHandle: (result: StandardRes) => void;
  onCloseFunc: () => void;
  changeToAddStandardSet?: () => void;
}) {
  const { t } = useTranslation();

  const [findById, { data, isSuccess, isFetching }] =
    StandardAPI.endpoints.findById.useLazyQuery();

  const [createStandard, { isLoading: isSaveLoading }] =
    StandardAPI.endpoints.create.useMutation({});
  const [updateStandard, { isLoading: isUpdateLoading }] =
    StandardAPI.endpoints.update.useMutation({});

  const { isReady: isLoadSchoolReady, school } = useSelector(
    (state) => state.userInformation
  );

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (props.data != null && props.data.standardId > 0) {
      findById(props.data.standardId);
    } else {
      setIsReady(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setIsReady(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (school && isLoadSchoolReady) {
      if (school != null && school.schoolId > 0) {
        formik.setFieldValue('schoolId', school.schoolId);
      }
    }
  }, [school]);

  const setFormData = (data: StandardRes) => {
    formik.setValues({
      standardId: data.standardId,
      standardSetId: props.standardSet ? props.standardSet.standardSetId : -1,
      code: data.code,
      name: data.name,
      description: data.description,
      displayOrder: data.displayOrder,
    });
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      standardId: -1,
      standardSetId: props.standardSet ? props.standardSet.standardSetId : -1,
      code: '',
      name: '',
      description: '',
      displayOrder: 1,
    },

    validationSchema: yup.object().shape({
      standardSetId: yup
        .number()
        .min(1, t('validation.required.field'))
        .required(t('validation.required.field')),
      name: yup.string().required(t('validation.required.field')),
      description: yup.string().required(t('validation.required.field')),
      vi: yup.string().required(t('validation.required.field')),
      en: yup.string().required(t('validation.required.field')),
    }),

    onSubmit: (values) => {
      if (data != null && data.standardId > 0) {
        updateStandard(values)
          .unwrap()
          .then((result) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.update_success_message'),
              placement: 'bottomRight',
            });
            props.successHandle(result);
          })
          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotUpdate'),
              placement: 'bottomRight',
            });
          });
      } else {
        createStandard(values)
          .unwrap()
          .then((result) => {
            notification.success({
              message: t('alert.success'),
              description: t('alert.createSuccessful'),
              placement: 'bottomRight',
            });

            props.successHandle(result);
          })

          .catch(() => {
            notification.error({
              message: t('alert.warning'),
              description: t('alert.cannotCreate'),
              placement: 'bottomRight',
            });
          });
      }
    },
  });

  const handleSelectStandardSet = (standardSetId: number) => {
    formik.setFieldValue('standardSetId', standardSetId);
  };

  const handleOnLanguageChange = (value: string) => {
    formik.setFieldValue('description', value);
  };

  return (
    <>
      {!isReady && <Skeleton avatar paragraph={{ rows: 4 }} />}

      {isReady && (
        <FormikProvider value={formik}>
          <Row gutter={[Default_Gutter, Default_Gutter]}>
            <Col span={24}>
              <InputGroup
                label={t('standard_set.header')}
                hint={
                  <Button
                    type={'primary'}
                    shape="circle"
                    size={'small'}
                    icon={<PlusOutlined />}
                    onClick={props.changeToAddStandardSet}
                  />
                }
              >
                {props.standardSet != null ? (
                  <StandardSetDisplay item={props.standardSet} />
                ) : (
                  <SelectStandardSet
                    formik={formik}
                    name={'standardSetId'}
                    value={formik.values.standardSetId}
                    onChange={handleSelectStandardSet}
                  />
                )}
              </InputGroup>

              <Input
                name="code"
                type="text"
                label={t('label.code')}
                placeholder={t('label.code')}
                onChange={formik.handleChange}
                value={formik.values.code}
              />

              <Input
                name="name"
                type="text"
                label={t('label.name')}
                placeholder={t('label.name')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />

              <LanguageContentInput
                initValue={data ? data.description : ''}
                onChange={handleOnLanguageChange}
                maxLength={512}
                row={2}
              />

              <Input
                name="displayOrder"
                type="number"
                label={t('label.displayOrder')}
                placeholder={t('label.displayOrder')}
                onChange={formik.handleChange}
                value={formik.values.displayOrder}
              />

              <div className="submit-container">
                <ButtonGroup>
                  <Button
                    type={'primary'}
                    loading={isFetching || isSaveLoading || isUpdateLoading}
                    onClick={formik.submitForm}
                  >
                    {props.data ? (
                      <>{t('standard.actions.edit')}</>
                    ) : (
                      <>{t('standard.actions.add')}</>
                    )}
                  </Button>

                  <Button type="default" onClick={props.onCloseFunc}>
                    {t('button.cancel')}
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          </Row>
        </FormikProvider>
      )}
    </>
  );
}
