import OptionEditable from '@cms/editable/OptionEditable';
import {
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  OrderingOptionProps,
  OrderingProps,
} from '@cms/comps/interact/ordering/OrderingComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { updateComponent } from '@app/redux/slices/composeResource';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import { CompProps } from '@cms/ComponentInteface';
import produce from 'immer';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import styled from 'styled-components';

export const OrderingCompEditable = (props: {
  item: OrderingProps;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();

  const [modifiedTime, setModifiedTime] = useState(-1);
  const [sourceItems, setSourceItems] = useState<OrderingOptionProps[]>(
    props.item.configuration.sourceItems
  );

  useEffect(() => {
    setSourceItems(props.item.configuration.sourceItems);
  }, [props.item]);

  const updateDataForOption = (
    data: AssetsContentProps,
    index: number,
    option: OrderingOptionProps
  ) => {
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(sourceItems, index, {
      ...option,
      content: { ...data },
    });
    updateOptions(newSourceItems);
  };

  const handleOnChangeType = (
    type: AssetsTypeEnum,
    index: number,
    option: OrderingOptionProps
  ) => {
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(sourceItems, index, {
      ...option,
      content: { ...option.content, type: type },
    });
    updateOptions(newSourceItems);
  };

  const handleOnClickOperation = (
    operation: ContentOperationEnum,
    index: number,
    option: OrderingOptionProps
  ) => {
    let newSourceItems: OrderingOptionProps[] = [];

    if (operation === ContentOperationEnum.ADD) {
      const cloneOption = {
        ...option,
        content: { ...option.content, id: COMPONENT_UTILS.generateUID() },
      };
      newSourceItems = COMPONENT_UTILS.insertAtIndex(
        sourceItems,
        index,
        cloneOption
      );
    } else {
      newSourceItems = COMPONENT_UTILS.removeAtIndex(sourceItems, index);
    }

    updateOptions(newSourceItems);
  };

  const updateOptions = (sourceItems: OrderingOptionProps[]) => {
    const newSourceItems = sourceItems.map((opt, index) => {
      return { ...opt, label: COMPONENT_UTILS.getABC(index) };
    });
    setSourceItems(newSourceItems);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const handleUpdateComponentData = () => {
    onUpdateComponent();
    props.onClose();
  };

  const onUpdateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
      };
    });

    dispatch(updateComponent(newComps));
  };

  return (
    <>
      <OrderingEditableStyle
        className={`ordering-container ordering-compose ordering-vertical`}
      >
        {sourceItems &&
          sourceItems.map((option, index) => {
            return (
              <OptionEditable
                key={JSON.stringify(option) + '_' + index + '_' + modifiedTime}
                type={option.content.type}
                disabled={{
                  addOption: false,
                  removeOption: sourceItems.length === 1,
                }}
                onChangeType={(newType) =>
                  handleOnChangeType(newType, index, option)
                }
                onOperation={(operation) =>
                  handleOnClickOperation(operation, index, option)
                }
              >
                <div className={'draggable-item'}>
                  <span>{index + 1}.&nbsp;</span>

                  <ContentViewerEditable
                    contentData={option.content}
                    onChange={(data) =>
                      updateDataForOption(data, index, option)
                    }
                  />
                </div>
              </OptionEditable>
            );
          })}
      </OrderingEditableStyle>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={handleUpdateComponentData}
      />
      <AutoUpdateToolbar
        lastChange={modifiedTime}
        onTimeout={onUpdateComponent}
      />
    </>
  );
};

const OrderingEditableStyle = styled.div`
  position: relative;
  padding: 0.5em;

  .cms-content-actions {
    display: flex;
    flex-direction: row;

    .cms-content-display {
      .draggable-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
`;
