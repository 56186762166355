import { createContext } from 'react';
import { StudentInvitationRes, UserRes } from '@modules/users/services/model';

export const SelectStudentContext = createContext({
  students: [] as UserRes[],

  invitedStudents: [] as StudentInvitationRes[],
  setInvitedStudents: (data: any) => {
    console.log(data);
  },

  selectedStudents: [] as number[],
  setStudents: (data: any) => {
    console.log(data);
  },
});
