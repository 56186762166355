import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';
import MyClassPage from '@modules/users/pages/MyClassPage';

const MyClass = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-class-page'} selectUser={'teacher'}>
      <PageHeader
        menu={NavigationMenuEnum.MY_CLASS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('classes.my_class')}
        description={t('classes.my_class')}
      />
      <MyClassPage />
    </ManagementLayout>
  );
};

export default MyClass;
