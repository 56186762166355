import OptionEditable from '@cms/editable/OptionEditable';
import React from 'react';
import {
  ContentActionEnum,
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import {
  AssetsContentProps,
  AudioContentProps,
  TextContentProps,
} from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { useAudioCompContext } from '@cms/comps/content/audio/AudioCompContext';
import { ComponentSettingToolbar } from '@cms/comps/common/ComponentSettingToolbar';
import TimestampViewerEditable from './TimestampViewerEditable';
import styled from 'styled-components';
import { Divider } from 'antd';
import { InputGroup } from '@components/input/InputGroup';
import AudioWaveSuffer from '@cms/comps/content/audio/AudioWaveSuffe';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { TimelineProps } from '@cms/ComponentInteface';

const AudioCompEditable = (props: { onClose: () => void }) => {
  const {
    modifiedTime,
    audioContent,
    sourceItems,
    updateOptions,
    updateComponent,
    updateAudioContent,
  } = useAudioCompContext();

  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: TimelineProps
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    const newSourceItems = OptionGroupUtils.updateOption(
      index,
      newOption,
      sourceItems
    );
    updateOptions(newSourceItems);
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: TimelineProps
  ) => {
    let newOptions: TimelineProps[] = [];

    if (operation === ContentOperationEnum.ADD) {
      newOptions = OptionGroupUtils.insertOption(
        index,
        option,
        sourceItems,
        OptionTypeEnum.NUMBER
      );

      // then, update correct answer.
    } else {
      newOptions = OptionGroupUtils.removeOption(
        index,
        sourceItems,
        OptionTypeEnum.NUMBER
      );
    }
    // then update data...
    updateOptions(newOptions);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: TimelineProps
  ) => {
    const newOption = { ...option, content: { ...data } };
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(
      sourceItems,
      index,
      newOption
    );
    updateOptions(newSourceItems);
  };

  const updateOptionTimestamp = (
    from: number,
    to: number,
    index: number,
    option: TimelineProps
  ) => {
    const newOption = { ...option, from: from, to: to };
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(
      sourceItems,
      index,
      newOption
    );
    updateOptions(newSourceItems);
  };

  const handleUpdateComponentData = () => {
    updateComponent();
    props.onClose();
  };

  const handleOnDataChange = (data: AudioContentProps) => {
    updateAudioContent(data);
  };

  const insertTimelineData = (time: number) => {
    const template: TextContentProps = {
      id: COMPONENT_UTILS.generateUID(),
      type: AssetsTypeEnum.TEXT,
      data: 'Click to play.',
    };

    const newOption: TimelineProps = {
      label: sourceItems.length + 1 + '',
      from: time,
      to: time + 1,
      content: template,
    };

    const newSourceItems = [...sourceItems, newOption];
    updateOptions(newSourceItems);
  };

  return (
    <AudioSubtitleEditableStyle>
      <div className={'audio-subtitle-editable scroll-container'}>
        <AudioWaveSuffer
          key={audioContent.data}
          url={audioContent.data}
          onClick={insertTimelineData}
        />

        <div className={'audio-subtitle-editable-content'} key={modifiedTime}>
          {sourceItems != null &&
            sourceItems.map((subtitle, index) => {
              return (
                <div className={`audio-subtitle-group-item`} key={modifiedTime}>
                  <OptionEditable
                    contentTypes={[
                      ContentActionEnum.CHANGE_TO_TEXT,
                      ContentActionEnum.CHANGE_TO_IMAGE,
                    ]}
                    disabled={{
                      addOption: false,
                      removeOption: sourceItems.length === 1,
                    }}
                    type={subtitle.content.type}
                    onChangeType={(newType) =>
                      updateOptionType(newType, index, subtitle)
                    }
                    onOperation={(operation) =>
                      updateGroupOptions(operation, index, subtitle)
                    }
                  >
                    <TimestampViewerEditable
                      from={subtitle.from}
                      to={subtitle.to}
                      onChange={(from, to) =>
                        updateOptionTimestamp(from, to, index, subtitle)
                      }
                    />

                    <InputGroup label={'Subtitle'}>
                      <ContentViewerEditable
                        contentData={subtitle.content}
                        onChange={(data) =>
                          updateOptionData(data, index, subtitle)
                        }
                      />
                    </InputGroup>
                  </OptionEditable>
                </div>
              );
            })}
        </div>
      </div>

      <Divider />

      <div className={'audio-review-container'}>
        <ContentViewerEditable
          contentData={audioContent}
          onChange={(data) => handleOnDataChange(data as AudioContentProps)}
        />
      </div>

      <ComponentSettingToolbar
        showComponent={false}
        onClick={handleUpdateComponentData}
      />

      <AutoUpdateToolbar
        lastChange={modifiedTime}
        onTimeout={updateComponent}
      />
    </AudioSubtitleEditableStyle>
  );
};

export default AudioCompEditable;

const AudioSubtitleEditableStyle = styled.div`
  .audio-subtitle-editable {
    position: relative;
    max-height: 500px;
    overflow-y: auto;
    background: #fafafa;
    padding: 0.5em;

    .audio-wave-suffer {
      position: sticky;
      top: 0px;
      background: #fff;
      padding: 1em;
      z-index: 2;
    }
  }

  .audio-review-container {
    .audio-viewer-comp {
      display: none;
    }

    .content-viewer-editable {
      padding-right: 1em;
    }

    .ant-space-item {
      &:not(:first-child) {
        margin-left: 1em;
      }
    }
  }

  .audio-subtitle-group-item {
    margin-top: 0.75em;

    &:not(:first-child) {
      .form-label {
        display: none;
      }
    }

    .cms-content-display {
      display: flex;
      align-items: flex-start;

      .form-group {
        margin-bottom: 0px;
        max-width: 85px;
        margin-right: 5px;

        &:last-child {
          max-width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
`;
