import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { BasicMessageRes } from '@services/model/Response';
import {
  AddLessonToTocReq,
  CreateProductTocReq,
  MoveLessonReq,
  MoveProductTocReq,
  ProductTocRes,
  RemoveLessonFromTocReq,
  UpdateProductTocReq,
  ViewLessonProductReq,
} from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export const ProductTocAPI = createApi({
  reducerPath: 'ProductTocAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getProductTocs: builder.query<ProductTocRes[], unknown>({
      query: (productId) => ({
        url: `/product/${productId}/table-of-content`,
        method: 'GET',
      }),
    }),

    createProductToc: builder.mutation<ProductTocRes, CreateProductTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content`,
        method: 'POST',
        body,
      }),
    }),

    insertProductToc: builder.mutation<ProductTocRes[], CreateProductTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/insert`,
        method: 'POST',
        body,
      }),
    }),

    updateProductToc: builder.mutation<ProductTocRes, UpdateProductTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}`,
        method: 'PUT',
        body,
      }),
    }),

    moveProductTocUp: builder.mutation<ProductTocRes, MoveProductTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/move-up`,
        method: 'PUT',
        body,
      }),
    }),

    moveProductTocDown: builder.mutation<BasicMessageRes, MoveProductTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/move-down`,
        method: 'PUT',
        body,
      }),
    }),

    removeProductToc: builder.mutation<
      BasicMessageRes,
      { productId: number; productTocId: number }
    >({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}`,
        method: 'DELETE',
      }),
    }),

    addLessonToTOC: builder.mutation<ProductTocRes, AddLessonToTocReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/lessons`,
        method: 'POST',
        body,
      }),
    }),

    removeLessonFromTOC: builder.mutation<
      ProductTocRes,
      RemoveLessonFromTocReq
    >({
      query: (body: RemoveLessonFromTocReq) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/lessons`,
        method: 'DELETE',
        body,
      }),
    }),

    viewLesson: builder.query<LessonRes, ViewLessonProductReq>({
      query: (params) => ({
        url: `/product/${params.productId}/table-of-content/${params.productTocId}/lessons/${params.lessonId}/view`,
        method: 'GET',
      }),
    }),

    moveLessonUp: builder.mutation<ProductTocRes, MoveLessonReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/lessons/${body.lessonId}/move-up`,
        method: 'PUT',
        body,
      }),
    }),

    moveLessonDown: builder.mutation<BasicMessageRes, MoveLessonReq>({
      query: (body) => ({
        url: `/product/${body.productId}/table-of-content/${body.productTocId}/lessons/${body.lessonId}/move-down`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
