import React, { createContext, useContext, useEffect, useState } from 'react';
import { getDragNDropAns } from '@cms/comps/interact/drag-n-drop/DragNDropComp';
import {
  CompFeedbackProps,
  initCompFeedback,
} from '@cms/feedback/CompFeedback';

import {
  DropDownAnsProps,
  DropDownOptionProps,
  DropDownProps,
} from '@cms/comps/interact/dropdown/DropdownComp';
import {
  CompAnswerProps,
  CompInteractSettingProps,
  CompProps,
} from '@cms/ComponentInteface';
import { CompGroupEnum } from '@cms/utils/CompProps';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';
import { updateComponentAndAnswer } from '@app/redux/slices/composeResource';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { CompFeedbackContextProvider } from '@cms/feedback/CompFeedbackContext';

const DropDownCompContext = createContext({
  modifiedTime: -1 as number,
  disabled: false as boolean,
  disableSource: [] as string[],

  originalData: {} as DropDownProps,
  setting: {} as CompInteractSettingProps,
  sourceItems: [] as DropDownOptionProps[],
  targetItems: [] as DropDownOptionProps[],

  answers: [] as string[],
  feedback: initCompFeedback as CompFeedbackProps,

  updateOptions: (options: DropDownOptionProps[], type: CompGroupEnum) => {
    console.log(options, type);
  },
  updateAnswers: (answers: string[]) => {
    console.log(answers);
  },
  handleOnAnswerChange: (value: string) => {
    console.log(value);
  },
  updateComponent: () => {},
});

export const DropDownCompContextProvider = (props: {
  disabled: boolean;
  item: DropDownProps;
  answer: DropDownAnsProps | null;
  feedback: ComponentResponseProps | null;
  onChange: (newAns: CompAnswerProps) => void;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [disableSource, setDisableSource] = useState<string[]>([]);

  const [answer, setCompAnswer] = useState({
    value: [] as string[],
    triggerChange: false,
  });

  const [feedback, setFeedBack] = useState<CompFeedbackProps>(initCompFeedback);

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );
  const [targetItems, setTargetItems] = useState(
    props.item.configuration.targetItems
  );

  useEffect(() => {
    if (props.answer && props.answer.answer) {
      setCompAnswer({
        value: props.answer.answer,
        triggerChange: false,
      });
    } else {
      setCompAnswer({
        value: [],
        triggerChange: false,
      });
    }
  }, [props.answer]);

  useEffect(() => {
    if (props.feedback) {
      setFeedBack({
        manualScore: !props.feedback.autoScore,
        correct: props.feedback.correct,
        incorrect: props.feedback.incorrect,
      });
    } else {
      setFeedBack(initCompFeedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (answer && answer.triggerChange) {
      props.onChange({
        id: props.item.id,
        type: props.item.type,
        answer: answer.value,
      });
    }
  }, [answer]);

  useEffect(() => {
    if (props.answer && props.answer.answer) {
      updateDisabledGroup(props.answer?.answer);
    } else {
      setDisableSource([]);
    }
  }, [props.answer]);

  const updateDisabledGroup = (answers: string[]) => {
    const disabledSrc: string[] = [];
    // each object is used one time
    if (!props.item.setting.onlyOne) {
      answers.forEach((item) => {
        const ans = getDragNDropAns(item);
        disabledSrc.push(ans.drag);
      });
    }
    setDisableSource(disabledSrc);
  };

  const handleOnAnswerChange = (answer: string) => {
    setCompAnswer((prev) => {
      const indexGroup = answer.split(':')[0];

      const isExits = [...prev.value].some((ans) => {
        return ans.split(':')[0] === indexGroup;
      });

      if (isExits) {
        const newAns = [...prev.value].map((ans) => {
          if (ans.split(':')[0] === indexGroup) {
            return answer;
          } else {
            return ans;
          }
        });

        return {
          value: newAns,
          triggerChange: true,
        };
      } else {
        return {
          value: [...prev.value, answer],
          triggerChange: true,
        };
      }
    });
  };

  const updateOptions = (
    options: DropDownOptionProps[],
    type: CompGroupEnum
  ) => {
    if (type === CompGroupEnum.source) {
      setSourceItems(options);
    } else {
      setTargetItems(options);
    }
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateComponent = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration = {
        sourceItems: sourceItems,
        targetItems: targetItems,
      };
    });

    dispatch(
      updateComponentAndAnswer({
        comp: newComps,
        ans: {
          id: props.item.id,
          type: props.item.type,
          answer: answer.value,
        },
      })
    );
  };

  const updateAnswers = (newAnswers: string[]) => {
    setCompAnswer({
      value: newAnswers,
      triggerChange: true,
    });
  };

  return (
    <DropDownCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        originalData: props.item,

        sourceItems,
        targetItems,
        updateOptions,

        disabled: props.disabled,
        setting: props.item.setting,

        disableSource,

        answers: answer.value,
        feedback: feedback,

        updateComponent,
        updateAnswers,
        handleOnAnswerChange,
      }}
    >
      <CompFeedbackContextProvider feedBack={feedback}>
        {props.children}
      </CompFeedbackContextProvider>
    </DropDownCompContext.Provider>
  );
};

export const useDropDownCompContext = () => {
  const context = useContext(DropDownCompContext);
  if (!context) {
    throw new Error('You must wrap container by DragNDropCompContextProvider');
  }
  return context;
};
