import { GameUtils, GlossaryGameContent } from '@cms/comps/game/GameUtils';
import React, { useEffect, useState } from 'react';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import WordBoxTryTimes from '@cms/comps/game/common/WordTryTime';
import { WordScrambleSettingProps } from '@cms/comps/game/word-scramble/WordScrambleComp';
import WordScrambleDragItems from '@cms/comps/game/word-scramble/WordScrambleDragItems';
import WordScrambleDropItems from '@cms/comps/game/word-scramble/WordScrambleDropItems';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import styled from 'styled-components';

const WordScrambleItem = (props: {
  item: GlossaryGameContent;
  setting: WordScrambleSettingProps;

  onCorrectPart?: (numberTry: number, answer: any) => void; // correct answer but not complete
  onCorrect: (numberTry: number) => void;

  onIncorrectPart?: (numberTry: number, answer: any) => void; // incorrect answer but have change
  onIncorrect: (numberTry: number) => void;
}) => {
  const [numberTry, setNumberTry] = useState(0);

  const [answers, setAnswers] = useState<string[]>([]);
  const [disabledIds, setDisabledIds] = useState<number[]>([]);

  const [randomAnswers, setRandomAnswers] = useState<string[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);

  useEffect(() => {
    const correctAnswer = GameUtils.convertAnswer(props.item.word.data);
    const randomAnswers = [...correctAnswer].sort(() => 0.5 - Math.random());

    setAnswers(GameUtils.getHiddenAnswer(props.item.word.data));
    setRandomAnswers(randomAnswers);
    setCorrectAnswers(correctAnswer);

    setDisabledIds([]);
    setNumberTry(0);
  }, [JSON.stringify(props.item)]);

  useEffect(() => {
    if (answers.length > 0) {
      if (answers.includes(GameUtils.hiddenCharacter)) {
        if (props.onCorrectPart) {
          props.onCorrectPart(numberTry, answers);
        }
      } else {
        props.onCorrect(numberTry);
      }
    }
  }, [answers]);

  useEffect(() => {
    if (numberTry >= props.setting.tryTimes) {
      props.onIncorrect(numberTry);
    } else if (props.onIncorrectPart) {
      props.onIncorrectPart(numberTry, '');
    }
  }, [numberTry]);

  const handleOnDrop = (dragData: string, dropIndex: number) => {
    const dragLabel = dragData.split('|')[0];
    const dragIndex = Number(dragData.split('|')[1]);

    const isCorrect = correctAnswers[dropIndex] === dragLabel;

    if (isCorrect) {
      setAnswers((prevState) =>
        COMPONENT_UTILS.updateAtIndex(prevState, dropIndex, dragLabel)
      );
      setDisabledIds((prevState) => [...prevState, dragIndex]);
    } else {
      setNumberTry((prevState) => prevState + 1);
    }
  };

  return (
    <WordScrambleItemStyle className={'word-box-item'}>
      <div className={'word-chart-container'}>
        <WordScrambleDragItems
          randomAnswers={randomAnswers}
          disabledIds={disabledIds}
        />
      </div>

      <div className={'word-chard-direction-container'}>
        <div className={'word-box-content-display'}>
          <ContentViewer contentData={props.item.description} />
        </div>
      </div>

      <div className={'word-box-answer-container'}>
        <WordScrambleDropItems
          answers={answers}
          correctAnswer={correctAnswers}
          onDrop={handleOnDrop}
        />

        <WordBoxTryTimes
          type={'incorrect'}
          current={numberTry}
          total={props.setting.tryTimes}
        />
      </div>
    </WordScrambleItemStyle>
  );
};

export default WordScrambleItem;

const WordScrambleItemStyle = styled.div`
  display: flex;
  flex-direction: column;

  .word-chard-direction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .try-times-container {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
  }
`;
