import { Button, Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { PublishProductStyle } from '@modules/product/container/publish/ProductInformationStep';
import ScrollToTop from '@components/feature/ScrollToTop';
import LessonMetadata from '@modules/product/components/lesson/lesson-data/LessonMetadata';
import { ReviewProductContext } from '@modules/product/context/BuildProductContext';
import TableOfContent from '@modules/product/components/toc/TableOfContent';
import { useTranslation } from 'react-i18next';
import { InputGroup } from '@components/input/InputGroup';
import Card, { CardClassEnum } from '@components/card';
import { H2 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { ProductTocRes } from '@modules/product/services/product_model';
import { LessonRes } from '@modules/product/services/lesson_model';

export default function ProductLessonIntroduceStep(props: {
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();
  const { productId } = useContext(ReviewProductContext);

  const [selectLessonId, setSelectLessonId] = useState(-1);
  const [selectedProductTocId, setSelectedProductTocId] = useState(-1);
  const [productTocs, setProductTocs] = useState<ProductTocRes[]>([]);

  const setSelectLesson = (lesson: LessonRes, productToc: ProductTocRes) => {
    setSelectLessonId(lesson.lessonId);
    setSelectedProductTocId(productToc.productTocId);
  };

  return (
    <>
      <ScrollToTop visible={false} />

      <PublishProductStyle className={'publish-product-section'}>
        <H2>{t('publish.basic_info.introduction_lesson')}</H2>

        <div className={'product-publish-content'}>
          <ReviewProductContext.Provider
            value={{
              productId,
              selectLessonId,
              selectedProductTocId,
              setSelectLesson,
              productTocs,
              setProductTocs,
            }}
          >
            <Row gutter={[40, 20]} align="top" justify="center">
              <Col span={10}>
                <InputGroup label={t('product.table_of_content')}>
                  <TableOfContent />
                </InputGroup>
              </Col>

              <Col span={14}>
                {selectLessonId != null && selectLessonId > 0 && (
                  <InputGroup label={t('table_of_content.lesson_content')}>
                    <Card className={CardClassEnum.rectangle}>
                      <LessonMetadata
                        key={selectLessonId}
                        lessonId={selectLessonId}
                        onSuccess={() => {}}
                        onCancel={() => setSelectLessonId(-1)}
                      />
                    </Card>
                  </InputGroup>
                )}
              </Col>
            </Row>
          </ReviewProductContext.Provider>
        </div>

        <div className={'product-publish-step'}>
          <Button
            type={'default'}
            shape={'round'}
            onClick={() => props.goPrevious()}
            size={'large'}
          >
            {IconUtils.actions.previous} {t('button.step.previous')}
          </Button>

          <Button
            type={'primary'}
            shape={'round'}
            onClick={() => props.goNext()}
            size={'large'}
          >
            {t('button.step.next')} {IconUtils.actions.next}
          </Button>
        </div>
      </PublishProductStyle>
    </>
  );
}
