import React, { useEffect } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { InteractVideoSectionProps } from '@cms/section-bank/interact-video/InteractVideoSection';
import TimestampViewerEditable from '@cms/comps/content/audio/TimestampViewerEditable';
import { InputGroup } from '@components/input/InputGroup';
import { ContentViewerEditable } from '@cms/comps/content/ContentViewerComp';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { CompMode, TimelineProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsContentProps } from '@cms/content/ContentType';
import styled from 'styled-components';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';

export const InteractVideoSectionSetting = (props: {
  item: InteractVideoSectionProps;
  onChange: (setting: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      timeline: [] as TimelineProps[], //   [] as TimelineProps[] props.item.setting.timeline
      modifiedTime: -1,
    },
    onSubmit: (values) => {
      props.onChange(JSON.stringify({ timeline: values.timeline }));
    },
  });

  useEffect(() => {
    if (
      props.item.setting.timeline == null ||
      props.item.setting.timeline.length === 0 ||
      props.item.setting.timeline.length !== props.item.group.content.length //
    ) {
      const timelines: TimelineProps[] = props.item.group.content.map(
        (rs, index) => {
          return {
            label: index + '',
            from: index,
            to: index + 1,
            content: {
              id: COMPONENT_UTILS.generateUID(),
              type: AssetsTypeEnum.TEXT,
              data: 'Click to view question.',
            },
          };
        }
      );
      formik.setFieldValue('timeline', timelines);
      formik.setFieldValue('modifiedTime', DateAndTimeUtils.getCurrentTime());
      props.onChange(JSON.stringify({ timeline: timelines }));
    } else {
      formik.setFieldValue('timeline', props.item.setting.timeline);
      formik.setFieldValue('modifiedTime', DateAndTimeUtils.getCurrentTime());
    }
  }, [props.item.setting]);

  const updateOptionTimestamp = (
    from: number,
    to: number,
    index: number,
    timeline: TimelineProps
  ) => {
    const newOption = { ...timeline, from: from, to: to };
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(
      formik.values.timeline,
      index,
      newOption
    );

    formik.setFieldValue('timeline', newSourceItems);
    formik.setFieldValue('modifiedTime', DateAndTimeUtils.getCurrentTime());
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: TimelineProps
  ) => {
    const newOption = { ...option, content: { ...data } };
    const newSourceItems = COMPONENT_UTILS.updateAtIndex(
      formik.values.timeline,
      index,
      newOption
    );

    formik.setFieldValue('timeline', newSourceItems);
    formik.setFieldValue('modifiedTime', DateAndTimeUtils.getCurrentTime());
  };

  return (
    <InteractVideoStyle>
      <FormikProvider value={formik}>
        {formik.values.timeline.map((timeline, index) => {
          return (
            <div
              className={'timeline-item'}
              key={timeline.label + '-' + formik.values.modifiedTime}
            >
              <span className={'question-number'}>Q{index + 1}.&nbsp;</span>

              <TimestampViewerEditable
                from={timeline.from}
                to={timeline.to}
                onChange={(from, to) =>
                  updateOptionTimestamp(from, to, index, timeline)
                }
              />

              <InputGroup addedClass={'label-title'} label={'Text display'}>
                <ContentViewerEditable
                  mode={CompMode.COMPOSE}
                  contentData={timeline.content}
                  onChange={(data) => updateOptionData(data, index, timeline)}
                />
              </InputGroup>
            </div>
          );
        })}

        <Button
          type={'primary'}
          onClick={formik.submitForm}
          icon={IconUtils.actions.save}
        >
          {t('button.update')}
        </Button>
      </FormikProvider>
    </InteractVideoStyle>
  );
};

const InteractVideoStyle = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  width: 600px;
  font-size: 14px;

  .timeline-item {
    display: flex;
    align-items: center;
    gap: 0.25em;

    .question-number {
      font-weight: bold;
      display: inline-block;
    }

    &:not(:first-child) {
      .form-group .ant-form-label {
        display: none;
      }
    }

    .form-group {
      margin-bottom: 10px;
    }

    .from-group,
    .to-group {
      width: 75px;
    }

    .label-title {
      flex-grow: 1;
      margin-left: 1em;
    }
  }
`;
