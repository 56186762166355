import UpdateLessonDataModal, {
  UpdateLessonDataEnum,
} from '@modules/product/components/lesson/lesson-data/LessonDataModal';
import { LessonGroupStyle } from '@modules/product/components/lesson/Style';
import React, { useEffect, useMemo, useState } from 'react';

import { Divider, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Button, { ButtonClassEnum } from '@components/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import i18next from 'i18next';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import SwitchLanguage from '@components/header/SwitchLanguage';

import useConfirmModal from '@components/modal/ConfirmModal';
import { LessonReview } from '@modules/product/components/lesson/lesson-data/LessonPreview';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { IconUtils } from '@utils/IconUtils';
import { useProUser } from '@components/button/RequireProUserButton';
import { useRouter } from '@hooks/useRouter';
import { useSelector } from '@app/redux/hook';
import { SiteMap } from '@router/SiteMap';

export const useLoadLessonData = (lessonId: number, autoReload: boolean) => {
  const [getLesson, { data, isSuccess, error, isError, isFetching }] =
    LessonAPI.endpoints.getLesson.useLazyQuery({});

  useEffect(() => {
    if (autoReload) {
      refresh();
    }
  }, [lessonId, autoReload]);

  const refresh = () => {
    if (lessonId != null && lessonId > 0) {
      getLesson(lessonId);
    }
  };

  return {
    data,
    isSuccess,
    isFetching,
    error,
    isError,
    refresh,
  };
};

export const useLoadLessonResourceData = (lessonId: number) => {
  const [getLessonResources, { data, isSuccess, error, isError, isFetching }] =
    LessonAPI.endpoints.getLessonResources.useLazyQuery({});

  useEffect(() => {
    refresh();
  }, [lessonId]);

  const refresh = () => {
    getLessonResources(lessonId);
  };

  return {
    data,
    isSuccess,
    isFetching,
    error,
    isError,
    refresh,
  };
};

export const ResourceActionToolbar = (props: {
  lessonId: number;
  resourceId: number;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleGoBack = () => {
    if (props.lessonId != null && props.lessonId > 0) {
      router.push(SiteMap.content.lesson.list);
    } else {
      router.push(SiteMap.content.resource.list);
    }
  };

  return (
    <LessonGroupStyle className={'edit-lesson-actions exit-group'}>
      <div className={'action-group'}>
        <SwitchLanguage />

        <Button
          danger
          type={'primary'}
          shape={'round'}
          className={ButtonClassEnum.hover_text}
          onClick={handleGoBack}
        >
          {IconUtils.compose.exit_compose} {t('label.exit')}
        </Button>
      </div>
    </LessonGroupStyle>
  );
};

export const LessonActionToolbar = (props: {
  lessonId: number;
  productId?: number;
  productTocId?: number;
  editMode: EditModeEnum;
  onCloseFunc: () => void;
}) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const router = useRouter();
  const proUser = useProUser();

  const { changed } = useSelector((state) => state.composeResource);

  const [mode, changeToMode] = useState<UpdateLessonDataEnum>(
    UpdateLessonDataEnum.DEFAULT
  );

  const headerModal = useMemo(() => {
    switch (mode) {
      case UpdateLessonDataEnum.METADATA:
        return <>{t('lesson.actions.review')}</>;
      case UpdateLessonDataEnum.STANDARDS:
        return <>{t('lesson.actions.view_standards')}</>;
      case UpdateLessonDataEnum.SHARE:
        return <>{t('lesson.actions.share_to_student')}</>;
      case UpdateLessonDataEnum.EXTRACT:
        return <>{t('lesson.actions.extract_to_json')}</>;
      default:
        return '';
    }
  }, [mode, i18next.language]);

  const goToMultipleQuestion = () => {
    if (props.editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(() => {
        router.push(
          SiteMap.content.lesson.compose_gen(
            props.lessonId,
            proUser,
            props.productId,
            props.productTocId
          )
        );
      });
    } else {
      router.push(
        SiteMap.content.lesson.compose_gen(
          props.lessonId,
          proUser,
          props.productId,
          props.productTocId
        )
      );
    }
  };

  const goToBuildLesson = () => {
    if (props.editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(() => {
        router.push(
          SiteMap.content.lesson.build_resource_gen(
            props.lessonId,
            props.productId,
            props.productTocId
          )
        );
      });
    } else {
      router.push(
        SiteMap.content.lesson.build_section_gen(
          props.lessonId,
          props.productId,
          props.productTocId
        )
      );
    }
  };

  const goToBuildSection = () => {
    if (props.editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(() => {
        router.push(
          SiteMap.content.lesson.build_section_gen(
            props.lessonId,
            props.productId,
            props.productTocId
          )
        );
      });
    } else {
      router.push(
        SiteMap.content.lesson.build_section_gen(
          props.lessonId,
          props.productId,
          props.productTocId
        )
      );
    }
  };

  const goToSingleResource = () => {
    router.push(
      SiteMap.content.lesson.compose_resource_gen(
        props.lessonId,
        proUser,
        -1,
        props.productId,
        props.productTocId
      )
    );
  };

  const goBack = () => {
    if (props.editMode === EditModeEnum.SINGLE_QUESTION && changed.changed) {
      confirmChange(props.onCloseFunc);
    } else {
      props.onCloseFunc();
    }
  };

  const confirmChange = (callBackFunc: () => void) => {
    confirm(
      'warning',
      t('header.confirm'),
      t('resource.warning.exit_without_saving'),
      t('label.quit'),
      t('label.cancel'),
      (r) => {
        if (r) {
          callBackFunc();
        }
      }
    );
  };

  return (
    <>
      <LessonGroupStyle className={'edit-lesson-actions'}>
        <Space align={'center'}>
          <Tooltip
            title={t('lesson_compose.actions.multiple')}
            placement={'topLeft'}
          >
            <Button
              type={
                props.editMode === EditModeEnum.MULTIPLE_QUESTION
                  ? 'primary'
                  : 'default'
              }
              shape={'circle'}
              className={'action-button'}
              onClick={goToMultipleQuestion}
            >
              {IconUtils.compose.multiple_question}
            </Button>
          </Tooltip>

          <Tooltip
            title={t('lesson_compose.actions.single')}
            placement={'topLeft'}
          >
            <Button
              type={
                props.editMode === EditModeEnum.SINGLE_QUESTION
                  ? 'primary'
                  : 'default'
              }
              shape={'circle'}
              className={'action-button'}
              onClick={goToSingleResource}
            >
              {IconUtils.compose.multiple_question}
            </Button>
          </Tooltip>

          <Tooltip
            title={t('lesson_compose.actions.build')}
            placement={'topLeft'}
          >
            <Button
              type={
                props.editMode === EditModeEnum.BUILD_QUESTION
                  ? 'primary'
                  : 'default'
              }
              shape={'circle'}
              className={'action-button'}
              onClick={goToBuildLesson}
            >
              {IconUtils.compose.build_lesson}
            </Button>
          </Tooltip>

          <Tooltip
            title={t('lesson_compose.actions.section')}
            placement={'topLeft'}
          >
            <Button
              type={
                props.editMode === EditModeEnum.BUILD_SECTION
                  ? 'primary'
                  : 'default'
              }
              shape={'circle'}
              className={'action-button'}
              onClick={goToBuildSection}
            >
              {IconUtils.compose.build_section}
            </Button>
          </Tooltip>
        </Space>

        <Divider type={'vertical'} />

        <Space align={'center'}>
          <Tooltip
            title={t('lesson_compose.actions.review')}
            placement={'topLeft'}
          >
            <Button
              type={'default'}
              shape={'circle'}
              className={'action-button'}
              onClick={() => changeToMode(UpdateLessonDataEnum.REVIEW)}
            >
              {IconUtils.compose.review}
            </Button>
          </Tooltip>

          <Tooltip
            title={t('lesson_compose.actions.view_json')}
            placement={'topLeft'}
          >
            <Button
              type={'default'}
              shape={'circle'}
              className={'action-button'}
              onClick={() => changeToMode(UpdateLessonDataEnum.EXTRACT)}
            >
              {IconUtils.compose.extract}
            </Button>
          </Tooltip>
        </Space>

        <Divider type={'vertical'} />

        <Space align={'center'}>
          <SwitchLanguage />

          <Tooltip
            title={t('lesson_compose.actions.go_back')}
            placement={'topLeft'}
          >
            <Button
              danger
              type={'primary'}
              shape={'circle'}
              className={'action-button'}
              onClick={goBack}
            >
              {IconUtils.compose.exit_compose}
            </Button>
          </Tooltip>
        </Space>
      </LessonGroupStyle>

      {mode === UpdateLessonDataEnum.REVIEW ? (
        <CustomModal
          header={headerModal}
          className={`${CustomModalClassEnum.full_size_modal} ${CustomModalClassEnum.no_padding}`}
          content={<LessonReview lessonId={props.lessonId} />}
          onCloseFunc={() => {
            changeToMode(UpdateLessonDataEnum.DEFAULT);
          }}
        />
      ) : (
        <>
          {mode !== UpdateLessonDataEnum.DEFAULT && (
            <CustomModal
              header={headerModal}
              className={CustomModalClassEnum.full_size_modal}
              content={
                <UpdateLessonDataModal
                  type={'edit'}
                  action={mode}
                  lessonId={props.lessonId}
                  onCancel={() => changeToMode(UpdateLessonDataEnum.DEFAULT)}
                  onChange={(mode) => {
                    changeToMode(mode);
                  }}
                />
              }
              onCloseFunc={() => {
                changeToMode(UpdateLessonDataEnum.DEFAULT);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
