import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import InProgressPage from '@modules/authorization/pages/InProgressPage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const MyReports = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'my-reports-page'}>
      <PageHeader
        menu={NavigationMenuEnum.REPORTS}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('report.title')}
        description={t('report.title')}
      />

      <InProgressPage />
    </ManagementLayout>
  );
};

export default MyReports;
