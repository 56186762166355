import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LessonButton from './style';
import { IconUtils } from '@utils/IconUtils';

export const StartOverButton = () => {
  const { t } = useTranslation();

  const { isShowCorrectAnswer, isCheckAnswerMode, dispatchExerciseAction } =
    useViewLessonContext();

  const handleClearAnswer = () => {
    dispatchExerciseAction(ExerciseActionEnum.clear_correct_answer, null);
  };

  return (
    <>
      {!isShowCorrectAnswer && isCheckAnswerMode && (
        <LessonButton
          type={'primary'}
          shape={'round'}
          onClick={handleClearAnswer}
          icon={IconUtils.exercise.start_over}
        >
          {t('exercise.button.start_over')}
        </LessonButton>
      )}
    </>
  );
};
