import React from 'react';
import { RoleEnum } from '@app/redux/slices/roles';
import MyTeacherDashboardPage from '@modules/dashboard/pages/MyTeacherDashboardPage';
import MyStudentDashboardPage from '@modules/dashboard/pages/MyStudentDashboardPage';
import AdminDashboardPage from '@modules/dashboard/pages/AdminDashboardPage';
import Authorization from '@utils/authorization';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@app/header/PageHeader';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.ADMIN]}>
        <ManagementLayout className={'admin-dashboard-page'}>
          <PageHeader
            menu={NavigationMenuEnum.DASHBOARD}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('dashboard.header')}
            description={t('dashboard.header')}
          />

          <AdminDashboardPage />
        </ManagementLayout>
      </Authorization>

      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.SCHOOL, RoleEnum.SUPPORT]}
      >
        <ManagementLayout className={'school-dashboard-page'}>
          <PageHeader
            menu={NavigationMenuEnum.DASHBOARD}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('dashboard.header')}
            description={t('dashboard.header')}
          />

          <MyTeacherDashboardPage />
        </ManagementLayout>
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.TEACHER]}>
        <ManagementLayout className={'teacher-dashboard-page'}>
          <PageHeader
            menu={NavigationMenuEnum.DASHBOARD}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('dashboard.header')}
            description={t('dashboard.header')}
          />

          <MyTeacherDashboardPage />
        </ManagementLayout>
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
        <ManagementLayout className={'student-dashboard-page'}>
          <PageHeader
            menu={NavigationMenuEnum.DASHBOARD}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('dashboard.header')}
            description={t('dashboard.header')}
          />
          <MyStudentDashboardPage />
        </ManagementLayout>
      </Authorization>

      <Authorization type={'ifAnyGranted'} roles={[RoleEnum.PARENT]}>
        <ManagementLayout className={'teacher-dashboard-page'}>
          <PageHeader
            menu={NavigationMenuEnum.DASHBOARD}
            subMenu={NavigationMenuEnum.EMPTY}
            title={t('dashboard.header')}
            description={t('dashboard.header')}
          />
        </ManagementLayout>
      </Authorization>
    </>
  );
};

export default Dashboard;
