import { useEffect, useState } from 'react';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { LessonSectionProps } from '@cms/section-bank/SectionSetting';
import { LessonAPI } from '@modules/product/services/LessonAPI';
import { ProductTocAPI } from '@modules/product/services/ProductTocAPI';
import { LessonRes } from '@modules/product/services/lesson_model';

export function useViewLesson(
  lessonId: number,
  productId?: number,
  productTocId?: number
) {
  const [viewLessonInToc, { data, isSuccess, isError, error }] =
    ProductTocAPI.endpoints.viewLesson.useLazyQuery({});

  const [
    viewLesson,
    { data: lsData, isSuccess: lsSuccess, isError: lsIsError, error: lsError },
  ] = LessonAPI.endpoints.viewLesson.useLazyQuery({});

  const [ready, setReady] = useState(false);
  const [lessonData, setLessonData] = useState<LessonRes | null>(null);
  const [sections, setSections] = useState<LessonSectionProps[]>([]);

  useEffect(() => {
    if (
      productId != null &&
      productId > 0 &&
      productTocId != null &&
      productTocId > 0 &&
      lessonId != null &&
      lessonId > 0
    ) {
      viewLessonInToc({
        productId: productId,
        productTocId: productTocId,
        lessonId: lessonId,
      });
    } else if (lessonId != null && lessonId > 0) {
      viewLesson(lessonId);
    }
  }, [lessonId, productId, productTocId]);

  useEffect(() => {
    if (data && isSuccess) {
      updateLessonData(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (lsData && lsSuccess) {
      updateLessonData(lsData);
    }
  }, [lsData, lsSuccess]);

  const updateLessonData = (data: LessonRes) => {
    const sections = ResourceUtils.getSections(data.sections);
    setLessonData(data);
    setSections(sections);
    setReady(true);
  };

  return {
    ready,
    lessonData,
    sections,
    isSuccess: isSuccess || lsSuccess,
    isError: isError || lsIsError,
    error: error || lsError,
  };
}
