import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { CategoryRes, CreateCategoryReq } from '@modules/admin/service/model';

export const CategoryAPI = createApi({
  reducerPath: 'CategoryAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<CategoryRes>, PaginationReq>({
      query: (params) => ({
        url: `/categories/find-all`,
        params,
      }),
    }),

    findById: builder.query<CategoryRes, unknown>({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'GET',
      }),
    }),

    createCategory: builder.mutation<CategoryRes, CreateCategoryReq>({
      query: (body: CreateCategoryReq) => ({
        url: '/categories',
        method: 'POST',
        body,
      }),
    }),

    updateCategory: builder.mutation<CategoryRes, CreateCategoryReq>({
      query: (body: CreateCategoryReq) => ({
        url: `/categories/${body.categoryId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteCategory: builder.mutation<BasicMessageRes, unknown>({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
