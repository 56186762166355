import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExerciseActionEnum,
  useViewLessonContext,
} from '@cms/lesson-template/context/ViewLessonContext';
import { IconUtils } from '@utils/IconUtils';
import LessonButton from '../style';
import { useTheme } from '@app/styled/StyledProvider';

export const CompleteExerciseButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { dispatchExerciseAction } = useViewLessonContext();

  return (
    <LessonButton
      className={'complete-assignment-btn'}
      type={'primary'}
      shape={'round'}
      style={{ background: theme.exercise.submit }}
      onClick={() =>
        dispatchExerciseAction(ExerciseActionEnum.complete_assignment, '')
      }
    >
      {IconUtils.exercise.submit} {t('exercise.button.complete')}
    </LessonButton>
  );
};
