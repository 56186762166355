import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Button, notification } from 'antd';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserTeacherAPI } from '@modules/users/services/UserTeacherAPI';
import { ResetPasswordUserReq } from '@modules/users/services/model';

export const ResetUserPasswordForm = (props: {
  schoolId?: number;
  userId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const [resetPassword, { isLoading }] =
    UserTeacherAPI.endpoints.resetPassword.useMutation({});

  const { t } = useTranslation();
  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      password: '',
      repeatPassword: '',
    },

    validationSchema: yup.object().shape({
      password: yup.string().required(t('registration.required.password')),
      repeatPassword: yup
        .string()
        .required(t('registration.required.repeat_password'))
        .oneOf([yup.ref('password')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      const payload: ResetPasswordUserReq = {
        schoolId: props.schoolId,
        userId: props.userId,
        password: values.password,
      };

      resetPassword(payload)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('reset_password.warning.success'),
            description: t('reset_password.warning.success_message'),
            placement: 'bottomRight',
          });

          props.onSuccess();
        })
        .catch(() => {
          notification.error({
            message: t('reset_password.warning.error'),
            description: t('reset_password.warning.error_message'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Input
        id="password"
        name="password"
        type="password"
        label={t('label.password')}
        placeholder={t('form.enter_password')}
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <Input
        id="repeatPassword"
        name="repeatPassword"
        type="password"
        label={t('label.password_again')}
        placeholder={t('form.enter_password_again')}
        onChange={formik.handleChange}
        value={formik.values.repeatPassword}
      />

      <div className="submit-container">
        <ButtonGroup>
          <Button
            type={'primary'}
            loading={isLoading}
            onClick={formik.submitForm}
          >
            {t('button.reset_password')}
          </Button>

          <Button type="default" onClick={props.onCancel}>
            {t('button.back')}
          </Button>
        </ButtonGroup>
      </div>
    </FormikProvider>
  );
};
