import { WordPuzzleCompProps } from '@cms/comps/game/word-puzzle/WordPuzzleComp';
import produce from 'immer';
import { CompScoringProps } from '@cms/ComponentInteface';

class WordPuzzleValidation {
  getScoring = (comp: WordPuzzleCompProps): CompScoringProps => {
    const totalPart =
      comp.configuration.sourceItems.length +
      comp.configuration.targetItems.length;

    return {
      id: comp.id,
      type: comp.type,
      manualScore: false,
      maxScore: totalPart,
    };
  };

  validation = (comp: WordPuzzleCompProps): WordPuzzleCompProps => {
    const newComps = produce(comp, (draft) => {
      const accross = draft.configuration.sourceItems;
      const down = draft.configuration.targetItems;

      let min_x_char = 0;
      accross.forEach((wd) => {
        if (wd.word.data.trim().length > min_x_char) {
          min_x_char = wd.word.data.trim().length;
        }
      });

      let min_y_char = 0;
      down.forEach((wd) => {
        if (wd.word.data.trim().length > min_y_char) {
          min_y_char = wd.word.data.trim().length;
        }
      });

      if (min_x_char > draft.setting.maxColumns) {
        draft.setting.maxColumns = min_x_char;
      }

      if (min_y_char > draft.setting.maxRows) {
        draft.setting.maxRows = min_y_char;
      }
    });

    return newComps;
  };
}

export const WordPuzzleValidationUtils = new WordPuzzleValidation();
