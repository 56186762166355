import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  CategoryRes,
  GradeRes,
  LessonTypeRes,
  SubjectRes,
} from '@modules/admin/service/model';

export const MetadataAPI = createApi({
  reducerPath: 'MetadataAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findALlCategory: builder.query<CategoryRes[], string>({
      query: (clientId) => ({
        url: `/public/school/${clientId}/categories`,
      }),
    }),

    findALlGrades: builder.query<GradeRes[], string>({
      query: (clientId) => ({
        url: `/public/school/${clientId}/grades`,
      }),
    }),

    findAllSubjects: builder.query<SubjectRes[], string>({
      query: (clientId) => ({
        url: `/public/school/${clientId}/subjects`,
      }),
    }),

    findAllLessonType: builder.query<LessonTypeRes[], unknown>({
      query: () => ({
        url: `/public/school/lesson-type`,
      }),
    }),
  }),
});
