import React, { createContext, useContext, useEffect, useState } from 'react';

import { CompProps, TimelineProps } from '@cms/ComponentInteface';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import {
  AudioCompProps,
  AudioCompSettingProps,
} from '@cms/comps/content/audio/AudioComp';
import { AudioContentProps } from '@cms/content/ContentType';
import { updateComponent } from '@app/redux/slices/composeResource';

const AudioCompContext = createContext({
  modifiedTime: -1 as number,

  originalData: {} as AudioCompProps,
  setting: {} as AudioCompSettingProps,

  audioContent: {} as AudioContentProps,
  sourceItems: [] as TimelineProps[],

  updateAudioContent: (audioContent: AudioContentProps) => {
    console.log(audioContent);
  },

  updateOptions: (options: TimelineProps[]) => {
    console.log(options);
  },

  updateComponent: () => {},
});

export const AudioCompContextProvider = (props: {
  item: AudioCompProps;
  children: any;
}) => {
  const dispatch = useDispatch();

  const [modifiedTime, setModifiedTime] = useState(-1);

  const [audioContent, setAudioContent] = useState(
    props.item.configuration.content
  );
  const [sourceItems, setSourceItems] = useState(
    props.item.configuration.sourceItems
  );

  useEffect(() => {
    setAudioContent(props.item.configuration.content);
    setSourceItems(props.item.configuration.sourceItems);
  }, [props.item]);

  const updateOptions = (options: TimelineProps[]) => {
    setSourceItems(options);
    setModifiedTime(DateAndTimeUtils.getCurrentTime());
  };

  const updateAudioContent = (audioContent: AudioContentProps) => {
    setAudioContent(audioContent);
  };

  const updateComponentData = () => {
    const newComps: CompProps = produce(props.item, (draft) => {
      draft.configuration.sourceItems = sourceItems;
      draft.configuration.content = audioContent;
    });

    dispatch(updateComponent(newComps));
  };

  return (
    <AudioCompContext.Provider
      value={{
        modifiedTime: modifiedTime,
        originalData: props.item,
        audioContent,
        updateAudioContent,

        sourceItems,
        updateOptions,
        setting: props.item.setting,
        updateComponent: updateComponentData,
      }}
    >
      {props.children}
    </AudioCompContext.Provider>
  );
};

export const useAudioCompContext = () => {
  const context = useContext(AudioCompContext);
  if (!context) {
    throw new Error('You must wrap container by DragNDropCompContextProvider');
  }
  return context;
};
