import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { Button, Col, Row, Tag, Tooltip } from 'antd';
import ButtonGroup from '@components/button/ButtonGroup';
import { StandardAPI } from '@modules/product/services/StandardAPI';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import ColorUtils from '@utils/ColorUtils';
import { EditStandardForm } from '@modules/product/components/standards/SelectStandardForm';
import { EditStandardSetForm } from '@modules/product/components/standards/EditStandardSetForm';
import { Default_Gutter } from '@components/grid';
import SelectStandards from '@app/interact/SelectStandard';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';

export const ResourceStandardItem = (props: { standard: StandardRes }) => {
  return (
    <Tooltip
      openClassName={'resource-standard-tooltip'}
      title={
        <>
          <b>{props.standard.name}:&nbsp;</b>

          <i
            dangerouslySetInnerHTML={{
              __html: props.standard.description,
            }}
          />
        </>
      }
    >
      <Tag color={ColorUtils.getDefaultColor(props.standard.standardId).color}>
        <span
          style={{
            fontSize: 14,
            padding: '0.25em 1em',
            display: 'inline-block',
          }}
        >
          {props.standard.code}
        </span>
      </Tag>
    </Tooltip>
  );
};

export enum EditStandardModeEnum {
  select_standard = 'select-standard',
  add_standard = 'add-standard',
  add_standard_set = 'add-standard-set',
}

export const ResourceStandardForm = (props: {
  resourceId: number;
  onChange?: (mode: EditStandardModeEnum) => void;
  onCancel: () => void;
  onSuccess?: () => void;
}) => {
  const [mode, changeMode] = useState(EditStandardModeEnum.select_standard);
  const [addedStandard, setAddedStandard] = useState<StandardRes[]>([]);
  const [standardSet, setSelectStandardSet] = useState<StandardSetRes | null>(
    null
  );

  useEffect(() => {
    if (props.onChange) {
      props.onChange(mode);
    }
  }, [mode]);

  const changeToSelectStandardMode = () => {
    changeMode(EditStandardModeEnum.select_standard);
  };

  const changeToAddStandardMode = () => {
    changeMode(EditStandardModeEnum.add_standard);
  };

  const changeToAddStandardSet = () => {
    changeMode(EditStandardModeEnum.add_standard_set);
  };

  const handleOnCreatedStandardSet = (standardSet: StandardSetRes) => {
    setSelectStandardSet(standardSet);
    changeToAddStandardMode();
  };

  const handleOnCreatedStandard = (standard: StandardRes) => {
    setAddedStandard((prev) => {
      return [...prev, standard];
    });
    changeToSelectStandardMode();
  };

  const handleOnSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const handleOnCancel = () => {
    props.onCancel();
  };

  return (
    <ResourceMetadataFormStyle>
      {mode === EditStandardModeEnum.select_standard && (
        <SelectResourceStandard
          standards={addedStandard}
          resourceId={props.resourceId}
          onSuccess={handleOnSuccess}
          onCancel={handleOnCancel}
          changeToAddMode={changeToAddStandardMode}
        />
      )}

      {mode === EditStandardModeEnum.add_standard && (
        <EditStandardForm
          data={null}
          standardSet={standardSet}
          changeToAddStandardSet={changeToAddStandardSet}
          successHandle={handleOnCreatedStandard}
          onCloseFunc={changeToSelectStandardMode}
        />
      )}

      {mode === EditStandardModeEnum.add_standard_set && (
        <EditStandardSetForm
          mode={'compose'}
          data={null}
          successHandle={handleOnCreatedStandardSet}
          onCloseFunc={changeToAddStandardMode}
        />
      )}
    </ResourceMetadataFormStyle>
  );
};

export default ResourceStandardForm;

export const SelectResourceStandard = (props: {
  resourceId: number;
  standards: StandardRes[];
  onCancel: () => void;
  onSuccess?: () => void;
  changeToAddMode?: () => void;
}) => {
  const { t } = useTranslation();

  const [getStandardResource, { data, isSuccess, isFetching }] =
    StandardAPI.endpoints.getStandardResource.useLazyQuery();

  const [updateStandardResource, { isLoading }] =
    StandardAPI.endpoints.updateStandardResource.useMutation({});

  useEffect(() => {
    if (props.resourceId > 0) {
      getStandardResource(props.resourceId);
    }
  }, [props.resourceId]);

  useEffect(() => {
    if (data && isSuccess) {
      const standardIds = data.map((st) => {
        return st.standardId;
      });

      if (props.standards != null && props.standards.length > 0) {
        props.standards.forEach((st) => {
          if (!standardIds.includes(st.standardId)) {
            standardIds.push(st.standardId);
          }
        });
      }

      formik.setFieldValue('standards', standardIds);
    }
  }, [data, props.standards]);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      standards: [],
    },

    validationSchema: yup.object().shape({}),

    onSubmit: (values) => {
      const request = {
        resourceId: props.resourceId,
        standardIds: values.standards,
      };

      updateStandardResource(request)
        .unwrap()
        .then((res) => {
          if (res.success) {
            if (props.onSuccess) {
              props.onSuccess();
            } else {
              props.onCancel();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <SelectStandards
            formik={formik}
            changeToAddMode={props.changeToAddMode}
          />
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={24}>
          <ButtonGroup type={'right'} className="submit-container">
            <Button type={'default'} shape={'round'} onClick={props.onCancel}>
              {t('button.cancel')}
            </Button>

            <Button
              type={'primary'}
              shape={'round'}
              loading={isFetching || isLoading}
              onClick={formik.submitForm}
            >
              {t('button.update')}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </FormikProvider>
  );
};

const ResourceMetadataFormStyle = styled.div`
  width: 100%;
  font-size: 14px;
`;
