import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { SiteMap } from '@router/SiteMap';
import { LinkSample } from '@modules/public/features/Link';

export const RegistrationFeature = () => {
  return (
    <div className={'group'}>
      <FeatureGroup header={'Đăng kí tài khoản học sinh'}>
        <div>
          Chức năng này cho phép học sinh đăng kí tài khoản. Tài khoản học sinh
          không cần khai báo email.
        </div>
        <dd>
          <dt>Đăng kí với thông tin hợp lý</dt>
          <dl>
            <ol>
              <li>
                Truy cập Đăng kí <LinkSample href={SiteMap.register.index} />
              </li>

              <li>Chọn đăng kí học sinh</li>

              <li>Nhập username và mật khẩu ex (ktranstudent_01, 123456)</li>

              <li>Đăng kí thành công</li>

              <li>
                Quay lại trang đăng nhập{' '}
                <LinkSample href={SiteMap.auth.login} />
              </li>
              <li>Nhập username và mật khẩu vừa tạo</li>
              <li> Đăng nhập thành công.</li>

              <li>
                Kiểm tra username đã được sử dụng hoặc usersname không đúng
                format.
              </li>
            </ol>
          </dl>

          <dt>Check list</dt>
          <dl>
            <ol>
              <li>Gửi request</li>
              <li>Tạo student with status activated.</li>
              <li>Add student to school</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Đăng kí tài khoản Giáo viên'}>
        <div>
          Chức năng này cho phép giáo viên đăng kí tài khoản. Tài khoản của giáo
          viên cần khai báo email.
        </div>
        <dd>
          <dt>Đăng kí với thông tin hợp lý</dt>
          <dl>
            <ol>
              <li>
                Truy cập Đăng kí <LinkSample href={SiteMap.register.index} />
              </li>

              <li>Chọn đăng kí giáo viên</li>

              <li>
                Truy cập yopmail và tạo 1 email ngẫu nhiên ktranteacher_01
              </li>
              <li>
                Nhập username và mật khẩu ex (ktranteacher_01, 123456) và cung
                cấp email vừa tạo
              </li>

              <li>
                Đăng kí và hiển thị modal đăng kí thành công. Click vào login để
                quay lại trang đăng nhập{' '}
              </li>

              <li>Nhập username và mật khẩu vừa tạo</li>
              <li>Không thể đăng nhập vì tài khoản chưa kích hoạt.</li>
              <li>
                Kiểm tra hộp thư và kích hoạt tài khoản{' '}
                <LinkSample href={SiteMap.register.active_user} />
              </li>

              <li>
                Thông báo kích hoạt tài khoản thành công, chuyển sang page{' '}
                <LinkSample href={SiteMap.register.success} />. Click đăng nhập
                để quay lại
              </li>

              <li> Đăng nhập thành công.</li>

              <li>
                Kiểm tra username/ email đã được sử dụng hoặc username không
                đúng format.
              </li>
            </ol>
          </dl>

          <dt>Check list</dt>
          <dl>
            <ol>
              <li>Gửi request</li>
              <li>Tạo teacher account with status inactive</li>
              <li>Add teacher to school with status inactive</li>
              <li>Send activation email</li>
              <li>Click activation link</li>
              <li>Send activate request to server</li>
              <li>Update status of user from inactive to activated.</li>
              <li>
                Update status of user of school from inactive to activated.
              </li>
              <li>Login with teacher account to confirm.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Đăng kí tài khoản Phụ huynh'}>
        <div>Tương tự với đăng kí tài khoản teacher.</div>

        <div>
          Chức năng này cho phép phụ huynh đăng kí đăng kí tài khoản. Tài khoản
          của phụ huynh cần khai báo email. Phụ huynh có thể liên kết với con
          của họ ngay tại thời điểm đăng kí.
        </div>
      </FeatureGroup>

      <FeatureGroup header={'Outside'}>
        <div>
          <p>
            Chức năng đăng kí yêu cầu gửi email để kích hoạt tài khoản. Email
            kích hoạt thuộc quản lý của school admin.
          </p>

          <p>
            Kiểm tra nội dung email kích hoạt với các role là teacher và parent.
          </p>

          <p>Kiểm tra nội dung email gửi về.</p>
        </div>

        <dd>
          <dt>Check list</dt>
          <dl>
            <ol>
              <li>Gửi request</li>
              <li>Tạo parent account with status inactive</li>
              <li>Add parent to school with status inactive</li>
              <li>
                If request included student information, create student_parent
              </li>
              <li>Send activation email</li>
              <li>Click activation link</li>
              <li>Send activate request to server</li>
              <li>Update status of parent from inactive to activated.</li>
              <li>
                Update status of parent of school from inactive to activated.
              </li>
              <li>Login with parent account to confirm.</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Quên mật khẩu'}>
        <div>
          Chức năng này giúp các user có liên kết với email lấy lại mật khẩu nếu
          quên.
        </div>

        <dd>
          <dt>Lấy lại mật khẩu với thông tin hợp lý</dt>
          <dl>
            <ol>
              <li>
                Truy cập Đăng nhập <LinkSample href={SiteMap.register.index} />
              </li>

              <li>
                Chọn quên mật khẩu{' '}
                <LinkSample href={SiteMap.auth.forgot_password} />
              </li>

              <li>Nhập email đăng kí để lấy lại mật khẩu</li>

              <li>
                Kiểm tra email và click vào link thay đổi mật khẩu{' '}
                <LinkSample href={SiteMap.auth.reset_password} />
              </li>

              <li>Nhập mật khẩu mới</li>

              <li>Quay lại trang đăng nhập để đăng nhập lại</li>

              <li> Đăng nhập thành công.</li>
            </ol>
          </dl>

          <dt>Lấy lại mật khẩu với thông tin không hợp lý</dt>
          <dl>
            forgot-password
            <li>
              Truy cập Đăng nhập <LinkSample href={SiteMap.register.index} />
            </li>
            <li>
              Chọn quên mật khẩu{' '}
              <LinkSample href={SiteMap.auth.forgot_password} />
            </li>
            <li>
              Nhập email không đúng format --&gt; thông báo email không hợp lệ
            </li>
            <li>
              Nhập emial chưa bao giờ sử dụng --&gt; thông báo email không tồn
              tại
            </li>
          </dl>
        </dd>

        <dd>
          <dt>Check list</dt>
          <dl>
            <ol>
              <li>Gửi request</li>
              <li>
                Tạo user request với type = forgot password và email = email mà
                user đã đăng kí. Một request bao gồm 1 uid và 1 secret code với
                9 chữ cái, và 1 expired date.
              </li>
              <li>
                Tạo user request với thông tin type, email -&gt; user_id, tạo
                ngẫu nhiên mật khẩu và gửi về email
              </li>
              <li>User check email and click reset password link</li>
              <li>View reset password page, change password and confirm</li>
              <li>
                Kiểm tra thông tin của request gửi về. đảm bảo request đó đúng
                với request trong hệ thống nhờ kiểm tra uid, secret code và
                expired date
              </li>
              <li>
                Kiểm tra action và tiến hành update lại. Lưu ý user request có
                thể có nhiều loại request.
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Logout'}>
        <div>Kiểm tra chức năng logout</div>

        <dd>
          <dt>Logout</dt>
          <dl>
            <ol>
              <li>Đăng nhập vào tài khoản bất kì</li>

              <li>Mở thêm tab thứ 2</li>

              <li>Click Logout</li>

              <li>
                2 cửa sổ đều phải sang trang login và toàn bộ thông tin của user
                bị xóa
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Kích hoạt tài khoản'}>
        <div>
          Chức năng này giúp học sinh đăng nhập nhanh vào tài khoản của mình sau
          khi giáo viên tạo tài khoản cho họ
        </div>

        <dd>
          <dt>Active Account</dt>
          <dl>
            <ol>
              <li>....</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
