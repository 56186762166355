import { SessionAPI } from '@services/private/SessionAPI';
import React, { useEffect } from 'react';
import { ClassRoomExerciseStyle } from '@classroom/container/task/style';
import { ViewStudentExercise } from '@classroom/container/task/ViewStudentTask';

export const ClassRoomParticipantTask = (props: {
  sessionId: number;
  sessionTaskId: number;
}) => {
  const [getTaskExercise, { data: instancesData, isSuccess: instanceSuccess }] =
    SessionAPI.endpoints.getTaskExercise.useLazyQuery();

  useEffect(() => {
    if (props.sessionId > 0 && props.sessionTaskId > 0) {
      getTaskExercise({
        sessionId: props.sessionId,
        sessionTaskId: props.sessionTaskId,
      });
    }
  }, [props.sessionId, props.sessionTaskId]);

  return (
    <ClassRoomExerciseStyle className={'class-room-task'}>
      {instancesData && instanceSuccess && (
        <div className={'class-room-exercise-item'}>
          <ViewStudentExercise taskInstance={instancesData} />
        </div>
      )}
    </ClassRoomExerciseStyle>
  );
};
