import { createSlice } from '@reduxjs/toolkit';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';

export interface FilterProductProps {
  keyword: string;
  grades: number[],
  subjects: number[],
  categories: number[],
  submitted: boolean;
  currentTime: number
}

const initialState: FilterProductProps = {
  keyword: '',
  grades: [],
  subjects: [],
  categories: [],
  submitted: false,
  currentTime: 0,
};

const filterProductAndCourse = createSlice({
  name: 'filterProductAndCourse',
  initialState,

  reducers: {
    updateGrades(state, action) {
      state.grades = action.payload;
    },

    updateSubjects(state, action) {
      state.subjects = action.payload;
    },

    updateCategory(state, action) {
      state.categories = action.payload;
    },

    filterKeyword: (state, action) => {
      state.keyword = action.payload;
      state.submitted = true;
      state.currentTime = DateAndTimeUtils.getCurrentTime();
    },

    clearFilter: (state) => {
      state.submitted = false;
    }
  },
});

const { actions, reducer } = filterProductAndCourse;
export const {
  updateGrades, updateSubjects, updateCategory, filterKeyword, clearFilter
} = actions;
export default reducer;
