import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import {
  LessonTemplateWrapper,
  useLessonTemplateContext,
} from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import styled from 'styled-components';
import { useLessonNavigationContext } from '@cms/lesson-template/context/LessonNavigationContext';
import LessonSection from '@cms/section-bank/components/SectionItem';
import { useTranslation } from 'react-i18next';

export const ExerciseSections = () => {
  const { t } = useTranslation();
  const { section } = useLessonNavigationContext();
  const { type, sections } = useViewLessonContext();
  const { config } = useLessonTemplateContext();

  return (
    <LessonTemplateWrapper>
      <LessonSectionGroupStyle
        className={'lesson-section-group exercise-sections'}
      >
        {sections.length > 0 ? (
          <>
            {sections.map((item, index) => {
              const visible = Boolean(
                !config.singleMode || section.sectionId === item.lessonSectionId
              );

              return (
                <React.Fragment
                  key={JSON.stringify(item) + '_' + index + '_' + visible}
                >
                  <LessonSection visible={visible} item={item} mode={type} />
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <div className={'lesson-empty-warning'}>
            {t('exercise.warning.empty_lesson')}
          </div>
        )}
      </LessonSectionGroupStyle>
    </LessonTemplateWrapper>
  );
};

const LessonSectionGroupStyle = styled.div`
  .lesson-section {
    margin-top: var(--cms-padding-section, 2em);
  }

  .lesson-empty-warning {
    font-size: 125%;
    line-height: 2;
  }
`;
