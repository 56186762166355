import { H3 } from '@components/typography';
import React, { useEffect, useState } from 'react';
import { ExerciseStandardRes } from '@services/reports/LessonReport';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';
import { Space, Tag, Typography } from 'antd';
import ColorUtils from '@utils/ColorUtils';
import styled from 'styled-components';
import {
  LessonResult,
  LessonScore,
} from '@modules/product/components/toc/TableOfContentProgress';
import NavLink from '@components/button/NavLink';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { IconUtils } from '@utils/IconUtils';
import Button from '@components/button';
import { SiteMap } from '@router/SiteMap';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import { ExerciseScoreOverallRes } from '@modules/assignments/service/exercise_model';
import { useExerciseServiceContext } from '@cms/service/ExerciseServiceContext';

const { Text } = Typography;

export interface ScoreStandard {
  standard: StandardRes;
  score: number;
  maxScore: number;
}

export interface ScoreStandardSet {
  standardSet: StandardSetRes;
  score: number;
  maxScore: number;
  standards: ScoreStandard[];
}

export const ExerciseScoreResult = (props: { exerciseId: number }) => {
  const { t } = useTranslation();

  const { viewScores } = useExerciseServiceContext();

  const [data, setData] = useState<ExerciseScoreOverallRes | null>(null);
  const [showDetail, setShowDetail] = useState(true);

  const [score, setScore] = useState({
    score: 0,
    maxScore: 1,
  });

  const [standardSetScore, setStandardSetScore] = useState<ScoreStandardSet[]>(
    []
  );

  useEffect(() => {
    viewScores((data) => {
      setData(data);
      showStandardSetScore(data);
    });
  }, [props.exerciseId]);

  const showStandardSetScore = (data: ExerciseScoreOverallRes) => {
    setScore({
      score: data.score,
      maxScore: data.maxScore,
    });

    const scores: ExerciseStandardRes[] = [...data.scores].sort((a, b) => {
      return (
        a.standard.standardSet.displayOrder -
        b.standard.standardSet.displayOrder
      );
    });

    const standardSets: ScoreStandardSet[] = [];
    let currentScore: ScoreStandardSet | null = null;

    scores.forEach((scr) => {
      if (
        currentScore == null ||
        currentScore.standardSet.standardSetId !==
          scr.standard.standardSet.standardSetId
      ) {
        if (currentScore != null) {
          standardSets.push(currentScore);
        }

        currentScore = {
          standardSet: scr.standard.standardSet,
          score: scr.score,
          maxScore: scr.maxScore,
          standards: [
            {
              standard: scr.standard,
              score: scr.score,
              maxScore: scr.maxScore,
            },
          ],
        };
      } else {
        currentScore.score += scr.score;
        currentScore.maxScore += scr.maxScore;
        currentScore.standards.push({
          standard: scr.standard,
          score: scr.score,
          maxScore: scr.maxScore,
        });
      }
    });

    if (currentScore != null) {
      standardSets.push(currentScore);
    }

    setStandardSetScore(standardSets);
  };

  if (data && data.maxScore > 0) {
    return (
      <ExerciseScoreResultStyle className={'exercise-score-result'}>
        <div className={'exercise-score-detail'}>
          <div className={'exercise-score-overall'}>
            <H3
              className={'exercise-score-header'}
              onClick={() => setShowDetail((prev) => !prev)}
            >
              <LessonScore score={score.score} maxScore={score.maxScore} />
              {t('exercise.actions.score_detail')}:
            </H3>

            {data && (
              <>
                <Authorization type={'ifAnyGranted'} roles={[RoleEnum.STUDENT]}>
                  <NavLink
                    href={SiteMap.reports.view_lesson_gen(
                      data.lessonId,
                      data.productId,
                      data.productTocId
                    )}
                  >
                    <Button
                      type={'primary'}
                      size={'large'}
                      icon={IconUtils.exercise.report}
                    >
                      {t('exercise.actions.view_report')}
                    </Button>
                  </NavLink>
                </Authorization>

                {data.assignmentInstance != null && (
                  <Authorization
                    type={'ifAnyGranted'}
                    roles={[RoleEnum.TEACHER]}
                  >
                    <NavLink
                      href={SiteMap.reports.view_exercise_gen(
                        data.assignmentInstance.assignmentInstanceId
                      )}
                    >
                      <Button
                        type={'primary'}
                        size={'large'}
                        icon={IconUtils.exercise.report}
                      >
                        {t('exercise.actions.view_report')}
                      </Button>
                    </NavLink>
                  </Authorization>
                )}
              </>
            )}
          </div>

          {showDetail && standardSetScore.length > 0 && (
            <div className={'standard-set-score-items'}>
              {showDetail && (
                <Button
                  className={'close-standard-score'}
                  type={'text'}
                  onClick={() => setShowDetail(false)}
                  icon={IconUtils.close}
                />
              )}

              {standardSetScore.map((st) => {
                return (
                  <div
                    className="standard-set-score-item"
                    key={st.standardSet.standardSetId}
                  >
                    <Space align={'baseline'}>
                      <Tag
                        color={
                          ColorUtils.getDefaultColor(
                            st.standardSet.standardSetId
                          ).color
                        }
                      >
                        {st.standardSet.code}
                      </Tag>

                      <Text>
                        <LanguageContentTag
                          content={st.standardSet.description}
                        />
                      </Text>

                      <span>
                        <LessonResult score={st.score} maxScore={st.maxScore} />
                      </span>
                    </Space>

                    <div className={'standard-score-items'}>
                      {st.standards.map((std) => {
                        return (
                          <StandardScoreItem
                            key={std.standard.standardId}
                            item={std}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ExerciseScoreResultStyle>
    );
  } else {
    return null;
  }
};

const StandardScoreItem = (props: { item: ScoreStandard }) => {
  return (
    <StandardScoreItemStyle className={'standard-score-item'}>
      <div className={'standard-score-info'}>
        <Tag
          color={
            ColorUtils.getDefaultColor(props.item.standard.standardId).color
          }
        >
          {props.item.standard.code}
        </Tag>

        <Text>
          <i>
            <LanguageContentTag content={props.item.standard.description} />
          </i>
        </Text>
      </div>

      <div className={'standard-score-score'}>
        <LessonResult score={props.item.score} maxScore={props.item.maxScore} />
      </div>
    </StandardScoreItemStyle>
  );
};

const StandardScoreItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExerciseScoreResultStyle = styled.div`
  margin-bottom: 2em;
  position: relative;

  .exercise-score-detail {
    position: relative;

    .exercise-score-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .ant-typography {
        margin-bottom: 0px;
      }

      .exercise-score-header {
        cursor: pointer;
      }

      .score-display {
        margin-right: 1em;
        width: 3em;
        height: 3em;
        font-size: 15px;
      }
    }
  }

  .standard-set-score-items {
    margin-top: 1em;
    position: relative;
    border: 1px solid #ccc;
    background: #fff;
    padding: 1em 3em 1em 1em;

    .close-standard-score {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(0%, 0%);
    }

    .standard-set-score-item {
      &:not(:last-child) {
        margin-bottom: 1em;
      }

      > .ant-space {
        width: 100%;

        .ant-space-item:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }

  .standard-score-items {
    padding: 0.5em 0.5em;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0.5em;
    margin-top: 0.5em;
    margin-left: 3em;

    .standard-score-item {
      &:not(:first-child) {
        margin-top: 0.25em;
      }

      .ant-progress-text {
        min-width: 60px;
        text-align: center;
      }
    }
  }
`;
