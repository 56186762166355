import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { ImageCompProps } from '@cms/comps/content/ImageComp';

export const SampleImageData: ImageCompProps = {
  id: 'sample-image-data',
  type: CompTypeEnum.IMAGE,

  configuration: {
    content: {
      id: 'sample-image-data',
      type: AssetsTypeEnum.IMAGE,
      data: 'https://gstudy.sgp1.digitaloceanspaces.com/studygroup/library/ykAV4jM2eag2ApwA.image_sample.png',
    },
  },
};
