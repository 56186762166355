import { CompMode } from '@cms/ComponentInteface';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { ResourceNavigationProps } from '@cms/lesson-template/context/ViewLessonContext';
import { LessonSectionRes } from '@modules/product/services/lesson_model';

export const getQuestionNavigation = (
  sections: LessonSectionRes[],
  _resourceId: number,
  type: CompMode
): ResourceNavigationProps => {
  let resourceId = _resourceId;

  if (resourceId > 0 || type !== CompMode.COMPOSE) {
    let match = false;
    let currentIndex = -1;
    let current = null as ResourceProps | null;

    let currentData = {
      sectionId: -1,
      resourceId: resourceId,
    };

    let prev = {
      sectionId: -1,
      resourceId: -1,
    };

    let next = {
      sectionId: -1,
      resourceId: -1,
    };

    const resources = ResourceUtils.getResources(sections);

    if (resourceId < 0 && resources.length > 0) {
      resourceId = resources[0].resourceId;
    }

    resources.forEach((rs, index) => {
      if (
        currentData.resourceId < 0 ||
        rs.resourceId === currentData.resourceId
      ) {
        currentData = {
          sectionId: rs.sectionId!,
          resourceId: rs.resourceId,
        };
        current = rs;
        currentIndex = index;
        match = true;
      } else {
        if (match) {
          if (next.sectionId < 0) {
            next = {
              sectionId: rs.sectionId!,
              resourceId: rs.resourceId,
            };
          }
        } else {
          prev = {
            sectionId: rs.sectionId!,
            resourceId: rs.resourceId,
          };
        }
      }
      index++;
    });

    const firstQuestion = currentIndex === 0;
    const lastQuestion = currentIndex === resources.length - 1;

    return {
      currentId: currentData.resourceId,
      currentIndex: currentIndex,
      currentData: currentData,
      next: next,
      previous: prev,
      current: current,
      first: firstQuestion,
      last: lastQuestion,
    };
  } else {
    const resources = ResourceUtils.getResources(sections);
    const first = resources.length === 0;
    const last = false;

    const previous =
      resources.length > 0
        ? {
            sectionId: -1,
            resourceId: resources[resources.length - 1].resourceId,
          }
        : {
            sectionId: -1,
            resourceId: -1,
          };

    return {
      currentId: -1,
      currentIndex: resources.length,
      currentData: {
        sectionId: -1,
        resourceId: -1,
      },
      next: {
        sectionId: -1,
        resourceId: -1,
      },
      previous: previous,
      current: null,
      first: first,
      last: last,
    };
  }
};
