import React from 'react';

import ReviewMyPublicSitePage from '@modules/setting/pages/ReviewPublicSitePage';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';

const ReviewSchool = () => {
  return (
    <ManagementLayout className={'review-school-page'}>
      <PageHeader
        menu={NavigationMenuEnum.SETTING}
        subMenu={NavigationMenuEnum.SETTING_SCHOOL}
        title={'Review School'}
        description={'Review School'}
      />

      <ReviewMyPublicSitePage />
    </ManagementLayout>
  );
};

export default ReviewSchool;
