import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateLessonTypeReq,
  LessonTypeRes,
} from '@modules/admin/service/model';

export const LessonTypeAPI = createApi({
  reducerPath: 'LessonTypeAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<LessonTypeRes>, unknown>({
      query: () => ({
        url: `/lesson-type/find-all`,
      }),
    }),

    findById: builder.query<LessonTypeRes, unknown>({
      query: (id) => ({
        url: `/lesson-type/${id}`,
        method: 'GET',
      }),
    }),

    createLessonType: builder.mutation<LessonTypeRes, CreateLessonTypeReq>({
      query: (body: CreateLessonTypeReq) => ({
        url: '/lesson-type',
        body,
        method: 'POST',
      }),
    }),

    updateLessonType: builder.mutation<LessonTypeRes, CreateLessonTypeReq>({
      query: (body: CreateLessonTypeReq) => ({
        url: `/lesson-type/${body.lessonTypeId}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteLessonType: builder.mutation<BasicMessageRes, unknown>({
      query: (id) => ({
        url: `/lesson-type/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
