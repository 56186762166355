// import { useTheme } from '@app/styled/StyledProvider';

import {
  appColors,
  componentColors,
  eventColors,
  exerciseColors,
  menuColors,
  statusColors,
  ThemeAppColor,
  ThemeComponentColor,
  ThemeEventColor,
  ThemeExerciseColor,
  ThemeMenuColor,
  ThemeStatusColor,
  ThemeWarningColor,
  warningColors,
} from '@app/styled/model';

export const getThemeColor = (theme: string) => {
  if (theme === 'purple') {
    return getColorArray('#362036', '#B785B7', '#212529');
  } else if (theme === 'blue') {
    return getColorArray('#1c375b', '#6f8197', '#212529');
  } else if (theme === 'white') {
    return getColorArray('#525FE1', '#FFA41B', '#F86F03');
  } else if (theme === 'default') {
    return getThemeColors(
      appColors,
      menuColors,
      warningColors,
      eventColors,
      statusColors,
      exerciseColors,
      componentColors
    );
  } else {
    return default_theme;
  }
};

export const getThemeColors = (
  app: ThemeAppColor,
  menu: ThemeMenuColor,
  warning: ThemeWarningColor,
  event: ThemeEventColor,
  status: ThemeStatusColor,
  exercise: ThemeExerciseColor,
  component: ThemeComponentColor
) => {
  return {
    color: {
      white: '#fff',
      black: '#000',
      grey: '#ccc',
      dark_grey: '#333',
    },

    app: {
      primary: app.primary,
      primary_text: app.primaryText,
      primary_bgr: app.primaryBgr,

      background: '#ebecf0', //'#fafafa'
      border: '#ccc',

      secondary: '#212529',
      secondary_text: '#FFF',
    },

    menu: {
      bgr: menu.secondaryBgr,
      border: menu.secondary,

      text: menu.primary,
      background: menu.primaryBgr,

      active: {
        text: menu.secondary,
        background: menu.primary,
        submenu_bgr: menu.primaryBgr,
      },
    },

    warning: {
      info: warning.info,
      success: warning.success,
      warning: warning.warning,
      error: warning.error,
      error_bgr: warning.errorBgr,
    },

    event: {
      primary: event.today,
      primary_bgr: event.today,
      default: event.pass,
      future: event.future,
    },

    status: {
      activated: status.activated,
      inactivated: status.inactivated,
      removed: status.removed,

      pending: status.pending,
      published: status.published,

      empty: status.inactivated,
      not_started: status.inactivated,
      in_progress: status.activated,
      completed: status.published,
    },

    assignment: {
      pending: status.inactivated,
      pending_bgr: status.inactivatedBgr,

      active: status.activated,
      active_bgr: status.activatedBgr,

      expired: status.removed,
      expired_bgr: status.removedBgr,

      not_started: status.inactivated,
      in_progress: status.activated,
      need_grading: status.pending,
      completed: status.published,
    },

    exercise: {
      save: exercise.save,
      submit: exercise.submit,
      review: exercise.review,

      exit_grading: exercise.exit,
      exit_grading_text: exercise.exitBgr,

      need_grading_bgr: exercise.needGrading,

      check_answer: exercise.submit,
      view_report: exercise.review,
    },

    component: {
      primary: component.primary,
      primary_bgr: component.primaryBgr,

      secondary: component.secondary,
      secondary_bgr: component.secondaryBgr,

      disabled: component.disabled,

      //score
      correct: component.correct,
      incorrect: component.incorrect,
      incorrect_bgr: component.incorrectBgr,
      not_started: component.notStart,
      skip: component.skip,
      warning: component.skip,
      need_grading: component.needGrading,

      // show correct answer
      correct_answer: component.correctAnswer,
      correct_answer_bgr: component.correctAnswerBgr,
    },
  };
};

const getColorArray = (
  primary: string,
  primaryBgr: string,
  secondary: string
) => {
  return {
    white: '#FFF',

    color: {
      white: '#fff',
      black: '#000',
      grey: '#ccc',
      dark_grey: '#333',
    },

    app: {
      primary: primary,
      primary_text: '#FFF',
      primary_bgr: primaryBgr,
      background: '#ebecf0', //'#fafafa'
      border: '#ccc',

      secondary: '#212529',
      secondary_text: '#FFF',
    },

    warning: {
      info: '#0474BB',
      success: '#00A14B',
      warning: '#f58220',
      error: '#ED1F24',
      error_bgr: '#f9d0d0',
    },

    menu: {
      bgr: '#FFF',
      border: '#ccc',

      text: primary,
      background: primaryBgr,

      active: {
        text: '#FFF',
        background: primary,
        submenu_bgr: primaryBgr,
      },
    },

    event: {
      primary: primary,
      primary_bgr: primaryBgr,
      default: '#e2d8f9',
      future: '#0474BB',
    },

    status: {
      activated: '#0474BB',
      inactivated: '#CCC',
      removed: '#ED1F24',

      pending: '#f58220',
      published: '#884ffb',
      empty: '#ccc',

      not_started: '#ccc',
      in_progress: '#0474BB',
      completed: '#884ffb',
    },

    exercise: {
      check_answer: '#00A14B',
      view_report: '#f58220',

      save: '#0474BB',
      submit: '#00A14B',
      review: '#f58220',

      exit_grading: '#333',
      exit_grading_text: '#FFF',

      need_grading_bgr: '#e2d8f9',
    },

    assignment: {
      pending: '#cccccc',
      pending_bgr: '#cccccc',

      active: '#0474BB',
      active_bgr: '#cde3f6',

      expired: '#ED1F24',
      expired_bgr: '#f9d0d0',

      not_started: '#ED1F24',
      in_progress: '#f58220',
      need_grading: '#0474BB',
      completed: '#00A14B',
    },

    component: {
      primary: primary,
      primary_bgr: primaryBgr,

      secondary: '#ccc',
      secondary_bgr: '#f5f5f5',

      disabled: '#ccc',

      //score
      correct: '#00A14B',
      incorrect: '#ED1F24',
      incorrect_bgr: '#f9d0d0',
      not_started: '#ccc',
      skip: '#f58220',
      warning: '#f58220',
      need_grading: '#0474BB',

      // show correct answer
      correct_answer: '#d424ff',
      correct_answer_bgr: 'rgba(212, 36, 255, 0.3)',
    },
  };
};

const primary = '#11009E';
const primary_bgr = '#EEEEEE';
const secondary = '#C4B0FF';
const background = '#fafafa';

const default_theme = {
  white: '#FFF',

  color: {
    white: '#fff',
    black: '#000',
    grey: '#ccc',
    dark_grey: '#333',
  },

  app: {
    primary: primary,
    primary_text: '#FFF',
    primary_bgr: primary_bgr,
    background: background, //'#fafafa'
    border: '#ccc',

    secondary: secondary,
    secondary_text: '#333',
  },

  menu: {
    bgr: '#FFF',
    border: '#ccc',

    text: primary,
    background: primary_bgr,

    active: {
      text: '#FFF',
      background: primary,
      submenu_bgr: primary_bgr,
    },
  },

  event: {
    primary: primary,
    primary_bgr: primary_bgr,
    default: '#e2d8f9',
    future: secondary,
  },

  warning: {
    info: '#0474BB',
    success: '#00A14B',
    warning: '#d8572a',
    error: '#8d0801',
    error_bgr: '#f7a399',
  },

  status: {
    activated: '#0474BB',
    inactivated: '#CCC',
    removed: '#8d0801',

    pending: '#d8572a',
    published: '#00A14B',
    empty: '#ccc',

    not_started: '#ccc',
    in_progress: '#0474BB',
    completed: '#00A14B',
  },

  exercise: {
    check_answer: '#00A14B',
    view_report: '#f58220',

    save: '#0474BB',
    submit: '#00A14B',
    review: '#f58220',

    exit_grading: '#333',
    exit_grading_text: '#FFF',

    need_grading_bgr: '#e2d8f9',
  },

  assignment: {
    pending: '#cccccc',
    pending_bgr: '#cccccc',

    active: '#0474BB',
    active_bgr: '#cde3f6',

    expired: '#ED1F24',
    expired_bgr: '#f9d0d0',

    not_started: '#ED1F24',
    in_progress: '#f58220',
    need_grading: '#0474BB',
    completed: '#00A14B',
  },

  component: {
    primary: '#0474BB',
    primary_bgr: '#cde3f6',

    secondary: '#ccc',
    secondary_bgr: '#f5f5f5',

    disabled: '#ccc',

    //score
    correct: '#00A14B',
    incorrect: '#ED1F24',
    incorrect_bgr: '#f9d0d0',
    not_started: '#ccc',
    skip: '#f58220',
    warning: '#f58220',
    need_grading: '#0474BB',

    // show correct answer
    correct_answer: '#d424ff',
    correct_answer_bgr: 'rgba(212, 36, 255, 0.3)',
  },
};

//  background: ${(props) => props.theme.app.primary};
// const theme = useTheme();
// background: theme.exercise.review
