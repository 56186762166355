import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import Button from '@components/button';
import { IconUtils } from '@utils/IconUtils';
import { useTheme } from '@app/styled/StyledProvider';

const SubmitGradingScoringButton = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { triggerBeforeNavigate } = useViewLessonContext();
  const { gradings } = useViewLessonContext();

  const [unscoredQuestion, setUnscoredQuestion] = useState<number | null>(null);
  const [totalManualQuestion, setTotalManualQuestion] = useState<number>(
    Object.keys(gradings).length
  );

  useEffect(() => {
    const resourceIds = Object.keys(gradings);
    let _unscoredQuestion = 0;

    resourceIds.forEach((rsId) => {
      const questionGrading = gradings[+rsId];
      const unscored = questionGrading.some((scoring) => {
        return scoring.score == null || scoring.score < 0;
      });

      if (unscored) {
        _unscoredQuestion += 1;
      }
    });

    setTotalManualQuestion(resourceIds.length);
    setUnscoredQuestion(_unscoredQuestion);
  }, [gradings]);

  const submitGrading = () => {
    triggerBeforeNavigate();
  };

  return (
    <>
      {unscoredQuestion != null &&
      totalManualQuestion != null &&
      (totalManualQuestion === 0 ||
        totalManualQuestion === unscoredQuestion) ? (
        <Button
          className={'complete-scoring-button'}
          type={'primary'}
          shape={'round'}
          onClick={submitGrading}
          style={{ background: theme.exercise.submit }}
          icon={IconUtils.exercise.grade}
        >
          {t('exercise.actions.complete_grading')}
        </Button>
      ) : (
        <Button
          className={'update-scoring-button'}
          type={'primary'}
          shape={'round'}
          onClick={submitGrading}
          icon={IconUtils.exercise.grade}
          style={{
            background: theme.exercise.exit_grading,
            color: theme.exercise.exit_grading_text,
          }}
        >
          {t('exercise.button.update_scoring')}
        </Button>
      )}
    </>
  );
};

export default SubmitGradingScoringButton;
