import { Button, Drawer } from 'antd';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewProductContext } from '@modules/product/components/view-toc/ViewTableOfContentContext';
import { H3 } from '@components/typography';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import ViewTableOfContent from '@modules/product/components/view-toc/ViewTableOfContent';
import styled from 'styled-components';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import { ResponsiveSwitchContent } from '@components/responsive/Responsive';
import { ResponsiveScreen } from '@hooks/useMedia';
import { ViewMode } from '@services/model/common';
import { SiteMap } from '@router/SiteMap';

export const ViewTableOfContentToolbar = (props: {
  type: ViewMode;
  children?: ReactNode;
}) => {
  const [showTableOfContent, setShowTableOfContent] = useState({
    isShow: false,
    timestamp: -1,
  });
  const { t } = useTranslation();
  const { productId, navigation, setSelectLesson } = useViewProductContext();

  const viewPreviousLesson = () => {
    if (navigation.previous != null) {
      setSelectLesson(
        navigation.previous.lesson,
        navigation.previous.productToc
      );
    }
  };

  const viewNextLesson = () => {
    if (navigation.next != null) {
      setSelectLesson(navigation.next.lesson, navigation.next.productToc);
    }
  };

  return (
    <ViewTableOfContentToolbarStyle>
      <Button
        size="large"
        type={'default'}
        title={t('product.table_of_content')}
        icon={IconUtils.table_of_content}
        onClick={() =>
          setShowTableOfContent({
            isShow: true,
            timestamp: DateAndTimeUtils.getCurrentTime(),
          })
        }
      />

      <div className={'view-table-of-content-toolbar'}>
        <ResponsiveSwitchContent
          screens={[
            ResponsiveScreen.xxl,
            ResponsiveScreen.xl,
            ResponsiveScreen.lg,
          ]}
          excludeScreenContent={null}
        >
          <Button
            size="large"
            type={'default'}
            shape={'round'}
            disabled={navigation.previous == null}
            onClick={viewPreviousLesson}
          >
            {IconUtils.actions.previous}{' '}
            {t('table_of_content.actions.previous_lesson')}
          </Button>
        </ResponsiveSwitchContent>

        {navigation.current && <H3>{navigation.current.lesson.name}</H3>}

        <ResponsiveSwitchContent
          screens={[
            ResponsiveScreen.xxl,
            ResponsiveScreen.xl,
            ResponsiveScreen.lg,
          ]}
          excludeScreenContent={null}
        >
          <Button
            size="large"
            type={'default'}
            shape={'round'}
            disabled={navigation.next == null}
            onClick={viewNextLesson}
          >
            {t('table_of_content.actions.next_lesson')} {IconUtils.actions.next}
          </Button>
        </ResponsiveSwitchContent>
      </div>

      <Drawer
        title={
          props.type === ViewMode.view ? (
            <H3 style={{ marginBottom: 0 }}>{t('product.table_of_content')}</H3>
          ) : (
            <NavLink href={SiteMap.content.product.toc_gen(productId)}>
              <H3 style={{ marginBottom: 0 }}>
                {t('product.table_of_content')} {IconUtils.edit}
              </H3>
            </NavLink>
          )
        }
        placement={'left'}
        width={500}
        onClose={() => setShowTableOfContent({ isShow: false, timestamp: -1 })}
        open={showTableOfContent.isShow}
      >
        <ViewTableOfContent
          onSelectLesson={() =>
            setShowTableOfContent({ isShow: false, timestamp: -1 })
          }
        />

        {props.children}
      </Drawer>
    </ViewTableOfContentToolbarStyle>
  );
};

const ViewTableOfContentToolbarStyle = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;

  border-top: 1px solid #ccc;
  background: #ccc;
  padding: 0.5em 1em;

  .view-table-of-content-toolbar {
    flex-grow: 1;
    border-left: 1px solid #ccc;
    padding-left: 1em;
    margin-left: 1em;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-typography {
      margin-bottom: 0px;
    }
  }

  .ant-popover-inner-content {
    background: rgba(0, 0, 0, 0.025);
    padding-right: 5px;
  }
`;
