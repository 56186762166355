import { createSlice } from '@reduxjs/toolkit';
import { UserRes } from '@modules/users/services/model';

const initialState = {
  students: [] as UserRes[],
  selectedStudents: [] as UserRes[],
};

const isNotExitsInArr = (current: UserRes, selectedStudents: UserRes[]) => {
  const index = selectedStudents.findIndex((item) => {
    return item.userId === current.userId;
  });

  return index < 0;
};

const addStudentSlice = createSlice({
  name: 'addStudent',
  initialState,
  reducers: {
    initStudents: (
      state,
      action: { payload: { students: UserRes[]; selectedStudent: UserRes[] } }
    ) => {
      state.selectedStudents = action.payload.selectedStudent;

      state.students = action.payload.students.filter((std: any) => {
        return isNotExitsInArr(std, state.selectedStudents);
      });
    },

    addStudent: (state, action: { payload: UserRes }) => {
      state.selectedStudents.push(action.payload);
      state.students = state.students.filter((std: any) => {
        return isNotExitsInArr(std, state.selectedStudents);
      });
    },

    addStudentById: (state, action: { payload: number }) => {
      const selectedStudent = state.students.filter((std) => {
        return std.userId === action.payload;
      });

      const exclude = state.students.filter((std) => {
        return std.userId !== action.payload;
      });

      state.students = exclude;
      state.selectedStudents = state.selectedStudents.concat(selectedStudent);
    },

    removeStudent: (state, action: { payload: UserRes }) => {
      state.selectedStudents.forEach((elem, index) => {
        if (elem.userId === action.payload.userId) {
          state.selectedStudents.splice(index, 1);
          state.students.push(elem);
        }
      });
    },

    removeStudentById: (state, action: { payload: number }) => {
      state.selectedStudents.forEach((elem, index) => {
        if (elem.userId === action.payload) {
          state.selectedStudents.splice(index, 1);
          state.students.push(elem);
        }
      });
    },

    clear: (state) => {
      state.students = [];
    },
  },
});

const { actions, reducer } = addStudentSlice;
export const {
  initStudents,
  addStudent,
  addStudentById,
  removeStudent,
  removeStudentById,
  clear,
} = actions;
export default reducer;
