import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationRes } from '@services/model/PaginationRes';
import { baseQueryWithReAuth } from '@services/baseQuery';
import {
  AssetsRes,
  FilterAssetsReq,
  UpdateAssetMetadataReq,
} from '@modules/product/services/assets_model';

export const CMSAssetsAPI = createApi({
  reducerPath: 'CMSAssetsAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    filter: builder.query<PaginationRes<AssetsRes>, FilterAssetsReq>({
      query: (params) => ({
        url: '/assets/filter',
        params,
      }),
    }),

    uploadFile: builder.mutation<AssetsRes, unknown>({
      query: (body: any) => ({
        url: '/assets/upload-file',
        method: 'POST',
        body,
      }),
    }),

    uploadFiles: builder.mutation<AssetsRes[], unknown>({
      query: (body: any) => ({
        url: '/assets/upload-files',
        method: 'POST',
        body,
      }),
    }),

    updateMetadata: builder.mutation<AssetsRes, UpdateAssetMetadataReq>({
      query: (body) => ({
        url: `/assets/${body.assetsId}/update-metadata`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
