import { ComponentValidationProps } from '@cms/utils/ResourceUtils';

import {
  DragNDropAnsProps,
  DragNDropCompProps,
  getDragNDropAns,
} from '@cms/comps/interact/drag-n-drop/DragNDropComp';

export const DragNDropValidation = {
  validateComponentAndAnswer: (
    component: DragNDropCompProps,
    answer: DragNDropAnsProps
  ): ComponentValidationProps => {
    let needUpdateAnswer = false;
    const currentAns = { ...answer };

    const dragItems = component.configuration.sourceItems.map((source) => {
      return source.label;
    });

    const dropItems = component.configuration.targetItems.map((source) => {
      return source.label;
    });

    if (currentAns.answer != null && currentAns.answer.length > 0) {
      const newAns: string[] = [];
      currentAns.answer.forEach((ans) => {
        const ansPart = getDragNDropAns(ans);
        // if the drag item has been removed or drop items has been removed --> remove them in correct answer.
        if (
          dragItems.indexOf(ansPart.drag) < 0 ||
          dropItems.indexOf(ansPart.drop) < 0
        ) {
          needUpdateAnswer = true;
        } else {
          newAns.push(ans);
        }
      });

      if (needUpdateAnswer) {
        currentAns.answer = newAns;
      }
    }

    return {
      updateComponent: false,
      updateAnswer: needUpdateAnswer,
      newComponent: component,
      newAnswer: currentAns,
    };
  },
};
