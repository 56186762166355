import { Col, Row } from 'antd';
import { Default_Gutter } from '@components/grid';
import React, { ReactNode } from 'react';

export const LoginLayout = (props: { children: ReactNode }) => {
  return (
    <div
      className={'login-page-container'}
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: '6em',
        paddingBottom: '6em',
      }}
    >
      <div className={'container'}>
        <Row gutter={[Default_Gutter, Default_Gutter]} align="middle">
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <h1 className="ls-tight font-bolder mb-5">
              ENET GSTUDY
              <br />
              GIẢI PH&Aacute;P GI&Aacute;O DỤC TO&Agrave;N DIỆN
            </h1>

            <p className="lead mb-10">
              Enet Gstudy l&agrave; một nền tảng được ph&aacute;t triển
              tr&ecirc;n tinh thần chia sẻ v&agrave; khai ph&oacute;ng.
              Đ&acirc;y cũng c&oacute; thể gọi l&agrave; nền tảng kinh doanh
              gi&aacute;o dục đương đại bởi n&oacute; li&ecirc;n kết 4
              b&ecirc;n: Trường học, Nh&agrave; xuất bản, Thầy c&ocirc;
              gi&aacute;o v&agrave; học sinh. Trong m&ocirc; h&igrave;nh
              n&agrave;y, c&aacute;c b&ecirc;n c&oacute; sự giao dịch hay chia
              sẻ nguồn lực để tất cả c&ugrave;ng đạt được mục ti&ecirc;u như
              mong đợi.
            </p>

            <ul className="list-unstyled">
              <li className="py-3">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                      <i className="bi bi-check"></i>
                    </div>
                  </div>

                  <div>
                    <span className="text-heading font-semibold mb-0">
                      Nh&agrave; xuất bản bi&ecirc;n soạn v&agrave; b&aacute;n
                      bản quyền nội dung to&agrave;n cầu{' '}
                    </span>
                  </div>
                </div>
              </li>
              <li className="py-3">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                      <i className="bi bi-check"></i>
                    </div>
                  </div>

                  <div>
                    <span className="text-heading font-semibold mb-0">
                      Trường học c&oacute; thể soạn hoặc mua nội dung với chi
                      ph&iacute; thấp{' '}
                    </span>
                  </div>
                </div>
              </li>
              <li className="py-3">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                      <i className="bi bi-check"></i>
                    </div>
                  </div>

                  <div>
                    <span className="text-heading font-semibold mb-0">
                      Gi&aacute;o vi&ecirc;n c&oacute; thể hợp t&aacute;c nhiều
                      trường học{' '}
                    </span>
                  </div>
                </div>
              </li>
              <li className="py-3">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="icon icon-xs text-sm icon-shape bg-opacity-30 bg-success text-success rounded-circle me-3">
                      <i className="bi bi-check"></i>
                    </div>
                  </div>

                  <div>
                    <span className="text-heading font-semibold mb-0">
                      Học sinh được đ&aacute;nh gi&aacute; đ&uacute;ng năng lực{' '}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </Col>

          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            {props.children}
          </Col>
        </Row>
      </div>
    </div>
  );
};
