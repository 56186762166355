import React from 'react';
import Button from '@components/button';
import Popover from 'antd/lib/popover';
import { ComponentGradingStyle } from './ComponentGradingItem';
import { ComponentScoreIcon } from '@cms/icons/ComponentScoreIcon';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';
import { ComponentResponseProps } from '@modules/assignments/service/exercise_model';

export const ComponentScoringItem = (props: {
  className?: string;
  feedback: ComponentResponseProps;
}) => {
  const { t } = useTranslation();
  return (
    <ComponentGradingStyle
      className={`component-scoring ${props.className ? props.className : ''}`}
    >
      <div className={'component-score-field'}>
        <ComponentScoreIcon
          isSkip={props.feedback.isSkip}
          score={props.feedback.score}
          maxScore={props.feedback.maxScore}
        />
      </div>

      {props.feedback.comment != null && props.feedback.comment !== '' && (
        <div className={'component-comment-field'}>
          <Popover
            content={<p>{props.feedback.comment}</p>}
            title={t('component.label.comment')}
            trigger={'click'}
          >
            <Button
              shape={'circle'}
              type={'text'}
              ghost
              icon={IconUtils.exercise.add_comments}
            />
          </Popover>
        </div>
      )}
    </ComponentGradingStyle>
  );
};
