export const StringUtils = {
  stringify: (object: any, separate: string): string => {
    if (object === null || object === undefined) {
      return '';
    } else if (object instanceof Object) {
      const keys = Object.keys(object);
      if (keys.length === 0) {
        return '';
      } else {
        const arr = keys.map((key) => {
          const propsValue = StringUtils.stringify(object[key], separate);
          return propsValue !== '' ? key + ':' + propsValue : '';
        });
        return arr.join(separate);
      }
    } else if (object instanceof Array) {
      if (object.length === 0) {
        return '';
      } else {
        const arr: string[] = object.map((ob) => {
          return StringUtils.stringify(ob, separate);
        });
        return '[' + arr.join(separate) + ']';
      }
    } else {
      return object + '';
    }
  },
  replaceAll: (str: string, str1: string, str2: string) => {
    return str.replace(new RegExp(str1, 'g'), str2);
  },

  concat: (array: string[], separate: string) => {
    return array.join(separate);
  },

  classes: (...args: string[]) => {
    return args.join(' ');
  },
};
