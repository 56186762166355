import React, { useEffect } from 'react';
import { AnonymousViewLesson } from '@cms/lesson-template/AnnonymousViewLesson';
import { useRouter } from '@hooks/useRouter';
import EmptyLayout from '@components/Layout/EmptyLayout';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';
import { SiteMap } from '@router/SiteMap';

const ViewLesson = () => {
  const router = useRouter();

  const { lessonId } = router.query as unknown as {
    lessonId: number;
    lessonName: string;
  };

  const [viewLessonContent, { data }] =
    PublicPageAPI.endpoints.viewLessonContent.useLazyQuery();

  useEffect(() => {
    viewLessonContent(lessonId);
  }, [lessonId]);

  const handleGoBack = () => {
    router.push(SiteMap.public.lessons);
  };

  return (
    <EmptyLayout className={'view-public-lesson-page'}>
      {data && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_LESSONS}
            subMenu={NavigationMenuEnum.EMPTY}
            title={'Lessons'}
            description={'Lessons'}
          />

          <AnonymousViewLesson
            data={data}
            key={data.lessonId}
            goBack={handleGoBack}
          />
        </>
      )}
    </EmptyLayout>
  );
};

export default ViewLesson;
