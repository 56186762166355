import ResourceStandardEditable from '@modules/product/components/resource/ResourceStandardEditable';
import ResourceScoringEditable from '@modules/product/components/resource/ResourceScoringEditable';
import ResourceAnswerEditable from '@modules/product/components/resource/ResourceAnswerEditable';
import React from 'react';
import styled from 'styled-components';
import { RequireProUser } from '@components/button/RequireProUserButton';
// import { ResourceJSONData } from '@cms/lesson-template/compose/resource/ResourceJSONData';

export const ResourceRelativeData = () => {
  return (
    <RequireProUser>
      <ResourceRelativeDataStyle className={'resource-validation-table'}>
        <ResourceAnswerEditable />
        <ResourceScoringEditable />
        <ResourceStandardEditable />
        {/*<ResourceJSONData />*/}
      </ResourceRelativeDataStyle>
    </RequireProUser>
  );
};

const ResourceRelativeDataStyle = styled.div`
  margin-top: 2em;

  .ant-typography {
    margin-top: 1.5em;
    margin-bottom: 0;
  }
`;
