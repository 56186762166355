import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import Button from '@components/button';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { IconUtils } from '@utils/IconUtils';
import CollectionEditForm, {
  CollectionCreateForm,
} from '../container/CollectionEditForm';
import { CollectionManagement } from '@modules/product/container/collection/CollectionManagement';
import { H1 } from '@components/typography';
import { ManageCollectionActionEnum } from '../container/collection/actions';
import CollectionPublishForm from '../container/CollectionPublishForm';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

export default function ManageCollectionPage() {
  const { t } = useTranslation();
  const router = useRouter();

  const [action, setAction] = useState({
    action: ManageCollectionActionEnum.default,
    params: -1,
  });

  const handleAddCollection = () => {
    setAction({
      action: ManageCollectionActionEnum.add_collection,
      params: -1,
    });
  };

  const handleOnCancel = () => {
    setAction({
      action: ManageCollectionActionEnum.default,
      params: -1,
    });
  };

  const handleUpdateSuccess = () => {
    setAction({
      action: ManageCollectionActionEnum.refresh,
      params: -1,
    });
    handleOnCancel();
  };

  const handleActionChange = (
    action: ManageCollectionActionEnum,
    params: number
  ) => {
    setAction({ action: action, params: params });
  };

  const handleOnSelect = (lessonExampleId: number) => {
    router.push(SiteMap.private.my_lesson.create_gen(lessonExampleId));
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('my_lesson.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={handleAddCollection}
          />
        </H1>
      </HeaderGroup>

      <CollectionManagement
        action={action}
        onActionChange={handleActionChange}
      />

      {action.action === ManageCollectionActionEnum.add_collection && (
        <CustomModal
          header={t('my_lesson.actions.create')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CollectionCreateForm
              onSelect={handleOnSelect}
              onCancel={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === ManageCollectionActionEnum.update_collection && (
        <CustomModal
          header={t('my_lesson.actions.update')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CollectionEditForm
              lessonId={action.params}
              successHandle={handleUpdateSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}

      {action.action === ManageCollectionActionEnum.publish_collection && (
        <CustomModal
          header={t('my_lesson.actions.publish')}
          className={CustomModalClassEnum.medium_modal}
          content={
            <CollectionPublishForm
              lessonId={action.params}
              successHandle={handleUpdateSuccess}
              onCloseFunc={handleOnCancel}
            />
          }
          onCloseFunc={handleOnCancel}
        />
      )}
    </>
  );
}
