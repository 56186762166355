import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { WordSearchSettingProps } from '@cms/comps/game/word-search/WordSearchComp';
import styled from 'styled-components';
import {
  GridWordProps,
  WordSearchGenerateWord,
} from '@cms/comps/game/word-search/WordSearchUtils';

const WordSearchBackgroundStyle = styled.div`
  .word-search-row {
    display: flex;
    justify-content: center;
    align-items: center;

    .word-search-column {
      display: inline-flex;
      width: 1.6em;
      height: 1.6em;
      justify-content: center;
      align-items: center;
    }
  }
`;
const WordSearchBackground = (props: {
  items: GlossaryGameContent[];
  setting: WordSearchSettingProps;

  // update state.
  setPoints: any;
  setCorrectAnswers: any;
}) => {
  const words: string[] = useMemo(() => {
    return props.items.map((word) => {
      return word.word.data.toLowerCase();
    });
  }, [JSON.stringify(props.items)]);

  const wordSearchUtils = useRef(
    new WordSearchGenerateWord(words, props.setting)
  );

  const [gridWords, setGridWords] = useState<GridWordProps[][]>([]);
  const [correctIds, setCorrectIds] = useState<string[]>([]);

  const [actionCompleted, setActionCompleted] = useState(false);
  const [startWord, setStartWord] = useState<GridWordProps | null>(null);
  const [endWord, setEndWord] = useState<GridWordProps | null>(null);
  const [highlightIds, setHighlightIds] = useState<string[]>([]);

  // init the chart if data change....
  useEffect(() => {
    wordSearchUtils.current = new WordSearchGenerateWord(words, props.setting);
    setGridWords(wordSearchUtils.current.getResult());
    reset();
  }, [words, JSON.stringify(props.setting)]);

  //
  // useEffect(() => {
  //   if (wordSearchUtils.current) {
  //     setGridWords(wordSearchUtils.current.getResult());
  //   }
  // }, []);

  // FOR TESTING.
  // useEffect(() => {
  //   if (gridWords.length > 0) {
  //     gridWords.forEach((words, index) => {
  //       const data = words.map((wd) => {
  //         return wd.correct ? wd.char : '*';
  //       });
  //     });
  //   }
  // }, [gridWords]);

  useEffect(() => {
    if (actionCompleted && startWord && endWord) {
      checkAnswer(startWord, endWord);
    } else {
      if (startWord && endWord) {
        const selectWords = getSelectedWordsIds(startWord, endWord);
        setHighlightIds(selectWords);
      } else {
        setHighlightIds([]);
      }
    }
  }, [actionCompleted, startWord, endWord]);

  const checkAnswer = (start: GridWordProps, end: GridWordProps) => {
    const selectedWords = getSelectedWords(start, end);

    const answerPart = selectedWords.map((word) => {
      return word.char;
    });

    const exits = words.findIndex((correctAns) => {
      return (
        answerPart.join('').toLowerCase().trim() === correctAns.trim() ||
        answerPart.reverse().join('').toLowerCase().trim() === correctAns.trim()
      );
    });

    // is correct
    if (exits > -1) {
      const selectWords = getSelectedWordsIds(start, end);
      setCorrectIds((prevState) => {
        return [...prevState, ...selectWords];
      });

      props.setPoints((point: number) => point + 1);
      props.setCorrectAnswers((prevState: string[]) => {
        return [...prevState, answerPart.join('').toLowerCase().trim()];
      });
    }

    reset();
  };

  const reset = () => {
    // then reset all
    setActionCompleted(false);
    setStartWord(null);
    setEndWord(null);
    setHighlightIds([]);
  };

  const getSelectedWordsIds = (start: GridWordProps, end: GridWordProps) => {
    const selectedWords = getSelectedWords(start, end);

    return selectedWords.map((word) => {
      return word.id;
    });
  };

  const getSelectedWords = (start: GridWordProps, end: GridWordProps) => {
    if (
      start.row === end.row ||
      start.column === end.column ||
      Math.abs(start.row - end.row) === Math.abs(start.column - end.column)
    ) {
      const selectedWords = [];

      const from_row = start.row < end.row ? start.row : end.row;
      const to_row = start.row > end.row ? start.row : end.row;

      const from_column = start.column < end.column ? start.column : end.column;
      const to_column = start.column > end.column ? start.column : end.column;

      let movingType;

      if (from_row !== to_row && from_column !== to_column) {
        if (start.row < end.row) {
          movingType =
            start.column < end.column
              ? 'top-left-to-bottom-right'
              : 'top-right-to-bottom-left';
        } else {
          movingType =
            start.column < end.column
              ? 'top-right-to-bottom-left'
              : 'top-left-to-bottom-right';
        }
      } else if (from_row === to_row) {
        movingType =
          start.column < end.column ? 'left-to-right' : 'right-to-left';
      } else if (from_column === to_column) {
        movingType = start.row < end.row ? 'top-to-bottom' : 'bottom-to-top';
      }

      if (from_row !== to_row && from_column !== to_column) {
        if (movingType === 'top-left-to-bottom-right') {
          for (let i = from_row; i <= to_row; i++) {
            for (let j = from_column; j <= to_column; j++) {
              const targetBox = getWordByIndex(i, j);
              selectedWords.push(targetBox);
              i++;
            }
          }
        } else {
          for (let i = from_row; i <= to_row; i++) {
            for (let j = to_column; j >= from_column; j--) {
              const targetBox = getWordByIndex(i, j);
              selectedWords.push(targetBox);
              i++;
            }
          }
        }
      } else if (from_row === to_row) {
        for (let j = from_column; j <= to_column; j++) {
          const targetBox = getWordByIndex(from_row, j);
          selectedWords.push(targetBox);
        }
      } else if (from_column === to_column) {
        for (let i = from_row; i <= to_row; i++) {
          const targetBox = getWordByIndex(i, from_column);
          selectedWords.push(targetBox);
        }
      }

      return selectedWords;
    } else {
      return [];
    }
  };

  const getWordByIndex = (row: number, column: number) => {
    return gridWords[row][column];
  };

  const selectionStart = (data: GridWordProps) => {
    setStartWord(data);
    setActionCompleted(false);
  };

  const selectionMove = (data: GridWordProps) => {
    setEndWord(data);
  };

  const selectionEnd = (data: GridWordProps) => {
    setEndWord(data);
    setActionCompleted(true);
  };

  return (
    <WordSearchBackgroundStyle className={'word-search-background'}>
      {gridWords.map((words, row) => {
        return (
          <div className={'word-search-row'} key={'words-row-' + row}>
            {words.map((word, column) => {
              return (
                <WordSearchCharacter
                  key={`word-row-${row}-words-column-${column}`}
                  data={word}
                  highlightIds={highlightIds}
                  correctIds={correctIds}
                  selectionStart={selectionStart}
                  selectionMove={selectionMove}
                  selectionEnd={selectionEnd}
                />
              );
            })}
          </div>
        );
      })}
    </WordSearchBackgroundStyle>
  );
};

const WordSearchCharacterStyle = styled.div`
  transition: all 0.1s;
  user-select: none;
  cursor: pointer;
  border-radius: 100%;

  &.selected-word {
    background: ${(props) => props.theme.component.incorrect};
    color: ${(props) => props.theme.color.white};
  }

  &.correct-word {
    background: ${(props) => props.theme.component.correct};
    color: ${(props) => props.theme.color.white};
  }
`;

const WordSearchCharacter = (props: {
  data: GridWordProps;
  highlightIds: string[];
  correctIds: string[];
  selectionStart: (data: GridWordProps) => void;
  selectionMove: (data: GridWordProps) => void;
  selectionEnd: (data: GridWordProps) => void;
}) => {
  const [highlight, setHighLight] = useState(false);

  const onMouseOver = () => {
    setHighLight(true);
  };

  const onMouseOut = () => {
    setHighLight(false);
  };

  return (
    <WordSearchCharacterStyle
      className={`word-search-column
        ${props.correctIds.includes(props.data.id) ? ' correct-word ' : ''} 
        
        ${
          highlight || props.highlightIds.includes(props.data.id)
            ? 'selected-word'
            : ''
        }`}
      onMouseDown={() => props.selectionStart(props.data)}
      onMouseMove={() => props.selectionMove(props.data)}
      onMouseUp={() => props.selectionEnd(props.data)}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {props.data.char}
    </WordSearchCharacterStyle>
  );
};

export default WordSearchBackground;
