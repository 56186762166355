import React from 'react';
import { Button, Divider, notification } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '@components/input/input';
import ButtonGroup from '@components/button/ButtonGroup';
import {
  MyProfileAPI,
  UpdatePasswordReq,
} from '@services/private/MyProfileAPI';
import { IconUtils } from '@utils/IconUtils';
import { H2 } from '@components/typography';

export default function UpdatePasswordForm() {
  const { t } = useTranslation();
  const [updatePassword, { isLoading }] =
    MyProfileAPI.endpoints.updatePassword.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      password: '',
      newPassword: '',
      repeatPassword: '',
    },

    validationSchema: yup.object().shape({
      password: yup.string().required(t('registration.required.password')),
      newPassword: yup.string().required(t('registration.required.password')),
      repeatPassword: yup
        .string()
        .required(t('registration.required.repeat_password'))
        .oneOf([yup.ref('newPassword')], t('errors.password_must_match')),
    }),

    onSubmit: (values) => {
      const request: UpdatePasswordReq = {
        password: values.password,
        newPassword: values.newPassword,
      };
      updatePassword(request)
        .unwrap()
        .then(() => {
          notification.success({
            message: t('profile.warning.success'),
            description: t('profile.warning.update_password_success'),
            placement: 'bottomRight',
          });
        })
        .catch(() => {
          notification.error({
            message: t('profile.warning.error'),
            description: t('profile.warning.update_password_error'),
            placement: 'bottomRight',
          });
        });
    },
  });

  return (
    <>
      <H2>{t('profile.actions.change_password')}</H2>

      <FormikProvider value={formik}>
        <Input
          required
          name="password"
          type="password"
          label={t('label.password')}
          placeholder={t('form.enter_password')}
          onChange={formik.handleChange}
          value={formik.values.password}
        />

        <Divider />

        <Input
          required
          name="newPassword"
          type="password"
          label={t('label.password_new')}
          placeholder={t('form.enter_password')}
          onChange={formik.handleChange}
          value={formik.values.newPassword}
        />

        <Input
          required
          name="repeatPassword"
          type="password"
          label={t('label.password_again')}
          placeholder={t('form.enter_password_again')}
          onChange={formik.handleChange}
          value={formik.values.repeatPassword}
        />

        <ButtonGroup className="submit-container" type={'right'}>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            loading={isLoading}
            onClick={formik.submitForm}
            icon={IconUtils.save}
          >
            {t('button.update')}
          </Button>
        </ButtonGroup>
      </FormikProvider>
    </>
  );
}
