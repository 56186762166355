import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '@app/redux/slices/profileSlice';
import LoginForm from '@modules/authorization/container/LoginForm';
import { LoginLayout } from '@modules/authorization/layout/LoginLayout';
import Card, { CardClassEnum } from '@components/card';

const LogoutPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  const handleLoginSuccess = () => {};

  return (
    <LoginLayout>
      <h1 className={'text-center'}>{t('login.header')}</h1>

      <Card className={CardClassEnum.rectangle_box}>
        <LoginForm onSuccess={handleLoginSuccess} />
      </Card>
    </LoginLayout>
  );
};

export default LogoutPage;
