import React from 'react';

import ManageProductPage from '@modules/product/pages/ManageProductPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ManageProduct = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-product-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_PRODUCT}
        title={t('product.actions.review')}
        description={t('product.actions.review')}
      />
      <ManageProductPage />
    </ManagementLayout>
  );
};

export default ManageProduct;
