import React from 'react';

import { ViewClassInformation } from '../container/classes/ViewClassInformation';
import { PageFullSize } from '@components/Layout/PageFullSize';

export default function ViewClassPage(props: { classId: number }) {
  return (
    <PageFullSize>
      <ViewClassInformation classId={props.classId} />
    </PageFullSize>
  );
}
