import { useTranslation } from 'react-i18next';
import { useProUser } from '@components/button/RequireProUserButton';

import { useLessonServiceContext } from '@cms/context/service/LessonServiceProvider';
import React, { useEffect } from 'react';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import ResourceMetadataEditable, {
  ResourceInfoEditable,
} from '@modules/product/components/resource/metadata/ResourceMetadataEditable';
import LessonCreate from '@modules/product/container/LessonCreateForm';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { useSelector } from '@app/redux/hook';

export const ComposeLessonActionHandler = () => {
  const { t } = useTranslation();
  const proUser = useProUser();

  const { newComponents, newAnswers, scoring } = useSelector(
    (state) => state.composeResource
  );

  const { action, lessonId, dispatchAction, resources } =
    useLessonEditableContext();

  const service = useLessonServiceContext();

  useEffect(() => {
    // click to view resource
    if (action.action === LessonEditableActionEnum.view_resource) {
      service.viewResource(action.params);
    } else if (action.action === LessonEditableActionEnum.save_resource) {
      service.onCreateOrUpdate(
        service.resourceId,
        newComponents,
        newAnswers,
        scoring,
        (resourceId) => {
          service.viewResource(resourceId);
        }
      );
    } else if (action.action === LessonEditableActionEnum.clone_resource) {
      service.onClone(action.params, (cloneResourceId) => {
        service.viewResource(cloneResourceId);
      });
    } else if (action.action === LessonEditableActionEnum.insert_resource) {
      service.onInsert(action.params, () => {
        // do nothing....
      });
    } else if (action.action === LessonEditableActionEnum.delete_resource) {
      service.onDelete(action.params, () => {
        // do nothing...
      });
    } else if (action.action === LessonEditableActionEnum.refresh) {
      refreshData();
    }
  }, [action]);

  const refreshData = () => {
    service.getLessonData();
  };

  const changeActionToDefault = () => {
    dispatchAction(LessonEditableActionEnum.default, -1);
  };

  const handleOnCreate = (name: string) => {
    service.onCreateOrUpdateLesson(name);
  };

  return (
    <>
      {action.action === LessonEditableActionEnum.show_setting && (
        <CustomModal
          header={t('resource.question_info')}
          maskClosable={false}
          className={CustomModalClassEnum.large_modal}
          content={
            proUser ? (
              <ResourceMetadataEditable
                lessonId={lessonId}
                resourceId={action.params}
                onSuccess={changeActionToDefault}
                onCancel={changeActionToDefault}
              />
            ) : (
              <ResourceInfoEditable
                resourceId={action.params}
                onSuccess={changeActionToDefault}
                onCancel={changeActionToDefault}
              />
            )
          }
          onCloseFunc={changeActionToDefault}
        />
      )}

      {action.action === LessonEditableActionEnum.create_lesson && (
        <CustomModal
          header={t('lesson.actions.create')}
          maskClosable={false}
          className={CustomModalClassEnum.default}
          content={<LessonCreate onSubmit={handleOnCreate} />}
          onCloseFunc={changeActionToDefault}
        />
      )}

      {action.action === LessonEditableActionEnum.view_data && (
        <CustomModal
          header={'Lesson Data'}
          maskClosable={false}
          className={CustomModalClassEnum.large_modal}
          content={
            <div className={'lesson-data'}>
              <h3>Lesson Data....</h3>
              {resources.map((res) => {
                return (
                  <blockquote
                    key={res.resourceId}
                    style={{
                      minHeight: '6em',
                      maxHeight: '24em',
                      overflowY: 'auto',
                      border: '1px solid #000',
                      padding: 10,
                    }}
                  >
                    {JSON.stringify(res)}
                  </blockquote>
                );
              })}
            </div>
          }
          onCloseFunc={changeActionToDefault}
        />
      )}
    </>
  );
};
