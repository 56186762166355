import React from 'react';

import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { useTranslation } from 'react-i18next';
import { H1 } from '@components/typography';
import { UserRequestManagementTable } from '../container/UserRequestManagementTable';

export default function UserRequestPage() {
  const { t } = useTranslation();

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>{t('user_requests.header')}</H1>
      </HeaderGroup>

      <UserRequestManagementTable />
    </>
  );
}
