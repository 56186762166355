import React from 'react';
import styled from 'styled-components';
import { Dropdown, Button, Avatar } from 'antd';
import { Card } from 'antd';
import { CourseRes } from '@services/private/CourseAPI';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import { useTranslation } from 'react-i18next';
import { H3, H5 } from '@components/typography';
import { CircleAvatar, UserAvatar } from '@components/avatar/UserAvatar';
import useConfirmModal from '@components/modal/ConfirmModal';
import { DateLabel } from '@components/text/DateLabel';
import { IconUtils } from '@utils/IconUtils';
import NavLink from '@components/button/NavLink';
import { MenuProps } from 'antd/es/menu';
import { useTheme } from '@app/styled/StyledProvider';
import { SiteMap } from '@router/SiteMap';
const { Meta } = Card;

export const CourseInfo = (props: { item: CourseRes }) => {
  const { t } = useTranslation();

  return (
    <div className={'course-info'}>
      <div className={'course-content'}>
        <H3>{props.item.name}</H3>

        <p>{props.item.description}</p>

        {props.item.startDate != null && (
          <div className={'created-date'}>
            <label>{t('course.published')}</label>
            <H5>
              <DateLabel label={props.item.createdDate} />
            </H5>
          </div>
        )}
      </div>
    </div>
  );
};

export const CourseInfoEditable = (props: {
  item: CourseRes;
  onEdit: (courseData: CourseRes) => void;
  onViewStudent: (courseData: CourseRes) => void;
  onRemove: (id: number) => void;
  onShare: (id: number) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { confirm } = useConfirmModal();

  const handleClickRemove = (id: number) => {
    confirm(
      'danger',
      t('header.confirm'),
      t('course.warning.remove'),
      t('label.yes'),
      t('label.no'),
      (result) => {
        if (result) {
          props.onRemove(id);
        }
      }
    );
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'share',
      label: t('course.actions.share'),
      icon: IconUtils.actions.share,
      onClick: () => props.onShare(props.item.courseId),
    },
    {
      key: 'remove',
      icon: IconUtils.actions.delete,
      label: t('course.actions.remove'),
      onClick: () => handleClickRemove(props.item.courseId),
    },
    {
      key: 'divider-1',
      type: 'divider',
    },
    {
      key: 'publish',
      icon: IconUtils.actions.publish,
      label: (
        <NavLink
          href={SiteMap.management.courses.publish_gen(props.item.courseId)}
        >
          {t('course.actions.publish')}
        </NavLink>
      ),
    },
  ];

  return (
    <CourseStyle className={'course-info'}>
      <Card
        hoverable
        cover={
          <ThumbnailsImg
            onClick={() => props.onEdit(props.item)}
            ratio={ImageScale.lesson.ratio}
            type={'icon'}
            src={props.item.thumbnails}
          />
        }
      >
        <div className={'course-content'}>
          <Meta
            title={
              <H3 onClick={() => props.onEdit(props.item)}>
                {props.item.name} {IconUtils.edit}
              </H3>
            }
          />

          <div
            className={'created-date'}
            title={t('course.actions.update_student')}
            onClick={() => props.onViewStudent(props.item)}
          >
            <label>{t('course.students')}</label>
            <H5>
              {t('course.warning.total_students', {
                studentNumber: props.item.students.length,
              })}
            </H5>

            <Avatar.Group
              maxCount={6}
              maxStyle={{
                color: theme.app.primary,
                backgroundColor: theme.app.primary_bgr,
              }}
            >
              {props.item.students.map((st) => {
                return <UserAvatar key={st.userId} src={st.avatar} size={40} />;
              })}

              <CircleAvatar size={40} icon={IconUtils.add_new} />
            </Avatar.Group>
          </div>

          <div className={'created-date'}>
            <label>{t('label.created_date')}</label>

            <H5>
              <DateLabel label={props.item.createdDate} />
            </H5>
          </div>

          {props.item.startDate != null && (
            <div className={'created-date'}>
              <label>{t('course.published')}</label>
              <H5>
                <DateLabel label={props.item.createdDate} />
              </H5>
            </div>
          )}
        </div>

        <div className={'course-actions'}>
          <Dropdown menu={{ items: menuItems }} trigger={['click']}>
            <Button type="default" shape="circle" icon={IconUtils.more_icon} />
          </Dropdown>
        </div>
      </Card>
    </CourseStyle>
  );
};

export const CourseStyle = styled.div`
  border: 1px solid #ccc;

  .course-description {
    margin-bottom: 1em;
  }

  .created-date {
    margin-top: 10px;
  }

  .course-actions {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
