import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { StandardSetAPI } from '@modules/product/services/StandardSetAPI';
import { Button, Dropdown, notification } from 'antd';
import { StandardAPI } from '@modules/product/services/StandardAPI';
import useConfirmModal from '@components/modal/ConfirmModal';
import { StandardSetTag } from '@modules/product/components/standards/StandardSetDisplay';
import { AlignType } from 'rc-table/lib/interface';
import { LanguageContentTag } from '@components/text/LanguageTag';
import { IconUtils } from '@utils/IconUtils';
import Card, { CardClassEnum } from '@components/card';
import { StandardSetItem } from './StandardSetItem';
import {
  StandardRes,
  StandardSetRes,
} from '@modules/product/services/standard_model';

export enum StandardSetAction {
  default = 'default',
  create = 'create',
  update = 'update',
  delete = 'delete',

  add_standard = 'add-standard',
  update_standard = 'update-standard',
  delete_standard = 'delete-standard',
}

export const useManageStandardSet = (onRemoveSuccess: () => void) => {
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const [deleteStandardSet] = StandardSetAPI.endpoints.delete.useMutation({});
  const [deleteStandard] = StandardAPI.endpoints.delete.useMutation({});

  const [action, setAction] = useState<StandardSetAction>(
    StandardSetAction.default
  );

  const [standard, setStandard] = useState<StandardRes | null>(null);
  const [standardSet, setStandardSet] = useState<StandardSetRes | null>(null);

  const handleOnCancel = () => {
    setAction(StandardSetAction.default);
    setStandard(null);
    setStandardSet(null);
  };

  const handleClickStandardSetMenu = (
    item: StandardSetRes,
    action: StandardSetAction
  ) => {
    if (action === StandardSetAction.delete) {
      confirm(
        'danger',
        t('header.confirm'),
        t('confirm.confirmRemoveItem'),
        t('button.confirm'),
        t('button.cancel'),
        (r) => {
          if (r) {
            deleteStandardSet(item.standardSetId)
              .unwrap()
              .then(() => {
                notification.success({
                  message: t('standard_set.warning.remove_success'),
                  description: t('standard_set.warning.remove_success_message'),
                  placement: 'bottomRight',
                });

                handleOnCancel();
                onRemoveSuccess();
              })
              .catch(() => {
                notification.error({
                  message: t('standard_set.warning.remove_error'),
                  description: t('standard_set.warning.remove_error_message'),
                  placement: 'bottomRight',
                });

                handleOnCancel();
              });
          }
        }
      );
    } else {
      setAction(action);
      setStandardSet(item);
    }
  };

  const handleClickStandardMenu = (
    standard: StandardRes | null,
    action: StandardSetAction,
    standardSet: StandardSetRes | null
  ) => {
    if (action === StandardSetAction.delete_standard && standard) {
      confirm(
        'danger',
        t('header.confirm'),
        t('confirm.confirmRemoveItem'),
        t('button.confirm'),
        t('button.cancel'),
        (r) => {
          if (r) {
            deleteStandard(standard.standardId)
              .unwrap()
              .then(() => {
                notification.success({
                  message: t('standard.warning.remove_success'),
                  description: t('standard.warning.remove_success_message'),
                  placement: 'bottomRight',
                });

                handleOnCancel();
                onRemoveSuccess();
              })
              .catch(() => {
                notification.error({
                  message: t('standard.warning.remove_error'),
                  description: t('standard.warning.remove_error_message'),
                  placement: 'bottomRight',
                });

                handleOnCancel();
              });
          }
        }
      );
    } else {
      setStandard(standard);
      setAction(action);
      setStandardSet(standardSet);
    }
  };

  const columns: any[] = [
    {
      title: t('label.code'),
      dataIndex: 'code',
      key: 'code',
      width: 120,
      sorter: true,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      width: 180,
      sorter: true,
    },
    {
      title: t('label.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },

    {
      title: t('label.actions'),
      dataIndex: 'action',
      key: 'action',
      width: 120,
      align: 'center' as AlignType,
    },
  ];

  const renderMobile = (
    item: StandardSetRes,
    onClickStandard: (
      std: StandardRes | null,
      action: StandardSetAction,
      stdSet: StandardSetRes
    ) => void
  ) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <StandardSetItem
          item={item}
          onClick={handleClickStandardSetMenu}
          onClickStandard={onClickStandard}
        />
      </Card>
    );
  };

  const processDataRow = (item: StandardSetRes) => {
    return {
      key: item.standardSetId,
      code: <StandardSetTag item={item} />,
      name: (
        <>
          <p>{item.name}</p>
          <p>
            <b>
              {t('standard_set.warning.total_standard', {
                numb: item.standards.length,
              })}
            </b>
          </p>
        </>
      ),
      description: <LanguageContentTag multiple content={item.description} />,

      action: (
        <Dropdown
          menu={{
            items: [
              {
                key: StandardSetAction.update,
                icon: IconUtils.actions.edit,
                label: t('standard_set.actions.edit'),
                onClick: () =>
                  handleClickStandardSetMenu(item, StandardSetAction.update),
              },
              {
                key: StandardSetAction.delete,
                icon: IconUtils.actions.delete,
                label: t('standard_set.actions.remove'),
                onClick: () =>
                  handleClickStandardSetMenu(item, StandardSetAction.delete),
              },
              {
                key: 'divider-1',
                type: 'divider',
              },
              {
                key: StandardSetAction.add_standard,
                icon: IconUtils.lesson.standards,
                label: t('standard_set.actions.add_standard'),
                onClick: () =>
                  handleClickStandardSetMenu(
                    item,
                    StandardSetAction.add_standard
                  ),
              },
            ],
          }}
          arrow
          trigger={['click']}
          placement={'bottom'}
        >
          <Button type="default" shape="circle" icon={IconUtils.more_icon} />
        </Dropdown>
      ),
      standards: item.standards,
      original: item,
    };
  };

  return {
    action,
    setAction,

    selectedStandardSet: standardSet,
    setSelectedStandardSet: setStandardSet,

    selectStandard: standard,
    setSelectStandard: setStandard,

    handleOnCancel,

    columns,
    processDataRow,
    renderMobile,

    handleClickStandardMenu,
  };
};
