import React from 'react';
import { AuthorizeStyle } from '@modules/authorization/styled/PageStyle';
import { useRouter } from '@hooks/useRouter';
import EmptyLayout from '@components/Layout/EmptyLayout';
import SessionPublicCodeInfo from '@modules/calendar/container/SessionPublicCodeInfo';
import { useTranslation } from 'react-i18next';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PageHeader } from '@app/header/PageHeader';

const JoinClassRoom = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const token = router.query.token as string;

  return (
    <EmptyLayout className={'join-classroom-page'}>
      <PageHeader
        menu={NavigationMenuEnum.CALENDAR}
        subMenu={NavigationMenuEnum.EMPTY}
        title={t('session.actions.invitation')}
        description={t('session.actions.invitation')}
      />

      <AuthorizeStyle>
        <SessionPublicCodeInfo token={token} />
      </AuthorizeStyle>
    </EmptyLayout>
  );
};

export default JoinClassRoom;
