import { FeatureGroup } from '@modules/public/components/features/FeatureGroup';
import { SiteMap } from '@router/SiteMap';
import { LinkSample } from '@modules/public/features/Link';
import { RoleEnum } from '@app/redux/slices/roles';

export const CreateLessonFeature = () => {
  return (
    <div className={'group'}>
      <FeatureGroup
        header={'Tạo bài học từ template'}
        roles={[RoleEnum.TEACHER, RoleEnum.STUDENT]}
      >
        <div>
          Chức năng này cho phép giáo viên và học sinh tạo nhanh các bài học và
          chia sẻ công khai cho nhau.
          <br />
          Mục đích của chức năng này là tạo các bài ghi chú, các bài flash card
          để tự luyện tập hay tóm tắt lại bài giảng.
          <br />
          Số lượng component trong loại bài học này hạn chế.
          <br />
        </div>

        <dd>
          <dt>Tạo bài học từ template</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang bài học của tôi{' '}
                <LinkSample href={SiteMap.private.my_lesson.list} />{' '}
              </li>
              <li>Click dấu + để tạo bài học</li>
              <li>Chọn mẫu bài học</li>
              <li>
                Di chuyển tới trang biên soạn{' '}
                <LinkSample href={SiteMap.private.my_lesson.create} />
              </li>
              <li>
                Chọn nội dung từ template (click Select Content và chọn template
                từ panel) và chỉnh sửa lại cho phù hợp
              </li>
              <li>Click dấu + hoặc delete để xóa và thêm câu hỏi/ slide</li>
              <li>Click update để hoàn tất.</li>
            </ol>
          </dl>

          <dt>Chỉnh sửa tiêu đề</dt>
          <dl>
            <ol>
              <li>Click vào dấu edit</li>
              <li>Nhập tên, mô tả và icon cho bài học </li>
              <li>Lưu lại và kiểm tra thay đổi.</li>
            </ol>
          </dl>

          <dt>Chỉnh sửa nội dung</dt>
          <dl>
            <ol>
              <li>Click vào biểu tượng Compose</li>
              <li>
                Di chuyển đến trang biên soạn{' '}
                <LinkSample href={SiteMap.private.my_lesson.compose} />
              </li>
              <li>Lưu lại và kiểm tra thay đổi.</li>
            </ol>
          </dl>

          <dt>Luyện tập</dt>
          <dl>
            <ol>
              <li>Click vào biểu tượng Practice</li>
              <li>
                Di chuyển đến trang luyện tập
                <LinkSample href={SiteMap.private.my_lesson.practice} />
              </li>
              <li>Xem bài giảng hoặc trả lời câu hỏi.</li>
            </ol>
          </dl>

          <dt>Công khai nội dung</dt>
          <dl>
            <ol>
              <li>Bấm vào biểu tượng publish</li>
              <li>Xác nhận</li>
              <li>
                Click vào biểu tượng publish để xem đường dẫn. Copy đường dẫn và
                gửi cho bạn bè.
              </li>
              <li>
                Truy cập vào <LinkSample href={SiteMap.public.lessons} /> thấy
                bài học.
              </li>
              <li>
                Click vào đường dẫn{' '}
                <LinkSample href={SiteMap.public.lessons_view} /> và xem bài
                học.{' '}
              </li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>

      <FeatureGroup header={'Tạo bài học phức tạp'} roles={[RoleEnum.TEACHER]}>
        <div>
          Chức năng này dành cho người dùng chuyên nghiệp. Hệ thống cho phép
          giáo viên tạo các bài học phức tạp hơn từ thư viện nội dung thay cho
          các mẫu thiết kế mặc định.
          <br />
          Nếu user không phải là pro user, thì đường đi của nó tương tự với
          create lesson simple. Để enable pro user, click vào account, chọn
          become Pro user.
        </div>

        <dd>
          <dt>Tạo bài học</dt>
          <dl>
            <ol>
              <li>
                Truy cập vào trang bài học của tôi{' '}
                <LinkSample href={SiteMap.content.lesson.list} />{' '}
              </li>
              <li>Click dấu + để tạo bài học</li>
              <li>
                Nhập tên và chọn Mẫu bài học. Nếu không chọn thì sẽ dùng mẫu mặc
                định, single question.
              </li>

              <li>
                Di chuyển tới trang biên soạn{' '}
                <LinkSample href={SiteMap.content.lesson.compose} />
              </li>
              <li>
                Click vào các câu hỏi (tương ứng với các slide bên tay trái)
              </li>
              <li>Click Select content để chọn nội dung.</li>
              <li>Chỉnh sửa và cập nhật.</li>
            </ol>
          </dl>

          <dt>Chỉnh sửa tiêu đề</dt>
          <dl>
            <ol>
              <li>Click vào dấu edit</li>
              <li>Nhập tên, mô tả và icon cho bài học </li>
              <li>Lưu lại và kiểm tra thay đổi.</li>
            </ol>
          </dl>

          <dt>Chỉnh sửa nội dung</dt>
          <dl>
            <ol>
              <li>Click vào biểu tượng Compose</li>
              <li>
                Di chuyển đến trang biên soạn{' '}
                <LinkSample href={SiteMap.content.lesson.compose} />
              </li>
              <li>Lưu lại và kiểm tra thay đổi.</li>
            </ol>
          </dl>

          <dt>Review bài học</dt>
          <dl>
            <ol>
              <li>Click vào biểu tượng Review </li>
              <li>Xem bài học</li>
              <li>Kiểm tra đáp án bằng cách click .</li>
            </ol>
          </dl>
        </dd>
      </FeatureGroup>
    </div>
  );
};
