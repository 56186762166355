import { Button, Col, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { DateAndTimeFormat, DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import * as yup from 'yup';
import { AssignmentAPI } from '@modules/assignments/service/AssignmentAPI';
import { CreateAssignmentForm } from '@modules/assignments/container/assignment/CreateAssignmentForm';
import AssignmentStudentForm from '@modules/assignments/container/assignment/AssignmentStudentForm';
import ButtonGroup from '@components/button/ButtonGroup';
import Card, { CardClassEnum } from '@components/card';
import { H2 } from '@components/typography';
import { CreateAssignmentStyle } from './CreateAssignment';
import { ViewAssignmentLesson } from './AssignmentLessonForm';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import {
  ClassRosterReq,
  SimpleClassRosterRes,
} from '@modules/users/services/clazz';
import { ExerciseStatus } from '@modules/assignments/service/exercise_model';
import {
  AssignmentExpiredType,
  AssignmentInstanceRes,
  UpdateAssignmentInstanceReq,
} from '@modules/assignments/service/assignment_model';
import { LessonRes } from '@modules/product/services/lesson_model';

const EditAssignmentForm = (props: {
  assignmentInstanceId: number;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();

  const [ready, setReady] = useState(false);
  const [lesson, setLesson] = useState<LessonRes | undefined>(undefined);
  const [rosters, setRosters] = useState<SimpleClassRosterRes[]>([]);
  const [studentAssignmentStatus, setStudentAssignmentStatus] = useState<
    Record<number, ExerciseStatus>
  >({});

  const [findAssignmentInstance, { data, isSuccess, isLoading }] =
    AssignmentAPI.endpoints.findAssignmentInstance.useLazyQuery({});

  const [updateAssignment, { isLoading: isSavingLoading }] =
    AssignmentAPI.endpoints.updateAssignment.useMutation({});

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      assignmentGroupId: -1,
      name: '',
      direction: '',

      expiredType: AssignmentExpiredType.date_n_time,
      duration: 45,
      unlockAtDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      unlockAtTime: '08:00:00',

      lockAtDate: DateAndTimeUtils.getDate(DateAndTimeFormat.YYYY_MM_DD),
      lockAtTime: '23:30:00',
      rosters: [],
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(t('validation.required.field')),
      description: yup.string(),
    }),

    onSubmit: (values) => {
      const request: UpdateAssignmentInstanceReq = {
        assignmentInstanceId: props.assignmentInstanceId,
        assignmentGroupId: values.assignmentGroupId,
        name: values.name,
        direction: values.direction,
        expiredType: values.expiredType,
        duration: values.duration,
        unlockAt: values.unlockAtDate + 'T' + values.unlockAtTime,
        lockAt: values.lockAtDate + 'T' + values.lockAtTime,
        rosters: values.rosters,
      };

      updateAssignmentData(request);
    },
  });

  useEffect(() => {
    findAssignmentInstance(props.assignmentInstanceId);
  }, [props.assignmentInstanceId]);

  useEffect(() => {
    if (data && isSuccess) {
      restoreAssignmentData(data);
    }
  }, [data]);

  const restoreAssignmentData = (data: AssignmentInstanceRes) => {
    formik.setFieldValue(
      'assignmentGroupId',
      data.assignmentGroup ? data.assignmentGroup.assignmentGroupId : -1
    );
    formik.setFieldValue('name', data.name);
    formik.setFieldValue('direction', data.direction);
    formik.setFieldValue('expiredType', data.expiredType);

    if (data.duration != null && data.duration > 0) {
      formik.setFieldValue('duration', data.duration);
    }

    if (data.unlockAt) {
      formik.setFieldValue('unlockAtDate', data.unlockAt.split('T')[0]);
      formik.setFieldValue('unlockAtTime', data.unlockAt.split('T')[1]);
    }

    if (data.lockAt) {
      formik.setFieldValue('lockAtDate', data.lockAt.split('T')[0]);
      formik.setFieldValue('lockAtTime', data.lockAt.split('T')[1]);
    }

    setLesson(data.lesson);

    const _rosters: SimpleClassRosterRes[] = [];
    const _rosterIds: ClassRosterReq[] = [];
    const _studentAssignmentStatus: Record<number, ExerciseStatus> = {};

    data.results.forEach((st) => {
      _rosters.push({
        user: st.student!,
        clazz: st.clazz!,
      });

      _rosterIds.push({
        userId: st.student!.userId,
        classId: st.clazz!.classId,
      });

      _studentAssignmentStatus[st.student!.userId] = st.status;
    });

    setRosters(_rosters);
    setStudentAssignmentStatus(studentAssignmentStatus);
    formik.setFieldValue('rosters', _rosterIds);

    setReady(true);
  };

  const updateAssignmentData = (values: UpdateAssignmentInstanceReq) => {
    updateAssignment(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('assignment.warning.create_success'),
          description: t('assignment.warning.create_success_msg'),
          placement: 'bottomRight',
        });
        props.onSuccess();
      })
      .catch(() => {
        notification.error({
          message: t('assignment.warning.create_error'),
          description: t('assignment.warning.create_error_msg'),
          placement: 'bottomRight',
        });
      });
  };

  const handleOnSelectStudent = (rosters: ClassRosterReq[]) => {
    formik.setFieldValue('rosters', rosters);
  };

  return (
    <CreateAssignmentStyle>
      <FormikProvider value={formik}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
            <H2>{t('assignment.info')}</H2>

            <Card className={CardClassEnum.rectangle_box}>
              <CreateAssignmentForm formik={formik} />
            </Card>
          </Col>

          {lesson && (
            <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
              <ViewAssignmentLesson item={lesson} />
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
            {data && isSuccess && ready && (
              <AssignmentStudentForm
                studentAssignmentStatus={studentAssignmentStatus}
                rosters={rosters}
                rosterIds={formik.values.rosters}
                onChange={handleOnSelectStudent}
              />
            )}
          </Col>

          <Col xs={24} sm={24} md={24} lg={20} xl={18} xxl={18}>
            <ButtonGroup type={'right'}>
              <Button
                type="default"
                size={'large'}
                onClick={props.onCancel}
                shape={'round'}
              >
                {t('button.cancel')}
              </Button>

              <Button
                type={'primary'}
                shape={'round'}
                size={'large'}
                onClick={formik.submitForm}
                loading={isLoading || isSavingLoading}
                icon={IconUtils.actions.save}
              >
                {t('assignment.actions.update')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormikProvider>
    </CreateAssignmentStyle>
  );
};

export default EditAssignmentForm;
