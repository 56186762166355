import React, { useState } from 'react';
import { H1 } from '../typography';
import Latex from 'react-latex';

import {
  MathEquationInput,
  MathEquationTabEnum,
} from '@cms/comps/math-equation/MathEquationInput';

export const MathJaxExample = () => {
  const [expression, setExpression] = useState('\\frac{1}{2}');
  const [expression2, setExpression2] = useState('\\frac{1}{2}');
  const [expression3, setExpression3] = useState('\\frac{1}{2}');

  return (
    <div style={{ fontSize: 16 }}>
      <H1>Mathjax 1: </H1>

      <Latex>{`$${expression}$`}</Latex>

      <MathEquationInput
        id={'math-equation-id'}
        disabled={false}
        initValue={expression}
        activeTab={MathEquationTabEnum.operations}
        activeTabs={[MathEquationTabEnum.operations, MathEquationTabEnum.array]}
        onChange={(val) => setExpression(val)}
      />

      <H1>Mathjax 2: </H1>

      <Latex>{`$${expression2}$`}</Latex>

      <MathEquationInput
        id={'math-equation-id-2'}
        disabled={false}
        initValue={expression2}
        activeTab={MathEquationTabEnum.operations}
        activeTabs={[MathEquationTabEnum.operations, MathEquationTabEnum.array]}
        onChange={(val) => setExpression2(val)}
      />

      <H1>Mathjax 3: </H1>

      <Latex>{`$${expression3}$`}</Latex>

      <MathEquationInput
        id={'math-equation-id-3'}
        disabled={false}
        initValue={expression3}
        activeTab={MathEquationTabEnum.operations}
        activeTabs={[MathEquationTabEnum.operations, MathEquationTabEnum.array]}
        onChange={(val) => setExpression3(val)}
      />
    </div>
  );
};
