import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useTranslation } from 'react-i18next';

import {
  LessonNavigationAction,
  LessonNavigationType,
  useLessonNavigationContext,
} from '@cms/lesson-template/context/LessonNavigationContext';
import LessonButton from '@cms/lesson-template/components/buttons/style';
import { useLessonTemplateContext } from '@cms/design-template/layout/LessonTemplateLayoutProvider';
import { LessonTemplateLayoutEnum } from '@modules/product/services/LessonTemplateAPI';

export const PreviousQuestionButton = () => {
  const { t } = useTranslation();
  const { config } = useLessonTemplateContext();

  const { triggerBeforeNavigate } = useViewLessonContext();

  const { resource, updateNavigation } = useLessonNavigationContext();

  const handleNextQuestion = () => {
    triggerBeforeNavigate();

    updateNavigation(LessonNavigationAction.PREVIOUS_QUESTION, {
      resourceId: resource.previousResourceId,
      type: LessonNavigationType.refresh,
    });
  };

  if (config.layout === LessonTemplateLayoutEnum.presentation) {
    return (
      <LessonButton
        shape={'circle'}
        size={'large'}
        className={'previous-question-button'}
        onClick={handleNextQuestion}
        disabled={resource.previousResourceId < 0}
        icon={<LeftOutlined />}
      />
    );
  } else {
    return (
      <LessonButton
        className={'previous-question-button'}
        shape={'round'}
        type={'primary'}
        onClick={handleNextQuestion}
        disabled={resource.previousResourceId < 0}
      >
        <LeftOutlined /> {t('exercise.button.previous')}
      </LessonButton>
    );
  }
};
