import { clearCurrentUserData, login } from '@app/redux/slices/profileSlice';
import { refreshExpiredData } from '@app/redux/slices/sessionExpiredSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';
import { RoleEnum } from '@app/redux/slices/roles';

interface LoginRes {
  refreshToken: string;
  id: string;
  username: string;
  email: string;
  expiredTime: string;

  error?: string;
  redirect?: string;
  schoolId: number | null;
}

export interface RedirectLoginReq extends LoginRes {
  access_token: string;
  roles: string | RoleEnum[];
}

export interface LoginOauthReq extends LoginRes {
  token: string;
  roles: RoleEnum[];
}

export const LogoutComp = () => {
  const router = useRouter();

  router.push(SiteMap.auth.login);

  return null;
};

export const LoginComp = (props: { data: LoginOauthReq }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    clearCurrentUserData();

    const payload = {
      access_token: props.data.token,
      refresh_token: props.data.refreshToken,
      uuid: props.data.id,
      roles: props.data.roles,
      schoolId: props.data.schoolId ?? null,
    };

    dispatch(login(payload));
    dispatch(refreshExpiredData(props.data.expiredTime));

    setTimeout(() => {
      if (props.data.redirect) {
        router.push(props.data.redirect);
      } else {
        router.push(SiteMap.private.dashboard);
      }
    }, 0);
  }, []);

  return null;
};
