import React, { useEffect, useState } from 'react';
import { ProductTableOfContentRes } from '@services/publish/PublishSchool';
import { PublicPageContainer } from '@modules/authorization/styled/PageStyle';
import { ProductDetailPage } from '@modules/public/pages/ProductDetailPage';
import PublicLayout from '@components/template/public-layout/PublicLayout';
import { PageHeader } from '@app/header/PageHeader';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { PublicPageAPI } from '@services/public/PublicPageAPI';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const ViewProduct = () => {
  const router = useRouter();

  const { productId, productName } = router.query as unknown as {
    productId: string;
    productName: string;
  };

  const [viewProduct, { data, isSuccess, error, isError }] =
    PublicPageAPI.endpoints.viewProduct.useLazyQuery();

  const [productData, setProductData] =
    useState<ProductTableOfContentRes | null>(null);

  useEffect(() => {
    viewProduct(productId);
  }, [productId, productName]);

  useEffect(() => {
    if (data && isSuccess) {
      setProductData(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (error && isError) {
      router.push(SiteMap.public.page_404);
    }
  }, [error, isError]);

  return (
    <PublicLayout className={'view-product-page'}>
      {productData && (
        <>
          <PageHeader
            menu={NavigationMenuEnum.PUBLIC_PRODUCTS}
            subMenu={NavigationMenuEnum.EMPTY}
            title={productData.product.name}
            keywords={productData.product.keyword}
            description={productData.product.description}
          />

          <PublicPageContainer>
            <ProductDetailPage
              school={productData.school}
              product={productData.product}
              author={productData.author}
              tableOfContent={productData.tableOfContent}
            />
          </PublicPageContainer>
        </>
      )}
    </PublicLayout>
  );
};

export default ViewProduct;
