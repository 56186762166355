import React from 'react';
import styled from 'styled-components';
import SessionExpiredModal from '@components/template/app-layout/utils/SessionExpiredModal';
import useMediaQuery from '@hooks/useMedia';
import { Layout } from 'antd';
import useLoadSchoolSetting from '@hooks/useLoadSchoolSetting';
import CookieConsent from 'react-cookie-consent';
const { Content } = Layout;

export default function EmptyLayout({
  className,
  children,
}: {
  className?: string;
  children: any;
}) {
  const screen = useMediaQuery();
  useLoadSchoolSetting(false);

  return (
    <EmptyLayoutStyle
      className={`empty-layout screen-${screen} ${className ?? ''}`}
    >
      <Content style={{ position: 'relative' }}>{children}</Content>
      <SessionExpiredModal />

      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </EmptyLayoutStyle>
  );
}

const EmptyLayoutStyle = styled(Layout)`
  font-size: 16px;
  line-height: 1.6;
  min-height: 100vh;
  overflow-y: auto;

  &.view-public-lesson-page {
  }

  &.view-product-lti-page {
    .ant-layout-content {
      padding: 10px 10px;

      .screen-xs & {
        padding: 6px 12px;
      }

      .screen-sm & {
        padding: 6px 12px;
      }

      .screen-md & {
        padding: 8px 16px;
      }

      .screen-lg & {
        padding: 12px 24px;
      }

      .screen-xl & {
        padding: 16px 32px;
      }

      .screen-xxl & {
        padding: 24px 48px;
      }
    }
  }

  &.white-bgr {
    background: #fff;
  }

  * {
    box-sizing: border-box;
  }
`;
