import { ReactNode, useMemo } from 'react';

import { Breadcrumb as AntBreadcrumb } from 'antd';
import styled from 'styled-components';
import NavLink from '@components/button/NavLink';

export interface BreadCrumbsProps {
  icon?: ReactNode | null;
  title: ReactNode;
  link?: string;
}

export default function Breadcrumb(props: { items: BreadCrumbsProps[] }) {
  const menus = useMemo(() => {
    return props.items.map((menu) => {
      if (menu.link) {
        return {
          title: (
            <NavLink href={menu.link}>
              {menu.icon} {menu.title}
            </NavLink>
          ),
        };
      }
      return { title: menu.title };
    });
  }, [props.items]);

  return (
    <BreadcrumbWrapper className={'breadcrumb-wrapper'}>
      <AntBreadcrumb items={menus} />
    </BreadcrumbWrapper>
  );
}

const BreadcrumbWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;
