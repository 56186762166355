import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import MyAssetsFilePage from '@modules/product/pages/MyAssetsFilesPage';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import { PageHeader } from '@app/header/PageHeader';

const ManageAssetsFile = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-assets-file-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_MY_ASSETS}
        title={t('assets.header')}
        description={t('assets.header')}
      />

      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
      >
        <MyAssetsFilePage />
      </Authorization>
    </ManagementLayout>
  );
};

export default ManageAssetsFile;
