import React from 'react';

import { useRouter } from '@hooks/useRouter';
import ViewLessonPage from '@modules/product/pages/ViewLessonPage';
import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import { useTranslation } from 'react-i18next';

import EmptyLayout from '@components/Layout/EmptyLayout';
import { PageHeader } from '@app/header/PageHeader';

const ReviewLesson = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id } = router.query as unknown as { id: number };

  return (
    <EmptyLayout className={'review-lesson-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_LESSON}
        title={t('lesson.actions.review')}
        description={t('lesson.actions.review')}
      />

      <ViewLessonPage lessonId={id} />
    </EmptyLayout>
  );
};

export default ReviewLesson;
