import { CompTypeEnum } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { MatchingCompProps } from '@cms/comps/interact/matching/MatchingComp';

export const MatchingDragNDropExampleData: MatchingCompProps = {
  id: 'matching_comp_example',
  type: CompTypeEnum.MATCHING,

  configuration: {
    sourceItems: [
      {
        label: '1',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
      },
      {
        label: '2',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
      },
      {
        label: '3',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Orange',
        },
      },
    ],
    targetItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Tao',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Chuoi',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Cam',
        },
      },
    ],
  },
  setting: {
    autoScore: true,
    layout: 'one-to-one',
    eventType: 'drag',
  },
};
