import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const { Footer } = Layout;

const FooterWrapper = styled(Footer)``;

const PublicFooter = () => {
  return (
    <FooterWrapper>
      <div style={{ textAlign: 'center', padding: '1em 0em' }}>
        Gstudy ©2022 Created by Gstudy.net
      </div>
    </FooterWrapper>
  );
};

export default PublicFooter;
