import React, { useEffect, useMemo, useState } from 'react';
import Table from '@components/table/Table';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { useTranslation } from 'react-i18next';
import { Button, Space, notification } from 'antd';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { SimpleCheckBox } from '@components/input/SimpleCheckBox';
import { ProductDisplay } from '@modules/public/components/products/ProductDipslay';
import { SiteMap } from '@router/SiteMap';
import NavLink from '@components/button/NavLink';
import { IconUtils } from '@utils/IconUtils';
import ButtonGroup from '@components/button/ButtonGroup';
import { LessonAccessibleDirection } from './LessonAccessibleDirection';
import Alert from '@components/alert';
import { H4 } from '@components/typography';
import {
  LessonAccessibleReq,
  ProductLessonAccessibleReq,
} from '@modules/product/services/product_model';

const ProductLessonAccessibleTable = (props: { productId: number }) => {
  const { t } = useTranslation();

  const [getTableOfContent, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.getTableOfContent.useLazyQuery({});

  const [updateAccessible, { isLoading: isUpdateLoading }] =
    ProductAPI.endpoints.updateAccessible.useMutation({});

  const [lessonAccessible, setLessonAccessible] = useState<
    Record<number, LessonAccessibleReq>
  >({});

  useEffect(() => {
    getTableOfContent(props.productId);
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      const lessonAccessible: Record<number, LessonAccessibleReq> = {};

      data.tableOfContent.forEach((toc) => {
        toc.lessons.forEach((ls) => {
          lessonAccessible[ls.lessonId] = {
            lessonId: ls.lessonId,
            shareable: ls.shareable,
            assignable: ls.assignable,
            printable: ls.printable,
          };
        });
      });

      setLessonAccessible(lessonAccessible);
    }
  }, [data]);

  const handleOnChange = (lessonId: number, type: string, value: boolean) => {
    const accessible: Record<string, any> = lessonAccessible[lessonId] ?? {
      lessonId: lessonId,
      shareable: false,
      assignable: false,
      printable: false,
    };

    accessible[type] = value;

    setLessonAccessible((prev) => {
      prev[lessonId] = accessible as LessonAccessibleReq;
      return { ...prev };
    });
  };

  const datas = useMemo(() => {
    if (data) {
      const lessonLicense: any[] = [];

      data.tableOfContent.forEach((item) => {
        lessonLicense.push({
          key: 'product-toc-' + item.productTocId,
          name: <H4 className={'mb-0'}>{item.name}</H4>,
          shareable: '',
          assignable: '',
          printable: '',
        });

        if (item.lessons.length > 0) {
          item.lessons.forEach((ls) => {
            let accessible = lessonAccessible[ls.lessonId]
              ? lessonAccessible[ls.lessonId]
              : {
                  lessonId: ls.lessonId,
                  shareable: ls.shareable,
                  assignable: ls.assignable,
                  printable: ls.printable,
                };

            lessonLicense.push({
              key: 'lesson-' + ls.lessonId,
              lesson: ls,
              name: (
                <div className={'lesson-info'} style={{ marginLeft: 50 }}>
                  <Space align={'center'}>
                    <ThumbnailsImg
                      type={'icon'}
                      src={ls.thumbnails}
                      ratio={ImageScale.lesson.ratio}
                      width={ImageScale.lesson.icon}
                    />

                    <span className={'lesson-display'}>{ls.name}</span>
                  </Space>
                </div>
              ),
              shareable: (
                <SimpleCheckBox
                  value={accessible.shareable}
                  onChange={(val) =>
                    handleOnChange(ls.lessonId, 'shareable', val)
                  }
                />
              ),
              assignable: (
                <SimpleCheckBox
                  value={accessible.assignable}
                  onChange={(val) =>
                    handleOnChange(ls.lessonId, 'assignable', val)
                  }
                />
              ),
              printable: (
                <SimpleCheckBox
                  value={accessible.printable}
                  onChange={(val) =>
                    handleOnChange(ls.lessonId, 'printable', val)
                  }
                />
              ),
            });
          });
        }
      });

      return lessonLicense;
    } else {
      return [];
    }
  }, [data, lessonAccessible]);

  const columns: any[] = [
    {
      title: '#',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <>
          {IconUtils.actions.share}
          <br />
          {t('product.label.accessible.shareable')}
        </>
      ),
      dataIndex: 'shareable',
      key: 'shareable',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <>
          {IconUtils.actions.assign}
          <br />
          {t('product.label.accessible.assignable')}
        </>
      ),
      dataIndex: 'assignable',
      key: 'assignable',
      width: 150,
      align: 'center',
    },
    {
      title: (
        <>
          {IconUtils.actions.print}
          <br />
          {t('product.label.accessible.printable')}
        </>
      ),
      dataIndex: 'printable',
      key: 'printable',
      width: 150,
      align: 'center',
    },
  ];

  const handleSubmit = () => {
    const request: ProductLessonAccessibleReq = {
      productId: props.productId,
      lessons: Object.entries(lessonAccessible).map(([key, value]) => {
        return value;
      }),
    };

    updateAccessible(request)
      .unwrap()
      .then(() => {
        notification.success({
          message: t('product.warning.update_accessible_success'),
          description: t('product.warning.update_accessible_success_mgs'),
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        notification.error({
          message: t('product.warning.update_accessible_error'),
          description: t('product.warning.update_accessible_error_mgs'),
          placement: 'bottomRight',
        });
      });
  };

  const getRowKeyFunc = (record: any) => {
    return record.key;
  };

  return (
    <>
      {data && (
        <Alert
          message={<ProductDisplay size={32} item={data.product} />}
          description={<LessonAccessibleDirection />}
          type="info"
          closable
        />
      )}

      <Table
        columns={columns}
        dataSource={datas}
        pagination={false}
        rowKey={getRowKeyFunc}
      />

      <ButtonGroup type={'right'} className={'submit-container'}>
        <NavLink href={SiteMap.content.product.list}>
          <Button type={'default'} shape={'round'} size={'large'}>
            {t('button.cancel')}
          </Button>
        </NavLink>

        <Button
          type={'primary'}
          loading={isLoading || isUpdateLoading}
          shape={'round'}
          size={'large'}
          icon={IconUtils.actions.save}
          onClick={handleSubmit}
        >
          {t('button.update')}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default ProductLessonAccessibleTable;
