import React, { useState } from 'react';
import ReactQuillEditor from '@components/editor/ReactQuill';
// import ReactDraftEditor from '@components/editor/ReactDraft';
import Button from '@components/button';
import { CodeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import RawTextArea from '@components/editor/RawTextArea';

export interface WysyEditorProps {
  initValue?: string;
  onChange: (newValue: string) => void;
  numberRows?: number;
}

export default function ContentEditor(props: WysyEditorProps) {
  const [showRaw, setShowRaw] = useState(false);

  const handleOnClickRaw = () => {
    setShowRaw(!showRaw);
  };

  return (
    <ContentEditorStyle className={'content-editable'}>
      <ToggleRawToolbar onClickRaw={handleOnClickRaw} />

      {showRaw ? <RawTextArea {...props} /> : <ReactQuillEditor {...props} />}
    </ContentEditorStyle>
  );
}

const ToggleRawToolbar = (props: { onClickRaw: () => void }) => {
  return (
    <div className={'toggle-raw-toolbar'}>
      <Button type={'text'} onClick={props.onClickRaw} size={'small'}>
        <CodeOutlined />
      </Button>
    </div>
  );
};

const ContentEditorStyle = styled.div`
  position: relative;
  padding-right: 40px;

  .toggle-raw-toolbar {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
  }
`;
