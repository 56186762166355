import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import {
  CreateStandardSetReq,
  StandardSetRes,
} from '@modules/product/services/standard_model';

export const StandardSetAPI = createApi({
  reducerPath: 'StandardSetAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findAll: builder.query<PaginationRes<StandardSetRes>, PaginationReq>({
      query: (params) => ({
        url: `/standard-sets`,
        params,
      }),
    }),

    findById: builder.query<StandardSetRes, number>({
      query: (standardSetId) => ({
        url: `/standard-sets/${standardSetId}`,
      }),
    }),

    create: builder.mutation<StandardSetRes, CreateStandardSetReq>({
      query: (body: CreateStandardSetReq) => ({
        url: '/standard-sets',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<StandardSetRes, CreateStandardSetReq>({
      query: (body: CreateStandardSetReq) => ({
        url: `/standard-sets/${body.standardSetId}`,
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (standardSetId) => ({
        url: `/standard-sets/${standardSetId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
