import React from 'react';
import { Col, Row } from 'antd';
import { ViewPublishSchoolTable } from '@modules/admin/container/ViewPublishSchoolTable';
import { useTranslation } from 'react-i18next';
import { H3 } from '@components/typography';
import { Default_Gutter } from '@components/grid';

export default function AdminDashboardPage() {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <H3>{t('dashboard.label.create_school_request')}</H3>
          <ViewPublishSchoolTable maxItems={10} />
        </Col>

        <Col span={12}>
          <H3>{t('dashboard.label.current_events')}</H3>
        </Col>
      </Row>

      <Row gutter={[Default_Gutter, Default_Gutter]}>
        <Col span={12}>
          <H3>{t('dashboard.label.current_assignment')}</H3>
        </Col>

        <Col span={12}>
          <H3>{t('dashboard.label.report')}</H3>
        </Col>
      </Row>
    </>
  );
}
