import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { FlashCardCompProps } from '@cms/comps/game/flashcard/FlashCardComp';

export const FlashCardExampleData: FlashCardCompProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.FLASH_CARD,

  configuration: {
    sourceItems: [
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Apple Thumbnails',
        },
      },
      {
        id: COMPONENT_UTILS.generateUID(),
        word: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana',
        },
        description: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Banana Thumbnail',
        },
      },
    ],
  },
  setting: {
    autoScore: false,
    scramble: false,
  },
};
