import React from 'react';

import {
  OneColumnSectionProps,
  SectionColumnSettingProps,
} from './OneColumnSection';
import { FormikProvider, useFormik } from 'formik';
import { Button, Slider } from 'antd';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { IconUtils } from '@utils/IconUtils';

export const OneColumnSectionSetting = (props: {
  item: OneColumnSectionProps;
  onChange: (setting: string) => void;
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: props.item.setting as SectionColumnSettingProps,
    onSubmit: (values) => {
      props.onChange(JSON.stringify(values));
    },
  });

  const handleSlideChange = (value: number) => {
    formik.setFieldValue('width', [value]);
  };

  return (
    <FormikProvider value={formik}>
      <Input
        name="gap"
        type="text"
        label={t('section.label.space_between')}
        onBlur={formik.handleChange}
        defaultValue={formik.values.gap}
      />

      <InputGroup label={t('section.label.column_size')}>
        <Slider
          min={1}
          step={1}
          max={100}
          onChange={handleSlideChange}
          value={formik.values.width[0]}
        />
      </InputGroup>

      <Button
        type={'primary'}
        onClick={formik.submitForm}
        icon={IconUtils.actions.save}
      >
        {t('button.update')}
      </Button>
    </FormikProvider>
  );
};
