import { LessonTypeEnum } from '@modules/admin/service/model';
import { ExerciseTemplate } from '@cms/lesson-template/exercise/ExerciseTemplate';
import React, { useEffect } from 'react';
import { useViewLessonContext } from '@cms/lesson-template/context/ViewLessonContext';
import { useDispatch } from 'react-redux';
import { resetData } from '@app/redux/slices/viewResource';
import { PageTemplate } from './pages/PageTemplate';
import { ReviewLessonToolbar } from '@cms/lesson-template/components/toolbar/common/ReviewLessonToolbar';
import { SelfCheckExerciseMode } from '@cms/lesson-template/components/mode/ExerciseMode';
import SwitchCondition from '@components/common/condition/SwitchCondition';
import PresentationTemplate from './presentation/PresentationTemplate';
import { GlossaryTemplate } from './glossary-game/GlossaryTemplate';
import { ViewLessonWrapper } from '@cms/lesson-template/layout/ViewLessonWrapper';
import useHandleExerciseAction from '@cms/lesson-template/components/toolbar/handler/useHandleExerciseAction';

const ViewLessonContent = () => {
  const dispatch = useDispatch();
  const { lessonType } = useViewLessonContext();

  useHandleExerciseAction();

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <>
      <SelfCheckExerciseMode>
        <ReviewLessonToolbar />
      </SelfCheckExerciseMode>
      <ViewLessonWrapper>
        <SwitchCondition
          conditions={[
            {
              condition: lessonType === LessonTypeEnum.page,
              children: <PageTemplate />,
            },
            {
              condition: lessonType === LessonTypeEnum.presentation,
              children: <PresentationTemplate />,
            },
            {
              condition:
                lessonType === LessonTypeEnum.lesson ||
                lessonType === LessonTypeEnum.collection,
              children: <ExerciseTemplate />,
            },
            {
              condition: lessonType === LessonTypeEnum.game,
              children: <GlossaryTemplate />,
            },
          ]}
        />
      </ViewLessonWrapper>
    </>
  );
};

export default ViewLessonContent;
