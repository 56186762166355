import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import { BasicMessageRes } from '@services/model/Response';
import { EntityStatusEnum } from '@services/model/common';
import { LanguageEnum } from '@components/language/LanguageIcon';

export interface LanguageRes {
  languageId: number;
  code: LanguageEnum;
  name: string;
  displayOrder: number;
  status: EntityStatusEnum;
  createdDate: string;
  modifiedDate: string;
}

export interface CreateLanguageCommand {
  code: string;
  name: string;
  displayOrder: number;
}

export interface UpdateLanguageCommand extends CreateLanguageCommand {
  languageId: number;
}

export interface SchoolLanguageRes {
  languages: LanguageRes[];
  defaultLanguage: LanguageRes;
}

export const LanguageAPI = createApi({
  reducerPath: 'LanguageAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    findByClientId: builder.query<SchoolLanguageRes, string>({
      query: (clientId) => ({
        url: `/public/school/${clientId}/languages`,
      }),
    }),

    findAll: builder.query<PaginationRes<LanguageRes>, PaginationReq>({
      query: (params) => ({
        url: `/languages`,
        params,
      }),
    }),

    findActivated: builder.query<LanguageRes[], unknown>({
      query: () => ({
        url: `/languages/active`,
        method: 'GET',
      }),
    }),

    findById: builder.query<LanguageRes, number>({
      query: (languageId) => ({
        url: `/languages/${languageId}`,
      }),
    }),

    create: builder.mutation<LanguageRes, CreateLanguageCommand>({
      query: (body: CreateLanguageCommand) => ({
        url: '/languages',
        method: 'POST',
        body,
      }),
    }),

    update: builder.mutation<LanguageRes, UpdateLanguageCommand>({
      query: (body: UpdateLanguageCommand) => ({
        url: `/languages/${body.languageId}`,
        method: 'PUT',
        body,
      }),
    }),

    delete: builder.mutation<BasicMessageRes, number>({
      query: (languageId) => ({
        url: `/languages/${languageId}`,
        method: 'DELETE',
      }),
    }),

    publish: builder.mutation<BasicMessageRes, number>({
      query: (languageId) => ({
        url: `/languages/${languageId}/publish`,
        method: 'PUT',
      }),
    }),
  }),
});
