import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { ClassEditForm } from '@modules/users/component/classes/ClassEditForm';
import Button from '@components/button';
import { HeaderGroup } from '@modules/product/styled/CommonStyle';
import { H1 } from '@components/typography';
import { IconUtils } from '@utils/IconUtils';
import { ManageClassTable } from '@modules/users/container/classes/ManageClassTable';

export enum ManageClassActionEnum {
  default = '',
  refresh = 'refresh',
  add = 'add',
  update_metadata = 'update-metadata',
  update_student = 'update-student',
  assign_teacher = 'assign-teacher',
  invite_student = 'invite-student',
  delete = 'delete',
}
export default function ManageClassPage() {
  const { t } = useTranslation();

  const [action, setAction] = useState(ManageClassActionEnum.default);

  const onAddedSuccess = () => {
    setAction(ManageClassActionEnum.refresh);
  };

  const onCancel = () => {
    setAction(ManageClassActionEnum.default);
  };

  return (
    <>
      <HeaderGroup className="header-group">
        <H1>
          {t('classes.header')}

          <Button
            type={'primary'}
            shape="circle"
            icon={IconUtils.add_new}
            onClick={() => setAction(ManageClassActionEnum.add)}
          />
        </H1>
      </HeaderGroup>

      <ManageClassTable action={action} />

      {action === ManageClassActionEnum.add && (
        <CustomModal
          header={t('classes.add_class')}
          className={CustomModalClassEnum.large_modal}
          content={
            <ClassEditForm
              successHandle={onAddedSuccess}
              onCloseFunc={onCancel}
            />
          }
          onCloseFunc={onCancel}
        />
      )}
    </>
  );
}
