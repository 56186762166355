import React, { useState } from 'react';
import { PaginationReq } from '@services/model/PaginationRes';
import { Col, Divider, notification, Row } from 'antd';
import { useViewTeacherInvitation } from '@modules/setting/container/TeacherInvitation';
import { Avatar, Button } from 'antd';
import InvitationTypeComp from '@components/text/InvitationType';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@components/button/ButtonGroup';
import { UserDisplayName } from '@components/avatar/UserDisplayName';
import NavLink from '@components/button/NavLink';
import { H5 } from '@components/typography';
import { SiteMap } from '@router/SiteMap';

export const UserInvitations = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: 5,
    sort: 'invitedDate',
    direction: 'descend',
  });

  const { data, acceptTheInvitation, getTeacherInvitation } =
    useViewTeacherInvitation(params, setParams);

  const handleAcceptInvitation = (
    studentSubscriberId: number,
    isAccepted: boolean
  ) => {
    acceptTheInvitation(
      studentSubscriberId,
      isAccepted,
      (isSuccess, accepted) => {
        const warningMsg = accepted
          ? t('invitation.invitation.warning.accept')
          : t('invitation.invitation.warning.reject');

        if (isSuccess) {
          notification.success({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_success_message')
              : t('invitation.invitation.warning.reject_success_message'),
            placement: 'bottomRight',
          });

          getTeacherInvitation(params);
        } else {
          notification.error({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_error_message')
              : t('invitation.invitation.warning.reject_error_message'),
            placement: 'bottomRight',
          });
        }
      }
    );
  };

  return (
    <div className={'invitation-group'}>
      {data &&
        data.content &&
        data.content.map((item) => {
          return (
            <Row gutter={[8, 12]} key={item.studentSubscriberId}>
              <Col flex={'30px'}>
                <Avatar src={item.teacher.avatar} />
              </Col>

              <Col flex={'auto'}>
                <H5>
                  <InvitationTypeComp type={item.type} params={item.params} />
                </H5>

                <div style={{ marginBottom: '0.5em' }}>
                  <UserDisplayName
                    username={item.teacher.username}
                    firstName={item.teacher.firstName}
                    lastName={item.teacher.lastName}
                    email={item.teacher.email}
                  />
                </div>

                <ButtonGroup type={'left'}>
                  <Button
                    key={'accept-invitation'}
                    type={'primary'}
                    shape={'round'}
                    size={'small'}
                    onClick={() =>
                      handleAcceptInvitation(item.studentSubscriberId, true)
                    }
                  >
                    {t('invitation.invitation.actions.accept')}
                  </Button>

                  <Button
                    key={'reject-invitation'}
                    danger
                    type={'primary'}
                    shape={'round'}
                    size={'small'}
                    onClick={() =>
                      handleAcceptInvitation(item.studentSubscriberId, false)
                    }
                  >
                    {t('invitation.invitation.actions.reject')}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          );
        })}

      <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />

      <ButtonGroup type={'center'}>
        <NavLink href={SiteMap.private.my_notification}>
          <Button type={'default'} shape={'round'}>
            &nbsp; View All &nbsp;
          </Button>
        </NavLink>
      </ButtonGroup>
    </div>
  );
};
