import React from 'react';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { Button, Col, Row } from 'antd';
import Input from '@components/input/input';
import { InputGroup } from '@components/input/InputGroup';
import { useTranslation } from 'react-i18next';
import { Default_Gutter } from '@components/grid';
import { IconUtils } from '@utils/IconUtils';
import SelectProduct from '@modules/common/components/SelectProduct';
import SelectLesson from '@modules/common/components/SelectLesson';

const initFilter = {
  productId: -1,
  lessonId: -1,
  keyword: '',
};

export default function FilterResource(props: {
  layout?: 'vertical' | 'horizontal';
  onSubmit: (params: { keyword: string; lessonId: number }) => void;
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: initFilter,

    onSubmit: (values) => {
      props.onSubmit({ keyword: values.keyword, lessonId: values.lessonId });
    },
  });

  const onProductChange = (productId: number) => {
    formik.setFieldValue('productId', productId);
    formik.setFieldValue('lessonId', -1);
  };

  const columnSpan =
    props.layout != null && props.layout === 'vertical' ? 24 : 12;

  return (
    <FilterResourceStyle
      className={`filter-resource-bank form-${props.layout ?? 'horizontal'}`}
    >
      <FormikProvider value={formik}>
        <Row
          gutter={
            props.layout
              ? [Default_Gutter.md, Default_Gutter.md]
              : [Default_Gutter, Default_Gutter]
          }
        >
          <Col
            xs={24}
            sm={24}
            md={columnSpan}
            lg={columnSpan}
            xl={columnSpan}
            xxl={columnSpan}
          >
            <SelectProduct
              formik={formik}
              label={t('select.select_a_product')}
              placeholder={t('select.select_a_product')}
              name={'productId'}
              value={formik.values.productId}
              onChange={(val) => onProductChange(val)}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={columnSpan}
            lg={columnSpan}
            xl={columnSpan}
            xxl={columnSpan}
          >
            <SelectLesson
              formik={formik}
              label={t('select.select_a_lesson')}
              placeholder={t('select.select_a_lesson')}
              productId={formik.values.productId}
              name={'lessonId'}
              value={formik.values.lessonId}
              onChange={(value) => formik.setFieldValue('lessonId', value)}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={columnSpan}
            lg={columnSpan}
            xl={columnSpan}
            xxl={columnSpan}
          >
            <Input
              name="keyword"
              type="text"
              label={t('label.keyword')}
              placeholder={t('label.enter_keyword')}
              onChange={formik.handleChange}
              value={formik.values.keyword}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={columnSpan}
            lg={columnSpan}
            xl={columnSpan}
            xxl={columnSpan}
          >
            <InputGroup label={'vertical' === props.layout ? '' : <>&nbsp;</>}>
              <Button
                type={'primary'}
                onClick={formik.submitForm}
                icon={IconUtils.actions.filter}
              >
                {t('button.filter')}
              </Button>
            </InputGroup>
          </Col>
        </Row>
      </FormikProvider>
    </FilterResourceStyle>
  );
}

const FilterResourceStyle = styled.div`
  &.form-vertical {
    .form-group {
      margin-bottom: 0;
    }
  }
`;
