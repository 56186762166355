import React, { createContext, ReactNode, useContext } from 'react';
import {
  CompAnswerProps,
  CompProps,
  CompScoringProps,
} from '@cms/ComponentInteface';
import { LessonDBService } from '@cms/context/service/LessonDBService';
import { LessonSessionService } from '@cms/context/service/LessonSessionService';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { LessonRes } from '@modules/product/services/lesson_model';

export const LessonServiceContext = createContext({
  loading: false as boolean,
  lesson: null as LessonRes | null,
  resourceId: 0 as number,
  resource: null as ResourceProps | null,
  resources: [] as ResourceProps[],

  getLessonData: () => {},

  viewResource: (resourceId: number) => {
    console.log(resourceId);
  },

  findById: (resourceId: number) => {
    console.log(resourceId);
  },

  onCreateOrUpdate: (
    resourceId: number,
    components: CompProps[],
    answers: CompAnswerProps[],
    scoring: CompScoringProps[],
    callBackFun: (rsId: number) => void
  ) => {
    console.log(components, answers, scoring, callBackFun);
  },

  onDelete: (resourceId: number, callBack: (rmId: number) => void) => {
    console.log(resourceId, callBack);
  },

  onInsert: (resourceId: number, callBack: (clId: number) => void) => {
    console.log(resourceId, callBack);
  },

  onClone: (resourceId: number, callBack: (clId: number) => void) => {
    console.log('clone', resourceId, callBack);
  },

  onCreateOrUpdateLesson: (name: string) => {
    console.log('.........save and update lesson', name);
  },
});

export const LessonServiceProvider = (props: {
  type: 'db' | 'session';
  lessonId: number;
  resourceId?: number;
  lessonExampleId: number;
  children: ReactNode;
}) => {
  if (props.type === 'db') {
    return (
      <LessonDBService lessonId={props.lessonId} resourceId={props.resourceId}>
        {props.children}
      </LessonDBService>
    );
  } else {
    return (
      <LessonSessionService
        lessonId={props.lessonId}
        lessonExampleId={props.lessonExampleId}
      >
        {props.children}
      </LessonSessionService>
    );
  }
};

export const useLessonServiceContext = () => {
  const context = useContext(LessonServiceContext);

  if (!context) {
    throw new Error('You must wrap container by LessonServiceProvider');
  }
  return context;
};
