import { ChatBox } from '@components/chatbox/ChatBox';
import styled from 'styled-components';
import { useClassRoomContext } from '@classroom/context/ClassRoomContext';
import { Drawer } from 'antd';
import React from 'react';
import { H4 } from '@components/typography';
import { useClassRoomInteractContext } from '@classroom/context/ClassRoomInteractContext';
import { SessionRes } from '@services/model/session';

export const ClassRoomChatBoxModal = () => {
  const { session } = useClassRoomContext();
  const { openChatBox, onOpenChatBox } = useClassRoomInteractContext();

  const handleOnClose = () => {
    onOpenChatBox(false);
  };

  return (
    <Drawer
      className={'chat-box-message'}
      width={500}
      title={<H4 style={{ marginBottom: 0 }}>Messages</H4>}
      placement="right"
      open={openChatBox}
      onClose={handleOnClose}
    >
      <ClassRoomChatBoxStyle className={'class-room-chat-box'}>
        <ClassRoomChatBox session={session} />
      </ClassRoomChatBoxStyle>
    </Drawer>
  );
};

export const ClassRoomChatBox = (props: { session: SessionRes }) => {
  return (
    <ChatBox
      collection={`/sessions/${props.session.code}`}
      channel={`/messages`}
    />
  );
};

const ClassRoomChatBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .chat-box-container {
    flex-grow: 1;
    padding: 0px;

    .chat-box-messages {
      padding: 0px !important;
    }
  }
`;
