import React from 'react';
import { PublishProductStyle } from '@modules/product/container/publish/ProductInformationStep';
import ScrollToTop from '@components/feature/ScrollToTop';
import { useTranslation } from 'react-i18next';

import { H2 } from '@components/typography';
import ProductLessonPrice from '@modules/product/components/prices/ProductLessonPrices';

export default function ProductMultipleLicenseStep(props: {
  productId: number;
  goPrevious: () => void;
  goNext: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop visible={false} />
      <PublishProductStyle className={'publish-product-section'}>
        <H2>{t('publish.basic_info.price')}</H2>

        <div className={'product-publish-content'}>
          <ProductLessonPrice
            productId={props.productId}
            goPrevious={props.goPrevious}
            goNext={props.goNext}
          />
        </div>
      </PublishProductStyle>
    </>
  );
}
