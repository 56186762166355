import { GlossaryGameContent } from '@cms/comps/game/GameUtils';
import { ContentViewer } from '@cms/comps/content/ContentViewerComp';
import React, { useState } from 'react';
import styled from 'styled-components';

export const WordSearchBank = (props: {
  correctAnswers: string[];
  items: GlossaryGameContent[];
}) => {
  const [selectedWord, setSelectedWord] = useState('');

  return (
    <div className={'word-search-box'}>
      {props.items.map((word, index) => {
        if (
          props.correctAnswers.includes(word.word.data.toLowerCase().trim())
        ) {
          return (
            <WordSearchItemStyle
              className={`word-search-collection-item correct-item`}
              key={word.id + '_' + index + '_correct'}
            >
              <div className={'word-search-question-number'}>
                {index + 1}. &nbsp;
              </div>

              <div className={'word-search-question-content'}>
                <ContentViewer contentData={word.description} />
              </div>
            </WordSearchItemStyle>
          );
        } else {
          return (
            <WordSearchItemStyle
              className={`word-search-collection-item ${
                selectedWord === word.id ? ' selected-item' : ''
              }`}
              onClick={() => setSelectedWord(word.id)}
              key={word.id + '_' + index}
            >
              <div className={'word-search-question-number'}>
                {index + 1}. &nbsp;
              </div>
              <div className={'word-search-question-content'}>
                <ContentViewer contentData={word.description} />
              </div>
            </WordSearchItemStyle>
          );
        }
      })}
    </div>
  );
};

const WordSearchItemStyle = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;

  &.correct-item {
    color: ${(props) => props.theme.component.correct};
    cursor: default;
  }

  &.selected-item {
    font-weight: bold;
  }
`;
