import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import { CompTypeEnum } from '@cms/ComponentInteface';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { OrderingProps } from '@cms/comps/interact/ordering/OrderingComp';

export const OrderingExampleData: OrderingProps = {
  id: COMPONENT_UTILS.generateUID(),
  type: CompTypeEnum.ORDERING,

  configuration: {
    sourceItems: [
      {
        label: 'a',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'One',
        },
      },
      {
        label: 'b',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Three',
        },
      },
      {
        label: 'c',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Two',
        },
      },
      {
        label: 'd',
        content: {
          id: COMPONENT_UTILS.generateUID(),
          type: AssetsTypeEnum.TEXT,
          data: 'Four',
        },
      },
    ],
  },
  setting: {
    layout: 'vertical',
    autoScore: true,
  },
};
