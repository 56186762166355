import React from 'react';
import { List, Space } from 'antd';
import { H4 } from '@components/typography';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { LessonRes } from '@modules/product/services/lesson_model';

export const PublicSessionLessonInfo = (props: {
  title: string;
  items: LessonRes[];
}) => {
  return (
    <div className={'public-session-info'}>
      <H4>{props.title}</H4>

      <List
        bordered
        size={'small'}
        itemLayout="horizontal"
        dataSource={props.items}
        renderItem={(item: LessonRes) => {
          return (
            <List.Item>
              <Space>
                <ThumbnailsImg
                  type={'icon'}
                  src={item.thumbnails}
                  ratio={ImageScale.lesson.ratio}
                  width={ImageScale.lesson.icon}
                />
                <span>{item.name}</span>
              </Space>
            </List.Item>
          );
        }}
      />
    </div>
  );
};
