import React, { useState } from 'react';
import styled from 'styled-components';
import DragNDropContext from './DragNDropContext';
import { DropItem } from './DropItem';
import { DragItem } from './DragItem';

interface UserProps {
  label: string;
  firstName: string;
  lastName: string;
  type: string;
}

const users: UserProps[] = [
  { label: '1', firstName: 'User', lastName: '1 ', type: 'user' },
  { label: '2', firstName: 'User', lastName: '2 ', type: 'user' },
  { label: '3', firstName: 'User', lastName: '3 ', type: 'group2' },
  { label: '4', firstName: 'User', lastName: '4 ', type: 'group3' },
];

const UserInfo = ({ user }: { user: UserProps }) => {
  return (
    <span className={'user-info-items'}>
      <b>
        {user.label} - {user.firstName}, {user.lastName}
      </b>
    </span>
  );
};

const DragNDropBeautyExample = (props: { id: string }) => {
  const [columnAUsers, setColumnAUsers] = useState<UserProps[]>([]);
  const [columbBUsers, setColumnBUsers] = useState<UserProps[]>([]);
  const [columbCUsers, setColumnCUsers] = useState<UserProps[]>([]);

  // const handleOnRemove = (droppableId: string, dragId: string) => {
  //   const dragData = parseDragId(dragId);
  //
  //   if (droppableId == "") {
  //     // drop out from object bank.....
  //   } else {
  //     setDropUsers((prev) => {
  //       const removeAnswer = droppableId + ":" + dragData.draggableId;
  //       return [...prev].filter((res) => {
  //         return res != removeAnswer;
  //       });
  //     })
  //   }
  // }
  //
  // const handleOnDrop = (source: DraggableLocation, destination: DraggableLocation, dragId: string) => {
  //   const dragData = parseDragId(dragId);
  //
  //   setDropUsers((prev) => {
  //     return [...prev, destination.droppableId + ":" + dragData.draggableId];
  //   });
  // }

  const handleDropColumnA = (draggableId: string) => {
    const dropItems = users.filter((user) => {
      return user.label === draggableId;
    });

    setColumnAUsers((pre) => {
      return [...pre, ...dropItems];
    });
  };

  const handleOnRemoveColumnA = (draggableId: string) => {
    setColumnAUsers((pre) => {
      return [...pre].filter((stu) => {
        return stu.label !== draggableId;
      });
    });
  };

  const handleOnReorderColumnA = (draggableId: string, index: number) => {
    console.log(draggableId, index);
  };

  const handleDropColumnB = (draggableId: string) => {
    const dropItems = users.filter((user) => {
      return user.label === draggableId;
    });

    setColumnBUsers((pre) => {
      return [...pre, ...dropItems];
    });
  };

  const handleOnRemoveColumnB = (draggableId: string) => {
    setColumnBUsers((pre) => {
      return [...pre].filter((stu) => {
        return stu.label !== draggableId;
      });
    });
  };

  const handleOnReorderColumnB = (draggableId: string, index: number) => {
    console.log(draggableId, index);
  };

  const handleDropColumnC = (draggableId: string) => {
    const dropItems = users.filter((user) => {
      return user.label === draggableId;
    });

    setColumnCUsers((pre) => {
      return [...pre, ...dropItems];
    });
  };

  const handleOnRemoveColumnC = (draggableId: string) => {
    setColumnCUsers((pre) => {
      return [...pre].filter((stu) => {
        return stu.label !== draggableId;
      });
    });
  };

  const handleOnReorderColumnC = (draggableId: string, index: number) => {
    console.log(draggableId, index);
  };

  return (
    <DragNDropBeautyExampleStyle className={'drag-n-drop-comp'}>
      <DragNDropContext id={props.id}>
        <DropItem
          acceptType={''}
          droppableId={''}
          className={'draggable-containers'}
        >
          {users.map((user, index) => {
            return (
              <DragItem
                acceptType={user.type}
                draggableId={user.label}
                index={index}
                key={JSON.stringify(user) + '_' + index}
              >
                <UserInfo user={user} />
              </DragItem>
            );
          })}
        </DropItem>

        <div className={'table-content'}>
          <table>
            <tr>
              <th style={{ width: '33%' }}>A - Column A: User group</th>
              <th style={{ width: '33%' }}>B - Column B: Group 1</th>
              <th style={{ width: '33%' }}>C - Column C: Group 2</th>
            </tr>

            <tr>
              <td>
                <DropItem
                  acceptType={'user'}
                  droppableId={'a'}
                  className={'draggable-containers'}
                  handleOnDrop={handleDropColumnA}
                  handleOnRemove={handleOnRemoveColumnA}
                  handleOnReorder={handleOnReorderColumnA}
                >
                  {columnAUsers.map((user, index) => {
                    return (
                      <DragItem
                        acceptType={''}
                        draggableId={user.label}
                        index={index}
                        key={JSON.stringify(user) + '_' + index}
                      >
                        <UserInfo user={user} />
                      </DragItem>
                    );
                  })}
                  <h3>User</h3>
                </DropItem>
              </td>
              <td>
                <DropItem
                  acceptType={'group1'}
                  droppableId={'b'}
                  className={'droppable-containers'}
                  handleOnDrop={handleDropColumnB}
                  handleOnRemove={handleOnRemoveColumnB}
                  handleOnReorder={handleOnReorderColumnB}
                >
                  {columbBUsers.map((user, index) => {
                    return (
                      <DragItem
                        acceptType={''}
                        draggableId={user.label}
                        index={index}
                        key={JSON.stringify(user) + '_' + index}
                      >
                        <UserInfo user={user} />
                      </DragItem>
                    );
                  })}
                  <h3>Group 1</h3>
                </DropItem>
              </td>
              <td>
                <DropItem
                  acceptType={'group2'}
                  droppableId={'c'}
                  className={'droppable-containers'}
                  handleOnDrop={handleDropColumnC}
                  handleOnRemove={handleOnRemoveColumnC}
                  handleOnReorder={handleOnReorderColumnC}
                >
                  {columbCUsers.map((user, index) => {
                    return (
                      <DragItem
                        draggableId={user.label}
                        index={index}
                        acceptType={''}
                        key={JSON.stringify(user) + '_' + index}
                      >
                        <UserInfo user={user} />
                      </DragItem>
                    );
                  })}
                  <h3>Group 2</h3>
                </DropItem>
              </td>
            </tr>
          </table>
        </div>
      </DragNDropContext>
    </DragNDropBeautyExampleStyle>
  );
};

export default DragNDropBeautyExample;

const DragNDropBeautyExampleStyle = styled.div`
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto;

  table {
    width: 100%;

    tr td,
    tr th {
      padding: 5px 10px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;

      &:last-child {
        border-right: 1px solid #ccc;
      }
    }

    tr:last-child {
      td,
      th {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .droppable-container {
    border: 1px solid #ccc;
    margin-bottom: 30px;
    min-height: 45px;
    gap: 0.5em;
    align-items: center;

    .draggable-item {
      border: 1px dashed #ccc;
      display: inline-flex;
      padding: 0.25em 0.5em;
      margin: 0.25em;
    }
  }
`;
