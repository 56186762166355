import { Alert as AndtAlert, AlertProps } from 'antd';
import styled from 'styled-components';

const AntdAlert = styled(AndtAlert)`
  margin-bottom: 1.5em;

  &:not(:first-child) {
    margin-top: 1.5em;
  }
`;

const Alert = ({ className, ...props }: AlertProps) => {
  return (
    <AntdAlert
      className={`gstudy-alert ${className ? className : ''}`}
      {...props}
    />
  );
};

export default Alert;
