import { PreviousSectionButton } from '@cms/lesson-template/components/buttons/PreviousSectionButton';
import React from 'react';
import {
  AnonymousViewMode,
  DoExerciseMode,
  GradingExerciseMode,
  ReviewExerciseMode,
  SelfCheckExerciseMode,
} from '@cms/lesson-template/components/mode/ExerciseMode';

export const PreviousSectionBtn = () => {
  return (
    <>
      <AnonymousViewMode>
        <PreviousSectionButton />
      </AnonymousViewMode>

      <SelfCheckExerciseMode>
        <PreviousSectionButton />
      </SelfCheckExerciseMode>

      <DoExerciseMode>
        <PreviousSectionButton />
      </DoExerciseMode>

      <GradingExerciseMode>
        <PreviousSectionButton />
      </GradingExerciseMode>

      <ReviewExerciseMode>
        <PreviousSectionButton />
      </ReviewExerciseMode>
    </>
  );
};
