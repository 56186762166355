import { GameUtils } from '@cms/comps/game/GameUtils';

export interface GridWordProps {
  id: string;

  row: number;
  column: number;

  wordId?: string[];
  char: string;
  answer: string;
  correct: boolean;
}

export interface GridWordCharConfig {
  horizontal: boolean;
  vertical: boolean;
  diagonal: boolean;
  maxRows: number;
  maxColumns: number;
}

export class WordSearchGenerateWord {
  placementWords;
  setting;
  wordGridArray;

  constructor(words: string[], setting: GridWordCharConfig) {
    this.placementWords = [
      ...words.sort((a, b) => {
        return a.length - b.length;
      }),
    ];
    this.setting = setting;
    this.wordGridArray = this.initWordList(setting);

    // then place words...
    this.placeWord();
  }

  initWordList = (setting: GridWordCharConfig): GridWordProps[][] => {
    const wordList = [];

    for (let i = 0; i < setting.maxRows; i++) {
      const word_grid_row: any[] = [];

      for (let j = 0; j < setting.maxColumns; j++) {
        word_grid_row.push({
          id: `row-${i}-column-${j}`,

          row: i,
          column: j,

          char: GameUtils.hiddenCharacter,
          correct: false,
        });
      }

      wordList.push(word_grid_row);
    }

    return wordList;
  };

  placeWord = () => {
    // let tWord = 0;

    const horizontal = Number(this.setting.horizontal);
    const vertical = Number(this.setting.vertical);
    const diagonal = Number(this.setting.diagonal);

    placeWord: while (this.placementWords.length > 0) {
      const px = Math.floor(Math.random() * this.setting.maxColumns);
      const py = Math.floor(Math.random() * this.setting.maxRows);

      const randDir = Math.floor(Math.random() * (2 + diagonal)) + 1;

      let dx = (randDir >> 0) & horizontal;
      let dy = (randDir >> 1) & vertical;

      if ((dx | dy) === 0) {
        dx = horizontal | diagonal;
        dy = vertical | diagonal;
      }

      const word = this.placementWords[0];

      if (
        word.length <= this.setting.maxColumns &&
        word.length <= this.setting.maxRows
      ) {
        // find position to display
        for (let i = 0; i < word.length; ++i) {
          if (px + dx * i < 0 || py + dy * i < 0) {
            continue placeWord;
          }

          if (
            px + dx * i >= this.setting.maxColumns ||
            py + dy * i >= this.setting.maxRows
          ) {
            continue placeWord;
          }

          try {
            const curChar = this.wordGridArray[py + dy * i][px + dx * i];

            if (curChar) {
              if (
                curChar.char !== GameUtils.hiddenCharacter &&
                curChar.char !== word.toUpperCase().charAt(i)
              ) {
                continue placeWord;
              }
            }
          } catch (e) {
            console.log(
              `err in placeWord ${py + dy * i}/${
                this.wordGridArray.length
              }  and ${px + dx * i}/${this.wordGridArray[0].length}`,
              this.wordGridArray
            );
          }
        }

        for (let i = 0; i < word.length; ++i) {
          const tx = py + dy * i;
          const ty = px + dx * i;
          this.appendWordToTheBox(tx, ty, word.toUpperCase().charAt(i), true);
        }
      }

      this.placementWords.shift();
    }

    for (let i = 0; i < this.setting.maxRows; i++) {
      for (let j = 0; j < this.setting.maxColumns; j++) {
        const currentChart = this.wordGridArray[i][j];
        if (currentChart && currentChart.char === GameUtils.hiddenCharacter) {
          this.appendWordToTheBox(
            i,
            j,
            String.fromCharCode(
              Math.floor(Math.random() * (0x5a - 0x41) + 0x41)
            ),
            false
          );
        }
      }
    }
  };

  appendWordToTheBox(
    rowIdx: number,
    columnIdx: number,
    char: string,
    isCorrect: boolean
  ) {
    this.wordGridArray[rowIdx][columnIdx] = {
      id: `row-${rowIdx}-column-${columnIdx}`,

      row: rowIdx,
      column: columnIdx,

      char: char,
      answer: '',
      correct: isCorrect,
    };
  }

  getResult = (): GridWordProps[][] => {
    return this.wordGridArray;
  };
}
