import { Checkbox, Col, Row } from 'antd';
import React from 'react';
import { InputGroup } from '@components/input/InputGroup';
import { RoleEnum } from '@app/redux/slices/roles';
import { Default_Gutter } from '@components/grid';
import styled from 'styled-components';
import { HintText } from '@components/text/HintText';

export const ViewUserPermission = (props: {
  roles: RoleEnum;
  permissions: RoleEnum[];
}) => {
  return (
    <UpdatePermissionStyle className={'teacher-permission-form'}>
      <Checkbox.Group style={{ width: '100%' }} value={props.permissions}>
        <Row gutter={[Default_Gutter, Default_Gutter]}>
          <Col span={24}>
            <InputGroup label={'Management'}>
              <Checkbox value={RoleEnum.MANAGE_COURSE}>
                Manage Course
                <HintText>Allow user create and publish courses</HintText>
              </Checkbox>

              <Checkbox value={RoleEnum.MANAGE_CLASS}>
                Manage Class And Students
                <HintText>Allow user to manage classes and students</HintText>
              </Checkbox>

              <Checkbox value={RoleEnum.CREATE_CALENDAR}>
                Create Calendar Event
                <HintText>Allow user to create Calendar events.</HintText>
              </Checkbox>
            </InputGroup>
          </Col>

          <Col span={24}>
            <InputGroup label={'CMS'}>
              <Checkbox value={RoleEnum.COMPOSE_LESSON}>
                Compose Lesson
                <HintText>
                  Allow user to compose lesson. User can access all lesson
                  created by him.
                </HintText>
              </Checkbox>
              <Checkbox value={RoleEnum.CREATE_PRODUCT}>
                Create product
                <HintText>
                  Allow user to create product. User can access all products
                  created by him.
                </HintText>
              </Checkbox>
              <Checkbox value={RoleEnum.CREATE_LESSON_TEMPLATE}>
                Create Lesson Template
                <HintText>Allow user to create lesson template.</HintText>
              </Checkbox>
            </InputGroup>
          </Col>

          <Col span={24}>
            <InputGroup label={'License'}>
              <Checkbox value={RoleEnum.BOOK_LICENSE}>
                Assign Book License
                <HintText>
                  Allow user to assign completed product to teachers. User can
                  access all products and all lessons.
                </HintText>
              </Checkbox>

              <Checkbox value={RoleEnum.PUBLISH_LICENSE}>
                Publish Books
                <HintText>
                  Allow user to publish products. User can access all products
                  and all lessons.
                </HintText>
              </Checkbox>
            </InputGroup>
          </Col>

          <Col span={24}>
            <InputGroup label={'Intergration'}>
              <Checkbox value={RoleEnum.MANAGE_OAUTH_APP}>
                Manage Oauth App
                <HintText>Manage Oauth App.</HintText>
              </Checkbox>
            </InputGroup>
          </Col>
        </Row>
      </Checkbox.Group>
    </UpdatePermissionStyle>
  );
};

const UpdatePermissionStyle = styled.div`
  .ant-checkbox-wrapper {
    width: 100%;
    margin-left: 0;

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }
`;
