import React, { useEffect, useState } from 'react';
import { MAX_PAGE_SIZE, PaginationReq } from '@services/model/PaginationRes';
import { useTranslation } from 'react-i18next';
import TablePagination from '@components/table/TablePagination';
import { StudentAssignmentAPI } from '@modules/assignments/service/StudentAssignmentAPI';
import { Space, Statistic } from 'antd';
import { DateAndTimeLabel } from '@components/text/DateLabel';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ImageScale } from '@utils/ImageScale';
import {
  ExerciseAction,
  StudentExerciseWithStatus,
} from '@components/exercise/ExerciseStatus';
import styled from 'styled-components';
import { ResponsiveScreen } from '@hooks/useMedia';
import Card, { CardClassEnum } from '@components/card';
import { AssignmentStudentMobileItem } from '@components/common/assignment/teacher/AssignmentMobileItem';
import { AssignmentInstanceRes } from '../service/assignment_model';
const { Countdown } = Statistic;

export const StudentAssignmentTable = (props: { classId?: number }) => {
  const { t } = useTranslation();

  const [filterStudentAssignment, { data, isFetching }] =
    StudentAssignmentAPI.endpoints.filterStudentAssignment.useLazyQuery();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: MAX_PAGE_SIZE,
    sort: 'createdDate',
    direction: 'descend',
  });

  useEffect(() => {
    refreshData();
  }, [params]);

  const refreshData = () => {
    if (props.classId != null && props.classId > 0) {
      filterStudentAssignment({ ...params, classId: props.classId });
    } else {
      filterStudentAssignment(params);
    }
  };

  const columns = [
    {
      title: t('assignment.lesson'),
      dataIndex: 'thumbnails',
      key: 'thumbnails',
      width: 72,
    },
    {
      title: t('label.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('assignment.unlockAt'),
      dataIndex: 'unlockAt',
      key: 'unlockAt',
      sorter: 180,
      width: 200,
      align: 'center',
    },
    {
      title: t('assignment.lockAt'),
      dataIndex: 'lockAt',
      key: 'lockAt',
      sorter: true,
      width: 200,
      align: 'center',
    },
    {
      title: t('label.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      align: 'center',
    },
  ];

  const processDataRow = (item: AssignmentInstanceRes) => {
    return {
      key: item.assignmentInstanceId,
      thumbnails: (
        <ThumbnailsImg
          type={'thumbnails'}
          src={item.lesson.thumbnails}
          ratio={ImageScale.lesson.ratio}
          minWidth={72}
        />
      ),
      name: (
        <>
          <StudentExerciseWithStatus
            unlockInMs={item.unlockInMs}
            expiredInMs={item.expiredInMs}
            status={item.exercise.status}
          >
            {item.name}
          </StudentExerciseWithStatus>

          <TimeCountDown
            unlockInMs={item.unlockInMs}
            expiredInMs={item.expiredInMs}
          />
        </>
      ),
      unlockAt: <DateAndTimeLabel label={item.unlockAt} />,
      lockAt: <DateAndTimeLabel label={item.lockAt} />,

      actions: (
        <div className={'assignment-actions'}>
          <ExerciseAction item={item} />
        </div>
      ),
    };
  };

  const renderMobile = (item: AssignmentInstanceRes) => {
    return (
      <Card hoverable className={`${CardClassEnum.rectangle_box}`}>
        <AssignmentStudentMobileItem item={item} />
      </Card>
    );
  };

  return (
    <TablePagination
      params={params}
      isLoading={isFetching}
      columns={columns}
      data={data}
      refresh={setParams}
      processDataRow={processDataRow}
      responsive={{
        screen: [ResponsiveScreen.xs, ResponsiveScreen.sm, ResponsiveScreen.md],
        render: renderMobile,
      }}
    />
  );
};

const milisecondOfMinute = 60 * 1000;
const milisecondOfHour = 60 * milisecondOfMinute;
const milisecondOfDay = 24 * milisecondOfHour;

const confirm = 30 * milisecondOfMinute;
const warning = 15 * milisecondOfMinute;
const error = 5 * milisecondOfMinute;

export const getAssignmentClass = (expiredInMs: number) => {
  if (expiredInMs < error) {
    return 'error-timeout';
  } else if (expiredInMs < warning) {
    return 'warning-timeout';
  } else if (expiredInMs < confirm) {
    return 'confirm-timeout';
  } else {
    return 'default-timeout';
  }
};

export const getAssignmentScoreColor = (percent: number) => {
  if (percent <= 50) {
    return '#d9363e';
  } else if (percent <= 66) {
    return '#ffc53d';
  } else if (percent <= 100) {
    return '#389e0d';
  } else {
    return '#389e0d';
  }
};

export const getAssignmentColor = (expiredInMs: number): string => {
  if (expiredInMs < error) {
    return '#d9363e';
  } else if (expiredInMs < warning) {
    return '#ffc53d';
  } else if (expiredInMs < confirm) {
    return '#389e0d';
  } else {
    return '#389e0d';
  }
};

const CountdownStyle = styled(Countdown)`
  font-size: inherit;
  font-weight: bold;
  display: inline-flex;

  .ant-statistic-content {
    font-size: inherit;
    color: inherit;
  }

  &.default-timeout {
    color: ${(props) => props.theme.warning.info};
  }

  &.confirm-timeout {
    color: ${(props) => props.theme.warning.success};
  }

  &.warning-timeout {
    color: ${(props) => props.theme.warning.warning};
    font-weight: bold;
  }

  &.error-timeout {
    color: ${(props) => props.theme.warning.error};
  }
`;

export enum AssignmentCountDownFormatEnum {
  HH_MM_SS = 'HH:mm:ss',
  HH_MM = 'HH:mm',
  MM_SS = 'mm:ss',
}

export const AssignmentCountDown = (props: { item: AssignmentInstanceRes }) => {
  return (
    <TimeCountDown
      unlockInMs={props.item.unlockInMs}
      expiredInMs={props.item.expiredInMs}
    />
  );
};

export const TimeCountDown = (props: {
  unlockInMs: number;
  expiredInMs: number;
}) => {
  const { t } = useTranslation();

  const getFormat = (value: number) => {
    if (value < milisecondOfHour) {
      return AssignmentCountDownFormatEnum.MM_SS;
    } else {
      return AssignmentCountDownFormatEnum.HH_MM_SS;
    }
  };

  return (
    <AssignmentCountDownStyle className={'assignment-status'}>
      {props.unlockInMs > 0 ? (
        <div className={'assignment-status-wrap'}>
          <AssignmentUnlockTime time={props.unlockInMs} />
        </div>
      ) : (
        <>
          {props.expiredInMs > milisecondOfDay ? (
            <Space align={'center'}>
              {t('assignment.warning.expired_in', {
                day: (props.expiredInMs / milisecondOfDay).toFixed(1),
              })}
            </Space>
          ) : (
            <Space align={'center'}>
              <span>{t('assignment.expired_in')}</span>

              <CountdownStyle
                className={getAssignmentClass(props.expiredInMs)}
                value={Date.now() + props.expiredInMs}
                format={getFormat(props.expiredInMs)}
              />
            </Space>
          )}
        </>
      )}
    </AssignmentCountDownStyle>
  );
};

const AssignmentUnlockTime = (props: { time: number }) => {
  const { t } = useTranslation();

  const countDown = getCountDownLabel(props.time);

  if (countDown.unit === 'day' && countDown.day) {
    return (
      <Space align={'center'}>
        {t('assignment.warning.unlock_in', {
          day: countDown.day,
        })}
      </Space>
    );
  } else if (countDown.unit === 'hour') {
    return (
      <Space align={'center'}>
        <span>{t('assignment.start_in')} </span>
        <CountdownStyle
          value={Date.now() + props.time}
          format={countDown.format}
        />
      </Space>
    );
  } else {
    return (
      <Space align={'center'}>
        <span>{t('assignment.start_in')} </span>

        <CountdownStyle
          value={Date.now() + props.time}
          format={countDown.format}
        />
      </Space>
    );
  }
};

const getCountDownLabel = (ms: number) => {
  if (ms > milisecondOfDay) {
    return {
      day: (ms / milisecondOfDay).toFixed(1),
      unit: 'day',
    };
  } else if (ms > milisecondOfHour) {
    return {
      hour: (ms / milisecondOfHour).toFixed(1),
      unit: 'hour',
      format: AssignmentCountDownFormatEnum.HH_MM_SS,
    };
  } else if (ms > milisecondOfMinute) {
    return {
      min: (ms / milisecondOfHour).toFixed(1),
      unit: 'min',
      format: AssignmentCountDownFormatEnum.HH_MM,
    };
  } else {
    return {
      min: (ms / 1000).toFixed(1),
      unit: 'sec',
      format: AssignmentCountDownFormatEnum.MM_SS,
    };
  }
};

const AssignmentCountDownStyle = styled.div`
  white-space: nowrap;

  .assignment-status-wrap {
    display: inline-flex;
    align-items: center;

    .ant-statistic {
      margin-left: 0.25em;
    }
  }
`;
