import React, { useEffect, useMemo, useState } from 'react';
import { PriceTag } from '@components/product/LessonInfo';
import { H4 } from '@components/typography';
import Table from '@components/table/Table';
import { ProductAPI } from '@modules/product/services/ProductAPI';
import { useTranslation } from 'react-i18next';
import { Button, notification, Space } from 'antd';
import { ImageScale } from '@utils/ImageScale';
import ThumbnailsImg from '@components/product/ProductThumbnails';
import { ProductPriceRes } from '@modules/product/services/product_model';

const ProductLessonPrice = (props: {
  productId: number;
  goPrevious: () => void;
  goNext: () => void;
}) => {
  const { t } = useTranslation();

  const [getProductPrices, { data, isSuccess, isLoading }] =
    ProductAPI.endpoints.getProductPrices.useLazyQuery({});

  const [updateProductPrice, { isLoading: isUpdateLoading }] =
    ProductAPI.endpoints.updateProductPrice.useMutation({});

  const [prices, setPrices] = useState<ProductPriceRes | null>(null);

  useEffect(() => {
    getProductPrices(props.productId);
  }, [props.productId]);

  useEffect(() => {
    if (data && isSuccess) {
      setPrices(data);
    }
  }, [data]);

  const columns = [
    {
      title: 'Lesson Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '3 month',
      dataIndex: 'threeMonth',
      key: 'threeMonth',
      width: 200,
    },
    {
      title: '6 month',
      dataIndex: 'sixMonth',
      key: 'sixMonth',
      width: 200,
    },
    {
      title: '1 Year',
      dataIndex: 'oneYear',
      key: 'oneYear',
      width: 200,
    },
    {
      title: 'Forever',
      dataIndex: 'forever',
      key: 'forever',
      width: 200,
    },
  ];

  const handleUpdateProductPrices = () => {
    updateProductPrice(prices!)
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'Bài học đã được cập nhật',
          placement: 'bottomRight',
        });

        props.goNext();
      })
      .catch(() => {
        notification.error({
          message: 'Cập nhật thất bại',
          description: 'Có sự cố khi cập nhật bài học. Vui lòng thử lại sau.',
          placement: 'bottomRight',
        });
      });
  };

  const handleLessonPriceChange = (
    productTocId: number,
    lessonId: number,
    path: string,
    value: number
  ) => {
    setPrices((prevState) => {
      if (prevState != null) {
        const newVal = Object.assign({}, prevState);
        newVal.productTocs = [...newVal.productTocs].map((toc) => {
          if (toc.productTocId === productTocId) {
            const newLessons = toc.lessons.map((ls) => {
              if (ls.lessonId === lessonId) {
                const lsData = { ...ls };
                // @ts-ignore
                lsData[path] = value;
                return { ...lsData };
              } else {
                return { ...ls };
              }
            });

            return { ...toc, lessons: newLessons };
          } else {
            return { ...toc };
          }
        });
        return newVal;
      } else {
        return null;
      }
    });
  };

  const handleProductPriceChange = (path: string, value: number) => {
    setPrices((prevState) => {
      if (prevState != null) {
        const newVal = Object.assign({}, prevState);
        // @ts-ignore
        newVal[path] = value;
        return newVal;
      } else {
        return null;
      }
    });
  };

  const datas = useMemo(() => {
    if (data) {
      const lessonLicense = [];

      lessonLicense.push({
        key: 'product-' + data.productId,
        name: (
          <Space size={12}>
            <ThumbnailsImg
              type={'icon'}
              src={data.icon}
              ratio={ImageScale.product.ratio}
              width={ImageScale.product.iconLarge}
            />

            <H4 className={'lesson-display'}>{data.name}</H4>
          </Space>
        ),
        threeMonth: (
          <PriceTag
            className={'product-price'}
            price={data.threeMonth}
            onChange={(val) => handleProductPriceChange('threeMonth', val)}
          />
        ),
        sixMonth: (
          <PriceTag
            className={'product-price'}
            price={data.sixMonth}
            onChange={(val) => handleProductPriceChange('sixMonth', val)}
          />
        ),
        oneYear: (
          <PriceTag
            className={'product-price'}
            price={data.oneYear}
            onChange={(val) => handleProductPriceChange('oneYear', val)}
          />
        ),
        forever: (
          <PriceTag
            className={'product-price'}
            price={data.forever}
            onChange={(val) => handleProductPriceChange('forever', val)}
          />
        ),
      });

      lessonLicense.push({
        key: 'product-header-' + data.productId,
        name: <H4>You can separate lessons</H4>,
        threeMonth: '',
        sixMonth: '',
        oneYear: '',
        forever: '',
      });

      data.productTocs.forEach((item) => {
        lessonLicense.push({
          key: 'product-toc-' + item.productTocId,
          name: <b>{item.name}</b>,
          threeMonth: '',
          sixMonth: '',
          oneYear: '',
          forever: '',
        });

        if (item.lessons.length > 0) {
          item.lessons.forEach((ls) => {
            lessonLicense.push({
              key: 'lesson-' + ls.lessonId,
              lesson: ls,
              name: (
                <div className={'lesson-info'} style={{ marginLeft: 50 }}>
                  <Space>
                    <ThumbnailsImg
                      type={'icon'}
                      src={ls.thumbnails}
                      ratio={ImageScale.product.ratio}
                      width={ImageScale.product.icon}
                    />

                    <span className={'lesson-display'}>{ls.name}</span>
                  </Space>
                </div>
              ),
              threeMonth: (
                <PriceTag
                  className={'lesson-price'}
                  price={ls.threeMonth}
                  onChange={(val) =>
                    handleLessonPriceChange(
                      item.productTocId,
                      ls.lessonId,
                      'threeMonth',
                      val
                    )
                  }
                />
              ),
              sixMonth: (
                <PriceTag
                  className={'lesson-price'}
                  price={ls.sixMonth}
                  onChange={(val) =>
                    handleLessonPriceChange(
                      item.productTocId,
                      ls.lessonId,
                      'sixMonth',
                      val
                    )
                  }
                />
              ),
              oneYear: (
                <PriceTag
                  className={'lesson-price'}
                  price={ls.oneYear}
                  onChange={(val) =>
                    handleLessonPriceChange(
                      item.productTocId,
                      ls.lessonId,
                      'oneYear',
                      val
                    )
                  }
                />
              ),
              forever: (
                <PriceTag
                  className={'lesson-price'}
                  price={ls.forever}
                  onChange={(val) =>
                    handleLessonPriceChange(
                      item.productTocId,
                      ls.lessonId,
                      'forever',
                      val
                    )
                  }
                />
              ),
            });
          });
        }
      });

      //
      // lessonLicense.push({
      //   key: 'product-save-' + data.productId,
      //   name: <H4>Save</H4>,
      //   threeMonth: 1,
      //   sixMonth: 1,
      //   oneYear: 1,
      //   forever: 1,
      // });
      console.log('handleLessonPriceChange', lessonLicense);
      return lessonLicense;
    } else {
      return [];
    }
  }, [prices]);

  const getRowKeyFunc = (record: any) => {
    return record.key;
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={datas}
        pagination={false}
        rowKey={getRowKeyFunc}
      />

      <div className={'product-publish-step'}>
        <Button
          type={'default'}
          shape={'round'}
          onClick={() => props.goPrevious()}
        >
          {t('button.step.previous')}
        </Button>

        <Button
          type={'primary'}
          loading={isLoading || isUpdateLoading}
          shape={'round'}
          onClick={handleUpdateProductPrices}
        >
          {t('button.step.next')}
        </Button>
      </div>
    </>
  );
};

export default ProductLessonPrice;
