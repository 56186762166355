// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaM-bQn1mrMSUPlifNUEGsEdzO7_VVi-M",
  authDomain: "gstudy-338907.firebaseapp.com",
  databaseURL: "https://gstudy-338907-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gstudy-338907",
  storageBucket: "gstudy-338907.appspot.com",
  messagingSenderId: "382780470326",
  appId: "1:382780470326:web:5fe1a7416e77da6a30d497",
  measurementId: "G-8Y4W433LL6"
};

// Initialize Firebase
export const firebase = undefined; // initializeApp(firebaseConfig);
export const authorize = undefined; // getAuth(firebase);
export const database = undefined; // getDatabase(firebase);
export const firestore = undefined; // getFirestore(firebase);

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyASYLJjKYY0BcHdd2s85Zp6NqX_wlbrxFY',
  authDomain: 'poetic-archway-346018.firebaseapp.com',
  projectId: 'poetic-archway-346018',
  storageBucket: 'poetic-archway-346018.appspot.com',
  messagingSenderId: '361987602466',
  appId: '1:361987602466:web:26ff9cc1aaa146b412327b',
  measurementId: 'G-GM7DW001KY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/
