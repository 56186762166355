import { PageRouterProps } from '@router/modules/props';
import { SiteMap } from '@router/SiteMap';
import { PrivateRouter } from '@router/PrivateRouter';
import { RoleEnum } from '@app/redux/slices/roles';
import React from 'react';
import ManageLessons from '@router/pages/content/lesson/manage_lesson';
import ReviewLesson from '@router/pages/content/lesson/review_lesson';
import CreateLesson from '@router/pages/content/lesson/create_lesson';
import BuildSection from '@router/pages/content/lesson/compose/build_section';
import ComposeLesson from '@router/pages/content/lesson/compose/compose_lesson';
import BuildLessonBank from '@router/pages/content/lesson/compose/build_lesson_bank';
import ManageProduct from '@router/pages/content/product/manage_product';
import PublishProduct from '@router/pages/content/product/publish_product';
import ReviewProduct from '@router/pages/content/product/review_product';
import TableOfContentProduct from '@router/pages/content/product/table-of-content';
import CreateResource from '@router/pages/content/resource/create_resource';
import ManageStandardSet from '@router/pages/content/manage_standard_set';
import ManageAssetsFile from '@router/pages/content/manage_assets';
import ManageResources from '@router/pages/content/resource/manage_resource';
import ComposeLessonResource from '@router/pages/content/lesson/compose/compose_lesson_resource';
import ComposeLessonResourceSimple from '@router/pages/content/lesson/compose/compose_lesson_resource_simple';
import ComposeResource from '@router/pages/content/resource/compose_resource';
import ComposeLessonSimple from '@router/pages/content/lesson/compose/compose_lesson_simple';
import ManageProductAccessible from '@router/pages/content/product/manage_product_accessible';

export const ProductRouters: PageRouterProps[] = [
  // product
  {
    path: SiteMap.content.product.list,
    element: (
      <PrivateRouter
        roles={SiteMap.content.product.roles}
        element={<ManageProduct />}
      />
    ),
  },
  {
    path: SiteMap.content.product.publish,
    element: (
      <PrivateRouter
        roles={SiteMap.content.product.publish_roles}
        element={<PublishProduct />}
      />
    ),
  },

  {
    path: SiteMap.content.product.accessible,
    element: (
      <PrivateRouter
        roles={SiteMap.content.product.accessible_roles}
        element={<ManageProductAccessible />}
      />
    ),
  },

  {
    path: SiteMap.content.product.review,
    element: <PrivateRouter element={<ReviewProduct />} />,
  },
  {
    path: SiteMap.content.product.toc,
    element: <PrivateRouter element={<TableOfContentProduct />} />,
  },

  // lesson
  {
    path: SiteMap.content.lesson.list,
    element: <PrivateRouter element={<ManageLessons />} />,
  },
  {
    path: SiteMap.content.lesson.review,
    element: <PrivateRouter element={<ReviewLesson />} />,
  },
  {
    path: SiteMap.content.lesson.create,
    element: <PrivateRouter element={<CreateLesson />} />,
  },

  {
    path: SiteMap.content.lesson.compose,
    element: <PrivateRouter element={<ComposeLesson />} />,
  },
  {
    path: SiteMap.content.lesson.compose_simple,
    element: <PrivateRouter element={<ComposeLessonSimple />} />,
  },

  {
    path: SiteMap.content.lesson.compose_resource,
    element: <PrivateRouter element={<ComposeLessonResource />} />,
  },
  {
    path: SiteMap.content.lesson.compose_resource_simple,
    element: <PrivateRouter element={<ComposeLessonResourceSimple />} />,
  },

  {
    path: SiteMap.content.lesson.build_resource,
    element: <PrivateRouter element={<BuildLessonBank />} />,
  },
  {
    path: SiteMap.content.lesson.build_section,
    element: <PrivateRouter element={<BuildSection />} />,
  },

  // resource
  {
    path: SiteMap.content.resource.list,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<ManageResources />}
      />
    ),
  },
  {
    path: SiteMap.content.resource.create,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<CreateResource />}
      />
    ),
  },
  {
    path: SiteMap.content.resource.compose,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<ComposeResource />}
      />
    ),
  },
  {
    path: SiteMap.content.resource.assets,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
        element={<ManageAssetsFile />}
      />
    ),
  },

  {
    path: SiteMap.content.resource.standards_set,
    element: (
      <PrivateRouter
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL]}
        element={<ManageStandardSet />}
      />
    ),
  },
];
