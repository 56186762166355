import { LanguageEnum } from '@components/language/LanguageIcon';

const env: {
  APP_URL: string;
  SERVER_API: string;
  SERVER_URL: string;
  CLIENT_ID: string;
  DEFAULT_LANGUAGE: LanguageEnum;
  ENVIRONMENT: string;
} = {
  APP_URL: process.env.REACT_APP_URL + '',
  SERVER_API: process.env.REACT_APP_API_URL + '',
  SERVER_URL: process.env.REACT_APP_GATEWAY_URL + '',

  CLIENT_ID: process.env.REACT_APP_CLIENT_ID + '',
  DEFAULT_LANGUAGE: (process.env.REACT_APP_DEFAULT_LANGUAGE +
    '') as LanguageEnum,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT + '',
};

export default env;

// REACT_APP_APP_URL=http://localhost:3001
// =http://localhost:8888/api
// =http://localhost:8888
//
// =f1f456a1-93fe-43b8-81d5-92aa20f81467
// =testing
// =en
