import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AssetsTypeEnum } from '@modules/product/components/lesson/AddStaticResource';
import { CompGroupEnum } from '@cms/utils/CompProps';
import OptionGroupUtils, {
  OptionTypeEnum,
} from '@cms/comps/interact/editable/OptionGroupUtils';
import {
  ContentActionEnum,
  ContentOperationEnum,
  ContentViewerEditable,
} from '@cms/comps/content/ContentViewerComp';
import { AssetsContentProps } from '@cms/content/ContentType';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';
import AutoUpdateToolbar from '@cms/comps/common/AutoUpdateToolbar';
import { ComposeModeContent } from '@cms/comps/common/ComposeModeContent';
import { useDropDownCompContext } from '@cms/comps/interact/dropdown/DropdownCompContext';
import { DropDownOptionProps } from '@cms/comps/interact/dropdown/DropdownComp';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import { StringUtils } from '@utils/StringUtils';
import OptionEditable from '@cms/editable/OptionEditable';
import styled from 'styled-components';

const DropDownEditableContext = createContext({
  editItem: '',
  changeToEdit: (id: string) => {
    console.log(id);
  },

  updateOptionType: (
    type: AssetsTypeEnum,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    console.log(type, index, option, from);
  },

  updateGroupOptions: (
    operation: ContentOperationEnum,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    console.log(operation, index, option, from);
  },

  updateOptionData: (
    data: AssetsContentProps,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    console.log(data, index, option, from);
  },
});

export const DropDownEditable = (props: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [editItem, setEditItem] = useState('');

  const {
    modifiedTime,
    answers,
    sourceItems,
    targetItems,
    updateOptions,
    updateAnswers,
    updateComponent,
  } = useDropDownCompContext();

  const updateOptionType = (
    type: AssetsTypeEnum,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    const newOption = {
      label: option.label,
      content: { ...option.content, type: type },
    };

    if (from === CompGroupEnum.source) {
      const newSourceItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        sourceItems
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = OptionGroupUtils.updateOption(
        index,
        newOption,
        targetItems
      );
      updateOptions(newTargetItems, from);
    }
  };

  const updateGroupOptions = (
    operation: ContentOperationEnum,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    let newOptions: DropDownOptionProps[] = [];
    let newAnswers: string[] = [];

    if (from === CompGroupEnum.source) {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          sourceItems,
          OptionTypeEnum.EMPTY_ABC
        );

        newAnswers = handleInsertOption(newOptions[index + 1].label, answers);

        // then, update correct answer.
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          sourceItems,
          OptionTypeEnum.EMPTY_ABC
        );

        newAnswers = handleRemoveOption(option.label, answers);
      }
    } else {
      if (operation === ContentOperationEnum.ADD) {
        newOptions = OptionGroupUtils.insertOption(
          index,
          option,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleInsertGroup(index, answers);
      } else {
        newOptions = OptionGroupUtils.removeOption(
          index,
          targetItems,
          OptionTypeEnum.NUMBER
        );

        newAnswers = handleRemoveGroup(index + 1, answers);
      }
    }

    // then update data...
    updateOptions(newOptions, from);
    updateAnswers(newAnswers);
  };

  const updateOptionData = (
    data: AssetsContentProps,
    index: number,
    option: DropDownOptionProps,
    from: CompGroupEnum
  ) => {
    const newOption = { label: option.label, content: { ...data } };

    if (from === CompGroupEnum.source) {
      const newSourceItems = COMPONENT_UTILS.updateAtIndex(
        sourceItems,
        index,
        newOption
      );
      updateOptions(newSourceItems, from);
    } else {
      const newTargetItems = COMPONENT_UTILS.updateAtIndex(
        targetItems,
        index,
        newOption
      );
      updateOptions(newTargetItems, from);
      setEditItem('');
    }
  };

  const changeToEdit = (editId: string) => {
    setEditItem(editId);
  };

  return (
    <DropDownEditableContext.Provider
      value={{
        editItem,
        changeToEdit,
        updateGroupOptions,
        updateOptionType,
        updateOptionData,
      }}
    >
      {props.children}

      <ComposeModeContent>
        <AutoUpdateToolbar
          lastChange={modifiedTime}
          onTimeout={updateComponent}
        />
      </ComposeModeContent>

      {editItem === 'source-items' && (
        <CustomModal
          maskClosable={false}
          header={t('component.label.options')}
          className={CustomModalClassEnum.default}
          content={
            <DropDownEditableStyle className={'dropdown-option-editable'}>
              {sourceItems &&
                sourceItems.map((drop, index) => {
                  return (
                    <div
                      className={`dropdown-inline-group-item`}
                      key={StringUtils.concat(
                        [
                          drop.label,
                          index + '',
                          drop.content.id,
                          modifiedTime + '',
                        ],
                        '-'
                      )}
                    >
                      <OptionEditable
                        contentTypes={[
                          ContentActionEnum.CHANGE_TO_TEXT,
                          ContentActionEnum.CHANGE_TO_IMAGE,
                        ]}
                        disabled={{
                          addOption: sourceItems.length === 15,
                          removeOption: sourceItems.length === 2,
                        }}
                        type={drop.content.type}
                        onChangeType={(newType) =>
                          updateOptionType(
                            newType,
                            index,
                            drop,
                            CompGroupEnum.source
                          )
                        }
                        onOperation={(operation) =>
                          updateGroupOptions(
                            operation,
                            index,
                            drop,
                            CompGroupEnum.source
                          )
                        }
                      >
                        <ContentViewerEditable
                          contentData={drop.content}
                          onChange={(data) =>
                            updateOptionData(
                              data,
                              index,
                              drop,
                              CompGroupEnum.source
                            )
                          }
                        />
                      </OptionEditable>
                    </div>
                  );
                })}
            </DropDownEditableStyle>
          }
          onCloseFunc={() => changeToEdit('')}
        />
      )}
    </DropDownEditableContext.Provider>
  );
};

export const useDropDownEditable = () => {
  const context = useContext(DropDownEditableContext);
  if (!context) {
    throw new Error('You must wrap container by DropDownEditable');
  }
  return context;
};

const DropDownEditableStyle = styled.div`
  font-size: 18px;

  .dropdown-inline-group-item {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
`;

const handleInsertOption = (optionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(optionLabel);

  return answers.map((ans) => {
    const ansData = parseDropdownAnswer(ans);
    // if answer is a -> a => b
    const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
    if (answerIndex >= newOptionIndex) {
      return generateDropdownAns(
        ansData.index,
        ansData.groupLabel,
        COMPONENT_UTILS.getABC(answerIndex + 1)
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveOption = (removeOptionLabel: string, answers: string[]) => {
  const newOptionIndex = COMPONENT_UTILS.getIndexOfABC(removeOptionLabel);

  return answers
    .filter((ans) => {
      const ansData = parseDropdownAnswer(ans);
      return ansData.optionLabel !== removeOptionLabel;
    })
    .map((ans) => {
      const ansData = parseDropdownAnswer(ans);

      const answerIndex = COMPONENT_UTILS.getIndexOfABC(ansData.optionLabel);
      if (answerIndex > newOptionIndex) {
        return generateDropdownAns(
          ansData.index,
          ansData.groupLabel,
          COMPONENT_UTILS.getABC(answerIndex - 1)
        );
      } else {
        return ans;
      }
    });
};

const handleInsertGroup = (index: number, answers: string[]) => {
  // then, update correct answer.
  return answers.map((ans) => {
    const ansData = parseDropdownAnswer(ans);

    if (ansData.index > index + 1) {
      return generateDropdownAns(
        ansData.index + 1,
        ansData.groupLabel,
        ansData.optionLabel
      );
    } else {
      return ans;
    }
  });
};

const handleRemoveGroup = (index: number, answers: string[]) => {
  return answers
    .filter((ans) => {
      const ansData = parseDropdownAnswer(ans);
      return ansData.index !== index;
    })
    .map((ans) => {
      const ansData = parseDropdownAnswer(ans);

      if (ansData.index > index) {
        return generateDropdownAns(
          ansData.index - 1,
          ansData.groupLabel,
          ansData.optionLabel
        );
      } else {
        return ans;
      }
    });
};

const parseDropdownAnswer = (ans: string) => {
  const groupPart = ans.split(':')[0];
  const answerPart = ans.split(':')[1];

  const index = groupPart.split('|')[0];
  const groupLabel = groupPart.split('|')[1];

  return {
    index: Number(index),
    groupLabel: groupLabel,
    // group: groupPart,
    optionLabel: answerPart,
  };
};

export const generateDropdownAns = (
  group: number,
  groupIndex: string,
  optionLabel: string
) => {
  return `${group}|${groupIndex}:${optionLabel}`;
};
