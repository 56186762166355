import React, { useState } from 'react';
import { PaginationReq } from '@services/model/PaginationRes';
import { notification } from 'antd';
import { useViewTeacherInvitation } from '@modules/setting/container/TeacherInvitation';
import { List, Avatar, Button, Skeleton } from 'antd';
import InvitationTypeComp from '@components/text/InvitationType';
import { useTranslation } from 'react-i18next';
import Card, { CardClassEnum } from '@components/card';
import { H2, H5 } from '@components/typography';
import { InComingEventStyle } from '@modules/dashboard/components/IncomingEvents';
import { UserDisplayName } from '@components/avatar/UserDisplayName';

export const MyInvitations = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState<PaginationReq>({
    page: 1,
    size: 5,
    sort: 'invitedDate',
    direction: 'descend',
  });

  const {
    data,
    isFetching,
    acceptTheInvitation,
    refreshData,
    getTeacherInvitation,
  } = useViewTeacherInvitation(params, setParams);

  const handleAcceptInvitation = (
    studentSubscriberId: number,
    isAccepted: boolean
  ) => {
    acceptTheInvitation(
      studentSubscriberId,
      isAccepted,
      (isSuccess, accepted) => {
        const warningMsg = accepted
          ? t('invitation.invitation.warning.accept')
          : t('invitation.invitation.warning.reject');

        if (isSuccess) {
          notification.success({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_success_message')
              : t('invitation.invitation.warning.reject_success_message'),
            placement: 'bottomRight',
          });

          getTeacherInvitation(params);
        } else {
          notification.error({
            message: warningMsg,
            description: accepted
              ? t('invitation.invitation.warning.accept_error_message')
              : t('invitation.invitation.warning.reject_error_message'),
            placement: 'bottomRight',
          });
        }
      }
    );
  };

  const loadMore = () => {
    refreshData(params);
  };

  return (
    <InComingEventStyle className={'invitation-group'}>
      <H2>{t('invitation.header')}</H2>

      <Card className={CardClassEnum.rectangle_box}>
        <List
          loading={isFetching}
          itemLayout="horizontal"
          dataSource={data ? data.content : []}
          loadMore={
            <Button type={'default'} shape={'round'} onClick={loadMore}>
              Load more
            </Button>
          }
          renderItem={(item) => (
            <List.Item
              key={item.studentSubscriberId}
              actions={[
                <Button
                  key={'accept-invitation'}
                  type={'primary'}
                  shape={'round'}
                  onClick={() =>
                    handleAcceptInvitation(item.studentSubscriberId, true)
                  }
                >
                  {t('invitation.invitation.actions.accept')}
                </Button>,
                <Button
                  key={'reject-invitation'}
                  danger
                  type={'primary'}
                  shape={'round'}
                  onClick={() =>
                    handleAcceptInvitation(item.studentSubscriberId, false)
                  }
                >
                  {t('invitation.invitation.actions.reject')}
                </Button>,
              ]}
            >
              <Skeleton avatar loading={isFetching} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.teacher.avatar} />}
                  title={
                    <H5>
                      <InvitationTypeComp
                        type={item.type}
                        params={item.params}
                      />
                    </H5>
                  }
                  description={
                    <UserDisplayName
                      username={item.teacher.username}
                      firstName={item.teacher.firstName}
                      lastName={item.teacher.lastName}
                      email={item.teacher.email}
                    />
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Card>
    </InComingEventStyle>
  );
};
