import { SchoolSettingAPI } from '@services/private/SchoolSettingAPI';
import { useEffect } from 'react';

export const useLoadSchoolSetting = (schoolId: number | undefined) => {
  const [getSchoolSetting, { data, isFetching, isSuccess }] =
    SchoolSettingAPI.endpoints.getSchoolSetting.useLazyQuery();

  const [
    getSchoolSettingAdmin,
    { data: adminData, isFetching: adminFetching, isSuccess: isAdminSuccess },
  ] = SchoolSettingAPI.endpoints.getSchoolSettingAdmin.useLazyQuery();

  useEffect(() => {
    if (schoolId != null && schoolId > 0) {
      getSchoolSettingAdmin(schoolId);
    } else {
      getSchoolSetting({});
    }
  }, [schoolId]);


  return schoolId != null && schoolId > 0 ? {
    data: adminData, isFetching: adminFetching, isSuccess: isAdminSuccess
  } : {
    data: data, isFetching: isFetching, isSuccess
  };
};