import {
  convertSessionToEvent,
  SessionInfoProps,
} from '@modules/calendar/container/ManageCalendar';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import CustomModal, {
  CustomModalClassEnum,
} from '@components/modal/CustomModal';
import { useTranslation } from 'react-i18next';
import PublicSessionInfo from '@components/public/session/PublicSessionInfo';
import { useLanguage } from '@hooks/useLanguage';
import { DateDefaultFormat } from '@components/text/DateAndTime';
import useCalendarConfig from '@modules/calendar/components/CalendarConfig';
import { SessionRes } from '@services/model/session';

export default function PublicCalendarInfo(props: {
  events: SessionRes[];
  onChangeDate: (date: string) => void;
}) {
  const { t } = useTranslation();
  const calendarConfig = useCalendarConfig();
  const language = useLanguage();

  const [defaultDate, setDefaultDate] = useState(
    dayjs().format(DateDefaultFormat)
  );

  const calendarRef = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState<SessionRes | null>(null);
  const [events, setEvents] = useState<SessionInfoProps[]>([]);

  useEffect(() => {
    const listEvent = props.events.map((item: SessionRes) => {
      return convertSessionToEvent(item);
    });

    setEvents(listEvent);
  }, [props.events]);

  useEffect(() => {
    if (calendarRef.current) {
      // @ts-ignore
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(defaultDate);
    }
  }, [defaultDate]);

  useEffect(() => {
    props.onChangeDate(defaultDate);
  }, [defaultDate]);

  const handleEventClick = (info: any) => {
    const currentEventData: SessionRes = info.event._def.extendedProps.item;
    setSelectedEvent(currentEventData);
  };

  const handleDateClick = (arg: any) => {
    setDefaultDate(arg.dateStr);
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        initialDate={defaultDate}
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        headerToolbar={{
          left: '',
          center: '',
          right: 'title', //dayGridMonth,timeGridWeek,timeGridDay
        }}
        editable
        selectable
        events={events}
        timeZone={'UTC'}
        allDaySlot={false}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        locale={language}
        height={100 * 24}
        expandRows={true}
        businessHours={calendarConfig.workSpec}
        slotMinTime={calendarConfig.workMin}
        slotMaxTime={calendarConfig.workMax}
        hiddenDays={calendarConfig.hideDays}
      />

      {selectedEvent && (
        <CustomModal
          header={t('session.header')}
          className={CustomModalClassEnum.full_size_modal}
          content={<PublicSessionInfo item={selectedEvent} />}
          onCloseFunc={() => setSelectedEvent(null)}
        />
      )}
    </>
  );
}
