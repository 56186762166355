import ButtonGroup from '@components/button/ButtonGroup';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

export const ClassRoomDirection = (props: { onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className={'class-room-direction'}>
      <div className={'content'}>
        <p>
          <b>Class Room</b> hiển thị thông tin các slide trong bài giảng của
          bạn. Bạn có thể chọn slide để trình bày hoặc yêu cầu học sinh trả lời
          câu hỏi ở đây.
        </p>

        <p>
          <b>Presentation</b> hiển thị nội dung đang được trình chiếu trong buổi
          học. Khung hiển thị màu tím thể hiện buổi học đang được trình bày.
        </p>

        <p>
          Khi bạn giao bài cho học sinh, bạn có thể kiểm tra tiến độ của học
          sinh tại tab <b>Exercise</b> . Bạn có thể lựa chọn bài làm để trình
          chiếu tại đây.
        </p>

        <p>
          <b>Raise hand</b> thể hiện yêu cầu của các học sinh ở thời điểm hiện
          tại. Bạn cũng có thể chủ động yêu cầu học sinh trả lời tại đây.
        </p>
      </div>

      <ButtonGroup className={'submit-container'} type={'center'}>
        <Button size={'large'} shape={'round'} onClick={props.onClose}>
          {t('button.close')}
        </Button>
      </ButtonGroup>
    </div>
  );
};
