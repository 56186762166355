import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@services/baseQuery';
import { PaginationReq, PaginationRes } from '@services/model/PaginationRes';
import {
  AssignmentInstanceRes,
  FilterStudentAssignmentReq,
} from '@modules/assignments/service/assignment_model';

export const StudentAssignmentAPI = createApi({
  reducerPath: 'StudentAssignmentAPI',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    filterStudentAssignment: builder.query<
      PaginationRes<AssignmentInstanceRes>,
      FilterStudentAssignmentReq
    >({
      query: (params) => ({
        url: `/student-assignments`,
        params,
      }),
    }),

    getUncompletedAssignment: builder.query<
      PaginationRes<AssignmentInstanceRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/student-assignments/uncompleted`,
        params,
      }),
    }),

    getCompletedAssignment: builder.query<
      PaginationRes<AssignmentInstanceRes>,
      PaginationReq
    >({
      query: (params) => ({
        url: `/student-assignments/completed`,
        params,
      }),
    }),
  }),
});
