import React from 'react';

import { NavigationMenuEnum } from '@components/template/app-layout/navigation/Navigation';
import Authorization from '@utils/authorization';
import { RoleEnum } from '@app/redux/slices/roles';
import { useTranslation } from 'react-i18next';
import { ManagementLayout } from '@components/template/app-layout/ManagementLayout';
import ResourceTableManagement from '@cms/resource-bank/ResourceTableManagement';
import { PageHeader } from '@app/header/PageHeader';

const ManageResources = () => {
  const { t } = useTranslation();

  return (
    <ManagementLayout className={'manage-resource-page'}>
      <PageHeader
        menu={NavigationMenuEnum.PRODUCTS}
        subMenu={NavigationMenuEnum.PRODUCTS_RESOURCE}
        title={t('resource.header')}
        description={t('resource.header')}
      />

      <Authorization
        type={'ifAnyGranted'}
        roles={[RoleEnum.TEACHER, RoleEnum.SCHOOL, RoleEnum.ADMIN]}
      >
        <ResourceTableManagement />
      </Authorization>
    </ManagementLayout>
  );
};

export default ManageResources;
