// eslint-disable-next-line @typescript-eslint/no-unused-vars

import React, { useEffect, useState } from 'react';
import Head from '@app/header';
import { Collapse } from 'antd';
import {
  MathOperationProps,
  OperationUtils,
} from '@cms/comps/math/operation/OperationUtils';
import { MathJaxExample } from '@components/math-equation/MathJaxExample';
import { useTranslation } from 'react-i18next';
import { ContentInlineEditable } from '@cms/comps/common/ContentInlineEditable';

const { Panel } = Collapse;

const TemplateFileForTesting = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // printOperationInline(OperationUtils.parseAdditionInline(['3523', '649']));
    console.log(OperationUtils.parsePlaceValue('575'));

    // printOperationInline(OperationUtils.parseSubtractionInline('925', ['275']));
    // printOperationInline(OperationUtils.parseMultiplicatsionInline('35', '12'));
    // printOperationInline(OperationUtils.parseDivisionInline('125', '3'));

    // console.log(OperationUtils.parseAddition(['3523', '649']));
    //
    // printOperation(OperationUtils.parseAddition(['9976.12', '426.123']));
    // printOperation(OperationUtils.parseAddition(['0.62', '0.89323']));
    //
    // console.log(OperationUtils.parseSubtraction('1029', ['275', '645'], true));
    //
    // printOperation(OperationUtils.parseSubtraction('999', ['199']));
    // printOperation(OperationUtils.parseSubtraction('99.9', ['19.9']));
    // printOperation(OperationUtils.parseSubtraction('99.9', ['19.9', '10.256']));

    // printOperation(OperationUtils.parseMultiplication('35', '12', true));
    // console.log(OperationUtils.parseMultiplication('35', '12', true));

    // printOperation(OperationUtils.parseDivision('125', '3'));
    // console.log(OperationUtils.parseDivision('125', '3'));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const printOperationInline = (row: MathOperationProps) => {
    const message = row.results.map((rs) => {
      return '|' + rs.exp.join('|') + '|';
    });

    console.log(message.join(' '));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const printOperation = (addition: MathOperationProps) => {
    addition.results.forEach((rs, index) => {
      if (
        rs.type === 'sum' ||
        rs.type === 'difference' ||
        (rs.type === 'template' && index === 2) ||
        rs.type === 'product'
      ) {
        console.log(
          OperationUtils.createStringChars('_', rs.exp.length * 2 + 1).join('')
        );
      }

      console.log('|' + rs.exp.join('|') + '|');
    });

    console.log('\n \n \n');
  };

  const [contentValue, setContentValue] = useState(
    'This is __________ and __________ and __________'
  );

  const handleOnContentChange = (value: string) => {
    setContentValue(value);
  };

  return (
    <>
      <Head>
        <title>{t('organization')} - Template</title>
        <meta name="description" content="Parent registration" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ContentInlineEditable
        value={contentValue}
        onChange={handleOnContentChange}
      />
      {contentValue} - ContentInlineEditable
      <div style={{ display: 'none' }}>
        <MathJaxExample />

        <Collapse defaultActiveKey={['1']}>
          <Panel header="Working with API" key="Working with API">
            <h4>GET API</h4>

            <p>
              {`const {data} = UserAPI.endpoints.getMyAccount.useQuery({}, { refetchOnMountOrArgChange: true });`}
            </p>

            <p>
              {`const {data} = UserAPI.endpoints.getMyAccount.useQuery({}, { refetchOnMountOrArgChange: true });`}
            </p>

            <h4>POST, PUT, DELETE API</h4>
            <p>
              {` const [updateMyAccount] = UserAPI.endpoints.updateMyAccount.useMutation({});`}
            </p>

            <p>
              {` insertStall(request)
                .unwrap()
                .then((saveData) => {
                  callBackFun();
                  updateStallData(update_stall_type.add, [saveData.stall], psData);
                
                  warningMessage('success', 'Add Stall Information', 'Successful!');
                  setStallData(null);
                })
                .catch(() => {
                  callBackFun();
                  warningMessage(
                    'error',
                    'Add Stall Information',
                    'Cannot add stall information. Please try again later!'
                  );
                  setStallData(null);
                });`}
            </p>
          </Panel>

          <Panel header="Working with redux" key="Working with redux">
            <h4>Working with redux</h4>

            <div className={'content-help'}>
              {`const { isAuth, role} = useSelector( (state: RootState) => state.profile);`}
            </div>
          </Panel>

          <Panel header="Working with Public page" key="public-page">
            <h4>Working with Public page</h4>

            <div className={'content-help'}>
              {`const getAllArticles = async () => {
                const result = await fetch("https://some_api_url");
                const articles = await result.json();
      
                return articles.results.map((article) => {
                const articleTitle = article.title;
      
                return {
                params: {
                title: articleName,
                id: article.id,
              },
              };
              });
              };
      
                /**
                * @dev Defines the paths available to reach directly
                */
                export async function getStaticPaths() {
                const paths = await getAllArticles();
                return {
                paths,
                fallback: false,
              };
            }`}
            </div>
          </Panel>

          <Panel header="Working with context" key="Working with context">
            <h4>Create Context</h4>

            <div className={'content-help'}>
              {`export const GCalendarContext = createContext({
                events: [] as SessionInfoProps[],
                setEvents: (data: any) => { console.log(data)},
              });`}
            </div>

            <h4>Using context</h4>

            <div className={'content-help'}>
              {`const [events, setEvents] = useState<SessionInfoProps[]>([]);`}
            </div>

            <div className={'content-help'}>
              {`<GCalendarContext.Provider value={{events, setEvents}}></GCalendarContext.Provider>`}
            </div>

            <div className={'content-help'}>
              {`const {defaultDate, events, setEvents} = useContext(GCalendarContext);`}
            </div>
          </Panel>

          <Panel header="Notification" key="Notification">
            <div className={'content-help'}>
              {`notification.success({
                  message: \`Mời thành công\`,
                  description: 'Mời học sinh thành công',
                  placement: 'bottomRight',
                });`}
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default TemplateFileForTesting;
