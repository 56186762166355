import React, { useEffect, useRef, useState } from 'react';
import { ResourceProps } from '@modules/product/components/resource/Resource';
import { CompProps } from '@cms/ComponentInteface';
import { COMPONENT_UTILS } from '@cms/utils/ComponentUtils';

import { ResourceAPI } from '@modules/product/services/ResourceAPI';
import { AssetsRes } from '@modules/product/services/assets_model';
import ResourceEditableSingle from '@modules/product/components/resource/ResourceEditableSingle';
import styled from 'styled-components';

import {
  addComponents,
  clearComposeResourceData,
  initData,
} from '@app/redux/slices/composeResource';
import { LessonValidation } from '@cms/lesson-template/compose/validation';

import { useConfirmBrowserExit } from '@hooks/useConfirmBrowserExit';
import {
  LessonEditableActionEnum,
  useLessonEditableContext,
} from '@cms/context/LessonEditableProvider';
import { EditModeEnum } from '@modules/product/components/lesson/ComposeLessonProps';
import { DateAndTimeUtils } from '@utils/DateAndTimeUtils';
import ResourceWysiEditable from '@modules/product/components/resource/ResourceWysiEditable';
import { useProUser } from '@components/button/RequireProUserButton';
import { ResourceUtils } from '@cms/utils/ResourceUtils';
import { useDispatch, useSelector } from '@app/redux/hook';
import { useRouter } from '@hooks/useRouter';
import { SiteMap } from '@router/SiteMap';

const dropResourcePlaceHolder: ResourceProps = {
  resourceId: -1,

  title: '',
  description: '',
  keyword: '',

  components: [],
  correctAnswer: [],
  scoring: [],

  displayOrder: 1,
  questionNumber: 1,
  showQuestionNumber: true,
};

interface ComposeResourceSetting {
  type: 'complex' | 'simple';
  autoUpdate: boolean;
  allowDragToUpload: boolean;
}

const ComposeResourceTemplate = (props: {
  resourceId: number;
  setting?: ComposeResourceSetting;
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const proUser = useProUser();

  const container = useRef<HTMLDivElement>(null);
  const { lessonId, lesson, editMode, action } = useLessonEditableContext();

  const [lastModifiedTime, setLastModifiedTime] = useState(-1);

  const { changed } = useSelector((state) => state.composeResource);

  const [findById, { data, isSuccess, error, isError }] =
    ResourceAPI.endpoints.findById.useLazyQuery({});

  const { enable, disable } = useConfirmBrowserExit(
    true,
    'Confirm before exit... '
  );

  // load resource data from resource id.
  useEffect(() => {
    if (props.resourceId && props.resourceId > 0) {
      findById(props.resourceId);
      // dont know why data not change when click resource xxxx then click -1 then click xxx again. API fetched but no trigger
      setLastModifiedTime(DateAndTimeUtils.getCurrentTime());
    } else {
      dispatch(initData(null));
    }
    return () => {
      dispatch(clearComposeResourceData());
    };
  }, [props.resourceId]);

  // then store to the redux.
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(initData(ResourceUtils.convert(data, 'content', -1)));
    } else if (error && isError) {
      if (lessonId > 0) {
        router.push(SiteMap.content.lesson.compose_gen(lessonId, proUser));
      } else {
        router.push(SiteMap.content.resource.list);
      }
    }
  }, [data, error, lessonId, lastModifiedTime]);

  useEffect(() => {
    if (changed.changed) {
      enable();
    } else {
      disable();
    }
  }, [changed]);

  // for lesson edit resource....

  const handleOnUploadFiles = (file: AssetsRes) => {
    const fileComp: CompProps = COMPONENT_UTILS.generateComps(file);
    dispatch(addComponents([fileComp]));
  };

  useEffect(() => {
    if (action.action === LessonEditableActionEnum.add_component) {
      if (container.current) {
        container.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [action, container]);

  return (
    <>
      <EditResourceTemplateStyle
        className={'lesson-content-editable show-correct-answer'}
      >
        {lessonId > 0 && lesson && (
          <div className={'lesson-validation-btn'}>
            <LessonValidation lessonId={lessonId} />
          </div>
        )}

        <div className={'lesson-resources'}>
          {editMode === EditModeEnum.WYSI ? (
            <ResourceWysiEditable item={dropResourcePlaceHolder} />
          ) : (
            <ResourceEditableSingle
              item={dropResourcePlaceHolder}
              allowDragToUpload={
                props.setting ? props.setting.allowDragToUpload : true
              }
              handleOnUploadFiles={handleOnUploadFiles}
            />
          )}
        </div>

        <div className={'bottom-editable-resource'} ref={container} />
      </EditResourceTemplateStyle>
    </>
  );
};

export default ComposeResourceTemplate;

const EditResourceTemplateStyle = styled.div`
  padding-bottom: 50px;

  .lesson-validation-btn {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .lesson-subtitle {
    margin-bottom: 0.5em;

    .lesson-title-display {
      font-size: 200%;
    }
  }

  .resource-subtitle {
    margin-top: 0.5em;
    margin-bottom: 1em;

    .resource-title-display {
      font-size: 125%;
    }
  }
`;
